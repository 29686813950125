import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'app/services/user/user.service';
import { CompanyService } from 'app/services/company/company.service';
import { Company } from 'app/functional/models/company';
import { Role } from 'app/functional/models/role.model';
import { UserWithOrganizations } from 'app/functional/models/user';
import { OrganizationService } from '../../../services/organization/organization.service';
import { Organization } from '../../../functional/models/organization.model';
import { CreateCustomerDialogComponent } from '../../../dialogs/dossiers/create-customer-dialog/create-customer-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatSort) set content(content: any) {
    if (content && this.dataSource) {
      this.dataSource.sort = content;
      this.dataSource.paginator = this.paginator;
    }
  }

  displayedColumns: string[] = ['name', 'address', 'organizations'];
  dataSource!: MatTableDataSource<UserWithOrganizations>;
  customers: Array<UserWithOrganizations> = [];
  companySubscription = new Subscription();
  selection = new SelectionModel<UserWithOrganizations>(true, []);
  organizations: Organization[] = [];
  company?: Company;
  role = Role.customer;

  constructor(
    public router: Router,
    public userService: UserService,
    public companyService: CompanyService,
    private organizationService: OrganizationService,
    private dialog: MatDialog
  ) {
    this.role = this.userService.tryClaims().role;
  }

  async ngOnInit() {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
      await this.getCustomersAndOrganizations(this.company);
    });
  }

  async getCustomersAndOrganizations(company: Company) {
    this.customers = await this.userService.getCustomers(company);
    this.organizations = await this.organizationService.filter({companyId: this.company!.id});

    for (const customer of this.customers) {
      customer.organizations = this.organizations.filter(item => item.userIds.includes(customer.id));

      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/dot-notation
      customer['name'] = customer.firstName + ' ' + customer.lastName;
    }

    this.dataSource = new MatTableDataSource(this.customers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.companySubscription.unsubscribe();
  }

  redirect(id: number) {
    this.router.navigate(['management', 'customers', 'details', id]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  makeCSV(): void {
    let csv = 'id,voornaam,achternaam,email\n';
    for (const customer of this.dataSource.data) {
      csv += customer.id + ',';
      csv += customer.firstName + ',';
      csv += customer.lastName + ',';
      csv += customer.email + ',\n';
    }
    const blob = new Blob([csv], {
      type: 'text/csv',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = 'export-users.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  routeToOrganization(event: MouseEvent, organization: Organization) {
    event.preventDefault();
    event.stopPropagation();

    this.router.navigate(['management', 'organizations', 'details', organization.id]);
  }

  handleClick(event: any) {
    if (event === 'new') {
      const dialogRefx = this.dialog.open(CreateCustomerDialogComponent, {
        panelClass: 'create-customer-dialog',
        data: {
          company: this.company,
          permissionsSelectable: false,
          isEmployee: false
        },
        autoFocus: false
      });

      dialogRefx.afterClosed().subscribe((res: any) => {
        if (res && res.data) {
          this.customers.push(res.data);
          this.dataSource.data = [...this.customers];
        }
      });
    }
  }

  masterToggle() {
    if(this.isAllSelected()){
      this.selection.clear();
    }else{
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }
}
