<div class="emma-body" style="padding-top: 55px; background-color: white">
  <app-sub-nav
    [search]="true"
    (searchChange)="exchange.onSearchChange($event)"
    [buttons]="[{icon:'add', name: 'new' | translate, event: 'new', menu: true, hidden: !exchange.canUserCreate() && !exchange.canUserWrite()}]">

    <ng-container select>
      <span class="no-select" *ngIf="exchange.canUserCreate()"  (click)="exchange.openDialog();">{{'exchange_details.create_folder' | translate}}</span>
      <span class="no-select" *ngIf="exchange.canUserWrite()" (click)="exchange.openFilePicker();">{{'exchange_details.upload_file' | translate}}</span>
      <span class="no-select" *ngIf="exchange.canUserWrite() && claims.role !== localRole.customer" (click)="exchange.openFileRequest();">{{'exchange_details.request' | translate}}</span>
    </ng-container>

    <div class="exchange-button-holder">
      <app-emma-select
        style="margin-left: 20px"
        name="search-select"
        [options]="[
          {displayName: 'explorer.search.current_folder' | translate, value: 1},
          {displayName: 'explorer.search.sub_folders' | translate, value: 2},
          {displayName: 'explorer.search.all_folders' | translate, value: 3}
        ]"
        [(ngModel)]="searchType"
        [prefix]="('explorer.search.placeholder' | translate) + ':'">
      </app-emma-select>

      <app-emma-select
        style="margin-left: 20px"
        name="sort-select"
        *ngIf="exchange.view === 'small'"
        [options]="[
          {displayName: 'explorer.sort.name_asc' | translate, value: 1},
          {displayName: 'explorer.sort.name_dsc' | translate, value: 2},
          {displayName: 'explorer.sort.date_asc' | translate, value: 3},
          {displayName: 'explorer.sort.date_dsc' | translate, value: 4},
        ]"
        [(ngModel)]="exchange.lSortType"
        (change)="exchange.setSort(exchange.lSortType)"
        [prefix]="('explorer.sort.placeholder' | translate) + ':'">
      </app-emma-select>

      <app-emma-select
        style="margin-left: 20px"
        name="view-select"
        [title]="'explorer.view.title' | translate"
        [renderIconOnly]="true"
        [options]="[
          {displayName: 'explorer.view.grid' | translate, value: 'small', postfixIcon: 'apps'},
          {displayName: 'explorer.view.list' | translate, value: 'list', postfixIcon: 'list'}
        ]"
        [(ngModel)]="exchange.view"
        (change)="exchange.setView(exchange.view)">
      </app-emma-select>
    </div>
  </app-sub-nav>

  <app-exchange-overview
    #exchange
    [searchType]="searchType"
    (breadCrumbChange)="setCrumbs($event)">
  </app-exchange-overview>
</div>
