<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div class="deny-document-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{ 'dialog.info.reject' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 align-text-center">
      <p>{{ 'dialog.info.await_approval' | translate }}</p>
      <p><b>{{ 'dialog.info.reason' | translate }}</b></p>
    </div>
  </div>
  <div class="row">
    <form [formGroup]="reasonForm" *ngIf="reasonForm">
      <div class="col-md-12 centered">
          <div class="emma-input-container">
            <label label for="reason" class="emma-input-label">{{'dialog.info.placeholder' | translate }}</label>
            <textarea appEmmaInput id="reason" formControlName="reason"></textarea>
          </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div class="col-md-12 align-text-center">
      <p>{{ 'dialog.info.explanation1' | translate }}<br />
        {{ 'dialog.info.explanation2' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <button  class="btn btn-blue btn-custom " (click)="submit()">
        <b>{{ 'dialog.info.reject' | translate }}</b>
      </button>
    </div>
  </div>
</div>
