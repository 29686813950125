<div class="card" id="mail-settings-block">
  <div class="card-header card-header-grey card-header-icon">
    <div class="card-icon card-header-custom"><mat-icon>notifications</mat-icon></div>
    <div class="exchange-card-title-between">
      <h4 class="card-title">
        {{ 'email_settings.edit_email_notifications' | translate }}
      </h4>
    </div>
  </div>
  <div class="card-body settings-body">
    <div class="row" *ngFor="let setting of emailSettings">
      <div class="flex-centered-items">
        <div class="col-md-1"><mat-slide-toggle (change)="setToggled($event, setting)" [(ngModel)]="setting.toggled"></mat-slide-toggle></div>
        <div class="col-md-6 emma-font-small-default">{{setting.displayName}}</div>
        <div class="col-md-5">
          <div class="emma-input-container">
            <label label for="end" class="emma-input-label">{{'email_settings.disabled_till' | translate }}</label>
            <div class="emma-input">
              <input id="end" appEmmaInput [matDatepicker]="picker" (dateChange)="setDate(setting)" [(ngModel)]="setting.endDate" [disabled]="setting.toggled">
              <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
            </div>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
