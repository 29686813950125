<div class="user-info-container">
  <div class="user-card">
    <app-user-icon [firstName]="user.firstName" [lastName]="user.lastName" [large]="true">
    </app-user-icon>
    <div class="name-label">
      {{user.firstName}} {{user.lastName}}
    </div>

    <div class="user-title">
      {{'dashboard.email' | translate}}:
    </div>
    <div class="user-content">
      <a href="mailto:{{user.email}}">{{user.email}}</a>
    </div>

    <div *ngIf="user.phone" class="user-title">
      {{'dashboard.phone' | translate}}:
    </div>
    <div class="user-content">
      <a href="tel:{{user.phone}}">{{user.phone}}</a>
    </div>

    <div class="user-title" *ngIf="user.street || user.houseNumber || user.postalCode || user.city">
      {{'dashboard.address' | translate}}:
    </div>
    <div class="user-content">
      {{user.street}} {{user.houseNumber}} {{user.premise}}
    </div>
    <div class="user-content">
      {{user.postalCode}} {{user.city}}
    </div>

    <ng-container *ngIf="user.role === lRole.customer">
      <div class="user-title">
        {{'dashboard.managers'| translate}}:
        <ng-icon name="heroPlusCircle" (click)="addEmployee()"></ng-icon>
      </div>
      <ng-container *ngFor="let employee of employees; let first = first;">
        <div class="user-content employee-row" [ngClass]="{'employee-row-first': first}" (click)="navigateToUser(employee)">
          {{employee.firstName}} {{employee.lastName}}
          <ng-icon name="heroTrash" (click)="deleteUser(employee, $event)"></ng-icon>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <app-user-recents [company]="company" [user]="user" style="flex: 1"></app-user-recents>
</div>
