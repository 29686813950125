<div class="user-recents table-paginator-container">
  <div class="mat-table-container">
    <table mat-table class="emma-table" [dataSource]="mixedView" matSort #firstSort="matSort">
      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dashboard.signing_document' | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.docName}} </td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dashboard.user_name' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="!row.users || row.users.length === 0">
            <span class="font-link" (click)="routeToUser(row.user)" *ngIf="row.userName">{{row.userName}}</span>
            <span *ngIf="!row.userName"> - </span>
          </ng-container>
          <div style="display: flex; align-items: center" *ngIf="row.users && row.users.length > 0">
            <app-user-icon
              style="{{first ? '' : 'left: -'+ (20 * index) +'px; position: relative;'}}"
              *ngFor="let user of row.users; let first = first; let index = index"
              [firstName]="user.firstName"
              [lastName]="user.lastName"
              [border]="true"
              class="hover-user-icon"
              [appEmmaTooltip]="user.firstName + ' ' + user.lastName"
              (click)="routeToUser(user)">
            </app-user-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dashboard.signing_date' | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.creationDate | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dashboard.type' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <app-tag *ngIf="row.type === 'accept'" [color]="colors.blue"> {{'dashboard.approvals' | translate}} </app-tag>
          <app-tag *ngIf="row.type === 'signing'" [color]="colors.blue"> {{'dashboard.signings' | translate}} </app-tag>
          <app-tag *ngIf="row.type === 'request'" [color]="colors.pink"> {{'dashboard.request' | translate}} </app-tag>
          <app-tag *ngIf="row.type === 'upload'" [color]="colors.indigo"> {{'dashboard.uploads' | translate}} </app-tag>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dashboard.status' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <app-tag [color]="colors.red" *ngIf="row.status === 'rejected'"> {{'dashboard.rejected' | translate}}</app-tag>
          <app-tag [color]="colors.green" *ngIf="row.status === 'signed'"> {{'dashboard.approved' | translate}} </app-tag>
          <app-tag [color]="colors.green" *ngIf="row.status === 'accepted'"> {{'dashboard.signed' | translate}} </app-tag>
          <app-tag [color]="colors.yellow" *ngIf="row.status === 'pending'"> {{'dashboard.pending' | translate}}</app-tag>
          <app-tag [color]="colors.green" *ngIf="row.status === 'uploaded'"> {{'dashboard.uploaded' | translate}}</app-tag>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <app-emma-select
            [customStyle]="true"
            [renderButton]="false"
            #filter>
            <ng-icon class="ng-icon-default" type="solid" name="heroFunnel" button (click)="filter.toggleMenu()"></ng-icon>
            <ng-container body>
              <div style="display: flex; margin: 20px">
                <div style="display: flex; flex-flow: column;">
                  <p>{{'dashboard.type' | translate}}:</p>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.approvals" (change)="applyFilter()">
                    <app-tag [color]="colors.blue">{{'dashboard.approvals' | translate}}</app-tag>
                  </mat-checkbox>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.signings" (change)="applyFilter()">
                    <app-tag [color]="colors.blue">{{'dashboard.signings' | translate}}</app-tag>
                  </mat-checkbox>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.requests" (change)="applyFilter()">
                    <app-tag [color]="colors.pink">{{'dashboard.request' | translate}}</app-tag>
                  </mat-checkbox>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.uploads" (change)="applyFilter()">
                    <app-tag [color]="colors.indigo">{{'dashboard.uploads' | translate}}</app-tag>
                  </mat-checkbox>
                </div>
                <div style="display: flex; flex-flow: column; margin-left: 20px">
                  <p>{{'dashboard.status' | translate}}:</p>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.rejected" (change)="applyFilter()">
                    <app-tag [color]="colors.red">{{'dashboard.rejected' | translate}}</app-tag>
                  </mat-checkbox>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.accepted" (change)="applyFilter()">
                    <app-tag [color]="colors.green">{{'dashboard.approved' | translate}}</app-tag>
                  </mat-checkbox>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.signed" (change)="applyFilter()">
                    <app-tag [color]="colors.green">{{'dashboard.signed' | translate}} </app-tag>
                  </mat-checkbox>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.pending" (change)="applyFilter()">
                    <app-tag [color]="colors.yellow">{{'dashboard.pending' | translate}}</app-tag>
                  </mat-checkbox>
                  <mat-checkbox style="margin-bottom: 10px" [(ngModel)]="filterSettings.uploaded" (change)="applyFilter()">
                    <app-tag [color]="colors.green">{{'dashboard.uploaded' | translate}}</app-tag>
                  </mat-checkbox>
                </div>
              </div>
            </ng-container>
          </app-emma-select>
        </th>
        <td mat-cell *matCellDef="let row">
          <app-emma-select
            [options]="[
              {displayName: 'signing.overview.view.file' | translate, value: 1, hidden: !row.fileId},
              {displayName: 'signing.overview.view.dossier' | translate, value: 2, hidden: !row.dossierId},
              {displayName: 'signing.overview.view.folder' | translate, value: 3, hidden: !row.folderId},
              {displayName: 'signing.overview.view.signature' | translate, value: 4, hidden: !row.signature || (row.signature && (row.signature.rejectionDate || row.signature.signDate))},
            ]"
            [renderButton]="false"
            (change)="triggerAction($event, row)"
            #selection>
            <mat-icon class="more-button" (click)="selection.toggleMenu()" button>more_vert</mat-icon>
          </app-emma-select>
        </td>
      </ng-container>

      <tr class="empty-row" *matNoDataRow>
        <td colspan="9999">
          <div class="illustration">
            <img src="assets/img/illustrations/uptodate.svg">
            <h1 class="not-found-title">{{'dashboard.no_recent' | translate}}</h1>
            <span>
                {{'dashboard.no_recent_description' | translate}}
            </span>
          </div>
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row class="element" *matRowDef="let row; columns: columns;"></tr>
    </table>
  </div>
  <div>
    <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </div>
</div>
