<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    [search]="true"
    (searchChange)="filter = $event"
    [buttons]="[{icon:'add', name: 'new' | translate, event: 'new'}]"
    (buttonClicked)="createOrganization($event)">
  </app-sub-nav>
  <app-organization-overview
    class="emma-body"
    [filter]="filter">
  </app-organization-overview>
</div>
