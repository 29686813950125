import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {SearchCompaniesAndCustomersComponent} from './search-companies-and-customers.component';
import {AutocompleteSelectModule} from '../../base-components/autocomplete-select/autocomplete-select.module';

@NgModule({
  declarations: [SearchCompaniesAndCustomersComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    AutocompleteSelectModule,
  ],
  exports: [
    SearchCompaniesAndCustomersComponent,
  ]
})
export class SearchCompaniesAndCustomersModule { }
