import {Component, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import { SearchType, SortType } from 'app/functional/models/exchange.model';
import {NavVariableService} from '../../../services/nav-variable/nav-variable.service';
import {Folder} from '../../../functional/models/folder';
import {ExchangeOverviewComponent} from '../../../components/exchange/exchange-overview/exchange-overview.component';
import {Location} from '@angular/common';
import {UserService} from '../../../services/user/user.service';
import {Role} from '../../../functional/models/role.model';
import {Claims} from '../../../functional/models/user';

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExplorerComponent implements OnDestroy {

  @ViewChild('exchange') exchange!: ExchangeOverviewComponent;

  searchType = SearchType.currentFolder;
  sortType = SortType.nameAscending;
  localRole = Role;
  claims!: Claims;

  constructor(
    private navVariableService: NavVariableService,
    private location: Location,
    private userService: UserService
  ) {
    this.claims = this.userService.tryClaims();
    window.addEventListener('window-navigate', (e: any) => this.navigate(e.detail.folder), false);
  }

  ngOnDestroy() {
    window.removeEventListener('window-navigate', (e: any) => this.navigate(e.detail.folder), false);
  }

  navigate(item: Folder) {
    if (this.exchange) {
      const folder = this.exchange.breadCrumb.find(e => e.id === Number(item.id));
      if (folder) {
        this.exchange.navigate(folder);
      }
    }
  }

  setCrumbs(event: Folder[]) {
    if (!event || event.length === 0){
      this.location.replaceState('exchange');
    } else {
      const folderId = event[event.length - 1].id;
      if (folderId === -1) {
        this.location.replaceState('exchange');
      } else {
        this.location.replaceState('exchange/' + folderId);
      }
    }

    this.navVariableService.navVariables.next({folderCrumbs: event});
  }
}
