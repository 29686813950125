import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DossierTemplateService} from '../../../services/dossier-template/dossier-template.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CompanyService} from '../../../services/company/company.service';
import {Company} from '../../../functional/models/company';

@Component({
  selector: 'app-create-dossier-template-dialog',
  templateUrl: './create-dossier-template-dialog.component.html',
  styleUrls: ['./create-dossier-template-dialog.component.scss']
})
export class CreateDossierTemplateDialogComponent implements OnInit {
  createTemplate!: UntypedFormGroup;
  designation = 1;
  companies: Company[] = [];
  filteredCompanies: Company[] = [];

  constructor(
    public formBuilder: UntypedFormBuilder,
    public templateService: DossierTemplateService,
    public dialogRef: MatDialogRef<CreateDossierTemplateDialogComponent>,
    public companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  async ngOnInit() {
    this.createTemplate = this.formBuilder.group({
      name: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      description: ['', []],
      companyType: [0, []],
      companyId: [0, []],
      filter: ['',[]]
    });

    this.companies = await this.companyService.getCompanies();
    this.filteredCompanies = this.companies;
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  async createDossierTemplate() {
    this.createTemplate.markAllAsTouched();

    if (this.createTemplate.invalid || !this.designation) {
      return;
    }

    if (this.designation === 2 && this.createTemplate.get('companyId')?.value === null){
      return;
    }

    let result;

    if (this.designation === 1) {
      result = await this.templateService.create({
        name: this.createTemplate.get('name')!.value,
        companyType: this.createTemplate.get('companyType')!.value,
        description: this.createTemplate.get('description')?.value || ''
      });
    }

    if (this.designation === 2) {
      result = await this.templateService.create({
        name: this.createTemplate.get('name')!.value,
        companyId: this.createTemplate.get('companyId')!.value,
        description: this.createTemplate.get('description')?.value || '',
      });
    }

    if (result) {
      await this.templateService.createState(result.id, {
        name: 'Aangemaakt',
        initialState: true,
        finalState: false,
        mayHaveDeadline: false,
      });

      await this.templateService.createState(result.id, {
        name: 'Gesloten',
        initialState: false,
        finalState: true,
        mayHaveDeadline: true,
      });
      this.dialogRef.close(result);
    }
  }

  filter(event: string) {
    if (event === '') {
      this.filteredCompanies = this.companies;
    } else {
      this.filteredCompanies = this.companies.filter(item => item.name.toLowerCase().includes(event) || item.id.toString().includes(event));
    }
  }
}
