import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../../functional/models/user';
import {Dossier} from '../../functional/models/dossier.model';
import {Organization} from '../../functional/models/organization.model';
import {Group} from '../../functional/models/group';
import {Folder} from '../../functional/models/folder';
import {Document} from '../../functional/models/document';

@Injectable({
  providedIn: 'root'
})
export class NavVariableService {

  navVariables = new BehaviorSubject<NavData>({});

  constructor() { }

}

export class NavData {
  user?: User;
  dossier?: Dossier;
  organization?: Organization;
  group?: Group;
  folderCrumbs?: Folder[];
  fileCrumb?: Document;
}
