import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedGuard implements CanActivate {

  constructor(public router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const refresh = localStorage.getItem('Refresh');
    const helper = new JwtHelperService();

    if (refresh && !helper.isTokenExpired(refresh)) {
      this.router.navigate(['dashboard']);
      return false;
    } else {
      localStorage.removeItem('Token');
      localStorage.removeItem('Refresh');
      return true;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
