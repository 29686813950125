import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Signing, SignType} from 'app/functional/models/signing';
import {SigningService} from 'app/services/signing/signing.service';
import JSPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-display-signatures-dialog',
  templateUrl: './display-signatures-dialog.component.html',
  styleUrls: ['./display-signatures-dialog.component.scss']
})
export class DisplaySignaturesDialogComponent implements OnInit {
  public signatures!: Signing[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DisplaySignaturesDialogComponent>,
    private signatureService: SigningService
  ) { }

  async ngOnInit() {
    this.signatures = this.data.signatures;
    await this.refreshSignatures();
  }

  async refreshSignatures() {
    for (const signature of this.signatures) {
      if (signature.signType === SignType.signature) {
        signature.displayUrl = this.blobToImage(await this.signatureService.getSignature(signature.id));
      }
    }
  }

  blobToImage(blob: ArrayBuffer) {
    const bytes = new Uint8Array(blob);
    const binary = [].map.call(bytes, (byte) => String.fromCharCode(byte)).join('');
    const mediaType = 'image/png';
    return [
      'data:',
      mediaType ? mediaType + ';':'',
      'base64,',
      btoa(binary)
    ].join('');
  }

  getBase64FromImage(url: any, onSuccess: any, onError: any) {
    const xhr = new XMLHttpRequest();

    xhr.responseType = 'arraybuffer';
    xhr.open('GET', url);

    xhr.onload = () => {
      const bytes = new Uint8Array(xhr.response);
      const binary = [].map.call(bytes, (byte) => String.fromCharCode(byte)).join('');
      const mediaType = xhr.getResponseHeader('content-type');
      const base64 = [
        'data:',
        mediaType ? mediaType + ';':'',
        'base64,',
        btoa(binary)
      ].join('');
      onSuccess(base64);
    };
    xhr.onerror = onError;
    xhr.send();
  }

  closeModal() {
    this.dialogRef.close();
  }

  async download(signatureView: HTMLDivElement) {
    html2canvas(signatureView, {useCORS: false, allowTaint: true}).then(canvas => {
      const imgWidth = 200;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const position = 0;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new JSPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('signatures.pdf');
    });
  }
}
