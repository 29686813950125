import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { CreateCustomerDialogComponent } from './create-customer-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRippleModule} from '@angular/material/core';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {TranslateModule} from '@ngx-translate/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { NgIconsModule } from '@ng-icons/core';
import {EmmaInputModule} from '../../../components/base-components/emma-input/emma-input.module';
import {EmmaSelectModule} from '../../../components/base-components/emma-select/emma-select.module';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';

@NgModule({
    declarations: [CreateCustomerDialogComponent],
    imports: [
        CommonModule,
        MatSelectModule,
        MatOptionModule,
        MatExpansionModule,
        MatRadioModule,
        MatRippleModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatDatepickerModule,
        TranslateModule.forRoot(),
        MatCheckboxModule,
        NgIconsModule,
        EmmaInputModule,
        ReactiveFormsModule,
        EmmaSelectModule,
        MatListModule
    ],
    providers: [
        DatePipe
    ]
})
export class CreateCustomerDialogModule { }
