<div class="main-content" style="height: 100%;">
  <div class="container-fluid" style="height: 100%;">
    <div class="row" *ngIf="userRole <= 1">
      <div class="col-lg-3 mobile-hidden" *ngIf="company && (company.usedSpace || company.usedSpace === 0)">
        <div class="card card-stats">
          <div class="card-header card-header-blue card-header-icon">
            <div class="card-icon">
              <mat-icon>storage</mat-icon>
            </div>
            <p class="card-category">{{'dashboard.storage' | translate}}</p>
            <p class="card-title">{{floor(company.usedSpace/10000000)/100}}
              <small>GB</small>
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mobile-hidden" *ngIf="company && (company.files || company.files === 0)">
        <div class="card card-stats">
          <div class="card-header card-header-green card-header-icon">
            <div class="card-icon">
              <mat-icon>folder</mat-icon>
            </div>
            <p class="card-category">{{'dashboard.files' | translate}}</p>
            <p class="card-title">{{company.files}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mobile-hidden" *ngIf="company && (company.customers || company.customers === 0)">
        <div class="card card-stats">
          <div class="card-header card-header-light-blue card-header-icon">
            <div class="card-icon">
              <mat-icon>person</mat-icon>
            </div>
            <p class="card-category">{{'dashboard.customers' | translate}}</p>
            <p class="card-title">{{company.customers}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mobile-hidden" *ngIf="company && (company.employees || company.employees === 0)">
        <div class="card card-stats">
          <div class="card-header card-header-light-green card-header-icon">
            <div class="card-icon">
              <mat-icon>group</mat-icon>
            </div>
            <p class="card-category">{{ 'dashboard.employees' | translate}}</p>
            <p class="card-title">{{company.employees}}/{{company.maxEmployees}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="height: 100%; margin-top: 20px;">
      <div class="col-md-offset-1 col-md-10" style="height: 100%;" *ngIf="user">
        <app-emma-select
          [renderButton]="false"
          [options]="[
            {displayName: 'dashboard.my_tasks' | translate, value: 1},
            {displayName: 'dashboard.recent' | translate, value: 2}
          ]"
          [(ngModel)]="view"
          #select>
          <div button style="display: flex; align-items: center;" (click)="select.toggleMenu()">
            <h2 class="emma-title" style="margin-top: 10px; margin-bottom: 10px;">{{view === 1 ? ('dashboard.my_tasks' | translate) : ('dashboard.recent' | translate)}}</h2>
            <ng-icon style="margin-left: 10px" class="ng-icon-default" name="heroChevronDown"></ng-icon>
          </div>
        </app-emma-select>
        <div class="card" style="height: 70%;">
          <app-user-recents #recents [company]="currentCompany" [user]="user" style="height: 100%; margin-top: -1px;" [view]="view"></app-user-recents>
        </div>
      </div>
    </div>
  </div>
</div>
