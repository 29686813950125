import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { CompanyService } from '../../../services/company/company.service';
import { Company } from '../../../functional/models/company';
import { Subscription } from 'rxjs';
import { Role } from '../../../functional/models/role.model';
import {RedactedUser, User} from 'app/functional/models/user';
import {TagColor} from '../../../components/tag/tag.component';
import {CreateCustomerDialogComponent} from '../../../dialogs/dossiers/create-customer-dialog/create-customer-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConfirmDialogComponent} from '../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogType} from '../../../functional/models/dialog.model';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatSort) set content(content: any) {
    if (content && this.dataSource) {
      this.dataSource.sort = content;
      this.dataSource.paginator = this.paginator;
    }
  }

  colors = TagColor;
  displayedColumns: string[] = ['name', 'address', 'role'];
  dataSource!: MatTableDataSource<User | RedactedUser>;
  employees: (User | RedactedUser)[] = [];
  role = Role.customer;
  companySubscription = new Subscription();
  company?: Company;

  constructor(
    public router: Router,
    public userService: UserService,
    public companyService: CompanyService,
    private dialog: MatDialog
  ) {
    this.role = userService.tryClaims().role;
  }

  async ngOnInit() {
    if (this.role === Role.superuser || this.role === Role.admin) {
      this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
        if (company) {
          this.company = company;
        } else {
          this.company = await this.companyService.findCurrentCompany();
        }

        this.employees = [
          ...(await this.companyService.getEmployees(this.company.id)),
          ...(await this.companyService.getAdmins(this.company.id))
        ];
        for (const employee of this.employees) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/dot-notation
          employee['name'] = employee.firstName + ' ' + employee.lastName;
        }
        this.dataSource = new MatTableDataSource(this.employees);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    } else {
      this.employees = await this.userService.getEmployees();
      for (const employee of this.employees) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/dot-notation
        employee['name'] = employee.firstName + ' ' + employee.lastName;
      }

      this.dataSource = new MatTableDataSource(this.employees);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnDestroy(): void {
    if (!!this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }

  redirect(id: number) {
    this.router.navigate(['management', 'employees', 'details', id]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getFistLetters(firstName: any, lastName: any) {
    return firstName.charAt(0) + lastName.charAt(0);
  }

  async handleClick(event: string) {
    if (event === 'new'){
      const meta = await this.companyService.getMeta(this.company!.id);
      if (meta.maxEmployees <= meta.employees) {
        this.dialog.open(ConfirmDialogComponent,{
          panelClass: 'confirm-dialog',
          data: {
            dialogType: ConfirmDialogType.employeeLimitReached
          },
          autoFocus: false
        });
        return;
      }

      const dialogRefx = this.dialog.open(CreateCustomerDialogComponent, {
        panelClass: 'create-customer-dialog',
        data: {
          company: this.company,
          permissionsSelectable: false,
          isEmployee: true
        },
        autoFocus: false
      });

      dialogRefx.afterClosed().subscribe((res: any) => {
        if (res && res.data) {
          this.employees.push(res.data);
          this.dataSource.data = [...this.employees];
        }
      });
    }
  }
}

