import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChatComponent} from './chat.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {TranslateModule} from '@ngx-translate/core';
import {MatBadgeModule} from '@angular/material/badge';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {LinkyModule} from 'ngx-linky';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {EmmaSelectModule} from '../base-components/emma-select/emma-select.module';
import {EmmaTooltipModule} from '../base-components/emma-tooltip/emma-tooltip.module';
import { NgIconsModule } from '@ng-icons/core';
import {heroArchiveBox, heroChatBubbleLeftEllipsis, heroChatBubbleLeftRight} from '@ng-icons/heroicons/outline';

@NgModule({
  declarations: [
    ChatComponent
  ],
  exports: [
    ChatComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    TranslateModule.forRoot(),
    MatBadgeModule,
    FormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgIconsModule.withIcons({
      heroChatBubbleLeftRight,
      heroChatBubbleLeftEllipsis,
      heroArchiveBox
    }),
    LinkyModule,
    TextFieldModule,
    MatInputModule,
    EmmaSelectModule,
    EmmaTooltipModule
  ]
})
export class ChatModule { }
