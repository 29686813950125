import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Organization, NewOrganization } from 'app/functional/models/organization.model';
import { environment } from 'environments/environment';
import {RedactedUser} from '../../functional/models/user';
import {Dossier} from '../../functional/models/dossier.model';
import {Role} from '../../functional/models/role.model';
import { Folder } from 'app/functional/models/folder';

export class FilterOptions {
  companyId?: number;
  dossierId?: number;
  userId?: number;
  kvk?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private url = `${environment.rootUrl}organization`;

  constructor(private http: HttpClient) { }

  create(newOrganization: NewOrganization): Promise<Organization> {
    return this.http.post<Organization>(this.url, newOrganization).toPromise();
  }

  filter(options: FilterOptions): Promise<Organization[]> {
    let params = new HttpParams();
    if (options.companyId) {
      params = params.set('company_id', options.companyId.toString());
    }
    if (options.dossierId) {
      params = params.set('dossier_id', options.dossierId.toString());
    }
    if (options.userId) {
      params = params.set('user_id', options.userId.toString());
    }
    if (options.kvk) {
      params = params.set('kvk', options.kvk.toString());
    }
    return this.http.get<Organization[]>(this.url, { params }).toPromise();
  }

  getOrganization(organizationId: number): Promise<Organization> {
    return this.http.get<Organization>(`${this.url}/${organizationId}`).toPromise();
  }


  deleteOrganization(organizationId: number) {
    return this.http.delete(`${this.url}/${organizationId}`).toPromise();
  }

  update(organization: Organization): Promise<Organization> {
    return this.http.put<Organization>(`${this.url}/${organization.id}`, organization).toPromise();
  }

  getUsers(organizationId: number, role?: Role): Promise<RedactedUser[]> {
    if (role) {
      const roleString = JSON.stringify(role);
      return this.http.get<RedactedUser[]>(`${this.url}/${organizationId}/users?role=${roleString}`).toPromise();
    }
    return this.http.get<RedactedUser[]>(`${this.url}/${organizationId}/users`).toPromise();
  }

  addUser(organizationId: number, userId: number): Promise<void> {
    return this.http.post<void>(`${this.url}/${organizationId}/users`, {userId}).toPromise();
  }

  delete(organizationId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${organizationId}`).toPromise();
  }

  removeUser(organizationId: number, userId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${organizationId}/users/${userId}`).toPromise();
  }

  getDossiers(organizationId: number): Promise<Dossier[]> {
    return this.http.get<Dossier[]>(`${this.url}/${organizationId}/dossiers`).toPromise();
  }

  addDossier(organizationId: number, dossierId: number): Promise<void> {
    return this.http.post<void>(`${this.url}/${organizationId}/dossiers`, {dossierId}).toPromise();
  }

  removeDossier(organizationId: number, dossierId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${organizationId}/dossiers/${dossierId}`).toPromise();
  }

  addFolder(organizationId: number, folderId: number): Promise<void> {
    return this.http.post<void>(`${this.url}/${organizationId}/folder`, { folderId }).toPromise();
  }

  removeFolder(organizationId: number, folderId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${organizationId}/folder/${folderId}`).toPromise();
  }

  getFolders(organizationId: number): Promise<Folder[]> {
    return this.http.get<Folder[]>(`${this.url}/${organizationId}/folder`).toPromise();
  }
}
