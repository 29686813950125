<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="customer-information-dialog-container">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{ 'dialog.info.customer' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <p>{{ 'dialog.info.customer_dossier' | translate }}</p>
    </div>
  </div>
  <div class="row centered" *ngFor="let customer of dossier.customers">
    <div class="col-md-3"><b>{{customer.firstName + ' ' + customer.lastName}}</b></div>
    <div class="col-md-3">{{customer.email}}</div>
    <div class="col-md-3">{{customer.phone}}</div>
  </div>
</div>
