import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NewDossier, Dossier, DossierFolderModel, EditDossier, DossierEditFolderModel } from 'app/functional/models/dossier.model';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../user/user.service';
import { Role } from '../../functional/models/role.model';
import { Company } from '../../functional/models/company';
import { CompanyService } from '../company/company.service';

export class FilterDossier {
  userId?: number;
  companyId?: number;
  organizationId?: number;
  initial = true;
}

export class Actor {
  employee!: boolean;
  customer!: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DossierService {
  dossiers: BehaviorSubject<Dossier[]> = new BehaviorSubject<Dossier[]>([]);
  private url = `${environment.rootUrl}dossier`;
  private company!: Company;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private companyService: CompanyService
  ) {
    this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
        await this.initializeDossiers();
      } else {
        this.company = await this.companyService.findCurrentCompany();
        await this.initializeDossiers();
      }
    });
  }

  async initializeDossiers() {
    const claims = this.userService.tryClaims();
    if (claims.role === Role.superuser || claims.role === Role.admin) {
      this.initialize({ companyId: this.company.id, initial: true });
    } else if (claims.role === Role.employee) {
      this.initialize({ userId: claims.userId, initial: true });
    } else if (claims.role === Role.customer) {
      this.initialize({ userId: claims.userId, initial: false});
    }
  }

  async initialize(filter: FilterDossier): Promise<Dossier[]> {
    let urlParams = new HttpParams();

    if (filter.userId) {
      urlParams = urlParams.set('user_id', JSON.stringify(filter.userId));
    }

    if (filter.companyId) {
      urlParams = urlParams.set('company_id', JSON.stringify(filter.companyId));
    }

    if (!filter.initial) {
      urlParams = urlParams.set('initial', JSON.stringify(filter.initial));
    }

    const dossiers = await this.http.get<Dossier[]>(this.url, { params: urlParams }).toPromise();
    this.dossiers.next(dossiers);
    return dossiers;
  }

  filter(filter: FilterDossier): Dossier[] {
    let filtered = this.dossiers.getValue();
    filtered = filtered.filter(item => item.dossierState !== null);

    if (filter.userId){
      filtered = filtered.filter(item => [...item.customers, ...item.employees].some(fItem => fItem.id === filter.userId));
    }

    if (filter.organizationId){
      filtered = filtered.filter(item => item.organizations.some(fItem => fItem.id === filter.organizationId));
    }

    if (filter.initial){
      return filtered;
    } else {
      return filtered.filter(item => !item.dossierState.initialState);
    }
  }

  async create(newDossier: NewDossier): Promise<Dossier> {
    const dossier = await this.http.post<Dossier>(this.url, newDossier).toPromise();
    this.dossiers.next(this.dossiers.getValue().concat([dossier]));
    return dossier;
  }

  async edit(dossierId: number, model: EditDossier): Promise<Dossier> {
    const dossier = await this.http.put<Dossier>(`${this.url}/${dossierId}`, model).toPromise();
    const dossiers = this.dossiers.getValue().filter(item => Number(item.id) !== Number(dossierId));
    this.dossiers.next(dossiers.concat([dossier]));
    return dossier;
  }

  async get(dossierId: number): Promise<Dossier> {
    const dossier = await this.http.get<Dossier>(`${this.url}/${dossierId}`).toPromise();
    const dossiers = this.dossiers.getValue().filter(lDossier => Number(lDossier.id) !== Number(dossierId));
    this.dossiers.next(dossiers.concat([dossier]));
    return dossier;
  }

  async delete(dossierId: number) {
    const dossier = await this.http.delete(`${this.url}/${dossierId}`).toPromise();
    this.dossiers.next(this.dossiers.getValue().filter(item => Number(item.id) !== Number(dossierId)));
    return dossier;
  }

  async advance(dossierId: number, actionId: number): Promise<Dossier> {
    await this.http.post<void>(`${this.url}/${dossierId}/advance`, {
      actionId,
    }).toPromise();

    return await this.get(dossierId);
  }

  createFolder(dossierId: number, model: DossierFolderModel) {
    return this.http.post<void>(`${this.url}/${dossierId}/folder`, model).toPromise();
  }

  editFolder(dossierId: number, folderId: number, model: DossierEditFolderModel) {
    return this.http.put<void>(`${this.url}/${dossierId}/folder/${folderId}`, model).toPromise();
  }
}
