<div class="card" *ngIf="!!companyId" id="ftp-settings-block">
  <div class="card-header card-header-grey card-header-icon">
    <div class="card-icon card-header-custom"><mat-icon>sync_alt</mat-icon></div>
    <div class="exchange-card-title-between">
      <h4 class="card-title">
        {{'settings.ftp_title' | translate}}
      </h4>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="ftpForm" *ngIf="ftpForm">
      <div class="row">
        <div class="col-md-8">
          <div class="emma-input-container">
            <label label for="host" class="emma-input-label">{{'settings.ftp_host'  | translate }}</label>
            <input appEmmaInput id="host" formControlName="host">
          </div>
        </div>
        <div class="col-md-4">
          <div class="emma-input-container">
            <label label for="port" class="emma-input-label">{{ 'settings.ftp_port'  | translate }}</label>
            <input appEmmaInput id="port" formControlName="port">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="emma-input-container">
            <label label for="user" class="emma-input-label">{{'settings.ftp_user' | translate }}</label>
            <input appEmmaInput id="user" formControlName="user">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="emma-input-container">
            <label label for="pass" class="emma-input-label">{{ 'settings.ftp_password'  | translate }}</label>
            <input appEmmaInput id="pass" formControlName="pass" type="password" data-private>
          </div>
        </div>
      </div>
      <button  type="submit" class="btn btn-blue btn-custom pull-right" (click)="submitFtp()">{{'settings.ftp_submit' | translate}}</button>
      <div class="clearfix"></div>
    </form>
  </div>
</div>
