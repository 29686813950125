import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCompaniesComponent } from './search-companies.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {AutocompleteSelectModule} from '../../base-components/autocomplete-select/autocomplete-select.module';

@NgModule({
  declarations: [SearchCompaniesComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    TranslateModule.forRoot(),
    AutocompleteSelectModule,
  ],
  exports: [
    SearchCompaniesComponent,
  ]
})
export class SearchCompaniesModule { }
