import {Component, OnInit, ViewChild} from '@angular/core';
import {CompanyService} from '../../../../services/company/company.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {Router} from '@angular/router';
import { Company } from 'app/functional/models/company';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  companies: Array<Company> = [];
  displayedColumns: string[] = ['id', 'name'];
  dataSource!: MatTableDataSource<Company>;
  needsPackage = false;
  totalCompanies = 0;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatSort) set content(content: any) {
    if (content && this.dataSource) {
      this.dataSource.sort = content;
    }
  }

  constructor(
    private companyService: CompanyService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.companies = await this.companyService.getCompanies();
    for (const company of this.companies) {
      if (company.id !== 1) { // demo.myemma.nl does not make money
        ++this.totalCompanies;
      }
    }
    this.filterCompanies();
  }

  redirect(id: number) {
    this.router.navigate(['management', 'companies', 'details', id]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterCompanies() {
    let filteredCompanies: Company[];
    if (this.needsPackage) {
      filteredCompanies = this.companies.filter(company => company.packages!.length !== 0);
    } else {
      filteredCompanies = this.companies;
    }
    this.dataSource = new MatTableDataSource(filteredCompanies);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  createBusiness($event: any) {
    this.router.navigate(['management', 'companies', 'create']);
  }
}
