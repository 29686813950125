import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../../services/company/company.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Company } from '../../../functional/models/company';
import { MfaService } from '../../../services/mfa-service/mfa.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  company!: Company;
  returnUrl!: string;
  counter = 0;
  reason?: number;
  isMobile!: boolean;
  loginForm!: UntypedFormGroup;

  constructor(
      public userService: UserService,
      public mfaService: MfaService,
      public router: Router,
      public companyService: CompanyService,
      public snackBar: MatSnackBar,
      public translate: TranslateService,
      private activeRoute: ActivatedRoute,
      private formBuilder: UntypedFormBuilder
  ) {
    this.returnUrl = this.activeRoute.snapshot.queryParams.returnUrl || 'dashboard';
    this.loginForm = this.formBuilder.group({
      remember: [false, [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
    this.isMobile = window.innerWidth <= 991;
  }

  async ngOnInit() {
    this.isMobile = window.innerWidth <= 991;
    this.company = await this.companyService.findCurrentCompany();
  }

  async login() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid){
      return;
    }

    if (this.company.mfaRequired) {
      if (this.loginForm.get('remember')?.value) {
        await this.mfaService.challenge({
          email: this.loginForm.get('email')?.value,
          password: this.loginForm.get('password')?.value,
          companyId: this.company.id,
          channel: 1
        });
      } else {
        await this.mfaService.challenge({
          email: this.loginForm.get('email')?.value,
          password: this.loginForm.get('password')?.value,
          companyId: this.company.id,
          exp: 14400,
          channel: 1
        });
      }
      const returnUrl = this.returnUrl;
      await this.router.navigate(['auth', 'authenticator'], { queryParams: { returnUrl } });
    } else {
      if (this.loginForm.get('remember')?.value) {
        await this.userService.login({
          email: this.loginForm.get('email')?.value,
          password: this.loginForm.get('password')?.value,
          companyId: this.company.id,
          channel: 1
        });
      } else {
        await this.userService.login({
          email: this.loginForm.get('email')?.value,
          password: this.loginForm.get('password')?.value,
          companyId: this.company.id,
          duration: 14400,
          channel: 1
        });
      }
      await this.router.navigate([this.returnUrl]);
    }
  }
}
