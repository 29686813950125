import { Component, DoCheck, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Document } from '../../../functional/models/document';
import { Upload } from '../../../functional/models/upload';
import { Folder } from '../../../functional/models/folder';
import { CompanyService } from '../../../services/company/company.service';
import { Company } from 'app/functional/models/company';
import { CssParameterService } from 'app/services/css-parameter/css-parameter.service';
import { CssParameter, NewCssParameter } from 'app/functional/models/css-parameter.model';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit, OnDestroy, DoCheck {
  configuredParameters: CssParameter[] = [];

  image = this.getDemoDocument('.png');
  pdf = this.getDemoDocument('.pdf');
  mp4 = this.getDemoDocument('.mp4');
  word = this.getDemoDocument('.docx');
  powerpoint = this.getDemoDocument('.pps');
  excel = this.getDemoDocument('.xls');
  publisher = this.getDemoDocument('.pub');
  exe = this.getDemoDocument('.exe');
  other = this.getDemoDocument('.zip');
  folder = this.getDemoFolder();

  upload = new Upload('Upload.png', 1, undefined, 1);
  upload2 = new Upload('Upload2.exe',1, undefined, 1);
  documents: Document[] = [this.image, this.other, this.mp4, this.pdf, this.word, this.powerpoint, this.excel, this.publisher, this.exe];
  uploads: Upload[] = [this.upload, this.upload2];

  output = 'hsl';

  primary = 'hsla(201, 74%, 47%, 1)';
  secondary = 'hsla(0, 0%, 60%, 1)';

  boxColorStart = '';
  boxColorFinish = '';
  boxShadowStart = '';
  boxShadowFinish = '';
  boxTitleColor = '';
  boxSubtitleColor = '';

  boxSecondaryColorStart = '';
  boxSecondaryColorFinish = '';
  boxSecondaryShadowStart = '';
  boxSecondaryShadowFinish = '';
  boxSecondaryTitleColor = '';
  boxSecondarySubtitleColor = '';

  buttonBackgroundColor = '';
  buttonBorderColor = '';
  buttonTextColor = '';
  buttonShadowStart = '';
  buttonShadowMiddle = '';
  buttonShadowFinish = '';
  folderColor = 'rgba(137, 215, 248, 1)';
  defaultFileColor = 'rgba(55, 58, 68, 1)';

  company?: Company;
  companySubscription!: Subscription;

  constructor(
    private companyService: CompanyService,
    private cssParameterService: CssParameterService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.calculatePrimaryColors(this.primary);
    this.calculateSecondaryColors(this.secondary);
  }


  calculateSecondaryColors(event: string) {
    if (event) {
      const colors = event.match(/\d+/g)!.map(Number);

      if (colors[0] > 45 && colors[0] < 200) {
        if (colors[2] > 60) {
          this.boxSecondaryTitleColor = 'hsla(0, 0%, 0%, 1)';
          this.boxSecondarySubtitleColor = 'hsla(0, 0%, 0%, 0.62)';
        } else {
          this.boxSecondaryTitleColor = 'hsla(0, 100%, 100%, 1)';
          this.boxSecondarySubtitleColor = 'hsla(0, 100%, 100%, 0.62)';
        }
      } else {
        if (colors[2] > 75) {
          this.boxSecondaryTitleColor = 'hsla(0, 0%, 0%, 1)';
          this.boxSecondarySubtitleColor = 'hsla(0, 0%, 0%, 0.62)';
        } else {
          this.boxSecondaryTitleColor = 'hsla(0, 100%, 100%, 1)';
          this.boxSecondarySubtitleColor = 'hsla(0, 100%, 100%, 0.62)';
        }
      }

      this.boxSecondaryColorStart = event;
      this.boxSecondaryColorFinish = event;
      this.boxSecondaryShadowStart = `hsla(0, 100%, 100%, 0.14)`;
      this.boxSecondaryShadowFinish = `hsla(${colors[0]}, ${colors[1]}%, ${colors[2]}%, 0.4)`;
    }
  }

  calculatePrimaryColors(event: string) {
    if (event) {
      const colors = event.match(/\d+/g)!.map(Number);
      if (colors[0] > 45 && colors[0] < 200) {
        if (colors[2] > 60) {
          this.boxTitleColor = 'hsla(0, 0%, 0%, 1)';
          this.boxSubtitleColor = 'hsla(0, 0%, 0%, 0.62)';
          this.buttonTextColor = 'hsla(0, 0%, 0%, 1)';
        } else {
          this.boxTitleColor = 'hsla(0, 100%, 100%, 1)';
          this.boxSubtitleColor = 'hsla(0, 100%, 100%, 0.62)';
          this.buttonTextColor = 'hsla(0, 100%, 100%, 1)';
        }
      } else {
        if (colors[2] > 75) {
          this.boxTitleColor = 'hsla(0, 0%, 0%, 1)';
          this.boxSubtitleColor = 'hsla(0, 0%, 0%, 0.62)';
          this.buttonTextColor = 'hsla(0, 0%, 0%, 1)';
        } else {
          this.boxTitleColor = 'hsla(0, 100%, 100%, 1)';
          this.boxSubtitleColor = 'hsla(0, 100%, 100%, 0.62)';
          this.buttonTextColor = 'hsla(0, 100%, 100%, 1)';
        }
      }

      this.boxColorStart = event;
      this.boxColorFinish = event;
      this.boxShadowStart = `hsla(0, 100%, 100%, 0.14)`;
      this.boxShadowFinish = `hsla(${colors[0]}, ${colors[1]}%, ${colors[2]}%, 0.4)`;

      this.buttonBackgroundColor = event;
      this.buttonBorderColor = event;
      this.buttonShadowStart = `hsla(${colors[0]}, ${colors[1]}%, ${colors[2]}%, 0.14)`;
      this.buttonShadowMiddle = `hsla(${colors[0]}, ${colors[1]}%, ${colors[2]}%, 0.2)`;
      this.buttonShadowFinish = `hsla(${colors[0]}, ${colors[1]}%, ${colors[2]}%, 0.12)`;
    }
  }

  async ngOnInit() {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company| null) => {
      this.setDemoDefaults();
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }

      await this.setParams();

      if (this.configuredParameters.length !== 0) {
        this.setDemo();
      }
    });
  }

  async setParams() {
    this.configuredParameters = await this.cssParameterService.readByCompany(this.company!.id);
  }

  ngOnDestroy(): void {
    if (!!this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }

  getValueIfDefined(name: string): string | null {
    const elem = this.configuredParameters.find(row => row.name === name);
    return elem ? elem.value : null;
  }

  ngDoCheck() {
    const el = document.querySelector('.folder') as HTMLElement;
    const el2 = document.querySelectorAll('.primary');
    if (el) {
      el.style.color = this.folderColor;
    }
    if (el2 && el2.length > 0) {
      for (const index of Object.keys(el2)) {
        const element = el2[+index] as HTMLElement;
        if (element && element.style) {
          element.style.color = this.defaultFileColor;
        }
      }
    }
  }

  setDemoDefaults() {
    this.primary = 'hsla(201, 74%, 47%, 1)';
    this.secondary = 'hsla(0, 0%, 60%, 1)';
    this.calculatePrimaryColors(this.primary);
    this.calculateSecondaryColors(this.secondary);
  }

  async updateTheme() {
    if (!this.company) {
      return;
    }
    await this.resetTheme(false);
    await this.setParams();
    const vars = [
      ['primary', this.primary],
      ['secondary', this.secondary],

      [ 'boxColorPrimaryStart', this.boxColorStart ],
      [ 'boxColorPrimaryFinish', this.boxColorFinish ],
      [ 'boxShadowPrimaryStart', this.boxShadowStart ],
      [ 'boxShadowPrimaryFinish', this.boxShadowFinish ],
      [ 'boxTitlePrimaryColor', this.boxTitleColor ],
      [ 'boxSubtitlePrimaryColor', this.boxSubtitleColor ],

      [ 'boxColorSecondaryStart', this.boxSecondaryColorStart ],
      [ 'boxColorSecondaryFinish', this.boxSecondaryColorFinish ],
      [ 'boxShadowSecondaryStart', this.boxSecondaryShadowStart ],
      [ 'boxShadowSecondaryFinish', this.boxSecondaryShadowFinish ],
      [ 'boxTitleSecondaryColor', this.boxSecondaryTitleColor ],
      [ 'boxSubtitleSecondaryColor', this.boxSecondarySubtitleColor ],

      [ 'buttonBackgroundColor', this.buttonBackgroundColor ],
      [ 'buttonBorderColor', this.buttonBorderColor ],
      [ 'buttonTextColor', this.buttonTextColor ],
      [ 'buttonShadowStart', this.buttonShadowStart ],
      [ 'buttonShadowMiddle', this.buttonShadowMiddle ],
      [ 'buttonShadowFinish', this.buttonShadowFinish ],
      [ 'folderColor', this.folderColor],
      [ 'defaultFileColor', this.defaultFileColor]
    ];
    const toCreate: NewCssParameter[] = [];
    const toUpdate: CssParameter[] = [];
    for (const variable of vars) {
      const primaryKey = this.getPK(variable[0]);
      if (primaryKey) {
        const cssParameter = {
          id: primaryKey,
          name: variable[0],
          value: variable[1],
          companyId: this.company.id,
        };
        toUpdate.push(cssParameter);
      } else {
        const newCssParameter = {
          name: variable[0],
          value: variable[1],
          companyId: this.company.id,
        };
        toCreate.push(newCssParameter);
      }
    }
    this.configuredParameters = this.configuredParameters.concat(await this.cssParameterService.bulkCreate(toCreate));
    this.configuredParameters = this.configuredParameters.concat(await this.cssParameterService.bulkUpdate(toUpdate));
    this.snackBar.open(this.translate.instant('theme.adjusted'), this.translate.instant('close'), { duration: 5000 });
  }

  async resetTheme(message: boolean) {
    if (!this.company) {
      return;
    }
    try {
      await this.cssParameterService.deleteByCompany(this.company.id);
      this.configuredParameters = [];
      if (message) {
        this.snackBar.open(this.translate.instant('theme.reverted'), this.translate.instant('close'), {duration: 5000});
      }
    } catch { }
  }

  private getPK(varName: string): number | undefined {
    if (this.configuredParameters.length === 0) {
      return undefined;
    }
    return this.configuredParameters.find(param => param.name === varName)!.id;
  }

  private getDemoDocument(extension: string): Document {
    return {
      id: 1,
      name: 'demo' + extension,
      description: 'a demo ' + extension + ' file',
      parentFolder: {companyId: 0, creationDate: new Date(), description: '', id: 0, name: 'Test'},
      companyId: 1,
      size: 0,
      extension,
      creationDate: new Date().toUTCString(),
    };
  }

  private getDemoFolder(): Folder {
    return {
      id: 1,
      name: 'demo',
      description: 'a demo folder',
      companyId: 1,
      creationDate: new Date(),
      labels: [],
    };
  }

  private setDemo() {
    this.primary  = this.getValueIfDefined('primary') || this.primary;
    this.secondary = this.getValueIfDefined('secondary') || this.secondary;

    this.calculatePrimaryColors(this.primary);
    this.calculateSecondaryColors(this.secondary);
  }
}
