<div class="emma-input-container">
  <label label for="search-kvk" class="emma-input-label"  >{{'search.kvk'  | translate }}</label>
  <div class="emma-input">
    <input appEmmaInput id="search-kvk" (keyup.enter)="search($event)" [formControl]="kvkForm">
    <ng-icon class="ng-icon-default" name="heroMagnifyingGlass" (click)="search($event)"></ng-icon>
  </div>
  <span class="emma-input-error" *ngIf="kvkForm.touched && kvkForm.invalid && kvkForm.hasError('notfound')">
    {{ 'kvk.notfound' | translate }}
  </span>
  <span class="emma-input-error" *ngIf="kvkForm.touched && kvkForm.invalid && kvkForm.hasError('minlength')">
    {{ 'kvk.minlength' | translate }}
  </span>
  <span class="emma-input-error" *ngIf="kvkForm.touched && kvkForm.invalid && kvkForm.hasError('maxlength')">
    {{ 'kvk.maxlength' | translate }}
  </span>
  <span class="emma-input-error" *ngIf="kvkForm.touched && kvkForm.invalid && kvkForm.hasError('pattern')">
    {{ 'kvk.pattern' | translate }}
  </span>
  <span class="emma-input-error" *ngIf="kvkForm.touched && kvkForm.invalid && kvkForm.hasError('required')">
    {{ 'kvk.required' | translate }}
  </span>
</div>
