<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div [@.disabled]="disableAnimation" class="create-customer-dialog dialog-content" *ngIf="userForm">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title" *ngIf="!isEmployee">{{ 'users.create.dialog_title' | translate }}</p>
      <p class="emma-title" *ngIf="isEmployee">{{ 'users.create.employee_title' | translate }}</p>
    </div>
  </div>
  <form [formGroup]="userForm">
    <div class="row">
      <div class="col-md-6">
        <div class="emma-input-container">
          <label label for="firstName" class="emma-input-label">{{ 'users.create.firstname'  | translate }}</label>
          <input appEmmaInput id="firstName" formControlName="firstName">
        </div>
      </div>
      <div class="col-md-6">
        <div class="emma-input-container">
          <label label for="lastName" class="emma-input-label">{{ 'users.create.lastname'  | translate }}</label>
          <input appEmmaInput id="lastName" formControlName="lastName">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="emma-input-container">
          <label label for="email" class="emma-input-label">{{ 'users.create.email'  | translate }}</label>
          <input appEmmaInput id="email" formControlName="email">
        </div>
      </div>
      <div class="col-md-6">
        <div class="emma-input-container">
          <label label for="phone" class="emma-input-label">{{ 'users.create.phone'  | translate }}</label>
          <input appEmmaInput id="phone" formControlName="phone">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="emma-input-container">
          <label label for="gender" class="emma-input-label">{{'users.create.gender'  | translate }}</label>
          <app-emma-select
            id="gender"
            layout="input"
            [fullWidth]="true"
            [renderButton]="true"
            [options]="[
                          {displayName: 'users.create.undefined' | translate, value: 0},
                          {displayName: 'users.create.male' | translate, value: 1},
                          {displayName: 'users.create.female' | translate, value: 2},
                          {displayName: 'users.create.notapplicable' | translate, value: 9},
                        ]"
            formControlName="gender">
          </app-emma-select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="emma-input-container">
          <label label for="birth" class="emma-input-label">{{'users.create.birthdate'  | translate }}</label>
          <div class="emma-input">
            <input id="birth" appEmmaInput formControlName="birth" [matDatepicker]="picker">
            <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
          </div>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="permissionsSelectable">
      <div class="col-md-12">
        <div mat-subheader><b>{{'dossier.rights.title' | translate}}</b></div>
      </div>
    </div>
    <mat-radio-group class="row" [(ngModel)]="selectedPermissions" *ngIf="permissionsSelectable">
      <div class="col-md-6">
        <mat-radio-button [value]="1">{{'dossier.rights.rwa' | translate}}</mat-radio-button>
      </div>
      <div class="col-md-6">
        <mat-radio-button [value]="2">{{'dossier.rights.r' | translate}}</mat-radio-button>
      </div>
    </mat-radio-group>
    <mat-accordion>
      <mat-expansion-panel [expanded]="!permissionsSelectable">
        <mat-expansion-panel-header class="no-padding">
          <mat-panel-title>
            {{'users.create.address' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-md-6">
            <div class="emma-input-container">
              <label label for="street" class="emma-input-label">{{'dialog.create.street' | translate }}</label>
              <input appEmmaInput id="street" formControlName="street">
            </div>
          </div>
          <div class="col-md-3">
            <div class="emma-input-container">
              <label label for="houseNumber" class="emma-input-label">{{'dialog.create.house_number' | translate }}</label>
              <input appEmmaInput id="houseNumber" formControlName="houseNumber">
            </div>
          </div>
          <div class="col-md-3">
            <div class="emma-input-container">
              <label label for="premise" class="emma-input-label">{{'dialog.create.addon' | translate }}</label>
              <input appEmmaInput id="premise" formControlName="premise">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="emma-input-container">
              <label label for="postalCode" class="emma-input-label">{{'dialog.create.postalcode' | translate }}</label>
              <input appEmmaInput id="postalCode" formControlName="postalCode">
            </div>
          </div>
          <div class="col-md-6">
            <div class="emma-input-container">
              <label label for="city" class="emma-input-label">{{'dialog.create.residence' | translate }}</label>
              <input appEmmaInput id="city" formControlName="city">
            </div>
          </div>
        </div>
        <div class="row" style="display: flex">
          <div class="col-md-6">
            <div class="emma-input-container">
              <label label for="relationId" class="emma-input-label">{{'dialog.create.relationId' | translate }}</label>
              <input appEmmaInput id="relationId" formControlName="relationId">
            </div>
          </div>
          <div class="col-md-6 col-center">
            <div class="col-centered-reverse">
              <mat-label>{{'users.create.sendmail' | translate}}</mat-label>
              <mat-checkbox formControlName="sendMail"></mat-checkbox>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
  <div class="row">
    <div class="col-md-12 centered extra-margin">
      <button  class="btn btn-blue btn-custom" (click)="create()">
        <b *ngIf="!isEmployee">{{'users.create.create' | translate}}</b>
        <b *ngIf="isEmployee">{{'users.create.create_employee' | translate}}</b>
        <ng-icon class="ng-icon-default-size" name="heroPlusSmall"></ng-icon>
      </button>
    </div>
  </div>
</div>
