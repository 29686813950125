<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="file-tree-dialog">
  <div class="row margin-30">
    <div class="col-md-12 centered">
      <p class="emma-title">{{ type === 'clone' ? ('dialog.tree.clone' | translate) : ('dialog.tree.move' | translate)}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-font-light">{{'dialog.tree.destination' | translate}}</p>
    </div>
  </div>

  <div style="height: 72%; overflow: auto">
    <app-recursive-tree [sources]="selectedFolders" [fileSource]="source" (selectedChange)="setSelected($event)" [multiSelect]="multi"></app-recursive-tree>
  </div>

  <div class="row margin-top-50">
    <div class="col-md-12 margin-30 centered">
      <button  class="btn btn-blue btn-custom" (click)="submit()">
        <b>{{type === 'clone' ? ('dialog.tree.submit_clone' | translate) : ('dialog.tree.submit_move' | translate) }}</b>

      </button>
    </div>
  </div>
</div>
