import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SigningService } from 'app/services/signing/signing.service';
import { ExplorerItem } from '../../../functional/models/explorer.model';
import { Role } from '../../../functional/models/role.model';
import { RedactedUser, RedactedUserWithPermissions } from '../../../functional/models/user';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../services/company/company.service';

@Component({
  selector: 'app-request-signing-dialog',
  templateUrl: './request-signing-dialog.component.html',
  styleUrls: ['./request-signing-dialog.component.scss']
})
export class RequestSigningDialogComponent implements OnInit {
  explorerItems!: ExplorerItem[];
  signUsers!: RedactedUserWithPermissions[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Role = Role;
  constructor(
    public dialogRef: MatDialogRef<RequestSigningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private signingService: SigningService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private companyService: CompanyService
  ) {
    this.explorerItems = data.explorerItem;
    this.signUsers = this.explorerItems[0].users!.filter(user => user.role === Role.admin || user.permissions.includes('r'));
    this.signUsers = this.explorerItems[0].users!.filter((user, iterator, a) => a.findIndex(t => (t.id === user.id)) === iterator);
  }

  async ngOnInit() {
    const admins: RedactedUserWithPermissions[] = (await this.companyService.getAdmins(this.data.company.id)).map((user: RedactedUser) =>
      ({...user, permissions: 'rwcdm'}));
    this.signUsers = [...this.signUsers, ...admins];
  }

  async submit() {
    for (const item of this.explorerItems) {
      for (const user of this.signUsers) {
        if (!item.signings || !item.signings.find(signing => signing.userId === user.id)) {
          // @ts-ignore
          if (user.signed || user.signed === 0) {
            await this.signingService.create({
              fileId: Number(item.file!.id),
              userId: user.id,
              message: '',
              // @ts-ignore
              signingType: user.signed,
            });
          }
        }
      }
      this.dialogRef.close({confirmed: true});
      this.snackBar.open(this.translate.instant('signature_dialog.requested'), this.translate.instant('close'), { duration: 5000 });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
