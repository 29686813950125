import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import { UserDetailsComponent } from './user-details/user-details.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import localeNDSNL from '@angular/common/locales/nds-NL';
import {ExchangeModule} from '../../exchange/exchange.module';
import {StartLoggingDialogComponent} from '../../../dialogs/start-logging-dialog/start-logging-dialog.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {ConnectedGroupsComponent} from '../../../components/connected-groups/connected-groups.component';
import {SubNavModule} from '../../../components/sub-nav/sub-nav.module';
import {OrganizationModule} from '../../../components/organization/organization.module';
import {DossierOverviewModule} from '../../../components/dossiers/dossier-overview/dossier-overview.module';
import {ExchangeOverviewModule} from '../../../components/exchange/exchange-overview/exchange-overview.module';
import {CreateBusinessCustomerDialogModule} from
    '../../../dialogs/dossiers/create-business-customer-dialog/create-business-customer-dialog.module';
import {UserIconModule} from '../../../components/user-icon/user-icon.module';
import {CustomerDetailsModule} from '../../../components/customer-details/customer-details.module';
import {NgIconsModule} from '@ng-icons/core';
import {ConnectedUsersModule} from '../../../components/connected-users/connected-users.module';
import {AddGroupDialogModule} from '../../../dialogs/add-group-dialog/add-group-dialog.module';
import {EmmaInputModule} from '../../../components/base-components/emma-input/emma-input.module';
import {EmmaSelectModule} from '../../../components/base-components/emma-select/emma-select.module';
registerLocaleData(localeNDSNL);

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        BrowserAnimationsModule,
        MatSortModule,
        MatPaginatorModule,
        FormsModule,
        MatSelectModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        TranslateModule.forRoot(),
        MatMenuModule,
        ExchangeModule,
        MatCheckboxModule,
        SubNavModule,
        OrganizationModule,
        DossierOverviewModule,
        ExchangeOverviewModule,
        CreateBusinessCustomerDialogModule,
        UserIconModule,
        CustomerDetailsModule,
        NgIconsModule,
        ConnectedUsersModule,
        AddGroupDialogModule,
        EmmaInputModule,
        EmmaSelectModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'nds-NL' },
        DatePipe
    ],
    declarations: [
        UserDetailsComponent,
        StartLoggingDialogComponent,
        ConnectedGroupsComponent
    ],
    exports: [
        UserDetailsComponent
    ]
})
export class UsersModule { }
