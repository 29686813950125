<div class="emma-body" style="padding-top: 55px; background-color: white">
  <app-sub-nav
    [buttons]="[
      {icon:'edit', name: 'viewer.sign_cap' | translate, event: 'sign', class: 'btn-green', hidden: !toSign || toSign?.signDate || toSign?.rejectionDate || toSign?.signType !== 0},
      {icon:'clear', name: 'viewer.reject' | translate, event: 'deny', class: 'btn-red', hidden: !toSign || toSign?.signDate || toSign?.rejectionDate},
      {icon:'check', name: 'viewer.approve_cap' | translate, event: 'approve', class: 'btn-green', hidden: !toSign || toSign?.signDate  || toSign?.rejectionDate || toSign?.signType !== 1},
      {icon:'download', name: 'viewer.download' | translate, event: 'download', hidden: document === null || noAccess}
    ]"
    [back]="true"
    (buttonClicked)="handleClick($event, downloadLink)"
  ></app-sub-nav>

  <ng-container *ngIf="displayUrl != null && document != null">
    <iframe [src]='displayUrl | safe' class="fill-screen" frameborder='0' *ngIf="!isImage() && !isPDF()" data-private></iframe>
    <iframe [src]='documentUrl| safe' class="fill-screen" frameborder='0' *ngIf="!isImage() && isPDF()" data-private></iframe>
    <img [src]="documentUrl" class="image fill-screen" *ngIf="isImage() && !isPDF()" data-private>
  </ng-container>
  <div class="illustration" *ngIf="noAccess">
    <img src="assets/img/illustrations/business.svg">
    <h1 class="not-found-title">{{'viewer.no_access_title'|translate}}</h1>
    <span>
      {{'viewer.no_access_description' | translate}}
    </span>
  </div>
  <a style="display: none" #downloadLink [href]="downloadUrl"></a>
</div>
