import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { CompanyService } from '../../../services/company/company.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  email?: string;
  companyId!: number;
  isMobile!: boolean;
  forgotForm: UntypedFormGroup;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
    this.isMobile = window.innerWidth <= 991;
  }

  async ngOnInit(): Promise<void> {
    this.isMobile = window.innerWidth <= 991;
    const company = await this.companyService.findCurrentCompany();
    this.companyId = company.id;
  }

  async forgot() {
    this.forgotForm.markAllAsTouched();
    if (this.forgotForm.invalid){
      return;
    }
    try {
      await this.userService.forgot({
        email: this.forgotForm.get('email')?.value,
        companyId: this.companyId,
      });
      this.snackBar.open(this.translate.instant('auth.forgot.success'), this.translate.instant('close'), {
        duration: 5000,
      });
    } catch { }
  }
}
