import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchUsersComponent} from './search-users.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {AutocompleteSelectModule} from '../../base-components/autocomplete-select/autocomplete-select.module';

@NgModule({
  declarations: [
    SearchUsersComponent
  ],
  imports: [
    CommonModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forRoot(),
    AutocompleteSelectModule,
    FormsModule,
  ],
  exports: [
    SearchUsersComponent
  ]
})
export class SearchUsersModule { }
