import {Component, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export class EmmaSelectOption {
  displayName!: string;
  value!: any;
  postfixIcon?: string;
  hidden?: boolean;
  searchObject?: any;
}

@Component({
  selector: 'app-emma-select',
  templateUrl: './emma-select.component.html',
  styleUrls: ['./emma-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmmaSelectComponent),
      multi: true
    }
  ]
})
export class EmmaSelectComponent implements ControlValueAccessor {
  @ViewChild('dropdownMenu') insideElement!: any;

  @Input() prefix?: string;
  @Input() options: EmmaSelectOption[] = [];
  @Input() settings: EmmaSelectOption[] = [];
  @Input() title?: string;
  @Input() renderIconOnly = false;
  @Input() renderButton = true;
  @Input() fullWidth = false;
  @Input() noResults = '';
  @Input() placeholder = '';
  @Input() layout: 'input' | 'rounded' = 'rounded';
  @Input() lSelected: any;
  @Input() customStyle = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();

  showMenu = false;
  disabled = false;

  @HostListener('document:click', ['$event']) clickedOutside(event: MouseEvent){
    if (this.showMenu && !this.insideElement.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }

  onChange = (_: any) => {};

  get selected(): any {
    return this.lSelected;
  }

  set selected(selected: any) {
    this.lSelected = selected;
    this.onChange(this.lSelected);
    this.change.emit(this.lSelected);
  }

  setSelected(item: EmmaSelectOption) {
    this.selected = item.value;
    this.showMenu = false;
  }

  getSelected(value: number) {
    return this.options.find(item => item.value === value);
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(val: any): void {
    if (val !== undefined) {
      this.lSelected = val;
    }
  }
}
