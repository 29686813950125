import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {UserService} from '../../../services/user/user.service';
import {UpdateUserForm, User} from 'app/functional/models/user';
import {CompanyService} from '../../../services/company/company.service';
import {Company} from '../../../functional/models/company';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {NavigationTab} from '../../../components/sub-nav/sub-nav.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit {
  id?: string;
  passwordStrength = 0;
  company!: Company;

  user!: User;
  profileForm!: UntypedFormGroup;
  formGroup!: UntypedFormGroup;
  activeTab!: string;

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private companyService: CompanyService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    @Inject(DOCUMENT) public currentDocument: HTMLDocument,
  ) {
    this.formGroup = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirm: ['', [Validators.required, this.passMatch()]]
    });
  }

  async ngOnInit() {
    this.company = await this.companyService.findCurrentCompany();
    this.user = await this.userService.getUser();

    this.profileForm = this.formBuilder.group({
      email: [this.user.email, [Validators.required, Validators.email]],
      phone: [this.user.phone, []],
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      role: [this.user.role, [Validators.required]],
      gender: [this.user.gender || 0, []],
      birth: [this.user.birth, []],
      street: [this.user.street, []],
      houseNumber: [this.user.houseNumber, []],
      premise: [this.user.premise, []],
      postalCode: [this.user.postalCode, []],
      city: [this.user.city, []],
      description: [this.user.description, []],
      relationId: [this.user.relationId, []],
      language: [this.user.language, []],
      companyId: [this.user.companyId, []],
      fcm: [this.user.fcm, []],
      desktopFcm: [this.user.desktopFcm, []],
    });
  }

  async updateProfile() {
    this.profileForm.markAllAsTouched();
    if (this.profileForm.invalid){
      return;
    }

    const usr = this.profileForm.value as UpdateUserForm;
    usr.birth = this.datePipe.transform(usr.birth, 'yyyy-MM-dd');

    const newUser = await this.userService.editUser(Number(this.user.id),  usr);

    this.snackBar.open(this.translate.instant('success'), '', {
      duration: 2000,
    });

    if (this.user.language !== this.profileForm.get('language')?.value) {
      if (this.profileForm.get('language')?.value){
        if (this.company && this.company.companyType >= 1) {
          this.translate.use(`${this.company.companyType}_${this.profileForm.get('language')?.value}`);
        } else {
          this.translate.use(this.profileForm.get('language')?.value);
        }
        this.currentDocument.documentElement.lang = this.profileForm.get('language')?.value;
      }
    }

    this.user = newUser;
  }

  passMatch(): ValidatorFn  {
    return (control: AbstractControl): ({ [key: string]: boolean } | null) => {
      if (!this.formGroup){
        return null;
      }

      const passMatch = this.formGroup.get('password')?.value === this.formGroup.get('confirm')?.value;
      if (passMatch) {
        return null;
      }
      return {invalidMatch: true};
    };
  }

  async changePass() {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid || this.passwordStrength !== 100) {
      return;
    }

    await this.userService.reset({
      newPassword: this.formGroup.get('password')!.value,
    });

    this.snackBar.open(this.translate.instant('success'), '', {
      duration: 2000,
    });
  }

  onStrengthChanged(e: number) {
    this.passwordStrength = e;
  }

  async setActive(activatedTab: NavigationTab) {
    this.activeTab = activatedTab.event;
  }
}
