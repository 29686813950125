<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="new-chat-dialog">
  <div class="scrollable">
    <div class="row">
      <div class="col-md-12 centered extra-margin-top">
        <p class="emma-title title-padding"> {{ 'chat.new' | translate }}</p>
      </div>
    </div>
    <form [formGroup]="chatForm">
      <div class="row">
        <div class="col-md-12">
          <div class="emma-input-container">
            <label label for="name" class="emma-input-label">{{ 'chat.name_placeholder'  | translate }}</label>
            <input appEmmaInput id="name" formControlName="name">
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-12">
        <p class="emma-subtitle subtitle-alt" style="margin-bottom: 5px"> {{ 'chat.customer' | translate }} </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-search-companies-and-customers id="search-customer-or-company" [company]="company" (valueChange)="onSelect($event?.value)" *ngIf="selectedUsers.length === 0 && !selectedOrganization"></app-search-companies-and-customers>
        <app-search-customers [company]="company" (valueChange)="onSelect($event?.value)" *ngIf="selectedUsers.length > 0" #customerSearch></app-search-customers>
      </div>
    </div>
    <div class="row" *ngIf="selectedOrganization" style="width: 100%">
      <div class="col-md-10 col-sm-10">
        <p class="emma-font-light">{{selectedOrganization.name}}</p>
      </div>
      <div class="col-sm-2 col-md-2">
        <mat-icon class="emma-font-light delete" (click)="removeOrganization()">delete</mat-icon>
      </div>
    </div>
    <ng-container *ngIf="selectedUsers.length > 0">
      <div class="row" *ngFor="let selectedUser of selectedUsers">
        <div class="col-md-10 col-sm-10">
          <p class="emma-font-light">{{selectedUser.firstName + ' ' + selectedUser.lastName}}</p>
        </div>
        <div class="col-sm-2 col-md-2">
          <mat-icon class="emma-font-light delete" (click)="removeUser(selectedUser.id)">delete</mat-icon>
        </div>
      </div>
    </ng-container>

    <div class="row" *ngIf="selectedOrganization || selectedUsers.length === 1">
      <div class="col-md-12">
        <p class="emma-subtitle1"> {{ 'chat.subject' | translate }} </p>
      </div>
    </div>
    <mat-radio-group [(ngModel)]="questionType" class="row" *ngIf="selectedOrganization || selectedUsers.length === 1" style="margin-bottom: 5px" id="question-type">
      <div class="col-md-6 col-sm-6">
        <mat-radio-button [value]="1" id="general-question"> {{ 'chat.general_question' | translate }} </mat-radio-button>
      </div>
      <div class="col-md-6 col-sm-6">
        <mat-radio-button [value]="2" id="dossier-question"> {{ 'chat.specific_dossier' | translate }} </mat-radio-button>
      </div>
    </mat-radio-group>

    <div class="row" *ngIf="questionType === 2 && !selectedDossier">
      <div class="col-md-12">
        <app-search-dossiers [company]="company" [userId]="selectedUsers[0].id" (valueChange)="setDossier($event?.value)" *ngIf="selectedUsers.length === 1"></app-search-dossiers>
        <app-search-dossiers [company]="company" [organizationId]="selectedOrganization.id" (valueChange)="setDossier($event?.value)" *ngIf="selectedOrganization"></app-search-dossiers>
      </div>
    </div>

    <div class="row" *ngIf="questionType === 2 && selectedDossier">
      <div class="col-md-12">
        <p class="emma-subtitle1"> {{ 'chat.dossier' | translate }} </p>
      </div>
    </div>
    <div class="row" *ngIf="questionType === 2 && selectedDossier">
      <div class="col-sm-10 col-md-10">
        <p class="emma-font-light">{{selectedDossier.name}}</p>
      </div>
      <div class="col-sm-2 col-md-2">
        <p class="emma-font-light">
          <mat-icon class="delete" (click)="removeDossier()">delete</mat-icon>
        </p>
      </div>
    </div>

    <div class="row" *ngIf="selectedOrganization">
      <div class="col-md-12">
        <p class="emma-subtitle1"> {{ 'chat.contact_persons' | translate }} </p>
      </div>
    </div>
    <div class="row" *ngIf="selectedOrganization">
      <div class="col-md-12">
        <ng-container *ngFor="let userSelection of organizationUsers">
          <mat-checkbox [(ngModel)]="userSelection[1]">
            {{userSelection[0].firstName + ' ' + userSelection[0].lastName}}
          </mat-checkbox><br/>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <p class="emma-subtitle subtitle-alt"> {{ 'chat.colleagues' | translate }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <app-search-employees id="search-employees" (valueChange)="addColleagues($event?.value)" [company]="company" #colleagueSearch *ngIf="company"></app-search-employees>
      </div>
    </div>

    <div class="row" *ngFor="let colleague of colleagues; let first = first;">
      <div class="col-sm-10 col-md-10" *ngIf="claims.userId !== colleague.id">
        <p class="emma-font-light">
          {{colleague.firstName + ' ' + colleague.lastName}}
        </p>
      </div>
      <div class="col-sm-10 col-md-10" *ngIf="claims.userId === colleague.id">
        <p class="emma-font-light">
          U
        </p>
      </div>
      <div class="col-sm-2 col-md-2" *ngIf="claims.userId !== colleague.id">
        <p class="emma-font-light">
          <mat-icon class="delete" (click)="removeColleague(colleague.id)">delete</mat-icon>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-centered">
        <button mat-button class="btn btn-blue btn-custom" (click)="createChat()" id="create-chat"> {{ 'chat.create_chat' | translate }} </button>
      </div>
    </div>
  </div>
</div>
