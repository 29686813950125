import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Company} from '../../../functional/models/company';
import {ChatModel} from '../../../functional/models/chat.model';
import {Claims, RedactedUser} from '../../../functional/models/user';
import {SearchEmployeesComponent} from '../../../components/search/search-employees/search-employees.component';
import {Organization} from '../../../functional/models/organization.model';
import {Role} from '../../../functional/models/role.model';
import {OrganizationService} from '../../../services/organization/organization.service';
import {UserService} from '../../../services/user/user.service';
import {SearchCustomersComponent} from '../../../components/search/search-customers/search-customers.component';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ChatService} from '../../../services/chat/chat.service';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../services/utils/utils.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-chat-user-dialog',
  templateUrl: './add-chat-user-dialog.component.html',
  styleUrls: ['./add-chat-user-dialog.component.scss']
})
export class AddChatUserDialogComponent implements OnInit {
  @ViewChild('colleagueSearch') colleagueSearch!: SearchEmployeesComponent;
  @ViewChild('customerSearch') customerSearch!: SearchCustomersComponent;

  company!: Company;
  chat!: ChatModel;
  colleagues: RedactedUser[] = [];
  organizationUsers: [RedactedUser, boolean][] = [];
  selectedUsers: RedactedUser[] = [];
  claims!: Claims;
  selectedOrganization!: Organization | null;
  chatForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddChatUserDialogComponent>,
    public organizationService: OrganizationService,
    public userService: UserService,
    public snackBar: MatSnackBar,
    public chatService: ChatService,
    private translate: TranslateService,
    private utils: UtilsService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.claims = this.userService.tryClaims();
    this.company = this.data.company;
    this.chat = this.data.chat;
    this.colleagues = this.chat.users.filter((user) => user.role !== Role.customer);

    this.selectedOrganization = this.chat.organization;

    this.chatForm = this.formBuilder.group({
      name: [this.chat?.name || '', [Validators.required]]
    });
  }

  async ngOnInit() {
    if (this.selectedOrganization) {
      const organizationUsers = (await this.organizationService.getUsers(this.selectedOrganization.id!, Role.customer));
        this.organizationUsers =
          organizationUsers.map((user) => [user, !!this.chat.users.find((usr) => usr.id === user.id)]
        );
    } else {
      this.selectedUsers = this.chat.users.filter((user) => user.role === Role.customer);
    }
  }

  addColleagues(event: any) {
    if (event) {
      if (!this.colleagues.find((colleague) => colleague.id === event.id)) {
        this.colleagues.push(event);
      }
      this.colleagueSearch.clearSelected();
    }
  }

  removeColleague(id: number) {
    this.colleagues = this.colleagues.filter((colleague) => colleague.id !== id);
  }

  getOrganizationUsersMapped(): number[] {
    const usrs = this.organizationUsers.filter((userSelection) => userSelection[1]);
    return usrs.map((userSelected) => userSelected[0].id);
  }

  getColleaguesMapped(): number[] {
    return this.colleagues.map((colleague) => colleague.id);
  }

  async editChat() {
    this.chatForm.markAllAsTouched();
    if (this.chatForm.invalid){
      return;
    }


    if (!this.selectedOrganization && this.selectedUsers.length === 0 && this.colleagues.length <= 1) {
      this.snackBar.open(this.translate.instant('chat.choose_user'), this.translate.instant('close'), { duration: 5000 });
      return;
    }

    const updatedChat = await this.chatService.update(this.chat.id, this.chatForm.get('name')?.value);

    let selection: RedactedUser[] = [...this.selectedUsers, ...this.colleagues, ...this.organizationUsers.filter(
      (user) => user[1]
    ).map((userMap) => userMap[0])];
    updatedChat.users = this.utils.deepCopy(selection);
    for (const user of this.chat.users) {
      if (!selection.find((usr) => usr.id === user.id)) {
        await this.chatService.removeUser(this.chat.id, user.id);
      } else {
        selection = selection.filter((usr) => usr.id !== user.id);
      }
    }

    for (const user of selection) {
      await this.chatService.addUser(this.chat.id, user.id);
    }

    this.dialogRef.close(updatedChat);
  }

  async onSelect(event: any | undefined) {
    if (event) {
      if (event.hasOwnProperty('name')) {
        this.selectedOrganization = event;
        this.organizationUsers = (await this.organizationService.getUsers(this.selectedOrganization!.id!, Role.customer)).map(
          (user) => [user, true]
        );
      } else {
        this.selectedUsers.push(event);
      }
      if (this.customerSearch) {
        this.customerSearch.clearSelected();
      }
    }
  }

  removeUser(id: number) {
    this.selectedUsers = this.selectedUsers.filter((user) => user.id !== id);
  }

  removeOrganization() {
    this.selectedOrganization = null;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
