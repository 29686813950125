import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierTemplateSelectorComponent } from './dossier-template-selector.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';



@NgModule({
  declarations: [DossierTemplateSelectorComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  exports: [
    DossierTemplateSelectorComponent
  ]
})
export class DossierTemplateSelectorModule { }
