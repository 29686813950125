<ng-container *ngIf="!pathContains('auth') && isAuthenticated()">
  <div class="wrapper">
    <mat-sidenav-container [hasBackdrop]="true" class="page-container">
      <mat-sidenav #nav [mode]="'over'" position="end" [fixedInViewport]="true">
        <app-chat></app-chat>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="sidebar" style="background-color: #f3f4f6;">
          <app-sidebar style="display: flex; flex-flow: column; height: 100%"></app-sidebar>
        </div>
        <div class="main-panel">
          <app-navbar #navbar></app-navbar>
          <button (click)="nav.toggle()" id="chat-button" mat-fab class="chat-button" aria-label="chat button">
            <ng-icon [matBadge]="unread.toString()" matBadgeColor="warn" *ngIf="unread > 0" name="heroChatBubbleOvalLeftEllipsis"></ng-icon>
            <ng-icon *ngIf="unread === 0" name="heroChatBubbleOvalLeftEllipsis"></ng-icon>
          </button>
          <main [style.padding-top.px]="navbar.subMenu.length > 0 ? 56 + 48 : 56">
            <ng-container *ngTemplateOutlet="template"></ng-container>
          </main>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-container>

<ng-container *ngIf="pathContains('auth') && !isAuthenticated()">
  <div class="wrapper">
    <div class="IE-container" *ngIf="isIE()">
      <div class="IE-overlay">
      </div>
      <div class="card IE-message" style="width: 20rem;">
        <div class="card-body">
          <p class="card-text">{{'ierror' | translate}}</p>
        </div>
      </div>
    </div>

    <div class="login-panel">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #template>
  <router-outlet>
  </router-outlet>
</ng-template>

<audio id="beep" src="assets/notification.wav"></audio>
