import { Injectable } from '@angular/core';
import {ChatFilter, ChatFilterModel, ChatMessage, ChatMessageModel, ChatModel, CreateChatModel} from '../../functional/models/chat.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ChatService {
  private url = environment.rootUrl + 'chat';
  private messageUrl = environment.rootUrl + 'chat_message';

  constructor(private http: HttpClient) { }

  getAll(filter: ChatFilter): Promise<ChatModel[]> {
    let params = new HttpParams();
    if (filter.companyId) {
      params = params.set('company_id', filter.companyId.toString());
    }

    if (filter.userId) {
      params = params.set('user_id', filter.userId.toString());
    }

    if (filter.parentUserId) {
      params = params.set('parent_user_id', filter.parentUserId.toString());
    }

    params = params.set('archived', filter.archive.toString());

    return this.http.get<ChatModel[]>(this.url, { params }).toPromise();
  }

  create(model: CreateChatModel): Promise<ChatModel> {
    return this.http.post<ChatModel>(this.url, model).toPromise();
  }

  update(chatId: number, name: string): Promise<ChatModel> {
    return this.http.put<ChatModel>(`${this.url}/${chatId}`, {name}).toPromise();
  }

  removeUser(chatId: number, userId: number) {
    return this.http.delete(`${this.url}/${chatId}/users/${userId}`).toPromise();
  }

  addUser(chatId: number, userId: number) {
    return this.http.post(`${this.url}/${chatId}/users`, { userId }).toPromise();
  }

  getMessages(filters: ChatFilterModel): Promise<ChatMessage[]> {
    let params = new HttpParams();
    if (filters.chatId) {
      params = params.set('chat_id', filters.chatId.toString());
    }
    if (filters.endDate) {
      params = params.set('end_date', filters.endDate.toString());
    }
    if (filters.limit) {
      params = params.set('limit', filters.limit.toString());
    }
    if (filters.offset) {
      params = params.set('offset', filters.offset.toString());
    }
    if (filters.senderId) {
      params = params.set('sender_id', filters.senderId.toString());
    }
    if (filters.startDate) {
      params = params.set('start_date', filters.startDate.toString());
    }
    return this.http.get<ChatMessage[]>(this.messageUrl, {params}).toPromise();
  }

  sendMessage(model: ChatMessageModel): Promise<ChatMessage> {
    return this.http.post<ChatMessage>(this.messageUrl, model).toPromise();
  }

  deleteChat(chatId: number) {
    return this.http.delete(`${this.url}/${chatId}`).toPromise();
  }

  archiveChat(chatId: number) {
    return this.http.delete(`${this.url}/${chatId}/archive`).toPromise();
  }

  setOnline(chatId: number) {
    return this.http.post(`${this.url}/${chatId}/set_online`, {}).toPromise();
  }
}
