import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {CompanyService} from '../../../../services/company/company.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

export class ValidateCompany {
  // tslint:disable-next-line:typedef
  static createValidator(companyService: CompanyService) {
    return async (control: AbstractControl) =>
      (await companyService.existsCompanyUrl(control.value + '.myemmacloud.nl')) ? { companyInvalid: true } : null;
  }
}

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyFormComponent implements OnInit {
  companyForm!: UntypedFormGroup;

  constructor(
    public router: Router,
    public companyService: CompanyService,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.companyForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      diskSpace: [9, [Validators.required]],
      maxCustomers: [10000, [Validators.required]],
      maxEmployees: [1, [Validators.required]],
      companyType: [0, Validators.required],
      domain: ['', [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9]+$'),
        Validators.minLength(3),
        Validators.maxLength(20)
      ], [
        ValidateCompany.createValidator(this.companyService)
      ]],
    });
  }


  async create() {
    this.companyForm.markAllAsTouched();
    if (this.companyForm.invalid) {
      return;
    }
    await this.companyService.createCompany({
      name: this.companyForm.get('name')!.value,
      diskSpace: (this.companyForm.get('diskSpace')!.value * 1000000000),
      fromEmail: this.companyForm.get('domain')!.value + '@myemmacloud.nl',
      maxCustomers: this.companyForm.get('maxCustomers')!.value,
      maxEmployees: this.companyForm.get('maxEmployees')!.value,
      companyType: Number(this.companyForm.get('companyType')!.value),
      urlPrefix: this.companyForm.get('domain')!.value,
    });
    await this.router.navigate(['management', 'companies']);
  }
}

