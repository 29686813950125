import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators} from '@angular/forms';
import {Colors, Criteria} from '../../enum';
import {MatPasswordStrengthValidator} from '../../validator';
import {regExpValidator} from '../../validator/regexp.class';
import {ThemePalette} from '@angular/material/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-password-strength',
  exportAs: 'matPasswordStrength',
  templateUrl: './mat-password-strength.component.html',
  styleUrls: ['./mat-password-strength.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatPasswordStrengthComponent),
      multi: true
    }
  ]
})
export class MatPasswordStrengthComponent implements OnInit, OnChanges, AfterContentChecked, ControlValueAccessor {

  @Input() password!: string;
  @Input() externalError!: boolean;

  @Input() enableLengthRule = true;
  @Input() enableLowerCaseLetterRule = true;
  @Input() enableUpperCaseLetterRule = true;
  @Input() enableDigitRule = true;
  @Input() enableSpecialCharRule = true;

  @Input() min = 8;
  @Input() max = 30;
  @Input() customValidator!: RegExp;

  @Input() warnThreshold = 21;
  @Input() accentThreshold = 81;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onStrengthChanged: EventEmitter<number> = new EventEmitter();

  criteriaMap = new Map<Criteria, RegExp>();

  containAtLeastMinChars!: boolean;
  containAtLeastOneLowerCaseLetter!: boolean;
  containAtLeastOneUpperCaseLetter!: boolean;
  containAtLeastOneDigit!: boolean;
  containAtLeastOneSpecialChar!: boolean;
  containAtCustomChars!: boolean;

  // TO ACCESS VIA CONTENT CHILD
  passwordFormControl: UntypedFormControl = new UntypedFormControl();
  passwordConfirmationFormControl: UntypedFormControl = new UntypedFormControl();

  validatorsArray: ValidatorFn[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Validators?: ValidatorFn | null;
  matPasswordStrengthValidator = new MatPasswordStrengthValidator();

  private pStrength = 0;

  get strength(): number {
    return this.pStrength ? this.pStrength : 0;
  }

  get color(): ThemePalette {

    if (this.pStrength < this.warnThreshold) {
      return Colors.warn;
    } else if (this.pStrength < this.accentThreshold) {
      return Colors.accent;
    } else {
      return Colors.primary;
    }
  }

  propagateChange = (_: any) => {
  };

  ngOnInit(): void {
    this.setRulesAndValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.externalError && changes.externalError.firstChange) || (!changes.password || changes.password.isFirstChange())) {
      return;
    } else if (changes.externalError && changes.externalError.currentValue) {
      return;
    } else if (changes.password.previousValue === changes.password.currentValue && !changes.password.firstChange) {
      this.calculatePasswordStrength();
    } else {
      if (this.password && this.password.length > 0){
        this.calculatePasswordStrength();
      } else {
        this.reset();
      }
    }
  }

  parseCustomValidatorsRegex(value: string | RegExp = this.customValidator) {
    return this.customValidator;
  }

  setRulesAndValidators(): void {
    this.validatorsArray = [];
    this.criteriaMap = new Map<Criteria, RegExp>();
    this.passwordConfirmationFormControl
      .setValidators(
        // @ts-ignore
        Validators.compose([
          Validators.required, this.matPasswordStrengthValidator.confirm(this.password)
        ])
      );
    this.validatorsArray.push(Validators.required);
    if (this.enableLengthRule) {
      this.criteriaMap.set(Criteria.at_least_eight_chars, RegExp(`^.{${this.min},${this.max}}$`));
      this.validatorsArray.push(Validators.minLength(this.min));
      this.validatorsArray.push(Validators.maxLength(this.max));
    }
    if (this.enableLowerCaseLetterRule) {
      this.criteriaMap.set(Criteria.at_least_one_lower_case_char, regExpValidator.lowerCase);
      this.validatorsArray.push(Validators.pattern(regExpValidator.lowerCase));
    }
    if (this.enableUpperCaseLetterRule) {
      this.criteriaMap.set(Criteria.at_least_one_upper_case_char, regExpValidator.upperCase);
      this.validatorsArray.push(Validators.pattern(regExpValidator.upperCase));
    }
    if (this.enableDigitRule) {
      this.criteriaMap.set(Criteria.at_least_one_digit_char, regExpValidator.digit);
      this.validatorsArray.push(Validators.pattern(regExpValidator.digit));
    }
    if (this.enableSpecialCharRule) {
      this.criteriaMap.set(Criteria.at_least_one_special_char, regExpValidator.specialChar);
      this.validatorsArray.push(Validators.pattern(regExpValidator.specialChar));
    }
    if (this.customValidator) {
      this.criteriaMap.set(Criteria.at_custom_chars, this.parseCustomValidatorsRegex()!);
      this.validatorsArray.push(Validators.pattern(this.parseCustomValidatorsRegex()!));
    }

    this.criteriaMap.forEach((value: any, key: string) => {
      // @ts-ignore
      this.validatorsArray.push(this.matPasswordStrengthValidator.validate(key, value));
    });

    this.passwordFormControl.setValidators(Validators.compose([...this.validatorsArray]));
    this.Validators = Validators.compose([...this.validatorsArray]);

  }

  calculatePasswordStrength(): void {
    const requirements: Array<boolean> = [];
    const unit = 100 / this.criteriaMap.size;

    requirements.push(
      this.enableLengthRule ? this.pContainAtLeastMinChars() : false,
      this.enableLowerCaseLetterRule ? this.pContainAtLeastOneLowerCaseLetter() : false,
      this.enableUpperCaseLetterRule ? this.pContainAtLeastOneUpperCaseLetter() : false,
      this.enableDigitRule ? this.pContainAtLeastOneDigit() : false,
      this.enableSpecialCharRule ? this.pContainAtLeastOneSpecialChar() : false,
      this.customValidator ? this.pContainCustomChars() : false
    );

    this.pStrength = requirements.filter(v => v).length * unit;
    this.propagateChange(this.strength);
    this.onStrengthChanged.emit(this.strength);
    this.setRulesAndValidators();
  }

  reset() {
    this.pStrength = 0;
    this.containAtLeastMinChars =
      this.containAtLeastOneLowerCaseLetter =
        this.containAtLeastOneUpperCaseLetter =
          this.containAtLeastOneDigit =
            this.containAtCustomChars =
              this.containAtLeastOneSpecialChar = false;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.pStrength = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  ngAfterContentChecked(): void {
    if (this.password) {
      this.calculatePasswordStrength();
    }
  }

  private pContainAtLeastMinChars(): boolean {
    this.containAtLeastMinChars = this.password.length >= this.min;
    return this.containAtLeastMinChars;
  }

  private pContainAtLeastOneLowerCaseLetter(): boolean {
    this.containAtLeastOneLowerCaseLetter =
      this.criteriaMap
        .get(Criteria.at_least_one_lower_case_char)!
        .test(this.password);
    return this.containAtLeastOneLowerCaseLetter;
  }

  private pContainAtLeastOneUpperCaseLetter(): boolean {
    this.containAtLeastOneUpperCaseLetter =
      this.criteriaMap
        .get(Criteria.at_least_one_upper_case_char)!
        .test(this.password);
    return this.containAtLeastOneUpperCaseLetter;
  }

  private pContainAtLeastOneDigit(): boolean {
    this.containAtLeastOneDigit =
      this.criteriaMap
        .get(Criteria.at_least_one_digit_char)!
        .test(this.password);
    return this.containAtLeastOneDigit;
  }

  private pContainAtLeastOneSpecialChar(): boolean {
    this.containAtLeastOneSpecialChar =
      this.criteriaMap
        .get(Criteria.at_least_one_special_char)!
        .test(this.password);
    return this.containAtLeastOneSpecialChar;
  }

  private pContainCustomChars(): boolean {
    this.containAtCustomChars =
      this.criteriaMap
        .get(Criteria.at_custom_chars)!
        .test(this.password);
    return this.containAtCustomChars;
  }
}
