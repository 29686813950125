<div class="dossier-configuration" *ngIf="thisUser">
  <div class="row">
    <div class="col-md-6">
      <h2 class="emma-subtitle">Dossier naam</h2>
      <div class="emma-input-container">
        <label label for="nameInput" class="emma-input-label">{{'dossier.naam' | translate}}</label>
        <input appEmmaInput id="nameInput" [formControl]="nameForm" (keyup)="dossierName = nameForm?.value">
        <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('required')">
          {{ 'dossier.name_obligated' | translate }}
        </span>
        <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('pattern')">
          {{ 'dossier.name_wrong' | translate}}
        </span>
      </div>

      <mat-chip-list *ngIf="recurring">
        <mat-chip (click)="nameForm.setValue(nameForm.value || '' + ' \{\{hash\}\} ')">{{'dossier_overview.hash' | translate}}</mat-chip>
        <mat-chip (click)="nameForm.setValue(nameForm.value || '' + ' \{\{day\}\} ')">{{'dossier_overview.day' | translate}}</mat-chip>
        <mat-chip (click)="nameForm.setValue(nameForm.value || '' + ' \{\{week\}\} ')">{{'dossier_overview.week' | translate}}</mat-chip>
        <mat-chip (click)="nameForm.setValue(nameForm.value || '' + ' \{\{month\}\} ')">{{'dossier_overview.month' | translate}}</mat-chip>
        <mat-chip (click)="nameForm.setValue(nameForm.value || '' + ' \{\{quarter\}\} ')">{{'dossier_overview.quarter' | translate}}</mat-chip>
        <mat-chip (click)="nameForm.setValue(nameForm.value || '' + ' \{\{year\}\} ')">{{'dossier_overview.year' | translate}}</mat-chip>
      </mat-chip-list>
    </div>
    <div class="col-md-6">
      <h2 class="emma-subtitle">{{'dossier_overview.deadline' | translate}}</h2>
      <p class="emma-font-light" *ngIf="localTemplate.length === 0 && localStates.length === 0">
        {{'dossier_overview.no_deadlines' | translate}}
      </p>
      <p class="emma-font-light" *ngIf="recurring && (localTemplate.length > 0 || localStates.length > 0)">
        {{'dossier_overview.deadlines_subtitle' | translate}}
      </p>

      <div *ngIf="localTemplate.length > 0" id="deadline-inputs">
        <div class="calendar-input" *ngFor="let templateState of localTemplate">
          <ng-container *ngIf="templateState.mayHaveDeadline">
            <div class="emma-input-container" *ngIf="!recurring">
              <label label [for]="pinput" class="emma-input-label">{{templateState.name}}</label>
              <div class="emma-input">
                <input #pinput appEmmaInput [matDatepicker]="picker" [ngModel]="getDeadlineFromState(templateState.id)?.deadlineDays" (dateChange)="setState($event.value, templateState.id)">
                <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
              </div>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <div class="emma-input-container" *ngIf="recurring">
              <label label [for]="pinput" class="emma-input-label">{{ templateState.name }}</label>
              <input appEmmaInput #pinput [ngModel]="getDeadlineFromState(templateState.id)?.deadlineDays" (ngModelChange)="setState(+$event, templateState.id)">
              <span class="emma-input-error" *ngIf="!!pinput.value && (isNotANumber(pinput.value) || pinput.value < 1) ">
                {{ 'dossier.number_positive' | translate }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="localStates.length > 0" id="deadline-inputs-2">
        <div class="calendar-input" *ngFor="let templateState of localStates">
          <!--<ng-container *ngIf="templateState.mayHaveDeadline">--><!-- TODO no way to validate if the deadline can actually be set -->
            <div class="emma-input-container" *ngIf="!recurring">
              <label label [for]="pinput" class="emma-input-label">{{templateState.name}}</label>
              <div class="emma-input">
                <input #pinput appEmmaInput [matDatepicker]="picker" [ngModel]="getDeadlineFromState(templateState.id)?.deadlineDays" (dateChange)="setState($event.value, templateState.id)">
                <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
              </div>
              <mat-datepicker #picker></mat-datepicker>
            </div>

            <div class="emma-input-container" *ngIf="recurring">
              <label label [for]="pinput" class="emma-input-label">{{ templateState.name }}</label>
              <input appEmmaInput #pinput [ngModel]="getDeadlineFromState(templateState.id)?.deadlineDays" (ngModelChange)="setState(+$event, templateState.id)">
              <span class="emma-input-error" *ngIf="!!pinput.value && (isNotANumber(pinput.value) || pinput.value < 1) ">
                {{ 'dossier.number_positive' | translate }}
              </span>
            </div>
          <!--</ng-container>-->
        </div>
      </div>
    </div>
  </div>
</div>
