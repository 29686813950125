import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user/user.service';
import { Dossier, Progress } from 'app/functional/models/dossier.model';
import { DossierService } from 'app/services/dossier/dossier.service';
import { Company } from 'app/functional/models/company';
import { Subscription } from 'rxjs';
import { CompanyService } from 'app/services/company/company.service';
import { Role } from 'app/functional/models/role.model';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Claims, RedactedUser } from '../../../functional/models/user';
import { ZipService } from '../../../services/zip/zip.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from '../../../functional/models/organization.model';
import { ConfirmDeleteDialogComponent, DeleteType } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DossierRecurringService } from '../../../services/dossier-recurring/dossier-recurring.service';
import { RecurringDossier } from '../../../functional/models/dossier-recurring.model';
import { RRule, RRuleSet } from 'rrule';

@Component({
  selector: 'app-dossier-overview',
  templateUrl: './dossier-overview.component.html',
  styleUrls: ['./dossier-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DossierOverviewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() subcompanyFilter!: number;
  @Input() userFilter!: number;
  @Input() set view(view: 'open' | 'closed' | 'all' | 'recurring') {
    this.lView = view;
    if (this.company) {
      this.update();
    }
  };
  @Input() selectable!: boolean;

  @ViewChild(MatSort) sort!: MatSort;

  lView!: 'open' | 'closed' | 'all' | 'recurring';
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<DossierTableLayout> = new MatTableDataSource();
  progress: Record<string, Progress> = {};
  company!: Company;
  companySubscription = new Subscription();
  openDossiers: Dossier[] = [];
  closedDossiers: Dossier[] = [];
  recurringDossiers: RecurringDossier[] = [];
  claims!: Claims;
  selection = new SelectionModel<DossierTableLayout>(true, []);
  filterKeys = '';
  localRole = Role;
  pFilter = '';

  //Set filter to deep search object
  @ViewChild(MatSort) set content(content: any) {
    if (content && this.dataSource) {
      this.dataSource.sort = content;
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = (data, filter: string) => {
        const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
          const term = this.nestedFilterCheck(currentTerm, data, key);
          return term === undefined || term === null ? '' : term + ' ';
        }, '').toLowerCase();
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.includes(transformedFilter);
      };
    }
  }

  @Input() set filter(value: string) {
    this.pFilter = value;
    this.applyFilter(this.pFilter);
  };

  constructor(
    private dossierService: DossierService,
    private userService: UserService,
    private router: Router,
    private companyService: CompanyService,
    private zipService: ZipService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private recurringService: DossierRecurringService,
  ) {}

  async ngOnInit() {
    this.claims = this.userService.tryClaims();
    this.setView();
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
      await this.update();
    });
  }

  nestedFilterCheck(search: string, data: any, key: string) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          const term = this.nestedFilterCheck(search, data[key], k);
          search = term === undefined || term === null ? '' : term + ' ';
        }
      }
    } else {
      search += data[key] === undefined || data[key] === null ? '' : data[key];
    }
    return search;
  }

  setView() {
    const select = [];
    if (this.claims.role !== Role.employee && this.claims.role !== Role.customer) {
      if (this.selectable) {
        select.push('select');
      }
    }

    if (this.lView === 'recurring') {
      this.displayedColumns = [
        ...select,
        'name',
        'organizations',
        'persons',
        'nextOccurrence'];
    } else {
      this.displayedColumns = [
        ...select,
        'name',
        'organizations',
        'persons',
        'lastEvent',
        'customerDeadline',
        'submissionDeadline',
        'progress'];
    }
  }

  async update() {
    const claims = this.userService.tryClaims();
    const userId = claims.userId;
    this.setView();

    if (claims.role !== Role.customer) {
      this.recurringDossiers = await this.recurringService.filter({
        companyId: this.company.id,
        userId: this.userFilter ? this.userFilter : undefined,
        active: true,
      });
    }

    this.dossierService.dossiers.subscribe((lDossiers) => {
      if (this.subcompanyFilter) {
        lDossiers = this.dossierService.filter({ organizationId: this.subcompanyFilter, initial: true});
      } else if (this.userFilter) {
        lDossiers = this.dossierService.filter({userId: this.userFilter, initial: true});
      } else if (claims.role === Role.superuser || claims.role === Role.admin) {
        lDossiers = this.dossierService.filter({ companyId: this.company.id, initial: true });
      } else if (claims.role === Role.employee) {
        lDossiers = this.dossierService.filter({ userId, initial: true });
      } else if (claims.role === Role.customer) {
        lDossiers = this.dossierService.filter({ userId, initial: false });
        // remove those dossiers with only folders to which the user has no access.
        lDossiers = lDossiers.filter(d => d.folders.some((f) => f.permission !== ''));
      } else {
        throw new Error('unexpected role');
      }

      this.openDossiers = [];
      this.closedDossiers = [];
      for (const dossier of lDossiers) {
        this.progress[dossier.id.toString()] =  Dossier.getDossierProgress(dossier);
        if (dossier.dossierState?.finalState) {
          this.closedDossiers.push(dossier);
        } else {
          this.openDossiers.push(dossier);
        }
      }
      if (this.lView === 'open') {
        this.showOpen();
      } else if (this.lView === 'closed'){
        this.showClosed();
      } else if (this.lView === 'all') {
        this.showAll();
      } else if (this.lView === 'recurring') {
        this.showRecurring();
      }
    });
  }

  async redirect(dossier: DossierTableLayout) {
    if (dossier.isRecurring) {
      await this.router.navigate(['dossier', 'recurring', 'edit', dossier.id]);
    } else {
      await this.router.navigate(['dossier', 'details', dossier.id]);
    }
  }

  /**
   * Retrieves the deadline of the provided dossier
   *
   * @param dossier The dossier whose deadline is to be determined
   * @param final If this is `true`, this function will find the deadline of the final state. If
   * this is `false`, this function will find the deadline of the next state.
   */

  getDeadline(dossier: Dossier, final: boolean): Date | undefined {
    if (final) {
      const finalState = dossier.states.find(dossierState => dossierState.finalState);
      if (finalState?.deadline) {
        return new Date(finalState.deadline);
      }
      return undefined; // assume the final state exists
    }
    const deadlineState = Dossier.orderStates(dossier).find(dossierState => !dossierState.finalState && dossierState.deadline);
    return deadlineState?.deadline ? new Date(deadlineState.deadline) : undefined;
  }

  getProgress(dossier: Dossier): Progress {
    return this.progress[dossier.id.toString()];
  }

  getNextOccurrence(rrule: string) {
    const occurrences =  this.stringToRRule(rrule).all((d, l) => l < 1);
    if (occurrences.length > 0){
      return occurrences[0];
    }
    return undefined;
  }

  stringToRRule(rrule: string) {
    const rruleItems = rrule.split(/\r?\n/);
    const rule = RRule.fromString(rruleItems[0] + '\n' + rruleItems[1]);
    const ruleSet = new RRuleSet();

    ruleSet.rrule(rule);
    for (const lrule of rruleItems) {
      if (lrule.includes('EXDATE')){
        const dates = lrule.split(':')[1].split(',');
        for (const date of dates) {
          ruleSet.exdate(this.timestampToDate(date));
        }
      }
    }
    return ruleSet;
  }

  timestampToDate(stamp: string) {
    return new Date(stamp.replace(/^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z$/, '$1-$2-$3T$4:$5:$6Z'));
  }

  showRecurring(): void {
    this.selection.clear();
    const dossierOverview: DossierTableLayout[] = this.recurringDossiers.map(dossier => ({
      id: dossier.id,
      name: dossier.name,
      organizations: dossier.organizations,
      customer: dossier.users.filter((item) => item.role === Role.customer),
      nextOccurrence: this.getNextOccurrence(dossier.rrule),
      isRecurring: true
    }));
    this.dataSource = new MatTableDataSource<any>(dossierOverview);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Toggles the DataSource to show the dossiers that are currently open.
   */
  showOpen(): void {
    this.selection.clear();
    const dossierOverview: DossierTableLayout[] = this.openDossiers.map(dossier => ({
        id: dossier.id,
        name: dossier.name,
        organizations: dossier.organizations,
        customer: dossier.customers,
        lastEvent: dossier.lastEvent,
        customerDeadline: this.getDeadline(dossier, false),
        submissionDeadline: this.getDeadline(dossier, true),
        progress: this.getProgress(dossier).current,
        progressOf: this.getProgress(dossier).of,
        isRecurring: false
      }));
    this.dataSource = new MatTableDataSource<any>(dossierOverview);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Toggles the DataSource to show the dossiers that are currently closed.
   */
  showClosed(): void {
    this.selection.clear();
    const dossierOverview: DossierTableLayout[] = this.closedDossiers.map(dossier => ({
        id: dossier.id,
        name: dossier.name,
        organizations: dossier.organizations,
        customer: dossier.customers,
        lastEvent: dossier.lastEvent,
        customerDeadline: this.getDeadline(dossier, false),
        submissionDeadline: this.getDeadline(dossier, true),
        progress: this.getProgress(dossier).current,
        progressOf: this.getProgress(dossier).of,
        isRecurring: false
      }));
    this.dataSource = new MatTableDataSource<any>(dossierOverview);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  showAll(): void {
    this.selection.clear();
    const dossierOverview: DossierTableLayout[] = [...this.closedDossiers, ...this.openDossiers].map(dossier => ({
      id: dossier.id,
      name: dossier.name,
      organizations: dossier.organizations,
      customer: dossier.customers,
      lastEvent: dossier.lastEvent,
      customerDeadline: this.getDeadline(dossier, false),
      submissionDeadline: this.getDeadline(dossier, true),
      progress: this.getProgress(dossier).current,
      progressOf: this.getProgress(dossier).of,
      isRecurring: false
    }));
    this.dataSource = new MatTableDataSource<any>(dossierOverview);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if(this.isAllSelected()){
      this.selection.clear();
    }else{
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }
  }

  checkboxLabel(row?: DossierTableLayout): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  async deleteSelected() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: {
        deleteType: DeleteType.dossier,
        toDelete: this.selection.selected
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (!res) {
        return;
      }
      const toDelete = [];
      for (const selected of this.selection.selected) {
        if (this.lView === 'recurring'){
          toDelete.push(this.recurringService.delete(selected.id));
        } else {
          toDelete.push(this.dossierService.delete(selected.id));
        }
      }
      await Promise.all(toDelete);
      await this.update();
      this.selection.clear();
      this.applyFilter(this.filterKeys);
    });
  }

  async downloadDossiers() {
    const dossierIds: number[] = this.selection.selected.map(item => item.id);
    await this.zipService.createZip({fileIds: [], folderIds: [], dossierIds });
    this.snackbar.open(this.translate.instant('dossier_overview.prepare_files'), '', {duration: 2000});
  }

  getheight(paginator: any) {
    return '55';
  }

  async routeToCustomer(event: MouseEvent, customer: RedactedUser) {
    event.preventDefault();
    event.stopPropagation();
    await this.router.navigate(['management', 'customers', 'details', customer.id]);
  }

  async routeToOrganization(event: MouseEvent, organization: Organization) {
    event.preventDefault();
    event.stopPropagation();
    await this.router.navigate(['management', 'organizations', 'details', organization.id]);
  }
}

export class DossierTableLayout {
  id!: number;
  name!: string;
  organizations!: Organization[];
  customer!: RedactedUser[];
  nextOccurrence?: Date;
  lastEvent?: Date;
  customerDeadline?: Date;
  submissionDeadline?: Date;
  progress?: number;
  progressOf?: number;
  isRecurring!: boolean;
}
