import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss']
})
export class SurveyQuestionComponent {

  @Input() editing!: boolean;
  type = 1;
  questions: string[] = ['yeet'];
  conditional = false;

  constructor() { }

}
