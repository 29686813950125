/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Folder, CreateFolderModel, EditFolderModel, FolderMeta } from '../../functional/models/folder';
import { UserFolder } from 'app/functional/models/user-folder.model';
import {RedactedUser, RedactedUserWithPermissions, User} from 'app/functional/models/user';
import { GroupFolder } from 'app/functional/models/group-folder.model';
import { Document } from 'app/functional/models/document';
import {Organization} from '../../functional/models/organization.model';
import {Group} from '../../functional/models/group';
import {FileRequest} from '../../functional/models/file-request.model';

export class UserPermission {
  userFolder!: UserFolder;
  user!: User;
}

export class GroupPermission {
  groupFolder!: GroupFolder;
  group!: Group;
}

export class AccessorsPermissions {
  users!: RedactedUser[];
  organization!: Organization[];
  groups!: Group[];
}

export class ListResponse {
  folders!: Folder[];
  files!: Document[];
}

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  private url = `${environment.rootUrl}folder`;

  constructor(
    private http: HttpClient
  ) { }

  async create(model: CreateFolderModel): Promise<Folder> {
    return await this.http.post<Folder>(`${this.url}`, model).toPromise();
  }

  get(folderId: number): Promise<Folder> {
    return this.http.get<Folder>(`${this.url}/${folderId}`).toPromise();
  }

  async clone(folderId: number, targetFolderId: number, includeFiles?: boolean) {
    let params = new HttpParams();
    if (includeFiles) {
      params = params.set('include_files', JSON.stringify(includeFiles));
    }
    return await this.http.post<Folder>(`${this.url}/${folderId}/copy`, {targetFolder: targetFolderId}, {params}).toPromise();
  }

  readByCompany(companyId: number): Promise<Folder[]> {
    return this.http.get<Folder[]>(this.url, { params: { company_id: companyId.toString() }}).toPromise();
  }

  async edit(folderId: number, model: EditFolderModel): Promise<Folder> {
    return await this.http.put<Folder>(`${this.url}/${folderId}`, model).toPromise();
  }

  async delete(folderId: number): Promise<void> {
    await this.http.delete<void>(`${this.url}/${folderId}`).toPromise();
    return;
  }

  meta(folderId: number): Promise<FolderMeta> {
    return this.http.get<FolderMeta>(`${this.url}/${folderId}/meta`).toPromise();
  }

  async getChildren(folderId: number, forceReload = false): Promise<ListResponse> {
    return await this.http.get<ListResponse>(`${this.url}/${folderId}/list`).toPromise();
  }

  getUserPermissions(folderId: number): Promise<UserPermission[]> {
    return this.http.get<UserPermission[]>(`${this.url}/${folderId}/user_permission`).toPromise();
  }

  getCurrentUserPermissions(folderId: number): Promise<string> {
    return this.http.get<string>(`${this.url}/${folderId}/permissions`).toPromise();
  }

  getGroupPermissions(folderId: number): Promise<GroupPermission[]> {
    return this.http.get<GroupPermission[]>(`${this.url}/${folderId}/group_permission`).toPromise();
  }

  addUserPermission(folderId: number, userId: number, model: string): Promise<UserFolder> {
    return this.http.post<UserFolder>(`${this.url}/${folderId}/user_permission/${userId}`, {permissions: model}).toPromise();
  }

  editUserPermission(folderId: number, userId: number, model: string): Promise<UserFolder> {
    return this.http.put<UserFolder>(`${this.url}/${folderId}/user_permission/${userId}`, {permissions: model}).toPromise();
  }

  deleteUserPermission(folderId: number, userId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${folderId}/user_permission/${userId}`).toPromise();
  }

  addGroupPermission(folderId: number, groupId: number, model: string): Promise<GroupFolder> {
    return this.http.post<GroupFolder>(`${this.url}/${folderId}/group_permission/${groupId}`, {permissions: model}).toPromise();
  }

  editGroupPermission(folderId: number, groupId: number, model: string): Promise<GroupFolder> {
    return this.http.put<GroupFolder>(`${this.url}/${folderId}/group_permission/${groupId}`, {permissions: model}).toPromise();
  }

  deleteGroupPermission(folderId: number, groupId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${folderId}/group_permission/${groupId}`).toPromise();
  }

  getOrganizations(folderId: number): Promise<Organization[]> {
    return this.http.get<Organization[]>(`${this.url}/${folderId}/organizations`).toPromise();
  }

  getPath(folderId: number): Promise<Folder[]> {
    return this.http.get<Folder[]>(`${this.url}/${folderId}/path`).toPromise();
  }

  isRoot(folder: Folder): boolean {
    return !folder.parentFolder;
  }

  getAccessors(folderId: number): Promise<{users: RedactedUserWithPermissions[]}> {
    return this.http.get<{users: RedactedUserWithPermissions[]}>(`${this.url}/${folderId}/meta`).toPromise();
  }

  childDocuments(folderId: number, label?: string): Promise<Document[]> {
    let params = {};
    if (label) {
      params = { label };
    }
    return this.http.get<Document[]>(`${this.url}/${folderId}/child_files`, { params, }).toPromise();
  }

  childRequestsCascade(folderId: number, label?: string): Promise<FileRequest[]> {
    let params = {};
    if (label) {
      params = { label };
    }
    return this.http.get<FileRequest[]>(`${this.url}/${folderId}/file_requests_cascade`, { params, }).toPromise();
  }

  childDocumentsCascade(folderId: number, label?: string): Promise<Document[]> {
    let params = {};
    if (label) {
      params = { label };
    }
    return this.http.get<Document[]>(`${this.url}/${folderId}/child_files_cascade`, { params, }).toPromise();
  }

  childFolders(folderId: number, label?: string): Promise<Folder[]> {
    let params = {};
    if (label) {
      params = { label };
    }
    return this.http.get<Folder[]>(`${this.url}/${folderId}/child_folders`, { params, }).toPromise();
  }

  childFoldersCascade(folderId: number, label?: string): Promise<Folder[]> {
    let params = {};
    if (label) {
      params = { label };
    }
    return this.http.get<Folder[]>(`${this.url}/${folderId}/child_folders_cascade`, { params, }).toPromise();
  }

  async cloneFolder(folderId: number, targetFolder: number, newName?: string): Promise<Folder> {
    const payload: any = { targetFolder };
    if (newName) {
      payload.newName = newName;
    }

    return await this.http.post<Folder>(`${this.url}/${folderId}/copy`, payload).toPromise();
  }

}
