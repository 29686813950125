import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddonOverviewComponent } from './addon-overview/addon-overview.component';
import { AddonDetailsComponent } from './addon-details/addon-details.component';
import { RouterModule } from '@angular/router';
import { addonRoutes } from './addon.routing';
import { TranslateModule } from '@ngx-translate/core';
import { DialogComponent } from './dialog/dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { SimplicateLabelsDialogComponent } from './addon-details/simplicate-labels-dialog/simplicate-labels-dialog.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ExactLabelsDialogComponent } from './addon-details/exact-labels-dialog/exact-labels-dialog.component';
import {RecursiveTreeModule} from '../../components/recursive-tree/recursive-tree.module';
import {NgIconsModule} from '@ng-icons/core';
import {EmmaInputModule} from '../../components/base-components/emma-input/emma-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    TranslateModule.forRoot(),
    RouterModule.forChild(addonRoutes),
    MatChipsModule,
    RecursiveTreeModule,
    NgIconsModule,
    EmmaInputModule,
  ],
  declarations: [
    AddonOverviewComponent,
    AddonDetailsComponent,
    DialogComponent,
    SimplicateLabelsDialogComponent,
    ExactLabelsDialogComponent,
  ],
})
export class AddonModule { }
