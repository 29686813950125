import {Component, ViewChild} from '@angular/core';
import {OrganizationOverviewComponent} from '../../../../components/organization/organization-overview/organization-overview.component';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent {
  @ViewChild(OrganizationOverviewComponent) organizationOverview!: OrganizationOverviewComponent;
  filter = '';

  constructor() { }

  async createOrganization(event: string) {
      if (event === 'new'){
        await this.organizationOverview.create();
      }
  }
}
