import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmmaSigningOverviewComponent } from './emma-signing-overview.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {TranslateModule} from '@ngx-translate/core';
import {MatSortModule} from '@angular/material/sort';
import {EmmaSelectModule} from '../../base-components/emma-select/emma-select.module';

@NgModule({
  declarations: [
    EmmaSigningOverviewComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    TranslateModule.forRoot(),
    MatSortModule,
    EmmaSelectModule
  ],
  exports: [
    EmmaSigningOverviewComponent
  ]
})
export class EmmaSigningOverviewModule { }
