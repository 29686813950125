<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    (navigationChange)="setActive($event)"
    (buttonClicked)="handleClick($event)"
    [buttons]="buttons"
    [navigation]="[
      {name: 'organization.overview' | translate, event: 'details'},
      {name: 'organization.contacts' | translate, event: 'persons'},
      {name: 'organization.dossiers' | translate, event: 'dossiers'},
      {name: 'organization.files' | translate, event: 'files'},
      {name: 'organization.managers' | translate, event: 'employees'}
    ]">

    <ng-container select>
      <ng-container *ngIf="activeTab === 'details'">
        <span class="no-select"
          *ngIf="claims.role === role.superuser || claims.role === role.admin || claims.role === role.employee"
          (click)="deleteOrganization();">
          {{'users.details.delete_account' | translate}}
        </span>
      </ng-container>

      <ng-container *ngIf="activeTab === 'files'">
        <span class="no-select" *ngIf="claims.role === role.superuser || claims.role === role.admin" (click)="handleClick('new');">
          Nieuwe folder
        </span>
        <span class="no-select" *ngIf="claims.role === role.superuser || claims.role === role.admin" (click)="handleClick('newFromTemplate')">
          Nieuwe folder van sjabloon
        </span>
      </ng-container>
    </ng-container>
  </app-sub-nav>

  <ng-container *ngIf="activeTab === 'details'">
    <div class="organization-form">
      <app-organization-details
        id="details"
        [organizationId]="organizationId"
        [organization]="currentOrganization"
        *ngIf="currentOrganization">
      </app-organization-details>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab === 'employees'">
    <app-connected-users
      #organizationEmployees
      [users]="employees"
      [isRedacted]="true"
      [deleteable]="true"
      [selectable]="false"
      [isCustomer]="false"
      [company]="company"
      (deletedUsers)="deleteUsers($event)"
      >
    </app-connected-users>
  </ng-container>

  <ng-container *ngIf="activeTab === 'persons'">
    <app-connected-users
      #organizationContacts
      [users]="contacts"
      [isRedacted]="true"
      [deleteable]="true"
      [selectable]="false"
      [isCustomer]="true"
      [company]="company"
      (deletedUsers)="deleteUsers($event)">
    </app-connected-users>
  </ng-container>

  <ng-container *ngIf="activeTab === 'dossiers'">
    <app-dossier-overview
      id="overview"
      view="all"
      [subcompanyFilter]="organizationId">
    </app-dossier-overview>
  </ng-container>

  <ng-container *ngIf="activeTab === 'files'">
    <div class="organization-explorer-wrapper">
      <app-sub-nav
        [search]="true"
        [exchangeSubject]="true"
        (searchChange)="exchangeOverview.onSearchChange($event)">
        <div class="exchange-button-holder">
          <app-emma-select
            style="margin-left: 20px"
            name="search-select"
            [options]="[
              {displayName: 'explorer.search.current_folder' | translate, value: 1},
              {displayName: 'explorer.search.sub_folders' | translate, value: 2},
              {displayName: 'explorer.search.all_folders' | translate, value: 3}
            ]"
            [(ngModel)]="searchType"
            [prefix]="('explorer.search.placeholder' | translate) + ':'">
          </app-emma-select>

          <app-emma-select
            style="margin-left: 20px"
            name="sort-select"
            *ngIf="exchangeOverview.view === 'small'"
            [options]="[
              {displayName: 'explorer.sort.name_asc' | translate, value: 1},
              {displayName: 'explorer.sort.name_dsc' | translate, value: 2},
              {displayName: 'explorer.sort.date_asc' | translate, value: 3},
              {displayName: 'explorer.sort.date_dsc' | translate, value: 4},
            ]"
            [(ngModel)]="exchangeOverview.lSortType"
            (change)="exchangeOverview.setSort(exchangeOverview.lSortType)"
            [prefix]="('explorer.sort.placeholder' | translate) + ':'">
          </app-emma-select>

          <app-emma-select
            style="margin-left: 20px"
            name="view-select"
            [title]="'explorer.view.title' | translate"
            [renderIconOnly]="true"
            [options]="[
              {displayName: 'explorer.view.grid' | translate, value: 'small', postfixIcon: 'apps'},
               {displayName: 'explorer.view.list' | translate, value: 'list', postfixIcon: 'list'}
            ]"
            [(ngModel)]="exchangeOverview.view"
            (change)="exchangeOverview.setView(exchangeOverview.view)">
          </app-emma-select>
        </div>
      </app-sub-nav>
      <app-exchange-overview
        id="explorer"
        (breadCrumbChange)="setCrumbs($event)"
        [isUserOverview]="true"
        [organizationId]="organizationId"
        [searchType]="searchType"
        #exchangeOverview>
      </app-exchange-overview>
    </div>
  </ng-container>
</div>

