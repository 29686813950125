import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { UrlSelectorModule } from '../../components/url-selector/url-selector.module';
import { FtpSettingsModule } from '../../components/ftp-settings/ftp-settings.module';
import { CssInputComponentModule } from 'app/components/css-input-component/css-input-component.module';
import { MailTemplatesModule } from './mail-templates/mail-templates.module';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { MailTemplatesComponent } from './general-settings/mail-templates/mail-templates.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MailParametersComponent } from './mail-parameters/mail-parameters.component';
import { ApiKeysComponent } from './api-keys/api-keys.component';
import {TranslateModule} from '@ngx-translate/core';
import { WorkflowEditorComponent } from './workflow-editor/workflow-editor.component';
import { SurveyEditorComponent } from './survey-editor/survey-editor.component';
import {SurveyBuilderModule} from '../../components/survey-builder/survey-builder.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {WorkflowOverviewComponent} from './workflow-overview/workflow-overview.component';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {HammerModule} from '@angular/platform-browser';
import { CsvImportComponent } from './csv-import/csv-import.component';
import {SubNavModule} from '../../components/sub-nav/sub-nav.module';
import {EmmaInputModule} from '../../components/base-components/emma-input/emma-input.module';
import {EmmaTooltipModule} from '../../components/base-components/emma-tooltip/emma-tooltip.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroInformationCircle, heroMagnifyingGlassMinus, heroMagnifyingGlassPlus,
  heroSquares2x2, heroSquaresPlus, heroTrash } from '@ng-icons/heroicons/outline';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    FtpSettingsModule,
    UrlSelectorModule,
    MailTemplatesModule,
    CssInputComponentModule,
    TranslateModule,
    SurveyBuilderModule,
    MatTooltipModule,
    MatCardModule,
    RouterModule,
    MatSidenavModule,
    MatExpansionModule,
    MatCheckboxModule,
    FormsModule,
    HammerModule,
    SubNavModule,
    EmmaInputModule,
    NgIconsModule.withIcons({
      heroSquares2x2,
      heroSquaresPlus,
      heroTrash,
      heroMagnifyingGlassPlus,
      heroMagnifyingGlassMinus,
      heroInformationCircle
    }),
    EmmaTooltipModule
  ],
  declarations: [
    SettingsComponent,
    GeneralSettingsComponent,
    MailTemplatesComponent,
    MailParametersComponent,
    ApiKeysComponent,
    WorkflowEditorComponent,
    SurveyEditorComponent,
    WorkflowOverviewComponent,
    CsvImportComponent,
  ]
})
export class SettingsModule {}
