import { Role } from './role.model';
import {Organization} from './organization.model';

export enum Gender {
  unspecified = 0,
  male = 1,
  female = 2,
  notApplicable = 9,
}

export class UpdateUserForm {
  email!: string;
  firstName!: string;
  lastName!: string;
  role!: Role;
  gender!: Gender;
  postalCode!: string;
  street!: string;
  houseNumber!: string;
  premise!: string;
  city!: string;
  phone!: string;
  birth?: string | null; // not possible to encode in the type system, but this is a date, not a date time
  description?: string;
  fcm?: string;
  desktopFcm?: string;
  companyId!: number;
  relationId?: string;
  language?: string;
}

export class CreateUserForm extends UpdateUserForm {}

export class UserFilterModel {
  companyId?: number;
  role?: number;
  email?: number;
}

export class User extends CreateUserForm {
  id!: number;
  creationDate!: Date;
  isActive!: boolean;
  hasProfilePicture!: boolean;
  language!: string;
  logUntil?: string;
  permissions?: string;
  relationId?: string;

  static default(): User {
    return {
      id: 1,
      email: 'mijn@adres.nl',
      firstName: 'Henk',
      lastName: 'De Boer',
      role: Role.admin,
      gender: Gender.male,
      postalCode: '1234 AB',
      street: 'De Straatweg',
      houseNumber: '1',
      premise: 'A',
      city: 'Dorpstad',
      phone: '+31 6 12 34 45 67',
      birth:'1980-10-12',
      description: 'Een goeie testgebruiker',
      companyId: 1,
      creationDate: new Date('1980-10-12'),
      isActive: true,
      hasProfilePicture: true,
      language: 'nl',
    };
  }

  static default2(): User {
    return {
      id: 1,
      email: 'jouw@adres.nl',
      firstName: 'Gerda',
      lastName: 'De Boerin',
      role: Role.employee,
      gender: Gender.female,
      postalCode: '4321 YZ',
      street: 'De Wegstraat',
      houseNumber: '2',
      premise: '',
      city: 'Dorpstad',
      phone: '+31 6 12 34 45 68',
      birth: '1980-10-12',
      description: 'Een goeie testgebruiker',
      companyId: 1,
      creationDate: new Date('1980-10-12'),
      isActive: true,
      hasProfilePicture: true,
      language: 'nl',
    };
  }
}

export class UserWithMail extends User {
  sendMail!: boolean;
}

export interface AddParentForm {
  parentUserId: number;
}

// the payload of a json web token
export class Claims {
  userId!: number;
  exp!: number;
  issuedAt!: number;
  role!: Role;
  version!: number;
  companyId!: number;
}

export class RedactedUser {
  id!: number;
  firstName!: string;
  lastName!: string;
  email!: string;
  permissions?: string;
  phone!: string;
  role!: number;
}

export class RedactedUserWithPermissions {
  id!: number;
  firstName!: string;
  lastName!: string;
  email!: string;
  phone!: string;
  role!: number;
  permissions!: string;
}

export class UserWithOrganizations extends User {
  organizations?: Organization[];
}
