export enum MailTemplateKind {
  // An activity report is a report of activity that has happened among the child users of an
  // employee. This is as opposed to a storage report, which contains information about the whole
  // company.
  activityReport = 0,
  // An email containing a password reset link.
  forgotPassword = 1,
  // An email notifying the user that they have a new message.
  notificationMessage = 2,
  // An email notifying the user that they have a new signing to fulfill.
  notificationSignature = 3,
  // An email sent to superusers on registering.
  registerSuperuser = 4,
  // An email sent to admins on registering.
  registerAdmin = 5,
  // An email sent to employees on registering.
  registerEmployee = 6,
  // An email sent to customers on registering.
  registerCustomer = 7,
  // An email reminding a customer that they have an unread message.
  reminderMessage = 8,
  // An email reminding a customer that they have an unfulfilled signing.
  reminderSigning = 9,
  // An email sent to admins that they have almost run out of storage.
  storageFull = 10,
  // This is the daily report
  storageReport = 11,
  // A signing is done
  signingDone = 12,
  // All singings in a dossier are done.
  allSigningsDone = 13,
  // A file request was fulfilled.
  fileRequestCompleted = 14,
  // All file requests within a dossier are completed.
  allFileRequestsCompleted = 15,
  // A signing was rejected.
  signingRejected = 16,
  // A dossier was completed.
  dossierCompleted = 17,
  // A dossier has moved to a new state.
  dossierStateUpdated = 18,
  // An email notifying the user that their account has been removed
  deletedAccount = 19,
  // An email sent when user tries to add too many files via email
  emailUploadError = 20,
  // An email sent when user tries to add too many files via email
  downloadReady = 21,
  // A dossier is near it's deadline
  dossierNearDeadline = 22,
  /// Sent when a file request without a dossier is created.
  fileRequestCreated = 23,
/// Sent to remind the user that there is a file request that needs to be fulfilled.
  fileRequestReminder = 24,
/// Sent when a user has not read messages in a chat.
  unreadChatMessage = 25,
}

// export class UpdateMailTemplate {
//   subject!: string;
//   text!: string;
//   html!: string;
//   language!: string;
// }

// export class CreateMailTemplate extends UpdateMailTemplate {
//   kind!: MailTemplateKind;
//   companyId!: number;
// }

// export class MailTemplate extends CreateMailTemplate {
//   id!: number;
// }

// export class MailTemplateModel {
//   image!: string;
//   title!: string;
//   content!: string;
//   listFiles!: boolean;
//   button!: boolean;
//   buttonText?: string;
// }

export class CreateMailTemplate {
  content!: string;
  subject!: string;
  kind!: MailTemplateKind;
}

export type UpdateMailTemplate = CreateMailTemplate;

export class MailTemplate extends CreateMailTemplate {
  id!: number;
  version!: number;
}
