import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Organization} from '../../../functional/models/organization.model';
import {Company} from '../../../functional/models/company';
import {OrganizationService} from '../../../services/organization/organization.service';
import {UserService} from '../../../services/user/user.service';
import {Role} from '../../../functional/models/role.model';
import {RedactedUser, User} from '../../../functional/models/user';
import {EmmaSelectOption} from '../../base-components/emma-select/emma-select.component';

@Component({
  selector: 'app-search-companies-and-customers',
  templateUrl: './search-companies-and-customers.component.html',
  styleUrls: ['./search-companies-and-customers.component.scss']
})
export class SearchCompaniesAndCustomersComponent implements OnInit {
  @Input() company!: Company;
  @Input() preSetOrganization?: Organization[];
  @Input() preSetUser?: (RedactedUser | User)[];
  @Output() valueChange: EventEmitter<EmmaSelectOption | undefined> = new EventEmitter<EmmaSelectOption | undefined>();
  @Input() set value(item: EmmaSelectOption | undefined) {
    this.lValue = item;
    this.valueChange.emit(this.lValue);
  };

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  options: EmmaSelectOption[] = [];
  lValue?: EmmaSelectOption;
  searchable: (Organization | RedactedUser | User)[] = [];

  constructor(
    private organizationService: OrganizationService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    await this.reset();
  }

  async reset() {
    const claims = this.userService.tryClaims();
    if (!this.preSetOrganization) {
      if (claims.role === Role.superuser || claims.role === Role.admin) {
        this.searchable = [...this.searchable, ...(await this.organizationService.filter({ companyId: this.company.id}))];
      } else if (claims.role === Role.employee) {
        this.searchable = [...this.searchable, ...(await this.organizationService.filter({ userId: claims.userId }))];
      }
    } else {
      this.searchable = [...this.searchable, ...this.preSetOrganization];
    }

    if (!this.preSetUser) {
      if (claims.role === Role.superuser || claims.role === Role.admin) {
        this.searchable = [...this.searchable, ...(await this.userService.getCustomers(this.company))];
      } else if (claims.role === Role.employee) {
        this.searchable = [...this.searchable, ...(await this.userService.getUserCustomers(claims.userId))];
      }
    } else {
      this.searchable = [...this.searchable, ...this.preSetUser];
    }

    this.options = this.searchable.map((item: any) => ({
      displayName: item.name || item.firstName + ' ' + item.lastName,
      value: item,
      searchObject: item
    }));
  }

  clearSelected() {
    this.lValue = undefined;
  }

}
