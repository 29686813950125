import { Component, EventEmitter, Output } from '@angular/core';
import { KvkService } from '../../../services/kvk/kvk.service';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-search-kvk',
  templateUrl: './search-kvk.component.html',
  styleUrls: ['./search-kvk.component.scss']
})
export class SearchKvkComponent {
  @Output() companySelected = new EventEmitter();
  kvkForm: UntypedFormControl =  new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(8),
    Validators.pattern(/^[0-9]\d*$/)
  ]);

  constructor(private kvkService: KvkService) {}

  async search(event: any) {
    event.stopPropagation();
    event.preventDefault();

    if (this.kvkForm.invalid){
      this.kvkForm.markAllAsTouched();
      return;
    }
    event.preventDefault();
    const company: any = await this.kvkService.getCompany(this.kvkForm.value);
    if (company.data.items.length > 0) {
      this.companySelected.emit(company);
    } else {
      this.kvkForm.setErrors({notfound: true});
    }
  }
}
