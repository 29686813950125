import { DossierEvent } from './dossier.model';
import {Role} from './role.model';

export class DossierTemplateOverview {
  companyId?: number;
  companyType?: number;
  description!: string;
  id!: number;
  name!: string;
}

export class DossierTemplate {
  id!: number;
  name!: string;
  states!: DossierStateTemplate[];
  folders!: CreateFolderTemplate[];
  fileRequests!: FileRequestTemplate[];

  static orderStates(dossierTemplate: DossierTemplate): DossierStateTemplate[] {
    // find the initial state
    const initial = dossierTemplate
      .states
      .find((state) => state.initialState);
    if (!initial) {
      throw new Error(`No initial state found for dossierTemplate ${dossierTemplate.id}`);
    }
    const result: [DossierStateTemplate] = [initial];
    while (!result[result.length - 1]?.finalState) {
      const currentState = result[result.length - 1];
      // find the happy action
      const happyAction = currentState.actions
        .find((actionTemplate) => actionTemplate.isHappy);
      if (!happyAction) {
        throw new Error(`No no happy action found for state ${currentState.id}`);
      }
      // find the next state
      const nextState = dossierTemplate
        .states
        .find((stateTemplate) => stateTemplate.id === happyAction.destinationStateTemplateId);
      if (!nextState) {
        throw new Error(`No next state found for happy action ${happyAction.id}`);
      }
      result.push(nextState);
    }
    return result;
  }
}

export class CreateDossierTemplate {
  companyId?: number;
  companyType?: number;
  name!: string;
  description!: string;
}

export class CloneDossierTemplate {
  companyId?: number;
  companyType?: number;
  name!: string;
  description!: string;
}

export class DossierStateTemplate {
  id!: number;
  name!: string;
  dossierTemplateId!: number;
  actions!: DossierActionTemplate[];
  initialState!: boolean;
  finalState!: boolean;
  mayHaveDeadline!: boolean;
  permissions?: [number, DossierPermissionTemplate[]][];
}

export class CreateDossierStateTemplate {
  name!: string;
  initialState!: boolean;
  finalState!: boolean;
  mayHaveDeadline!: boolean;
}

export class DossierActionTemplate {
  id!: number;
  name!: string;
  dossierTemplateId!: number;
  dossierStateTemplateId!: number;
  destinationStateTemplateId?: number;
  isHappy!: boolean;
  event!: DossierEvent;
}

export class CreateDossierActionTemplate {
  destinationStateTemplateId?: number;
  event!: DossierEvent;
  isHappy!: boolean;
  name!: string;
}

export class CreateTemplateFolder {
  name!: string;
  description!: string;
  isFinalFolder!: boolean;
}

export class CreateFolderTemplate {
  id!: number;
  name!: string;
  dossierTemplateId!: number;
}

export class TemplateFolder {
  description!: string;
  dossierTemplateId!: number;
  id!: number;
  isFinalFolder!: boolean;
  name!: string;
}

export class FileRequestTemplate {
  id!: number;
  dossierTemplateId!: number;
  folderTemplateId!: number;
  filename!: string;
  message?: string;
  exampleFileId?: number;
}

export class CreateFileRequestTemplate {
  exampleFileId?: number;
  filename!: string;
  folderTemplateId!: number;
  message?: string;
}

export class DossierPermissionTemplatePartial {
  id!: number;
  folderTemplateId!: number;
  role!: Role;
  dossierTemplateId!: number;
  dossierStateTemplateId!: number;
  permissions!: string;
  permissionString?: string;
}

export class DossierPermissionTemplate extends DossierPermissionTemplatePartial {
  permissionString!: string;
}

export class CreateDossierPermissionTemplate {
  folderTemplateId!: number;
  role!: Role;
  permissions!: string;
}


