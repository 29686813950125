import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DossierTemplateService} from '../../../services/dossier-template/dossier-template.service';
import {Company} from '../../../functional/models/company';
import {CompanyService} from '../../../services/company/company.service';

@Component({
  selector: 'app-clone-dossier-template-dialog',
  templateUrl: './clone-dossier-template-dialog.component.html',
  styleUrls: ['./clone-dossier-template-dialog.component.scss']
})
export class CloneDossierTemplateDialogComponent implements OnInit {
  designation = 1;
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  cloneForm!: UntypedFormGroup;

  constructor(
    public templateService: DossierTemplateService,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<CloneDossierTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  async ngOnInit() {
    this.cloneForm = this.formBuilder.group({
      name: [this.data.name || '', [Validators.required]],
      designation: [null, [Validators.required]],
      description: ['', []],
      companyType: [0, []],
      companyId: [null, []],
      filter: ['', []]
    });

    this.companies = await this.companyService.getCompanies();
    this.filteredCompanies = this.companies;
  }
  closeModal() {
    this.dialogRef.close();
  }

  async cloneTemplate() {
    this.cloneForm.markAllAsTouched();

    if (this.cloneForm.invalid || !this.designation) {
      return;
    }

    if (this.designation === 2 && this.cloneForm.get('companyId')?.value === null){
      return;
    }

    let result;

    if (this.designation === 1) {
      result = await this.templateService.clone(this.data.template.id,{
        name: this.cloneForm.get('name')!.value,
        companyType: this.cloneForm.get('companyType')!.value,
        description: this.cloneForm.get('description')?.value || ''
      });
    }

    if (this.designation === 2) {
      result = await this.templateService.clone(this.data.template.id,{
        name: this.cloneForm.get('name')!.value,
        companyId: this.cloneForm.get('companyId')!.value,
        description: this.cloneForm.get('description')?.value || ''
      });
    }

    this.dialogRef.close(result);
  }

  filter(event: string) {
    if (event === ''){
      this.filteredCompanies = this.companies;
    } else {
      this.filteredCompanies = this.companies.filter(item => item.name.toLowerCase().includes(event) || item.id.toString().includes(event));
    }
  }
}
