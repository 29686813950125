import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmmaSelectOption } from '../emma-select/emma-select.component';

@Component({
  selector: 'app-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteSelectComponent),
      multi: true
    }
  ]
})
export class AutocompleteSelectComponent implements ControlValueAccessor {
  @Input() lValue?: EmmaSelectOption;
  @Input() lSelectValue?: any;
  @Input() placeholder = '';
  filteredOptions: EmmaSelectOption[] = [];
  unfilteredOptions: EmmaSelectOption[] = [];
  disabled = false;
  displayVal = '';

  get displayValue(): string {
    return this.displayVal;
  }

  @Input() set displayValue(value: string) {
    this.displayVal = value;
    this.filteredOptions = this.filter(this.unfilteredOptions, this.displayVal);
  }

  set selectValue(val: any) {
    this.lSelectValue = val;
    if (val !== undefined) {
      this.value = this.unfilteredOptions.find(item => item.value === val);
    }
  }

  get selectValue(): any {
    return this.lSelectValue;
  }

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  set value(val: EmmaSelectOption | undefined){
    this.displayValue = val?.displayName || '';
    this.lSelectValue = val?.value || undefined;
    this.lValue = val;
    this.propagateChange(this.lValue);
  }

  @Input() set options(options: EmmaSelectOption[]) {
    this.unfilteredOptions = options;
    this.filteredOptions = this.filter(this.unfilteredOptions, this.displayVal);
  }

  propagateChange = (_: any) => {};

  writeValue(value: EmmaSelectOption | undefined){
    this.value = value;
  }

  registerOnChange(fn: any){
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any){}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  filter(arr: EmmaSelectOption[], searchKey?: string) {
    if (!searchKey){
      return arr;
    }

    const words = searchKey.toLowerCase().split(' ').filter(item => !!item);

    return arr.filter(obj => words.every(word => obj.displayName.toLowerCase().includes(word)) ||
      words.every(word => JSON.stringify(obj.value).toLowerCase().includes(word)) ||
      (!!obj.searchObject && this.hasValueDeep(obj.searchObject, words)));
  };

  hasValueDeep(json: any, findValue: string[]): boolean {
    const values = Object.values(json);
    let hasValue = false;
    for(const value of values) {
      if (typeof value === 'object') {
        if (!!value){
          hasValue = this.hasValueDeep(value, findValue);
        } else {
          return false;
        }
      } else {
        hasValue = values.every(item => findValue.some(toFind => JSON.stringify(item).toLowerCase().includes(toFind)));
      }
      if (hasValue){
        break;
      }
    }
    return hasValue;
  }
}
