<div class="dossier-customer-selector" style="height: 100%;">
  <div class="row" style="height: 100%;">
    <div class="col-md-6" style="height: 100%;">
      <h4 class="emma-subtitle">{{ 'dossier.dossier_customers' | translate }}</h4>
      <app-search-companies-and-customers [company]="company" (valueChange)="onItemSelect($event?.value)" #search></app-search-companies-and-customers>
      <div class="content-center">
        <p class="link-text" (click)="openNewCustomer()">{{ 'dossier.nieuwe_klant' | translate }}</p><p class="link-text-between"> / </p><p class="link-text" (click)="openNewBusinessCustomer()">{{ 'dossier.nieuwe_organisatie' | translate }}</p>
      </div>
      <div style="max-height: 100%; overflow-x: hidden; overflow-y: auto; padding-bottom: 300px">
        <mat-list *ngFor="let user of users; let last = last;">
          <mat-list-item>
            <div mat-line>{{user[0].firstName + ' ' + user[0].lastName}}</div>
            <button mat-icon-button (click)="removeUser(user[0])">
              <ng-icon class="ng-icon-default" name="heroTrash"></ng-icon>
            </button>
          </mat-list-item>
          <div>
            <mat-radio-group [(ngModel)]="user[1]">
              <div><b>{{ 'dossier.rechten' | translate }}</b></div>
              <mat-radio-button [value]="1">{{ 'dossier.alle_rechten' | translate }}</mat-radio-button>
              <mat-radio-button [value]="2">{{ 'dossier.inzien' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <br *ngIf="!last" />
          <mat-divider *ngIf="!last"></mat-divider>
          <br />
        </mat-list>

        <mat-accordion *ngFor="let organization of organizations; let i = index; let last = last;">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="no-padding">
              <mat-panel-title>
                {{organization[0].name}}
              </mat-panel-title>
              <ng-icon (click)="deleteOrganization(organization)" class="ng-icon-default" name="heroTrash"></ng-icon>
            </mat-expansion-panel-header>
            <p *ngIf="organization[1].length === 0" class="emma-font-light">
              {{'dossier.no_contacts' | translate}}
            </p>
            <mat-list *ngFor="let user of organization[1];">
              <mat-list-item>
                <div mat-line>{{user[0].firstName + ' ' + user[0].lastName}}</div>
                <button mat-icon-button (click)="removeOrganizationUser(i, user[0])">
                  <ng-icon class="ng-icon-default" name="heroTrash"></ng-icon>
                </button>
              </mat-list-item>
              <div>
                <mat-radio-group [(ngModel)]="user[1]">
                  <div><b>{{ 'dossier.rechten' | translate }}</b></div>
                  <mat-radio-button [value]="1">{{ 'dossier.alle_rechten' | translate }}</mat-radio-button>
                  <mat-radio-button [value]="2">{{ 'dossier.inzien' | translate }}</mat-radio-button>
                </mat-radio-group>
              </div>
              <br *ngIf="!last" />
              <mat-divider *ngIf="!last"></mat-divider>
              <br />
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="col-md-6">
      <h4 class="emma-subtitle">{{ 'dossier.medewerker_dossier' | translate }}</h4>
      <app-search-employees #employeeSearch (valueChange)="onEmployeeSelected($event?.value)"></app-search-employees>
      <mat-list id="dossier-employees">
        <mat-list-item *ngFor="let user of employees">
          <app-user-icon [firstName]="user.firstName" [lastName]="user.lastName"></app-user-icon>
          <div mat-line>{{user.id === thisUser.id ? ('dossier.you' | translate) : user.firstName + ' ' + user.lastName }}</div>
          <button mat-icon-button (click)="removeUser(user)">
            <ng-icon class="ng-icon-default" name="heroTrash"></ng-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
