import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeComponent } from './theme/theme.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LogoUploadModule } from 'app/components/logo-upload/logo-upload.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatIconModule } from '@angular/material/icon';
import { DocumentModule } from 'app/components/exchange/document/document.module';
import { FolderModule } from 'app/components/exchange/folder/folder.module';

@NgModule({
  declarations: [ThemeComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    DocumentModule,
    FolderModule,
    TranslateModule.forRoot(),
    MatButtonModule,
    ColorPickerModule,
    MatSnackBarModule,
    LogoUploadModule,
    MatIconModule,
  ]
})
export class ThemeModule { }
