import {AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {EndpointOptions, jsPlumb, jsPlumbInstance, OnConnectionBindInfo} from 'jsplumb';
import {DossierTemplateService} from '../../../services/dossier-template/dossier-template.service';
import {ActivatedRoute} from '@angular/router';
import {DossierActionTemplate, DossierPermissionTemplate, DossierStateTemplate, DossierTemplate, FileRequestTemplate, TemplateFolder} from '../../../functional/models/dossier-template.model';
import {Dossier, dossierEventNames} from '../../../functional/models/dossier.model';
import {AddDossierTemplateFolderDialogComponent} from '../../../dialogs/dossier-template/add-dossier-template-folder-dialog/add-dossier-template-folder-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AddDossierTemplateActionDialogComponent} from '../../../dialogs/dossier-template/add-dossier-template-action-dialog/add-dossier-template-action-dialog.component';
import {AddDossierTemplateStepDialogComponent} from '../../../dialogs/dossier-template/add-dossier-template-step-dialog/add-dossier-template-step-dialog.component';
import {AddDossierTemplateRequestDialogComponent} from '../../../dialogs/dossier-template/add-dossier-template-request-dialog/add-dossier-template-request-dialog.component';
import {ConfirmDeleteDialogComponent, DeleteType} from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {Role} from '../../../functional/models/role.model';
import {AddDossierTemplatePermissionDialogComponent} from '../../../dialogs/dossier-template/add-dossier-template-permission-dialog/add-dossier-template-permission-dialog.component';
import {UtilsService} from '../../../services/utils/utils.service';

@Component({
  selector: 'app-workflow-editor',
  templateUrl: './workflow-editor.component.html',
  styleUrls: ['./workflow-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowEditorComponent implements AfterViewInit {
  @ViewChild('canvas') canvas!: ElementRef;

  zoom = 1;

  listDiv: HTMLElement = document.getElementById('list')!;
  connections: any[] = [];

  // this is the paint style for the connecting lines..
  connectorPaintStyle: any = {
    strokeWidth: 2,
    stroke: '#61B7CF',
    joinstyle: 'round',
    outlineStroke: 'white',
    outlineWidth: 2
  };

  // .. and this is the hover style.
  connectorHoverStyle: any = {
    strokeWidth: 3,
    stroke: '#216477',
    outlineWidth: 5,
    outlineStroke: 'white'
  };

  endpointHoverStyle: any = {
    fill: '#216477',
    stroke: '#216477'
  };

  // the definition of source endpoints (the small blue ones)
  sourceEndpoint: EndpointOptions = {
    endpoint: 'Dot',
    paintStyle: {
      stroke: '#cccccc',
      fill: 'transparent',
      strokeWidth: 1
    },
    maxConnections: -1,
    isSource: true,
    connector: [ 'Flowchart', {
      stub: 20,
      gap: 10,
      cornerRadius: 5,
    }],
    connectorStyle: this.connectorPaintStyle,
    hoverPaintStyle: this.endpointHoverStyle,
    connectorHoverStyle: this.connectorHoverStyle,
    dragOptions: {},
  };

  // the definition of target endpoints (will appear when the user drags a connection)
  targetEndpoint: EndpointOptions = {
    endpoint: 'Dot',
    paintStyle: { fill: '#cccccc', strokeWidth: 1 },
    hoverPaintStyle: this.endpointHoverStyle,
    maxConnections: -1,
    dropOptions: { hoverClass: 'hover' },
    isTarget: true,
  };

  instance!: jsPlumbInstance;
  dossierTemplate?: DossierTemplate;
  opened = false;

  eventList: any = [];
  dossierEvents = dossierEventNames;
  dossierFolders: TemplateFolder[] = [];
  dossierFileRequests: FileRequestTemplate[] = [];
  selectedTab: 'folders' | 'file-requests' | null = null;
  panning: any;
  selectedState?: DossierStateTemplate;

  editingFolders: TemplateFolder[] = [];
  editingStates: DossierStateTemplate[] = [];
  editingActions: DossierActionTemplate[] = [];
  editingPermissions: [number, DossierPermissionTemplate[]][] = [];

  private readonly dossierTemplateId: number;

  constructor(
    public templateService: DossierTemplateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private utilService: UtilsService
  ) {
    this.dossierTemplateId = Number(this.route.snapshot.params.id);
  }

  createIcon() {
    const icon = document.createElement('mat-icon');
    icon.classList.add('mat-icon', 'notranslate', 'material-icons', 'mat-icon-no-color', 'ng-star-inserted');
    return icon;
  }

  async handleClick(state: DossierStateTemplate | undefined) {
    if (!this.opened){
      this.opened = true;
    }
    if (this.selectedState) {
      const element = document.getElementById(`flowchartWindow${this.selectedState.id}`);
      if (element) {
        element.classList.remove('state-selected');
      }
    }

    this.selectedState = state;

    this.selectedState!.permissions = this.sortPermissionsByFolder(
      await this.templateService.getPermissionChange(this.dossierTemplateId, this.selectedState!.id)
    );

    if (state) {
      document.getElementById(`flowchartWindow${state.id}`)!.classList.add('state-selected');
    }
  }

  sortPermissionsByFolder(permissions: DossierPermissionTemplate[]) {
    const sortedPermissions: [number, DossierPermissionTemplate[]][] = [];
    for(const permission of permissions){
      const folderPermission = sortedPermissions.find(sortedPerm => sortedPerm[0] === permission.folderTemplateId);
      if (!folderPermission){
        sortedPermissions.push([permission.folderTemplateId, [permission]]);
      } else {
        folderPermission[1].push(permission);
      }
    }
    return sortedPermissions;
  }

  async ngAfterViewInit() {
    this.dossierTemplate = await this.templateService.get(this.dossierTemplateId);
    this.dossierFolders = await this.templateService.getFolders(this.dossierTemplateId);
    this.dossierFileRequests = await this.templateService.getRequests(this.dossierTemplateId);
    this.drawCanvas();
  }

  drawCanvas() {
    const dossierStates = Dossier.orderDossierTemplate(this.dossierTemplate!);
    const unorderedStates = this.dossierTemplate!.states.filter(state =>
      !state.initialState &&
      !this.dossierTemplate!.states.some(st => st.actions.some(action => action.destinationStateTemplateId === state.id)) ||
      !dossierStates.some(st => st.id === state.id)
    );

    const el = this.canvas.nativeElement as HTMLElement;
    const center = 2 * Math.round((el.offsetHeight / 2) / 2);

    unorderedStates.forEach((state, index) => {
      const div = document.createElement('div');
      div.classList.add('block', 'dragger');
      div.id = `flowchartWindow${state.id}`;
      div.dataset.pointer = `${state.id}`;
      div.style.top = center - 200 + 'px';
      this.canvas!.nativeElement.appendChild(div);
      const left = index * 180;
      div.style.left = left + 'px';

      div.addEventListener('dblclick', (event) => {
        this.handleClick(state);
      });

      if (state.finalState) {
        div.dataset.type = 'end';
        const icon = this.createIcon();
        icon.innerText = 'flag';
        div.appendChild(icon);
      } else {
        div.dataset.type = 'state';
        const icon = this.createIcon();
        icon.innerText = 'label_important';
        icon.style.marginTop = '-30px';
        div.appendChild(icon);
        const name = document.createElement('span');
        name.classList.add('block-text');
        name.innerText = state.name;
        div.appendChild(name);
      }
    });

    dossierStates.forEach((state, index) => {
      const div = document.createElement('div');
      div.classList.add('block', 'dragger');
      div.id = `flowchartWindow${state.id}`;
      div.dataset.pointer = `${state.id}`;
      div.style.top = center + 'px';
      this.canvas!.nativeElement.appendChild(div);
      const left = index * 180;
      div.style.left = left + 'px';

      div.addEventListener('dblclick', async () => {
        await this.handleClick(state);
      });

      if (state.initialState) {
        div.dataset.type = 'start';
        const icon = this.createIcon();
        icon.innerText = 'outlined_flag';
        div.appendChild(icon);
      } else if (state.finalState) {
        div.dataset.type = 'end';
        const icon = this.createIcon();
        icon.innerText = 'flag';
        div.appendChild(icon);
      } else {
        div.dataset.type = 'state';
        const icon = this.createIcon();
        icon.innerText = 'label_important';
        icon.style.marginTop = '-30px';
        div.appendChild(icon);
        const name = document.createElement('span');
        name.classList.add('block-text');
        name.innerText = state.name;
        div.appendChild(name);
      }
    });

    this.initializeInstance();

    // @ts-ignore grid is not included in the TS lib
    this.instance.draggable(this.canvas.nativeElement.children, {grid: [20, 20]});

    // suspend drawing and initialise.
    this.instance.batch( () => {
      const canvas: HTMLElement = this.canvas!.nativeElement;

      for(let i = 0; i <= canvas.childNodes.length; i++) {
        const element = (canvas.children[i] as any);
        if (!element || !element.dataset){
          continue;
        }

        const id = element.dataset.pointer;
        const type = element.dataset.type;
        if (id && type) {
          if (type === 'start') {
            this.addEndpoints(`Window${id}`, ['RightMiddle'], []);
          }
          if (type === 'end') {
            this.addEndpoints(`Window${id}`, [], ['BottomCenter']);
          }
          if (type === 'state') {
            this.addEndpoints(`Window${id}`, ['RightMiddle'], ['BottomCenter']);
          }
        }
      }

      for (const state of this.dossierTemplate!.states) {
        for (const action of state.actions) {
          const conn = this.instance.connect({
            uuids: [`Window${state.id}RightMiddle`, `Window${action.destinationStateTemplateId}BottomCenter`],
            anchor: 'Top',
            cssClass: action.isHappy ? 'main-action' : 'secondary-action',
          });
          (conn as any).canvas.dataset.action = JSON.stringify(action);
        }
      }

      this.instance.bind('connectionDragStop', async (connection: OnConnectionBindInfo) => {
        if (connection.target){
          if ((connection as any).canvas.dataset.action) {
            const dialogRef = this.dialog.open(AddDossierTemplateActionDialogComponent, {
              data: {
                template: this.dossierTemplate,
                folders: this.dossierFolders,
                action: JSON.parse((connection as any).canvas.dataset.action),
                source: Number((connection.source as HTMLElement).dataset.pointer),
                target: Number((connection.target as HTMLElement).dataset.pointer),
              },
              autoFocus: false
            });

            dialogRef.afterClosed().subscribe(async (result: DossierActionTemplate) => {
              if (result) {
                const state = this.dossierTemplate?.states.find(
                  item => item.id === Number((connection.source as HTMLElement).dataset.pointer)
                );
                if (state) {
                  state.actions = state.actions.map(action => action.id === result.id ? result : action);
                }
                if (!!this.selectedState && this.selectedState.id === Number((connection.source as HTMLElement).dataset.pointer)) {
                  this.selectedState.actions = this.selectedState.actions.map(action => action.id === result.id ? result : action);
                }

                (connection as any).canvas.dataset.action = JSON.stringify(result);
              } else {
                this.clearCanvas();
                await this.ngAfterViewInit();
              }
            });
          } else {
            const state = this.dossierTemplate?.states.find(item => item.id === Number((connection.source as HTMLElement).dataset.pointer));
            const result = await this.addAction(state, false, Number((connection.target as HTMLElement).dataset.pointer));
            (connection as any).canvas.dataset.action = JSON.stringify(result);
          }
        } else {
          await this.deleteAction(JSON.parse((connection as any).canvas.dataset.action), false);
        }
      });
    });
    this.instance.fire('jsPlumbDemoLoaded', this.instance, new CustomEvent(''));
  }

  clearCanvas() {
    const canvas: HTMLElement = this.canvas!.nativeElement;
    canvas.innerHTML = '';
  }

  initializeInstance() {
    this.instance = (window as any).jsp = jsPlumb.getInstance({
      // default drag options
      // eslint-disable-next-line @typescript-eslint/naming-convention
      DragOptions: { cursor: 'pointer', zIndex: 2000 },
      // the overlays to decorate each connection with.  note that the label overlay uses a function to generate the label text; in this
      // case it returns the 'labelText' member that we set on each connection in the 'init' method below.
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ConnectionOverlays: [
        [ 'Arrow', {
          location: 1,
          visible:true,
          width:11,
          length:11,
          id:'ARROW',
          events:{
            click: () => alert('you clicked on the arrow overlay')
          }
        } ],
      ],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Container: 'canvas'
    });
  }

  addEndpoints(toId: string, sourceAnchors: any[], targetAnchors: any[]) {
    for (const anchor of sourceAnchors) {
      const sourceUUID = toId + anchor;
      this.instance.addEndpoint('flowchart' + toId, this.sourceEndpoint, {
        anchor,
        uuid: sourceUUID,
        maxConnections: -1,
      });
    }

    for (const anchor of targetAnchors) {
      const targetUUID = toId + anchor;
      this.instance.addEndpoint('flowchart' + toId, this.targetEndpoint, {
        anchor,
        uuid: targetUUID,
        maxConnections: -1,
      });
    }
  };

  zoomOut() {
    if (this.zoom <= 0.6) {
      return;
    }
    const z = this.zoom - 0.10;
    this.zoom = Number(z.toFixed(1));
    const bgSize = 20 * this.zoom;
    const bgOffset = -10 * this.zoom;
    document.getElementById('canvas')!.style.transform = `scale(${this.zoom})`;

    const container = document.getElementById('workflow-container')!;
    container.style.backgroundSize = `${bgSize}px ${bgSize}px`;
    container.style.backgroundImage = `radial-gradient(lightgray ${this.zoom}px, transparent 0)`;
    container.style.backgroundPosition = `${bgOffset}px ${bgOffset}px`;
    this.instance.setZoom(this.zoom);
  }

  zoomIn() {
    if (this.zoom >= 1.2) {
      return;
    }

    const z = this.zoom + 0.10;
    this.zoom = Number(z.toFixed(1));

    const bgSize = 20 * this.zoom;
    const bgOffset = -10 * this.zoom;
    document.getElementById('canvas')!.style!.transform = `scale(${this.zoom})`;
    const container = document.getElementById('workflow-container')!;
    container.style.backgroundSize = `${bgSize}px ${bgSize}px`;
    container.style.backgroundImage  = `radial-gradient(lightgray ${this.zoom}px, transparent 0)`;
    container.style.backgroundPosition = `${bgOffset}px ${bgOffset}px`;
    this.instance.setZoom(this.zoom);
  }

  dragCanvas(canvas: any, event: any) {
    if (this.panning === canvas) {
      document.getElementById('canvas')!.style.left = event.deltaX + 'px';
      document.getElementById('canvas')!.style.top = event.deltaY + 'px';
    }
  }

  addFolder() {
    const dialogRef = this.dialog.open(AddDossierTemplateFolderDialogComponent, {
      data: {
        currentState: this.selectedState,
        template: this.dossierTemplate,
        folders: this.dossierFolders,
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: {folder: TemplateFolder; fileRequests: FileRequestTemplate[]}) => {
      if (result) {
        this.dossierFolders.push(result.folder);
        this.dossierFileRequests = [...this.dossierFileRequests, ...result.fileRequests];
      }
    });
  }

  async addAction(sourceState?: DossierStateTemplate, redraw = true, target?: any) {
    const dialogRef = this.dialog.open(AddDossierTemplateActionDialogComponent, {
      data: {
        currentState: sourceState || this.selectedState,
        template: this.dossierTemplate,
        folders: this.dossierFolders,
        target: target ? Number(target) : null
      },
      autoFocus: false
    });

    const result: DossierActionTemplate = await dialogRef.afterClosed().toPromise();
    if (result) {
      if (sourceState){
        sourceState?.actions.push(result);
      } else {
        this.selectedState?.actions.push(result);
      }
      if (redraw) {
        this.clearCanvas();
        this.drawCanvas();
      }
    } else {
      if (target) {
        this.clearCanvas();
        this.drawCanvas();
      }
    }
    return result;
  }

  addStep() {
    const dialogRef = this.dialog.open(AddDossierTemplateStepDialogComponent, {
      data: {
        currentState: this.selectedState,
        template: this.dossierTemplate,
        folders: this.dossierFolders,
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: DossierStateTemplate) => {
      if (result) {
        this.dossierTemplate?.states.push(result);
        this.clearCanvas();
        this.drawCanvas();
      }
    });
  }

  addFileRequests(folder: TemplateFolder) {
    const dialogRef = this.dialog.open(AddDossierTemplateRequestDialogComponent, {
      data: {
        currentState: this.selectedState,
        template: this.dossierTemplate,
        folders: this.dossierFolders,
        currentFolder: folder
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: FileRequestTemplate[]) => {
      if (result) {
        this.dossierFileRequests = [...this.dossierFileRequests, ...result];
      }
    });
  }

  addPermissions() {
    const dialogRef = this.dialog.open(AddDossierTemplatePermissionDialogComponent, {
      data: {
        currentState: this.selectedState,
        template: this.dossierTemplate,
        folders: this.dossierFolders,
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: DossierPermissionTemplate) => {
      if (result) {
        const existingPermissions = this.selectedState?.permissions?.find(item => item[0] === result.folderTemplateId);
        if (!existingPermissions){
          this.selectedState?.permissions?.push([result.folderTemplateId, [result]]);
        } else {
          existingPermissions[1].push(result);
        }
      }
    });
  }

  async deleteStep(state: DossierStateTemplate | undefined) {
    if (state) {
      const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
        data: {
          deleteType: DeleteType.step,
          toDelete: [state]
        },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result) {
          await this.templateService.deleteState(state.dossierTemplateId, state.id);
          this.selectedState = undefined;
          this.clearCanvas();
          await this.ngAfterViewInit();
        }
      });
    }
  }

  async deleteFolder(folder: TemplateFolder) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: {
        deleteType: DeleteType.folder,
        toDelete: [folder]
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        await this.templateService.deleteFolder(folder.dossierTemplateId, folder.id);
        this.dossierFolders = this.dossierFolders.filter(dfolder => dfolder.id !== folder.id);
      }
    });
  }

  async deleteFileRequest(request: FileRequestTemplate) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: {
        deleteType: DeleteType.request,
        toDelete: [request]
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        await this.templateService.deleteRequest(request.dossierTemplateId, request.id);
        this.dossierFileRequests = this.dossierFileRequests.filter(frequest => request.id !== frequest.id);
      }
    });
  }

  async deleteAction(action: DossierActionTemplate, refresh = true) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: {
        deleteType: DeleteType.action,
        toDelete: [action]
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        await this.templateService.deleteStateAction(action.dossierTemplateId, action.dossierStateTemplateId, action.id);

        if (this.selectedState) {
          this.selectedState!.actions = this.selectedState!.actions.filter(stateAction => stateAction.id !== action.id);
        } else {
          for (const state of this.dossierTemplate!.states) {
            state.actions = state.actions.filter(item => item.id !== action.id);
          }
        }

        if (refresh) {
          this.clearCanvas();
          await this.ngAfterViewInit();
        }
      } else {
        this.clearCanvas();
        await this.ngAfterViewInit();
      }
    });
  }

  async deletePermission(state: DossierStateTemplate, permission: DossierPermissionTemplate) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: {
        deleteType: DeleteType.rights,
        toDelete: [permission]
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        await this.templateService.deletePermissionChange(state.dossierTemplateId, state.id, permission.id);
        this.selectedState!.permissions = this.selectedState!.permissions!.filter(permItem => permItem[0] !== permission.folderTemplateId);
      }
    });
  }

  async deleteFolderPermission(state: DossierStateTemplate, permission: [number, DossierPermissionTemplate[]]) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: {
        deleteType: DeleteType.rights,
        toDelete: permission[1]
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        for (const perm of permission[1]) {
          await this.templateService.deletePermissionChange(state.dossierTemplateId, state.id, perm.id);
        }

        this.selectedState!.permissions = this.selectedState!.permissions!.filter(permItem => permItem[0] !== permission[0]);
      }
    });
  }

  hasFileRequestFromFolder(folderId: number) {
    return this.dossierFileRequests.some(request => request.folderTemplateId === folderId);
  }

  getFolder(folderId: number): TemplateFolder | undefined {
    return this.dossierFolders.find(folder => folder.id === folderId);
  }

  isCustomerRole(permission: DossierPermissionTemplate) {
    return permission.role === Role.customer;
  }

  isEditingState(stateId: number) {
    return this.editingStates.some(item => item.id === stateId);
  }

  editDossierState(state: DossierStateTemplate) {
    this.editingStates.push(this.utilService.deepCopy(state));
  }

  async updateDossierState(stateId: number) {
    this.selectedState = await this.templateService.updateState(this.selectedState!.dossierTemplateId, this.selectedState!.id, {
      name: this.selectedState!.name,
      mayHaveDeadline: this.selectedState!.mayHaveDeadline,
      initialState: this.selectedState!.initialState,
      finalState: this.selectedState!.finalState
    });
    this.editingStates = this.editingStates.filter(item => item.id !== stateId);
  }

  undoEditDossierState(state: DossierStateTemplate) {
    const oldState = this.editingStates.find(item => item.id === state.id)!;
    state.name = oldState.name;
    state.mayHaveDeadline = oldState.mayHaveDeadline;
    this.editingStates = this.editingStates.filter(item => item.id !== state.id);
  }

  isEditingFolder(folderId: number){
    return this.editingFolders.some(item => item.id === folderId);
  }

  editFolder(folder: TemplateFolder) {
    this.editingFolders.push(this.utilService.deepCopy(folder));
  }

  undoEditFolder(folder: TemplateFolder) {
    const oldFolder = this.editingFolders.find(item => item.id === folder.id)!;
    folder.isFinalFolder = oldFolder.isFinalFolder;
    folder.name = oldFolder.name;
    folder.description = oldFolder.description;
    this.editingFolders = this.editingFolders.filter(item => item.id !== folder.id);
  }

  async updateFolder(folderId: number) {
    const folder = this.dossierFolders.find(item => item.id === folderId)!;
    await this.templateService.updateFolder(folder.dossierTemplateId, folder.id, {
      name: folder.name,
      description: folder.description,
      isFinalFolder: folder.isFinalFolder
    });
    this.editingFolders = this.editingFolders.filter(item => item.id !== folderId);
  }

  isEditingAction(actionId: number){
    return this.editingActions.some(item => item.id === actionId);
  }

  editAction(action: DossierActionTemplate) {
    this.editingActions.push(this.utilService.deepCopy(action));
  }

  undoEditAction(action: DossierActionTemplate) {
    const oldAction = this.editingActions.find(item => item.id === action.id)!;
    action.isHappy = oldAction.isHappy;
    action.destinationStateTemplateId = oldAction.destinationStateTemplateId;
    action.name = oldAction.name;
    action.event = oldAction.event;
    this.editingActions = this.editingActions.filter(item => item.id !== action.id);
  }

  async updateAction(actionId: number) {
    const action = this.selectedState?.actions.find(item => item.id === actionId)!;
    await this.templateService.updateStateAction(
      action.dossierTemplateId,
      action.dossierStateTemplateId,
      action.id,
      {
        destinationStateTemplateId: action.destinationStateTemplateId,
        event: action.event,
        isHappy: action.isHappy,
        name: action.name,
      }
    );
    this.editingActions = this.editingActions.filter(item => item.id !== actionId);
  }

  isEditingPermission(folderId: number) {
    return this.editingPermissions.some(item => item[0] === folderId);
  }

  editPermission(permission: [number, DossierPermissionTemplate[]]) {
    this.editingPermissions.push(this.utilService.deepCopy(permission));
  }

  undoEditPermission(permissionByFolder: [number, DossierPermissionTemplate[]]) {
    const oldPermission = this.editingPermissions.find(item => item[0] === permissionByFolder[0])!;
    for (const permission of permissionByFolder[1]){
      permission.permissionString = oldPermission[1].find(item => item.id === permission.id)!.permissionString;
    }
    this.editingPermissions = this.editingPermissions.filter(item => item[0] !== permissionByFolder[0]);
  }

  async updatePermission(folderId: number) {
    const permissions = this.selectedState?.permissions?.find(item => item[0] === folderId)!;

    for (const permission of permissions[1]){
      await this.templateService.updatePermissionChange(
        permission.dossierTemplateId,
        permission.dossierStateTemplateId,
        permission.id,
        {
          folderTemplateId: permission.folderTemplateId,
          role: permission.role,
          permissions: permission.permissionString
        }
      );
    }
  }
}
