import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Folder } from '../../../functional/models/folder';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DocumentService } from '../../../services/document/document.service';
import { TranslateService } from '@ngx-translate/core';
import { FolderService } from '../../../services/folder/folder.service';
import { FileRequest } from '../../../functional/models/file-request.model';
import { Document, DossierDocument } from '../../../functional/models/document';
import { FileRequestService } from '../../../services/file-request/file-request.service';
import { Router } from '@angular/router';
import { Dossier, DossierAction, DossierEvent } from '../../../functional/models/dossier.model';
import { ConfirmDialogComponent } from '../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import { Claims } from '../../../functional/models/user';
import { UserService } from '../../../services/user/user.service';
import { SigningService } from '../../../services/signing/signing.service';
import { Signing, SignType } from '../../../functional/models/signing';
import { EditFolderDialogComponent } from '../../../dialogs/dossiers/edit-folder-dialog/edit-folder-dialog.component';
import { DossierService } from '../../../services/dossier/dossier.service';
import { ZipService } from '../../../services/zip/zip.service';
import { ConfirmDialogType } from '../../../functional/models/dialog.model';
import { FileRequestDialogComponent } from '../../../dialogs/file-request/file-request-dialog/file-request-dialog.component';
import { FulfillRequestDialogComponent } from '../../../dialogs/file-request/fulfill-request-dialog/fulfill-request-dialog.component';
import { Company } from '../../../functional/models/company';
import { CompanyService } from '../../../services/company/company.service';
import { DisplaySignaturesDialogComponent } from '../../../dialogs/display-signatures-dialog/display-signatures-dialog.component';
import { RequestSigningDialogComponent } from '../../../dialogs/signing/request-signing-dialog/request-signing-dialog.component';
import { ReplyDenyDocumentDialogComponent } from '../../../dialogs/signing/reply-deny-document-dialog/reply-deny-document-dialog.component';

@Component({
  selector: 'app-dossier-folder',
  templateUrl: './dossier-folder.component.html',
  styleUrls: ['./dossier-folder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DossierFolderComponent implements OnInit {
  @Output() folderChange = new EventEmitter();
  set folder(folder: Folder) {
    this.folderContent = folder;
    this.folderChange.emit(this.folderContent);
  }

  @Input()
  get folder(): Folder {
    return this.folderContent;
  }

  @Input() requestable = false;
  @Input() uploadable = true;
  @Input() isFinalFolder = false;
  @Input() dossier!: Dossier;
  @Input() fileRequests!: FileRequest[];

  files: DossierDocument[] = [];
  claims!: Claims | null | undefined;
  signings: Signing[] = [];
  rejectedCount = 0;
  company!: Company;
  downloadUrl!: string;
  folderContent!: Folder;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private documentService: DocumentService,
    private folderService: FolderService,
    private translate: TranslateService,
    private requestService: FileRequestService,
    private userService: UserService,
    private router: Router,
    private signingService: SigningService,
    private dossierService: DossierService,
    private zipService: ZipService,
    private companyService: CompanyService,
  ) {
    this.claims = this.userService.getLoginClaims();
  }

  async ngOnInit(): Promise<void> {
    this.company = await this.companyService.findCurrentCompany();
    await this.updateView();
  }

  async updateView() {
    await this.loadFiles();
    await this.loadSignings();
  }

  /**
   * Checks whether an action in the next state requires that files be signed.
   */
  isSigningAction(dossier: Dossier): boolean {
    const signingEvents = [DossierEvent.signingDone, DossierEvent.allSigningsDone];
    const curState = dossier.dossierState;
    const states = Dossier.orderStates(dossier);
    const idx = states.findIndex(state => state.id === curState.id);
    return !!states[idx + 1]?.actions.some(action => signingEvents.includes(action.event));
  }

  async loadSignings() {
    const signings = await this.signingService.getFileSigningsByDossier(this.dossier.id);
    for (const signature of signings) {
      const file = this.files.find(files =>
        files.id === signature.fileId);
      if (file){
        if (!file.signings) {
          file.signings = [];
        }
        file.signings.push(signature);
      }
    }
  }

  isRejected(file: any) {
    return file.signings?.some((s: Signing) => !!s.rejectionDate);
  }

  async loadFiles(): Promise<void> {
    this.files = (await this.folderService.getChildren(this.folder.id)).files;
  }

  async requestFile(): Promise<void> {
    const accessors = [
      ...this.dossier.customers,
      ...this.dossier.employees,
      ...(await this.companyService.getAdmins(this.company.id))
    ];

    const dialogRef = this.dialog.open(FileRequestDialogComponent, {
      data: {
        accessors,
        dossierId: this.dossier.id,
        folderId: this.folder.id
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      window.dispatchEvent(new CustomEvent('request-made', {bubbles: true, detail: {dossierId: () => this.dossier.id}}));
    });
  }

  requestSigning(type: SignType): void {
    const toSignFiles = this.files.filter(file => !file.signings || file.signings.length === 0);

    const dialogRef = this.dialog.open(RequestSigningDialogComponent, {
      data: {
        signType: type,
        files: toSignFiles,
        dossier: this.dossier,
        company: this.company
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed) {

        let action = this.dossier.dossierState.actions.find(a => a.event === DossierEvent.signingsRequested);
        if (!action) {
          action = this.dossier.dossierState.actions.find(a => DossierAction.isManual(a));
        }
        if (action) {
          const nextDialog = this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog',
            data: { dialogType: ConfirmDialogType.requestApproval },
            autoFocus: false
          });
          await this.dossierService.advance(this.dossier.id, action.id);
          window.dispatchEvent(new CustomEvent('request-made', { bubbles: true, detail: { dossierId: () => this.dossier.id } }));
          nextDialog.afterClosed().subscribe(async res => {
            if (res?.toDashboard) {
              await this.router.navigate(['dashboard']);
            }
          });
        }
      }
    });
  }

  countRequests(fileRequests: FileRequest[]): boolean {
    if (fileRequests && fileRequests.length > 0) {
      for (const request of fileRequests) {
        if (!request.fileId) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * File dropped in context.
   *
   * @param files The browser event that was triggered.
   */
  async dropped(files: NgxFileDropEntry[]): Promise<void> {
    const fileList: File[] = [];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => fileList.push(file));
      }
    }
    await this.uploadFiles(fileList);
  }

  async uploadFiles(files: File[]): Promise<void> {
    for (const file of files) {
      try {
        await this.documentService.create(file, {
          name: file.name,
          description: '',
          parentFolder: this.folder.id,
          documentSize: file.size,
        },
        (response: Response) => {
          console.error(response);
          
          this.snackBar.open(this.translate.instant('explorer.fail_upload'), '', {
            duration: 2000,
          });
        });

        await this.updateView();
      } catch {}
    }
  }

  async getFileDetails(e: InputEvent): Promise<void> {
    const element = e.target as HTMLInputElement;
    const files = element.files;
    if (files == null)
      {return;}

    await this.uploadFiles(Array.from(files));
  }

  async deleteFolder(): Promise<void> {
    await this.folderService.delete(this.folder.id);
    window.dispatchEvent(new CustomEvent('folder-deleted', { bubbles: true, detail: { dossierId: () => this.dossier.id } }));
  }

  openReasonDialog(file: any) {
    const deniedSignature = file.signings.find((s: Signing) => s.rejectionDate);

    this.dialog.open(ReplyDenyDocumentDialogComponent, {
      data: {
        signature: deniedSignature
      },
      autoFocus: false
    });
  }

  async deleteFile(file: Document): Promise<void> {
    await this.documentService.delete(file.id);
    await this.updateView();
    window.dispatchEvent(new CustomEvent('request-made', { bubbles: true, detail: { dossierId: () => this.dossier.id } }));
  }

  async displayFile(file: Document): Promise<void> {
    await this.router.navigate(['exchange/viewer', file.id]);
  }

  async deleteFileRequest(request: FileRequest, event: any) {
    await this.requestService.delete(request.id);
    window.dispatchEvent(new CustomEvent('request-made', { bubbles: true, detail: { dossierId: () => this.dossier.id } }));
    event.preventDefault();
    event.stopPropagation();
  }

  async openEditFolderDialog() {
    this.dialog.open(EditFolderDialogComponent, {
      data: {
        folder: this.folderContent,
        dossier: this.dossier
      },
      autoFocus: false
    });
  }

  areFilesToSign() {
    const toSign = this.files.filter( file => !file.signings || file.signings.length === 0);
    return toSign.length > 0;
  }

  async noSigningRequired() {
    const nextAction = this.dossier.dossierState.actions.find((action: DossierAction) =>
      action.event === DossierEvent.manualEmployeeAction);
    if (nextAction) {
      await this.dossierService.advance(this.dossier.id, nextAction.id);
    }
  }

  async startDownload(file: Document, downloadLink: HTMLAnchorElement) {
    this.downloadUrl = (await this.documentService.getUrl(file.id, 'attachment')).url;
    await new Promise(resolve => setTimeout(resolve, 1000));
    downloadLink.click();
  }

  hasManualEmployeeAction() {
    return this.dossier.dossierState.actions.find(action => action.event === DossierEvent.manualEmployeeAction);
  }

  async downloadFolder() {
    await this.zipService.createZip({
      dossierIds: [],
      fileIds: [],
      folderIds: [this.folder.id],
    });

    this.snackBar.open(this.translate.instant('exchange_details.preparing_files'), '', {duration: 2000});
  }

  /**
   * Checks whether the file needs to be signed at least one additional time.
   *
   * @param file the file for which it is checked if it requires signing.
   */
  needsSigning(file: any): boolean {
    return !!file.signings?.some((s: Signing) => !s.signDate);
  }

  /**
   * Checks whether the file has been signed fully by all requested users.
   */
  isSigned(file: any): boolean {
    return (file.signings?.length !== 0) && !!file.signings?.every((s: Signing) => !!s.signDate);
  }

  hasRejected(files: DossierDocument[]) {
    return files.find(file => !!file.signings?.find(signing => !!signing.rejectionDate));
  }

  openFilerequestFulfillDialog(request: FileRequest, event: any) {
    const dialogRef = this.dialog.open(FulfillRequestDialogComponent, {
      data: {
        request,
        dossier: this.dossier,
        folder: this.folder
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.dispatchEvent(new CustomEvent('request-made', {bubbles: true, detail: {dossierId: () => this.dossier.id}}));
      }
    });
    event.preventDefault();
    event.stopPropagation();
  }

  async displaySignatures(file: DossierDocument) {
    const signatures = await this.signingService.getSignings(file.id);
    this.dialog.open(DisplaySignaturesDialogComponent, {
      data: {
        signatures,
        company: this.company
      },
      autoFocus: false
    });
  }
}
