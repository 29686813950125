import { Routes } from '@angular/router';
import { ResetComponent } from './reset/reset.component';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';
import { AuthenticatorComponent } from './authenticator/authenticator.component';

export const authRoutes: Routes = [
  { path: 'login/:returnUrl', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'reset/:id/:type', component: ResetComponent },
  { path: 'reset/:id', component: ResetComponent },
  { path: 'authenticator', component: AuthenticatorComponent },
  { path: '', component: LoginComponent}
];
