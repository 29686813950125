export class Upload {
  name: string;
  size: number;
  file: File | undefined;
  parentFolderId: number;

  constructor(name: string, size: number, file: File | undefined, parentFolderId: number) {
    this.name = name.trim();
    this.size = size;
    this.file = file;
    this.parentFolderId = parentFolderId;
  }

}
