import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DateAdapter, MatRippleModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MAT_LEGACY_SELECT_SCROLL_STRATEGY as MAT_SELECT_SCROLL_STRATEGY, MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import localeNDSNL from '@angular/common/locales/nds-NL';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './pages/components/components.module';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ExchangeModule } from './pages/exchange/exchange.module';
import { GeneralModule } from './pages/general/general.module';
import { ManagementModule } from './pages/management/management.module';
import { SettingsModule } from './pages/settings/settings.module';
import { MessagingModule } from './pages/messaging/messaging.module';
import { TokenInterceptor } from './http.interceptor';
import { AuthModule } from './pages/auth/auth.module';
import { ThemeModule } from './pages/theme/theme.module';
import { ErrorInterceptor } from './error.interceptor';
import { DossierModule } from './pages/dossier/dossier.module';
import { OrganizationsModule } from './pages/management/organizations/organizations.module';
import { RoutingService } from './services/routing/routing.service';
import { AddonModule } from './pages/addon/addon.module';
import { CreateMailDialogComponent } from './dialogs/create-mail-dialog/create-mail-dialog.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SearchEmployeesModule } from './components/search/search-employees/search-employees.module';
import { NewChatDialogComponent } from './dialogs/chat/new-chat-dialog/new-chat-dialog.component';
import { DeleteChatDialogComponent } from './dialogs/chat/delete-chat-dialog/delete-chat-dialog.component';
import { ArchiveChatDialogComponent } from './dialogs/chat/archive-chat-dialog/archive-chat-dialog.component';
import { AddChatUserDialogComponent } from './dialogs/chat/add-chat-user-dialog/add-chat-user-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ChatModule } from './components/chat/chat.module';
import { MatBadgeModule } from '@angular/material/badge';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { SearchCustomersModule } from './components/search/search-customers/search-customers.module';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SearchCompaniesAndCustomersModule } from './components/search/search-companies-and-customers/search-companies-and-customers.module';
import { SearchDossiersModule } from './components/search/search-dossiers/search-dossiers.module';
import { LeaveChatDialogComponent } from './dialogs/chat/leave-chat-dialog/leave-chat-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { NewChatCustomerDialogComponent} from './dialogs/chat/new-chat-customer-dialog/new-chat-customer-dialog.component';
import { ConfirmDeleteDialogComponent } from './dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { AddEmployeeDialogComponent } from './dialogs/groups/add-employee-dialog/add-employee-dialog.component';
import { SearchChatDialogComponent } from './dialogs/chat/search-chat-dialog/search-chat-dialog.component';
import { LogoutReasonDialogComponent } from './dialogs/logout-reason-dialog/logout-reason-dialog.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AddDomainDialogComponent } from './dialogs/add-domain-dialog/add-domain-dialog.component';
import { NLDateAdapter } from './functional/NLDateAdapter';
import { AddDossierTemplateFolderDialogComponent } from './dialogs/dossier-template/add-dossier-template-folder-dialog/add-dossier-template-folder-dialog.component';
import { AddDossierTemplateActionDialogComponent } from './dialogs/dossier-template/add-dossier-template-action-dialog/add-dossier-template-action-dialog.component';
registerLocaleData(localeNDSNL);
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { AddDossierTemplateStepDialogComponent } from './dialogs/dossier-template/add-dossier-template-step-dialog/add-dossier-template-step-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddDossierTemplateRequestDialogComponent } from './dialogs/dossier-template/add-dossier-template-request-dialog/add-dossier-template-request-dialog.component';
import { AddDossierTemplatePermissionDialogComponent } from './dialogs/dossier-template/add-dossier-template-permission-dialog/add-dossier-template-permission-dialog.component';
import { CreateDossierTemplateDialogComponent } from './dialogs/dossier-template/create-dossier-template-dialog/create-dossier-template-dialog.component';
import { CloneDossierTemplateDialogComponent } from './dialogs/dossier-template/clone-dossier-template-dialog/clone-dossier-template-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSortModule } from '@angular/material/sort';
import { MatPasswordStrengthModule } from './components/mat-password-strength/mat-password-strength.module';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { NgIconsModule } from '@ng-icons/core';
import { heroPlus, heroPlusSmall, heroTrash, heroPlusCircle, heroXCircle, heroCalendar, heroChatBubbleOvalLeftEllipsis } from '@ng-icons/heroicons/outline';
import { EmmaInputModule } from './components/base-components/emma-input/emma-input.module';
import { SearchUsersModule } from './components/search/search-users/search-users.module';

export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);
export const scrollerFactory = (overlay: Overlay): () => BlockScrollStrategy => () => overlay.scrollStrategies.block();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatRippleModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatPasswordStrengthModule.forRoot(),
        ExchangeModule,
        GeneralModule,
        ManagementModule,
        SettingsModule,
        MessagingModule,
        AuthModule,
        MatDialogModule,
        DossierModule,
        ThemeModule,
        OrganizationsModule,
        AddonModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        SearchEmployeesModule,
        MatIconModule,
        MatSidenavModule,
        ChatModule,
        MatBadgeModule,
        SearchCustomersModule,
        MatTableModule,
        MatCheckboxModule,
        MatRadioModule,
        SearchCompaniesAndCustomersModule,
        SearchDossiersModule,
        MatDividerModule,
        HammerModule,
        MatExpansionModule,
        NgxMatSelectSearchModule,
        MatSortModule,
        MatTabsModule,
        NgIconsModule.withIcons({
            heroXCircle,
            heroPlus,
            heroPlusSmall,
            heroTrash,
            heroPlusCircle,
            heroChatBubbleOvalLeftEllipsis,
            heroCalendar
        }),
        EmmaInputModule,
        SearchUsersModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollerFactory, deps: [Overlay] },
        { provide: DateAdapter, useClass: NLDateAdapter },
        { provide: LOCALE_ID, useValue: 'nds-NL' },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        RoutingService,
    ],
    declarations: [
        AppComponent,
        AddDomainDialogComponent,
        CreateMailDialogComponent,
        ConfirmDeleteDialogComponent,
        AddEmployeeDialogComponent,
        NewChatDialogComponent,
        DeleteChatDialogComponent,
        ArchiveChatDialogComponent,
        AddChatUserDialogComponent,
        LeaveChatDialogComponent,
        NewChatCustomerDialogComponent,
        SearchChatDialogComponent,
        LogoutReasonDialogComponent,
        AddDossierTemplateFolderDialogComponent,
        AddDossierTemplateActionDialogComponent,
        AddDossierTemplateStepDialogComponent,
        AddDossierTemplateRequestDialogComponent,
        AddDossierTemplatePermissionDialogComponent,
        CreateDossierTemplateDialogComponent,
        CloneDossierTemplateDialogComponent,
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
