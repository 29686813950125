<div
  [ngClass]="{'emma-dropdown-fit': !fullWidth, 'emma-dropdown-fullwidth': fullWidth, 'emma-dropdown emma-dropdown-container': !customStyle, 'emma-dropdown-custom emma-dropdown-custom-container': customStyle}"
  #dropdownMenu>

  <div (click)="toggleMenu()" class="emma-input no-select" *ngIf="renderButton && layout === 'input'">
    {{prefix}}
    <ng-container *ngIf="!renderIconOnly">
      {{lSelected === undefined ? placeholder : getSelected(lSelected)?.displayName}}
    </ng-container>
    <mat-icon *ngIf="renderIconOnly">{{getSelected(lSelected)?.postfixIcon}}</mat-icon>
    <mat-icon>expand_more</mat-icon>
  </div>

  <!-- rounded button -->
  <div (click)="toggleMenu()" class="rounded-button no-select" *ngIf="renderButton && layout === 'rounded'">
    {{prefix}}
    <ng-container *ngIf="!renderIconOnly">
      {{lSelected === undefined ? placeholder : getSelected(lSelected)?.displayName}}
    </ng-container>
    <mat-icon *ngIf="renderIconOnly">{{getSelected(lSelected)?.postfixIcon}}</mat-icon>
    <mat-icon>expand_more</mat-icon>
  </div>

  <!-- custom "button" -->
  <div style="cursor: pointer">
    <ng-container *ngIf="!renderButton">
      <ng-content select="[button]"></ng-content>
    </ng-container>
  </div>

  <div *ngIf="!disabled && showMenu" [ngClass]="{'emma-dropdown-fullwidth': fullWidth, 'emma-dropdown-menu': !customStyle, 'emma-dropdown-custom-menu': customStyle}">
    <p *ngIf="title">{{title}}</p>
    <div class="emma-dropdown-menu-margin">
      <ng-container *ngFor="let item of options">
        <span class="no-select" *ngIf="!item.hidden" (click)="setSelected(item)">{{item.displayName}} <mat-icon *ngIf="item.postfixIcon">{{item.postfixIcon}}</mat-icon></span>
      </ng-container>
      <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100px" *ngIf="noResults && (!options || options.length === 0)">
        <p style="color: #999999; font-weight: 400 !important; padding: 0;">{{noResults}}</p>
      </div>
      <ng-content select="[body]">
      </ng-content>
    </div>
  </div>
</div>
