import { NgModule } from '@angular/core';
import {SignaturepadComponent} from './signaturepad.component';

@NgModule({
  imports: [],
  declarations: [
    SignaturepadComponent
  ],
  exports: [
    SignaturepadComponent
  ],
})
export class SignaturepadModule { }
