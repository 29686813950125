import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
  CreateRecurringDossierModel,
  DossierRecurringFilter,
  RecurringDossier
} from '../../functional/models/dossier-recurring.model';

@Injectable({
  providedIn: 'root'
})
export class DossierRecurringService {
  private url = `${environment.rootUrl}recurring_dossier`;
  private validateUrl = `${environment.rootUrl}recurring_rule`;

  constructor(
    private http: HttpClient,
  ) { }

  filter(filter: DossierRecurringFilter): Promise<RecurringDossier[]> {
    let params = new HttpParams();
    if (filter.userId) {
      params = params.set('user_id', filter.userId.toString());
    }
    if (filter.companyId) {
      params = params.set('company_id', filter.companyId.toString());
    }
    if (filter.active) {
      params = params.set('active', JSON.stringify(filter.active));
    }

    return this.http.get<RecurringDossier[]>(this.url, { params }).toPromise();
  }

  get(id: number): Promise<RecurringDossier> {
    return this.http.get<RecurringDossier>(`${this.url}/${id}`).toPromise();
  }

  getOccurrences(id: number): Promise<string[]> {
    return this.http.get<string[]>(`${this.url}/${id}/next_occurrences`).toPromise();
  }

  create(model: CreateRecurringDossierModel): Promise<RecurringDossier> {
    return this.http.post<RecurringDossier>(this.url, model).toPromise();
  }

  edit(id: number, model: CreateRecurringDossierModel): Promise<RecurringDossier> {
    return this.http.put<RecurringDossier>(`${this.url}/${id}`, model).toPromise();
  }

  delete(id: number): Promise<any> {
    return this.http.delete(`${this.url}/${id}`).toPromise();
  }

  validateRRule(rrule: string): Promise<string[]> {
    return this.http.post<string[]>(`${this.validateUrl}/next_occurrences`, {rrule}).toPromise();
  }
}
