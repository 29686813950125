<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="filter-chat-dialog modal-centered">
  <div class="scrollable">
    <div class="row">
      <div class="col-md-12 centered extra-margin-top">
        <p class="emma-title title-padding"> {{ 'chat.filter' | translate }} </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <p class="emma-font-light"> {{ 'chat.filter_specifics' | translate }} </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <p class="emma-subtitle"> {{ 'chat.customers/organisations' | translate }} </p>
      </div>
    </div>
    <div class="row" *ngIf="company">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <app-search-companies-and-customers [company]="company" #search1 (valueChange)="addCustomerOrCompany($event?.value, search1)" class="search-field-size"></app-search-companies-and-customers>
      </div>
    </div>
    <div class="row" *ngFor="let user of userFilter">
      <div class="col-md-7 col-md-offset-2">{{user.firstName + ' ' + user.lastName}}</div>
      <div class="col-md-1"><mat-icon style="cursor:pointer;" (click)="deleteUser(user)">delete</mat-icon></div>
    </div>
    <div class="row" *ngFor="let organization of organizationFilter">
      <div class="col-md-7 col-md-offset-2">{{organization.name}}</div>
      <div class="col-md-1"><mat-icon style="cursor:pointer;" (click)="deleteOrganization(organization)">delete</mat-icon></div>
    </div>
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <p class="emma-subtitle"> {{ 'chat.dossiers' | translate }} </p>
      </div>
    </div>
    <div class="row" *ngIf="company && userId">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <app-search-dossiers [userId]="userId" [company]="company" #search2 (valueChange)="addDossier($event?.value, search2)" class="search-field-size"></app-search-dossiers>
      </div>
    </div>
    <div class="row" *ngFor="let dossier of dossierFilter">
      <div class="col-md-7 col-md-offset-2">{{dossier.name}}</div>
      <div class="col-md-1"><mat-icon style="cursor:pointer;" (click)="deleteDossier(dossier)">delete</mat-icon></div>
    </div>
    <div class="row">
      <div class="col-md-6 centered small-margin-bottom">
        <button  class="btn btn-blue" (click)="clear()">
          <b> {{ 'chat.reset_filter' | translate }} </b>

        </button>
      </div>
      <div class="col-md-6 centered small-margin-bottom">
        <button  class="btn btn-green" (click)="submit()">
          <b> {{ 'chat.filter_caps' | translate }} </b>

        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered">
        <p class="link-text" (click)="closeModal()">< {{'confirm_dialog.go_back'|translate}}</p>
      </div>
    </div>
  </div>
</div>
