<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="create-folder-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{ 'exchange.create_map' | translate }}</p>
    </div>
  </div>

  <ng-container *ngIf="!parentFolder || (isFromDuplicate && !templateFolder)">
    <div class="row">
      <div class="col-md-12 centered">
        <p class="emma-font-light" *ngIf="!isFromDuplicate">{{ 'exchange.pick_folder' | translate }}</p>
        <p class="emma-font-light" *ngIf="isFromDuplicate">{{ 'exchange.pick_template' | translate }}</p>
      </div>
    </div>
    <div class="folder-picker">
      <app-recursive-tree [fileSource]="fileTreeFilter" [sources]="[]" (selectedChange)="selectFolder($event)"></app-recursive-tree>
    </div>
    <div class="row margin-top-50">
      <div class="col-md-12 centered">
        <button  class="btn btn-blue btn-custom" (click)="setParentFolder(pickedFolder!)" [disabled]="!pickedFolder">
          <b>{{ 'exchange.picked_folder' | translate }}</b>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(parentFolder && !isFromDuplicate) || (isFromDuplicate && templateFolder)">
    <div class="row margin-30">
      <div class="col-md-12 centered">
        <div class="emma-input-container" style="width: 80%;">
          <label for="filename" class="emma-input-label">{{'exchange.foldername_placeholder' | translate}}</label>
          <input id="filename" appEmmaInput type="text" placeholder="Voorbeeld B.V." [formControl]="nameForm" required>
          <div style="margin-top: 5px">
            <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('required')">
              {{ 'exchange.name_obligated' | translate}}
            </span>
            <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('pattern')">
              {{ 'exchange.name_wrong' | translate}}
            </span>
            <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('nameInvalid')">
              {{ 'exchange.name_already_exist' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin-20" *ngIf="isFromDuplicate || parentFolderPermissions.includes('m')">
      <div class="col-md-12 centered">
        <p class="emma-subtitle">{{ 'exchange.access_rights' | translate }}</p>
      </div>
    </div>

    <div class="row margin-20" *ngIf="isFromDuplicate || parentFolderPermissions.includes('m')">
      <div class="col-md-12 centered">
        <app-search-companies-customers-employees-groups id="folder-create-search" #search style="width: 80%" [company]="company" (valueChange)="onSelect($event?.value, search)"></app-search-companies-customers-employees-groups>
      </div>
    </div>
    <div id="users-container">
      <ng-container *ngIf="groups.length > 0 || organizations.length > 0 || users.length > 0">
        <div class="row margin-20">
          <div class="col-md-12 centered">
            <p class="emma-subtitle1">
              {{ 'exchange.customers' | translate }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-6 col-md-1">
            <p class="emma-font-light-small rotated" id="view-right">
              {{ 'rights.view' | translate }}
              <mat-icon [appEmmaTooltip]="'rights.explain_view' | translate">error_outline</mat-icon>
            </p>
          </div>
          <div class="col-md-1">
            <p class="emma-font-light-small rotated" id="upload-right">
              {{ 'rights.upload' | translate }}
              <mat-icon [appEmmaTooltip]="'rights.explain_upload' | translate">error_outline</mat-icon>
            </p>
          </div>
          <div class="col-md-1">
            <p class="emma-font-light-small rotated" id="create-right">
              {{ 'rights.create' | translate }}
              <mat-icon [appEmmaTooltip]="'rights.explain_create' | translate">error_outline</mat-icon>
            </p>
          </div>
          <div class="col-md-1">
            <p class="emma-font-light-small rotated" id="delete-right">
              {{ 'rights.delete' | translate }}
              <mat-icon [appEmmaTooltip]="'rights.explain_delete' | translate">error_outline</mat-icon>
            </p>
          </div>
          <div class="col-md-1">
            <p class="emma-font-light-small rotated" id="administer-right">
              {{ 'rights.manage' | translate }}
              <mat-icon [appEmmaTooltip]="'rights.explain_manage' | translate">error_outline</mat-icon>
            </p>
          </div>
        </div>
      </ng-container>

      <div class="margin-40" *ngFor="let organization of organizations">
        <div class="row" id="folder-organizations">
          <div class="col-md-11">
            <p class="emma-subtitle1">
              {{organization.organization.name}}
            </p>
          </div>
          <div class="col-md-1">
            <p class="emma-subtitle1">
              <mat-icon (click)="deleteOrganization(organization)">delete</mat-icon>
            </p>
          </div>
        </div>

        <div class="row" *ngIf="organization.members.customers.length === 0 && organization.members.employees.length === 0">
          <div class="col-md-12">
            <p class="emma-font-light">
              {{'exchange.no_users' | translate}}
            </p>
          </div>
        </div>

        <form style="margin: 0;" *ngFor="let user of organization.members.customers" [formGroup]="userForms[user.id]">
          <div class="row">
            <div class="col-md-6">
              <p class="emma-font-default">
                {{ user.firstName + ' ' + user.lastName }}
              </p>
            </div>

            <div class="col-md-1">
              <mat-checkbox formControlName="read" [ngModel]="userForms[user.id].get('read')!.value" (click)="setRead(userForms[user.id])"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="write" [ngModel]="userForms[user.id].get('write')!.value" (click)="setOther(userForms[user.id], 'write')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="create" [ngModel]="userForms[user.id].get('create')!.value" (click)="setOther(userForms[user.id], 'create')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="delete" [ngModel]="userForms[user.id].get('delete')!.value" (click)="setOther(userForms[user.id], 'delete')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="modify" [ngModel]="userForms[user.id].get('modify')!.value" (click)="setOther(userForms[user.id], 'modify')"></mat-checkbox>
            </div>
           <div class="col-md-1">
              <mat-icon (click)="deleteUser(user)">delete</mat-icon>
            </div>
          </div>
        </form>

        <p class="emma-font-light-small" *ngIf="organization.members.employees.length > 0">Gekoppelde Medewerkers</p>
        <form style="margin: 0;" *ngFor="let user of organization.members.employees" [formGroup]="userForms[user.id]">
          <div class="row">
            <div class="col-md-6">
              <p class="emma-font-default">
                {{ user.firstName + ' ' + user.lastName }}
              </p>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="read" [ngModel]="userForms[user.id].get('read')!.value" (click)="setRead(userForms[user.id])"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="write" [ngModel]="userForms[user.id].get('write')!.value" (click)="setOther(userForms[user.id], 'write')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="create" [ngModel]="userForms[user.id].get('create')!.value" (click)="setOther(userForms[user.id], 'create')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="delete" [ngModel]="userForms[user.id].get('delete')!.value" (click)="setOther(userForms[user.id], 'delete')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="modify" [ngModel]="userForms[user.id].get('modify')!.value" (click)="setOther(userForms[user.id], 'modify')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-icon (click)="deleteUser(user)">delete</mat-icon>
            </div>
          </div>
        </form>
      </div>

      <ng-container *ngIf="users.length > 0">
        <div class="row" id="folder-users">
          <div class="col-md-12">
            <p class="emma-subtitle1">
              {{ 'exchange.selected_customers' | translate }}
            </p>
          </div>
        </div>
        <form style="margin: 0;" *ngFor="let user of users" [formGroup]="userForms[user.id]">
          <div class="row">
            <div class="col-md-6">
              <p class="emma-font-default">
                {{ user.firstName + ' ' + user.lastName }}
              </p>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="read" [ngModel]="userForms[user.id].get('read')!.value" (click)="setRead(userForms[user.id])"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="write" [ngModel]="userForms[user.id].get('write')!.value" (click)="setOther(userForms[user.id], 'write')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="create" [ngModel]="userForms[user.id].get('create')!.value" (click)="setOther(userForms[user.id], 'create')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="delete" [ngModel]="userForms[user.id].get('delete')!.value" (click)="setOther(userForms[user.id], 'delete')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="modify" [ngModel]="userForms[user.id].get('modify')!.value" (click)="setOther(userForms[user.id], 'modify')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-icon style="cursor:pointer;" (click)="deleteUser(user)">delete</mat-icon>
            </div>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="groups.length > 0">
        <div class="row">
          <div class="col-md-12">
            <p class="emma-subtitle1">
              {{ 'exchange.selected_groups' | translate }}
            </p>
          </div>
        </div>
        <form style="margin: 0;" *ngFor="let group of groups; let grIdx = index" [formGroup]="groupForms[grIdx]">
          <div class="row">
            <div class="col-md-6">
              <p class="emma-font-default">
                {{ group.name }}
              </p>
            </div>

            <div class="col-md-1">
              <mat-checkbox formControlName="read" (click)="setRead(groupForms[grIdx])"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="write" (click)="setOther(groupForms[grIdx], 'write')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="create" (click)="setOther(groupForms[grIdx], 'create')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="delete" (click)="setOther(groupForms[grIdx], 'delete')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-checkbox formControlName="modify" (click)="setOther(groupForms[grIdx], 'modify')"></mat-checkbox>
            </div>
            <div class="col-md-1">
              <mat-icon style="cursor:pointer;" (click)="deleteGroup(group)">delete</mat-icon>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <div class="row margin-top-50">
      <div class="col-md-12 centered">
        <button  class="btn btn-blue btn-custom" (click)="submit()" id="submit-folder-create">
          <b>{{ 'exchange.create_map_cap' | translate }}</b>
        </button>
      </div>
    </div>
  </ng-container>
</div>
