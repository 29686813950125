import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SimplicateService} from 'app/services/simplicate/simplicate.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  simplicateForm!: UntypedFormGroup;
  companyId!: number;

  constructor(
    private simplicateService: SimplicateService,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this.companyId = Number(data.companyId);

    this.formBuilder.group({
      apiKey: ['', [Validators.required]],
      apiSecret: ['', [Validators.required]],
      apiUrl: ['', Validators.required]
    });
  }

  async createConnection() {
    this.simplicateForm.markAllAsTouched();
    if (this.simplicateForm.invalid){
      return;
    }

    const urlPrefix = this.simplicateForm.get('apiUrl')?.value
      .replace('.simplicate.nl', '')
      .replace('.simplicate.nl/', '')
      .replace('http://', '')
      .replace('https://', '')
      .trim();

    await this.simplicateService.createConnection({
      apiKey: this.simplicateForm.get('apiKey')?.value,
      apiSecret: this.simplicateForm.get('apiSecret')?.value,
      urlPrefix,
      companyId: this.companyId
    });
    this.dialogRef.close();
  }
}
