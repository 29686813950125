import { NgModule } from '@angular/core';
import { DenyDocumentDialogComponent } from './deny-document-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EmmaInputModule} from '../../../components/base-components/emma-input/emma-input.module';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  declarations: [DenyDocumentDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    MatButtonModule,
    MatIconModule,
    FormsModule,
    NgIconsModule,
    EmmaInputModule,
  ]
})
export class DenyDocumentDialogModule { }
