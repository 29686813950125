<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    [search]="true"
    (searchChange)="applyFilter($event)"
    [buttons]="[{icon:'add', name: 'new' | translate, event: 'new'}]"
    (buttonClicked)="handleClick($event)">
  </app-sub-nav>
  <div class="table-paginator-container">
    <div class="mat-table-container">
      <table mat-table [dataSource]="dataSource" matSort class="emma-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'employees.name' | translate}} </th>
          <td mat-cell *matCellDef="let row">
            <span class="cell-icon-centered">
              <app-user-icon [short]="getFistLetters(row.firstName, row.lastName)" style="margin-right: 15px"></app-user-icon>
              <span class="cell-block">
                <span class="font-bold">{{row.firstName}} {{row.lastName}}</span>
                <span class="font-light">{{row.email}}</span>
              </span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef class="sort-style"> {{'employees.address' | translate}} </th>
          <td mat-cell *matCellDef="let row">
            <span class="cell-block">
              <span class="font-default" *ngIf="row.street">{{row.street}} {{row.houseNumber}} {{row.premise}}</span>
              <span class="font-default" *ngIf="row.postalCode || row.city">{{row.postalCode}} {{row.city}}</span>
              <span class="font-default" *ngIf="!row.street && !row.postalCode && !row.city"> - </span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'employees.role' | translate}} </th>
          <td mat-cell *matCellDef="let row">
            <app-tag [color]="colors.blue" *ngIf="row.role === 1">{{'users.details.admin' | translate}}</app-tag>
            <app-tag [color]="colors.purple" *ngIf="row.role === 2">{{('users.details.employee' | translate)}}</app-tag>
          </td>
        </ng-container>
        <tr class="empty-row" *matNoDataRow>
          <td colspan="9999">
            <div class="illustration">
              <img src="assets/img/illustrations/collaborate.svg">
              <h1 class="not-found-title">{{'employees.no_employees' | translate}}</h1>
              <span>
                {{'employees.no_employees_text1' | translate}}
                {{'employees.no_employees_text2' | translate}}
              </span>
            </div>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
        <tr mat-row class="element" *matRowDef="let row; columns: displayedColumns;" (click)="redirect(row.id)"></tr>
      </table>
    </div>
    <div>
      <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>
