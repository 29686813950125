import { Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentService } from 'app/services/document/document.service';
import {ActivatedRoute, Router} from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserService } from 'app/services/user/user.service';
import { Signing } from 'app/functional/models/signing';
import { SigningService } from 'app/services/signing/signing.service';
import { Subscription } from 'rxjs';
import { Document } from '../../../functional/models/document';
import {ConfirmDialogComponent} from '../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import {Location} from '@angular/common';
import {RoutingService} from '../../../services/routing/routing.service';
import {ConfirmDialogType} from '../../../functional/models/dialog.model';
import {DenyDocumentDialogComponent} from '../../../dialogs/signing/deny-document-dialog/deny-document-dialog.component';
import {SignDocumentDialogComponent} from '../../../dialogs/signing/sign-document-dialog/sign-document-dialog.component';
import {Role} from '../../../functional/models/role.model';
import {Claims} from '../../../functional/models/user';
import {Folder} from '../../../functional/models/folder';
import {TranslateService} from '@ngx-translate/core';
import {NavVariableService} from '../../../services/nav-variable/nav-variable.service';
import {UtilsService} from '../../../services/utils/utils.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, OnDestroy {
  id: number;
  displayUrl!: string;
  document!: Document;
  documentUrl!: string;
  stamp: Date;
  toSign?: Signing;
  subscription = new Subscription();
  downloadUrl!: string;
  noAccess = false;
  private claims: Claims;
  private breadCrumb: any[] = [];

  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService,
    private userService: UserService,
    private signingService: SigningService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private routing: RoutingService,
    private translate: TranslateService,
    private navVariableService: NavVariableService,
    private utilService: UtilsService
  ) {
    this.id = +this.activeRoute.snapshot.params.id;
    this.stamp = new Date();
    this.claims = this.userService.tryClaims();
    window.addEventListener('window-navigate', (e: any) => this.routeToExchange(e.detail.folder.id), false);
  }

  async ngOnInit() {
    this.noAccess = false;
    try {
      await Promise.all([
        this.keepUrlsRefreshed(),
        this.updateSigning()
      ]);
      await this.calculateCrumbs();
    } catch (e){
      console.error(e);
      this.noAccess = true;
    }
  }

  async keepUrlsRefreshed() {
      this.document = await this.documentService.get(this.id);
      this.documentUrl = (await this.documentService.getUrl(this.id, 'inline')).url;
      this.displayUrl = 'https://docs.google.com/viewerng/viewer?url=' + encodeURIComponent(this.documentUrl) + '&embedded=true';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async updateSigning() {
    const signings = await this.signingService.getSigningsByUser(this.userService.tryClaims().userId);
    this.toSign = signings.find(sign => sign.fileId === this.id);
  }

  isImage() {
    if (this.document) {
      const extensions = ['.png', '.jpg', '.jpeg', '.gif'];
      return extensions.includes(this.document.extension);
    }
    return false;
  }

  isPDF() {
    if (this.document) {
      const extensions = ['.pdf'];
      return extensions.includes(this.document.extension);
    }
    return false;
  }

  openSigning() {
    if (!this.toSign) {
      return;
    }
    const dialogRef = this.dialog.open(SignDocumentDialogComponent, {autoFocus: false});
    this.subscription.unsubscribe();
    this.subscription = dialogRef.afterClosed().subscribe(async result => {
      if (result && result.signature) {
        const signature = (result.signature as string).split(',');
        await this.signingService.sign(this.toSign!.id, { signature: signature[1] });
        this.dialog.open(ConfirmDialogComponent, {
          panelClass: 'confirm-dialog',
          autoFocus: false,
          data: {
            dialogType: ConfirmDialogType.documentsApproved
          }
        });
        this.updateSigning();
      }
    });
  }

  async acceptDocument() {
    if (!this.toSign) {
      return;
    }
    await this.signingService.sign(this.toSign.id, { signature: undefined });
    this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-dialog',
      data: {
        dialogType: ConfirmDialogType.documentsApproved
      },
      autoFocus: false
    });
    this.updateSigning();
  }

  async goBack() {
    if (this.routing.previousNavs.length > 0) {
      this.location.back();
    } else if (this.document){
      await this.router.navigate(['exchange', this.document.parentFolder.id]);
    } else {
      await this.router.navigate(['exchange']);
    }
  }

  openDenyDialog() {
    const dialogRef = this.dialog.open(DenyDocumentDialogComponent, {
      data: {
        signingId: this.toSign!.id
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.denied) {
        this.updateSigning();
      }
    });
  }

  async startDownload(download: HTMLElement) {
    this.downloadUrl = (await this.documentService.getUrl(this.id, 'attachment')).url;
    await new Promise(resolve => setTimeout(resolve, 1000));
    download.click();
  }


  async handleClick(event: string, downloadLink: HTMLAnchorElement) {
    if (event === 'back') {
      await this.goBack();
    }
    if (event === 'download') {
      await this.startDownload(downloadLink);
    }
    if (event === 'approve') {
      await this.acceptDocument();
    }
    if (event === 'sign') {
      await this.openSigning();
    }
    if (event === 'deny') {
      await this.openDenyDialog();
    }
  }

  async calculateCrumbs(): Promise<void> {
    this.breadCrumb = [];
    if (!this.document) {
      return;
    }

    let folders = [...(await this.documentService.getPath(this.document.id))];

    if (this.claims.role === Role.customer || this.claims.role === Role.employee) {
      folders = [this.getRoot(), ...folders];
    }

    for (const folder of folders) {
      this.pushCrumb(folder);
    }
  }

  /**
   * Pushes a deep copy of the supplied object onto the breadcrumb, and updates the previous folder to contain a
   * trailing slash.
   *
   * @param folder The folder to append to the breadcrumb.
   */
  pushCrumb(folder: Folder): void {
    if (this.breadCrumb.some(e => e.id === folder.id)) {
      // crumb already contains this folder
      return;
    }
    const copy: Folder = this.utilService.deepCopy<Folder>(folder);
    if (copy.name === 'root') {
      copy.name = this.translate.instant('explorer.folders');
    }
    this.breadCrumb.push(copy);
    this.navVariableService.navVariables.next({folderCrumbs: this.breadCrumb, fileCrumb: this.document});
  }

  /**
   * Returns a 'fake' root that is used for displaying when the user has no access to the root folder.
   */
  getRoot(): Folder {
    return {
      id: -1,
      name: 'root',
      description: '',
      companyId: this.document.companyId,
      creationDate: new Date(),
      labels: [],
    };
  }

  private routeToExchange(id: number) {
    if (id !== -1) {
      this.router.navigate(['exchange', id]);
    } else {
      this.router.navigate(['exchange']);
    }
  }
}
