<div class="main-content" style="overflow-y: auto">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <button  (click)="toggleCss()">
          {{ showCss ? 'Verberg Css' : 'Toon Css' }}
        </button>
        <app-css-input *ngIf="showCss"></app-css-input>
      </div>
    </div>
  </div>
  <!-- haha html goes brrrr -->
  <br><br>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <button  (click)="toggleMailTemplates()">
          {{ showMailTemplates ? 'Verberg Mail Templates' : 'Toon Mail Template' }}
        </button>
        <app-mail-templates *ngIf="showMailTemplates"></app-mail-templates>
      </div>
    </div>
  </div>
  <br><br>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <button  (click)="toggleMailParameters()">
          {{ showMailParameters ? 'Verberg Mail Parameters' : 'Toon Mail Parameters' }}
        </button>
        <app-mail-parameters *ngIf="showMailParameters"></app-mail-parameters>
      </div>
    </div>
  </div>
</div>
