import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Company, CompanyType } from 'app/functional/models/company';
import { Subscription } from 'rxjs';
import { UserService } from 'app/services/user/user.service';
import { CompanyService } from 'app/services/company/company.service';
import { MessageService} from '../../../services/message/message.service';
import { WebsocketService } from 'app/services/websocket/websocket.service';
import { User } from '../../../functional/models/user';
import { Role } from '../../../functional/models/role.model';
import * as LogRocket from 'logrocket';
import { LogoutReasonDialogComponent } from '../../../dialogs/logout-reason-dialog/logout-reason-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { IconName } from '@ng-icons/core/lib/icon-name';
import { Userpilot } from 'userpilot';
import Intercom from '@intercom/messenger-js-sdk';

export class RouteInfo {
  path!: string;
  title!: string;
  icon!: IconName;
  class?: string;
  role!: number;
  hasBadge?: string;
  children?: RouteInfo[];
  toggle?: boolean;
}

export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'dashboard',
    icon: 'heroRectangleGroup',
    class: '',
    role: 3,
  },
  {
    path: 'dossier',
    title: 'dossiers',
    icon: 'heroFolder',
    class: '',
    role: 3,
    hasBadge: 'dossiers'
  },
  {
    path: 'exchange',
    title: 'documents',
    icon: 'heroDocumentDuplicate',
    class: '',
    role: 3,
  },
  {
    path: 'messaging',
    title: 'messages',
    icon: 'heroInbox',
    class: '',
    role: 3,
    hasBadge: 'inbox',
    children: [
      {
        path: 'messaging/inbox',
        title: 'inbox',
        icon: 'heroInboxArrowDown',
        class: '',
        role: 3,
        hasBadge: 'inbox'
      },
      {
        path: 'messaging/outbox',
        title: 'outbox',
        icon: 'heroInboxArrowDown',
        class: 'rotate-icon',
        role: 3,
      },
    ],
  },
  {
    path: 'management/organizations',
    title: 'organizations',
    icon: 'heroBuildingOffice2',
    class: '',
    role: 2,
  },
  {
    path: 'management/customers',
    title: 'customers',
    icon: 'heroUsers',
    class: '',
    role: 2,
  },
  {
    path: 'management/groups',
    title: 'teams',
    icon: 'heroUserGroup',
    class: '',
    role: 2,
  },
  {
    path: 'management/employees',
    title: 'employees',
    icon: 'heroBriefcase',
    class: '',
    role: 1,
  },
  {
    path: 'manager',
    title: 'management',
    icon: 'heroExclamationCircle',
    class: '',
    role: 0,
    children: [
      {
        path: 'management/companies',
        title: 'companies',
        icon: 'heroBuildingOffice2',
        class: '',
        role: 0,
      },
    ],
  },
  {
    path: 'settings/workflow',
    title: 'workflow_editor',
    icon: 'heroViewColumns',
    class: '',
    role: 0,
  },
  {
    path: 'settings',
    title: 'main_settings',
    icon: 'heroCog',
    class: '',
    role: 1,
    children: [
      { path: 'settings/portal',
        title: 'settings',
        icon: 'heroCog',
        class: '',
        role: 0,
      },
      { path: 'settings/import',
        title: 'import',
        icon: 'heroCog',
        class: '',
        role: 0,
      },
      { path: 'settings/general',
        title: 'general',
        icon: 'heroCog',
        class: '',
        role: 0,
      },
      {
        path: 'settings/editor',
        title: 'editor',
        icon: 'heroEnvelopeOpen',
        class: '',
        role: 1,
      },
      {
        path: 'settings/api-keys',
        title: 'api_key',
        icon: 'heroKey',
        class: '',
        role: 0,
      },
      {
        path: 'addons',
        title: 'addons',
        icon: 'heroSquaresPlus',
        class: '',
        role: 1,
      },
      {
        path: 'theme',
        title: 'theme',
        icon: 'heroSwatch',
        class: '',
        role: 0,
      },
    ],
  },
];

declare let pendo: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems?: RouteInfo[];
  company!: Company;
  companySubscription = new Subscription();
  imageUrl = '/assets/img/icoon-myemma.png';
  loginSubscription = new Subscription();
  isMobile = false;
  statistic = {
    inbox: 0,
    dossiers: 0
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    private messageService: MessageService,
    private websocketService: WebsocketService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService
  ) {}

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.isMobile = window.innerWidth <= 1112;
  }

  updateCompany() {
    this.imageUrl = this.companyService.imageUrl(this.company);
    this.loginSubscription.unsubscribe();
    this.loginSubscription = this.websocketService.logins.subscribe(login => {
      this.localStorageService.resetLocalStorage();
      this.websocketService.close();
      this.router.navigate(['auth', 'login'], { queryParams: { returnUrl: '' }}).then(() => {
        this.dialog.open(LogoutReasonDialogComponent, {
          data: {
            reason: 77,
          },
          autoFocus: false
        });
      });
      this.loginSubscription.unsubscribe();
    });
  }

  getStatistic(statisticName: string): number {
    // @ts-ignore
    return this.statistic[statisticName];
  }

  async ngOnInit() {
    this.isMobile = window.innerWidth <= 1112;

    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company){
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
      this.updateCompany();
      await this.init();
    });
    window.addEventListener('item-read',  () => {
      this.statistic.inbox = this.statistic.inbox - 1;
    });
    await this.setupIntercom();
    await this.setupPendo();
    Userpilot.initialize('NX-5dce9e2e');
    await this.setupUserPilot();
  }

  async init() {
    const claims = this.userService.getLoginClaims();
    if (claims) {
      this.menuItems = ROUTES.filter(menuItem => menuItem.role >= claims.role);
      this.menuItems = this.menuItems
        .map(item => {
          if (item.children) {
            item.children = item.children.filter((child: RouteInfo) => child.role >= claims.role);
          }
          return item;
        });
    }
    await this.updateUnreadInbox();
    window.addEventListener('logo-updated',  e => this.updateCompany(), false);
    await this.updateCompany();
  }

  ngOnDestroy() {
    this.companySubscription.unsubscribe();
  }

  async updateUnreadInbox() {
    const user = this.userService.tryClaims();
    this.statistic.inbox = (await this.messageService.getInbox(user.userId)).filter(item => !item.read).length;
  }

  isMobileMenu() {
    return this.isMobile;
  }

  async logout() {
    this.localStorageService.resetLocalStorage();
    this.websocketService.close();
    this.companyService.refreshCompany();
    await this.router.navigate(['auth']);
  }

  isActive(instruction: RouteInfo[]): boolean {
    const url = this.router.url.replace(/^\/+|\/+$/g, '');
    return !!instruction.find(item => item.path === url);
  }

  getIndustry(type: CompanyType) {
    switch (type) {
      case 0:
        return 'Boekhoudkantoor';
      case 1:
        return 'Zorginstelling';
    }
  }

  async setupUserPilot() {
    const claims = this.userService.getLoginClaims();
    Userpilot.identify(
      claims?.userId.toString() || '0'
    );
  }

  async setupPendo() {
    const claims = this.userService.getLoginClaims();

    pendo.initialize({
      visitor: {
        id: claims?.userId.toString()
      },

      account: {
        id: claims?.userId.toString()
      }
    });
  }

  /* eslint-disable */
  async setupIntercom() {
    const claims = this.userService.getLoginClaims();

    if (!claims) {
      return;
    }

    const user: User = await this.userService.getUser();
    if (claims.role <= Role.employee) {
      try {
        Intercom({
          app_id: 'zy0sfoet',
          email: user.email,
          user_id: claims.userId.toString(),
          name: user.firstName + ' ' + user.lastName,
          first_name: user.firstName,
          phone: user.phone,
          custom_launcher_selector: '#intercom_widget',
          hide_default_launcher: true,
          company: {
            company_id: this.company.id,
            name: this.company.name,
            website: document.location.hostname,
            user_count: this.company.maxEmployees,
            created_at: this.company.creationDate,
            plan: this.company.maxCustomers,
            industry: this.getIndustry(this.company.companyType)
          },
          widget: {
            activator: '#intercom_widget',
            hide_default_launcher: true,
          }
        });
      } catch (e){
        console.error(e);
      }
    }

    if (user.logUntil) {
      const dateTo = new Date(user.logUntil);
      dateTo.setHours(0,0,0,0);

      const today = new Date();
      today.setHours(0,0,0,0);
    }

    try {
      LogRocket.init('sxlky3/myemma', {
        network: {
          requestSanitizer: request => {
            if (request?.body && (typeof request.body !== 'string' || request.body.includes('password') || request.body.includes('newPassword'))) {
              request.body = undefined;
            }

            request.headers['Authorization'] = '';
            return request;
          },
          responseSanitizer: response => {
            if (response.body && (response.body.includes('token') || response.body.includes('refresh'))){
              response.body = undefined;
            }
            response.headers['Authorization'] = '';
            return response;
          }
        }
      });

      LogRocket.identify(user.id.toString(), {
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
        user_id: user.id.toString(),
        company_name: this.company.name,
        company_id: this.company.id,
      });
    } catch (e) {
      console.error(e);
    }
  }
}
