import {NgModule} from '@angular/core';
import {RecurringSettingsComponent} from './recurring-settings.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EmmaInputModule} from '../base-components/emma-input/emma-input.module';
import {EmmaSelectModule} from '../base-components/emma-select/emma-select.module';
import { NgIconsModule } from '@ng-icons/core';
import {heroClock, heroTrash} from '@ng-icons/heroicons/outline';


@NgModule({
  declarations: [
    RecurringSettingsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    MatRadioModule,
    MatDatepickerModule,
    FormsModule,
    NgIconsModule.withIcons({
      heroTrash,
      heroClock
    }),
    EmmaInputModule,
    EmmaSelectModule,
    ReactiveFormsModule
  ],
  exports: [
    RecurringSettingsComponent
  ],
})
export class RecurringSettingsModule { }
