import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Folder} from 'app/functional/models/folder';
import {SelectionChange, SelectionModel} from '@angular/cdk/collections';
import {FolderService, GroupPermission, ListResponse} from 'app/services/folder/folder.service';
import {DocumentService} from 'app/services/document/document.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FtpSettingsDialogComponent} from 'app/dialogs/exchange/ftp-settings-dialog/ftp-settings-dialog.component';
import {
  FilePermissionsDialogComponent
} from 'app/dialogs/exchange/file-permissions-dialog/file-permissions-dialog.component';
import {
  EmailGeneratorDialogComponent
} from 'app/dialogs/exchange/email-generator-dialog/email-generator-dialog.component';
import {Company} from 'app/functional/models/company';
import {ZipService} from 'app/services/zip/zip.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Router} from '@angular/router';
import {FtpService} from 'app/services/ftp/ftp.service';
import {Ftp} from 'app/functional/models/ftp.model';
import {
  ConfirmDeleteDialogComponent,
  DeleteType
} from 'app/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {ExplorerItem} from 'app/functional/models/explorer.model';
import {RedactedUserWithPermissions, User} from 'app/functional/models/user';
import {Signing} from 'app/functional/models/signing';
import {
  RequestSigningDialogComponent
} from 'app/dialogs/exchange/request-signing-dialog/request-signing-dialog.component';
import {Role} from 'app/functional/models/role.model';
import {UserService} from 'app/services/user/user.service';
import {
  DisplaySignaturesDialogComponent
} from 'app/dialogs/display-signatures-dialog/display-signatures-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {FileTreeComponent} from 'app/dialogs/exchange/file-tree/file-tree.component';
import {FileRequestService} from '../../../services/file-request/file-request.service';
import {SourceFilter} from '../../recursive-tree/recursive-tree.component';
import {
  FulfillRequestDialogComponent
} from '../../../dialogs/file-request/fulfill-request-dialog/fulfill-request-dialog.component';
import {
  FileRequestDialogComponent
} from '../../../dialogs/file-request/file-request-dialog/file-request-dialog.component';
import {FileRequest} from '../../../functional/models/file-request.model';
import {Document} from '../../../functional/models/document';
import {CompanyService} from '../../../services/company/company.service';
import {
  ReplyDenyDocumentDialogComponent
} from '../../../dialogs/signing/reply-deny-document-dialog/reply-deny-document-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';

@Component({
  selector: 'app-exchange-details',
  templateUrl: './exchange-details.component.html',
  styleUrls: ['./exchange-details.component.scss']
})
export class ExchangeDetailsComponent implements OnInit, OnDestroy {
  @Input() listSelection!: SelectionModel<ExplorerItem>;
  @Input() currentFolder!: Folder;
  @Input() currentFolderPermissions!: any;
  @Output() explorerChanged = new EventEmitter();
  @Input() company!: Company;
  @Output() navigationChange = new EventEmitter();
  @Output() uploadChange = new EventEmitter();
  @Output() folderCreateChange = new EventEmitter();

  children?: ListResponse;
  localRole = Role;
  currentUserRole!: Role;
  downloadUrl!: string;
  ftpSet?: Ftp;
  panelOpenState: boolean = false;
  childViewOpened?: boolean;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  protected canUserAdministrate?: boolean;
  protected canUserRead?: boolean;
  protected canUserDelete?: boolean;

  constructor(
    public folderService: FolderService,
    public documentService: DocumentService,
    public dialog: MatDialog,
    public zipService: ZipService,
    public snackBar: MatSnackBar,
    public router: Router,
    public ftpService: FtpService,
    public userService: UserService,
    private translate: TranslateService,
    private requestService: FileRequestService,
    private companyService: CompanyService
  ) {
  }

  async ngOnInit() {
    this.childViewOpened = localStorage.getItem('exchange-childview-opened') === 'true';
    this.currentUserRole = this.userService.tryClaims().role;
    if (this.userService.tryClaims().role !== Role.employee && this.userService.tryClaims().role !== Role.customer) {
      this.ftpSet = await this.ftpService.readByCompany(this.company.id);
    }

    this.listSelection.changed
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (res: SelectionChange<ExplorerItem>) => {
        this.setCanUserRead();
        this.setCanUserDelete();
        this.setCanUserAdministrate();

        if (res.source.selected.length === 1) {
          if (this.childViewOpened) {
            await this.getCurrentFolderChildren(res.source.selected[ 0 ]);
          }
        } else {
          this.children = undefined;
        }
      })
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  async getCurrentFolderChildren(currentFolder: ExplorerItem) {
    await this.folderService.getChildren(currentFolder.folder!.id).then((children: ListResponse) => {
      this.children = children;
    });
  }

  async setDescription(event: FocusEvent) {
    const input = event.target as HTMLElement;

    if (input.innerText.length <= 1) {
      input.innerText = this.translate.instant('exchange_details.example_description');
    } else {
      try {
        if (this.listSelection.selected[ 0 ].file) {
          try {
            await this.documentService.update(this.listSelection.selected[ 0 ].file.id, {
              description: input.innerText,
              parentFolder: this.listSelection.selected[ 0 ].file.parentFolder.id,
              name: this.listSelection.selected[ 0 ].file.name
            });
            this.listSelection.selected[ 0 ].file.description = input.innerText;
          } catch {
          }
        } else if (this.listSelection.selected[ 0 ].folder) {
          try {
            await this.folderService.edit(this.listSelection.selected[ 0 ].folder.id, {
              name: this.listSelection.selected[ 0 ].folder.name,
              description: input.innerText,
              parentFolder: this.listSelection.selected[ 0 ].folder.parentFolder!.id,
              ftpDestinationFolder: this.listSelection.selected[ 0 ].folder.ftpDestinationFolder
            });
            this.listSelection.selected[ 0 ].folder.description = input.innerText;
          } catch {
          }
        }
        this.snackBar.open(this.translate.instant('exchange_details.description_saved'), '', {
          duration: 2000,
        });
      } catch {
      }
    }
  }

  async setTitle(event: FocusEvent) {
    const input = event.target as HTMLElement;

    if (input.innerText.length <= 1) {
      input.innerText = this.listSelection.selected[ 0 ].file?.name ||
        this.listSelection.selected[ 0 ].folder?.name ||
        this.translate.instant('exchange_details.example_name');
    } else {
      const text = input.innerText.replace(/(\r\n|\n|\r)/gm, '').trim();
      try {
        if (this.listSelection.selected[ 0 ].file) {
          try {
            await this.documentService.update(this.listSelection.selected[ 0 ].file.id, {
              description: this.listSelection.selected[ 0 ].file.description,
              parentFolder: this.listSelection.selected[ 0 ].file.parentFolder.id,
              name: text
            });
            this.listSelection.selected[ 0 ].file.name = input.innerText;
          } catch {

          }
        } else if (this.listSelection.selected[ 0 ].folder) {
          try {
            await this.folderService.edit(this.listSelection.selected[ 0 ].folder.id, {
              name: text,
              description: this.listSelection.selected[ 0 ].folder.description,
              parentFolder: this.listSelection.selected[ 0 ].folder.parentFolder!.id,
              ftpDestinationFolder: this.listSelection.selected[ 0 ].folder.ftpDestinationFolder
            });
            this.listSelection.selected[ 0 ].folder.name = text;
          } catch {
          }
        }
        this.snackBar.open(this.translate.instant('exchange_details.title_saved'), '', {
          duration: 2000,
        });
      } catch {
      }
    }
  }

  setCanUserRead(): void {
    if (!this.listSelection || !this.listSelection.selected || this.listSelection.selected!.length === 0) {
      this.canUserRead = false;
      return;
    }

    if (this.listSelection && this.listSelection.selected && this.listSelection.selected.length > 0) {
      this.canUserRead = !this.listSelection.selected.find(
        selection => selection.file && !selection.file.permission?.includes('r')
          || selection.folder && !selection.folder.permission?.includes('r')
      );
      return;
    }
    this.canUserRead = false;
  }

  setCanUserAdministrate(): void {
    if (!this.listSelection || !this.listSelection.selected || this.listSelection.selected!.length === 0) {
      this.canUserAdministrate = false;
      return;
    }

    if (this.listSelection && this.listSelection.selected && this.listSelection.selected.length > 0) {
      this.canUserAdministrate = !this.listSelection.selected.find(
        selection => selection.file && !selection.file.permission?.includes('m')
          || selection.folder && !selection.folder.permission?.includes('m')
      );
      return;
    }
    this.canUserAdministrate = false;
  }

  setCanUserDelete(): void {
    if (!this.listSelection || !this.listSelection.selected || this.listSelection.selected!.length === 0) {
      this.canUserDelete = false;
      return;
    }

    if (this.listSelection && this.listSelection.selected && this.listSelection.selected.length > 0) {
      this.canUserDelete = !this.listSelection.selected.find(
        selection => selection.file && !selection.file.permission?.includes('d')
          || selection.folder && !selection.folder.permission?.includes('d')
      );
      return;
    }
    this.canUserDelete = false;
  }

  canUserCreate(): boolean {
    if (!this.currentFolder || !this.currentFolderPermissions) {
      return false;
    }
    if (this.currentFolder.id === -1) {
      return false;
    }
    return this.currentFolderPermissions.includes('c');
  }

  canUserAdministrateCurrentFolder() {
    if (!this.currentFolder || !this.currentFolderPermissions) {
      return false;
    }

    if (this.currentFolder.id === -1) {
      return false;
    }

    return this.currentFolderPermissions.includes('m');
  }

  canUserWrite(): boolean {
    if (!this.currentFolder || !this.currentFolderPermissions) {
      return false;
    }

    if (this.currentFolder.id === -1) {
      return false;
    }

    return this.currentFolderPermissions.includes('w');
  }

  hasRequest(): boolean {
    return this.listSelection.selected.some(item => item.request !== undefined);
  }

  hasFile(): boolean {
    return this.listSelection.selected.some(item => item.file !== undefined);
  }

  hasFolder(): boolean {
    return this.listSelection.selected.some(item => item.folder !== undefined);
  }

  openSignatureDialog() {
    const dialogRef = this.dialog.open(RequestSigningDialogComponent, {
      data: {
        explorerItem: this.listSelection.selected,
        company: this.company,
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res?.confirmed) {
        this.listSelection.clear();
        this.explorerChanged.emit(true);
      }
    });
  }

  openFileReqestFulfillDialog(item: ExplorerItem) {
    const dialogRef = this.dialog.open(FulfillRequestDialogComponent, {
      data: {
        request: item.request
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.explorerChanged.emit(true);
      }
    });
  }

  openEmailDialog() {
    this.dialog.open(EmailGeneratorDialogComponent, {
      data: {
        folder: this.listSelection.selected[ 0 ]
      },
      autoFocus: false
    });
  }

  async deleteItems() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: {
        deleteType: DeleteType.file,
        toDelete: this.listSelection.selected
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async res => {
      if (!res) {
        return;
      }
      this.explorerChanged.emit(true);
      for (const selection of this.listSelection.selected) {
        if (selection.file) {
          await this.documentService.delete(selection.file.id);
        }
        if (selection.folder) {
          await this.folderService.delete(selection.folder.id);
        }
        if (selection.request) {
          await this.requestService.delete(selection.request.id);
        }
      }
      this.listSelection.clear();
      this.explorerChanged.emit(true);
    });
  }

  openFtpSettingsDialog() {
    const dialogRef = this.dialog.open(FtpSettingsDialogComponent, {
      data: {
        folder: this.listSelection.selected[ 0 ]
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.listSelection.clear();
        this.explorerChanged.emit(true);
      }
    });
  }

  openPermissionsDialog() {
    const dialogRef = this.dialog.open(FilePermissionsDialogComponent, {
      data: {
        folders: this.listSelection.selected,
        company: this.company
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.listSelection.clear();
        this.explorerChanged.emit(true);
      }
    });
  }

  async triggerDownloads(download: HTMLElement) {
    if (this.listSelection.selected.length === 1 && this.hasFile()) {
      this.downloadUrl = (await this.documentService.getUrl(this.listSelection.selected[ 0 ].file!.id, 'attachment')).url;
      await new Promise(resolve => setTimeout(resolve, 1000));
      download.click();
    } else {
      const files: number[] = this.listSelection.selected.filter(item => item.file).map(item => item.file!.id);
      const folders: number[] = this.listSelection.selected.filter(item => item.folder).map(item => item.folder!.id);
      await this.zipService.createZip({
        dossierIds: [],
        fileIds: files,
        folderIds: folders,
      });

      this.snackBar.open(this.translate.instant('exchange_details.preparing_files'), '', {duration: 2000});
    }
  }

  async duplicateFolder() {
    const promises = [];

    for (const item of this.listSelection.selected) {
      if (item.folder) {
        promises.push(this.folderService.cloneFolder(item.folder.id, item.folder.parentFolder!.id));
      }
    }

    await Promise.all(promises);
    // server probably does this action async so the display will not be refreshed correctly without this sleep.
    await new Promise(resolve => setTimeout(resolve, 500));

    this.listSelection.clear();
    this.explorerChanged.emit(true);
    this.snackBar.open(this.translate.instant('exchange_details.folders_duplicated'), '', {duration: 2000});
  }

  async emitActiveItem() {
    if (this.listSelection.selected[ 0 ].file) {
      this.emitActiveFile(this.listSelection.selected[ 0 ].file);
      return;
    }

    if (this.listSelection.selected[ 0 ].folder) {
      this.emitActiveFolder(this.listSelection.selected[ 0 ].folder);
      return;
    }
  }

  emitActiveFolder(folder: Folder, parentFolder?: Folder) {
    if (parentFolder) {
      this.navigationChange.emit(parentFolder);
    }
    this.navigationChange.emit(folder);
  }

  async emitActiveFile(file: Document) {
    await this.router.navigate(['exchange/viewer', file.id]);
    this.listSelection.clear();
  }

  getUserFromPermissions(user: User) {
    return this.listSelection.selected[ 0 ].users!.find(item => item.id === user.id);
  }

  getFistLetter(name: string) {
    return name.substring(0, 2);
  }

  getFistLetters(fistName: string, lastName: string) {
    return fistName.charAt(0) + lastName.charAt(0);
  }

  getBadge(signing: Signing): 'checked' | 'denied' | 'signing' | '' {
    if (signing.signDate) {
      return 'checked';
    } else if (signing.rejectionDate) {
      return 'denied';
    } else if (!signing.signDate && !signing.rejectionDate) {
      return 'signing';
    }
    return '';
  }

  getSigningTooltip(signing: Signing) {
    let signingString = '';

    if (signing.rejectionDate) {
      signingString = this.translate.instant('exchange_details.denied');
    }
    if (signing.signDate) {
      if (signing.signType === 0) {
        signingString = this.translate.instant('exchange_details.signed');
      } else {
        signingString = this.translate.instant('exchange_details.accepted');
      }
    }

    if (!signing.rejectionDate && !signing.signDate) {
      signingString = this.translate.instant('exchange_details.awaiting');
    }

    return signing.user.firstName + ' ' + signing.user.lastName + '\n\n' + signingString;
  }

  getTooltip(user: RedactedUserWithPermissions) {
    const permissions = [];
    let permissionString = '';
    if (user.permissions.includes('r')) {
      permissions.push(this.translate.instant('exchange_details.download'));
    }
    if (user.permissions.includes('w')) {
      permissions.push(this.translate.instant('exchange_details.upload'));
    }
    if (user.permissions.includes('d')) {
      permissions.push(this.translate.instant('exchange_details.delete'));
    }
    if (user.permissions.includes('c')) {
      permissions.push(this.translate.instant('exchange_details.create'));
    }
    if (user.permissions.includes('m')) {
      permissions.push(this.translate.instant('exchange_details.administer'));
    }
    let counter = 0;
    for (const permission of permissions) {
      permissionString = permissionString + ' ' + permission;
      if (counter === 2) {
        permissionString += '\n';
      }
      counter++;
    }
    return user.firstName + ' ' + user.lastName + '\n\n' + permissionString;
  }

  getGroupTooltip(groupPermission: GroupPermission) {
    const permissions = [];
    let permissionString = '';
    if (groupPermission.groupFolder.permissions.includes('r')) {
      permissions.push(this.translate.instant('exchange_details.download'));
    }
    if (groupPermission.groupFolder.permissions.includes('w')) {
      permissions.push(this.translate.instant('exchange_details.upload'));
    }
    if (groupPermission.groupFolder.permissions.includes('d')) {
      permissions.push(this.translate.instant('exchange_details.delete'));
    }
    if (groupPermission.groupFolder.permissions.includes('c')) {
      permissions.push(this.translate.instant('exchange_details.create'));
    }
    if (groupPermission.groupFolder.permissions.includes('m')) {
      permissions.push(this.translate.instant('exchange_details.administer'));
    }
    let counter = 0;
    for (const permission of permissions) {
      permissionString = permissionString + ' ' + permission;
      if (counter === 2) {
        permissionString += '\n';
      }
      counter++;
    }
    return groupPermission.group.name + '\n\n' + permissionString;
  }

  hasCustomers() {
    return this.listSelection.selected[ 0 ].users?.find(user => user.role === Role.customer && user.permissions.includes('r'));
  }

  openSigningDetails(userSigning: Signing) {
    if (userSigning.signDate) {
      this.dialog.open(DisplaySignaturesDialogComponent, {
        data: {
          signatures: [userSigning],
          company: this.company
        },
        autoFocus: false
      });
    }
    if (userSigning.rejectionDate) {
      this.dialog.open(ReplyDenyDocumentDialogComponent, {
        data: {
          signature: userSigning,
          company: this.company
        },
        autoFocus: false
      });
    }
  }

  getStrippedName(name: string, extension: string) {
    return name.replace(new RegExp(extension + '$'), 'finish');
  }

  createFolder() {
    this.folderCreateChange.emit(true);
  }

  uploadFile() {
    this.uploadChange.emit(true);
  }

  async openFileRequest() {
    const accessors = [
      ...(await this.folderService.getAccessors(this.currentFolder.id)).users,
      ...(await this.companyService.getAdmins(this.company.id))
    ];

    const requestDialog = this.dialog.open(FileRequestDialogComponent, {
      data: {
        accessors,
        folderId: this.currentFolder.id,
      },
      autoFocus: false
    });

    requestDialog.afterClosed().subscribe((res) => {
      if (res) {
        this.explorerChanged.emit(true);
      }
    });
  }

  async moveFiles() {
    let source!: SourceFilter;
    if (this.userService.tryClaims().role === Role.customer || this.userService.tryClaims().role === Role.employee) {
      source = {
        userId: this.userService.tryClaims().userId,
      };
    } else {
      source = {
        companyId: this.company.id,
      };
    }

    const treedialog = this.dialog.open(FileTreeComponent, {
      data: {
        type: 'copy',
        company: this.company,
        source,
        selectedFolders: this.listSelection.selected
      },
      autoFocus: false
    });

    treedialog.afterClosed().subscribe(async (res: { folderId: number }) => {
      const promises: Promise<Document | Folder | FileRequest>[] = [];
      for (const selected of this.listSelection.selected) {
        if (selected.file) {
          promises.push(
            this.documentService.update(selected.file.id, {
              name: selected.file.name,
              description: selected.file.description,
              parentFolder: res.folderId
            })
          );
        }

        if (selected.request) {
          promises.push(
            this.requestService.edit(selected.request.id, {
              filename: selected.request.filename,
              message: selected.request.message,
              exampleFileId: selected.request.exampleFileId,
              folderId: res.folderId
            })
          );
        }

        if (selected.folder) {
          promises.push(this.folderService.edit(selected.folder.id, {
            name: selected.folder.name,
            description: selected.folder.description,
            parentFolder: res.folderId,
            ftpDestinationFolder: selected.folder.ftpDestinationFolder
          }));
        }
      }
      await Promise.all(promises);
      this.listSelection.clear();
      this.explorerChanged.emit(true);
      this.snackBar.open(this.translate.instant('exchange_details.folders_duplicated'), '', {duration: 2000});
    });
  }

  cloneFiles() {
    let source!: SourceFilter;
    if (this.userService.tryClaims().role === Role.customer || this.userService.tryClaims().role === Role.employee) {
      source = {
        userId: this.userService.tryClaims().userId,
      };
    } else {
      source = {
        companyId: this.company.id,
      };
    }

    const treedialog = this.dialog.open(FileTreeComponent, {
      data: {
        type: 'clone',
        company: this.company,
        source,
        multi: true,
        selectedFolders: this.listSelection.selected
      },
      autoFocus: false
    });
    treedialog.afterClosed().subscribe(async (res: { folderIds: number[] }) => {
      if (!res.folderIds) {
        return;
      }

      const promises: Promise<any>[] = [];
      for (const folderId of res.folderIds) {
        for (const selected of this.listSelection.selected) {
          if (selected.file) {
            promises.push(this.documentService.clone(selected.file.id, folderId));
          }

          if (selected.folder) {
            promises.push(this.folderService.clone(selected.folder.id, folderId, true));
          }
        }
      }
      await Promise.all(promises);
      this.listSelection.clear();
      this.explorerChanged.emit(true);
      this.snackBar.open(this.translate.instant('exchange_details.folders_duplicated'), '', {duration: 2000});
    });
  }

  hasCompletedSignature() {
    if (this.listSelection.selected[ 0 ] && this.listSelection.selected[ 0 ].signings) {
      return this.listSelection.selected[ 0 ].signings.some(signing => !!signing.signDate);
    }
    return false;
  }

  async openSignaturesDialog() {
    this.dialog.open(DisplaySignaturesDialogComponent, {
      data: {
        signatures: this.listSelection.selected[ 0 ].signings,
        company: this.company,
      },
      autoFocus: false
    });
  }

  hasSingleCustomers(explorerItem: ExplorerItem) {
    if (explorerItem.singleUsers) {
      return explorerItem.singleUsers.some(user => user.role === Role.customer);
    }
    return false;
  }

  hasEmployees(explorerItem: ExplorerItem) {
    if (explorerItem.singleUsers) {
      if (explorerItem.singleUsers.some(user => user.role === Role.admin || user.role === Role.employee)) {
        return true;
      }
      if (explorerItem.organizations) {
        return explorerItem.organizations.some(org => org[ 1 ].some(user => user.role === Role.admin || user.role === Role.employee));
      }
    }
    return false;
  }

  blockEnter(evt: KeyboardEvent) {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }
  }

  async setChildrenOpened() {
    localStorage.setItem('exchange-childview-opened', 'true');
    this.childViewOpened = true;
    await this.getCurrentFolderChildren(this.listSelection.selected[ 0 ])
  }

  setChildrenClosed() {
    localStorage.removeItem('exchange-childview-opened');
  }
}
