<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="fulfill-request-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{ 'dialog.request.document' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-subtitle1">{{ 'dialog.request.document_requested' | translate }}</p>
    </div>
  </div>
  <div class="row margin-bottom">
    <div class="col-md-12 centered text-centered">
      {{request.filename}}
    </div>
  </div>
  <div class="row margin-bottom">
    <div class="col-md-12 centered text-centered">
      {{request.message}}
    </div>
  </div>
  <div class="row margin-bottom" *ngIf="toUpload">
    <div class="col-md-12 centered text-centered">
      {{toUpload.name}}
    </div>
  </div>
  <div class="row margin-bottom" *ngIf="!toUpload">
    <div class="col-md-12">
      <input type="file" style="display: none" #fileInput (change)="inserted($event)">
      <ngx-file-drop dropZoneClassName="dropzone-body" contentClassName="dropzone-body" class="dropzone-core" [multiple]="false" (onFileDrop)="dropped($event)" (click)="startInput(fileInput)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div class="dropzone-body">
            <mat-icon>upload_file</mat-icon>
            <p>{{'dialog.request.click_drag' | translate}}</p>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <button  id="submit-request" class="btn btn-blue btn-custom " (click)="sendFile()">
        <b>{{ 'dialog.request.provide' | translate }}</b>

      </button>
    </div>
  </div>
</div>
