import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Company} from '../../../functional/models/company';
import {UserService} from '../../../services/user/user.service';
import {OrganizationService} from '../../../services/organization/organization.service';
import {Dossier} from '../../../functional/models/dossier.model';
import {DossierService} from '../../../services/dossier/dossier.service';
import {Role} from '../../../functional/models/role.model';
import {EmmaSelectOption} from '../../base-components/emma-select/emma-select.component';

@Component({
  selector: 'app-search-dossiers',
  templateUrl: './search-dossiers.component.html',
  styleUrls: ['./search-dossiers.component.scss']
})
export class SearchDossiersComponent implements OnInit {
  @Input() preSet?: Dossier[];
  @Input() company!: Company;
  @Input() userId?: number;
  @Input() organizationId?: number;
  @Output() valueChange: EventEmitter<EmmaSelectOption | undefined> = new EventEmitter<EmmaSelectOption | undefined>();
  @Input() set value(item: EmmaSelectOption | undefined) {
    this.lValue = item;
    this.valueChange.emit(this.lValue);
  };

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  options: EmmaSelectOption[] = [];
  lValue?: EmmaSelectOption;
  dossiers: Dossier[] = [];

  constructor(public userService: UserService, public dossierService: DossierService, public organizationService: OrganizationService) {
  }

  async ngOnInit() {
    await this.resetCustomers();
  }

  async resetCustomers() {
    const isCustomer = this.userService.tryClaims().role === Role.customer;
    if (!this.preSet) {
      if (this.userId) {
        if (isCustomer) {
          this.dossiers = this.dossierService.filter({
            userId: this.userId,
            initial: false
          });
        } else {
          this.dossiers = this.dossierService.filter({
            userId: this.userId,
            initial: true
          });
        }
      }
      if (this.organizationId) {
        this.dossiers = await this.organizationService.getDossiers(this.organizationId);
      }
    } else {
      this.dossiers = this.preSet;
    }

    this.options = this.dossiers.map((dossier: Dossier) => ({
      displayName: dossier.name,
      value: dossier,
      searchObject: dossier
    }));
  }

  clearSelected() {
    this.lValue = undefined;
  }
}
