import { Component, Inject } from '@angular/core';
import { SigningService } from '../../../services/signing/signing.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-deny-document-dialog',
  templateUrl: './deny-document-dialog.component.html',
  styleUrls: ['./deny-document-dialog.component.scss']
})
export class DenyDocumentDialogComponent {

  signingId: number;
  reasonForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DenyDocumentDialogComponent>,
    private signingService: SigningService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.signingId = data.signingId;
    this.reasonForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', [])
    });
  }

  async submit() {
    await this.signingService.reject(this.signingId, {message: this.reasonForm.value});
    this.dialogRef.close({denied: true});
  }

  closeModal() {
    this.dialogRef.close();
  }
}
