<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    (navigationChange)="setActive($event)"
    (buttonClicked)="handleClick($event)"
    [buttons]="[{icon:'add', name: 'new' | translate, event: 'new', hidden: activeTab === 'details'}]"
    [navigation]="[{name: 'groups.overview' | translate, event: 'details'}, {name: 'groups.contacts' | translate, event: 'persons'}, {name: 'groups.employees' | translate, event: 'employees'}]">
  </app-sub-nav>
  <ng-container *ngIf="activeTab === 'details'">
    <div class="group-form">
      <form [formGroup]="groupForm" *ngIf="groupForm">
        <div class="row">
          <div class="col-md-12">
            <div class="emma-input-container">
              <label label for="name" class="emma-input-label">{{ 'groups.details.alter_name' | translate }}</label>
              <input appEmmaInput id="name" formControlName="name">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="emma-input-container">
              <label label for="description" class="emma-input-label">{{'groups.details.alter_description' | translate }}</label>
              <textarea appEmmaInput id="description" formControlName="description" style="height: 200px"></textarea>
            </div>
          </div>
        </div>
        <button  type="submit" class="btn btn-red pull-right" (click)="deleteGroup()">{{'groups.details.delete' | translate}}</button>
        <button  type="submit" class="btn btn-blue btn-custom pull-right" (click)="update()">{{'groups.details.alter_update' | translate}}</button>
        <div class="clearfix"></div>
      </form>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab === 'employees'">
    <app-connected-users
      #employeeOverview
      [users]="employees"
      [isCustomer]="false"
      [selectable]="false"
      [deleteable]="true"
      [isRedacted]="false"
      [company]="company"
      (deletedUsers)="deleteEmployees($event)"
    ></app-connected-users>
  </ng-container>

  <ng-container *ngIf="activeTab === 'persons'">
    <app-connected-users
      #customerOverview
      [users]="customers"
      [isCustomer]="false"
      [selectable]="false"
      [deleteable]="true"
      [isRedacted]="false"
      [company]="company"
      (deletedUsers)="deleteCustomers($event)"
    ></app-connected-users>
  </ng-container>
</div>
