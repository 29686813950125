import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MessageService } from 'app/services/message/message.service';
import { UserService } from 'app/services/user/user.service';
import {Message} from 'app/functional/models/message';
import {ActivatedRoute, Router} from '@angular/router';
import {Claims} from 'app/functional/models/user';
import { TranslateService } from '@ngx-translate/core';
import {MatLegacySelectionListChange as MatSelectionListChange} from '@angular/material/legacy-list';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CreateMailDialogComponent} from '../../../dialogs/create-mail-dialog/create-mail-dialog.component';
import {Subscription} from 'rxjs';
import {Company} from '../../../functional/models/company';
import {Role} from '../../../functional/models/role.model';
import {CompanyService} from '../../../services/company/company.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InboxComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedMessages: Message[] = [];
  messages: Message[] = [];
  id?: number;
  selectedMail?: Message;
  reply = false;
  claims!: Claims;
  companySubscription = new Subscription();
  company!: Company;
  pageEvent: PageEvent = {
    pageSize: 25,
    pageIndex: 0,
    length: 25
  };

  constructor(
    public messageService: MessageService,
    private userService: UserService,
    public activeRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private companyService: CompanyService
  ) {
    if (this.activeRoute.snapshot.params.id) {
      this.id = Number(this.activeRoute.snapshot.params.id);
    }
  }

  async ngOnInit() {
    this.claims = this.userService.tryClaims();
    if (this.claims.role === Role.superuser) {
      this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
        if (company) {
          this.company = company;
        } else {
          this.company = await this.companyService.findCurrentCompany();
        }
      });
    }
    await this.updateInbox(true);
  }

  async updateInbox(fromId: boolean) {
    let inbox: Message[];
    if (this.isOutbox()) {
      inbox = await this.messageService.getSent();
    } else {
      inbox = await this.messageService.getInbox(this.claims.userId);
    }

    this.messages = inbox.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0));

    if (fromId && this.id) {
      this.selectedMail = this.messages.find(e => e.id === this.id);
    }
    this.displayedMessages = this.messages;
  }

  openNewMessage(isReply: boolean) {
    if (!isReply) {
      const dialogRef = this.dialog.open(CreateMailDialogComponent, {
        data: {
          company: this.company,
          autoFocus: false
        },
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(async () => {
        await this.updateInbox(false);
      });
    } else {
      const dialogRef = this.dialog.open(CreateMailDialogComponent, {
        data: {
          message: this.selectedMail,
          autoFocus: false
        },
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(async () => {
        await this.updateInbox(false);
      });
    }
  }

  isOutbox() {
    return this.router.url.includes('outbox');
  }

  async setMailSelected(event: MatSelectionListChange) {
    this.selectedMail = this.messages.find(message => message.id === event.options[0].value);
    if (!this.selectedMail!.read && !this.isOutbox()) {
      if (!this.selectedMail?.read) {
        window.dispatchEvent(new Event('item-read'));
        await this.messageService.setRead(this.selectedMail!.id);
        await this.updateInbox(false);
      }
    }

    await this.loadChild(this.selectedMail!);
  }

  async loadChild(message: Message): Promise<Message> {
    if (message.replyToId) {
      message.replyTo = await this.messageService.getMessage(message.replyToId);
      if (message.replyTo.replyToId) {
        return await this.loadChild(message.replyTo);
      } else {
        return message;
      }
    }
    return message;
  }

  getDateFromString(dateString: string) {
    const date = new Date(dateString);
    const now = new Date();
    if (date.getDate() === now.getDate() && date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) {
      return (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    }
    if ((date.getDate() - 1) === now.getDate() && date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) {
      return this.translateService.instant('messaging.yesterday');
    }
    if ((date.getDate() - 2) === now.getDate() && date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) {
      return this.translateService.instant('messaging.before_yesterday');
    }
    if (date.getFullYear() === now.getFullYear()) {
      return (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
    }
    return (date.getDate() < 10 ? '0' : '') +
      date.getDate() +
      '/' +
      (date.getMonth() + 1 < 10 ? '0' : '') +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear();
  }

  applyFilter(value: string) {
    value = value.trim().toLowerCase();
    if (!value) {
      this.displayedMessages = this.messages;
    } else {
      this.displayedMessages = this.messages.filter(message => (
        message.sender.firstName + ' ' +
        message.sender.lastName + ' ' +
        message.recipient.firstName + ' ' +
        message.recipient.lastName + ' ' +
        message.subject +
        message.content
      ).toLowerCase().includes(value));
    }

    this.pageEvent = {
      pageSize: this.pageEvent.pageSize,
      pageIndex: 0,
      length: 25
    };
  }

  handleClick(event: any) {
    if (event === 'new') {
      this.openNewMessage(false);
    }
    if (event === 'reply') {
      this.openNewMessage(true);
    }
  }
}

