import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierProgressComponent } from './dossier-progress.component';



@NgModule({
  declarations: [DossierProgressComponent],
  exports: [
    DossierProgressComponent
  ],
  imports: [
    CommonModule
  ]
})
export class DossierProgressModule { }
