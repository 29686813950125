import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRecentsComponent } from './user-recents.component';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatSortModule} from '@angular/material/sort';
import {TagModule} from '../tag/tag.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {EmmaSelectModule} from '../base-components/emma-select/emma-select.module';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {FormsModule} from '@angular/forms';
import {UserIconModule} from '../user-icon/user-icon.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {EmmaTooltipModule} from '../base-components/emma-tooltip/emma-tooltip.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroFunnel } from '@ng-icons/heroicons/outline';

@NgModule({
  declarations: [
    UserRecentsComponent
  ],
  exports: [
    UserRecentsComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    TagModule,
    TranslateModule.forRoot(),
    MatIconModule,
    MatMenuModule,
    EmmaSelectModule,
    MatPaginatorModule,
    NgIconsModule.withIcons({
      heroFunnel
    }),
    MatCheckboxModule,
    FormsModule,
    UserIconModule,
    MatTooltipModule,
    EmmaTooltipModule
  ]
})
export class UserRecentsModule { }
