import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { User, UserWithMail } from '../../../functional/models/user';
import { UserService } from '../../../services/user/user.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Company } from '../../../functional/models/company';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-customer-dialog',
  templateUrl: './create-customer-dialog.component.html',
  styleUrls: ['./create-customer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'nds-NL' },
  ]
})
export class CreateCustomerDialogComponent implements OnInit, AfterViewInit {
  userForm!: UntypedFormGroup;
  company?: Company;
  companySubscription?: Subscription;
  companyId!: number;

  disableAnimation = true;
  selectedPermissions = 1;
  permissionsSelectable = true;
  isEmployee = false;

  constructor(
    public dialogRef: MatDialogRef<CreateCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private userService: UserService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.permissionsSelectable = dialogData.permissionsSelectable;
    this.isEmployee = dialogData.isEmployee;
    this.company = this.dialogData.company;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation = false);
  }

  closeModal() {
    this.dialogRef.close();
  }

  async ngOnInit() {
    this.userForm = this.formBuilder.group({
      role: [this.isEmployee ? 2 : 3 , []],
      description: ['', []],
      companyId: [this.company!.id, []],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', []],
      gender: [0, []],
      street: ['', []],
      postalCode: ['', []],
      premise: ['', []],
      houseNumber: ['', []],
      city: ['', []],
      birth: ['', []],
      relationId: ['', []],
      sendMail: [true, []]
    });
  }

  async create() {
    this.userForm.markAllAsTouched();
    if (this.userForm.invalid){
      return;
    }

    const parsedUser = this.userForm.value as UserWithMail;
    if (parsedUser.birth) {
      parsedUser.birth = this.datePipe.transform(parsedUser.birth, 'yyyy-MM-dd');
    }

    const result: User = await this.userService.createUser(parsedUser, parsedUser.sendMail);
    this.dialogRef.close({ data: result });
  }
}
