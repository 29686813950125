import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Folder} from 'app/functional/models/folder';
import {UserService} from 'app/services/user/user.service';
import {InboundMailService} from 'app/services/inbound-mail/inbound-mail.service';

@Component({
  selector: 'app-email-generator-dialog',
  templateUrl: './email-generator-dialog.component.html',
  styleUrls: ['./email-generator-dialog.component.scss']
})
export class EmailGeneratorDialogComponent implements OnInit {
  email = '';
  folder!: Folder;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmailGeneratorDialogComponent>,
    private inboundService: InboundMailService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.folder = this.data.folder.folder;
    this.email = (await this.inboundService.getMail(this.folder.id, this.userService.tryClaims().userId)).mailAddress;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
