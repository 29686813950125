<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <div class="selectable-item" [ngClass]="{'fselected': isSelected(node)}" (click)="setSelected(node)">
      <span style="display: flex; position: relative;">
        <mat-icon class="type-icon">folder</mat-icon>
        <mat-icon class="lock-icon" *ngIf="!node.canWrite">lock</mat-icon>
      </span>
      <span [ngClass]="{'bold': isOfSources(node)}">{{node.item}}</span>
    </div>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <div class="selectable-item" [ngClass]="{'fselected': isSelected(node)}" (click)="setSelected(node)">
      <span style="display: flex; position: relative;">
        <mat-icon class="type-icon">folder</mat-icon>
        <mat-icon class="lock-icon" *ngIf="!node.canWrite">lock</mat-icon>
      </span>
      <span class="folder-name-text" [ngClass]="{'bold': isOfSources(node)}">{{node.item}}</span>
    </div>
  </mat-tree-node>
</mat-tree>
