/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import { environment } from 'environments/environment';
import {AddParentForm, User, CreateUserForm, UpdateUserForm, Claims, UserFilterModel, RedactedUser} from 'app/functional/models/user';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ForgotModel, ForgotResetModel, LoginModel, ResetModel, TokenResponse } from 'app/functional/models/forms/auth';
import { CompanyService } from '../company/company.service';
import { Group } from 'app/functional/models/group';
import { Folder } from 'app/functional/models/folder';
import { Document } from 'app/functional/models/document';
import { Role } from 'app/functional/models/role.model';
import { DocumentService } from 'app/services/document/document.service';
import { FolderService } from 'app/services/folder/folder.service';
import {Company} from '../../functional/models/company';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url = `${environment.rootUrl}user`;

  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private documentService: DocumentService,
    private folderService: FolderService,
  ) {
  }

  sendWelcome(userIds: number[]) {
    return this.http.post(`${this.url}/welcome`, {userIds}).toPromise();
  }

  startLogging(userId: number, until: Date) {
    return this.http.put(`${this.url}/${userId}/log_until`, {logUntil: until.toISOString()}).toPromise();
  }

  readAll(filter?: UserFilterModel): Promise<User[]> {
    if (filter) {
      let filterParams = new HttpParams();
      if (filter.companyId) {
        filterParams = filterParams.set('company_id', filter.companyId.toString());
      }
      if (filter.role) {
        filterParams = filterParams.set('role', filter.role.toString());
      }
      if (filter.email) {
        filterParams = filterParams.set('email', filter.email.toString());
      }
      return this.http.get<User[]>(this.url, {params: filterParams}).toPromise();
    } else {
      return this.http.get<User[]>(this.url).toPromise();
    }
  }

  readByCompany(companyId: number): Promise<User[]> {
    return this.http.get<User[]>(this.url, {
      params: {
        company_id: companyId.toString(),
      }
    }).toPromise();
  }

  async refresh(): Promise<any> {
    const refresh = !!localStorage.getItem('Refresh');
    if (refresh) {
      const res: any = await this.http.post(`${this.url}/refresh`, {}).toPromise();
      localStorage.setItem('Token', res.token);
      localStorage.setItem('Refresh', res.refresh);
      return res;
    } else {
      return null;
    }
  }

  async login(loginModel: LoginModel): Promise<void> {
    // store the time before sending the request
    const curTime = Math.round((new Date()).getTime() / 1000);

    // perform the network call
    const res = await this.http.post<TokenResponse>(`${this.url}/login`, loginModel).toPromise();

    const helper = new JwtHelperService();
    const decoded = helper.decodeToken(res.token);
    if (!decoded) {
      throw new Error('Invalid token in `UserService`!');
    }
    // calculate the amount of time by which the server is ahead of the current system time.
    const offset = decoded.issuedAt - curTime;

    localStorage.setItem('Token', res.token);
    localStorage.setItem('Refresh', res.refresh);
    localStorage.setItem('Offset', offset.toString());
  }

  reset(resetModel: ResetModel) {
    return this.http.post(`${this.url}/reset`, resetModel).toPromise();
  }

  forgot(forgotModel: ForgotModel) {
    return this.http.post(`${this.url}/forgot`, forgotModel).toPromise();
  }

  forgotReset(forgotResetModel: ForgotResetModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: 'Bearer ' + forgotResetModel.token
      })
    };
    if (!!localStorage.getItem('Token')) {
      localStorage.removeItem('Token');
      localStorage.removeItem('Refresh');
    }
    return this.http.post(`${this.url}/reset`, {newPassword: forgotResetModel.newPassword}, httpOptions).toPromise();
  }

  getUser(id: number | string = ''): Promise<User> {
    if (id === '') {
      const claims = this.tryClaims();
      id = claims.userId;
    }
    return this.http.get<User>(`${this.url}/${id}`).toPromise();
  }

  createUser(createUserForm: CreateUserForm, sendMail: boolean = true): Promise<User> {
    const model = {
      ...createUserForm,
      birth: createUserForm.birth || null,
    };

    let urlParams = new HttpParams();
    urlParams = urlParams.set('send_email', sendMail.toString());

    return this.http.post<User>(`${this.url}`, model, {params: urlParams}).toPromise();
  }

  editUser(userId: number, updateUserForm: UpdateUserForm): Promise<User> {
    const model = {
      ...updateUserForm,
      birth: updateUserForm.birth ? new Date(updateUserForm.birth).toISOString().split('T')[0] : null,
    };
    return this.http.put<User>(`${this.url}/${userId}`, model).toPromise();
  }

  async getEmployees(): Promise<User[]> {
    const claims = this.tryClaims();
    if (claims.role <= 2) {
      const company = await this.companyService.findCurrentCompany();
      return this.companyService.getEmployees(company.id);
    } else {
      return this.http.get<User[]>(`${this.url}/${claims.userId}/parent_users`).toPromise();
    }
  }

  async getCustomers(company: Company): Promise<User[]> {
    const claims = this.tryClaims();
    if (claims.role <= 1) {
      return this.companyService.getCustomers(company.id);
    } else {
      return this.http.get<User[]>(`${this.url}/${claims.userId}/child_users`).toPromise();
    }
  }

  getUserEmployees(userId: number): Promise<RedactedUser[]> {
    return this.http.get<RedactedUser[]>(`${this.url}/${userId}/parent_users`).toPromise();
  }

  getUserCustomers(userId: number): Promise<User[]> {
    return this.http.get<User[]>(`${this.url}/${userId}/child_users`).toPromise();
  }

  getUserGroups(userId: number): Promise<Group[]> {
    return this.http.get<Group[]>(`${this.url}/${userId}/groups`).toPromise();
  }

  getAccesspoints(userId: number): Promise<Folder[]> {
    return this.http.get<Folder[]>(`${this.url}/${userId}/entry_points`).toPromise();
  }

  getLoginClaims(): Claims | null | undefined {
    const helper = new JwtHelperService();
    const jwt = localStorage.getItem('Token');
    if (jwt) {
      return helper.decodeToken(jwt!);
    }
  }

  tryClaims(): Claims {
    const claims = this.getLoginClaims();
    if (claims === null || claims === undefined) {
      throw new Error('login claims are undefined');
    }
    return claims;
  }

  setParent(userId: number, model: AddParentForm): Promise<void> {
    return this.http.post<void>(`${this.url}/${userId}/parent_user`, model).toPromise();
  }

  deleteAccount(userId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${userId}`).toPromise();
  }

  deleteParentUser(userId: number, parentUserId: number){
    const options = {
      headers: new HttpHeaders(),
      body: {
        parentUserId
      },
    };

    return this.http.delete<void>(`${this.url}/${userId}/parent_user`, options).toPromise();
  }

  /**
   * Returns a list of users that the provided user can send a message to.
   *
   * @param user the user that will send the messages.
   */
  async getRecipients(user: User, company?: Company): Promise<RedactedUser[]> {
    switch (user.role) {
      case Role.superuser: // fall through
      case Role.admin: {
        if (company) {
          return await this.getByCompany(company.id);
        } else {
          return await this.getByCompany(user.companyId);
        }
      }
      case Role.employee: {
        const customers = await this.getUserCustomers(user.id);
        const admins = await this.companyService.getAdmins(user.companyId);
        return [...customers, ...admins];
      }
      case Role.customer: {
        const parents = await this.getUserEmployees(user.id);
        const admins = await this.companyService.getAdmins(user.companyId);
        return [...parents, ...admins];
      }
    }
  }

  getByCompany(companyId: number): Promise<User[]> {
    return this.http.get<User[]>(this.url, {
      params: {
        company_id: companyId.toString(),
      }
    }).toPromise();
  }

  async updateDesktopToken(token: string | undefined) {
    const user = await this.getUser();
    if (token) {
      // noinspection TypeScriptValidateTypes
      const updatedUser: UpdateUserForm = {...user, desktopFcm: token};
      await this.editUser(user.id, updatedUser);
    }
  }

  async documentsCascade(userId: number): Promise<Document[]> {
    const claims = this.tryClaims();
    if (claims.role === Role.superuser || claims.role === Role.admin) {
      const company = await this.companyService.findCurrentCompany();
      return this.documentService.readByCompany(company.id); // just return the full company
    }
    return await this.http.get<Document[]>(`${this.url}/${userId}/files`).toPromise();
  }

  async foldersCascade(userId: number): Promise<Folder[]> {
    const claims = this.tryClaims();
    if (claims.role === Role.superuser || claims.role === Role.admin) {
      const company = await this.companyService.findCurrentCompany();
      return this.folderService.readByCompany(company.id); // just return the full company
    }
    return await this.http.get<Folder[]>(`${this.url}/${userId}/folders`).toPromise();
  }
}


