import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestSigningDialogComponent } from './request-signing-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  declarations: [RequestSigningDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    TranslateModule.forRoot(),
    NgIconsModule,
  ]
})
export class RequestSigningDialogModule { }
