import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { exchangeRoutes } from './pages/exchange/exchange.routing';
import { generalRoutes } from './pages/general/general.routing';
import { managementRoutes } from './pages/management/management.routing';
import { messageRoutes } from './pages/messaging/messaging.routes';
import { settingsRoutes } from './pages/settings/settingsRoutes';
import { authRoutes } from './pages/auth/auth.routing';
import { AuthGuard } from './functional/guards/auth.guard';
import { themeRoutes } from './pages/theme/themeRoutes';
import { dossierRoutes } from './pages/dossier/dossier.routing';
import { addonRoutes } from './pages/addon/addon.routing';
import { UnauthorizedGuard } from './functional/guards/unauthorized.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    children: generalRoutes,
    data: {
      breadcrumb: 'dashboard'
    },
    canActivateChild: [AuthGuard]
  },
  {
    path: 'management',
    children: managementRoutes,
    data: {
      breadcrumb: null
    },
    canActivateChild: [AuthGuard]
  },
  {
    path: 'messaging',
    children: messageRoutes,
    data: {
      breadcrumb: 'messages'
    },
    canActivateChild: [AuthGuard]
  },
  {
    path: 'settings',
    children: settingsRoutes,
    data: {
      breadcrumb: 'settings'
    },
    canActivateChild: [AuthGuard]
  },
  {
    path: 'exchange',
    children: exchangeRoutes,
    data: {
      breadcrumb: 'documents',
      renderFolderCrumbs: true,
      disableTitle: true,
    },
    canActivateChild: [AuthGuard]
  },
  {
    path: 'theme',
    children: themeRoutes,
    data: {
      breadcrumb: 'settings'
    },
    canActivateChild: [AuthGuard]
  },
  {
    path: 'dossier',
    children: dossierRoutes,
    data: {
      breadcrumb: 'dossiers',
      subMenu: ['active', 'closed', 'all']
    },
    canActivateChild: [AuthGuard]
  },
  { path: 'addons',       children: addonRoutes,        canActivateChild: [AuthGuard]         },
  { path: 'auth',         children: authRoutes,         canActivateChild: [UnauthorizedGuard] },
  { path: '',             redirectTo: 'dashboard',      pathMatch: 'full', canActivateChild: [AuthGuard]},
  { path: '**',           redirectTo: 'dashboard' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { enableTracing: false })
  ],
  exports: [],
})
export class AppRoutingModule { }
