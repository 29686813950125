import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NewCssTemplate, CssTemplate } from 'app/functional/models/css-template.model';

@Injectable({
  providedIn: 'root'
})
export class CssTemplateService {
  private url = environment.rootUrl + 'css_template';

  constructor(private http: HttpClient) {}

  create(newCssTemplate: NewCssTemplate): Promise<CssTemplate> {
    return this.http.post<CssTemplate>(this.url, newCssTemplate).toPromise();
  }

  readAll(): Promise<CssTemplate[]> {
    return this.http.get<CssTemplate[]>(this.url).toPromise();
  }

  update(cssTemplate: CssTemplate): Promise<CssTemplate> {
    return this.http.put<CssTemplate>(this.url + `/${cssTemplate.id}`, cssTemplate).toPromise();
  }

  delete(id: number): Promise<void> {
    return this.http.delete<void>(this.url + `/${id}`).toPromise();
  }
}
