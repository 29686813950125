import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Organization } from '../../../functional/models/organization.model';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Company } from '../../../functional/models/company';
import { OrganizationService } from '../../../services/organization/organization.service';
import { UserService } from '../../../services/user/user.service';
import { Role } from '../../../functional/models/role.model';
import { RedactedUser } from '../../../functional/models/user';
import { Group } from '../../../functional/models/group';
import { GroupService } from '../../../services/group/group.service';
import { EmmaSelectOption } from '../../base-components/emma-select/emma-select.component';

@Component({
  selector: 'app-search-companies-customers-employees-groups',
  templateUrl: './search-companies-customers-employees-groups.component.html',
  styleUrls: ['./search-companies-customers-employees-groups.component.scss']
})
export class SearchCompaniesCustomersEmployeesGroupsComponent implements OnInit {
  @Input() company!: Company;
  @Input() preSetOrganization?: Organization[];
  @Input() preSetUser?: RedactedUser[];
  @Input() preSetGroup?: Group[];
  @Output() valueChange: EventEmitter<EmmaSelectOption | undefined> = new EventEmitter<EmmaSelectOption | undefined>();
  @Input() set value(item: EmmaSelectOption | undefined) {
    this.lValue = item;
    this.valueChange.emit(this.lValue);
  };

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  searchable: (Organization|RedactedUser|Group)[] = [];
  filteredOptions!: Observable<(Organization|RedactedUser|Group)[]>;
  myControl = new UntypedFormControl();
  options: EmmaSelectOption[] = [];

  lValue?: EmmaSelectOption;


  constructor(
    private organizationService: OrganizationService,
    private userService: UserService,
    private groupService: GroupService
  ) {}

  async ngOnInit() {
    await this.resetOrganizations();
  }

  async resetOrganizations() {
    const claims = this.userService.tryClaims();

    if (!this.preSetOrganization) {
      if (claims.role === Role.superuser || claims.role === Role.admin) {
        this.searchable = [...this.searchable, ...(await this.organizationService.filter({ companyId: this.company.id}))];
      } else if (claims.role === Role.employee) {
        this.searchable = [...this.searchable, ...(await this.organizationService.filter({ userId: claims.userId }))];
      }
    } else {
      this.searchable = [...this.searchable, ...this.preSetOrganization];
    }

    if (!this.preSetUser) {
      if (claims.role === Role.superuser || claims.role === Role.admin) {
        this.searchable = [
          ...this.searchable,
          ...(await this.userService.getCustomers(this.company)),
          ...(await this.userService.getEmployees())
        ];
      } else if (claims.role === Role.employee) {
        this.searchable = [
          ...this.searchable,
          ...(await this.userService.getUserCustomers(claims.userId)),
          ...(await this.userService.getEmployees())
        ];
      }
    } else {
      this.searchable = [...this.searchable, ...this.preSetUser];
    }

    if (!this.preSetGroup) {
      if (claims.role === Role.superuser || claims.role === Role.admin) {
        this.searchable = [...this.searchable, ...(await this.groupService.readByCompany(claims.companyId))];
      } else if (claims.role === Role.employee) {
        this.searchable = [...this.searchable, ...(await this.groupService.readByUser(claims.userId))];
      }
    } else {
      this.searchable = [...this.searchable, ...this.preSetGroup];
    }

    this.options = this.searchable.map((item: any) => ({
      displayName: item.name || item.firstName + ' ' + item.lastName,
      value: item,
      searchObject: item
    }));
  }

  reset() {
    this.lValue = undefined;
  }
}
