<div class="confirm-dialog modal-centered">
  <div class="modal-header" *ngIf="displayImage">
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <ng-icon name="heroXCircle"></ng-icon>
    </button>
    <div [ngClass]="{'image-centered': displayImage.centered, 'padding-top': displayImage.bottom}">
      <img [src]="displayImage.image">
    </div>
  </div>
  <div class="modal-body content-centered">
    <ng-container *ngIf="data.dialogType === confirmDialogType.archived">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.closed.title'|translate}}
      </h1>
      <p>
        {{'confirm_dialog.closed.body_text_1' |translate}}
        <br/><br/>
        {{'confirm_dialog.closed.body_text_2' | translate}}
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.documentsApproved">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.accepted.title'|translate}}
      </h1>
      <p>
        {{'confirm_dialog.accepted.body_text_1'|translate}}
        <br />
        {{'confirm_dialog.accepted.body_text_2'|translate}}
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.sendToCustomer">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.sent.title_1'|translate}}
        <br />
        {{'confirm_dialog.sent.title_2'|translate}}
      </h1>
      <p>
        {{'confirm_dialog.sent.body_text_1'|translate}}
        <br />
        {{'confirm_dialog.sent.body_text_2'|translate}}
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.requestApproval">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.requested.title_1'|translate}}
        <br />
        {{'confirm_dialog.requested.title_2'|translate}}
      </h1>
      <p>
        {{'confirm_dialog.requested.body_text_1'|translate}}
        <br />
        {{'confirm_dialog.requested.body_text_2'|translate}}
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.addDocumentToClosedDossier">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.adding.title'|translate}}
      </h1>
      <p>{{'confirm_dialog.adding.body_text_1'|translate}}
        <br />
        {{'confirm_dialog.adding.body_text_2'|translate}}
        <br/><br/>
        <b>{{'confirm_dialog.adding.body_text_3'|translate}}</b>
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.submitAdministration">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.submit.title'|translate}}
      </h1>
      <p>{{'confirm_dialog.submit.body_text_1'|translate}}
        <br />
        {{'confirm_dialog.submit.body_text_2'|translate}}
        <br/><br/>
        <b>{{'confirm_dialog.submit.body_text_3'|translate}}</b>
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.administrationSubmitted">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.submitted.title_1'|translate}}
        <br />
        {{'confirm_dialog.submitted.title_2'|translate}}
      </h1>
      <p>
        {{'confirm_dialog.submitted.body_text_1'|translate}}
        <br />
        {{'confirm_dialog.submitted.body_text_2'|translate}}
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.skipFiles">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.skip_files.title_1'|translate}}
      </h1>
      <p>
        {{'confirm_dialog.skip_files.body_text_1'|translate}}
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.skipSignatures">
      <h1 class="emma-title-light text-align-center" mat-dialog-title>
        {{'confirm_dialog.skip_signatures.title_1'|translate}}
      </h1>
      <p>
        {{'confirm_dialog.skip_signatures.body_text_1'|translate}}
      </p>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.deleteUser">
      <div class="message-margin">
        <h1 class="emma-title text-align-center" mat-dialog-title>
          {{ 'dialog.delete.user' | translate }}
        </h1>
        <p class="emma-font-light text-align-center">
          {{ 'dialog.delete.quarantaine' | translate }}
          <br/>
          {{ 'dialog.delete.email' | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.deleteOrganization">
      <div class="message-margin">
        <h1 class="emma-title text-align-center" mat-dialog-title>
          {{ 'dialog.delete.organization' | translate }}
        </h1>
        <p class="emma-font-light text-align-center">
          {{ 'dialog.delete.safety_check_company' | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.deleteGroup">
      <div class="message-margin">
        <h1 class="emma-title text-align-center" mat-dialog-title>
          {{ 'dialog.delete.group' | translate }}
        </h1>
        <p class="emma-font-light text-align-center">
          {{ 'dialog.delete.safety_check_group' | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.deleteDossier">
      <div class="message-margin">
        <h1 class="emma-title text-align-center" mat-dialog-title>
          {{ 'dialog.delete.dossier' | translate }}
        </h1>
        <p class="emma-font-light text-align-center">
          {{ 'dialog.delete.safety_check_dossier' | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.moveFile">
      <div class="message-margin">
        <h1 class="emma-title text-align-center" mat-dialog-title>
          {{ 'dialog.info.move_title' | translate }}
        </h1>
        <p class="emma-font-light text-align-center">
          {{ 'dialog.info.move' | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="data.dialogType === confirmDialogType.employeeLimitReached">
      <div class="message-margin">
        <h1 class="emma-title text-align-center" mat-dialog-title>
          {{ 'dialog.info.employee_limit' | translate }}
        </h1>
        <p class="emma-font-light text-align-center">
          {{ 'dialog.info.employee_limit_reached' | translate }}
        </p>
      </div>
    </ng-container>

    <br />
    <img *ngIf="data.dialogType === 0" src="../../../../assets/img/modals/example-closed.svg">
    <button  class="btn btn-green" (click)="onSubmit()" *ngIf="
    data.dialogType !== confirmDialogType.submitAdministration &&
    data.dialogType !== confirmDialogType.deleteUser &&
    data.dialogType !== confirmDialogType.deleteOrganization &&
    data.dialogType !== confirmDialogType.deleteGroup &&
    data.dialogType !== confirmDialogType.deleteDossier">
      <b>{{'confirm_dialog.continue'|translate}}</b>

    </button>
    <button  class="btn btn-green" (click)="onSubmit()" *ngIf="
    data.dialogType === confirmDialogType.submitAdministration">
      <b>{{'confirm_dialog.send'|translate}}</b>

    </button>
    <button  class="btn btn-red" (click)="onSubmit()"
       *ngIf="
       data.dialogType === confirmDialogType.deleteUser ||
       data.dialogType === confirmDialogType.deleteOrganization ||
       data.dialogType === confirmDialogType.deleteGroup ||
       data.dialogType === confirmDialogType.deleteDossier"
    >
      <b>{{'confirm_dialog.delete'|translate}}</b>

    </button>
    <p class="link-text"
       *ngIf="
       data.dialogType !== confirmDialogType.addDocumentToClosedDossier &&
       data.dialogType !== confirmDialogType.submitAdministration &&
       data.dialogType !== confirmDialogType.deleteUser &&
       data.dialogType !== confirmDialogType.deleteOrganization &&
       data.dialogType !== confirmDialogType.deleteGroup &&
       data.dialogType !== confirmDialogType.deleteDossier &&
       data.dialogType !== confirmDialogType.moveFile &&
       data.dialogType !== confirmDialogType.employeeLimitReached"
       (click)="onSubmit(true)">{{'confirm_dialog.to_dashboard'|translate}}</p>
    <p class="link-text"
       *ngIf="
       data.dialogType === confirmDialogType.addDocumentToClosedDossier ||
       data.dialogType === confirmDialogType.submitAdministration ||
       data.dialogType === confirmDialogType.deleteUser ||
       data.dialogType === confirmDialogType.deleteOrganization ||
       data.dialogType === confirmDialogType.deleteGroup ||
       data.dialogType === confirmDialogType.deleteDossier ||
       data.dialogType === confirmDialogType.moveFile"
       (click)="closeModal()"><{{'confirm_dialog.go_back'|translate}}</p>
  </div>
</div>
