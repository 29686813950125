<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div class="add-chat-user-dialog">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding">{{ 'chat.beheren' | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <p class="emma-subtitle subtitle-alt">{{ 'chat.informatie' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="emma-input-container">
        <label label for="name" class="emma-input-label">{{'chat.name_placeholder' | translate }}</label>
        <input appEmmaInput id="name" formControlName="name">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <p class="emma-subtitle subtitle-alt">{{ 'chat.customer' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-search-companies-and-customers [company]="company" (valueChange)="onSelect($event?.value)" *ngIf="selectedUsers.length === 0 && !selectedOrganization && !chat.organization"></app-search-companies-and-customers>
      <app-search-customers [company]="company" (valueChange)="onSelect($event?.value)" *ngIf="selectedUsers.length > 0 && !selectedOrganization && !chat.organization" #customerSearch></app-search-customers>
    </div>
  </div>
  <div class="row" *ngIf="selectedOrganization">
    <div class="col-md-10 col-sm-10">
      <p class="emma-font-light">{{selectedOrganization.name}}</p>
    </div>
    <div class="col-sm-2 col-md-2" *ngIf="!chat.organization">
      <mat-icon class="emma-font-light delete" (click)="removeOrganization()">delete</mat-icon>
    </div>
  </div>
  <ng-container *ngIf="selectedUsers.length > 0">
    <div class="row" *ngFor="let selectedUser of selectedUsers">
      <div class="col-md-10 col-sm-10">
        <p class="emma-font-light">{{selectedUser.firstName + ' ' + selectedUser.lastName}}</p>
      </div>
      <div class="col-sm-2 col-md-2">
        <mat-icon class="emma-font-light delete" (click)="removeUser(selectedUser.id)">delete</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </div>
  </ng-container>

  <div class="row" *ngIf="selectedOrganization">
    <div class="col-md-12">
      <p class="emma-subtitle1"> {{ 'chat.contact_persons' | translate }} </p>
    </div>
  </div>
  <div class="row" *ngIf="selectedOrganization">
    <div class="col-md-12">
      <ng-container *ngFor="let userSelection of organizationUsers">
        <mat-checkbox [(ngModel)]="userSelection[1]">
          {{userSelection[0].firstName + ' ' + userSelection[0].lastName}}
        </mat-checkbox><br/>
      </ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <p class="emma-subtitle subtitle-alt">{{ 'chat.colleagues' | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-search-employees (valueChange)="addColleagues($event?.value)" [company]="company" #colleagueSearch *ngIf="company"></app-search-employees>
    </div>
  </div>

  <div class="row" *ngFor="let colleague of colleagues; let first = first;">
    <div class="col-sm-10 col-md-10" *ngIf="claims.userId !== colleague.id">
      <p class="emma-font-light">
        {{colleague.firstName + ' ' + colleague.lastName}}
      </p>
    </div>
    <div class="col-sm-10 col-md-10" *ngIf="claims.userId === colleague.id">
      <p class="emma-font-light">
        U
      </p>
    </div>
    <div class="col-sm-2 col-md-2" *ngIf="claims.userId !== colleague.id">
      <mat-icon class="emma-font-light delete" (click)="removeColleague(colleague.id)">delete</mat-icon>
    </div>
    <mat-divider></mat-divider>
  </div>

  <div class="row">
    <div class="col-md-12 col-centered">
      <button mat-button class="btn btn-blue btn-custom" (click)="editChat()">CHAT WIJZIGEN</button>
    </div>
  </div>
</div>
