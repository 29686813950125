import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchEmployeesComponent } from './search-employees.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {AutocompleteSelectModule} from '../../base-components/autocomplete-select/autocomplete-select.module';

@NgModule({
  declarations: [
    SearchEmployeesComponent
  ],
  exports: [
    SearchEmployeesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forRoot(),
    AutocompleteSelectModule,
  ]
})
export class SearchEmployeesModule { }
