import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateFolderDialogComponent } from './create-folder-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {SearchCustomersModule} from '../../../components/search/search-customers/search-customers.module';
import {SearchEmployeesModule} from '../../../components/search/search-employees/search-employees.module';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { NgIconsModule } from '@ng-icons/core';
import {EmmaInputModule} from '../../../components/base-components/emma-input/emma-input.module';



@NgModule({
  declarations: [CreateFolderDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    SearchCustomersModule,
    SearchEmployeesModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    NgIconsModule,
    EmmaInputModule
  ]
})
export class CreateFolderDialogModule { }
