<div class="emma-body-white">
  <div id="workflow-editor" style="width: 100%; height: 100%">
    <div style="height: 100%; width: 100%; display: flex">
      <mat-drawer-container [ngClass]="{'drawer-width-opened': opened}" class="example-container">
        <mat-drawer #drawer [(opened)]="opened" [mode]="'side'" class="drawer-width">
          <ng-container *ngIf="selectedState && dossierTemplate && !selectedTab">
            <div style="display: flex">
              <mat-icon (click)="handleClick(undefined)" class="icon-button" style="margin-right: 20px; cursor: pointer">arrow_back
              </mat-icon>
              <div style="justify-content: space-between; display: flex; width: 100%">
                <p class="emma-subtitle">{{'workflow_editor.step' | translate}}:</p>
                <span style="display: flex">
                  <mat-icon
                    (click)="editDossierState(selectedState)"
                    *ngIf="!isEditingState(selectedState.id)"
                    class="icon-button">
                    edit
                  </mat-icon>

                  <mat-icon
                    (click)="updateDossierState(selectedState.id)"
                    *ngIf="isEditingState(selectedState.id)"
                    class="icon-button">
                    check
                  </mat-icon>

                  <mat-icon
                    (click)="undoEditDossierState(selectedState)"
                    *ngIf="isEditingState(selectedState.id)"
                    class="icon-button">
                    close
                  </mat-icon>
                </span>
              </div>
            </div>

            <mat-form-field>
              <mat-label>
                {{'workflow_editor.name' | translate}}
              </mat-label>
              <input
                [(ngModel)]="selectedState.name"
                [disabled]="!isEditingState(selectedState.id)"
                matInput>
            </mat-form-field>

            <mat-checkbox
              [(ngModel)]="selectedState.mayHaveDeadline"
              [disabled]="!isEditingState(selectedState.id)">
              {{'workflow_editor.has_deadline' | translate}}
            </mat-checkbox>

            <div *ngIf="!selectedState.finalState" class="flex-between margin-top-20">
              <p class="emma-subtitle">{{'workflow_editor.actions' | translate}}</p>
              <mat-icon (click)="addAction()" class="icon-button add-button">add_circle_outline</mat-icon>
            </div>

            <p *ngIf="!selectedState.finalState && selectedState?.actions?.length === 0" class="emma-font-light">
              {{'workflow_editor.no_actions' | translate}}
            </p>
            <mat-accordion>
              <mat-expansion-panel *ngFor="let action of selectedState!.actions">
                <mat-expansion-panel-header>
                  {{action.name}}
                  <span style="display: flex">
                            <mat-icon
                              (click)="$event.preventDefault(); $event.stopPropagation(); editAction(action);"
                              *ngIf="!isEditingAction(action.id)"
                              class="icon-button">
                              edit
                            </mat-icon>
                            <mat-icon
                              (click)="$event.preventDefault(); $event.stopPropagation(); updateAction(action.id);"
                              *ngIf="isEditingAction(action.id)"
                              class="icon-button">
                              check
                            </mat-icon>
                            <mat-icon
                              (click)="$event.preventDefault(); $event.stopPropagation(); undoEditAction(action);"
                              *ngIf="isEditingAction(action.id)"
                              class="icon-button">
                              close
                            </mat-icon>
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); deleteAction(action);"
                                      class="icon-button trash">delete</mat-icon>
                          </span>
                </mat-expansion-panel-header>
                <mat-form-field>
                  <mat-label>
                    {{'workflow_editor.name' | translate}}
                  </mat-label>
                  <input
                    [(ngModel)]="action.name"
                    [disabled]="!isEditingAction(action.id)"
                    matInput>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>
                    {{'workflow_editor.type' | translate}}
                  </mat-label>
                  <mat-select
                    [(ngModel)]="action.event"
                    [disabled]="!isEditingAction(action.id)">
                    <mat-option *ngFor="let event of dossierEvents" [value]="event.event">
                      {{event.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>
                    {{'workflow_editor.target' | translate}}
                  </mat-label>
                  <mat-select
                    [(ngModel)]="action.destinationStateTemplateId"
                    [disabled]="!isEditingAction(action.id)">
                    <mat-option *ngFor="let state of dossierTemplate.states" [value]="state.id">
                      {{state.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-checkbox
                  [(ngModel)]="action.isHappy"
                  [disabled]="!isEditingAction(action.id)">
                  {{'workflow_editor.is_main' | translate}}
                </mat-checkbox>
              </mat-expansion-panel>
            </mat-accordion>

            <div class="flex-between margin-top-20">
              <p class="emma-subtitle">{{'workflow_editor.permissions' | translate}}</p>
              <mat-icon (click)="addPermissions()" class="icon-button add-button">add_circle_outline</mat-icon>
            </div>
            <p *ngIf="selectedState!.permissions && selectedState!.permissions.length === 0" class="emma-font-light">
              Deze stap heeft geen rechten mutaties</p>
            <mat-accordion>
              <ng-container *ngFor="let permission of selectedState!.permissions">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    {{permission[0] ? getFolder(permission[0])!.name : 'Alle mappen'}}
                    <span style="display: flex">
                              <mat-icon
                                (click)="$event.preventDefault(); $event.stopPropagation(); editPermission(permission)"
                                *ngIf="!isEditingPermission(permission[0])"
                                class="icon-button">
                                edit
                              </mat-icon>
                              <mat-icon
                                (click)="$event.preventDefault(); $event.stopPropagation(); updatePermission(permission[0])"
                                *ngIf="isEditingPermission(permission[0])"
                                class="icon-button">check</mat-icon>
                              <mat-icon
                                (click)="$event.preventDefault(); $event.stopPropagation(); undoEditPermission(permission)"
                                *ngIf="isEditingPermission(permission[0])"
                                class="icon-button">close</mat-icon>
                              <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); deleteFolderPermission(selectedState, permission);"
                                        class="icon-button trash">delete</mat-icon>
                            </span>
                  </mat-expansion-panel-header>

                  <table style="width: 100%;">
                    <tr>
                      <th>{{'workflow_editor.role' | translate}}</th>
                      <th>{{'workflow_editor.permissions' | translate}}</th>
                      <th></th>
                    </tr>

                    <tr *ngFor="let perm of permission[1]">
                      <td *ngIf="!perm.role">{{'Alle rollen'}}</td>
                      <td
                        *ngIf="perm.role">{{isCustomerRole(perm) ? ('workflow_editor.customer' | translate) : ('workflow_editor.employee' | translate) }}</td>
                      <td>
                        <mat-form-field class="full-width">
                          <mat-select [(ngModel)]="perm.permissionString"
                                      [disabled]="!isEditingPermission(permission[0])">
                            <mat-option
                              [value]="''">{{ 'dossier_template.dialog.add_permission.no_rights' | translate }}</mat-option>
                            <mat-option
                              [value]="'r'">{{ 'dossier_template.dialog.add_permission.read' | translate }}</mat-option>
                            <mat-option
                              [value]="'rwd'">{{ 'dossier_template.dialog.add_permission.read_write' | translate }}</mat-option>
                            <mat-option
                              [value]="'rwcdm'">{{ 'dossier_template.dialog.add_permission.manage' | translate }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); deletePermission(selectedState, perm);"
                                  class="icon-button trash">
                          delete
                        </mat-icon>
                      </td>
                    </tr>
                  </table>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </ng-container>
          <ng-container *ngIf="!selectedState && dossierTemplate && !selectedTab">
            <div class="flex-between margin-top-20">
              <p class="emma-subtitle">{{'workflow_editor.folders' | translate}}</p>
              <mat-icon (click)="addFolder()" class="icon-button add-button">add_circle_outline</mat-icon>
            </div>
            <p *ngIf="dossierFolders.length === 0"
               class="emma-font-light">{{'workflow_editor.no_folders' | translate}}</p>
            <mat-accordion>
              <mat-expansion-panel *ngFor="let folder of dossierFolders">
                <mat-expansion-panel-header>
                  {{folder.name}}
                  <span style="display: flex">
                            <mat-icon
                              (click)="$event.preventDefault(); $event.stopPropagation(); editFolder(folder)"
                              *ngIf="!isEditingFolder(folder.id)"
                              class="icon-button">
                              edit
                            </mat-icon>
                            <mat-icon
                              (click)="$event.preventDefault(); $event.stopPropagation(); updateFolder(folder.id)"
                              *ngIf="isEditingFolder(folder.id)"
                              class="icon-button">
                              check
                            </mat-icon>
                            <mat-icon
                              (click)="$event.preventDefault(); $event.stopPropagation(); undoEditFolder(folder)"
                              *ngIf="isEditingFolder(folder.id)"
                              class="icon-button">
                              close
                            </mat-icon>
                            <mat-icon
                              (click)="$event.preventDefault(); $event.stopPropagation(); deleteFolder(folder);"
                              class="icon-button trash">
                              delete
                            </mat-icon>
                          </span>
                </mat-expansion-panel-header>

                <mat-form-field>
                  <mat-label>
                    {{'workflow_editor.name' | translate}}
                  </mat-label>
                  <input
                    [(ngModel)]="folder.name"
                    [disabled]="!isEditingFolder(folder.id)"
                    matInput>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>
                    {{'workflow_editor.description' | translate}}
                  </mat-label>
                  <input
                    [(ngModel)]="folder.description"
                    [disabled]="!isEditingFolder(folder.id)"
                    matInput>
                </mat-form-field>

                <mat-checkbox
                  [(ngModel)]="folder.isFinalFolder"
                  [disabled]="!isEditingFolder(folder.id)">
                  {{'workflow_editor.sign_folder' | translate}}
                </mat-checkbox>

                <ng-container>
                  <div class="flex-between margin-top-20">
                    <p class="emma-subtitle">{{'workflow_editor.file_request' | translate}}</p>
                    <mat-icon (click)="addFileRequests(folder)" class="icon-button">add_circle_outline</mat-icon>
                  </div>

                  <p *ngIf="dossierFileRequests.length === 0" class="emma-font-light">
                    {{'workflow_editor.no_file_requests' | translate}}
                  </p>

                  <mat-accordion>
                    <ng-container *ngFor="let request of dossierFileRequests">
                      <mat-expansion-panel *ngIf="request.folderTemplateId === folder.id">
                        <mat-expansion-panel-header>
                          {{request.filename}}
                          <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); deleteFileRequest(request);"
                                    class="icon-button trash">
                            delete
                          </mat-icon>
                        </mat-expansion-panel-header>
                        <mat-form-field>
                          <mat-label>
                            {{'workflow_editor.name' | translate}}
                          </mat-label>
                          <input [(ngModel)]="request.filename" disabled matInput>
                        </mat-form-field>
                        <mat-form-field>
                          <mat-label>
                            {{'workflow_editor.description' | translate}}
                          </mat-label>
                          <input [(ngModel)]="request.message" disabled matInput>
                        </mat-form-field>
                      </mat-expansion-panel>
                    </ng-container>
                  </mat-accordion>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </mat-drawer>
        <mat-drawer-content (click)="drawer.toggle()">
          <mat-icon *ngIf="opened">keyboard_arrow_left</mat-icon>
          <mat-icon *ngIf="!opened">keyboard_arrow_right</mat-icon>
        </mat-drawer-content>
      </mat-drawer-container>
      <div class="jtk-demo-main" id="workflow-container">
        <div class="workflow-nav">
          <div class="workflow-nav-tile">
            <ng-icon
              (click)="zoomIn()"
              [appEmmaTooltip]="'Inzoomen'"
              class="ng-icon-default workflow-nav-button"
              name="heroMagnifyingGlassPlus">
            </ng-icon>
            <ng-icon
              (click)="zoomOut()"
              [appEmmaTooltip]="'Uitzoomen'"
              class="ng-icon-default workflow-nav-button"
              name="heroMagnifyingGlassMinus">
            </ng-icon>
            <ng-icon
              [appEmmaTooltip]="'Volgorde resetten'"
              class="ng-icon-default workflow-nav-button"
              name="heroSquares2x2">
            </ng-icon>
          </div>
          <div class="workflow-nav-tile">
            <ng-icon
              (click)="addStep()"
              [appEmmaTooltip]="'Stap toevoegen'"
              class="ng-icon-default workflow-nav-button"
              name="heroSquaresPlus"></ng-icon>
            <ng-icon
              (click)="deleteStep(selectedState)"
              [appEmmaTooltip]="'Verwijderen'"
              [ngClass]="{'disabled-icon': selectedState === undefined}"
              class="ng-icon-default workflow-nav-button"
              name="heroTrash"></ng-icon>
          </div>
          <div class="workflow-nav-tile">
            <ng-icon
              [appEmmaTooltip]="'Legenda'"
              class="ng-icon-default workflow-nav-button"
              name="heroInformationCircle"></ng-icon>
          </div>
        </div>
        <div
          #canvas
          (mousedown)="panning = $event.target"
          (mouseup)="panning = null"
          (pan)="dragCanvas(canvas, $event)"
          class="jtk-demo-canvas canvas-wide flowchart-demo jtk-surface"
          id="canvas">
        </div>
      </div>
    </div>
  </div>
</div>
