import { Injectable } from '@angular/core';
import {filter, pairwise} from 'rxjs/operators';
import {Router, RoutesRecognized} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  previousNavs: string[] = [];

  constructor(private router: Router) {
    this.router.events.pipe(filter((e: any) => e instanceof RoutesRecognized), pairwise()).subscribe((e: any) => {
      this.previousNavs.push(e[0].urlAfterRedirects);
    });
  }

  getNavigations() {
    return this.previousNavs;
  }
}
