import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrganizationService} from '../../../services/organization/organization.service';
import {Organization} from '../../../functional/models/organization.model';
import {Company} from '../../../functional/models/company';
import {UserService} from '../../../services/user/user.service';
import {Role} from '../../../functional/models/role.model';
import {EmmaSelectOption} from '../../base-components/emma-select/emma-select.component';

@Component({
  selector: 'app-search-companies',
  templateUrl: './search-companies.component.html',
  styleUrls: ['./search-companies.component.scss']
})
export class SearchCompaniesComponent implements OnInit {
  @Input() company!: Company;
  @Input() preSet?: Organization[];
  @Output() valueChange: EventEmitter<EmmaSelectOption | undefined> = new EventEmitter<EmmaSelectOption | undefined>();
  @Input() set value(item: EmmaSelectOption | undefined) {
    this.lValue = item;
    this.valueChange.emit(this.lValue);
  };

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  options: EmmaSelectOption[] = [];
  lValue?: EmmaSelectOption;
  organizations: Organization[] = [];

  constructor(private organizationService: OrganizationService, private userService: UserService) {}

  async ngOnInit() {
    await this.resetOrganizations();
  }

  async resetOrganizations() {

    if (!this.preSet) {
      const claims = this.userService.tryClaims();

      if (claims.role === Role.superuser || claims.role === Role.admin) {
        this.organizations = await this.organizationService.filter({ companyId: this.company.id});
      } else if (claims.role === Role.employee) {
        this.organizations = await this.organizationService.filter({ userId: claims.userId });
      }

    } else {
      this.organizations = this.preSet;
    }

    this.options = this.organizations.map((item: Organization) => ({
      displayName: item.name,
      value: item,
      searchObject: item
    }));
  }

  clearSelected() {
    this.lValue = undefined;
  }
}
