<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div class="start-logging-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'dialog.logging.title' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <form [formGroup]="dateForm" *ngIf="dateForm">
        <div class="emma-input-container">
          <label label for="date" class="emma-input-label">{{'dialog.logging.until'  | translate }}</label>
          <div class="emma-input">
            <input id="date" appEmmaInput formControlName="date" [matDatepicker]="picker">
            <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
          </div>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button  class="btn btn-green" (click)="startLogging()">
        <b> {{ 'dialog.logging.submit' | translate }} </b>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <p class="link-text" (click)="closeModal()">< {{'confirm_dialog.go_back'|translate}}</p>
    </div>
  </div>
</div>
