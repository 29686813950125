import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-editor',
  templateUrl: './survey-editor.component.html',
  styleUrls: ['./survey-editor.component.scss']
})
export class SurveyEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
