import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../../services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { MfaService } from '../../../services/mfa-service/mfa.service';
import { Company } from '../../../functional/models/company';
import { MFAOptionModel } from '../../../functional/models/mfa.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UrlService } from '../../../services/url/url.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.scss']
})
export class AuthenticatorComponent implements OnInit {

  qrRequired = true;
  company!: Company;
  returnUrl!: string;
  qrUrl!: string;
  method!: MFAOptionModel;
  codeForm!: UntypedFormGroup;
  routeUrl!: string;
  isMobile!: boolean;

  constructor(
      public userService: UserService,
      public router: Router,
      private formBuilder: UntypedFormBuilder,
      public companyService: CompanyService,
      public translate: TranslateService,
      private activeRoute: ActivatedRoute,
      public mfaService: MfaService,
      public urlService: UrlService,
      public snackBar: MatSnackBar,
  ) {
    this.returnUrl = this.activeRoute.snapshot.queryParams.returnUrl || 'dashboard';
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
    this.isMobile = window.innerWidth <= 991;
  }

  async ngOnInit(): Promise<void> {
    this.isMobile = window.innerWidth <= 991;
    const mfaToken = localStorage.getItem('MFAToken');
    const helper = new JwtHelperService();
    if (!mfaToken || helper.isTokenExpired(mfaToken)) {
      this.router.navigate(['auth'], {queryParams: {returnUrl: this.returnUrl}});
    }

    this.company = await this.companyService.findCurrentCompany();
    const user = this.mfaService.getMFAUser();
    const method = await this.mfaService.getMethods(0 , user!.userId);

    this.codeForm = this.formBuilder.group({
      field1: ['', [Validators.required]],
      field2: ['', [Validators.required]],
      field3: ['', [Validators.required]],
      field4: ['', [Validators.required]],
      field5: ['', [Validators.required]],
      field6: ['', [Validators.required]],
    });

    if (method.length === 0) {
      this.qrRequired = true;
    } else {
      this.qrRequired = true;
      for (const factor of method) {
        if (factor.kind === 1 && factor.isConfigured) {
          this.qrRequired = false;
          this.method = factor;
        }
      }
    }

    if (this.qrRequired) {
      //TODO dirty fix in order to circumvent the deprecated google charts api
      this.qrUrl = (await this.mfaService.configureAuthenticator()).url.replace('https://chart.googleapis.com/chart?','https://image-charts.com/chart?');
    }

    this.routeUrl = 'https://' + document.location.hostname + '/' + this.returnUrl;
  }

  focusNext(event: KeyboardEvent) {
    const element: HTMLInputElement = event.target as HTMLInputElement;
    if (event.key === 'Backspace') {
      return;
    }
    if (element.value.length === element.maxLength) {
      const nextId = Number(element.id.split('-')[1]) + 1;
      if (nextId > 6) {
        return;
      }
      document.getElementById('totp-' + nextId)!.focus();
    }
  }

  deleteNext(event: KeyboardEvent) {
    const element: HTMLInputElement = event.target as HTMLInputElement;
    if (event.key === 'Backspace') {
      if (element.value.length >= 1) {
        element.value = '';
        const previousId = Number(element.id.split('-')[1]) - 1;

        if (previousId > 0) {
          const previousElement = document.getElementById('totp-' + previousId);
          previousElement!.focus();
          event.preventDefault();
          event.stopPropagation();
        }
      }
      if (element.value.length === 0) {
        const previousId = Number(element.id.split('-')[1]) - 1;
        if (previousId > 0) {
          const previousElement = document.getElementById('totp-' + previousId);
          previousElement!.focus();
          event.preventDefault();
          event.stopPropagation();
        }
      }
    } else {
      if (element.value.length === element.maxLength) {
        const nextId = Number(element.id.split('-')[1]) + 1;
        if (nextId > 6) {
          event.preventDefault();
          event.stopPropagation();
        }
        document.getElementById('totp-' + nextId)!.focus();
      }
    }
  }

  async next() {
    this.qrRequired = false;
  }

  async submit() {
    this.codeForm.markAllAsTouched();
    if(this.codeForm.invalid){
      return;
    }

    try {
      const verifyToken =
        this.codeForm.get('field1')!.value.toString() +
        this.codeForm.get('field2')!.value.toString() +
        this.codeForm.get('field3')!.value.toString() +
        this.codeForm.get('field4')!.value.toString() +
        this.codeForm.get('field5')!.value.toString() +
        this.codeForm.get('field6')!.value.toString();

      if (!this.method) {
        await this.mfaService.verifyIntialAuthenticator(verifyToken);
        await this.router.navigate([this.returnUrl]);
        window.location.reload();
      } else {
        await this.mfaService.verifyAuthenticator(verifyToken);
        await this.router.navigate([this.returnUrl]);
        window.location.reload();
      }
    } catch (e) {
      this.snackBar.open(this.translate.instant('auth.authenticator.wrong'), this.translate.instant('close'), {duration: 5000});
    }
  }
}
