import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierOverviewComponent } from './dossier-overview.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {FormsModule} from '@angular/forms';
import {UserIconModule} from '../../user-icon/user-icon.module';
import {RouterModule} from '@angular/router';
import {EmmaTooltipModule} from '../../base-components/emma-tooltip/emma-tooltip.module';

@NgModule({
  declarations: [
    DossierOverviewComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatProgressBarModule,
    TranslateModule.forRoot(),
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    FormsModule,
    UserIconModule,
    RouterModule,
    EmmaTooltipModule,
  ],
  exports: [
    DossierOverviewComponent
  ]
})
export class DossierOverviewModule { }
