<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="add-employee-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{ 'add.employee' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <app-search-employees (valueChange)="setSelected($event?.value)" class="employee-search"></app-search-employees>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <button  class="btn btn-blue btn-custom" (click)="submit()">
        <b>{{ 'add.employee' | translate }}</b>

      </button>
    </div>
  </div>
</div>
