import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExplorerComponent } from './explorer/explorer.component';
import { ViewerComponent } from './viewer/viewer.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import {SubNavModule} from '../../components/sub-nav/sub-nav.module';
import {ExchangeOverviewModule} from '../../components/exchange/exchange-overview/exchange-overview.module';
import { SafePipe } from 'app/functional/pipes/safe.pipe';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {ExchangeDialogsModule} from '../../dialogs/exchange/exchange-dialogs.module';
import {DenyDocumentDialogModule} from '../../dialogs/signing/deny-document-dialog/deny-document-dialog.module';
import {SignDocumentDialogModule} from '../../dialogs/signing/sign-document-dialog/sign-document-dialog.module';
import {EmmaSelectModule} from '../../components/base-components/emma-select/emma-select.module';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';

@NgModule({
    imports: [
        TranslateModule.forRoot(),
        CommonModule,
        SubNavModule,
        ExchangeOverviewModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        ExchangeDialogsModule,
        DenyDocumentDialogModule,
        SignDocumentDialogModule,
        EmmaSelectModule,
        MatMenuModule,
    ],
    declarations: [
        ExplorerComponent,
        SafePipe,
        ViewerComponent
    ],
    exports: [
        SafePipe,
        ExplorerComponent
    ]
})
export class ExchangeModule { }
