import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import { CreateFolderDialogComponent } from '../../../dialogs/dossiers/create-folder-dialog/create-folder-dialog.component';
import { DossierService } from '../../../services/dossier/dossier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Dossier, DossierAction, DossierEvent, Progress} from '../../../functional/models/dossier.model';
import {
  CustomerInformationDialogComponent,
} from '../../../dialogs/dossiers/customer-information-dialog/customer-information-dialog.component';
import { UserService } from '../../../services/user/user.service';
import { Role } from '../../../functional/models/role.model';
import { Claims } from 'app/functional/models/user';
import {ConfirmDialogType} from '../../../functional/models/dialog.model';
import {NavVariableService} from '../../../services/nav-variable/nav-variable.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dossier-details',
  templateUrl: './dossier-details.component.html',
  styleUrls: ['./dossier-details.component.scss'],
})
export class DossierDetailsComponent implements OnInit {
  dossierId!: number;
  dossier!: Dossier;
  progress!: Progress;
  claims!: Claims;
  finalAccepted = false;
  canAdvance?: boolean;
  deadline!: Date | undefined;
  daysUntil = 0;
  activeTab!: string;

  constructor(
    private dialog: MatDialog,
    private dossierService: DossierService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private navVariableService: NavVariableService,
    private translate: TranslateService
  ) {
    this.dossierId = this.activeRoute.snapshot.params.id;
  }

  async ngOnInit() {
    this.claims = this.userService.tryClaims();
    await this.loadDossier();

    window.addEventListener('folder-deleted', (e: any) => this.loadDossier(e.detail.dossierId()), false);
    window.addEventListener('request-made', (e: any) => this.loadDossier(e.detail.dossierId()), false);
    window.addEventListener('dossier-updated', (e: any) => this.loadDossier(e.detail.dossierId()), false);
  }

  updateProgressAndStates() {
    this.progress =  Dossier.getDossierProgress(this.dossier);

    const state = Dossier.orderStates(this.dossier).find(dossierState => !dossierState.finalState && dossierState.deadline);
    if (state) {
      this.deadline = new Date(state.deadline!.toString());
      this.daysUntil = Math.round(((this.deadline!.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
    }
  }

  async loadDossier(id?: any) {
    if (id && Number(id) !== Number(this.dossierId)) {
      return;
    }
    this.dossier = await this.dossierService.get(this.dossierId);
    const happyAction = Dossier.getHappyAction(this.dossier.dossierState);
    if (happyAction && this.isManualAction(happyAction)) {
      // we know that there is a happy actor if there is a happy action.
      const actor = Dossier.getHappyActor(this.dossier)!;
      if (this.claims.role === Role.customer) {
        this.canAdvance = actor.customer;
      } else {
        this.canAdvance = actor.employee;
      }
    }
    if (!this.canAdvance) {
      for (const action of this.dossier.dossierState.actions) {
        if (!action.isHappy && this.isManualAction(action)) {
          this.canAdvance = (action.event === DossierEvent.manualEmployeeAction && this.claims.role !== Role.customer) ||
            (action.event === DossierEvent.manualCustomerAction && this.claims.role === Role.customer);
        }
      }
    }
    this.updateProgressAndStates();
    this.navVariableService.navVariables.next({dossier: this.dossier});
  }

  /**
   * This function checks whether an action must be performed manually.
   *
   * @param action The action that is checked.
   */
  isManualAction(action: DossierAction): boolean {
    return (
      action.event === DossierEvent.manualCustomerAction ||
      action.event === DossierEvent.manualEmployeeAction ||
      action.event === DossierEvent.allFileRequestsCompleted
    );
  }

  isUserAction(action: DossierAction) {
    return (action.event === DossierEvent.manualEmployeeAction && this.claims.role !== Role.customer) ||
      (action.event === DossierEvent.manualCustomerAction && this.claims.role === Role.customer);
  }

  isUserEmployee() {
    return this.claims.role !== Role.customer;
  }

  /**
   * Temporary function that returns a string representation of the provided enum variant.
   *
   * @param action The action that is to be stringified
   */
  getActionName(action: DossierAction): string {
    switch (action.event) {
      case (DossierEvent.manualEmployeeAction):
        if (this.isFinalAction(action)) {
          return this.translate.instant('dossier.close_this_dossier');
        }
        return this.translate.instant('dossier.dossier_to_customer');
      case (DossierEvent.manualCustomerAction):
        return this.translate.instant('dossier.send_dossier');
      case (DossierEvent.allSigningsDone):
        return this.translate.instant('dossier.send_dossier');
      case (DossierEvent.allFileRequestsCompleted):
        return this.translate.instant('dossier.send_dossier');
    }
    throw new Error('unsupported `DossierEvent`');
  }

  isFinalAction(action: DossierAction) {
    return action.name.toLowerCase().includes('sluit'); // Sluit dossier
  }

  isControlAndPrepare(action: DossierAction) {
    return action.name.toLowerCase().includes('geen signeringen') ||
      action.name.toLowerCase().includes('geen accorderingen'); // No signing required
  }

  isDossierToCustomer(action: DossierAction) {
    return action.name.toLowerCase().includes('dossier naar klant');
  }

  isCustomerDelivers(action: DossierAction) {
    return action.name.toLowerCase().includes('geen documenten nodig');
  }

  isRequestApproval(action: DossierAction) {
    return action.name.toLowerCase().includes('klant signeert / accordeert');
  }

  isOpenDossier(action: DossierAction) {
    return action.name.toLowerCase().includes('open dit dossier');
  }

  getDialogType(action: DossierAction): number | null {
    switch (action.event) {
      case (DossierEvent.manualEmployeeAction):
        if (this.isFinalAction(action)) {
          return ConfirmDialogType.archived;
        } else if (this.isControlAndPrepare(action)) {
          return ConfirmDialogType.skipSignatures;
        } else if (this.isDossierToCustomer(action) || this.isOpenDossier(action)) {
          return ConfirmDialogType.sendToCustomer;
        } else if (this.isCustomerDelivers(action)) {
          return ConfirmDialogType.skipFiles;
        } else if (this.isRequestApproval(action)) {
          return ConfirmDialogType.requestApproval;
        } else {
          return null;
        }
      case (DossierEvent.manualCustomerAction):
        return ConfirmDialogType.submitAdministration;
      case (DossierEvent.allFileRequestsCompleted):
        return ConfirmDialogType.submitAdministration;
      case (DossierEvent.allSigningsDone):
        return ConfirmDialogType.administrationSubmitted;
    }
    return null;
  }

  async nextStage(action: DossierAction) {
    const dialogType = this.getDialogType(action);
    if (dialogType) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        panelClass: 'confirm-dialog',
        data: {
          dialogType: this.getDialogType(action)
        },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result?.confirmed) {
          await this.dossierService.advance(this.dossierId, action.id);

          if (result.toDashboard) {
            await this.router.navigate(['dashboard']);
          } else {
            await this.loadDossier(this.dossierId);
            this.updateProgressAndStates();
          }

          if (action.event === DossierEvent.manualCustomerAction || action.event === DossierEvent.allFileRequestsCompleted) {
            this.dialog.open(ConfirmDialogComponent, {
              panelClass: 'confirm-dialog',
              data: {
                dialogType: ConfirmDialogType.administrationSubmitted
              },
              autoFocus: false
            });
          }
        }
      });
    } else {
      await this.dossierService.advance(this.dossierId, action.id);
      await this.loadDossier(this.dossierId);
      this.updateProgressAndStates();
    }
  }

  async openCreateFolder() {
    const dialogRef = this.dialog.open(CreateFolderDialogComponent, {
      data: {
        dossier: this.dossier
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async () => {
      await this.loadDossier();
    });
  }

  getFileRequests(folderId: number) {
    const requests = this.dossier.fileRequests.filter(request => request.folderId === folderId && request.isActive);
    if (requests && requests.length > 0) {
      return requests;
    }
    return null;
  }

  openContacts() {
    this.dialog.open(CustomerInformationDialogComponent, {
      panelClass: 'customer-information-dialog',
      data: {
        dossier: this.dossier,
      },
      autoFocus: false
    });
  }

  async toDossierEdit() {
    await this.router.navigate(['dossier', 'edit', this.dossier.id]);
  }

  async buttonClicked($event: string) {
    await this.openCreateFolder();
  }
}
