import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options, RRule, RRuleSet, Weekday } from 'rrule';
import { TranslateService } from '@ngx-translate/core';
import { EmmaSelectOption } from '../base-components/emma-select/emma-select.component';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-recurring-settings',
  templateUrl: './recurring-settings.component.html',
  styleUrls: ['./recurring-settings.component.scss']
})
export class RecurringSettingsComponent implements OnInit {

  @Input() set repeatRule(rule: string) {
    if (rule === 'NAR') {
      this.createRRule();
    } else {
      this.stringToRRuleSet(rule);
    }
  };

  @Output() repeatRuleChange: EventEmitter<string> = new EventEmitter<string>();

  rule!: RRuleSet;
  weekdays!: {day: number; rruleDay: Weekday; selected: boolean}[];
  months!: { id: number }[];

  monthOptions: EmmaSelectOption[] = [];
  dayOptions: EmmaSelectOption[] = [];

  interval = 1;
  frequency = 1;
  repeat = 1;
  setMonth = 1;
  count = 1;
  setDayOfMonth = 1;
  recurringForm: UntypedFormGroup;
  today = new Date();

  constructor(
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.recurringForm = this.formBuilder.group({
      startDate: [new Date(), [Validators.required, this.isBefore(new Date())]],
      endDate: ['', [this.isBefore()]]
    });

    this.months = [];
    this.weekdays = [];
    for (let i = 1; i <= 12; i++){
      this.months.push({
        id: i,
      });
    }

    for (let i = 0; i < 7; i++){
      this.weekdays.push({
        day: i,
        rruleDay: this.getRRuleDayFromNumber(i),
        selected: false
      });
    }
  }

  isBefore(day2?: Date) {
    return (control: AbstractControl): ({ [key: string]: boolean } | null) => {
      if (!control.value){
        return null;
      }

      if (!day2){
        day2 = this.recurringForm.get('endDate')?.value;
        if (!day2) {
          return null;
        }
      }

      day2.setHours(0);
      day2.setMinutes(0);
      day2.setSeconds(0);
      day2.setMilliseconds(0);
      if (control.value.getTime() < day2.getTime()) {
        return {dateInvalid: true};
      }
      return null;
    };
  }

  async ngOnInit() {
    this.dayOptions = [];
    this.monthOptions = [];

    for (let i = 1; i < 31; i++){
      this.dayOptions.push({displayName: i.toString(), value: i});
    }
    this.dayOptions.push({displayName: await this.translate.get('recurring.last_day').toPromise(), value: -1});

    for (const month of this.months){
      this.monthOptions.push({displayName: await this.translate.get('months.long.' + month.id).toPromise(), value: month.id});
    }
  }

  stringToRRuleSet(rrule: string) {
    const rruleItems = rrule.split(/\r?\n/);
    this.rule = new RRuleSet();
    const rule = RRule.fromString(rruleItems[0] + '\n' + rruleItems[1]);

    if (rule.options.bymonth?.length > 0) {
      this.frequency = 0;
    }

    if (rule.options.bymonthday?.length > 0 && !rule.options.bymonth){
      this.frequency = 1;
    }

    if (rule.options.byweekday?.length > 0) {
      this.frequency = 2;
    }

    if (!rule.options.byweekday && !rule.options.bymonthday && !rule.options.bymonth) {
      this.frequency = 3;
    }

    if (rule.options?.count) {
      this.repeat = 3;
    }

    if (rule.options?.until) {
      this.repeat = 2;
    }

    this.interval = Number(rule.options.interval) || 1;
    this.frequency = Number(rule.options.freq) || 1;
    this.recurringForm.get('startDate')?.setValue(rule.options.dtstart || new Date());
    this.recurringForm.get('endDate')?.setValue( rule.options.until || undefined);
    this.count = Number(rule.options.count) || 1;

    this.setMonth = Number(rule.options.bymonth) ? Number(rule.options.bymonth[0]) : 1;
    this.setDayOfMonth = Number(rule.options.bymonthday) ? Number(rule.options.bymonthday[0]) : 1;

    if (!rrule.includes('BYDAY')){
      rule.options.byweekday = [];
    }

    if (rule.options.byweekday) {
      this.weekdays.forEach((item) => {
        if (rule.options.byweekday.includes(item.rruleDay.weekday)) {
          item.selected = true;
        }
      });
    }

    this.rule.rrule(rule);
    for (const lrule of rruleItems) {
      if (lrule.includes('EXDATE')){
        const dates = lrule.split(':')[1].split(',');
        for (const date of dates) {
          this.rule.exdate(this.timestampToDate(date));
        }
      }
    }
  }

  timestampToDate(stamp: string) {
    return new Date(stamp.replace(/^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z$/, '$1-$2-$3T$4:$5:$6Z'));
  }

  range(start: number, count: number){
    return Array.from({length: count}, (_, i) => i + start);
  }

  getRRuleDayFromNumber(day: number): Weekday {
    switch (day){
      case 0: return RRule.SU;
      case 1: return RRule.MO;
      case 2: return RRule.TU;
      case 3: return RRule.WE;
      case 4: return RRule.TH;
      case 5: return RRule.FR;
      case 6: return RRule.SA;
      default: return RRule.SU;
    }
  }

  createRRule() {
    this.recurringForm.markAllAsTouched();
    if (this.recurringForm.invalid){
      return;
    }

    const startingDate = this.recurringForm.get('startDate')?.value || new Date();
    const endingDate = this.recurringForm.get('endingDate')?.value;

    if (this.interval < 1 || (this.repeat === 3 && this.count < 1)){
      return;
    }

    const rruleOptions: Partial<Options> = {
      freq: Number(this.frequency),
      dtstart: new Date(Date.UTC(startingDate.getFullYear(), startingDate.getMonth(), startingDate.getDate(), 12)),
    };

    rruleOptions.interval = Number(this.interval);

    if (this.frequency === 2){
      rruleOptions.byweekday = this.weekdays.filter(item => item.selected).map(item => item.rruleDay);
    }

    if (this.frequency === 0){
      rruleOptions.bymonth = Number(this.setMonth);
    }

    if (this.frequency === 1 || this.frequency === 0){
      rruleOptions.bymonthday = Number(this.setDayOfMonth);
    }

    if (this.repeat === 2){
      rruleOptions.until = endingDate ?
        new Date(Date.UTC(endingDate.getFullYear(), endingDate.getMonth(), endingDate.getDate(), 12)) : undefined;
    }

    if (this.repeat === 3){
      rruleOptions.count = Number(this.count);
    }

    const newRule = new RRuleSet();
    newRule.rrule(new RRule(rruleOptions));
    this.rule = newRule;
    this.repeatRuleChange.emit(this.rule.toString());
  }

  getRRuleOccurences(): {date: Date; excluded: boolean }[] {
    if (this.rule) {
      const excluded = this.rule.exdates().map((date) => ({date, excluded: true}));
      const nextDates = this.rule
        .all((d, l) => l < 12)
        .filter(date => !this.isToday(new Date(), date))
        .map(date => ({date, excluded: false}));
      return [...nextDates, ...excluded].sort((a,b) => a.date.getTime() - b.date.getTime());
    }
    return [];
  }

  isToday(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  toggleException(rule: { date: Date; excluded: boolean }) {
    if (rule.excluded){
      const newRule = new RRuleSet();
      for (const rrule of  this.rule.rrules()) {
        newRule.rrule(rrule);
      }
      for (const exception of this.rule.exdates()){
        if (exception.getTime() !== rule.date.getTime()){
          newRule.exdate(exception);
        }
      }
      this.rule = newRule;
    } else {
      this.rule.exdate(rule.date);
    }
    this.repeatRuleChange.emit(this.rule.toString());
  }
}
