<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="delete-company-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'dialog.delete.company' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <p class="emma-font-light"> {{ 'dialog.delete.safety_check_company' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form [formControl]="nameForm" *ngIf="nameForm">
        <div class="emma-input-container">
          <label label for="name" class="emma-input-label">{{ 'dialog.delete.placeholder'  | translate }}</label>
          <input appEmmaInput id="name" formControlName="name">
          <div style="margin-top: 5px">
            <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('nameInvalid')">
              {{ 'dialog.delete.names_not_matching' | translate }}
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button  class="btn btn-green" (click)="deleteChat()">
        <b> {{ 'dialog.delete.safety_check_company' | translate }} </b>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <p class="link-text" (click)="closeModal()">< {{'confirm_dialog.go_back'|translate}}</p>
    </div>
  </div>
</div>
