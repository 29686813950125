<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="delete-files-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding">{{ 'dialog.delete.users' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <p class="emma-font-light"> {{ 'dialog.delete.safety_check2' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button  class="btn btn-red" (click)="deleteFiles()">
        <b> {{ 'dialog.delete.yes' | translate }} </b>

      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <p class="link-text" (click)="closeModal()">< {{'confirm_dialog.go_back'|translate}}</p>
    </div>
  </div>
</div>
