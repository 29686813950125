import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-start-logging-dialog',
  templateUrl: './start-logging-dialog.component.html',
  styleUrls: ['./start-logging-dialog.component.scss']
})
export class StartLoggingDialogComponent implements OnInit {
  dateForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<StartLoggingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    formBuilder: UntypedFormBuilder
  ) {
    this.dateForm = formBuilder.group({
      date: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  startLogging() {
    this.dateForm.markAllAsTouched();
    if (this.dateForm.invalid){
      return;
    }

    this.dialogRef.close(this.dateForm.get('date')?.value);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
