import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from '../../../../services/company/company.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {TranslateService} from '@ngx-translate/core';
import { Package } from 'app/functional/models/package.model';
import { User } from 'app/functional/models/user';
import {Company} from '../../../../functional/models/company';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DeleteCompanyDialogComponent} from '../../../../dialogs/delete-company-dialog/delete-company-dialog.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  companyId!: number;
  company!: Company;
  packages: Package[] = [];

  id: number;
  userSource!: MatTableDataSource<User>;
  users: Array<User> = [];
  companyForm!: UntypedFormGroup;

  constructor(
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder
  ) {
    this.id = this.activeRoute.snapshot.params.id;
  }

  async ngOnInit() {
    this.company = await this.companyService.readById(this.id);

    this.companyForm = this.formBuilder.group({
      name: [this.company?.name || '', [Validators.required]],
      diskSpace: [this.company?.diskSpace || '1000000000' , [Validators.required]],
      minLength: [this.company?.passwordLength || '8', [Validators.required]],
      maxCustomers: [this.company?.maxCustomers || '100000', [Validators.required]],
      maxEmployees: [this.company.maxEmployees || '1', [Validators.required]],
      companyType: [this.company?.companyType.toString() || '0', []],
      fromEmail: [this.company?.fromEmail || '', [Validators.required]]
    });

    this.companyId = this.company.id;

    this.users = await this.companyService.getUsers(this.id);
    this.userSource = new MatTableDataSource(this.users);
    this.userSource.paginator = this.paginator.toArray()[0];
    this.userSource.sort = this.sort.toArray()[0];
  }

  async update() {
    this.companyForm.markAllAsTouched();
    if (this.companyForm.invalid){
      return;
    }

    await this.companyService.editCompany(this.companyId, {
      name: this.companyForm.get('name')?.value,
      diskSpace: Number(this.companyForm.get('diskSpace')?.value),
      fromEmail: this.companyForm.get('fromEmail')?.value,
      maxCustomers: Number(this.companyForm.get('maxCustomers')?.value),
      maxEmployees: Number(this.companyForm.get('maxEmployees')?.value),
      companyType: Number(this.companyForm.get('companyType')?.value),
      passwordLength: Number(this.companyForm.get('minLength')?.value),
      mfaRequired: this.company.mfaRequired
    });
    this.snackBar.open(this.translate.instant('success'), '', {
      duration: 2000,
    });
  }

  redirect(id: number) {
    this.router.navigate(['management', 'users', 'edit', id]);
  }

  createRedirect() {
    this.router.navigate(['management', 'users', 'create', 1, this.id]);
  }

  applyFilter(filterValue: string) {
    this.userSource.filter = filterValue.trim().toLowerCase();

    if (this.userSource.paginator) {
      this.userSource.paginator.firstPage();
    }
  }

  deleteCompany() {
    this.dialog.open(DeleteCompanyDialogComponent, {
      data: {
        company: this.company,
      },
      autoFocus: false
    });
  }
}
