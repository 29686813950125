/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Group, CreateGroup } from '../../functional/models/group';
import { User } from 'app/functional/models/user';
import { UserService } from '../user/user.service';
import { Role } from 'app/functional/models/role.model';
import { CompanyService } from '../company/company.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private url = `${environment.rootUrl}group`;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private companyService: CompanyService,
  ) { }

  /**
   * Returns all groups that this user belongs to.
   */
  async getGroups(): Promise<Group[]> {
    const claims = this.userService.tryClaims();
    if (claims.role === Role.superuser || claims.role === Role.admin) {
      // superusers and admins see all groups within a company
      const company = await this.companyService.findCurrentCompany();
      return await this.readByCompany(company.id);
    } else {
      // employees and customers see only their own groups.
      return await this.readByUser(claims.userId);
    }
  }

  readByUser(userId: number): Promise<Group[]> {
    return this.http.get<Group[]>(this.url, {
      params: {
        user_id: userId.toString(),
      }
    }).toPromise();
  }

  readByCompany(companyId: number): Promise<Group[]> {
    return this.http.get<Group[]>(this.url + '/', {
      params: {
        company_id: companyId.toString(),
      }
    }).toPromise();
  }

  getGroup(groupId: number): Promise<Group> {
    return this.http.get<Group>(`${this.url}/${groupId}`).toPromise();
  }

  editGroup(groupId: number, model: CreateGroup): Promise<Group> {
    return this.http.put<Group>(`${this.url}/${groupId}`, model).toPromise();
  }

  createGroup(model: CreateGroup): Promise<Group> {
    return this.http.post<Group>(`${this.url}`, model).toPromise();
  }

  addEmployee(groupId: number, userId: number): Promise<Group> {
    return this.http.post<Group>(`${this.url}/${groupId}/employees`, { userId, }).toPromise();
  }

  readEmployees(groupId: number): Promise<User[]> {
    return this.http.get<User[]>(`${this.url}/${groupId}/employees`).toPromise();
  }

  addCustomer(groupId: number, userId: number): Promise<Group> {
    return this.http.post<Group>(`${this.url}/${groupId}/customers`, { userId, }).toPromise();
  }

  readCustomers(groupId: number): Promise<User[]> {
    return this.http.get<User[]>(`${this.url}/${groupId}/customers`).toPromise();
  }

  deleteGroup(groupId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${groupId}`).toPromise();
  }

  deleteCustomer(customerId: number, groupId: number) {
    const model = {
      userId: customerId
    };
    return this.http.request<void>('delete', `${this.url}/${groupId}/customers`, { body: model }).toPromise();
  }

  deleteEmployee(employeeId: number, groupId: number) {
    const model = {
      userId: employeeId
    };
    return this.http.request<void>('delete', `${this.url}/${groupId}/employees`, { body: model }).toPromise();
  }
}
