import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileRequestDialogComponent } from './file-request-dialog.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgIconsModule } from '@ng-icons/core';
import {EmmaInputModule} from '../../../components/base-components/emma-input/emma-input.module';

@NgModule({
  declarations: [FileRequestDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule.forRoot(),
    MatCheckboxModule,
    MatExpansionModule,
    NgIconsModule,
    EmmaInputModule,
  ]
})
export class FileRequestDialogModule { }
