import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { Company } from '../../../functional/models/company';
import { CompanyService } from '../../../services/company/company.service';
import { Claims } from 'app/functional/models/user';
import { Subscription } from 'rxjs';
import { Role } from 'app/functional/models/role.model';
import { ZipService } from '../../../services/zip/zip.service';
import { Zip } from '../../../functional/models/zip.model';
import { WebsocketService } from 'app/services/websocket/websocket.service';
import { filter } from 'rxjs/operators';
import { NavData, NavVariableService } from '../../../services/nav-variable/nav-variable.service';
import { Folder } from '../../../functional/models/folder';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';

export class MenuItem {
  label!: any;
  url!: string;
  subMenu?: string[];
  isParam!: boolean;
  folderCrumbs?: boolean;
  disableTitle?: boolean;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  listTitles: any[] = [];
  // mobile_menu_visible: any = 0;
  mobileMenuVisible = 0;
  toggleButton: any;
  sidebarVisible: boolean;
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  claims: Claims | null | undefined;
  subscription = new Subscription();
  currentTitle!: string;
  url: any;
  zipItems: Zip[] = [];
  zipSubscription!: Subscription;
  localRole = Role;
  breadcrumbs: MenuItem[] = [];
  navData: NavData = {};
  subMenu: string[] = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    private zipService: ZipService,
    private websocketService: WebsocketService,
    private activatedRoute: ActivatedRoute,
    private navVariableService: NavVariableService,
    private localStorageService: LocalStorageService
  ) {
    this.sidebarVisible = false;
    window.addEventListener('dossier-set',  (e: any) => this.currentTitle = e.detail.name(), false);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this.createBreadCrumb(this.activatedRoute.root);
      });

    this.navVariableService.navVariables.subscribe((item) => {
      this.navData = item;
    });
  }

  async ngOnInit() {
    this.claims = this.userService.getLoginClaims();
    if (this.claims) {
      this.zipSubscription = this.websocketService.zips.subscribe(async () => {
        this.zipItems = (await this.zipService.getZips(this.claims!.userId)).reverse();
      });
      this.listTitles = ROUTES.filter(menuItem => menuItem.role >= this.claims!.role);
      if (this.claims.role === Role.superuser) {
        this.companies = await this.companyService.readAll();
        this.filteredCompanies = this.companies;
      }
      this.zipItems = (await this.zipService.getZips(this.claims.userId)).reverse();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.zipSubscription.unsubscribe();
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton || document.getElementsByClassName('navbar-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    setTimeout(() => toggleButton.classList.add('toggled'), 500);

    body.classList.add('nav-open');

    this.sidebarVisible = true;
  }

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle() {
    this.toggleButton = document.getElementsByClassName('navbar-toggler')[0];

    if (!this.sidebarVisible) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName('body')[0];
    const layer = document.createElement('div');

    if (this.mobileMenuVisible === 1) {
      body.classList.remove('nav-open');
      if (layer) {
        layer.remove();
      }
      setTimeout(() => this.toggleButton.classList.remove('toggled'), 400);

      this.mobileMenuVisible = 0;
    } else {
      setTimeout(() => this.toggleButton.classList.add('toggled'), 430);

      layer.setAttribute('class', 'close-layer');

      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild(layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document
          .getElementsByClassName('wrapper-full-page')[0]
          .appendChild(layer);
      }

      setTimeout(() => layer.classList.add('visible'), 100);

      layer.onclick = (() => {
        body.classList.remove('nav-open');
        this.mobileMenuVisible = 0;
        layer.classList.remove('visible');
        setTimeout(() => {
          layer.remove();
          this.toggleButton.classList.remove('toggled');
        }, 400);
      }).bind(this);

      body.classList.add('nav-open');
      this.mobileMenuVisible = 1;
    }
  }


  async logout() {
    this.localStorageService.resetLocalStorage();

    this.websocketService.close();
    await this.router.navigate(['auth']);
    await this.companyService.refreshCompany();
  }

  async setCompany(company?: Company) {
    this.companyService.company.next(company || null);
  }

  fixName(name: string) {
    const nameItems = name.split('/');
    nameItems.shift();
    return nameItems.join('');
  }

  async openDownload(item: Zip, link: any) {
    this.url = (await this.zipService.getUrlFromZip(this.userService.tryClaims().userId, this.fixName(item.zipName))).url;
    await new Promise(resolve => setTimeout(resolve, 1000));
    link.click();
  }

  filter(event: string) {
    if (event === '') {
      this.filteredCompanies = this.companies;
    } else {
      this.filteredCompanies = this.companies.filter(item => item.name.toLowerCase().includes(event) || item.id.toString().includes(event));
    }
  }

  createBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    const lastRoutePart = path!.split('/').pop();
    const isDynamicRoute = lastRoutePart!.startsWith(':');
    if(isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart!.split(':')[1];
      path = path!.replace(lastRoutePart!, route.snapshot.params[paramName]);
    }

    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: MenuItem = {
      label,
      url: nextUrl,
      subMenu: route?.routeConfig?.data?.subMenu,
      isParam: route?.routeConfig?.data?.isParam,
      folderCrumbs: route?.routeConfig?.data?.renderFolderCrumbs,
      disableTitle: route?.routeConfig?.data?.disableTitle
    };

    const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
      return this.createBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  getParamData(label: string) {
    let toReturn = '';
    let labelParts = [label];
    if (label.includes(';')){
      labelParts = label.split(';');
    }

    for (const labelPart of labelParts) {
      // @ts-ignore
      toReturn = toReturn + labelPart.split('.').reduce((p, c) => p && p[c] || '', this.navData) + ' ';
    }

    return toReturn.trim();
  }

  navigate(folder: Folder) {
    window.dispatchEvent(new CustomEvent('window-navigate', { bubbles: true, detail: { folder } }));
  }
}
