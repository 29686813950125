<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    (navigationChange)="setActive($event)"
    [navigation]="[
      {name: 'profile.overview' | translate, event: 'details'},
      {name: 'profile.mail' | translate, event: 'mailSettings'},
    ]"></app-sub-nav>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row" *ngIf="activeTab === 'details'">
        <div class="col-md-8">
          <div class="card" id="profile-block">
            <div class="card-header card-header-grey card-header-icon">
              <div class="card-icon card-header-custom"><mat-icon>build</mat-icon></div>
              <div class="exchange-card-title-between">
                <h4 class="card-title">
                  {{'profile.title' | translate}}
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form [formGroup]="profileForm" *ngIf="profileForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="email" class="emma-input-label">{{'users.create.email'  | translate }}</label>
                      <input appEmmaInput id="email" formControlName="email">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="phone" class="emma-input-label">{{'users.create.phone'  | translate }}</label>
                      <input appEmmaInput id="phone" formControlName="phone">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="firstName" class="emma-input-label">{{'users.create.firstname' | translate }}</label>
                      <input appEmmaInput id="firstName" formControlName="firstName">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="lastName" class="emma-input-label">{{'users.create.lastname'  | translate }}</label>
                      <input appEmmaInput id="lastName" formControlName="lastName">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="gender" class="emma-input-label">{{'users.create.gender'  | translate }}</label>
                      <app-emma-select
                        id="gender"
                        layout="input"
                        [fullWidth]="true"
                        [renderButton]="true"
                        [options]="[
                          {displayName: 'users.create.undefined' | translate, value: 0},
                          {displayName: 'users.create.male' | translate, value: 1},
                          {displayName: 'users.create.female' | translate, value: 2},
                          {displayName: 'users.create.notapplicable' | translate, value: 9},
                        ]"
                        formControlName="gender">
                      </app-emma-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="birth" class="emma-input-label">{{'users.create.birthdate'  | translate }}</label>
                      <div class="emma-input">
                        <input id="birth" appEmmaInput formControlName="birth" [matDatepicker]="picker">
                        <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
                      </div>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="street" class="emma-input-label">{{'users.create.address'  | translate }}</label>
                      <input appEmmaInput id="street" formControlName="street">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="emma-input-container">
                      <label label for="houseNumber" class="emma-input-label">{{'users.create.housenr'  | translate }}</label>
                      <input appEmmaInput id="houseNumber" formControlName="houseNumber">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="emma-input-container">
                      <label label for="premise" class="emma-input-label">{{'users.create.addition'  | translate }}</label>
                      <input appEmmaInput id="premise" formControlName="premise">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="postalCode" class="emma-input-label">{{'users.create.postal'  | translate }}</label>
                      <input appEmmaInput id="postalCode" formControlName="postalCode">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="city" class="emma-input-label">{{'users.create.place'  | translate }}</label>
                      <input appEmmaInput id="city" formControlName="city">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="language" class="emma-input-label">{{'users.create.preferred_language' | translate }}</label>
                      <app-emma-select
                        id="language"
                        layout="input"
                        [fullWidth]="true"
                        [renderButton]="true"
                        [options]="[
                          {displayName: 'English', value: 'en'},
                          {displayName: 'Nederlands', value: 'nl'},
                        ]"
                        formControlName="language">
                      </app-emma-select>
                    </div>
                  </div>
                </div>
                <button  type="submit" class="btn btn-blue btn-custom pull-right" (click)="updateProfile()">{{'profile.submit' | translate}}</button>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card" id="profile-password-block">
            <div class="card-header card-header-grey card-header-icon">
              <div class="card-icon card-header-custom"><mat-icon>lock</mat-icon></div>
              <div class="exchange-card-title-between">
                <h4 class="card-title">
                  {{'profile.reset_title' | translate}}
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form [formGroup]="formGroup" *ngIf="formGroup">
                <div class="row">
                  <div class="col-md-12">
                    <div class="emma-input-container">
                      <label label for="password" class="emma-input-label">{{'auth.reset.pass' | translate }}</label>
                      <input appEmmaInput id="password" type="password" formControlName="password" data-private>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="emma-input-container">
                      <label label for="confirm" class="emma-input-label">{{'auth.reset.pass_repeat' | translate }}</label>
                      <input appEmmaInput id="confirm" formControlName="confirm" type="password" data-private>
                      <div style="margin-top: 5px">
                        <span class="emma-input-error" *ngIf="formGroup?.touched && formGroup?.invalid && formGroup?.get('confirm')?.hasError('invalidMatch')">
                          {{'profile.passwords_not_matching' | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-password-strength
                      [min]="company?.passwordLength || 8" #passwordComponentWithValidation
                      [password]="formGroup.get('password')?.value || ''"
                      (onStrengthChanged)="onStrengthChanged($event)"
                      style="display: none"
                    ></mat-password-strength>
                    <mat-password-strength-info
                      [passwordComponent]="passwordComponentWithValidation"
                      [lowerCaseCriteriaMsg]="'profile.lowercase_required' | translate"
                      [upperCaseCriteriaMsg]="'profile.uppercase_required'| translate"
                      [digitsCriteriaMsg]="'profile.digit_required'| translate"
                      [specialCharsCriteriaMsg]="'profile.special_required' | translate"
                      [minCharsCriteriaMsg]="'profile.minimal_required' | translate:{'characters': company?.passwordLength.toString() || '8'}"
                      class="password-reset-box">
                    </mat-password-strength-info>
                  </div>
                </div>
                <button  type="submit" class="btn btn-blue btn-custom pull-right" (click)="changePass()">{{'profile.change_password' | translate}}</button>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="activeTab === 'mailSettings'">
        <div class="col-md-12">
          <app-mail-settings></app-mail-settings>
        </div>
      </div>
    </div>
  </div>
</div>
