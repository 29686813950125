<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="add-dossier-template-permission-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'dossier_template.dialog.add_permission.title' | translate }} </p>
    </div>
  </div>
  <form ngForm [formGroup]="addPermissionForm">
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <mat-form-field class="full-width">
          <mat-label>{{ 'dossier_template.dialog.add_permission.folder' | translate }}</mat-label>
          <mat-select formControlName="folder">
            <mat-option [value]="-1">{{'dossier_template.dialog.add_permission.all_folders' | translate}}</mat-option>
            <mat-option *ngFor="let folder of data.folders" [value]="folder.id">
              {{folder.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <mat-form-field class="full-width">
          <mat-label>{{ 'dossier_template.dialog.add_permission.role' | translate }}</mat-label>
          <mat-select placeholder="{{'users.create.role' | translate}}"  formControlName="role" [disabled]="!addPermissionForm.get('folder')?.value">
            <mat-option [value]="-1" *ngIf="!existPermissionForRole(-1)">{{'dossier_template.dialog.add_permission.all_roles' | translate}}</mat-option>
            <mat-option [value]="2" *ngIf="!existPermissionForRole(2)">{{'users.create.employee' | translate}}</mat-option>
            <mat-option [value]="3" *ngIf="!existPermissionForRole(3)">{{'users.create.customer' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <mat-radio-group formControlName="permission" class="full-width" [disabled]="!addPermissionForm.get('role')?.value">
        <div class="col-md-12 extra-margin extra-margin-top">
          <div class="row">
            <div class="col-md-6">
              <mat-radio-button [value]="''">{{ 'dossier_template.dialog.add_permission.no_rights' | translate }}</mat-radio-button>
            </div>
            <div class="col-md-6">
              <mat-radio-button [value]="'r'">{{ 'dossier_template.dialog.add_permission.read' | translate }}</mat-radio-button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-radio-button [value]="'rwd'">{{ 'dossier_template.dialog.add_permission.read_write' | translate }}</mat-radio-button>
            </div>
            <div class="col-md-6">
              <mat-radio-button [value]="'rwcdm'">{{ 'dossier_template.dialog.add_permission.manage' | translate }}</mat-radio-button>
            </div>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button data-test="delete-submit-button"  class="btn btn-blue btn-custom" (click)="addPermission()">
        <b>{{ 'dossier_template.dialog.add_permission.submit' | translate }}</b>

      </button>
    </div>
  </div>
</div>
