import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {FileRequestService} from 'app/services/file-request/file-request.service';
import {FileRequest} from 'app/functional/models/file-request.model';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {Upload} from 'app/functional/models/upload';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-fulfill-request-dialog',
  templateUrl: './fulfill-request-dialog.component.html',
  styleUrls: ['./fulfill-request-dialog.component.scss']
})
export class FulfillRequestDialogComponent {
  toUpload!: Upload;
  fileName!: string;
  fileDescription?: string;
  request!: FileRequest;

  constructor(
    public dialogRef: MatDialogRef<FulfillRequestDialogComponent>,
    private requestService: FileRequestService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.request = this.data.request;
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  async sendFile() {
    await this.requestService.fulfill(this.toUpload.file!,
      {
        fileRequestId: this.request.id,
        name: this.toUpload.name,
        description: '',
        documentSize: this.toUpload.file!.size,
      },
      (response: Response) => {
        console.error(response);

        this.snackBar.open(
          this.translate.instant('explorer.fail_upload'),
          this.translate.instant('close'),
          { duration: 2000, },
        );
      }
    );

    window.dispatchEvent(new Event('request-fulfilled'));
    this.dialogRef.close(true);
  }

  async dropped(files: NgxFileDropEntry[]) {
    if (!this.toUpload) {
      const droppedFile = files[0];
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.toUpload = new Upload(
            file.name,
            file.size,
            file,
            -1
          );
        });
      }
    }
  }

  inserted(event: any) {
    const file = event.target.files[0];
    this.toUpload = new Upload(
      file.name,
      file.size,
      file,
      -1
    );
  }

  startInput(fileInput: HTMLInputElement) {
    if (!this.toUpload) {
      fileInput.click();
    }
  }
}
