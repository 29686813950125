<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="file-request-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title-light">{{ 'dialog.request.document' | translate }}</p>
    </div>
  </div>
  <ng-container *ngIf="!isFilled">
    <form [formGroup]="fileRequestForm" *ngIf="fileRequestForm">
      <div class="row">
        <div class="col-md-12 centered">
          <p>{{ 'dialog.request.which_documents' | translate }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 centered">
          <div class="emma-input-container">
            <label label for="fileName" class="emma-input-label">{{'dialog.request.placeholder' | translate }}</label>
            <input appEmmaInput id="fileName" formControlName="fileName">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 centered">
          <div class="emma-input-container">
            <label label for="description" class="emma-input-label">{{'dialog.request.description' | translate }}</label>
            <textarea appEmmaInput id="description" formControlName="description" style="height: 100px"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 centered">
          <button data-test="request-continue-button"  id="request-next" class="btn btn-blue btn-custom " (click)="next()">
            <b>{{ 'dialog.request.continue' | translate }}</b>
          </button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="isFilled && !isSpecific">
    <div class="row">
      <div class="col-md-12 centered">
        <p>{{ 'dialog.request.who_documents' | translate }}</p>
      </div>
    </div>
    <div class="row align-bottom">
      <div class="col-md-6 centered">
        <button data-test="request-submit-button" id="next-to-persons" class="btn btn-blue btn-custom " (click)="setSpecific()">
          <b>{{ 'dialog.request.specific' | translate }}</b>
        </button>
      </div>
      <div class="col-md-6 centered">
        <button data-test="request-submit-button" id="submit-request-1" class="btn btn-blue btn-custom " (click)="requestDocument()">
          <b>{{ 'dialog.request.everyone' | translate }}</b>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isFilled && isSpecific">
    <div class="row">
      <div class="col-md-12 centered">
        <p>{{ 'dialog.request.who_documents' | translate }}</p>
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let accessor of accessors; let i = index;">
        <div class="col-md-6" *ngIf="accessor.role === Role.customer && accessor.permissions.includes('w')">
          <mat-checkbox [name]="'requestor-customer-' + i" [(ngModel)]="accessor['requested']">{{accessor.firstName + ' ' + accessor.lastName}}</mat-checkbox>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <mat-expansion-panel class="white-expansion">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'dialog.request.employees' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <ng-container *ngFor="let accessor of accessors; let i = index;">
            <div class="col-md-6" *ngIf="accessor.role === Role.admin || accessor.role === Role.employee">
              <mat-checkbox [name]="'requestor-employee-' + i" [(ngModel)]="accessor['requested']">{{accessor.firstName + ' ' + accessor.lastName}}</mat-checkbox>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="row align-bottom">
      <div class="col-md-6 centered">
        <button data-test="request-submit-button"  id="back" class="btn btn-outline-blue btn-custom" (click)="setBack()">
          <b>{{ 'dialog.request.back' | translate }}</b>
        </button>
      </div>
      <div class="col-md-6 centered">
        <button data-test="request-submit-button"  id="submit-request-2" class="btn btn-blue btn-custom " (click)="requestDocument()">
          <b>{{ 'dialog.request.add' | translate }}</b>
        </button>
      </div>
    </div>
  </ng-container>
</div>
