import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {RedactedUser} from '../../../functional/models/user';
import {Organization} from '../../../functional/models/organization.model';
import {Dossier} from '../../../functional/models/dossier.model';
import {Company} from '../../../functional/models/company';
import {SearchCompaniesAndCustomersComponent} from '../../../components/search/search-companies-and-customers/search-companies-and-customers.component';
import {SearchDossiersComponent} from '../../../components/search/search-dossiers/search-dossiers.component';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-search-chat-dialog',
  templateUrl: './search-chat-dialog.component.html',
  styleUrls: ['./search-chat-dialog.component.scss']
})
export class SearchChatDialogComponent implements OnInit {

  userFilter: RedactedUser[] = [];
  organizationFilter: Organization[] = [];
  dossierFilter: Dossier[] = [];
  company!: Company;
  userId!: number;

  constructor(
    public dialogRef: MatDialogRef<SearchChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService
  ) {
    this.userFilter = data.userFilter;
    this.organizationFilter = data.organizationFilter;
    this.dossierFilter = data.dossierFilter;
    this.company = data.company;
    this.userId = this.userService.tryClaims().userId;
  }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close({
      userFilter: this.userFilter,
      organizationFilter:  this.organizationFilter,
      dossierFilter: this.dossierFilter
    });
  }

  clear() {
    this.dialogRef.close({
      userFilter: [],
      organizationFilter:  [],
      dossierFilter: []
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  addCustomerOrCompany(event: RedactedUser | Organization | undefined, search: SearchCompaniesAndCustomersComponent) {
    if (event) {
      if (event.hasOwnProperty('name')) {
        this.organizationFilter.push(event as Organization);
      } else {
        this.userFilter.push(event as RedactedUser);
      }
      if (search) {
        search.clearSelected();
      }
    }
  }

  addDossier(event: Dossier | undefined, search: SearchDossiersComponent) {
    if (event) {
      this.dossierFilter.push(event);
      if (search) {
        search.clearSelected();
      }
    }
  }

  deleteDossier(dossier: Dossier) {
    this.dossierFilter = this.dossierFilter.filter(dossierI => dossierI.id !== dossier.id);
  }

  deleteUser(user: RedactedUser) {
    this.userFilter = this.userFilter.filter(userI => userI.id !== user.id);
  }

  deleteOrganization(organization: Organization) {
    this.organizationFilter = this.organizationFilter.filter(organizationI => organizationI.id !== organization.id);
  }
}
