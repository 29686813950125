import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ChatService} from '../../../services/chat/chat.service';

@Component({
  selector: 'app-leave-chat-dialog',
  templateUrl: './leave-chat-dialog.component.html',
  styleUrls: ['./leave-chat-dialog.component.scss']
})
export class LeaveChatDialogComponent implements OnInit {

  chatId!: number;
  userId!: number;

  constructor(
    public dialogRef: MatDialogRef<LeaveChatDialogComponent>,
    private chatService: ChatService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    this.chatId = this.data.chatId;
    this.userId = this.data.userId;
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  async leaveChat() {
    await this.chatService.removeUser(this.chatId, this.userId);
    this.dialogRef.close(true);
  }
}
