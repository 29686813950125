import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CreateMessageModel, Message } from '../../functional/models/message';
import { RedactedUser } from '../../functional/models/user';
import { UserService } from '../../services/user/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-create-mail-dialog',
  templateUrl: './create-mail-dialog.component.html',
  styleUrls: ['./create-mail-dialog.component.scss']
})
export class CreateMailDialogComponent implements OnInit {
  replyTo!: Message;
  recipients: RedactedUser[] = [];
  selectedUser?: RedactedUser;
  createMailForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<CreateMailDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    ) {
    if (data?.message) {
      this.replyTo = data.message;
    }

    this.createMailForm = this.formBuilder.group({
      subject: [this.replyTo?.subject ? 'RE: ' + this.replyTo.subject : '', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  async ngOnInit() {
    const sender = await this.userService.getUser();
    if (this.data.company) {
      this.recipients = await this.userService.getRecipients(sender, this.data.company);
    } else {
      this.recipients = await this.userService.getRecipients(sender);
    }
  }

  async sendMail() {
    this.createMailForm.markAllAsTouched();
    if (this.createMailForm.invalid){
      return;
    }

    if (!this.selectedUser && !this.replyTo) {
      this.snackBar.open(
        this.translateService.instant('form_invalid'),
        this.translateService.instant('close'),
        { duration: 5000 },
      );
      return;
    }

    const obj: CreateMessageModel = {
      subject: this.createMailForm.get('subject')?.value,
      content:  this.createMailForm.get('message')?.value,
      recipient: this.selectedUser ? this.selectedUser.id : this.replyTo!.sender!.id,
      replyToId: this.replyTo ? this.replyTo!.id : undefined,
    };

    try {
      await this.messageService.create(obj);
      this.snackBar.open(
        this.translateService.instant('messaging.sent'),
        this.translateService.instant('close'),
        { duration: 3000, }
      );
      this.dialogRef.close({confirmed: true});
    } catch { }
  }

  closeModal() {
    this.dialogRef.close();
  }

  setSelected(event: any | undefined) {
    this.selectedUser = event;
  }
}
