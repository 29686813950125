import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierFolderComponent } from './dossier-folder.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {NgxFileDropModule} from 'ngx-file-drop';
import {EditFolderDialogModule} from '../../../dialogs/dossiers/edit-folder-dialog/edit-folder-dialog.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {FulfillRequestDialogModule} from '../../../dialogs/file-request/fulfill-request-dialog/fulfill-request-dialog.module';
import {FileRequestDialogModule} from '../../../dialogs/file-request/file-request-dialog/file-request-dialog.module';
import {RequestSigningDialogModule} from '../../../dialogs/signing/request-signing-dialog/request-signing-dialog.module';
import {ReplyDenyDocumentDialogModule} from '../../../dialogs/signing/reply-deny-document-dialog/reply-deny-document-dialog.module';
import {DisplaySignaturesDialogModule} from '../../../dialogs/display-signatures-dialog/display-signatures-dialog.module';
import {EmmaSelectModule} from '../../base-components/emma-select/emma-select.module';
import {EmmaTooltipModule} from '../../base-components/emma-tooltip/emma-tooltip.module';
import {heroPaperClip, heroTrash, heroCheck, heroDocumentText, heroEllipsisVertical,
  heroXMark, heroPlusSmall, heroPencilSquare, heroDocumentPlus, heroInformationCircle } from '@ng-icons/heroicons/outline';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  declarations: [DossierFolderComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ScrollingModule,
    MatListModule,
    MatSnackBarModule,
    NgxFileDropModule,
    OverlayModule,
    FileRequestDialogModule,
    FulfillRequestDialogModule,
    RequestSigningDialogModule,
    ReplyDenyDocumentDialogModule,
    DisplaySignaturesDialogModule,
    EditFolderDialogModule,
    TranslateModule.forRoot(),
    MatTooltipModule,
    NgIconsModule.withIcons({
      heroEllipsisVertical,
      heroInformationCircle,
      heroXMark,
      heroPaperClip,
      heroPlusSmall,
      heroTrash,
      heroDocumentPlus,
      heroDocumentText,
      heroCheck,
      heroPencilSquare
    }),
    EmmaSelectModule,
    EmmaTooltipModule,
  ],
  exports: [
    DossierFolderComponent
  ]
})
export class DossierFolderModule { }
