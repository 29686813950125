import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Folder, FolderMeta, FolderWithChildren} from '../../../functional/models/folder';
import {Document} from '../../../functional/models/document';
import {FileRequest} from '../../../functional/models/file-request.model';
import {Upload} from '../../../functional/models/upload';
import {SelectionModel} from '@angular/cdk/collections';
import {ExplorerItem} from '../../../functional/models/explorer.model';
import {Company} from '../../../functional/models/company';
import {Role} from '../../../functional/models/role.model';
import {Subscription} from 'rxjs';
import {FolderService, GroupPermission, ListResponse} from '../../../services/folder/folder.service';
import {DocumentService} from '../../../services/document/document.service';
import {CompanyService} from '../../../services/company/company.service';
import {UserService} from '../../../services/user/user.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {SigningService} from '../../../services/signing/signing.service';
import {OrganizationService} from '../../../services/organization/organization.service';
import {FileRequestService} from '../../../services/file-request/file-request.service';
import {UtilsService} from '../../../services/utils/utils.service';
import {FileSystemFileEntry} from 'ngx-file-drop';
import {FileRequestDialogComponent} from '../../../dialogs/file-request/file-request-dialog/file-request-dialog.component';
import {CreateFolderDialogComponent} from '../../../dialogs/exchange/create-folder-dialog/create-folder-dialog.component';
import {ConfirmDialogComponent} from '../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogType} from '../../../functional/models/dialog.model';
import {FulfillRequestDialogComponent} from '../../../dialogs/file-request/fulfill-request-dialog/fulfill-request-dialog.component';
import { SearchType, SortType } from 'app/functional/models/exchange.model';
import {Organization} from '../../../functional/models/organization.model';
import { EmmaFileDropEntry } from 'app/functional/models/file-drop.model';

@Component({
  selector: 'app-exchange-overview',
  templateUrl: './exchange-overview.component.html',
  styleUrls: ['./exchange-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExchangeOverviewComponent implements OnInit, OnDestroy {

  @ViewChild('fileInput') fileUpload!: ElementRef;
  @Input() isUserOverview = false;
  @Input() organizationId?: number;
  @Input() userId?: number;

  @Input() set searchType(searchType: SearchType) {
    this.lSearchType = searchType;
    this.onSearchTypeChange(this.lSearchType);
  }

  @Input() set display(display: 'small' | 'list' | undefined) {
    if (!display) {
      return;
    }

    this.view = display;
  }

  @Output() breadCrumbChange = new EventEmitter<Folder[]>();

  currentFolder!: Folder;
  currentFolderPermissions!: string;

  defaultFolderContent: Folder[] = [];
  defaultDocumentContent: Document[] = [];
  defaultRequestContent: FileRequest[] = [];

  portalFolderContent: Folder[] = [];
  portalDocumentContent: Document[] = [];
  portalRequestContent: FileRequest[] = [];

  documents: Document[] = [];
  folders: Folder[] = [];
  requests: FileRequest[] = [];

  uploads: Upload[] = [];
  isUploading = false;

  listSelection: SelectionModel<ExplorerItem> = new SelectionModel<ExplorerItem>(true, []);
  rootFolders: Folder[] = [];

  lBreadCrumb: Folder[] = [];

  set breadCrumb(folders: Folder[]) {
    this.lBreadCrumb = folders;
    this.breadCrumbChange.emit(this.lBreadCrumb);
  }

  get breadCrumb(): Folder[] {
    return this.lBreadCrumb;
  }

  highlighted?: Document | Folder | FileRequest;
  dragging?: Document | Folder | FileRequest;
  company!: Company;
  role = Role.customer;
  searchTerm?: string;
  loading = true;
  storedSort = Number(localStorage.getItem('sort-preference')) as SortType || SortType.nameAscending;
  storedView = localStorage.getItem('view-preference');
  lSearchType = SearchType.currentFolder;
  lSortType = this.storedSort;
  view: 'list' | 'small' = this.storedView === 'list' ? 'list' : 'small';
  cancelSelect = false;
  localRole = Role;
  dialogSubscription = new Subscription();
  companySubscription = new Subscription();

  singleFolderPlaceholder = document.createElement('img');
  singleFilePlaceholder = document.createElement('img');
  singleRequestPlaceholder = document.createElement('img');
  multiFolderPlaceholder = document.createElement('img');
  multiFilePlaceholder = document.createElement('img');
  multiRequestPlaceholder = document.createElement('img');
  testfile = document.createElement('img');
  constructor(
    private folderService: FolderService,
    private documentService: DocumentService,
    private companyService: CompanyService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private signingService: SigningService,
    private organizationService: OrganizationService,
    private requestService: FileRequestService,
    private utilService: UtilsService,
  ) {
    this.singleFolderPlaceholder.src = 'assets/img/folder.png';
    this.singleFilePlaceholder.src = 'assets/img/file.png';
    this.singleRequestPlaceholder.src = 'assets/img/request.png';
    this.multiFolderPlaceholder.src = 'assets/img/folders.png';
    this.multiFilePlaceholder.src = 'assets/img/files.png';
    this.multiRequestPlaceholder.src = 'assets/img/requests.png';

    window.addEventListener('request-fulfilled', async () => {
      this.listSelection.clear();
      await this.refreshDisplay();
    });
  }

  async ngOnInit() {
    this.role = this.userService.tryClaims().role;
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      let newCompany: Company;
      if (company) {
        newCompany = company;
      } else {
        newCompany = await this.companyService.findCurrentCompany();
      }
      if (!this.company || this.company.id !== newCompany.id) {
        this.company = newCompany;
      }
      await this.init();
      this.loading = false;
    });
  }

  async init() {
    if (this.organizationId) {
      await this.initializeFromOrganizationRoot();
    } else if (this.userId) {
      await this.initializeFromUserRoot(this.userId);
    } else if (this.activeRoute.snapshot.params.id) {
      await this.initializeFromId(this.activeRoute.snapshot.params.id);
    } else {
      await this.initializeFromRoot();
    }
    this.sortItems();
  }

  async initializeFromOrganizationRoot() {
    this.rootFolders = await this.organizationService.getFolders(this.organizationId!);

    this.currentFolder = await this.getRoot();
    if (this.breadCrumb.length === 0) {
      this.pushCrumb(this.currentFolder);
    }
    await this.refreshDisplay();
  }

  async initializeFromUserRoot(userId?: number) {
    // accesspoints do not return permissions of the current logged in user
    if (!userId) {
      const claims = this.userService.tryClaims();
      userId = claims.userId;
    }

    this.rootFolders = await this.userService.getAccesspoints(userId);

    const folderPermissioncalls: Promise<string>[] = [];
    for (const folder of this.rootFolders) {
      folderPermissioncalls.push(this.folderService.getCurrentUserPermissions(folder.id));
    }

    const result = await Promise.all(folderPermissioncalls);
    for (let i = 0; i < result.length; i++){
      this.rootFolders[i].permission = result[i];
    }

    this.currentFolder = await this.getRoot();
    if (this.breadCrumb.length === 0) {
      this.pushCrumb(this.currentFolder);
    }
    await this.refreshDisplay();
  }

  async initializeFromId(id: number) {
    await this.fetchCurrentFolder(id);
    await this.calculateCrumbs();
    await this.refreshDisplay();
  }

  async initializeFromRoot() {
    if (this.role >= Role.employee) {
      await this.initializeFromUserRoot();
    } else {
      await this.initializeFromAdminRoot();
    }
  }

  ngOnDestroy() {
    this.dialogSubscription.unsubscribe();
    this.companySubscription.unsubscribe();
  }

  /**
   * Returns a 'fake' root that is used for displaying when the user has no access to the root folder.
   */
  async getRoot(): Promise<Folder> {
    return {
      id: -1,
      name: await this.translate.get('explorer.folders').toPromise(),
      description: '',
      companyId: this.company.id,
      creationDate: new Date(),
      labels: [],
    };
  }

  /**
   * Pushes a deep copy of the supplied object onto the breadcrumb, and updates the previous folder to contain a
   * trailing slash.
   *
   * @param folder The folder to append to the breadcrumb.
   */
  pushCrumb(folder: Folder): void {
    if (this.breadCrumb.some(e => e.id === folder.id)) {
      // crumb already contains this folder
      return;
    }
    const copy = Object.assign({}, folder);
    if (!copy.parentFolder) {
      copy.name = this.translate.instant('explorer.folders');
    }
    this.breadCrumb.push(copy);
    this.breadCrumbChange.emit(this.lBreadCrumb);
  }

  /**
   * Removes folders from the breadcrumb.
   *
   * @param folder Optional: if supplied folders are removed from the crumb until this folder is reached. If it is
   * omitted, the last folder is removed instead.
   */
  popCrumb(folder?: Folder): void {
    if (folder) {
      const index = this.breadCrumb.findIndex(e => e.id === folder.id);
      if (index > -1) {
        this.breadCrumb = this.breadCrumb.slice(0, index);
      }
    } else {
      this.breadCrumb.pop();
      this.breadCrumbChange.emit(this.lBreadCrumb);
    }
  }

  /**
   * Checks what the current folder is, and computes the full breadcrumb from scratch.
   */
  async calculateCrumbs(): Promise<void> {
    this.breadCrumb = [];
    if (!this.currentFolder) {
      return;
    }
    let folders = await this.folderService.getPath(this.currentFolder.id);

    if (this.role === Role.customer || this.role === Role.employee) {
      folders = [await this.getRoot(), ...folders, this.currentFolder];
    }

    if (this.role === Role.admin || this.role === Role.superuser) {
      folders = [...folders, this.currentFolder];
      folders[0].name = await this.translate.get('explorer.folders').toPromise();
    }

    for (const folder of folders) {
      this.pushCrumb(folder);
    }
  }

  async initializeFromAdminRoot() {
    this.currentFolder = await this.companyService.getRoot(this.company.id);
    this.breadCrumb = [];
    // note that since currentFolder can be null, it is possible, but not a problem, we push `null` onto `breadCrumb`
    this.pushCrumb(this.currentFolder);
    await this.refreshDisplay();
  }

  async fetchCurrentFolder(id: number) {
    this.currentFolder = await this.folderService.get(id);
  }

  async getFileDetails(e: InputEvent): Promise<void> {
    const element = e.target as HTMLInputElement;
    const files = element.files;
    if (files == null) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.uploads.push(new Upload(
        file.name,
        file.size,
        file,
        this.currentFolder.id
      ));
    }

    await this.uploadFiles();
  }

  async getFolder(id: number) {
    this.rootFolders.push(await this.folderService.get(id));
    await this.refreshDisplay();
  }

  async refreshDisplay() {
    if (!this.currentFolder) {
      // We might not be on this page
      return;
    }
    if (this.currentFolder.id === -1) { // we're in the root folder
      this.folders = this.rootFolders;
      this.requests = [];
      this.documents = [];
      this.currentFolderPermissions = '';
    } else {
      this.requests = [];

      const res: ListResponse = await this.folderService.getChildren(this.currentFolder.id);
      this.folders = res.folders;
      this.documents = res.files;
      this.currentFolderPermissions = await this.folderService.getCurrentUserPermissions(this.currentFolder.id);
      if (this.role === Role.customer){
        this.requests = await this.requestService.filter({folderId: this.currentFolder.id, userId: this.userService.tryClaims().userId});
      } else {
        this.requests = await this.requestService.filter({folderId: this.currentFolder.id});
      }
    }

    this.defaultFolderContent = this.folders;
    this.defaultDocumentContent = this.documents;
    this.defaultRequestContent = this.requests;
    await this.onSearchTypeChange(this.lSearchType);
  }

  async dropped(dropped: EmmaFileDropEntry[], droppedFolder?: Folder) {
    dropped = dropped.filter(item =>
      !item.fileEntry.name.toLowerCase().includes('.ds_store') &&
      !item.fileEntry.name.toLowerCase().includes('thumbs.db') &&
      !item.fileEntry.name.toLowerCase().includes('desktop.ini')
    );
    const folderPaths: FolderWithChildren[] = [];
    for (const droppedItem of dropped) {
      const path = droppedItem
        .relativePath
        .replace(/^\/|\/$/g, '')
        .split('/')
        .filter(seg => (droppedItem.fileEntry.isFile && seg !== droppedItem.fileEntry.name) || droppedItem.fileEntry.isDirectory);

      if (path.length === 0) {
        continue;
      }

      const shiftedPath = path.shift()!;
      let previousFolder: FolderWithChildren | undefined = folderPaths.find(pathSegment => pathSegment.originalPath === shiftedPath);
      if (!previousFolder) {
        previousFolder = {...await this.createFolder(shiftedPath, false, droppedFolder), ...{ children: [] }, originalPath: shiftedPath};
        folderPaths.push(previousFolder);
      }

      for (const pathSegment of path) {
        let folder: FolderWithChildren | undefined =
          previousFolder.children?.find(childPathSegment => childPathSegment.originalPath === pathSegment);
        if (!folder) {
          folder = ({...await this.createFolder(pathSegment, false, previousFolder), ...{children: []}});
          previousFolder.children.push({...folder, originalPath: pathSegment});
        }
        previousFolder = folder;
      }
    }

    for (const droppedItem of dropped.filter(drop => drop.fileEntry.isFile)) {
      const path = droppedItem
        .relativePath
        .replace(/^\/|\/$/g, '')
        .split('/')
        .filter(seg => seg !== droppedItem.fileEntry.name);
      if (path.length === 0) {
        await this.createUpload(droppedItem.fileEntry as FileSystemFileEntry, droppedFolder);
        continue;
      }

      let previousSegment = folderPaths.find(childPathSegment => childPathSegment.originalPath === path.shift()!)!;
      for (const pathSegment of path) {
        previousSegment = previousSegment.children.find(childPathSegment => childPathSegment.originalPath === pathSegment)!;
      }
      await this.createUpload(droppedItem.fileEntry as FileSystemFileEntry, previousSegment);
    }
    await this.uploadFiles();
    await this.refreshDisplay();
  }

  createUpload(fileEntry: FileSystemFileEntry, parentFolder?: Folder): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        fileEntry.file((file: File) => {
          this.uploads.push(new Upload(
            file.name,
            file.size,
            file,
            parentFolder?.id || this.currentFolder.id
          ));
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async createFolder(name: string, notify = true, parentFolder?: Folder): Promise<Folder> {
    if (!name){
      throw new Error('No name specified');
    }

    if (!this.currentFolder && !parentFolder) {
      throw new Error('No current folder specified');
    }

    const folder = await this.folderService.create({
      name,
      description: '',
      parentFolder: parentFolder?.id || this.currentFolder.id
    });
    if (notify) {
      this.snackBar.open(this.translate.instant('explorer.success_folder'), '', {
        duration: 2000,
      });

      await this.refreshDisplay();
    }
    return folder;
  }

  async openFileRequest() {
    const accessors = [
      ...(await this.folderService.getAccessors(this.currentFolder.id)).users,
      ...(await this.companyService.getAdmins(this.company.id))
    ];
    const dialog = this.dialog.open(FileRequestDialogComponent, {
      data: {
        accessors,
        folderId: this.currentFolder.id
      },
      autoFocus: false
    });

    dialog.afterClosed().subscribe(async () => {
      await this.refreshDisplay();
    });
  }

  async openDialog() {
    const dialog = this.dialog.open(CreateFolderDialogComponent, {
      data: {
        company: this.company,
        parentFolder: this.currentFolder,
        parentFolderPermissions: this.currentFolderPermissions,
        currentFolders: this.folders
      },
      autoFocus: false
    });

    dialog.afterClosed().subscribe(async () => {
      await this.refreshDisplay();
    });
  }


  async selectFolder(folder: Folder) {
    this.cancelSelect = false;
    const folderSelected = this.listSelection
      .selected
      .find(item => item.folder && item.folder.id === folder.id);
    if (folder.pending) {
      // the user has double clicked
      this.listSelection.clear();
      this.cancelSelect = true;
      await this.navigate(folder);
      return;
    }
    folder.pending = true;
    setTimeout(() => folder.pending = false, 500); // for registering double clicks
    if (folderSelected) {
      this.listSelection.toggle(folderSelected);
      return;
    }

    if (!folder.permission) {
      folder.permission = await this.folderService.getCurrentUserPermissions(folder.id);
    }

    const toggleItem = new ExplorerItem();
    toggleItem.folder = folder;

    if ([Role.admin, Role.employee, Role.superuser].includes(this.role)) {
      const requests: Promise<any>[] = [];
      requests.push(this.folderService.meta(folder.id));
      requests.push(this.folderService.getOrganizations(folder.id));
      requests.push(this.folderService.getGroupPermissions(folder.id));

      const result = await Promise.all(requests);

      const meta = result[0] as FolderMeta;
      toggleItem.users = meta.users.filter(u => u.permissions.includes('r'));
      toggleItem.organizations = (result[1] as Organization[]).map(organization => [organization!, []]);
      toggleItem.groupPermissions = result[2] as GroupPermission[];

      for (const organization of toggleItem.organizations) {
        organization[0].users = await this.organizationService.getUsers(organization[0].id!);
        organization[1] = toggleItem.users.filter(u => organization[0].users!.find(item => item.id === u.id));
      }

      toggleItem.singleUsers = meta.users.filter(u => u.permissions.includes('r')).filter(item =>
        !toggleItem.organizations?.find(orgPerm => orgPerm[1].find(orgUsr => orgUsr.id === item.id))
      );
    }

    if (!this.cancelSelect) {
      this.listSelection.toggle(toggleItem);
    }
    this.cancelSelect = false;
  }

  async clickFolder(folder: Folder) {
    await this.selectFolder(folder);
  }

  async clickRequest(request: FileRequest) {
    await this.selectRequest(request);
  }

  async selectRequest(request: FileRequest) {
    if (request.pending) {
      this.openFileReqestFulfillDialog(request);
      const lRequestSelected = this.listSelection.selected.find(item =>
        item.request && item.request.id === request.id);
      if (lRequestSelected) {
        this.listSelection.deselect(lRequestSelected);
      }
      return;
    }
    request.pending = true;
    setTimeout(() => request.pending = false, 500);

    const requestSelected = this.listSelection.selected.find(item => item.request && item.request.id === request.id);
    if (requestSelected) {
      this.listSelection.toggle(requestSelected);
      return;
    }
    const toggleItem = new ExplorerItem();
    toggleItem.request = request;
    this.listSelection.toggle(toggleItem);
  }

  async selectDocument(document: Document) {
    if (document.pending) {
      await this.router.navigate(['exchange', 'viewer', document.id]);
      return;
    }
    document.pending = true;
    setTimeout(() => document.pending = false, 500);
    const fileSelected = this.listSelection.selected.find(item => item.file && item.file.id === document.id);

    if (fileSelected) {
      this.listSelection.toggle(fileSelected);
      return;
    }
    const toggleItem = new ExplorerItem();

    if (!document.permission) {
      document.permission = this.currentFolderPermissions;
    }
    const parentMeta = await this.folderService.meta(document.parentFolder.id);
    toggleItem.users = parentMeta.users.filter(u => u.permissions.includes('r'));

    if (this.userService.tryClaims().role !== Role.customer) {
      toggleItem.signings = await this.signingService.getSignings(document.id);
    }

    toggleItem.file = document;
    this.listSelection.toggle(toggleItem);
  }

  async clickDocument(document: Document) {
    await this.selectDocument(document);
  }

  async navigate(folder: Folder) {
    // note: this only pops if the folder is in the parent
    this.popCrumb(folder);
    this.pushCrumb(folder);
    this.listSelection.clear();
    this.currentFolderPermissions = '';

    this.currentFolder = folder;
    if (this.currentFolder.id === -1) {
      //await this.router.navigate(['exchange']);
      if (this.rootFolders.length === 0 && this.role >= 2) {
        await this.initializeFromUserRoot();
      }
    } else {
      //await this.router.navigate(['exchange', this.currentFolder.id]);
    }

    this.searchTerm = '';
    this.onSearchChange('');

    await this.refreshDisplay();
  }

  async uploadFiles() {
    if (this.isUploading) {
      // We are already uploading, don't start uploading twice
      return;
    }

    this.isUploading = true;
    for (const file of this.uploads) {
      try {
        await this.documentService.create(file.file!, {
          name: file.name,
          description: '',
          parentFolder: Number(file.parentFolderId),
          documentSize: file.size,
        },
        (response: Response) => {
          this.snackBar.open(this.translate.instant('explorer.fail_upload'), '', {
            duration: 2000,
          });
        });
      } catch {
      } finally {
        this.uploads = this.uploads.filter(o => o !== file); // remove the current file from the list of uploads
        this.listSelection.clear();
        await this.refreshDisplay();
      }
    }
    // We are done uploading
    this.isUploading = false;
    // In case new entries were added that weren't handled by our previous for loop:
    if (this.uploads.length > 0) {this.uploadFiles();}
  }

  isRequest(item: Folder | Document | FileRequest) {
    return item.hasOwnProperty('filename');
  }

  isFile(item: Folder | Document | FileRequest) {
    return item.hasOwnProperty('extension');
  }

  isFolder(item: Folder | Document | FileRequest) {
    return item.hasOwnProperty('ftpDestinationFolder');
  }


  async deleteSelected() {
    if (!this.listSelection || !this.listSelection.selected || this.listSelection.selected!.length === 0) {
      return;
    }
    try {
      if (this.listSelection && this.listSelection.selected) {
        for (const item of this.listSelection.selected) {
          if (item.file) {
            await this.documentService.delete(item.file.id);
          } else if (item.folder) {
            await this.folderService.delete(item.folder.id);
          }
        }
        await this.refreshDisplay();
      }
    } catch { }
  }

  canUserCreate() {
    if (!this.currentFolder || !this.currentFolderPermissions) {
      return false;
    }

    if (this.currentFolder.id === -1) {
      return false;
    }

    return this.currentFolderPermissions.includes('c');
  }

  // create folders / upload
  canUserWrite() {
    if (!this.currentFolder || !this.currentFolderPermissions) {
      return false;
    }

    if (this.currentFolder.id === -1) {
      return false;
    }

    return this.currentFolderPermissions.includes('w');
  }

  canUserDelete() {
    if (!this.listSelection || !this.listSelection.selected || this.listSelection.selected!.length === 0) {
      return false;
    }

    if (this.listSelection && this.listSelection.selected && this.listSelection.selected.length > 0) {
      return !this.listSelection.selected.find(
        selection => !selection.file?.permission!.includes('d') && !selection.folder?.permission!.includes('d')
      );
    }
    return false;
  }

  canUserAdministrateCurrentFolder() {
    if (!this.currentFolder || !this.currentFolderPermissions) {
      return false;
    }

    if (this.currentFolder.id === -1) {
      return false;
    }

    return this.currentFolderPermissions.includes('m');
  }

  canUserAdministrate() {
    if (
      !this.listSelection ||
      !this.listSelection.selected ||
      this.listSelection.selected!.length === 0 ||
      this.listSelection.selected!.length > 1
    ) {
      return false;
    }

    if (this.listSelection.selected[0] || this.currentFolderPermissions) {
      const selection: any = this.listSelection.selected[0];
      if (selection && selection.permission) {
        return selection.permission.includes('m');
      } else {
        return this.currentFolderPermissions.includes('m');
      }
    }
    return false;
  }

  sortItems() {
    switch (this.lSortType) {
      case SortType.nameAscending: {
        this.folders = this.folders.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        this.requests = this.requests.sort((a, b) => (a.filename.toLowerCase() > b.filename.toLowerCase()) ? 1 : -1);
        this.documents = this.documents.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        break;
      }
      case SortType.nameDescending: {
        this.folders = this.folders.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : -1);
        this.requests = this.requests.sort((a, b) => (a.filename.toLowerCase() < b.filename.toLowerCase()) ? 1 : -1);
        this.documents = this.documents.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : -1);
        break;
      }
      case SortType.dateAscending: {
        this.folders = this.folders.sort((a, b) => (a.creationDate > b.creationDate) ? 1 : -1);
        this.requests = this.requests.sort((a, b) => (a.creationDate > b.creationDate) ? 1 : -1);
        this.documents = this.documents.sort((a, b) => (a.creationDate > b.creationDate) ? 1 : -1);
        break;
      }
      case SortType.dateDescending: {
        this.folders = this.folders.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : -1);
        this.requests = this.requests.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : -1);
        this.documents = this.documents.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : -1);
        break;
      }
    }
  }

  onSearchChange(searchValue: string | undefined): void {
    if (!searchValue) {
      this.folders = this.defaultFolderContent;
      this.documents = this.defaultDocumentContent;
      this.requests = this.defaultRequestContent;
      return;
    }

    if (this.lSearchType === SearchType.currentFolder) {
      this.folders = this.defaultFolderContent
        .filter(folder => folder.name.toLowerCase().includes(searchValue.toLowerCase()));
      this.documents = this.defaultDocumentContent
        .filter(document => document.name.toLowerCase().includes(searchValue.toLowerCase()));
      this.requests = this.defaultRequestContent
        .filter(request => request.filename.toLowerCase().includes(searchValue.toLowerCase()));
    }
    if (this.lSearchType === SearchType.currentFolderCascade || this.lSearchType === SearchType.allFolders) {
      this.folders = this.portalFolderContent
        .filter(folder => folder.name.toLowerCase().includes(searchValue.toLowerCase()));
      this.documents = this.portalDocumentContent
        .filter(document => document.name.toLowerCase().includes(searchValue.toLowerCase()));
      this.requests = this.portalRequestContent
        .filter(request => request.filename.toLowerCase().includes(searchValue.toLowerCase()));
    }
    this.sortItems();
  }

  async onSearchTypeChange(searchType: SearchType): Promise<void> {
    switch (searchType) {
      case SearchType.currentFolderCascade: {
        if (this.currentFolder.id === -1) { // We are inside a fake root.
          this.lSearchType = SearchType.allFolders;
          await this.onSearchTypeChange(this.lSearchType);
          return;
        }
        if (this.currentFolder) {
          this.portalRequestContent = await this.folderService.childRequestsCascade(this.currentFolder.id);
          this.portalDocumentContent = await this.folderService.childDocumentsCascade(this.currentFolder.id);
          this.portalFolderContent = await this.folderService.childFoldersCascade(this.currentFolder.id);
        } else {
          this.snackBar.open(this.translate.instant('explorer.no_current'), 'sluiten', { duration: 5000 });
        }
        break;
      }
      case SearchType.allFolders: {
        const claims = this.userService.tryClaims();
        this.portalRequestContent = await this.requestService.filter({userId: claims.userId});
        this.portalDocumentContent = await this.userService.documentsCascade(claims.userId);
        this.portalFolderContent = await this.userService.foldersCascade(claims.userId);
        break;
      }
    }
    this.onSearchChange(this.searchTerm);
  }

  setView(view: 'list' | 'small') {
    localStorage.setItem('view-preference', view);
    this.listSelection.clear();
    this.view = view;
  }

  setSort(sortType: SortType) {
    localStorage.setItem('sort-preference', sortType.toString());
    this.lSortType = sortType;
    this.sortItems();
  }

  async fileDragDropped(event: any) {
    event.preventDefault();
    if (this.dragging && this.highlighted) {
      const dragged = this.utilService.deepCopy<Document | Folder | FileRequest>(this.dragging);
      const highlighted = this.utilService.deepCopy<Document | Folder | FileRequest>(this.highlighted);
      const dialogRef = this.dialog.open(ConfirmDialogComponent,
        {
          panelClass: 'confirm-dialog',
          data: {dialogType: ConfirmDialogType.moveFile},
          autoFocus: false
        });

      dialogRef.afterClosed().subscribe(async (res: any) => {
        if (res) {
          await this.moveFile(dragged, highlighted);
          await this.refreshDisplay();
        }
        this.dragging = undefined;
        this.highlighted = undefined;
      });
    }
  }

  highlightFolder(folder: Folder, event: DragEvent) {
    event.preventDefault();
    if (this.highlighted !== folder) {
      this.highlighted = folder;
    }
    event.preventDefault();
  }

  unHighlightFolder(folder: Folder) {
    if (this.highlighted === folder){
      this.highlighted = undefined;
    }
  }

  async moveFile(document: Document | Folder | FileRequest, highlighted: Document | Folder | FileRequest) {
    if (highlighted) {
      const toFolderId = Number(highlighted.id.toString());
      const isFile = this.isFile(document);
      const isFolder = this.isFolder(document);
      const isRequest = this.isRequest(document);
      if (this.listSelection.selected.length > 1) {
        for (const item of this.listSelection.selected) {
          if (!isFile && !isFolder && !isRequest) {
            continue;
          }
          if (item.folder && !isFile && item.folder.id === document.id){
            continue;
          }
          if (item.file && isFile && item.file.id === document.id){
            continue;
          }
          if (item.request && isFile && item.request.id === document.id){
            continue;
          }

          if (item.file) {
            await this.documentService.update(item.file.id, {
              name: item.file.name,
              description: item.file.description,
              parentFolder: toFolderId
            });
          }
          if (item.folder) {
            await this.folderService.edit(item.folder.id, {
              name: item.folder.name,
              description: item.folder.description,
              parentFolder: toFolderId,
              ftpDestinationFolder: item.folder.ftpDestinationFolder
            });
          }
          if (item.request) {
            await this.requestService.edit(item.request.id, {
              folderId: toFolderId,
              message: item.request.message,
              filename: item.request.filename,
              exampleFileId: item.request.exampleFileId
            });
          }
        }
      }

      if (isFolder) {
        const doc = document as Folder;
        if (toFolderId !== doc.id) {
          await this.folderService.edit(doc.id, {
            name: doc.name,
            description: doc.description,
            parentFolder: toFolderId,
            ftpDestinationFolder: doc.ftpDestinationFolder
          });
        }
      }

      if (isFile) {
        const f = document as Document;
        await this.documentService.update(document.id, {
          name: f.name,
          description: f.description,
          parentFolder: toFolderId
        });
      }

      if (isRequest) {
        const r = document as FileRequest;
        await this.requestService.edit(r.id, {
          folderId: toFolderId,
          message: r.message,
          filename: r.filename,
          exampleFileId: r.exampleFileId
        });
      }
    }
    this.listSelection.clear();
    this.dragging = undefined;
    this.highlighted = undefined;
  }

  startDragging(doc: Document | Folder | FileRequest, event: DragEvent) {
    this.dragging = doc;
    if (this.isFile(doc)) {
      if (this.listSelection.selected.length > 1) {
        event.dataTransfer?.setDragImage(this.multiFilePlaceholder, 0, 0);
      } else {
        event.dataTransfer?.setDragImage(this.singleFilePlaceholder, 0, 0);
      }
    }

    if (this.isFolder(doc)) {
      if (this.listSelection.selected.length > 1) {
        event.dataTransfer?.setDragImage(this.multiFolderPlaceholder, 0, 0);
      } else {
        event.dataTransfer?.setDragImage(this.singleFolderPlaceholder, 0, 0);
      }
    }

    if (this.isRequest(doc)) {
      if (this.listSelection.selected.length > 1) {
        event.dataTransfer?.setDragImage(this.multiRequestPlaceholder, 0, 0);
      } else {
        event.dataTransfer?.setDragImage(this.singleRequestPlaceholder, 0, 0);
      }
    }
  }

  stopDragging() {
    this.dragging = undefined;
    this.highlighted = undefined;
  }

  openFileReqestFulfillDialog(request: FileRequest) {
    const dialogRef = this.dialog.open(FulfillRequestDialogComponent, {
      data: {
        request
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.listSelection.clear();
        await this.refreshDisplay();
      }
    });
  }

  openFilePicker() {
    this.fileUpload.nativeElement.click();
  }
}
