<div class="simplicate-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{ 'simplicate.link' | translate }}</p>
    </div>
  </div>
  <form [formGroup]="simplicateForm" *ngIf="simplicateForm">
    <div class="row padding-bottom">
      <div class="col-md-12">
        <div class="emma-input-container">
          <label label for="apiKey" class="emma-input-label">API key</label>
          <input appEmmaInput id="apiKey" formControlName="apiKey">
        </div>
      </div>
    </div>
    <div class="row padding-bottom">
      <div class="col-md-12">
        <div class="emma-input-container">
          <label label for="apiSecret" class="emma-input-label">API secret</label>
          <input appEmmaInput id="apiSecret" formControlName="apiSecret">
        </div>
      </div>
    </div>
    <div class="row padding-bottom">
      <div class="col-md-12">
        <div class="emma-input-container">
          <label label for="apiUrl" class="emma-input-label">Het gedeelte van de url voor '.simplicate.nl'</label>
          <input appEmmaInput id="apiUrl" formControlName="apiUrl">
        </div>
      </div>
    </div>
  </form>
  <button mat-button class="btn btn-blue btn-custom" (click)="createConnection()">{{ 'addon.create_link' | translate }}</button>
</div>
