<div class="table-paginator-container">
  <div class="mat-table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="lastEvent" matSortDirection="desc" class="dossier-overview-table emma-table">
      <ng-container matColumnDef="select" *ngIf="claims.role !== localRole.customer && claims.role !== localRole.employee">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            class="selector">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dossier_overview.dossier' | translate}} </th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="organizations">
        <th mat-header-cell *matHeaderCellDef class="sort-style"> {{'dossier_overview.organizations' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row?.organizations?.length === 0" class="font-default"> - </span>
          <span class="cell-block" *ngIf="row?.organizations?.length > 0">
            <span (click)="routeToOrganization($event, organization)" class="font-link" *ngFor="let organization of row.organizations">{{organization.name}}</span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="persons">
        <th mat-header-cell *matHeaderCellDef class="sort-style"> {{'dossier_overview.customer' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <div style="display: flex; align-items: center" *ngIf="row.customer.length >= 1">
            <app-user-icon
              style="{{first ? '' : 'left: -'+ (20 * index) +'px; position: relative;'}}"
              *ngFor="let customer of row.customer; let first = first; let index = index"
              [firstName]="customer.firstName"
              [lastName]="customer.lastName"
              [border]="true"
              class="hover-user-icon"
              [appEmmaTooltip]="customer.firstName + ' ' + customer.lastName"
              (click)="routeToCustomer($event, customer)">
            </app-user-icon>
          </div>
          <ng-container *ngIf="row.customer.length === 0">
            -
          </ng-container>
      </ng-container>

      <ng-container matColumnDef="nextOccurrence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dossier_overview.next_occurrence' | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.nextOccurrence? (row.nextOccurrence | date: 'dd/MM/yyyy') : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="lastEvent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dossier_overview.last_changed' | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.lastEvent | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="customerDeadline">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dossier_overview.deadline_customer' | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.customerDeadline ? (row.customerDeadline | date: 'dd/MM/yyyy') : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="submissionDeadline">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dossier_overview.deadline_submission' | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.submissionDeadline ? (row.submissionDeadline | date: 'dd/MM/yyyy') : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dossier_overview.progress' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <span class="centered-row" [appEmmaTooltip]="row.progress === 0 ? ('dossier_overview.tooltip' | translate) : ''">{{ ('dossier_overview.step' | translate) +' '+ row.progress + '/' + row.progressOf }} <mat-icon class="warning-icon" *ngIf="row.progress === 0">warning</mat-icon></span>
          <mat-progress-bar mode="determinate" [value]="(row.progress / row.progressOf) * 100"></mat-progress-bar>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="element" *matRowDef="let row; columns: displayedColumns;" (click)="redirect(row)"></tr>
      <tr class="empty-row" *matNoDataRow>
        <td colspan="9999">
          <div class="illustration">
            <img src="assets/img/illustrations/workflow.svg">
            <h1 class="not-found-title">{{'dossier_overview.no_dossiers' | translate}}</h1>
            <span>
              {{'dossier_overview.no_dossiers_text1' | translate}}
              {{'dossier_overview.no_dossiers_text2' | translate}}
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div>
    <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </div>
</div>
