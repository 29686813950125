import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCustomersAndEmployeesComponent } from './search-customers-and-employees.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {AutocompleteSelectModule} from '../../base-components/autocomplete-select/autocomplete-select.module';

@NgModule({
  declarations: [
    SearchCustomersAndEmployeesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forRoot(),
    AutocompleteSelectModule,
  ],
  exports: [
    SearchCustomersAndEmployeesComponent
  ]
})
export class SearchCustomersAndEmployeesModule { }
