<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <button (click)="organizationsInput.click()">Importeer Organisaties</button>
      <button (click)="usersInput.click()" >Importeer gebruikers</button>
      <button (click)="submitLists()">Uploaden</button>
    </div>

    <div class="row" style="flex-direction: column;">
      <p>Rijen: {{users.length}}, New: {{hasNewUsers}}, Duplicate: {{duplicateUsers.length}}</p>
      <ng-container *ngFor="let user of duplicateUsers">
        <p>{{user[0].firstName + ' ' + user[0].lastName + ' '  + user[0].email + ' | ' + user[1]}}</p>
      </ng-container>

      <ng-container *ngFor="let user of users">
        <p>{{user[0].firstName + ' ' + user[0].lastName + ' '  + user[0].email}}</p>
        <p *ngIf="organizations.length > 0 && user[1]" style="margin-right: 15px">{{getUserOrganization(user[1])?.name + ' ' + getUserOrganization(user[1])?.kvk + ' ' + getUserOrganization(user[1])?.fiscalNumber}}</p>
      </ng-container>
    </div>
    <br/><br/><br/>
    <div class="row" style="flex-direction: column;">
      <p>Rijen: {{organizations.length}}</p>
      <p *ngFor="let organizaton of organizations">{{organizaton.name + ' ' + organizaton.kvk + ' ' + organizaton.fiscalNumber}} <br /></p>
    </div>
  </div>
</div>
<input type="file" style="display: none" accept=".csv" #usersInput (change)="importUsers($event)">
<input type="file" style="display: none" accept=".csv" #organizationsInput (change)="importOrganizations($event)">
