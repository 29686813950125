import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MailSettingFormModel, MailSettingsModel } from '../../functional/models/mail-settings.model';

@Injectable({
  providedIn: 'root'
})
export class MailSettingsService {
  private url = `${environment.rootUrl}mail_settings`;

  constructor(
    private http: HttpClient,
  ) { }

  getSettings(userId: number): Promise<MailSettingsModel[]> {
    const before = Math.round((new Date()).getTime() / 1000);
    return this.http.get<MailSettingsModel[]>(
      `${this.url}?user_id=${userId}&before=${before}`,
    ).toPromise();
  }

  setSetting(model: MailSettingFormModel): Promise<MailSettingsModel> {
    return this.http.post<MailSettingsModel>(`${this.url}`, model).toPromise();
  }

  updateSetting(settingId: number, date: string | null): Promise<MailSettingsModel> {
    return this.http.put<MailSettingsModel>(`${this.url}/${settingId}`, { endDate: date }).toPromise();
  }

  deleteSetting(settingId: number) {
    return this.http.delete(`${this.url}/${settingId}`).toPromise();
  }
}
