import {Routes} from '@angular/router';
import {ExplorerComponent} from './explorer/explorer.component';
import {ViewerComponent} from './viewer/viewer.component';
import {AuthGuard} from '../../functional/guards/auth.guard';

export const exchangeRoutes: Routes = [
  { path: 'viewer/:id',
    component: ViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: ExplorerComponent,
  },
  { path: ':id',   component: ExplorerComponent },
];
