<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="create-mail-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{'messaging.create' | translate}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="emma-input-container">
        <label label for="recipient" class="emma-input-label">{{'messaging.recipient' | translate}}</label>
        <input appEmmaInput name="recipient" id="recipient" type="text" [value]="replyTo.sender.firstName + ' ' + replyTo.sender.lastName" *ngIf="replyTo" disabled>
        <ng-container *ngIf="!replyTo">
          <app-search-users [preSet]="recipients" (valueChange)="setSelected($event?.value)"></app-search-users>
        </ng-container>
      </div>
    </div>
  </div>
  <form [formGroup]="createMailForm" *ngIf="createMailForm">
    <div class="row">
      <div class="col-md-12">
        <div class="emma-input-container">
          <label label for="subject" class="emma-input-label">{{'messaging.subject'  | translate }}</label>
          <input appEmmaInput id="subject" formControlName="subject">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered">
        <div class="emma-input-container">
          <label label for="message" class="emma-input-label">{{'messaging.example' | translate }}</label>
          <textarea appEmmaInput id="message" formControlName="message" style="height: 200px"></textarea>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12 centered">
      <button  class="btn btn-blue btn-custom" (click)="sendMail()">
        <b>{{'messaging.send' | translate}}</b>
      </button>
    </div>
  </div>
</div>
