import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from '../../../services/dossier/dossier.service';
import {Company} from '../../../functional/models/company';
import {Claims, RedactedUser, User} from '../../../functional/models/user';
import {Subscription} from 'rxjs';
import {CompanyService} from '../../../services/company/company.service';
import {Dossier, NewDossierUser} from '../../../functional/models/dossier.model';
import {Organization} from '../../../functional/models/organization.model';
import {OrganizationService} from '../../../services/organization/organization.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {DossierTemplate} from '../../../functional/models/dossier-template.model';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '../../../functional/models/role.model';
import {UserService} from '../../../services/user/user.service';
import {ConfirmDialogComponent} from '../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogType} from '../../../functional/models/dialog.model';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DossierConfigurationComponent} from '../../../components/dossiers/dossier-configuration/dossier-configuration.component';
import {DossierRecurringService} from '../../../services/dossier-recurring/dossier-recurring.service';
import {RecurringDossier} from '../../../functional/models/dossier-recurring.model';
import {DossierTemplateService} from '../../../services/dossier-template/dossier-template.service';

@Component({
  selector: 'app-create-dossier',
  templateUrl: './create-dossier.component.html',
  styleUrls: ['./create-dossier.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateDossierComponent implements OnInit, OnDestroy {
  @ViewChild(DossierConfigurationComponent) dossierConfigurator!: DossierConfigurationComponent;
  @Input() dossierId!: number;
  @Input() dossier!: Dossier;
  @Input() recurringDossierId!: number;
  @Input() recurringDossier!: RecurringDossier;

  dossierView: 'repeat' | 'persons' | 'workflow' | 'deadlines' = 'workflow';

  dossierName!: string;
  company!: Company;
  templateSelected!: DossierTemplate;
  customers: [User | RedactedUser, number][] = [];
  organizations: [Organization, [User | RedactedUser, number][]][] = [];
  employees: User[] | RedactedUser[] = [];
  deadlines: {dossierStateTemplateId: number; deadlineDays: number | Date | undefined}[] = [];
  companySubscription = new Subscription();
  claims!: Claims;
  localRole = Role;
  thisUser!: User;
  isRepeat =  false;
  repeatRule = 'NAR';

  constructor(
    private router: Router,
    private dossierService: DossierService,
    private companyService: CompanyService,
    private activeRoute: ActivatedRoute,
    private organizationService: OrganizationService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private userService: UserService,
    private dialog: MatDialog,
    private recurringDossierService: DossierRecurringService,
    private templateService: DossierTemplateService
  ) {
  }

  async ngOnInit() {
    this.claims = this.userService.tryClaims();
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
    });

    await Promise.all([this.initializeCustomer(), this.initializeEmployees()]);
    await this.initializeOrganizations();
    this.thisUser = await this.userService.getUser();

    if (this.dossier || this.dossierId || this.activeRoute.snapshot.params.id){
      if (!this.dossier){
        if (!this.dossierId) {
          this.dossierId = this.dossierId || this.activeRoute.snapshot.params.id;
        }
        this.dossier = await this.dossierService.get(this.dossierId);
      }

      this.dossierName = this.dossier.name;
      this.employees = this.dossier.employees;
      this.customers = this.usersToEditModel(this.dossier.customers);
      this.organizations = await this.loadOrganizations(this.dossier.organizations);
      this.dossierView = 'persons';
    } else if (this.recurringDossier || this.recurringDossierId || this.activeRoute.snapshot.params.recurringId) {
      if (!this.recurringDossier){
        if (!this.recurringDossierId) {
          this.recurringDossierId = this.activeRoute.snapshot.params.recurringId;
        }
        this.recurringDossier = await this.recurringDossierService.get(this.recurringDossierId);
      }

      this.repeatRule = this.recurringDossier.rrule;
      this.templateSelected = await this.templateService.get(this.recurringDossier.dossierTemplateId);
      this.dossierName = this.recurringDossier.name;
      this.employees = this.recurringDossier.users.filter(item => item.role === Role.employee);
      this.customers = this.usersToEditModel(this.recurringDossier.users.filter(item => item.role !== Role.employee));
      this.organizations = await this.loadOrganizations(this.recurringDossier.organizations);
      this.isRepeat = true;
      this.dossierView = 'repeat';
      this.deadlines = this.recurringDossier.deadlines;
    } else {
      if (this.employees.length === 0) {
        this.employees.push(this.thisUser);
      }
    }
  }

  async initializeCustomer() {
    const customerIds = this.activeRoute.snapshot.queryParamMap.getAll('customer');
    const calls = [];

    for (const customerId of customerIds) {
      calls.push(this.userService.getUser(Number(customerId)));
    }

    this.customers = (await Promise.all(calls)).map(item => [item, 1]);
  }

  async initializeEmployees() {
    const employeeIds = this.activeRoute.snapshot.queryParamMap.getAll('employee');
    const calls = [];

    for (const employeeId of employeeIds) {
      calls.push(this.userService.getUser(Number(employeeId)));
    }

    this.employees = (await Promise.all(calls));
  }

  async initializeOrganizations() {
    const organizatonIds = this.activeRoute.snapshot.queryParamMap.getAll('organization');
    const calls = [];

    for (const organizationId of organizatonIds) {
      calls.push(this.organizationService.getOrganization(Number(organizationId)));
    }

    this.organizations = await this.loadOrganizations(await Promise.all(calls));
  }

  async loadOrganizations(organizations: Organization[]) {
    const sortedOrganizations: [Organization, [User | RedactedUser, number][]][] = [];
    for (const organization of (organizations)) {
      const organizationUsers = await this.organizationService.getUsers(organization.id, Role.customer);
      const copyUsers: [User | RedactedUser, number][] = [];
      for (const user of organizationUsers) {
        const customer: [User | RedactedUser, number] | undefined = this.customers.find(lCustomer => lCustomer[0].id === user.id);
        if (customer) {
          copyUsers.push(customer);
          this.customers = this.customers.filter(lCustomer => lCustomer[0].id !== user.id);
        }
      }
      sortedOrganizations.push([organization, copyUsers]);
    }
    return sortedOrganizations;
  }

  usersToEditModel(customers: RedactedUser[]) {
    const toReturn: [RedactedUser, number][] = [];
    for (const customer of customers) {
      if (customer.permissions && customer.permissions.includes('rwd')) {
        toReturn.push([customer, 1]);
      } else {
        toReturn.push([customer, 2]);
      }
    }
    return toReturn;
  }

  usersToDossierModel(users?: [User | RedactedUser, number][]): NewDossierUser[] {
    const toReturn: NewDossierUser[] = [];
    if (this.customers.length > 0 ) {
      for (const customer of this.customers) {
        toReturn.push({userId: customer[0].id, permissions: customer[1] === 1 ? 'rwd' : 'r'});
      }
    }
    if (this.employees.length > 0) {
      for (const employee of this.employees) {
        toReturn.push({userId: employee.id, permissions: 'rwdm'});
      }
    }
    return toReturn;
  }

  companyUsersToDossierModel(users: [User | RedactedUser, number][]): NewDossierUser[] {
    const toReturn: NewDossierUser[] = [];
    if (users.length > 0) {
      for (const customer of users) {
        toReturn.push({userId: customer[0].id, permissions: customer[1] === 1 ? 'rwd' : 'r'});
      }
    }
    return toReturn;
  }

  async createDossier() {
    this.dossierConfigurator.nameForm.markAllAsTouched();
    if(this.dossierConfigurator.nameForm.invalid){
      return;
    }

    let users = this.usersToDossierModel();
    if (this.organizations.length > 0) {
      for (const organization of this.organizations) {
        const oUsers = this.companyUsersToDossierModel(organization[1]);
        users = [...users, ...oUsers];
      }
    }

    if (users.length < 0) {
      this.snackBar.open(this.translate.instant('create_dossier.select_customer'), this.translate.instant('close'), { duration: 5000 });
      return;
    }
    if (!this.templateSelected) {
      this.snackBar.open(this.translate.instant('create_dossier.select_template'), this.translate.instant('close'), { duration: 5000 });
      return;
    }
    if (!this.dossierName) {
      this.snackBar.open(this.translate.instant('create_dossier.select_name'), this.translate.instant('close'), { duration: 5000 });
      return;
    }

    let result;
    if (this.isRepeat) {
      result = await this.recurringDossierService.create({
        name: this.dossierName,
        companyId: this.company.id,
        dossierTemplateId: this.templateSelected.id,
        deadlines: this.convertDeadlines(this.deadlines),
        users,
        organizations: this.organizations.map(item => item[0].id),
        active: true,
        rrule: this.repeatRule!
      });
    } else {
      result = await this.dossierService.create({
        name: this.dossierName,
        companyId: this.company.id,
        templateId: this.templateSelected.id,
        deadlines: this.convertDeadlines(this.deadlines),
        users,
      });

      if (this.organizations.length > 0) {
        for (const organization of this.organizations) {
          await this.organizationService.addDossier(organization[0].id!, result.id);
        }
      }
    }

    const hasSelf = users.some(user => user.userId === this.claims.userId);
    if (hasSelf || this.claims.role === Role.admin || this.claims.role === Role.superuser) {
      if (this.isRepeat){
        await this.router.navigate(['dossier']);
      } else {
        await this.router.navigate(['dossier', 'details', result.id]);
      }
    } else {
      // can't add organizations if user removed himself from the dossier
      await this.router.navigate(['dossier']);
    }
  }

  async editDossier() {
    this.dossierConfigurator.nameForm.markAllAsTouched();
    if(this.dossierConfigurator.nameForm.invalid){
      return;
    }

    let users = this.usersToDossierModel();
    if (this.organizations.length > 0) {
      for (const organization of this.organizations) {
        const oUsers = this.companyUsersToDossierModel(organization[1]);
        users = [...users, ...oUsers];
      }
    }

    if (users.length < 0) {
      this.snackBar.open(this.translate.instant('create_dossier.select_customer'), this.translate.instant('close'), { duration: 5000 });
      return;
    }

    if (!this.dossierName) {
      this.snackBar.open(this.translate.instant('create_dossier.select_name'), this.translate.instant('close'), { duration: 5000 });
      return;
    }

    if (this.dossierId) {
      const dossier = await this.dossierService.edit(this.dossierId, {
        name: this.dossierName,
        deadlines: this.convertDeadlines(this.deadlines),
        users,
      });

      // Remove organizations from dossier
      for (const organization of dossier.organizations) {
        if (!this.organizations.some(org => org[0].id === organization.id)) {
          await this.organizationService.removeDossier(organization.id, dossier.id);
        }
      }

      // Add new organizations to dossiers
      if (this.organizations.length > 0) {
        for (const organization of this.organizations) {
          if (!dossier.organizations.some(dOrganization => dOrganization.id === organization[0].id)) {
            await this.organizationService.addDossier(organization[0].id!, dossier.id);
          }
        }
      }
    } else if (this.recurringDossierId) {
      await this.recurringDossierService.edit(this.recurringDossierId, {
        name: this.dossierName,
        rrule: this.repeatRule!,
        dossierTemplateId: this.recurringDossier.dossierTemplateId,
        active: true,
        users,
        organizations: this.organizations.map(item => item[0].id),
        deadlines: this.convertDeadlines(this.deadlines),
      });
    }

    window.dispatchEvent(new CustomEvent('dossier-updated', {bubbles: true, detail: {dossierId: () => this.dossier.id}}));

    this.snackBar.open(this.translate.instant('success'), '', {
      duration: 2000,
    });
  }

  convertDeadlines(deadlines: {dossierStateTemplateId: number; deadlineDays: number | Date | undefined}[]) {
    const deadlineList: any = {};
    for (const deadline of deadlines) {
      deadlineList[deadline.dossierStateTemplateId] = deadline.deadlineDays;
    }
    return deadlineList;
  }

  ngOnDestroy() {
    this.companySubscription.unsubscribe();
  }

  async delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,
      {
        panelClass: 'confirm-dialog',
        data: {dialogType: ConfirmDialogType.deleteDossier},
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (res) {
        await this.dossierService.delete(this.dossier.id);
        await this.router.navigate(['dashboard']);
      }
    });
  }

  nextStep() {
    if (this.dossierView === 'workflow') {
      if (this.isRepeat){
        this.dossierView = 'repeat';
        return;
      } else {
        this.dossierView = 'persons';
        return;
      }
    }
    if (this.dossierView === 'repeat') {
      this.dossierView = 'persons';
      return;
    }
    if (this.dossierView === 'persons') {
      this.dossierView = 'deadlines';
      return;
    }
  }
}
