import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Organization} from '../../../functional/models/organization.model';
import {Subscription} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {UserService} from '../../../services/user/user.service';
import {CompanyService} from '../../../services/company/company.service';
import {OrganizationService} from '../../../services/organization/organization.service';
import {Router} from '@angular/router';
import {Role} from '../../../functional/models/role.model';
import {CreateBusinessCustomerDialogComponent}
  from '../../../dialogs/dossiers/create-business-customer-dialog/create-business-customer-dialog.component';
import {Company} from '../../../functional/models/company';
import { Input } from '@angular/core';

@Component({
  selector: 'app-organization-overview',
  templateUrl: './organization-overview.component.html',
  styleUrls: ['./organization-overview.component.scss']
})
export class OrganizationOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(MatSort) set content(content: any) {
    if (content && this.organizationSource) {
      this.organizationSource.sort = content;
      this.organizationSource.paginator = this.paginator;
    }
  }

  @Input() set filter(value: string) {
    this.pFilter = value;
    this.applyFilter(this.pFilter);
  };

  @Input() userId?: number;

  pFilter?: string;
  displayedColumns = ['kvk', 'name', 'address'];
  organizationSource: MatTableDataSource<Organization> = new MatTableDataSource<Organization>([]);
  dialogSubscription = new Subscription();
  organizations: Organization[] = [];
  companySubscription = new Subscription();
  company!: Company;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private companyService: CompanyService,
    private organizationService: OrganizationService,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
      await this.refreshOrganizations();
    });
  }

  async refreshOrganizations() {
    if (this.userId){
      this.displayedColumns = [...this.displayedColumns, 'delete'];
      this.organizations = await this.organizationService.filter({userId: this.userId});
    } else {
      const claims = this.userService.tryClaims();
      if (claims.role === Role.superuser || claims.role === Role.admin) {
        this.organizations = await this.organizationService.filter({companyId: this.company.id});
      } else if (claims.role === Role.employee) {
        this.organizations = await this.organizationService.filter({userId: claims.userId});
      } else {
        throw new Error('Unreachable statement reached');
      }
    }
    this.organizationSource = new MatTableDataSource(this.organizations);
    this.organizationSource.paginator = this.paginator;
  }

  async create() {
    const dialogRef = this.dialog.open(CreateBusinessCustomerDialogComponent, {
      panelClass: 'create-business-customer-dialog',
      data: {
        company : this.company,
        organizationSearch: false,
        organizationEdit: true,
        allowEmployees: true
      },
      autoFocus: false
    });
    this.dialogSubscription = dialogRef.afterClosed().subscribe(async () => {
      await this.refreshOrganizations();
    });
  }

  ngOnDestroy(): void {
    this.dialogSubscription.unsubscribe();
    this.companySubscription.unsubscribe();
  }

  async update(organization: Organization) {
    const dialogRef = this.dialog.open(CreateBusinessCustomerDialogComponent, {
      panelClass: 'create-business-customer-dialog',
      data: { organization, allowEmployees: true },
      autoFocus: false
    });
    this.dialogSubscription = dialogRef.afterClosed().subscribe(async () => {
      await this.refreshOrganizations();
    });
  }

  async redirect(id: number) {
    await this.router.navigate(['management', 'organizations', 'details', id]);
  }

  applyFilter(filterValue: string) {
    this.organizationSource.filter = filterValue.trim().toLowerCase();

    if (this.organizationSource.paginator) {
      this.organizationSource.paginator.firstPage();
    }
  }

  async deleteOrganization(row: Organization, $event: MouseEvent) {
    await this.organizationService.removeUser(row.id, this.userId!);
    await this.refreshOrganizations();
  }
}
