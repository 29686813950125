export enum ConfirmDialogType {
  archived,
  administrationSubmitted,
  addDocumentToClosedDossier,
  requestApproval,
  sendToCustomer,
  documentsApproved,
  submitAdministration,
  skipFiles,
  skipSignatures,
  deleteUser,
  deleteOrganization,
  deleteGroup,
  deleteDossier,
  moveFile,
  employeeLimitReached
}
