import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/user/user.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Role} from 'app/functional/models/role.model';
import {Claims, RedactedUser, User} from 'app/functional/models/user';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConfirmDialogComponent} from '../../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {ConfirmDialogType} from '../../../../functional/models/dialog.model';
import {MfaService} from '../../../../services/mfa-service/mfa.service';
import {Company} from '../../../../functional/models/company';
import {CompanyService} from '../../../../services/company/company.service';
import {StartLoggingDialogComponent} from '../../../../dialogs/start-logging-dialog/start-logging-dialog.component';
import {DatePipe} from '@angular/common';
import {NavVariableService} from '../../../../services/nav-variable/nav-variable.service';
import {NavigationButton, NavigationTab} from '../../../../components/sub-nav/sub-nav.component';
import {CreateBusinessCustomerDialogComponent} from '../../../../dialogs/dossiers/create-business-customer-dialog/create-business-customer-dialog.component';
import {DossierOverviewComponent} from '../../../../components/dossiers/dossier-overview/dossier-overview.component';
import {ExchangeOverviewComponent} from '../../../../components/exchange/exchange-overview/exchange-overview.component';
import {OrganizationOverviewComponent} from '../../../../components/organization/organization-overview/organization-overview.component';
import {ConnectedGroupsComponent} from '../../../../components/connected-groups/connected-groups.component';
import {OrganizationService} from '../../../../services/organization/organization.service';
import {ConnectedUsersComponent} from '../../../../components/connected-users/connected-users.component';
import {CreateFolderDialogComponent} from '../../../../dialogs/exchange/create-folder-dialog/create-folder-dialog.component';
import {AddGroupDialogComponent} from '../../../../dialogs/add-group-dialog/add-group-dialog.component';
import {GroupService} from '../../../../services/group/group.service';
import {Folder} from '../../../../functional/models/folder';
import {SearchType, SortType} from '../../../../functional/models/exchange.model';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDetailsComponent implements OnInit {
  @ViewChild('autosize') autosize!: CdkTextareaAutosize;
  @ViewChild(OrganizationOverviewComponent) organizations!: OrganizationOverviewComponent;
  @ViewChild(ConnectedGroupsComponent) groups!: ConnectedGroupsComponent;
  @ViewChild(DossierOverviewComponent) dossiers!: DossierOverviewComponent;
  @ViewChild(ExchangeOverviewComponent) exchange!: ExchangeOverviewComponent;
  @ViewChild(ConnectedUsersComponent) connectedUsers!: ConnectedUsersComponent;

  editForm!: UntypedFormGroup;
  user!: User;
  userId!: number;
  claims!: Claims;
  localRole = Role;
  company!: Company;
  activeTab?: string;
  buttons: NavigationButton[] = [];
  userCustomers: User[] = [];
  searchType = SearchType.currentFolder;
  sortType = SortType.nameAscending;
  private crumbs: Folder[] = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private mfaService: MfaService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private router: Router,
    private navVariableService: NavVariableService,
    private organizationService: OrganizationService,
    private groupService: GroupService
  ) {
    this.userId = Number(this.activeRoute.snapshot.params.id);
    window.addEventListener('window-navigate', (e: any) => this.navigate(e.detail.folder), false);
  }

  async ngOnInit() {
    this.claims = this.userService.tryClaims();
    this.user = await this.userService.getUser(this.userId.toString());
    this.navVariableService.navVariables.next({user: this.user});

    this.company = await this.companyService.readById(this.user.companyId);

    this.editForm = this.formBuilder.group({
      email: [this.user.email, [Validators.required, Validators.email]],
      phone: [this.user.phone, []],
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      role: [this.user.role, [Validators.required]],
      gender: [this.user.gender || 0, []],
      birth: [this.user.birth, []],
      street: [this.user.street, []],
      houseNumber: [this.user.houseNumber, []],
      premise: [this.user.premise, []],
      postalCode: [this.user.postalCode, []],
      city: [this.user.city, []],
      description: [this.user.description, []],
      relationId: [this.user.relationId, []]
    });

    if (this.user.role === Role.employee) {
      this.userCustomers = await this.userService.getUserCustomers(this.user.id);
    }
  }

  async updateProfile(ignoreValidation = false) {
    if (!ignoreValidation) {
      if (this.editForm.invalid) {
        this.snackBar.open(
          this.translate.instant('users.details.incorrect_form'),
          this.translate.instant('close'),
          {duration: 5000},
        );
        return;
      }
    }

    await this.userService.editUser(this.userId, {
      email: this.editForm.get('email')!.value,
      firstName: this.editForm.get('firstName')!.value,
      lastName: this.editForm.get('lastName')!.value,
      role: Number(this.editForm.get('role')!.value),
      phone: this.editForm.get('phone')!.value,
      description: this.editForm.get('description')!.value,
      street: this.editForm.get('street')!.value,
      postalCode: this.editForm.get('postalCode')!.value,
      premise: this.editForm.get('premise')!.value,
      birth: this.editForm.get('birth')!.value ?
        this.datePipe.transform(new Date(this.editForm.get('birth')!.value), 'yyyy-MM-dd') : undefined,
      houseNumber: this.editForm.get('houseNumber')!.value,
      gender: Number(this.editForm.get('gender')!.value),
      city: this.editForm.get('city')!.value,
      companyId: this.user.companyId,
      relationId: this.editForm.get('relationId')!.value
    });
    this.snackBar.open(this.translate.instant('success'), '', {
      duration: 2000,
    });
  }

  async resetPassword() {
    try {
      await this.userService.forgot({
        email: this.user.email,
        companyId: this.user.companyId
      });
      this.snackBar.open(this.translate.instant('success'), '', {
        duration: 2000,
      });
    } catch {
    }
  }

  async deleteAccount() {
    try {
      const dialogRef = this.dialog.open(ConfirmDialogComponent,
        {
          panelClass: 'confirm-dialog',
          data: {dialogType: ConfirmDialogType.deleteUser},
          autoFocus: false
        });
      dialogRef.afterClosed().subscribe(async (res: any) => {
        if (res) {

          await Promise.all([
            this.disconnectUserFromOrganizations(),
            this.disconnectUserFromParents()
          ]);

          const r = (Math.random() + 1).toString(36).substring(7);
          const deletedEmail = this.user.email + '#deleted+' + r;
          this.editForm.get('email')?.setValue(deletedEmail);

          await this.updateProfile(true);

          await this.userService.deleteAccount(this.userId);
          this.snackBar.open(this.translate.instant('success'), '', {
            duration: 2000,
          });

          if (this.user.role === Role.employee || this.user.role === Role.admin) {
            this.router.navigate(['management', 'employees']);
          }
          if (this.user.role === Role.customer) {
            this.router.navigate(['management', 'customers']);
          }
        }
      });
    } catch {}
  }

  async disconnectUserFromOrganizations() {
    const organizations = await this.organizationService.filter({
      userId: this.userId
    });

    const organizationsDelete: Promise<void>[] = organizations.map(org => this.organizationService.removeUser(org.id, this.user.id));
    await Promise.all(organizationsDelete);
  }

  async disconnectUserFromParents() {
    let disconnectUserCall: Promise<void>[] = [];

    if (this.user.role === Role.employee) {
      const customers = await this.userService.getUserCustomers(this.userId);
      disconnectUserCall = customers.map(customer => this.userService.deleteParentUser(customer.id, this.userId));
    }

    if (this.user.role === Role.customer) {
      const employees = await this.userService.getUserEmployees(this.userId);
      disconnectUserCall = employees.map(employee => this.userService.deleteParentUser(this.userId, employee.id));
    }

    await Promise.all(disconnectUserCall);
  }

  async resendWelcome() {
    await this.userService.sendWelcome([this.userId]);
    this.snackBar.open(this.translate.instant('success'), '', {
      duration: 2000,
    });
  }

  async resetMfa() {
    const mfas = await this.mfaService.getMethodsByUser(this.userId);
    for (const mfa of mfas){
      await this.mfaService.resetMFAUser(mfa.id);
    }
    this.snackBar.open(this.translate.instant('success'), '', {
      duration: 2000,
    });
  }

  openLogging() {
    const dialogRef = this.dialog.open(StartLoggingDialogComponent, {autoFocus: false});
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.userService.startLogging(this.userId, res);
        this.snackBar.open(this.translate.instant('success'), '', {
          duration: 2000,
        });
      }
    });
  }

  async handleClick(event: any) {
    if (event === 'new' && this.activeTab === 'dossiers') {
      await this.openCreateDossier();
    }
    if (event === 'new' && this.activeTab === 'organizations') {
      await this.openCreateOrganization();
    }
    if (event === 'new' && this.activeTab === 'groups') {
      await this.openAddGroup();
    }
    if (event === 'new' && this.activeTab === 'files') {
      this.openCreateFolder();
    }
    if (event === 'new' && this.activeTab === 'customers') {
      const users = await this.connectedUsers.addUsers(false);
      const promises = [];
      for (const user of users) {
        if (!this.userCustomers.some(customer => customer.id !== user.id)) {
          promises.push(this.userService.setParent(user.id, {parentUserId: this.user.id}));
        }
      }
      await Promise.all(promises);
      this.userCustomers = await this.userService.getUserCustomers(this.user.id);
    }
    if (event === 'newFromTemplate' && this.activeTab === 'files') {
      await this.newFolderFromTemplate();
    }
  }

  newFolderFromTemplate() {
    const users = this.user.role === Role.employee || this.user.role === Role.customer ? [this.user] : [];
    const dialog = this.dialog.open(
      CreateFolderDialogComponent,
      {
        data: {
          company: this.company,
          currentUser: this.userService.tryClaims(),
          users,
          fromDuplicate: true
        },
        autoFocus: false
      }
    );
    dialog.afterClosed().subscribe(async () => {
      if (this.exchange.currentFolder.id === -1){
        await this.exchange.init();
      } else {
        await this.exchange.refreshDisplay();
      }
    });
  }

  openCreateFolder() {
    const users = this.user.role === Role.employee || this.user.role === Role.customer ? [this.user] : [];
    const dialog = this.dialog.open(
      CreateFolderDialogComponent,
      {
        data: {
          company: this.company,
          currentUser: this.userService.tryClaims(),
          users
        },
        autoFocus: false
      }
    );
    dialog.afterClosed().subscribe(async () => {
      if (this.exchange.currentFolder.id === -1){
        await this.exchange.init();
      } else {
        await this.exchange.refreshDisplay();
      }
    });
  }

  async openCreateOrganization() {
    const dialog = this.dialog.open(
      CreateBusinessCustomerDialogComponent,
      {
        panelClass: 'create-business-customer-dialog',
        data: {
          company : this.company,
          organizationSearch: false,
          organizationEdit: true,
          allowEmployees: true,
          users: [this.user]
        },
        autoFocus: false
      }
    );

    dialog.afterClosed().subscribe(() => {
      this.organizations.refreshOrganizations();
    });
  }

  async openCreateDossier() {
    let users: number[] = [];
    try {
      users = (await this.userService.getUserEmployees(this.userId)).map(item => item.id);
    } finally {
      await this.router.navigate(['dossier', 'create'],{
        queryParams: {customer: this.user.id, employee: users}
      });
    }
  }

  async openAddGroup() {
    const dialog = this.dialog.open(
      AddGroupDialogComponent,
      {
        data: {
          company: this.company,
        },
        autoFocus: false
      }
    );
    dialog.afterClosed().subscribe(async (result) => {
      if (result.selection) {
        if (this.user.role === Role.customer){
          await this.groupService.addCustomer(result.selection.id, this.user.id);
        } else {
          await this.groupService.addEmployee(result.selection.id, this.user.id);
        }
        await this.groups.setGroups();
      }
    });
  }

  async deleteCustomer(event: (RedactedUser | User)[]) {
    const calls = [];
    for (const user of event){
      calls.push(this.userService.deleteParentUser(user.id, this.user.id));
    }
    await Promise.all(calls);
    this.userCustomers = await this.userService.getUserCustomers(this.user.id);
  }

  navigate(item: Folder) {
    if (this.exchange) {
      const folder = this.exchange.breadCrumb.find(e => e.id === Number(item.id));
      if (folder) {
        this.exchange.navigate(folder);
      }
    }
  }

  setCrumbs(event: Folder[]) {
    this.crumbs = event;
    this.navVariableService.navVariables.next({user: this.user, folderCrumbs: event});
  }

  changeTab(event: NavigationTab) {
    this.activeTab = event.event;
    if (this.activeTab === 'files'){
      this.navVariableService.navVariables.next({user: this.user, folderCrumbs: this.crumbs});
    } else {
      this.navVariableService.navVariables.next({user: this.user});
    }
  }
}
