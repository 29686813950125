import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationOverviewComponent } from './organization-overview/organization-overview.component';
import { OrganizationDetailsComponent } from './organization-details/organization-details.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import { NgIconsModule } from '@ng-icons/core';
import {EmmaInputModule} from '../base-components/emma-input/emma-input.module';

@NgModule({
  declarations: [
    OrganizationOverviewComponent,
    OrganizationDetailsComponent,
  ],
  exports: [
    OrganizationOverviewComponent,
    OrganizationDetailsComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    TranslateModule.forRoot(),
    NgIconsModule,
    EmmaInputModule,
  ]
})
export class OrganizationModule { }
