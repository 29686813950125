import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger} from '@angular/material/legacy-menu';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  @Input()
  // eslint-disable-next-line @typescript-eslint/ban-types
  set data(data: object) {
    if (data) {
      this.children = [];
      this.values = [];
      this.childElements = {};
      this.valueElements = {};
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'object' && value !== null && !this.isDate(value)) {
          this.childElements[key] = value;
        } else {
          this.valueElements[key] = value;
        }
      }
      this.children = Object.entries(this.childElements);
      this.values = Object.entries(this.valueElements);
    }
  }
  @Input() prefix?: string;
  @Input() isInitial = true;
  @ViewChild('menu', { static: true }) menu!: MatMenu;
  @ViewChild('matTrigger', { read: MatMenuTrigger }) matTrigger?: MatMenuTrigger;
  @ViewChild('triggerElement') triggerElement?: ElementRef;

  @Output() contextClicked = new EventEmitter<string>();

  childElements: Record<string, any> = {};
  valueElements: Record<string, string> = {};
  children: [string, any][] = [];
  values: [string, string][] = [];

  constructor() {}
  ngOnInit(): void {}

  toggle(event: MouseEvent) {
    this.triggerElement!.nativeElement.style.left = (event.clientX / 100 * 145) + 10 + 'px';
    this.triggerElement!.nativeElement.style.top = (event.clientY / 100 * 145) + 10 + 'px';
    if (this.matTrigger!.menuOpen) {
      this.matTrigger!.closeMenu();
      this.matTrigger!.openMenu();
    } else {
      this.matTrigger!.openMenu();
    }
  }

  emitData(value?: string) {
    if (value) {
      if (this.prefix) {
        this.contextClicked.emit(`${this.prefix}.${value}`);
      } else {
        this.contextClicked.emit(value);
      }
    }
  }

  getChildPrefix(child: string): string {
    if (this.prefix) {
      return `${this.prefix}.${child}`;
    } else {
      return child;
    }
  }

  private isDate(s: any): boolean {
    return !isNaN(Date.parse(s));
  }
}
