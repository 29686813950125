<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="add-dossier-template-step-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'dossier_template.dialog.add_step.title' | translate }} </p>
    </div>
  </div>
  <form ngForm [formGroup]="addStepForm">
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <div class="emma-input-container">
          <label label for="name" class="emma-input-label">{{ 'dossier_template.dialog.add_step.name' | translate }}</label>
          <input appEmmaInput id="name" formControlName="name">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top reverse">
        <mat-label>{{ 'dossier_template.dialog.add_step.deadlines' | translate }}</mat-label>
        <mat-checkbox formControlName="mayHaveDeadline" class="happy_checkbox"></mat-checkbox>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button data-test="delete-submit-button"  class="btn btn-blue btn-custom" (click)="addStep()">
        <b> {{ 'dossier_template.dialog.add_step.submit' | translate }} </b>
      </button>
    </div>
  </div>
</div>
