<button
  mat-button class="document-item"
  [id]="document.id"
  [ngClass]="isSelected() ? 'selected' : ''"
  [appEmmaTooltip]="document.name"
  (click)="triggerClick()">
  <mat-checkbox [disableRipple]="true" class="checkbox-position" (click)="changeSelection($event)" [checked]="isSelected()"></mat-checkbox>
  <mat-icon
    [ngClass]="getIconColor()">
    {{ getIcon() }}
    <span class="type" *ngIf="getIcon() == 'insert_drive_file'">
      <strong class="extension">{{ document.extension }}</strong>
    </span>
  </mat-icon>
  <span class="title">{{ document.name }}</span>
</button>
