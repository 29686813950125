import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ChatService} from '../../../services/chat/chat.service';

@Component({
  selector: 'app-delete-chat-dialog',
  templateUrl: './delete-chat-dialog.component.html',
  styleUrls: ['./delete-chat-dialog.component.scss']
})
export class DeleteChatDialogComponent implements OnInit {

  chatId!: number;

  constructor(
    public dialogRef: MatDialogRef<DeleteChatDialogComponent>,
    private chatService: ChatService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    this.chatId = this.data.chatId;
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  async deleteChat() {
    await this.chatService.deleteChat(this.chatId);
    this.dialogRef.close(true);
  }
}
