<div class="logo">
  <div class="overlay center" *ngIf="userRole === roles.superuser" (click)="file.click()">
    <ng-icon name="heroArrowUpTray" class="upload-icon"></ng-icon>
  </div>
  <span class="simple-text centered-logo">
    <div class="logo-img">
      <img [src]="logo" [alt]="company?.name">
    </div>
  </span>
</div>
<input #file type="file" class="hidden" (change)="uploadFile($event)" accept="image/png" />
