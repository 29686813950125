import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Company} from '../../functional/models/company';
import {Group} from '../../functional/models/group';

@Component({
  selector: 'app-add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.scss']
})
export class AddGroupDialogComponent implements OnInit {
  company: Company;
  selectedGroup?: Group;

  constructor(
    public dialogRef: MatDialogRef<AddGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.company = this.data.company;
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }

  addGroup() {
    this.dialogRef.close({
      selection: this.selectedGroup
    });
  }
}
