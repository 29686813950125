<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div class="sign-document-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title" mat-dialog-title>{{'viewer.sign_dialog.title' | translate}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div mat-dialog-content>
        <p>{{'viewer.sign_dialog.subtitle' | translate}}</p>
        <app-signaturepad [options]="signaturePadOptions" (endDrawing)="drawComplete()"></app-signaturepad>
      </div>
    </div>
  </div>
  <div class="row sign-document-buttons">
    <button class="btn btn-outline-blue" (click)="clear()">{{'viewer.sign_dialog.clear' | translate}}</button>
    <button  (click)="saveSignature()" class="btn btn-blue btn-custom" >{{'viewer.sign_dialog.sign' | translate}}</button>
  </div>
</div>
