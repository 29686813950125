export const environment = {
  production: false,
  rootUrl: 'https://t-api.myemmacloud.nl/v1/',
  socketUrl: 'wss://t-stream.myemmacloud.nl/v1/',
  exactOnline: {
    clientId: 'c02a6789-9ee0-48d0-84ac-041521ccfeee',
    redirectUri: 'https://redirect.test.myemmacloud.nl/',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAtKwMXpis1HE3LH23n9TfUbRAOVR_Q4Ig',
    authDomain: 'myemmatest-d4bfe.firebaseapp.com',
    databaseURL: 'https://myemmatest-d4bfe.firebaseio.com',
    projectId: 'myemmatest-d4bfe',
    storageBucket: 'myemmatest-d4bfe.appspot.com',
    messagingSenderId: '464264988357',
    appId: '1:464264988357:web:5e28e65f90d43a95cb12ac'
  }
};
