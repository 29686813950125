import { Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MailTemplateService, MailElements } from 'app/services/mail-template/mail-template.service';
import { MailParameterService } from 'app/services/mail-parameter/mail-parameter.service';
import { MailParameter } from 'app/functional/models/mail-parameter.model';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { UserService } from 'app/services/user/user.service';
import { MailTemplate, MailTemplateKind } from 'app/functional/models/mail-template.model';
import { Company } from 'app/functional/models/company';
import { CompanyService } from 'app/services/company/company.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';

class TemplateModel {
  kind!: MailTemplateKind;
  name!: string;
  configured = false;
  content!: string;
  subject!: string;
  version!: number;
}

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnInit, OnDestroy {
  @ViewChildren('span') menuSpans!: QueryList<any>;

  pending = false;
  image = 'signing-reminder';
  subject?: string;
  header?: string;
  text?: string;
  button?: string;
  subtext?: string;
  footer?: string;
  mailTemplates: MailTemplate[] = [];
  mailTemplateVariants = Object
    .keys(MailTemplateKind)
    .map(str => Number(str))
    .filter(kind => !isNaN(kind))
    .filter(kind => kind !== MailTemplateKind.registerSuperuser);
  companySubscription = new Subscription();
  company!: Company;
  templates: TemplateModel[] = [];
  selectedTemplate?: TemplateModel;
  selectedTemplateOptions?: MailElements;
  parentItem!: HTMLElement;
  caretSelection?: Range;
  endOffset!: number;
  startOffset!: number;
  node?: Node;
  parentElement?: HTMLElement;
  range?: any;
  restore?: any;
  rp?: any;
  params: MailParameter[] = [];
  defaultParams: MailParameter[] = [];
  pos: any = undefined;
  context?: any;

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private mailTemplateService: MailTemplateService,
    private mailParameterService: MailParameterService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (this.pending) {
        return;
      }
      this.pending = true;
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
      this.params = await this.mailParameterService.filter({ companyId: this.company.id });
      this.defaultParams = await this.mailParameterService.default();
      this.mailTemplates = (await this.mailTemplateService.recent())
        .filter(mt => this.mailTemplateService.meta(mt.kind).editable);

      this.templates = [];
      for (const template of this.mailTemplates.filter(t => t.content !== '')) {
        this.templates.push({
          kind: template.kind,
          name: this.mailTemplateService.kindToName(template.kind),
          configured: this.isConfigured(template.kind),
          subject: template.subject,
          content: template.content,
          version: template.version,
        });
      }
      this.setSelected(this.templates[0]);
      this.pending = false;
    });
  }

  setSelected(template: TemplateModel) {
    this.selectedTemplate = template;
    this.context = this.mailTemplateService.context(template.kind, this.company);
    this.selectedTemplateOptions = this.mailTemplateService.meta(template.kind);

    const ctx = this.getNetParams();
    if (this.selectedTemplateOptions?.subject) {
      const subject = ctx[this.selectedTemplateOptions.subject];
      if (subject) {
        const htmlSubject = this.handleToHtml(subject);
        this.subject = htmlSubject;
      } else {
        this.subject = '';
      }
    } else {
      this.subject = '';
    }

    if (this.selectedTemplateOptions?.header) {
      const header = ctx[this.selectedTemplateOptions.header];
      if (header) {
        const htmlText = this.handleToHtml(header);
        this.header = htmlText;
      } else {
        this.header = '';
      }
    } else {
      this.header = '';
    }

    if (this.selectedTemplateOptions?.text) {
      const text = ctx[this.selectedTemplateOptions.text];
      if (text) {
        const htmlText = this.handleToHtml(text);
        this.text = htmlText;
      } else {
        this.text = '';
      }
    } else {
      this.text = '';
    }

    if (this.selectedTemplateOptions?.button) {
      const button = ctx[this.selectedTemplateOptions.button];
      if (button) {
        const htmlButton = this.handleToHtml(button);
        this.button = htmlButton;
      } else {
        this.button = '';
      }
    } else {
      this.button = '';
    }

    if (this.selectedTemplateOptions?.subtext) {
      const subtext = ctx[this.selectedTemplateOptions.subtext];
      const htmlSubtext = this.handleToHtml(subtext);
      this.subtext = htmlSubtext;
    } else {
      this.subtext = '';
    }

    if (this.selectedTemplateOptions?.footer) {
      const footer = ctx[this.selectedTemplateOptions.footer];
      const htmlFooter = this.handleToHtml(footer);
      this.footer = htmlFooter;
    } else {
      this.footer = '';
    }
  }

  ngOnDestroy() {
    this.companySubscription.unsubscribe();
  }

  isConfigured(kind: MailTemplateKind): boolean {
    // TODO
    // determine the used params in this template
    // check if any of the params occur in `this.params`.
    return false;
  }

  cancel() {}

  htmlToHandle(
    tag: 'subject' | 'header' | 'text' | 'button' | 'subtext' | 'footer',
    html: string
  ): string | undefined {
    html = html.replace( /<br\s*[\/]?>/gi, ' ');

    let titleCopy: any = this[tag];
    if (!titleCopy) {
      return;
    }
    const item = new DOMParser().parseFromString(html, 'text/html').body;
    const children = item.children;

    for (const childItem of Array.from(children)) {
      const child = childItem as HTMLElement;
      titleCopy = titleCopy.replace(child.outerHTML, '{{' + child.title + '}}');
    }
    titleCopy = titleCopy.replace( /<br\s*[\/]?>/gi, ' ');
    titleCopy = titleCopy.replace(/&#8203;/g, '');
    titleCopy = titleCopy.replace(/&nbsp;/g, ' ');
    return titleCopy;
  }

  async openContextMenu(
    event: MouseEvent,
    trigger: ContextMenuComponent,
    parentItem: HTMLElement,
  ) {
    this.parentElement = parentItem;
    trigger.toggle(event);
    this.parentItem = parentItem;
    event.preventDefault();
  }

  renderTag(tag: string, name: string) {
    const span: HTMLElement = document.createElement('span');
    span.title = tag;
    span.innerHTML = name;
    span.classList.add('label');
    this.insertTextAtCaret(span);
  }

  insertTextAtCaret(label: HTMLElement) {
    try {
      this.restoreRangePosition(this.parentElement!);
      const range = document.getSelection()!.getRangeAt(0);
      range!.insertNode(label);
      label.insertAdjacentHTML('afterend', '&#8203;&nbsp;');
    } catch (e) {
    }
  }

  deletePressed(event: KeyboardEvent) {
    if (event.key !== 'Backspace') {
      return;
    }
    const target = event.target as HTMLElement;
    const s = window.getSelection();
    const r = s!.getRangeAt(0);
    const el = r.startContainer.parentElement!;

    if (el.classList.contains('label')) {
      // Check if we are exactly at the end of the .label element
      if (r.startOffset === r.endOffset && r.endOffset === el.textContent!.length) {
        // prevent the default delete behavior
        event.preventDefault();
        if (el.classList.contains('highlight')) {
          // remove the element
          el.remove();
        } else {
          el.classList.add('highlight');
        }
        return;
      }
    }
    target.querySelectorAll('span.label.highlight').forEach((elem) => {
      elem.classList.remove('highlight');
    });
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  saveRangePosition(editor: HTMLElement): void {
    const range = window.getSelection()!.getRangeAt(0);
    let startCaret: any = range.startContainer;
    let endCaret: any = range.endContainer;

    const pathToStart = [];
    while (startCaret !== editor) {
      pathToStart.push(this.getNodeIndex(startCaret));
      startCaret = startCaret.parentNode;
    }
    const pathToEnd = [];
    while (endCaret !== editor) {
      pathToEnd.push(this.getNodeIndex(endCaret));
      endCaret = endCaret.parentNode;
    }

    this.pos = {
      startCaret: pathToStart,
      startOffset: range.startOffset,
      endCaret: pathToEnd,
      endOffset: range.endOffset,
    };
  }

  restoreRangePosition(editor: HTMLElement): void {
    editor.focus();
    const selection = window.getSelection()!;
    const range = selection.getRangeAt(0);
    let startCaret: ChildNode = editor;
    let endCaret: ChildNode = editor;

    let C = this.pos.startCaret;
    let x = C.length;
    while (x--) {
      startCaret = startCaret.childNodes[C[x]];
    }
    C = this.pos.endCaret;
    x = C.length;
    while (x--) {
      endCaret = endCaret.childNodes[C[x]];
    }
    range.setStart(startCaret, this.pos.startOffset);
    range.setEnd(endCaret, this.pos.endOffset);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  getNodeIndex(n: Node): number {
    let i = 0;
    while (true) {
      if (!n.previousSibling) {
        break;
      } else {
        n = n.previousSibling;
      }
      i++;
    }
    return i;
  }

  handleToHtml(bars: string) {
    const ctx = this.mailTemplateService.context(this.selectedTemplate!.kind, this.company);
    let htmlString = bars;
    const barItems = bars.match(/{{([^{}]+)}}/g);
    if (barItems) {
      for (const item of barItems!.entries()) {
        const trimmedItem = item[1].replace(/{{|}}|/g, '').trim();
        const path = trimmedItem.split('.');
        let value: any = ctx;
        for (const segment of path) {
          value = value[segment];
        }
        htmlString = htmlString.replace(item[1], `<span class="label" title="${trimmedItem}">${value}</span>&#8203;&nbsp;`);
      }
    }
    return htmlString;
  }

  async update(): Promise<void> {
    const params = [];
    const claims = this.userService.tryClaims();
    if (this.selectedTemplateOptions?.subject) {
      params.push({
        version: this.selectedTemplate!.version,
        name: this.selectedTemplateOptions!.subject,
        value: this.htmlToHandle('subject', this.subject!)!,
        language: 'nl',
        companyId: claims.companyId,
      });
    }
    if (this.selectedTemplateOptions?.header) {
      params.push({
        version: this.selectedTemplate!.version,
        name: this.selectedTemplateOptions!.header,
        value: this.htmlToHandle('header', this.header!)!,
        language: 'nl',
        companyId: claims.companyId,
      });
    }
    if (this.selectedTemplateOptions?.text) {
      params.push({
        version: this.selectedTemplate!.version,
        name: this.selectedTemplateOptions!.text,
        value: this.htmlToHandle('text', this.text!)!,
        language: 'nl',
        companyId: claims.companyId,
      });
    }
    if (this.selectedTemplateOptions?.button) {
      params.push({
        version: this.selectedTemplate!.version,
        name: this.selectedTemplateOptions!.button,
        value: this.htmlToHandle('button', this.button!)!,
        language: 'nl',
        companyId: claims.companyId,
      });
    }
    if (this.selectedTemplateOptions?.subtext) {
      params.push({
        version: this.selectedTemplate!.version,
        name: this.selectedTemplateOptions!.subtext,
        value: this.htmlToHandle('subtext', this.subtext!)!,
        language: 'nl',
        companyId: claims.companyId,
      });
    }
    if (this.selectedTemplateOptions?.footer) {
      params.push({
        version: this.selectedTemplate!.version,
        name: this.selectedTemplateOptions!.footer,
        value: this.htmlToHandle('footer', this.footer!)!,
        language: 'nl',
        companyId: claims.companyId,
      });
    }

    const toCreate = [];
    const toUpdate = [];
    for (const param of params) {
      const existingParam: MailParameter | undefined = this.params.find(p => p.name === param.name);
      if (existingParam) {
        existingParam.value = param.value;
        toUpdate.push(existingParam);
      } else {
        toCreate.push(param);
      }
    }

    const newParams = await this.mailParameterService.create(toCreate);
    this.params = [...this.params, ...newParams];

    for (const u of toUpdate) {
      const result = await this.mailParameterService.update(u.id, u);
      this.params = this.params.filter((param) => param.id !== result.id);
      this.params.push(result);
    }

    this.snackBar.open(this.translate.instant('success'), '', {
      duration: 2000,
    });
  }

  async restoreParams(): Promise<void> {
    const kind = this.selectedTemplate!.kind;
    const used = this.mailTemplateService.toList(kind);

    const version = this.selectedTemplate!.version;
    const toDelete = this.params.filter(p => used.includes(p.name) && p.version === version);
    toDelete.forEach(async td => await this.mailParameterService.delete(td));
    this.params = this.params.filter(p => !(used.includes(p.name) && p.version === version));
    this.setSelected(this.selectedTemplate!);
  }

  log(event: string, ctxt: any) {
    const ctx = this.mailTemplateService.context(this.selectedTemplate!.kind, this.company);
    const path = event.split('.');
    let value: any = ctx;
    for (const segment of path) {
      value = value[segment];
    }
    this.renderTag(event, value);
  }

  private getNetParams(): Record<string, string> {
    const ctx: Record<string, string> = {};
    [
      ...this.params,
      // add all `defaultParams` that are not overridden in `params`
      ...this.defaultParams.filter(dp => !this.params.some(p => dp.name === p.name))
    ].forEach(p => ctx[p.name] = p.value);
    return ctx;
  }
}
