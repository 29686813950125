<div class="table-paginator-container">
  <div class="mat-table-container">
    <table mat-table [dataSource]="organizationSource" matSort matSortActive="name" matSortDirection="asc" class="organization-overview-table emma-table">
      <ng-container matColumnDef="kvk">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'organization.kvk' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row.kvk  || '-'}}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'organization.name' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef class="sort-style">{{'organization.address' | translate}}</th>
        <td mat-cell *matCellDef="let row">
          <span class="cell-block">
            <span class="font-default" *ngIf="row.street">{{row.street}} {{row.houseNumber}} {{row.premise}}</span>
            <span class="font-default" *ngIf="row.postalCode || row.city">{{row.postalCode}} {{row.city}}</span>
            <span class="font-default" *ngIf="!row.street && !row.postalCode && !row.city"> - </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <ng-icon name="heroTrash" class="ng-icon-default hover-hero" (click)="deleteOrganization(row, $event)"></ng-icon>
        </td>
      </ng-container>

      <tr class="empty-row" *matNoDataRow>
        <td colspan="9999">
          <div class="illustration">
            <img src="assets/img/illustrations/business.svg">
            <h1 class="not-found-title">{{'organization.no_organizations' | translate}}</h1>
            <span>
              {{'organization.no_organizations_text1' | translate}}
              {{'organization.no_organizations_text2' | translate}}
            </span>
          </div>
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="element" *matRowDef="let row; columns: displayedColumns;" (click)="redirect(row.id)"></tr>
    </table>
  </div>
  <div>
    <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </div>
</div>
