import simplicateAddon from './simplicate';
import { AddonModel } from '../../../functional/models/addon';
import mfaAddon from './mfa';

const addons: {id: number; app: AddonModel}[] = [
  {
    id: 1,
    app: simplicateAddon,
  },
  {
    id: 5,
    app: mfaAddon,
  }
  ];

export default addons;
