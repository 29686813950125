import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileRequest} from '../../../functional/models/file-request.model';
import {SelectionModel} from '@angular/cdk/collections';
import {ExplorerItem} from '../../../functional/models/explorer.model';

@Component({
  selector: 'app-file-request',
  templateUrl: './file-request.component.html',
  styleUrls: ['./file-request.component.scss']
})
export class FileRequestComponent implements OnInit {
  @Output() selectChange = new EventEmitter();
  @Output() clickChange = new EventEmitter();
  @Input() highlighted?: FileRequest;
  @Input() selected?: SelectionModel<ExplorerItem>;
  @Input() request!: FileRequest;

  constructor() { }

  ngOnInit(): void {
  }

  isSelected() {
    return !!this.selected?.selected.find(item => item.request && item.request.id === this.request.id);
  }

  changeSelection(event: Event) {
    this.selectChange.emit(this.request);
    event.stopPropagation();
    event.preventDefault();
  }

  triggerClick() {
    this.clickChange.emit(this.request);
  }
}
