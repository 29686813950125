import {Routes} from '@angular/router';
import {CompanyDetailsComponent} from './company-details/company-details.component';
import {CompanyListComponent} from './company-list/company-list.component';
import {CompanyFormComponent} from './company-form/company-form.component';

export const companyRoutes: Routes = [
  { path: 'details/:id', component: CompanyDetailsComponent},
  { path: 'edit/:id', component: CompanyDetailsComponent},
  { path: 'create', component: CompanyFormComponent},
  { path: '', component: CompanyListComponent},
];
