import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCustomersComponent } from './search-customers.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AutocompleteSelectModule} from '../../base-components/autocomplete-select/autocomplete-select.module';

@NgModule({
  declarations: [
    SearchCustomersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    AutocompleteSelectModule,
  ],
  exports: [
    SearchCustomersComponent
  ]
})
export class SearchCustomersModule { }
