import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { Claims } from 'app/functional/models/user';
import { UserService } from 'app/services/user/user.service';
import { Role } from 'app/functional/models/role.model';
import {DossierOverviewComponent} from '../../../components/dossiers/dossier-overview/dossier-overview.component';

@Component({
  selector: 'app-dossier-dashboard',
  templateUrl: './dossier-dashboard.component.html',
  styleUrls: ['./dossier-dashboard.component.scss']
})
export class DossierDashboardComponent implements OnInit {
  localRole = Role;
  claims!: Claims;
  view: 'open' | 'closed' | 'all' | 'recurring' = 'open';

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.claims = this.userService.tryClaims();
  }

  async createDossier() {
    await this.router.navigate(['dossier', 'create']);
  }

  async handleClick(event: any) {
    if (event === 'new'){
      await this.createDossier();
    }
  }

  setView(selectedTab: any) {
    this.view = selectedTab.event;
  }

  async action(event: any, dossierOverview: DossierOverviewComponent) {
    if (event === 1){
      await dossierOverview.downloadDossiers();
    }
    if (event === 2){
      await dossierOverview.deleteSelected();
    }
  }
}
