import { Component, Inject } from '@angular/core';
import { SigningService } from '../../../services/signing/signing.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Signing } from '../../../functional/models/signing';
import { Claims } from '../../../functional/models/user';
import { UserService } from '../../../services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-reply-deny-document-dialog',
  templateUrl: './reply-deny-document-dialog.component.html',
  styleUrls: ['./reply-deny-document-dialog.component.scss']
})
export class ReplyDenyDocumentDialogComponent {

  reaction = '1';
  signature: Signing;
  claims!: Claims | null | undefined;
  reactionForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ReplyDenyDocumentDialogComponent>,
    private userService: UserService,
    private signingService: SigningService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.signature = data.signature;
    this.claims = this.userService.getLoginClaims();
    this.reactionForm = new UntypedFormGroup({
      reply: new UntypedFormControl('', [])
    });
  }

  close() {
    this.dialogRef.close({replied: false});
  }

  async submit() {
    await this.signingService.startConversation(this.signature.id, {
      signingId: this.signature.id,
      content: this.reactionForm.get('reply')?.value,
      subject: this.translate.instant('dialog.request.reaction')
    });
    this.dialogRef.close({replied: true});
  }

  closeModal() {
    this.dialogRef.close();
  }
}
