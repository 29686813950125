import { Folder } from './folder';
import { RedactedUser } from './user';
import { Role } from './role.model';
import { FileRequest } from './file-request.model';
import {Actor} from '../../services/dossier/dossier.service';
import {Organization} from './organization.model';
import {DossierStateTemplate, DossierTemplate} from './dossier-template.model';

export enum DossierEvent {
  signingDone = 0,
  allSigningsDone = 1,
  fileRequestCompleted = 2,
  allFileRequestsCompleted = 3,
  signingRejected = 4,
  dossierCompleted = 5,
  dossierCreated = 6,
  deadlineThresholdReached = 7,
  manualEmployeeAction = 8,
  manualCustomerAction = 9,
  signingsRequested = 10,
}

export class DossierState {
  id!: number;
  name!: string;
  dossierId!: number;
  createdAt!: Date;
  updatedAt!: Date;
  reachedAt?: Date;
  initialState!: boolean;
  finalState!: boolean;
  actions!: DossierAction[];
  deadline?: string;
  mayHaveDeadline!: boolean;
  deadlineWarningPeriod?: number;
  permissionChanges!: DossierPermissionChange[];

  static default() {
    return {
      id: 1,
      name: 'Documenten staan klaar',
      dossierId: 1,
      createdAt: new Date(),
      updatedAt: new Date(),
      reachedAt: new Date(),
      initialState: false,
      finalState: false,
      deadline: new Date().toString(),
      deadlineWarningPeriod: 4,
    };
  }
}

export class Progress {
  current!: number;
  of!: number;
}

export class Dossier {
  id!: number;
  name!: string;
  dossierState!: DossierState;
  folder!: Folder;
  createdAt!: Date;
  updatedAt!: Date;
  states!: DossierState[];
  folders!: Folder[];
  employees!: RedactedUser[];
  customers!: RedactedUser[];
  lastEvent!: Date;
  fileRequests!: FileRequest[];
  organizations!: Organization[];

  static getDossierActor(dossier: Dossier): Actor {
    const result = {
      employee: false,
      customer: false,
    };
    for (const action of dossier.dossierState.actions) {
      const actionActor = Dossier.getActionActor(action);
      result.employee = result.employee || actionActor.employee;
      result.customer = result.customer || actionActor.customer;
    }
    return result;
  }

  static getHappyActor(dossier: Dossier): Actor | undefined {
    const happyAction = Dossier.getHappyAction(dossier.dossierState);
    return happyAction ? this.getActionActor(happyAction) : undefined;
  }

  static getHappyAction(state: DossierState): DossierAction | undefined {
    return state.actions.find(a => a.isHappy);
  }

  static getDossierProgress(dossier: Dossier): Progress {
    let index = 0;
    let current = 0;
    for (const state of this.orderStates(dossier)) {
      ++index;
      if (!dossier.dossierState || !dossier.dossierState.id){
        return {
          current: (current - 1),
          of: (index - 1),
        };
      }

      if (state.id === dossier.dossierState.id) {
        current = index;
      }
    }
    return {
      current: (current - 1),
      of: (index - 1),
    };
  }

  static orderStates(dossier: Dossier): DossierState[] {
    // find the initial state
    const initial = dossier.states.find((state) => state.initialState);
    if (!initial) {
      throw new Error(`No initial state found for dossier ${dossier.id}`);
    }
    const result: [DossierState] = [initial];
    while (!result[result.length - 1]?.finalState) {
      const currentState = result[result.length - 1];
      // find the happy action
      const happyAction = currentState.actions.find((action) => action.isHappy);
      if (!happyAction) {
        return result;
        //throw new Error(`No no happy action found for state ${currentState.id}`);
      }
      // find the next state
      const nextState = dossier.states.find((state) => state.id === happyAction.destinationStateId);
      if (!nextState) {
        throw new Error(`No next state found for happy action ${happyAction.id}`);
      }
      result.push(nextState);
    }
    return result;
  }

  static orderDossierTemplate(dossier: DossierTemplate): DossierStateTemplate[] {
    // find the initial state
    const initial = dossier.states.find((state) => state.initialState);
    if (!initial) {
      throw new Error(`No initial state found for dossier ${dossier.id}`);
    }
    const result: [DossierStateTemplate] = [initial];
    while (!result[result.length - 1]?.finalState) {
      const currentState = result[result.length - 1];
      // find the happy action
      let happyAction = currentState.actions.find((action) => action.isHappy);
      if (!happyAction && currentState.actions.length === 0) {
        return result;
        //throw new Error(`No no happy action found for state ${currentState.id}`);
      }

      if (!happyAction){
        happyAction = currentState.actions[0];
      }

      const nextState = dossier.states.find((state) => state.id === happyAction!.destinationStateTemplateId);
      if (!nextState) {
        throw new Error(`No next state found for happy action ${happyAction.id}`);
      }
      result.push(nextState);
    }
    return result;
  }

  static default() {
    return {
      id: 1,
      name: 'Dossier 1',
      dossierState: DossierState.default(),
      folder: Folder.default(),
      createdAt: new Date(),
      updatedAt: new Date(),
      lastEvent: new Date(),
    };
  }

  private static getActionActor(action: DossierAction): Actor {
    const result = {
      employee: false,
      customer: false,
    };
    if (action.event === DossierEvent.signingDone) {
      // we are waiting for a signing
      result.customer = true;
    } else if (action.event === DossierEvent.allSigningsDone) {
      // we are waiting for a signing
      result.customer = true;
    } else if (action.event === DossierEvent.fileRequestCompleted) {
      // we are waiting for file requests to be fulfilled
      result.customer = true;
    } else if (action.event === DossierEvent.allFileRequestsCompleted) {
      // we are waiting for file requests to be fulfilled
      result.customer = true;
    } else if (action.event === DossierEvent.signingRejected) {
      // we are waiting for files to be edited
      result.employee = true;
    } else if (action.event === DossierEvent.dossierCompleted) {
      // we are not waiting for anyone yay
    } else if (action.event === DossierEvent.dossierCreated) {
      // dossier has been created but nothing else
    } else if (action.event === DossierEvent.deadlineThresholdReached) {
      // the employee may send a reminder
      result.employee = true;
    } else if (action.event === DossierEvent.manualEmployeeAction) {
      // requires the employee to advance the dossier
      result.employee = true;
    } else if (action.event === DossierEvent.manualCustomerAction) {
      // requires the customer to advance the dossier
      result.customer = true;
    } else if (action.event === DossierEvent.signingsRequested) {
      // requires the customer to advance the dossier
      result.employee = true;
    }
    return result;
  }
}

export class DossierAction {
  id!: number;
  name!: string;
  stateId!: number;
  destinationStateId!: number;
  isHappy!: boolean;
  event!: DossierEvent;
  createdAt!: Date;
  updatedAt!: Date;
  fileRequestUpdates!: DossierActionFileRequest[];

  static isManual(da: DossierAction): boolean {
    return da.event === DossierEvent.manualEmployeeAction || da.event === DossierEvent.manualCustomerAction;
  }
}

export class DossierActionFileRequest {
  id!: number;
  dossierActionId!: number;
  fileRequestId!: number;
  isActive!: boolean;
}

export class DossierPermissionChange {
  id!: number;
  folderId!: number;
  role?: Role;
  dossierId!: number;
  dossierStateId!: number;
  permissions!: string;
}

export const dossierEventNames = [
  {event: DossierEvent.signingDone, name: 'Alle Bestandsverzoeken voldaan'},
  {event: DossierEvent.allSigningsDone, name: 'Alle signeringen voltooid'},
  {event: DossierEvent.fileRequestCompleted, name: 'Signering voltooid'},
  {event: DossierEvent.allFileRequestsCompleted, name: 'Bestandsverzoeken voltooid'},
  {event: DossierEvent.signingRejected, name: 'Signering afgekeurd'},
  {event: DossierEvent.dossierCompleted, name: 'Dossier voltooid'},
  {event: DossierEvent.dossierCreated, name: 'Dossier aangemaakt'},
  {event: DossierEvent.deadlineThresholdReached, name: 'Deadline bereikt'},
  {event: DossierEvent.manualEmployeeAction, name: 'Handmatige actie medewerker'},
  {event: DossierEvent.manualCustomerAction, name: 'Handmatige actie klant'},
  {event: DossierEvent.signingsRequested, name: 'Signering aangevraagd'},
];


// new dossiers

export class NewDossier {
  name!: string;
  templateId!: number;
  companyId!: number;
  deadlines!: any;
  users!: NewDossierUser[];
}

export class EditDossier {
  name!: string;
  deadlines!: any;
  users!: NewDossierUser[];
}

export class DeadlineObject {

}

export class NewDossierUser {
  userId!: number;
  permissions!: string;
}

// updating a dossier

export class UpdateDossier {
  name!: string;
}

export class DossierFolderModel {
  description!: string;
  name!: string;
  users!: Record<number, string>;
}

export class DossierEditFolderModel {
  description!: string;
  name!: string;
  users!: Record<number, string>;
}

