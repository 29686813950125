import { Directive, HostBinding, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appEmmaInput]',
})
export class EmmaInputDirective {

  constructor(
    @Optional() private ngControl: NgControl
  ) {
  }

  @HostBinding('class.emma-input')
  isAlt() {
    return true;
  }

  @HostBinding('class.is-valid')
  public get isValid(): boolean {
    return this.valid;
  }

  @HostBinding('class.is-invalid')
  public get isInvalid(): boolean {
    return this.invalid;
  }

  public get valid(): boolean {
    if (this.ngControl) {
      return this.ngControl.valid! && (this.ngControl.dirty! || this.ngControl.touched!);
    }
    return true;
  }

  public get invalid(): boolean {
    if (this.ngControl) {
      return !this.ngControl.pending! && !this.ngControl.valid! && (this.ngControl.touched! || this.ngControl.dirty!);
    }
    return false;
  }
}
