<app-logo-upload></app-logo-upload>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
  </div>
  <ul class="nav">
    <li [routerLinkActive]="menuItem.children ? '' : 'active custom'" *ngFor="let menuItem of menuItems" [class]="menuItem.class + ' nav-item'">
      <a class="nav-link center-items" (click)="menuItem.toggle = !menuItem.toggle" *ngIf="menuItem.children" [id]="menuItem.title">
        <ng-icon class="ng-icon-default-size" [name]="menuItem.icon"></ng-icon>
        <p class="p-flex-center" style="width: 100%;">{{ 'menu.' + menuItem.title | translate }}
          <span class="nav-badge" [matBadge]="getStatistic(menuItem.hasBadge).toString()" matBadgeOverlap="false" *ngIf="menuItem.hasBadge && getStatistic(menuItem.hasBadge) > 0"></span>
          <b class="caret" [ngClass]="{'caret-top': !menuItem.hasBadge || getStatistic(menuItem.hasBadge) === 0}"></b>
        </p>
      </a>
      <a class="nav-link center-items" [routerLink]="menuItem.path" *ngIf="!menuItem.children" [id]="menuItem.title" [id]="menuItem.title">
        <ng-icon class="ng-icon-default-size" [name]="menuItem.icon"></ng-icon>
        <p class="p-flex-center" style="width: 100%;">{{ 'menu.' + menuItem.title | translate }} <span [matBadge]="getStatistic(menuItem.hasBadge).toString()" class="nav-badge" matBadgeOverlap="false" *ngIf="menuItem.hasBadge && getStatistic(menuItem.hasBadge) > 0"></span></p>
      </a>
      <div [class]="menuItem.toggle ? 'item-show' : 'item-closed'" *ngIf="menuItem.children">
        <ul class="nav" style="margin-bottom: 0">
          <li class="nav-item" routerLinkActive="active custom" *ngFor="let menuItemChild of menuItem.children" [class]="menuItemChild.class">
            <a class="nav-link center-items" [routerLink]="menuItemChild.path" [id]="menuItemChild.title">
              <span class="sidebar-mini"><ng-icon class="ng-icon-default-size" [name]="menuItemChild.icon"></ng-icon></span>
              <span class="sidebar-normal">{{ 'menu.' + menuItemChild.title | translate }}
                <span [matBadge]="getStatistic(menuItemChild.hasBadge).toString()" style="top: 0 !important;" class="nav-badge nav-badge-child" matBadgeOverlap="false" *ngIf="menuItemChild.hasBadge && getStatistic(menuItemChild.hasBadge) > 0"></span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="isMobileMenu()">
      <a class="nav-link center-items" href="https://intercom.help/MyEmma/nl/" target="_blank">
        <ng-icon class="ng-icon-default" name="heroInformationCircle" type="solid"></ng-icon>
        <p>
          <span class="d-md-block">{{'navbar.support' | translate }}</span>
        </p>
      </a>
    </li>
    <li class="nav-item" *ngIf="isMobileMenu()">
      <a class="nav-link center-items"  routerLink="dashboard/profile">
        <ng-icon class="ng-icon-default" name="heroUserCircle" type="solid"></ng-icon>
        <p>
          <span class="d-md-block">{{ 'navbar.account' | translate }}</span>
        </p>
      </a>
    </li>
    <li data-test="logout-button-mobile" class="nav-item" *ngIf="isMobileMenu()">
      <a class="nav-link center-items" (click)="logout()">
        <ng-icon class="ng-icon-default" name="heroArrowRightOnRectangle"></ng-icon>
        <p>
          <span class="d-md-block">{{ 'navbar.logout' | translate }}</span>
        </p>
      </a>
    </li>
  </ul>
</div>
