/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { CreateMessageModel, Message } from 'app/functional/models/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private url = `${environment.rootUrl}message`;

  constructor(public http: HttpClient) { }

  create(model: CreateMessageModel): Promise<Message> {
    return this.http.post<Message>(`${this.url}`, model).toPromise();
  }

  getInbox(userId: number): Promise<Message[]> {
    return this.http.get<Message[]>(`${this.url}`, {
      params: {
        recipient_id: userId.toString(),
      }
    }).toPromise();
  }

  getMessage(id: number): Promise<Message> {
    return this.http.get<Message>(`${this.url}/${id}`).toPromise();
  }

  getSent(): Promise<Message[]> {
    return this.http.get<Message[]>(`${this.url}/sent`).toPromise();
  }

  setRead(id: number): Promise<void> {
    return this.http.put<void>(`${this.url}/set_read/${id}`, {}).toPromise();
  }

  delete(id: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${id}`).toPromise();
  }
}
