import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {managementRoutes} from './management.routing';
import { CustomersComponent } from './customers/customers.component';
import { EmployeesComponent } from './employees/employees.component';
import {GroupsModule} from './groups/groups.module';
import {UsersModule} from './users/users.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CompaniesModule} from './companies/companies.module';
import {TranslateModule} from '@ngx-translate/core';
import {customerRoutes} from './customers/customer.routing';
import {employeeRoutes} from './employees/employee.routing';
import {UserIconModule} from '../../components/user-icon/user-icon.module';
import {SubNavModule} from '../../components/sub-nav/sub-nav.module';
import {TagModule} from '../../components/tag/tag.module';
import {NgIconsModule} from '@ng-icons/core';
import {EmmaInputModule} from '../../components/base-components/emma-input/emma-input.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(managementRoutes),
        RouterModule.forChild(customerRoutes),
        RouterModule.forChild(employeeRoutes),
        GroupsModule,
        CompaniesModule,
        UsersModule,
        MatTableModule,
        MatButtonModule,
        MatRippleModule,
        MatTooltipModule,
        MatIconModule,
        BrowserAnimationsModule,
        MatSortModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(),
        UserIconModule,
        SubNavModule,
        TagModule,
        NgIconsModule,
        EmmaInputModule
    ],
    declarations: [
        CustomersComponent,
        EmployeesComponent
    ]
})
export class ManagementModule { }
