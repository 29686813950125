import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Ftp } from 'app/functional/models/ftp.model';
import { FtpService } from 'app/services/ftp/ftp.service';
import {Company} from '../../functional/models/company';
import {Subscription} from 'rxjs';
import {CompanyService} from '../../services/company/company.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-ftp-settings',
  templateUrl: './ftp-settings.component.html',
  styleUrls: ['./ftp-settings.component.scss']
})
export class FtpSettingsComponent implements OnInit, OnDestroy {
  ftpForm!: UntypedFormGroup;
  public companyId!: number;
  companySubscription?: Subscription;

  constructor(
    private ftpService: FtpService,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    public companyService: CompanyService,
    private formBuilder: UntypedFormBuilder
  ) { }

  @Input() set company(companyId: number) {
    this.companyId = companyId;
  }

  async getFtp() {
    const ftp: Ftp = await this.ftpService.readByCompany(this.companyId);
    this.ftpForm = this.formBuilder.group({
      host: [ftp?.url.split(':')[0] || '', [Validators.required]],
      port: [ftp?.url.split(':')[1] || '', [Validators.required]],
      user: [ftp?.username || '', [Validators.required]],
      pass: ['', [Validators.required]]
    });
  }

  async submitFtp() {
    this.ftpForm.markAllAsTouched();
    if (this.ftpForm.invalid){
      return;
    }

    try {
      await this.ftpService.update({
        url: this.ftpForm.get('host')?.value + ':' + this.ftpForm.get('port')?.value,
        password: this.ftpForm.get('pass')?.value,
        username: this.ftpForm.get('user')?.value,
        companyId: this.companyId,
      });
      this.snackBar.open(this.translate.instant('success'), '', {
        duration: 2000,
      });
    } catch { }
  }

  ngOnDestroy(): void {
    if (!!this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.companySubscription = this.companyService.company
      .subscribe(async (company: Company | null) => {
        if (company) {
          this.companyId = company.id;
        }
        this.getFtp();
      });
  }
}
