<table mat-table
       #firstTable
       #firstTableSort="matSort"
       class="emma-table"
       [dataSource]="signatureSource" matSort>
  <ng-container matColumnDef="docName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dashboard.signing_document' | translate}} </th>
    <td mat-cell *matCellDef="let row"> {{row.docName}} </td>
  </ng-container>

  <ng-container matColumnDef="userName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dashboard.signing_user' | translate}} </th>
    <td mat-cell *matCellDef="let row"> {{row.userName}} </td>
  </ng-container>

  <ng-container matColumnDef="createdOn">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'dashboard.signing_date' | translate}} </th>
    <td mat-cell *matCellDef="let row"> {{row.createdOn | date: 'dd/MM/yyyy'}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell  *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" (click)="menu.toggleMenu()">
      <app-emma-select [renderButton]="false" #menu>
        <mat-icon class="more-button" button>more_vert</mat-icon>
        <ng-container body>
          <span class="no-select" (click)="openSigning(row.fileId); menu.toggleMenu()">{{'signing.overview.view.file' | translate}}</span>
          <span class="no-select" *ngIf="row.dossierId != undefined" (click)="openDossier(row.dossierId); menu.toggleMenu()">{{'signing.overview.view.dossier' | translate }}</span>
          <span class="no-select" *ngIf="row.dossierId == undefined" (click)="openFolder(row.fileId); menu.toggleMenu()">{{'signing.overview.view.folder' | translate}}</span>
          <span class="no-select" *ngIf="row.signature.signDate" (click)="openSignature(row.signature); menu.toggleMenu()">{{'signing.overview.view.signature' | translate}}</span>
        </ng-container>
      </app-emma-select>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['docName', 'userName', 'createdOn', 'actions']; sticky: true"></tr>
  <tr mat-row class="element" *matRowDef="let row; columns: ['docName', 'userName', 'createdOn', 'actions'];"></tr>
</table>
