<!--
<p class="emma-font-default-bold warning-title" *ngIf="claims.role === 3 && deadline">Let op!</p>
<p class="emma-font-bold-underlined warning-subtitle" *ngIf="claims.role === 3 && deadline">U Heeft nog <span class="emma-font-red">{{daysUntil}}{{ 'dossier.days' | translate }}</span>{{ 'dossier.deliver' | translate }}</p>
-->
<div class="emma-body-white" style="padding-top: 55px;">
  <app-sub-nav
    [buttons]="activeTab === 'overview' && isUserEmployee() && !dossier?.dossierState?.finalState ? [
      {icon:'add', name: 'new' | translate, event: 'new', hidden: activeTab !== 'overview'}
    ] : []"
    [navigation]=" claims.role < 3 ? [
      {name: ('dossier.overview' | translate), event: 'overview'},
      {name: ('dossier.contact_info_customer' | translate), event: 'customers'},
      {name: ('dossier.modify' | translate), event: 'edit'},
    ] : [
      {name: ('dossier.overview' | translate), event: 'overview'}
    ]"
    (navigationChange)="activeTab = $event.event"
    (buttonClicked)="buttonClicked($event)">

    <ng-container *ngIf="dossier?.dossierState?.actions && canAdvance && activeTab === 'overview'">
      <ng-container *ngFor="let action of dossier.dossierState.actions">
        <button  style="margin-right: 15px" class="btn btn-blue btn-custom" (click)="nextStage(action)" *ngIf="isManualAction(action) && isUserAction(action)">
          <b>{{ action.name }}</b>
        </button>
      </ng-container>
    </ng-container>
  </app-sub-nav>

  <ng-container *ngIf="activeTab === 'customers'">
    <app-connected-users
      [users]="dossier.customers"
      [deleteable]="false"
      [isCustomer]="true"
      [isRedacted]="true"
      [selectable]="false">
    </app-connected-users>
  </ng-container>

  <ng-container *ngIf="activeTab === 'edit'">
    <app-create-dossier [dossierId]="dossierId" [dossier]="dossier"></app-create-dossier>
  </ng-container>

  <ng-container *ngIf="activeTab === 'overview'">
    <div style="margin-top: 38px; margin-left: 16px; margin-right: 16px; display: flex; flex-flow: column;">
      <app-dossier-progress
        style="z-index: 10"
        [progress]="progress.current"
        [dossier]="dossier"
        *ngIf="dossier && progress" id="progress-bar">
      </app-dossier-progress>

      <div class="row" *ngIf="dossier?.folders">
        <ng-container *ngFor="let folder of dossier.folders">
          <div class="col-lg-6 col-md-6" *ngIf="folder.permission?.includes('r')">
            <app-dossier-folder
              [folder]="folder"
              [isFinalFolder]="folder.isFinalFolder"
              [requestable]="!folder.isFinalFolder && !dossier?.dossierState?.finalState"
              [dossier]="dossier"
              [uploadable]="folder.permission?.includes('w') && !dossier.dossierState?.finalState"
              [fileRequests]="getFileRequests(folder.id)"
              *ngIf="folder.permission?.includes('r')"
            ></app-dossier-folder>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
