import { Component, OnInit } from '@angular/core';
import {Claims} from '../../../functional/models/user';
import {Role} from '../../../functional/models/role.model';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
// TODO promote this guy to commander settings?
export class GeneralSettingsComponent implements OnInit {
  claims!: Claims;
  roles = Role;
  showCss = false;
  showMailTemplates = false;
  showMailParameters = false;

  constructor(
    private userService: UserService,
  ) { }

  async ngOnInit() {
    this.claims = this.userService.tryClaims(); // assume logged in
  }

  toggleCss() {
    this.showCss = !this.showCss;
  }

  toggleMailTemplates() {
    this.showMailTemplates = !this.showMailTemplates;
  }

  toggleMailParameters() {
    this.showMailParameters = !this.showMailParameters;
  }
}
