import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {User} from '../../../functional/models/user';
import {UserService} from '../../../services/user/user.service';
import {DossierStateTemplate, DossierTemplate} from '../../../functional/models/dossier-template.model';
import {Dossier, DossierState} from '../../../functional/models/dossier.model';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-dossier-configuration',
  templateUrl: './dossier-configuration.component.html',
  styleUrls: ['./dossier-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DossierConfigurationComponent implements OnInit {
  @Output() dossierNameChange = new EventEmitter();
  @Output() deadlinesChange = new EventEmitter();
  @Input() recurring!: boolean;
  @Input()
  set dossierTemplate(template: DossierTemplate) {
    if (template) {
      this.localTemplate = DossierTemplate.orderStates(template);
      this.localTemplate.shift();
    }
  }

  @Input()
  set dossierStates(dossier: Dossier) {
    const orderedDossierStates = Dossier.orderStates(dossier);
    let deadlineSet = false;
    const localstates = [...orderedDossierStates];
    localstates.shift();
    this.localStates = localstates;
    for (const state of this.localStates) {
      const deadline = this.setStates.find(item => item.dossierStateTemplateId === state.id);
      if (state.deadline) {
        if (deadline) {
          deadline.deadlineDays = new Date(state.deadline);
        } else {
          this.setStates.push({dossierStateTemplateId: state.id, deadlineDays: new Date(state.deadline)});
        }
      } else {
        if (deadline) {
          deadline.deadlineDays = undefined;
        } else {
          this.setStates.push({dossierStateTemplateId: state.id, deadlineDays: undefined});
        }
      }
      if (state.deadline) {
        deadlineSet = true;
      }
      this.deadlines = this.setStates;
    }
    if (deadlineSet) {
      this.selectedDeadline = 2;
    }
  }

  @Input()
  get dossierName(): string {
    return this.nameForm.value;
  }

  set dossierName(dossierName: string) {
    this.nameForm.setValue(dossierName);
    this.dossierNameChange.emit(this.nameForm.value);
  }

  @Input()
  get deadlines(): {dossierStateTemplateId: number; deadlineDays: number | Date | undefined}[] {
    return this.setStates;
  }

  set deadlines(deadline: {dossierStateTemplateId: number; deadlineDays: number | Date | undefined}[]) {
    this.setStates = deadline;
    this.deadlinesChange.emit(this.setStates);
  }

  selectedDeadline = 1;
  thisUser!: User;
  localTemplate: DossierStateTemplate[] = [];
  localStates: DossierState[] = [];
  setStates: {dossierStateTemplateId: number; deadlineDays: number | Date | undefined}[] = [];
  nameForm = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(255),
    Validators.pattern('([A-Za-z0-9 _\\-+.\',()&€£¢$À-ÖØ-ßà-öø-ÿŠŒŽšœžŸµ{}]+)')
  ]);

  constructor(private userService: UserService) {
  }

  isInvalid(){
    this.nameForm.markAllAsTouched();
    return this.nameForm.invalid;
  }

  async ngOnInit() {
    this.thisUser = await this.userService.getUser();
  }

  getDeadlineFromState(stateId: number) {
    return this.setStates.find(item => item.dossierStateTemplateId === stateId);
  }

  setState(event: any, id: number) {
    if (!event || isNaN(event)){
      const deadline = this.setStates.find(item => item.dossierStateTemplateId === id);
      if (deadline) {
        deadline.deadlineDays = undefined;
      }
    } else {
      const deadline = this.setStates.find(item => item.dossierStateTemplateId === id);
      if (deadline) {
        deadline.deadlineDays = event;
      } else {
        this.setStates.push({dossierStateTemplateId: id, deadlineDays: event});
      }
    }
    this.deadlines = this.setStates;
  }

  isNotANumber(num: any) {
    return isNaN(num);
  }
}
