import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RedactedUser, User} from '../../../functional/models/user';
import {UserService} from '../../../services/user/user.service';
import {CompanyService} from '../../../services/company/company.service';
import {Company} from '../../../functional/models/company';
import {EmmaSelectOption} from '../../base-components/emma-select/emma-select.component';

@Component({
  selector: 'app-search-employees',
  templateUrl: './search-employees.component.html',
  styleUrls: ['./search-employees.component.scss']
})
export class SearchEmployeesComponent implements OnInit {
  @Input() preSet?: (User | RedactedUser)[];
  @Input() company?: Company;
  @Output() valueChange: EventEmitter<EmmaSelectOption | undefined> = new EventEmitter<EmmaSelectOption | undefined>();
  @Input() set value(item: EmmaSelectOption | undefined) {
    this.lValue = item;
    this.valueChange.emit(this.lValue);
  };

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  options: EmmaSelectOption[] = [];
  lValue?: EmmaSelectOption;
  users: (User | RedactedUser)[] = [];

  constructor(
    public userService: UserService,
    public companyService: CompanyService
  ) {
  }

  async ngOnInit() {
    if (!this.preSet) {
      if (this.company) {
        this.users = [...(await this.userService.getEmployees()), ...(await this.companyService.getAdmins(this.company!.id))];
      } else {
        this.users = await this.userService.getEmployees();
      }
    } else {
      this.users = this.preSet;
    }

    this.options = this.users.map((user: User | RedactedUser) => ({
      displayName: user.firstName + ' ' + user.lastName,
      value: user,
      searchObject: user
    }));
  }

  clearSelected() {
    this.lValue = undefined;
  }
}
