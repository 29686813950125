<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="add-dossier-template-request-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 extra-margin-top flex-between">
      <p class="emma-subtitle"> {{ 'dossier_template.dialog.add_request.title' | translate }} </p>
      <mat-icon style="cursor: pointer" (click)="newFilerequest()">add_circle_outline</mat-icon>
    </div>
  </div>
  <form ngForm [formGroup]="addRequestForm">
    <div class="row" *ngFor="let item of t.controls let i = index" [formGroup]="item">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-5" id="name">
            <div class="emma-input-container">
              <label label for="filename" class="emma-input-label">{{ 'dossier_template.dialog.add_request.filename' | translate }}</label>
              <input appEmmaInput id="filename" formControlName="filename">
            </div>
          </div>
          <div class="col-md-6" id="last-name">
            <div class="emma-input-container">
              <label label for="description" class="emma-input-label">{{ 'dossier_template.dialog.add_request.description' | translate }}</label>
              <input appEmmaInput id="description" formControlName="description">
            </div>
          </div>
          <div class="col-md-1 trash">
            <mat-icon (click)="deleteRequest(i)" id="delete-request" class="trash-icon">delete</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button data-test="delete-submit-button"  class="btn btn-blue btn-custom" (click)="addRequests()">
        <b> {{ 'dossier_template.dialog.add_request.submit' | translate }} </b>
      </button>
    </div>
  </div>
</div>
