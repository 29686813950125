import { Component, OnInit, Input } from '@angular/core';
import { Upload } from 'app/functional/models/upload';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Input() upload!: Upload;

  constructor() { }

  ngOnInit() {
    if (!this.upload) {
      throw new Error('`upload` is required for `UploadComponent`');
    }
  }

  getExtension() {
    const fileDataArr = this.upload.name.split('.');
    const extI = fileDataArr.length - 1;
    return fileDataArr[extI];
  }

  getIcon() {
    switch (this.getExtension()) {
      case 'jpg': return 'photo';
      case 'jpeg': return 'photo';
      case 'png': return 'photo';
      case 'mp4': return 'local_movies';
      case 'mov': return 'local_movies';
      default: return 'insert_drive_file';
    }
  }
}
