<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="add-dossier-template-folder-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'dossier_template.dialog.add_folder.title' | translate }} </p>
    </div>
  </div>
  <form ngForm [formGroup]="addFolderForm">
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <div class="emma-input-container">
          <label label for="name" class="emma-input-label">{{ 'dossier_template.dialog.add_folder.name' | translate }}</label>
          <input appEmmaInput id="name" formControlName="name">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered extra-margin">
        <div class="emma-input-container">
          <label label for="description" class="emma-input-label">{{ 'dossier_template.dialog.add_folder.description' | translate }}</label>
          <input appEmmaInput id="description" formControlName="description">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered extra-margin reverse">
        <mat-label>{{ 'dossier_template.dialog.add_folder.signable' | translate }}</mat-label>
        <mat-checkbox formControlName="signable" class="happy_checkbox"></mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 flex-between extra-margin">
        <p class="emma-subtitle"> {{ 'dossier_template.dialog.add_request.title' | translate }} </p>
        <mat-icon style="cursor: pointer" (click)="newFilerequest()">add_circle_outline</mat-icon>
      </div>
    </div>
    <div class="row" *ngFor="let item of t.controls let i = index" [formGroup]="item">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-5">
            <div class="emma-input-container">
              <label label for="filename" class="emma-input-label">{{ 'dossier_template.dialog.add_request.filename' | translate }}</label>
              <input appEmmaInput id="filename" formControlName="filename">
            </div>
          </div>
          <div class="col-md-6">
            <div class="emma-input-container">
              <label label for="fdescription" class="emma-input-label">{{ 'dossier_template.dialog.add_request.filename' | translate }}</label>
              <input appEmmaInput id="fdescription" formControlName="description">
            </div>
          </div>
          <div class="col-md-1 trash">
            <mat-icon (click)="deleteRequest(i)" id="delete-request" class="trash-icon">delete</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button data-test="delete-submit-button"  class="btn btn-blue btn-custom" (click)="addFolder()">
        <b> {{ 'dossier_template.dialog.add_folder.submit' | translate }} </b>
      </button>
    </div>
  </div>
</div>
