import {RedactedUser, User} from './user';
import { Document } from './document';

export class Signing {
  id!: number;
  userId!: number;
  user!: RedactedUser;
  signType!: number;
  fileId!: number;
  file!: Document;
  message!: string;
  dossierId?: number;
  creationDate?: string;
  rejectionDate?: string;
  rejectionMessage?: string;
  signDate?: string;
  requesterId?: number;
  requester?: RedactedUser;
  displayUrl?: string;

  static default(): Signing {
    return {
      id: 1,
      userId: 1,
      user: User.default(),
      signType: 0,
      fileId: 1,
      file: Document.default(),
      message: 'Kun je dit tekenen',
      creationDate: '1990-12-01',
      rejectionDate: undefined,
      rejectionMessage: undefined,
      signDate: '1990-12-01',
      requesterId: 1,
      requester: User.default2(),
    };
  }
}

export enum SignType {
  signature = 0,
  approval = 1,
}

export class SigningConversationModel {
  content!: string;
  signingId!: number;
  subject!: string;
}
