import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierCustomerSelectorComponent } from './dossier-customer-selector.component';
import {SearchCustomersModule} from '../../search/search-customers/search-customers.module';
import {TranslateModule} from '@ngx-translate/core';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import {FormsModule} from '@angular/forms';
import {CreateCustomerDialogModule} from '../../../dialogs/dossiers/create-customer-dialog/create-customer-dialog.module';
import {SearchCompaniesModule} from '../../search/search-companies/search-companies.module';
import {CreateBusinessCustomerDialogModule} from '../../../dialogs/dossiers/create-business-customer-dialog/create-business-customer-dialog.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatExpansionModule} from '@angular/material/expansion';
import {SearchCompaniesAndCustomersModule} from '../../search/search-companies-and-customers/search-companies-and-customers.module';
import {SearchEmployeesModule} from '../../search/search-employees/search-employees.module';
import {UserIconModule} from '../../user-icon/user-icon.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroTrash } from '@ng-icons/heroicons/outline';

@NgModule({
  declarations: [DossierCustomerSelectorComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    SearchCustomersModule,
    CreateCustomerDialogModule,
    CreateBusinessCustomerDialogModule,
    TranslateModule.forRoot(),
    SearchCompaniesModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatExpansionModule,
    NgIconsModule.withIcons({
      heroTrash
    }),
    SearchCompaniesAndCustomersModule,
    SearchEmployeesModule,
    UserIconModule,
  ],
  exports: [
    DossierCustomerSelectorComponent
  ]
})
export class DossierCustomerSelectorModule { }
