import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSelectorDialogComponent } from './user-selector-dialog.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {SearchCustomersModule} from '../../components/search/search-customers/search-customers.module';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {SearchCustomersAndEmployeesModule}
  from '../../components/search/search-customers-and-employees/search-customers-and-employees.module';
import {SearchEmployeesModule} from '../../components/search/search-employees/search-employees.module';
import {EmmaInputModule} from '../../components/base-components/emma-input/emma-input.module';
import {EmmaSelectModule} from '../../components/base-components/emma-select/emma-select.module';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  declarations: [
    UserSelectorDialogComponent
  ],
  imports: [
    CommonModule,
    NgIconsModule,
    MatButtonModule,
    SearchCustomersModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    SearchCustomersAndEmployeesModule,
    SearchEmployeesModule,
    EmmaInputModule,
    EmmaSelectModule
  ]
})
export class UserSelectorDialogModule { }
