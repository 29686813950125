<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div [ngClass]="claims!.role < 3 ? 'reply-deny-document-dialog' : 'reply-deny-document-dialog-small'">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{ 'dialog.request.reason_reject' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 align-text-center">
      <p class="emma-font-light">{{signature.rejectionMessage}}</p>
      <p><b class="emma-subtitle1">{{signature.rejectionDate | date: 'dd/MM/yyyy'}} - {{signature.user.firstName + " " +signature.user.lastName}}</b></p>
    </div>
  </div>
  <div class="row" *ngIf="claims!.role < 3">
    <div class="col-md-12">
      <mat-radio-group class="spacing" [(ngModel)]="reaction" aria-label="Select an option">
        <mat-radio-button value="1">{{ 'dialog.request.send_reaction' | translate }}</mat-radio-button>
        <mat-radio-button value="0">{{ 'dialog.request.send_no_reaction' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="row" *ngIf="claims!.role < 3 && reaction === '1'">
    <form [formGroup]="reactionForm" *ngIf="reactionForm">
      <div class="col-md-12 centered">
          <div class="emma-input-container reason-area">
            <label label for="reply" class="emma-input-label">{{'dialog.request.type_reaction' | translate }}</label>
            <textarea appEmmaInput class="reason-area-content" id="reply" formControlName="reply"></textarea>
          </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <button  class="btn btn-blue btn-custom " (click)="close()" *ngIf="claims!.role === 3 || reaction === '0'">
        <b>{{ 'dialog.request.continue' | translate }}</b>

      </button>
      <button  class="btn btn-blue btn-custom " (click)="submit()" *ngIf="claims!.role < 3 && reaction === '1'">
        <b>{{ 'dialog.request.send' | translate }}</b>

      </button>
    </div>
  </div>
</div>
