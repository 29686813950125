import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent {
  @Input() short?: string;
  @Input() border = false;
  @Input() badge!: 'checked' | 'denied' | 'signing' | '';
  @Input() large = false;
  @Input() set firstName(firstName: string){
    this.firstLetter = firstName.charAt(0);
  };

  @Input() set lastName(lastName: string){
    this.lastLetter = lastName.charAt(0);
  };

  firstLetter?: string;
  lastLetter?: string;

  constructor() { }
}
