import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyPageComponent } from './survey-page/survey-page.component';
import { SurveyQuestionComponent } from './survey-question/survey-question.component';
import { SurveyDivisionComponent } from './survey-division/survey-division.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';



@NgModule({
  declarations: [SurveyPageComponent, SurveyQuestionComponent, SurveyDivisionComponent],
  exports: [
    SurveyPageComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSlideToggleModule
  ]
})
export class SurveyBuilderModule { }
