import {Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2} from '@angular/core';

@Directive({
  selector: '[appEmmaTooltip]'
})
export class EmmaTooltipDirective implements OnDestroy {
  @Input() appEmmaTooltip = '';
  tooltip?: HTMLElement;
  offset = 10;
  initialized = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('mouseover')
  onMouseOver() {
    if (this.appEmmaTooltip) {
      this.show();
    }
  }

  @HostListener('click')
  @HostListener('mouseleave')
  @HostListener('mouseout')
  onMouseOut() {
    this.hide();
  }

  ngOnDestroy() {
    this.hide();
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    if (this.tooltip) {
      this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = undefined;
    }
  }

  create() {
    this.tooltip = this.renderer.createElement('span');

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.appEmmaTooltip) // textNode
    );

    this.renderer.appendChild(document.body, this.tooltip);
    //this.renderer.appendChild(this.el.nativeElement, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-bottom`);
  }

  isScaled() {
    return window.innerWidth <= 1900;
  }

  setPosition() {
    const hostPos = (this.el as any).nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltip!.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top = hostPos.bottom + this.offset;
    let left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;

    if (this.isScaled()) {
      top = ((hostPos.bottom / 100 * 23) + hostPos.bottom) + this.offset;
      left = (((hostPos.left / 100 * 25) + hostPos.left) + (hostPos.width - tooltipPos.width) / 2) - 3;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
