import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Company, CreateCompanyForm, EditCompanyForm, Meta } from '../../functional/models/company';
import { Folder } from '../../functional/models/folder';
import {RedactedUser, User} from '../../functional/models/user';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  company = new BehaviorSubject<Company | null>(null);
  private companyPending = false;
  private url = `${environment.rootUrl}company`;

  constructor(public http: HttpClient, public router: Router) { }

  public getCompanies(): Promise<Company[]> {
    return this.http.get<Company[]>(this.url).toPromise();
  }

  uploadLogo(companyId: number, image: File): Promise<void> {
    return this.http.put<void>(`${this.url}/${companyId}/image`, image, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': image.type
      }
    }).toPromise();
  }

  imageUrl(company: Company): string {
    if (company.hasImage) {
      return 'https://storage.googleapis.com/myemma_logos/' + company.id + '.png';
    } else {
      return '/assets/img/icoon-myemma.png';
    }
  }

  async refreshCompany(): Promise<void> {
    const curCompany = await this.findCurrentCompany();
    const refreshed = await this.readById(curCompany.id);
    this.company.next(refreshed);
  }

  async findCurrentCompany(): Promise<Company> {
    // get the current company from the behavioursubject
    let value = this.company.getValue();

    // hang this function while companyPending is true.
    while (this.companyPending) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    value = this.company.getValue();
    if (value) {
      return value;
    }

    // set company pending to true.
    this.companyPending = true;
    try {
      // use a try-finally block to query the company, because if this http call throws an exception
      // the function is exited with companyPending still true. This would mean that any future
      // calls to this function would hang indefinitely.
      const company = await this.http.get<Company>(`${this.url}`, {
        params: {
          url: document.location.hostname
        }
      }).toPromise();
      this.company.next(company);
      return company;
    } finally {
      this.companyPending = false;
    }
  }

  getCompanyByUrl(): Promise<Company> {
    return this.http.get<Company>(`${this.url}`, {params: {url: document.location.hostname}}).toPromise();
  }

  readById(companyId: number): Promise<Company> {
    return this.http.get<Company>(`${this.url}/${companyId}`).toPromise();
  }

  readAll(): Promise<Company[]> {
    return this.http.get<Company[]>(this.url).toPromise();
  }

  createCompany(model: CreateCompanyForm): Promise<Company> {
    return this.http.post<Company>(this.url, model).toPromise();
  }

  editCompany(companyId: number, model: EditCompanyForm): Promise<Company> {
    return this.http.put<Company>(`${this.url}/${companyId}`, model).toPromise();
  }

  getRoot(companyId: number): Promise<Folder> {
    return this.http.get<Folder>(`${this.url}/${companyId}/get_root`).toPromise();
  }

  getMeta(companyId: number): Promise<Meta> {
    return this.http.get<Meta>(`${this.url}/${companyId}/metadata`).toPromise();
  }

  getUsers(companyId: number): Promise<User[]> {
    return this.http.get<User[]>(`${this.url}/${companyId}/users`).toPromise();
  }

  getEmployees(companyId: number): Promise<User[]> {
    return this.http.get<User[]>(`${this.url}/${companyId}/employees`).toPromise();
  }

  getCustomers(companyId: number): Promise<User[]> {
    return this.http.get<User[]>(`${this.url}/${companyId}/customers`).toPromise();
  }

  getAdmins(companyId: number): Promise<RedactedUser[]> {
    return this.http.get<RedactedUser[]>(`${this.url}/${companyId}/admins`).toPromise();
  }

  deleteCompany(companyId: number) {
    return this.http.delete(`${this.url}/${companyId}`).toPromise();
  }

  async existsCompanyUrl(domain: string): Promise<boolean> {
    try {
      await this.http.get(`${environment.rootUrl}company?url=${domain}`).toPromise();
      return true;
    } catch (e) {
      return false;
    }
  }
}
