import { Component, OnInit } from '@angular/core';
import { MailParameterService } from 'app/services/mail-parameter/mail-parameter.service';
import { MailParameter } from 'app/functional/models/mail-parameter.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-mail-parameters',
  templateUrl: './mail-parameters.component.html',
  styleUrls: ['./mail-parameters.component.scss']
})
export class MailParametersComponent implements OnInit {
  params?: MailParameter[];
  form!: UntypedFormGroup;
  paramForms: UntypedFormGroup[] = [];

  constructor(
    private mailParameterService: MailParameterService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      value: ['', [Validators.required]],
      language: ['', [Validators.required]],
      version: ['', [Validators.required]],
    });
    this.params = await this.mailParameterService.default();
    this.params.sort((a, b) => a.id - b.id);
    this.form.patchValue({ version: Math.max(1, ...this.params.map(p => p.version ))});
    for (const param of this.params) {
      this.paramForms.push(this.formBuilder.group({
        id: [param.id, []],
        name: [param.name, [Validators.required]],
        value: [param.value, [Validators.required]],
        language: [param.language, [Validators.required]],
        version: [param.version, [Validators.required]],
      }));
    }
  }

  async create(): Promise<void> {
    if (this.form.invalid) {
      this.snackBar.open(
        'Je formulier zuigt.',
        'sluiten',
        { duration: 5000 },
      );
      return;
    }
    const param = [{
      name: this.form.get('name')!.value,
      value: this.form.get('value')!.value,
      language: this.form.get('language')!.value,
      version: +this.form.get('version')!.value,
    }];
    const newParams = await this.mailParameterService.create(param);
    this.params = [...this.params || [], ...newParams];
    this.form.reset();
    this.form.patchValue({ version: Math.max(1, ...this.params.map(p => p.version ))});
    for (const newParam of newParams) {
      this.paramForms.push(this.formBuilder.group({
        id: [newParam.id, []],
        name: [newParam.name, [Validators.required]],
        value: [newParam.value, [Validators.required]],
        language: [newParam.language, [Validators.required]],
        companyId: [newParam.companyId, [Validators.required]],
        version: [newParam.version, [Validators.required]],
      }));
    }
  }

  async updateParam(form: UntypedFormGroup): Promise<void> {
    const id = +form.get('id')!.value;
    const param = {
      name: form.get('name')!.value,
      value: form.get('value')!.value,
      language: form.get('language')!.value,
      version: +form.get('version')!.value,
    };
    await this.mailParameterService.update(id, param);
    this.snackBar.open(
      'Aangepast!',
      'Sluiten',
      { duration: 5000 },
    );
  }
}
