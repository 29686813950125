/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { CssParameter, NewCssParameter } from 'app/functional/models/css-parameter.model';

@Injectable({
  providedIn: 'root'
})
export class CssParameterService {
  private url = environment.rootUrl + 'css_parameter';

  constructor(private http: HttpClient) {}

  create(newCssParameter: NewCssParameter): Promise<CssParameter> {
    return this.http.post<CssParameter>(this.url, newCssParameter).toPromise();
  }

  bulkCreate(newCssParameters: NewCssParameter[]): Promise<CssParameter[]> {
    return this.http.post<CssParameter[]>(this.url, newCssParameters).toPromise();
  }

  readByCompany(companyId: number): Promise<CssParameter[]> {
    return this.http.get<CssParameter[]>(this.url, {
      params: {
        company_id: companyId.toString(),
      }
    }).toPromise();
  }

  update(cssParameter: CssParameter): Promise<CssParameter> {
    return this.http.put<CssParameter>(this.url + `/${cssParameter.id}`, cssParameter).toPromise();
  }

  bulkUpdate(cssParameters: CssParameter[]): Promise<CssParameter[]> {
    return this.http.put<CssParameter[]>(this.url, cssParameters).toPromise();
  }

  delete(id: number): Promise<void> {
    return this.http.delete<void>(this.url + `/${id}`).toPromise();
  }

  deleteByCompany(companyId: number): Promise<void> {
    return this.http.delete<void>(this.url, {
      params: {
        company_id: companyId.toString(),
      }
    }).toPromise();
  }
}
