<nav class="navbar navbar-expand-lg navbar-absolute emma-navbar emma-navbar-sub space-between" [ngClass]="{'exchange-adjustment': exchangeSubject, 'navbar-default': !exchangeSubject}">
  <div mat-tab-nav-bar *ngIf="navBar.length > 0" style="height: 100%">
    <ng-container *ngFor="let menuItem of navBar">
      <a class="nav-subtab-button" mat-tab-link *ngIf="!menuItem.hidden" [routerLink]="[]" [fragment]="menuItem.event" (click)="changeTab(menuItem)" [active]="activeLink?.event === menuItem.event">{{menuItem.name}}</a>
    </ng-container>
  </div>

  <div [class]="{
  'flex-end': navBar.length <= 0 && !search && !back,
  'space-between': navBar.length <= 0,
  'space-even': navBar.length > 0
  }">
    <div class="rounded-input" style="width: 500px; height: 40px" *ngIf="search">
      <input #searchInput (keyup)="searchChange.emit($event.target.value)" placeholder="{{'searchterm' | translate}}">
      <div class="rounded-input-icon" (click)="searchChange.emit(searchInput.value)">
        <mat-icon>search</mat-icon>
      </div>
    </div>

    <div style="display: flex" *ngIf="back">
      <button class="btn btn-outline-blue" (click)="buttonClicked.emit('back')">
        <ng-icon class="ng-icon-default-size" style="margin-right: 10px" name="heroArrowLeft"></ng-icon>
        {{'viewer.back' | translate}}
      </button>
    </div>

    <div style="display: flex">
      <ng-content></ng-content>

      <ng-container *ngFor="let button of buttons">
        <app-emma-select [renderButton]="false" #select style="margin-left: 20px" *ngIf="button.menu && !button.hidden">
          <button button
            class="btn {{button.class ? button.class : 'btn-blue btn-custom' }}" (click)="select.toggleMenu()">
            <mat-icon *ngIf="button.icon" class="btn-icon">{{button.icon}}</mat-icon>
            {{button.name}}
          </button>
          <ng-container body (click)="select.toggleMenu()">
            <ng-content select="[select]"></ng-content>
          </ng-container>
        </app-emma-select>

        <button class="btn {{button.class ? button.class : 'btn-blue btn-custom' }}" (click)="buttonClicked.emit(button.event)" *ngIf="!button.menu && !button.hidden">
          <mat-icon *ngIf="button.icon" class="btn-icon">{{button.icon}}</mat-icon>
          {{button.name}}
        </button>
      </ng-container>
    </div>
  </div>
</nav>
