import { NgModule } from '@angular/core';
import {DisplaySignaturesDialogComponent} from './display-signatures-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forRoot(),
    NgIconsModule,
  ],
  declarations: [
    DisplaySignaturesDialogComponent,
  ]
})
export class DisplaySignaturesDialogModule { }
