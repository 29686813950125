import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-domain-dialog',
  templateUrl: './add-domain-dialog.component.html',
  styleUrls: ['./add-domain-dialog.component.scss']
})
export class AddDomainDialogComponent {

  nameForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddDomainDialogComponent>
  ) {
    this.nameForm = this.formBuilder.group({
      name: ['',[Validators.required]]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.nameForm.markAllAsTouched();
    if (this.nameForm.invalid){
      return;
    }
    this.dialogRef.close(this.nameForm.value);
  }
}
