<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    [search]="true"
    (searchChange)="applyFilter($event)"
    [buttons]="[{icon:'add', name: 'new' | translate, event: 'new'}]"
    (buttonClicked)="handleClick($event)">
  </app-sub-nav>
  <div class="table-paginator-container">
    <div class="mat-table-container">
      <table mat-table [dataSource]="dataSource" matSort class="emma-table">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'groups.list.name' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'groups.list.description' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.description}} </td>
        </ng-container>

        <tr class="empty-row" *matNoDataRow>
          <td colspan="9999">
            <div class="illustration">
              <img src="assets/img/illustrations/community.svg">
              <h1 class="not-found-title">{{'groups.list.no_groups' | translate}}</h1>
              <span>
                {{'groups.list.no_groups_text1' | translate}}
                {{'groups.list.no_groups_text2' | translate}}
              </span>
            </div>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="['name', 'description']; sticky: true" ></tr>
        <tr mat-row class="element" *matRowDef="let row; columns: ['name', 'description'];" (click)="redirect(row.id)"></tr>
      </table>
    </div>
    <div>
      <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>
