import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ExchangeOverviewComponent} from './exchange-overview.component';
import {ExchangeDetailsModule} from '../exchange-details/exchange-details.module';
import {UploadModule} from '../upload/upload.module';
import {DocumentModule} from '../document/document.module';
import {FileRequestModule} from '../file-request/file-request.module';
import {FolderModule} from '../folder/folder.module';
import {NgxFileDropModule} from 'ngx-file-drop';
import {FileTableModule} from '../../file-table/file-table.module';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {EmmaTooltipModule} from '../../base-components/emma-tooltip/emma-tooltip.module';

@NgModule({
  declarations: [ExchangeOverviewComponent],
  exports: [
    ExchangeOverviewComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    ExchangeDetailsModule,
    UploadModule,
    DocumentModule,
    FileRequestModule,
    FolderModule,
    NgxFileDropModule,
    FileTableModule,
    MatIconModule,
    MatTooltipModule,
    EmmaTooltipModule,
  ]
})
export class ExchangeOverviewModule { }
