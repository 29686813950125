import {Component, OnDestroy, OnInit} from '@angular/core';
import {CreateUserForm, Gender, User} from '../../../functional/models/user';
import {Role} from '../../../functional/models/role.model';
import {NewOrganization, Organization} from '../../../functional/models/organization.model';
import {Company} from '../../../functional/models/company';
import {Subscription} from 'rxjs';
import {CompanyService} from '../../../services/company/company.service';
import {OrganizationService} from '../../../services/organization/organization.service';
import {UserService} from '../../../services/user/user.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-csv-import',
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.scss']
})
export class CsvImportComponent implements OnInit, OnDestroy {
  users: [CreateUserForm, string][] = [];
  organizations: NewOrganization[] = [];
  duplicateUsers: [CreateUserForm, string][] = [];
  hasNewUsers = 0;

  private companySubscription?: Subscription;
  private company!: Company;
  private currentOrganizations!: Organization[];
  private currentUsers!: User[];

  constructor(
    private companyService: CompanyService,
    private organizationService: OrganizationService,
    private userService: UserService,
    private datePipe: DatePipe
  ) { }

  ngOnDestroy(): void {
    if (this.companySubscription){
      this.companySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }

      await this.loadCurrentOrganizations();
      await this.loadCurrentUsers();
    });
  }

  async loadCurrentUsers() {
    this.currentUsers = await this.userService.getByCompany(this.company.id);
  }

  async loadCurrentOrganizations() {
    this.currentOrganizations = await this.organizationService.filter({companyId: this.company.id});
  }

  async importUsers(event: Event) {
    this.users = [];
    const files = (event.target as HTMLInputElement).files!;
    if (files.length > 0) {
      const file = files[0];
      const result = await this.fileToArray(file);
      this.hasNewUsers = result.length;
      for (const strUser of result) {

        if (this.currentUsers.some(usr => usr.email.toLowerCase() === strUser[1].toLowerCase())){
          this.hasNewUsers--;
        }

        const user: [CreateUserForm, string] = [{
          relationId: strUser[0],
          email: strUser[1],
          firstName: strUser[2],
          lastName: strUser[3],
          role: Role.customer,
          gender: Gender.unspecified,
          postalCode: strUser[5],
          street: strUser[6],
          houseNumber: strUser[7],
          premise: strUser[8],
          city: strUser[9],
          phone: strUser[10],
          birth: strUser[11] ? this.datePipe.transform(this.reverseDate(strUser[11]), 'yyyy-MM-dd') : undefined,
          description: strUser[12],
          companyId: this.company.id
        }, strUser[13]];

        if (this.users.some(usr => usr[0].email.toLowerCase() === strUser[1].toLowerCase())) {
          this.duplicateUsers.push(user);
        }

        this.users.push(user);
      }
    }
  }

  async importOrganizations(event: Event) {
    this.organizations = [];
    const files = (event.target as HTMLInputElement).files!;
    if (files.length > 0) {
      const file = files[0];
      const result = await this.fileToArray(file);

      for (const strOrganization of result) {
        this.organizations.push({
          relationId: strOrganization[0],
          name: strOrganization[1],
          kvk: strOrganization[2],
          fiscalNumber: strOrganization[3],
          postalCode: strOrganization[4],
          street: strOrganization[5],
          houseNumber: strOrganization[6],
          premise: strOrganization[7],
          city: strOrganization[8],
          companyId: this.company.id
        });
      }
    }
  }

  getUserOrganization(organizationId: string) {
    return this.organizations.find(org => org.relationId === organizationId);
  }

  async submitLists() {
    const organizationPromiseList = [];
    const userPromiseList = [];
    const userOrganizationLinkList = [];

    for (const organization of this.organizations) {
      if (this.currentOrganizations.some(org => org.name.toLowerCase() === organization.name.toLowerCase())) {
        continue;
      }
      organizationPromiseList.push(this.organizationService.create(organization));
    }
    const organizations = [...await Promise.all(organizationPromiseList), ...this.currentOrganizations];

    for (const user of this.users){
      if (this.currentUsers.some(usr => usr.email.toLowerCase() === user[0].email.toLowerCase())) {
        continue;
      }
      userPromiseList.push(this.userService.createUser(user[0], false));
    }
    const users = [...await Promise.all(userPromiseList), ...this.currentUsers];

    for (const user of this.users) {
      if (user[1]) {
        const linkedOrg = organizations.find(org => org.relationId === user[1]);
        const linkedUser = users.find(usr => usr.email.toLowerCase() === user[0].email.toLowerCase());
        if (linkedOrg && linkedUser) {
          userOrganizationLinkList.push(this.organizationService.addUser(linkedOrg.id, linkedUser.id));
        }
      }
    }
    await Promise.all(userOrganizationLinkList);
  }

  fileToArray(file: File): Promise<any[]> {
    const reader = new FileReader();
    const result: any[] = [];
    reader.readAsText(file);
    return new Promise((resolve)=> {
      reader.onload = (e) => {

        const csvString = (e.target!.result! as string).replace(/[\r]/g, '');
        const rows = csvString.split('\n');

        for (const row of rows) {
          if (!row.includes(';')) {
            continue;
          }
          result.push(row.split(';'));
        }
        resolve(result);
      };
    });
  }

  reverseDate(from: string) {
    return from.split('/').reverse().join('/');
  }
}
