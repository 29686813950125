import {Component, Input, OnInit} from '@angular/core';

export enum TagColor {
  red = 'red',
  green = 'green',
  blue = 'blue',
  indigo = 'indigo',
  purple = 'purple',
  yellow = 'yellow',
  pink = 'pink'
}

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  @Input() color!: TagColor;

  constructor() { }

  ngOnInit(): void {}

}
