import { ParentFolder } from './folder';
import { Signing } from './signing';

export class Document {
  id!: number;
  name!: string;
  description!: string;
  parentFolder!: ParentFolder;
  companyId!: number;
  size!: number;
  extension!: string;
  creationDate!: string;
  userId?: number;
  labels?: number[];
  permission?: string;
  pending?: boolean; // checks whether the model is alreading being processed.
  doubleClicked?: boolean; // checks whether the model should be opened after processing.

  static getIcon(document: Document): string {
    switch (document.extension.split('.').join('')) {
      case 'jpg': return 'photo';
      case 'jpeg': return 'photo';
      case 'png': return 'photo';
      case 'mp4': return 'local_movies';
      case 'mov': return 'local_movies';
      default: return 'insert_drive_file';
    }
  }

  static getIconColor(document: Document): string {
    switch (document.extension.split('.').join('')) {
      case 'pdf': return 'pdf';

      case 'docx': return 'word';
      case 'doc': return 'word';
      case 'dot': return 'word';
      case 'dotx': return 'word';
      case 'docb': return 'word';
      case 'docm': return 'word';
      case 'dotm': return 'word';

      case 'exe': return 'exe';

      case 'xls': return 'excel';
      case 'xlt': return 'excel';
      case 'xlm': return 'excel';
      case 'xlsx': return 'excel';
      case 'xltx': return 'excel';
      case 'xlmx': return 'excel';

      case 'pps': return 'powerpoint';
      case 'pptx': return 'powerpoint';

      case 'pub': return 'publisher';
      default: return 'primary file-custom';
    }
  }

  static default(): Document {
    return {
      id: 1,
      name: 'Bestand 2.pdf',
      description: 'Dit is een super nice bestand',
      parentFolder: ParentFolder.default(),
      companyId: 1,
      size: 100,
      extension: '.pdf',
      creationDate: new Date().toUTCString(),
      userId: 1,
      labels: [1, 2, 3],
      permission: 'rwd',
    };
  }
}

export class DocumentSkeleton {
  id!: number;
  name!: string;
  description!: string;
  parentFolder!: ParentFolder;
  companyId!: number;
  size!: number;
  creationDate!: string;
  userId?: number;
  labels?: number[];
  permission?: string;
  pending?: boolean; // checks whether the model is alreading being processed.
  doubleClicked?: boolean; // checks whether the model should be opened after processing.
  signedUrl!: string;

  static asDocument(document: DocumentSkeleton): Document {
    return {
      id: document.id,
      name: document.name,
      description: document.description,
      extension: '',
      parentFolder: document.parentFolder,
      companyId: document.companyId,
      size: document.size,
      creationDate: document.creationDate,
      userId: document.userId,
      labels: document.labels,
      permission: document.permission,
    };
  }

  static default(): DocumentSkeleton {
    return {
      id: 1,
      name: 'Bestand 2.pdf',
      description: 'Dit is een super nice bestand',
      parentFolder: ParentFolder.default(),
      companyId: 1,
      size: 100,
      creationDate: new Date().toUTCString(),
      userId: 1,
      labels: [1, 2, 3],
      permission: 'rwd',
      signedUrl: '',
    } as DocumentSkeleton;
  }
}

export class MessageDocumentModel {
  id?: number;
  companyId?: number;
  labels?: any;
  document!: string;
  name!: string;
}

export interface CreateDocumentModel {
  parentFolder: number;
  name: string;
  description: string;
  documentSize: number;
}

export interface RequestUserSignatureModel {
  fileId: number;
  userId: number;
  message: string;
  signingType: number;
  dossierId?: number;
}

export interface RequestGroupSignatureModel {
  fileId: number;
  groupId: number;
  message: string;
  signingType: number;
}

export interface SignModel {
  signature?: string;
}

export interface RejectModel {
  message: string;
}

export interface UpdateDocumentModel {
  name: string;
  description: string;
  parentFolder: number;
}

export class DocumentFilterModel {
  companyId?: number;
  end?: number;
  start?: string;
  label?: string;
  parentFolderId?: number;
  userId?: number;
  parentUserId?: number;
}

export class DossierDocument extends Document {
  signings?: Signing[];
}
