import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {SignDocumentDialogComponent} from './sign-document-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {SignaturepadModule} from '../../../components/signaturepad/signaturepad.module';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    MatIconModule,
    SignaturepadModule,
    MatDialogModule,
    MatButtonModule,
    NgIconsModule,
  ],
  declarations: [
    SignDocumentDialogComponent,
  ]
})
export class SignDocumentDialogModule { }
