import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Document} from '../../functional/models/document';
import {Signing, SignType} from '../../functional/models/signing';
import {DisplaySignaturesDialogComponent} from '../../dialogs/display-signatures-dialog/display-signatures-dialog.component';
import {DocumentService} from '../../services/document/document.service';
import {Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TagColor} from '../tag/tag.component';
import {SigningService} from '../../services/signing/signing.service';
import {FileRequestService} from '../../services/file-request/file-request.service';
import {FileRequest} from '../../functional/models/file-request.model';
import {RedactedUser, User} from '../../functional/models/user';
import {Company} from '../../functional/models/company';
import {MatSort} from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {Role} from '../../functional/models/role.model';
import {UserService} from '../../services/user/user.service';
import {UtilsService} from '../../services/utils/utils.service';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-user-recents',
  templateUrl: './user-recents.component.html',
  styleUrls: ['./user-recents.component.scss']
})
export class UserRecentsComponent implements OnInit {
  @Input() user!: User;
  @Input() company!: Company;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() set view(view: number){
    if (this.lView !== view){
      this.lView = view;
      this.setView(this.view);
    }
  }

  get view(): number {
    return this.lView;
  }


  lView = 1;
  filterSettings = {
    signings: true,
    requests: true,
    approvals : true,
    uploads: true,

    rejected: true,
    accepted: true,
    signed: true,
    pending: true,
    uploaded: true,
  };

  lData: UserRecentTableContent[] = [];
  filteredData: UserRecentTableContent[] = [];
  mixedView = new MatTableDataSource<UserRecentTableContent>([]);

  colors = TagColor;
  signings: Signing[] = [];
  requests: FileRequest[] = [];
  recent: Document[] = [];
  columns = ['docName', 'creationDate', 'type', 'status', 'actions'];


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private signingService: SigningService,
    private fileRequestService: FileRequestService,
    private documentService: DocumentService,
    private userService: UserService,
    private utils: UtilsService
  ) { }

  async ngOnInit() {
    const settings = localStorage.getItem('recents-filter');
    if (settings) {
      this.filterSettings = JSON.parse(settings);
    }
    await this.setView(this.view);

    this.applyFilter();
  }

  applyFilter() {
    localStorage.setItem('recents-filter', JSON.stringify(this.filterSettings));

    this.filteredData = this.lData.filter(item =>
      (item.type === 'request' && this.filterSettings.requests) ||
      (item.type === 'accept' && this.filterSettings.approvals) ||
      (item.type === 'signing' && this.filterSettings.signings) ||
      (item.type === 'upload' && this.filterSettings.uploads)
    );

    this.filteredData = this.filteredData.filter(item =>
      (item.status === 'signed' && this.filterSettings.signed) ||
      (item.status === 'rejected' && this.filterSettings.rejected) ||
      (item.status === 'accepted' && this.filterSettings.accepted) ||
      (item.status === 'uploaded' && this.filterSettings.uploaded) ||
      (item.status === 'pending' && this.filterSettings.pending)
    );

    this.mixedView = new MatTableDataSource(this.filteredData);
    this.mixedView.sort = this.sort;
    this.mixedView.paginator = this.paginator;
  }

  async setView(view: number) {
    const startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const claims = this.userService.tryClaims();
    const data: UserRecentTableContent[] = [];

    if (view === 1) {
      this.columns = ['docName', 'creationDate', 'type', 'status', 'actions'];
      this.signings = await this.signingService.getSigningsByUser(this.user.id);
      if (Number(this.user.id) !== Number(claims.userId) && claims.role === Role.employee) {
        this.requests = (await this.fileRequestService.filter({parentUserId: this.userService.tryClaims().userId}))
          .filter(item => item.isActive && item.userIds.includes(this.user.id));
      } else {
        this.requests = (await this.fileRequestService.filter({userId: this.user.id})).filter(item => item.isActive);
      }
      if (this.user.id !== claims.userId) {
        this.recent = await this.documentService.getAll({userId: this.user.id, start: this.utils.dateToDateTime(startDate)});
      } else {
        this.recent = [];
      }

      for (const signing of this.signings) {
        data.push({
          docName: signing.file.name,
          creationDate: signing.creationDate!,
          status: this.getSigningStatus(signing),
          type: signing.signType === SignType.signature ? 'signing' : 'accept',
          signature: signing,
          dossierId: signing.dossierId,
          folderId: signing.dossierId ? undefined : signing.file.parentFolder.id,
          fileId: signing.file.id
        });
      }

      for (const request of this.requests) {
        data.push({
          docName: request.filename,
          creationDate: request.creationDate,
          status: !!request.fileId ? 'uploaded' : 'pending',
          type: 'request',
          dossierId: request.dossierId,
          folderId: request.dossierId ? undefined : request.folderId
        });
      }

      for (const recent of this.recent) {
        data.push({
          docName: recent.name,
          creationDate: recent.creationDate,
          status: 'uploaded',
          type: 'upload',
          folderId: recent.parentFolder.id,
          fileId: recent.id,
        });
      }
    }

    if (view === 2 || view === 3) {
      this.columns = ['docName', 'userName', 'creationDate', 'type', 'status', 'actions'];
      let users: User[] = [];
      if (claims.role === Role.admin || claims.role === Role.superuser) {
        this.recent = await this.documentService.getAll({start: this.utils.dateToDateTime(startDate), companyId: this.company.id});
        users = await this.userService.readAll({companyId: this.company.id});
        this.signings = await this.signingService.getCompanySignings(this.company.id);
        this.requests = (await this.fileRequestService.filter({companyId: this.company.id})).filter(item => item.isActive);
      }

      if (claims.role === Role.employee) {
        this.signings = await this.signingService.getChildUserSignings(claims.userId);
        this.recent = await this.documentService.getAll({start: this.utils.dateToDateTime(startDate), parentUserId: claims.userId});
        this.requests = (await this.fileRequestService.filter({parentUserId: this.userService.tryClaims().userId}))
          .filter(item => item.isActive);
        users = await this.userService.getCustomers(this.company);
      }

      if (view === 3) {
        const combinedSignings: Signing[] = [];
        const combinedRequests: FileRequest[] = [];
        const combinedRecent: Document[] = [];
        for (const item of this.recent) {
          const occurence = combinedRecent.find(document => document.userId === item.userId);
          if (occurence) {
            // @ts-ignore
            occurence.count = occurence?.count ? occurence?.count + 1 : 2;
          } else {
            combinedRecent.push(item);
          }
          this.recent = combinedRecent;
        }

        for (const request of this.requests) {
          const occurence = combinedRequests.find(document => document.userIds === request.userIds);
          if (occurence) {
            // @ts-ignore
            occurence.count = occurence?.count ? occurence?.count + 1 : 2;
          } else {
            combinedRequests.push(request);
          }
          this.requests = combinedRequests;
        }

        for (const signing of this.signings) {
          const occurence = combinedSignings.find(item => item.userId === signing.userId &&
            item.signType === signing.signType && this.getSigningStatus(item) === this.getSigningStatus(signing));
          if (occurence) {
            // @ts-ignore
            occurence.count = occurence?.count ? occurence?.count + 1 : 2;
          } else {
            combinedSignings.push(signing);
          }
          this.signings = combinedSignings;
        }
      }

      for (const request of this.requests) {
        const requestUsers = users.filter(usr => request.userIds.includes(usr.id));
        data.push({
          docName: request.filename,
          creationDate: request.creationDate,
          status: !!request.fileId ? 'uploaded' : 'pending',
          type: 'request',
          dossierId: request.dossierId,
          folderId: request.dossierId ? undefined : request.folderId,
          user: requestUsers.length === 1 ? requestUsers[0] : undefined,
          userName: requestUsers.length === 1 ? requestUsers[0].firstName + ' ' + requestUsers[0].lastName : undefined,
          users: requestUsers.length > 1 ? requestUsers : undefined
        });
      }

      for (const signing of this.signings) {
        data.push({
          docName: signing.file.name,
          creationDate: signing.creationDate!,
          status: this.getSigningStatus(signing),
          type: signing.signType === SignType.signature ? 'signing' : 'accept',
          signature: signing,
          dossierId: signing.dossierId,
          folderId: signing.dossierId ? undefined : signing.file.parentFolder.id,
          fileId: signing.file.id,
          userName: signing.user.firstName + ' ' + signing.user.lastName,
          user: signing.user
        });
      }

      for (const recent of this.recent) {
        const user = (users.find(usr => Number(usr.id) === Number(recent.userId))!);
        if (!user) {
          continue;
        }
        data.push({
          docName: recent.name,
          creationDate: recent.creationDate,
          status: 'uploaded',
          type: 'upload',
          userName: user.firstName + ' ' + user.lastName,
          folderId: recent.parentFolder.id,
          fileId: recent.id,
          user,
        });
      }
    }
    this.lData = data.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
    this.applyFilter();
  }

  getSigningStatus(signing: Signing){
    if (signing.rejectionDate){
      return 'rejected';
    }
    if (signing.signDate){
      if (signing.signType === SignType.signature){
        return 'signed';
      } else {
        return 'accepted';
      }
    }
    return 'pending';
  }

  async openFile(id: any) {
    await this.router.navigate(['exchange', 'viewer', id]);
  }

  async openDossier(id: number) {
    await this.router.navigate(['dossier', 'details', id.toString()]);
  }

  async openFolder(id: number){
    await this.router.navigate(['exchange', id]);
  }

  async openFolderByDocument(id: number) {
    const doc: Document = await this.documentService.get(id);
    await this.router.navigate(['exchange', doc.parentFolder.id.toString()]);
  }

  openSignature(signature: Signing) {
    if (signature.signDate) {
      this.dialog.open(DisplaySignaturesDialogComponent, {
        data: {
          signatures: [signature],
        },
        autoFocus: false
      });
    }
  }

  triggerAction(event: any, row: UserRecentTableContent) {
    switch (event) {
      case 1: this.openFile(row.fileId); return;
      case 2: this.openDossier(row.dossierId!); return;
      case 3: this.openFolder(row.folderId!); return;
      case 4: this.openSignature(row.signature!); return;
    }
  }

  async routeToUser(user: User) {
    if (user.role === Role.customer) {
      await this.router.navigate(['management', 'customers', 'details', user.id]);
    } else {
      await this.router.navigate(['management', 'employees', 'details', user.id]);
    }
  }
}

export class UserRecentTableContent {
  docName!: string;
  creationDate!: string;
  status!: 'rejected' | 'signed' | 'accepted' | 'pending' | 'uploaded';
  type!: 'request' | 'signing' | 'accept' | 'upload';

  dossierId?: number;
  fileId?: number;
  folderId?: number;
  signature?: Signing;
  user?: User | RedactedUser;
  users?: User[];
  userName?: string;
}
