/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKey, CreateApiKey, FullApiKey, UpdateApiKey } from 'app/functional/models/api-key.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {
  private url = `${environment.rootUrl}api_key`;

  constructor(private http: HttpClient) { }

  create(createApiKey: CreateApiKey): Promise<FullApiKey> {
    return this.http.post<FullApiKey>(`${this.url}`, createApiKey).toPromise();
  }

  byUser(userId: number): Promise<ApiKey[]> {
    return this.http.get<ApiKey[]>(
      `${this.url}`,
      { params: { user_id: userId.toString() } }
    ).toPromise();
  }

  update(apiKeyId: number, updateApiKey: UpdateApiKey): Promise<ApiKey> {
    return this.http.put<ApiKey>(
      `${this.url}/${apiKeyId}`,
      updateApiKey,
    ).toPromise();
  }

  delete(apiKeyId: number): Promise<void> {
    return this.http.delete<void>(`${this.url}/${apiKeyId}`).toPromise();
  }
}
