<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="ftp-settings-dialog">
  <div class="row">
    <div class="col-md-12">
      <p class="emma-subtitle centered">{{ 'ftp.ftp_settings' | translate }}</p>
    </div>
  </div>
  <div class="row margin-20">
    <div class="col-md-12 centered">
      <p class="emma-font-light text-align-center">
        {{ 'ftp.synchronisation' | translate }}
      </p>
    </div>
  </div>
  <div class="row margin-30">
    <div class="col-md-12">
      <form [formGroup]="directoryForm" *ngIf="directoryForm">
        <div class="emma-input-container">
          <label label for="directory" class="emma-input-label">{{ 'folder_details.folder_settings_ftp_root'  | translate }}</label>
          <input appEmmaInput id="directory" formControlName="directory">
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered">
      <button  class="btn btn-blue btn-custom" (click)="submit()">
        <b>{{ 'ftp.save' | translate }}</b>
      </button>
    </div>
  </div>
</div>
