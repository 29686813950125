import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatLegacySelectionListChange as MatSelectionListChange} from '@angular/material/legacy-list';
import {Claims, RedactedUser} from '../../functional/models/user';
import {UserService} from '../../services/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DeleteChatDialogComponent} from '../../dialogs/chat/delete-chat-dialog/delete-chat-dialog.component';
import {AddChatUserDialogComponent} from '../../dialogs/chat/add-chat-user-dialog/add-chat-user-dialog.component';
import {NewChatDialogComponent} from '../../dialogs/chat/new-chat-dialog/new-chat-dialog.component';
import {Subscription} from 'rxjs';
import {Company} from '../../functional/models/company';
import {CompanyService} from '../../services/company/company.service';
import {ChatMessage, ChatModel} from '../../functional/models/chat.model';
import {ChatService} from '../../services/chat/chat.service';
import {Role} from '../../functional/models/role.model';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ArchiveChatDialogComponent} from '../../dialogs/chat/archive-chat-dialog/archive-chat-dialog.component';
import {LeaveChatDialogComponent} from '../../dialogs/chat/leave-chat-dialog/leave-chat-dialog.component';
import {NewChatCustomerDialogComponent} from '../../dialogs/chat/new-chat-customer-dialog/new-chat-customer-dialog.component';
import {Document} from '../../functional/models/document';
import {DocumentService} from '../../services/document/document.service';
import {Organization} from '../../functional/models/organization.model';
import {Dossier} from '../../functional/models/dossier.model';
import {SearchChatDialogComponent} from '../../dialogs/chat/search-chat-dialog/search-chat-dialog.component';
import { WebsocketService } from 'app/services/websocket/websocket.service';
import {UtilsService} from '../../services/utils/utils.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent implements OnInit, OnDestroy {
  chatSelected?: ChatModel;
  currentChatMessages: ChatMessage[] = [];
  companySubscription = new Subscription();
  chatSubscription = new Subscription();
  messageSubscription = new Subscription();
  company!: Company;
  claims!: Claims;
  deleting = false;
  message!: string;
  unreadRenderedBefore?: number = undefined;
  unreadCount = 0;

  fileUrl!: string;
  selectedTab: 'all' | 'own' | 'archived' | 'open' = 'own';

  nameFilter!: string;
  userFilter: RedactedUser[] = [];
  organizationFilter: Organization[] = [];
  dossierFilter: Dossier[] = [];

  private pChats: ChatModel[] = [];
  private pOpen: ChatModel[] = [];
  private pArchives: ChatModel[] = [];
  private pOwn: ChatModel[] = [];
  private pDisplay: ChatModel[] = [];

  get displayedChats(): ChatModel[] {
    let display = this.pDisplay;
    if (this.nameFilter && this.nameFilter !== '' && this.nameFilter !== ' ') {
      display = display.filter(chat => chat.name.toLowerCase().includes(this.nameFilter.toLowerCase()));
    }
    if (this.userFilter.length > 0) {
      display = display.filter(chat => !!chat.users.find(user => !!this.userFilter.find(usr => usr.id === user.id)));
    }
    if (this.organizationFilter.length > 0) {
      display = display.filter(
        chat => chat.organization
          && !!this.organizationFilter
            .find(organization => organization.id === chat.organization.id)
      );
    }
    if (this.dossierFilter.length > 0) {
      display = display.filter(chat => chat.dossier && !!this.dossierFilter.find(dossier => dossier.id === chat.dossier!.id));
    }

    return display.sort((a, b) => this.getDate(a) < this.getDate(b) ? 1 : -1);
  }

  set setAllChats(chats: ChatModel[]) {
    this.pChats = chats;
    this.pOpen = this.pChats
      .filter(chat => !chat.isArchived && chat.users.length <= 1 && !chat.users.some(user => user.id === this.claims.userId));
    this.pOwn = this.pChats
      .filter(chat => !chat.isArchived && chat.users.some(user => user.id === this.claims.userId));
    this.pArchives = this.pChats.filter(chat => chat.isArchived);
    this.selectTab(this.selectedTab);
  }

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private chatService: ChatService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private documentService: DocumentService,
    private websocketService: WebsocketService,
    private utilsService: UtilsService
  ) {
    this.claims = this.userService.tryClaims();
    this.listenMessages();
  }

  getDate(chat: ChatModel) {
    if (chat.recentMessage && chat.recentMessage.createdAt) {
      return chat.recentMessage.createdAt;
    }
    return chat.createdAt;
  }

  async ngOnInit() {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
      await this.getChats();
      this.pDisplay = this.pOwn;
      this.setGlobalUnread();
    });
  }

  ngOnDestroy(): void {
    this.deleting = true;
    this.companySubscription.unsubscribe();
    this.chatSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
  }

  setGlobalUnread() {
    this.unreadCount = this.pChats.reduce((sum, chat) => sum + chat.unreadCount, 0);
    window.dispatchEvent(new CustomEvent('unread-set', { bubbles: true, detail: { count: () => this.unreadCount } }));
  }

  addGlobalUnread() {
    this.unreadCount++;
    window.dispatchEvent(new CustomEvent('unread-set', { bubbles: true, detail: { count: () => this.unreadCount } }));
  }

  listenMessages() {
    this.messageSubscription = this.websocketService.messages
      .subscribe(message => this.handleMessage(message));
    this.chatSubscription = this.websocketService.chats
      .subscribe(chat => this.handleChat(chat));
  }

  async handleMessage(message: ChatMessage) {
    if (this.chatSelected && this.chatSelected.id === message.chatId) {
      this.currentChatMessages.push(message);
      await this.scrollToBottom();
      if (!document.hasFocus()) {
        this.setAllChats = this.pChats.map(cht => {
          if (cht.id === message.chatId) {
            return {...cht, unreadCount: cht.unreadCount += 1, recentMessage: message};
          } else {
            return cht;
          }
        });
        this.addGlobalUnread();
      } else {
        this.setCurrentChatLastOnline();
        await this.setChatLastOnline(this.chatSelected.id);
        this.setGlobalUnread();
      }
    } else {
      this.setAllChats = this.pChats.map(cht => {
        if (cht.id === message.chatId) {
          return {...cht, unreadCount: cht.unreadCount += 1, recentMessage: message};
        } else {
          return cht;
        }
      });
      this.addGlobalUnread();
    }
  }

  async handleChat(chat: ChatModel) {
    if (chat.isDeleted) {
      this.pChats = this.pChats.filter(cht => cht.id !== chat.id);
      this.pOwn = this.pOwn.filter(cht => cht.id !== chat.id);
      this.pOpen = this.pOpen.filter(cht => cht.id !== chat.id);
      this.pArchives = this.pArchives.filter(cht => cht.id !== chat.id);
    }
    if (chat.isArchived) {
      this.pChats = this.pChats.filter(cht => cht.id !== chat.id);
      this.pOwn = this.pOwn.filter(cht => cht.id !== chat.id);
      this.pOpen = this.pOpen.filter(cht => cht.id !== chat.id);
      this.pArchives.push(chat);
    } else {
      this.pChats.push(chat);
      this.setAllChats = this.pChats;
    }
  }

  isChat(data: any): boolean {
    const properties = [
      'id', 'name', 'companyId', 'users', 'lastOnline', 'createdAt',
      'updatedAt', 'unreadCount', 'labels', 'events', 'isArchvied',
    ];
    return properties.every((prop) => data.hasOwnProperty(prop));
  }

  isEvent(data: any): boolean {
    const properties = ['event', 'userId', 'createdAt'];
    return properties.every((prop) => data.hasOwnProperty(prop));
  }

  isMessage(data: any): boolean {
    const properties = ['id', 'content', 'chatId', 'createdAt', 'updatedAt', 'file', 'sender'];
    return properties.every((prop) => data.hasOwnProperty(prop));
  }

  async getChats() {
    const claims = this.userService.tryClaims();
    if (claims.role === Role.superuser || claims.role === Role.admin) {
      this.setAllChats = [
        ...await this.chatService.getAll({companyId: this.company.id, archive: true}),
        ...await this.chatService.getAll({companyId: this.company.id, archive: false})
      ];
    } else if (claims.role === Role.employee) {
      this.setAllChats = [
        ...await this.chatService.getAll({companyId: this.company.id, userId: claims!.userId, archive: true}),
        ...await this.chatService.getAll({companyId: this.company.id, parentUserId: claims!.userId, archive: false}),
        ...await this.chatService.getAll({companyId: this.company.id, userId: claims!.userId, archive: false}),
      ].reduce((acc: ChatModel[], current: ChatModel) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

       // = Array.from(new Set(chats!.map(chat => chat.id)))!.map(id => {return chats!.find(chat => chat.id === id)!});

    } else if (claims.role === Role.customer) {
      this.setAllChats = [
        ...await this.chatService.getAll({companyId: this.company.id, userId: claims!.userId, archive: true}),
        ...await this.chatService.getAll({companyId: this.company.id, userId: claims!.userId, archive: false})
      ];
    }
  }

  resetChat() {
    this.chatSelected = undefined;
    this.currentChatMessages = [];
    this.unreadRenderedBefore = undefined;
  }

  async goBack() {
    this.setCurrentChatLastOnline();
    await this.setChatLastOnline(this.chatSelected!.id);
    this.setGlobalUnread();
    this.resetChat();
  }

  setCurrentChatLastOnline() {
    if (this.chatSelected) {
      this.chatSelected!.lastOnline[this.claims.userId] = (new Date()).toString();
      this.chatSelected!.unreadCount = 0;
    }
  }

  async setChatLastOnline(chatId: number) {
    const curChat = this.pChats.find(cht => cht.id === chatId)!;
    // only set this user online if they are a member of this chat
    if (!this.userIn(curChat)) {
      return;
    }
    curChat.lastOnline[this.claims.userId.toString()] = (new Date()).toString();
    curChat.unreadCount = 0;
    this.setAllChats = this.pChats;
    await this.chatService.setOnline(chatId);
  }

  userIn(chat: ChatModel): boolean {
    const user = this.userService.tryClaims();
    return chat.users.some(u => u.id === user.userId);
  }

  async setChatSelected($event: MatSelectionListChange) {
    this.resetChat();
    this.currentChatMessages = await this.chatService.getMessages({
      chatId: $event.options[0].value.id
    });
    this.chatSelected = this.utilsService.deepCopy<ChatModel>($event.options[0].value);
    await this.scrollToBottom();

    if (!this.chatSelected?.isArchived) {
      await this.setChatLastOnline(this.chatSelected!.id);
      this.setGlobalUnread();
    }
  }

  async setChatSelectedByChat(chat: ChatModel) {
    this.resetChat();
    this.currentChatMessages = await this.chatService.getMessages({
      chatId: chat.id
    });
    this.chatSelected = this.utilsService.deepCopy<ChatModel>(chat);
    await this.scrollToBottom();

    if (!this.chatSelected?.isArchived) {
      await this.setChatLastOnline(this.chatSelected!.id);
      this.setGlobalUnread();
    }
  }

  async sendMessage() {
    this.message = this.message.trim();
    if (this.message && this.message !== '') {
      if (this.chatSelected) {
        const message = await this.chatService.sendMessage({
          chatId: this.chatSelected!.id,
          content: this.message
        });
        this.message = '';
        this.currentChatMessages.push(message);
        await this.scrollToBottom();
        this.setAllChats = this.pChats.map(cht => {
          if (cht.id === this.chatSelected!.id) {
            return {...cht, recentMessage: message};
          } else {
            return cht;
          }
        });

        this.setCurrentChatLastOnline();
        await this.setChatLastOnline(this.chatSelected.id);
      }
    }
  }

  getDateFromString(dateString: string) {
    const date = new Date(dateString);
    const now = new Date().getTime();

    if ((now - date.getTime()) < 3600 * 24 * 1000) {
      return (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    } else if ((now - date.getTime()) < 3600 * 48 * 1000) {
      return this.translateService.instant('messaging.yesterday');
    } else if ((now - date.getTime()) < 3600 * 72 * 1000) {
      return this.translateService.instant('messaging.before_yesterday');
    }
    return (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' +
      (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '/' +
      date.getFullYear();
  }

  async openAddChat() {
    let dialog;
    if (this.claims?.role === Role.customer) {
      dialog = this.dialog.open(NewChatCustomerDialogComponent, {
        data: {
          company: this.company,
        },
        autoFocus: false
      });
    } else {
      dialog = this.dialog.open(NewChatDialogComponent, {
        data: {
          company: this.company,
        },
        autoFocus: false
      });
    }
    dialog.afterClosed().subscribe(async (res) => {
      if (!!res) {
        await this.getChats();
        await this.setChatSelectedByChat(res);
      }
    });
  }

  openAddPerson() {
    const dialog = this.dialog.open(AddChatUserDialogComponent, {
      data: {
        company: this.company,
        chat: this.chatSelected!,
      },
      autoFocus: false
    });
    dialog.afterClosed().subscribe((res: ChatModel) => {
      const chatRef = this.pChats.find(item => item.id === this.chatSelected!.id)!;
      this.chatSelected!.name = res.name;
      this.chatSelected!.users = res.users;
      this.chatSelected!.organization = res.organization;
      chatRef.name = res.name;
      chatRef.users = res.users;
      chatRef.organization = res.organization;

      this.setAllChats = this.pChats;
    });
  }

  async deleteChat() {
    const deleteDialog = this.dialog.open(DeleteChatDialogComponent, {
      data: {
        chatId: this.chatSelected!.id
      },
      autoFocus: false
    });
    deleteDialog.afterClosed().subscribe(async (res: any) => {
      if (res) {
        this.resetChat();
        await this.getChats();
        this.snackBar.open(this.translateService.instant('chat.delete'), '', { duration: 5000 });
      }
    });
  }

  compareAndPrint(message: ChatMessage) {
    if (this.unreadRenderedBefore && this.unreadRenderedBefore !== message.id) {
      return false;
    }

    if (this.unreadRenderedBefore && this.unreadRenderedBefore === message.id) {
      return true;
    }

    if (this.toDate(message.createdAt) > this.toDate(this.chatSelected!.lastOnline[this.claims.userId])) {
      this.unreadRenderedBefore = message.id;
      return true;
    }
    return false;
  }

  toDate(element: string) {
    return new Date(element);
  }

  async scrollToBottom() {
    await (new Promise(resolve => setTimeout(resolve, 200)));
    const objDiv = document.getElementById('chatBox');
    if (objDiv?.scrollHeight) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  async archiveChat() {
    const deleteDialog = this.dialog.open(ArchiveChatDialogComponent, {
      data: {
        chatId: this.chatSelected!.id
      },
      autoFocus: false
    });
    deleteDialog.afterClosed().subscribe(async (res: any) => {
      if (res) {
        this.resetChat();
        await this.getChats();
        this.snackBar.open(this.translateService.instant('chat.archiveren'), '', { duration: 5000 });
      }
    });
  }

  async leaveChat() {
    const deleteDialog = this.dialog.open(LeaveChatDialogComponent, {
      data: {
        chatId: this.chatSelected!.id,
        userId: this.claims.userId
      },
      autoFocus: false
    });
    deleteDialog.afterClosed().subscribe(async (res: any) => {
      if (res) {
        this.resetChat();
        await this.getChats();
        this.snackBar.open(this.translateService.instant('chat.verlaten'), '', { duration: 5000 });
      }
    });
  }

  isMod() {
    return this.claims.role === Role.admin || this.claims.role === Role.employee || this.claims.role === Role.superuser;
  }

  isInChat() {
    return !!this.chatSelected?.users.find(user => user.id === this.userService.tryClaims().userId);
  }

  getUsers(chatSelected: ChatModel) {
    return chatSelected.users.reduce((userString, user) => userString + user.firstName + ' ' + user.lastName + '\n', '');
  }

  async uploadChatFile($event: any) {
    if (this.chatSelected) {
      const file = $event.target.files[0];
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = async (e) => {
        if (reader.result === null) {
          this.snackBar.open(
            this.translate.instant('explorer.fail_upload'),
            this.translate.instant('close'),
            { duration: 2000 },
          );
          return;
        }
        const document = this.utilsService.splitPreamble(reader.result as string).documentURI;
        try {
          const message = await this.chatService.sendMessage({
            chatId: this.chatSelected!.id,
            content: '',
            file: {
              document,
              name: file.name,
            }
          });
          this.currentChatMessages.push(message);
          await this.scrollToBottom();
          this.setAllChats = this.pChats.map(cht => {
            if (cht.id === this.chatSelected!.id) {
              return {...cht, recentMessage: message};
            } else {
              return cht;
            }
          });

          this.setCurrentChatLastOnline();
          await this.setChatLastOnline(this.chatSelected!.id);
        } catch {
        }
      };
    }
  }

  async getUrl(file: Document, link: HTMLElement) {
    this.fileUrl = (await this.documentService.getUrl(file.id!)).url;
    if (this.fileUrl) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      link.click();
    }
  }

  selectTab(s: 'all' | 'own' | 'archived' | 'open') {
    this.selectedTab = s;
    switch (s) {
      case 'all':
        this.pDisplay = this.pChats;
        break;
      case 'archived':
        this.pDisplay = this.pArchives;
        break;
      case 'open':
        this.pDisplay = this.pOpen;
        break;
      case 'own':
        this.pDisplay = this.pOwn;
        break;
    }
  }

  async assignSelf() {
    await this.chatService.addUser(
      this.chatSelected!.id,
      this.userService.tryClaims().userId
    );
    const currentUser = await this.userService.getUser();
    const chatRef = this.pChats.find(item => item.id === this.chatSelected!.id)!;
    chatRef.users = [...chatRef.users, currentUser];
    this.chatSelected!.users = [...this.chatSelected?.users || [], currentUser];
    this.setAllChats = this.pChats;

    this.snackBar.open(this.translate.instant('chat.picked'), '', { duration: 2000 });
  }

  openFilter() {
    const dialog = this.dialog.open(SearchChatDialogComponent, {
      data: {
        company: this.company,
        userFilter: this.userFilter,
        organizationFilter: this.organizationFilter,
        dossierFilter: this.dossierFilter,
      },
      autoFocus: false
    });

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.userFilter = res.userFilter;
        this.dossierFilter = res.dossierFilter;
        this.organizationFilter = res.organizationFilter;
      }
    });
  }
}
