import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OrganizationListComponent} from './organization-list/organization-list.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OrganizationDetailsComponent } from './organization-details/organization-details.component';
import {OrganizationModule} from '../../../components/organization/organization.module';
import {DossierOverviewModule} from '../../../components/dossiers/dossier-overview/dossier-overview.module';
import {ConfirmDeleteOrganizationUserDialogComponent} from '../../../dialogs/confirm-delete-organization-user-dialog/confirm-delete-organization-user-dialog.component';
import {ExchangeModule} from '../../exchange/exchange.module';
import {MatIconModule} from '@angular/material/icon';
import {ExchangeDetailsModule} from '../../../components/exchange/exchange-details/exchange-details.module';
import {SubNavModule} from '../../../components/sub-nav/sub-nav.module';
import {ExchangeOverviewModule} from '../../../components/exchange/exchange-overview/exchange-overview.module';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {NgIconsModule} from '@ng-icons/core';
import {ConnectedUsersModule} from '../../../components/connected-users/connected-users.module';
import {EmmaSelectModule} from '../../../components/base-components/emma-select/emma-select.module';

@NgModule({
  declarations: [
    OrganizationListComponent,
    OrganizationDetailsComponent,
    ConfirmDeleteOrganizationUserDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    OrganizationModule,
    DossierOverviewModule,
    ExchangeDetailsModule,
    ExchangeModule,
    MatIconModule,
    SubNavModule,
    ExchangeOverviewModule,
    MatMenuModule,
    NgIconsModule,
    ConnectedUsersModule,
    EmmaSelectModule,
  ]
})
export class OrganizationsModule { }
