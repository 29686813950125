<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="exchange-request-signing-dialog">
  <div class="row margin-30">
    <div class="col-md-12 centered">
      <p class="emma-title"> {{ 'signature_dialog.request' | translate }} </p>
    </div>
  </div>

  <div class="row margin-20">
    <div class="col-md-12 align-text-center">
      <p>{{ 'signature_dialog.want' | translate }}<b *ngFor="let item of explorerItems; let last = last">{{last? item.file!.name : item.file!.name + ', '}}</b> laten signeren of accorderen.</p>
      <p>{{ 'signature_dialog.who' | translate }}</p>
    </div>
  </div>

  <div class="row margin-20">
    <div class="col-md-12 centered">
      <p class="emma-subtitle">
        {{ 'signature_dialog.users' | translate }}
      </p>
    </div>
  </div>
  <ng-container *ngFor="let user of signUsers">
    <div class="row" *ngIf="user.role === Role.customer">
      <div class="col-md-5">
        <b>{{user.firstName + ' ' + user.lastName}}</b>
      </div>

      <div class="col-md-7">
        <mat-radio-group style="display: flex" [(ngModel)]="user['signed']" id="signing-type">
          <div class="col-md-4">
            <mat-radio-button [value]="0" class="signature-type">{{ 'signature_dialog.sign' | translate }}</mat-radio-button>
          </div>
          <div class="col-md-4">
            <mat-radio-button [value]="1" class="approval-type">{{ 'signature_dialog.approve' | translate }}</mat-radio-button>
          </div>
          <div class="col-md-4">
            <mat-radio-button [value]="undefined" class="nothing-type">{{ 'signature_dialog.no_approve' | translate }}</mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <mat-expansion-panel class="white-expansion">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'dialog.request.employees' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let user of signUsers; let i = index;">
        <div class="row" *ngIf="user.role === Role.admin || user.role === Role.employee">
          <div class="col-md-5">
            <b>{{user.firstName + ' ' + user.lastName}}</b>
          </div>

          <div class="col-md-7">
            <mat-radio-group style="display: flex" [(ngModel)]="user['signed']" id="signing-type-employees">
              <div class="col-md-4">
                <mat-radio-button [value]="0" class="signature-type">{{ 'signature_dialog.sign' | translate }}</mat-radio-button>
              </div>
              <div class="col-md-4">
                <mat-radio-button [value]="1" class="approval-type">{{ 'signature_dialog.approve' | translate }}</mat-radio-button>
              </div>
              <div class="col-md-4">
                <mat-radio-button [value]="undefined" class="nothing-type">{{ 'signature_dialog.no_approve' | translate }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </div>
  <div class="row margin-top-50">
    <div class="col-md-12 centered">
      <button  class="btn btn-blue btn-custom " (click)="submit()" id="request-signing">
        <b>{{ 'signature_dialog.request2' | translate }}</b>

      </button>
    </div>
  </div>
</div>
