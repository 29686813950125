export enum EntityKind {
  chat = 'ChatDto',
  chatMessage = 'ChatMessageDto',
  login = 'LoginDto',
  zip = 'ZipDto'
}

export class SocketMessage<T> {
  entity!: T;
  kind!: EntityKind;
}
