import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {SubNavComponent} from './sub-nav.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {EmmaSelectModule} from '../base-components/emma-select/emma-select.module';
import { NgIconsModule } from '@ng-icons/core';
import {heroArrowLeft} from '@ng-icons/heroicons/outline';

@NgModule({
  declarations: [
    SubNavComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    TranslateModule.forRoot(),
    MatIconModule,
    MatTabsModule,
    MatMenuModule,
    NgIconsModule.withIcons({
      heroArrowLeft
    }),
    EmmaSelectModule,
  ],
  exports: [
    SubNavComponent,
  ]
})
export class SubNavModule { }
