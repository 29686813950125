import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {SearchKvkComponent} from './search-kvk.component';
import {MatIconModule} from '@angular/material/icon';
import {EmmaInputModule} from '../../base-components/emma-input/emma-input.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroMagnifyingGlass } from '@ng-icons/heroicons/outline';



@NgModule({
  declarations: [SearchKvkComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    MatIconModule,
    EmmaInputModule,
    NgIconsModule.withIcons({
      heroMagnifyingGlass
    }),
  ],
  exports: [
    SearchKvkComponent
  ]
})
export class SearchKvkModule { }
