<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="add-dossier-template-action-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'dossier_template.dialog.add_action.title' | translate }} </p>
    </div>
  </div>
  <form ngForm [formGroup]="addActionForm">
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <div class="emma-input-container">
        <label label for="name" class="emma-input-label">{{ 'dossier_template.dialog.add_action.name'| translate }}</label>
        <input appEmmaInput id="name" formControlName="name">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <mat-form-field class="full-width">
        <mat-label>{{ 'dossier_template.dialog.add_action.actions' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let event of dossierEvents" [value]="event.event">
            {{event.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <mat-form-field class="full-width">
        <mat-label>{{ 'dossier_template.dialog.add_action.destination' | translate }}</mat-label>
        <mat-select formControlName="target">
          <ng-container *ngFor="let state of data.template.states">
            <mat-option *ngIf="!state.initialState" [value]="state.id">
              {{state.name}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top reverse">
      <mat-label>{{ 'dossier_template.dialog.add_action.happy' | translate }}</mat-label>
      <mat-checkbox formControlName="happy" class="happy_checkbox"></mat-checkbox>
    </div>
  </div>
  </form>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button data-test="delete-submit-button"  class="btn btn-blue btn-custom" (click)="addAction()">
        <b> {{ 'dossier_template.dialog.add_action.submit' | translate }} </b>
      </button>
    </div>
  </div>
</div>
