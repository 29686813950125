import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../services/user/user.service';
import {CompanyService} from '../../../services/company/company.service';
import {Company, Meta} from '../../../functional/models/company';
import {Group} from '../../../functional/models/group';
import { User } from 'app/functional/models/user';
import {Subscription} from 'rxjs';
import {Role} from '../../../functional/models/role.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import {mergeMapTo} from 'rxjs/operators';
import * as firebase from 'firebase/compat/app';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  floor = Math.floor;
  groups: Group[] = [];
  customers: User[] = [];
  employees: User[] = [];
  userRole!: Role;
  currentCompany!: Company;
  company?: Meta;

  companySubscription = new Subscription();
  user!: User;
  view = 1;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private router: Router,
    public dialog: MatDialog,
    private afMessaging: AngularFireMessaging
  ) { }

  ngOnDestroy(): void {
    this.companySubscription.unsubscribe();
  }

  async ngOnInit() {
    this.userRole = this.userService.tryClaims().role;
    this.user = await this.userService.getUser();
    this.currentCompany = await this.companyService.findCurrentCompany();

    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.currentCompany = company;
      }
      if (this.userRole === Role.admin || this.userRole === Role.superuser) {
        this.company = await this.companyService.getMeta(this.currentCompany.id);
      }
    });

    if (this.userService.tryClaims()) {
      this.requestPermission();
    }
  }

  requestPermission() {
    if (firebase && firebase.default.messaging && firebase.default.messaging.isSupported()) {
      try {
        this.afMessaging.requestPermission.pipe(mergeMapTo(this.afMessaging.tokenChanges)).subscribe(
          async (token) => {
            if (token) {
              await this.userService.updateDesktopToken(token!);
            }
          },
          (error) => {
            console.error(error);
          },
        );
      } catch {
      }
    }
  }

  async createDossier() {
    await this.router.navigate(['dossier', 'create']);
  }
}
