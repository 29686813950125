import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {Signing} from '../../../functional/models/signing';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Router} from '@angular/router';
import {SigningService} from '../../../services/signing/signing.service';
import {Document} from '../../../functional/models/document';
import {DocumentService} from '../../../services/document/document.service';
import {DisplaySignaturesDialogComponent} from '../../../dialogs/display-signatures-dialog/display-signatures-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-emma-signing-overview',
  templateUrl: './emma-signing-overview.component.html',
  styleUrls: ['./emma-signing-overview.component.scss']
})
export class EmmaSigningOverviewComponent implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) set content(content: any) {
    if (content && this.signatureSource) {
      this.signatureSource.sort = content;
      this.signatureSource.paginator = this.paginator;
    }
  }

  @Input() userId!: number;

  requests: Signing[] = [];
  signatureSource!: MatTableDataSource<Signing>;

  constructor(
    private router: Router,
    private signingService: SigningService,
    private documentService: DocumentService,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.requests = await this.signingService.getSigningsByUser(this.userId);
    this.signatureSource = new MatTableDataSource(this.requests);
  }

  async openSigning(row: any) {
    await this.router.navigate(['exchange', 'viewer', row.file.id]);
  }

  async openDossier(id: number) {
    await this.router.navigate(['dossier', 'details', id.toString()]);
  }

  async openFolder(id: number) {
    const doc: Document = await this.documentService.get(id);
    await this.router.navigate(['exchange', doc.parentFolder.id.toString()]);
  }

  openSignature(signature: Signing) {
    if (signature.signDate) {
      this.dialog.open(DisplaySignaturesDialogComponent, {
        data: {
          signatures: [signature],
        },
        autoFocus: false
      });
    }
  }
}
