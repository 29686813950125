<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div class="exact-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'exact.title' | translate }} </p>
    </div>
  </div>

  <ng-container *ngIf="exactMeta.length > 1">
    <ng-container>
      <div class="row">
        <div class="col-md-12 centered">
          <p class="emma-subtitle1 title-padding"> {{ 'exact.select_division' | translate }} </p>
        </div>
      </div>

      <mat-checkbox *ngFor="let meta of exactMeta" [(ngModel)]="meta.synchronised">
        {{meta.divisionName}}
      </mat-checkbox>
    </ng-container>

    <div class="row">
      <div class="col-md-12 centered small-margin-bottom">
        <button data-test="delete-submit-button"  class="btn btn-blue btn-custom" (click)="setSelectedMeta()">
          <b> {{ 'exact.next' | translate }} </b>

        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedMeta">
    <ng-container *ngFor="let meta of selectedMeta.slice(metaIndex - 1, metaIndex)">
      <div class="row">
        <div class="col-md-12 centered">
          <p class="emma-subtitle1 title-padding"> {{ 'exact.select_folder' | translate :{division: meta.divisionName} }} </p>
        </div>
      </div>

      <div class="file-tree">
        <app-recursive-tree [sources]="[]" [fileSource]="source" (selectedChange)="meta.destinationFolder = $event.id"></app-recursive-tree>
      </div>

      <div class="row">
        <div class="col-md-12 centered small-margin-bottom">
          <button [disabled]="!meta.destinationFolder" data-test="delete-submit-button"  class="btn btn-blue btn-custom" (click)="createSync()">
            <b> {{ metaIndex === selectedMeta.length ? ('exact.submit' | translate) : ('exact.next' | translate) }} </b>

          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
