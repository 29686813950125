<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card" id="company-form-block">
          <div class="card-header card-header-grey card-header-icon">
            <div class="card-icon card-header-custom"><mat-icon>business</mat-icon></div>
            <div class="exchange-card-title-between">
              <h4 class="card-title">
                {{'company.create' | translate}}
              </h4>
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="companyForm" *ngIf="companyForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="emma-input-container">
                    <label label for="name" class="emma-input-label">{{ 'company.name' | translate }}</label>
                    <input appEmmaInput id="name" formControlName="name">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="emma-input-container">
                    <label label for="domain" class="emma-input-label">{{'company.url'  | translate }}</label>
                    <div class="emma-input">
                      <input id="domain" appEmmaInput formControlName="domain">
                      <span>.myemmacloud.nl</span>
                    </div>

                    <span class="emma-input-error" *ngIf="companyForm.controls['domain'].hasError('companyInvalid')">
                      {{'domain_used' | translate}}
                    </span>
                    <span class="emma-input-error" *ngIf="companyForm.controls['domain'].hasError('pattern')">
                      {{'false_pattern' | translate}}
                    </span>
                    <span class="emma-input-error" *ngIf="companyForm.controls['domain'].hasError('minlength')">
                      {{'min_length' | translate}}
                    </span>
                    <span class="emma-input-error" *ngIf="companyForm.controls['domain'].hasError('maxlength')">
                      {{'max_length' | translate}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="emma-input-container">
                    <label label for="diskspace" class="emma-input-label">{{ 'company.diskspace' | translate }}</label>
                    <input appEmmaInput id="diskspace" formControlName="diskspace">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="emma-input-container">
                    <label label for="employees" class="emma-input-label">{{ 'company.employees' | translate }}</label>
                    <input appEmmaInput id="employees" formControlName="employees">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="emma-input-container">
                    <label label for="customers" class="emma-input-label">{{ 'company.customers' | translate }}</label>
                    <input appEmmaInput id="customers" formControlName="customers">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="emma-input-container">
                    <label label for="gender" class="emma-input-label">{{'company.typename'  | translate }}</label>
                    <app-emma-select
                      id="gender"
                      layout="input"
                      [fullWidth]="true"
                      [renderButton]="true"
                      formControlName="companyType"
                      [options]="[
                        {displayName: 'company.type.bookkeeper' | translate, value: '0'},
                        {displayName: 'company.type.care' | translate, value: '1'}
                      ]">
                    </app-emma-select>
                  </div>
                </div>
              </div>
              <button  type="submit" class="btn btn-blue btn-custom pull-right" (click)="create()">{{'company.submit_create' | translate}}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
