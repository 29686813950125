import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Company } from '../../../functional/models/company';
import { CompanyService } from '../../../services/company/company.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResetComponent implements OnInit {
  token!: string;
  type: string;
  passwordStrength = 0;
  expired = false;
  company!: Company;
  passwordForm: any;
  formGroup!: UntypedFormGroup;
  isMobile!: boolean;

  constructor(
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private companyService: CompanyService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirm: ['', [Validators.required, this.passMatch()]]
    });

    this.type = this.activeRoute.snapshot.params.type;
    this.token = this.activeRoute.snapshot.params.id;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
    this.isMobile = window.innerWidth <= 991;
  }

  async ngOnInit() {
    this.isMobile = window.innerWidth <= 991;
    this.company = await this.companyService.findCurrentCompany();
    const service = new JwtHelperService();
    if (service.isTokenExpired(this.token)) {
      this.expired = true;
      await this.expiredNotification();
    }
  }

  async expiredNotification() {
    this.snackBar.open(
      await this.translate.get('auth.reset.token_expired').toPromise(),
      await this.translate.get('close').toPromise(),
      {duration: 5000},
    );
  }

  passMatch(): ValidatorFn  {
    return (control: AbstractControl): ({ [key: string]: boolean } | null) => {
      if (!this.formGroup){
        return null;
      }

      const passMatch = this.formGroup.get('password')?.value === this.formGroup.get('confirm')?.value;
      if (passMatch) {
        return null;
      }
      return {invalidMatch: true};
    };
  }


  async reset() {
    if (this.formGroup.invalid && this.passwordStrength !== 100) {
      return;
    }

    if (this.expired) {
      await this.expiredNotification();
      return;
    }

    try {
      await this.userService.forgotReset({
        token: this.token,
        newPassword: this.formGroup.get('password')!.value,
      });
      this.router.navigate(['auth']);
    } catch {
    }
  }

  onStrengthChanged(e: number): void {
    this.passwordStrength = e;
  }
}
