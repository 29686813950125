import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Company} from '../../../functional/models/company';
import {Claims, RedactedUser, User} from '../../../functional/models/user';
import {Dossier} from '../../../functional/models/dossier.model';
import {UserService} from '../../../services/user/user.service';
import {ChatService} from '../../../services/chat/chat.service';
import {CreateChatModel} from '../../../functional/models/chat.model';

@Component({
  selector: 'app-new-chat-customer-dialog',
  templateUrl: './new-chat-customer-dialog.component.html',
  styleUrls: ['./new-chat-customer-dialog.component.scss']
})
export class NewChatCustomerDialogComponent implements OnInit {

  company: Company;
  questionType = 1;
  selectedDossier!: Dossier | null;
  claims!: Claims;
  currentUser!: User;

  constructor(
    private dialogRef: MatDialogRef<NewChatCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private chatService: ChatService,
  ) {
    this.claims = this.userService.tryClaims();
    this.company = data.company;
  }

  async ngOnInit() {
    this.currentUser = await this.userService.getUser();
  }

  setQuestionType(num: number) {
    this.questionType = num;
  }

  setDossier(event: Dossier | undefined) {
    if (event) {
      this.selectedDossier = event;
    }
  }

  removeDossier() {
    this.selectedDossier = null;
  }

  async getUserEmployees(): Promise<number[]> {
    let recipients: RedactedUser[] = [];
    if (this.company) {
      recipients = await this.userService.getRecipients(this.currentUser, this.data.company);
    } else {
      recipients = await this.userService.getRecipients(this.currentUser);
    }

    return recipients.map((user) => user.id);
  }

  async createChat() {
    const chatModel: CreateChatModel = {
      companyId: this.company.id,
      name: this.currentUser.firstName + ' ' + this.currentUser.lastName,
      users: [this.currentUser.id]
    };

    if (this.selectedDossier) {
      chatModel.dossierId = this.selectedDossier.id;
    }

    const chat = await this.chatService.create(chatModel);
    this.dialogRef.close(chat);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
