import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-logout-reason-dialog',
  templateUrl: './logout-reason-dialog.component.html',
  styleUrls: ['./logout-reason-dialog.component.scss']
})
export class LogoutReasonDialogComponent implements OnInit {

  reason: number;

  constructor(
    public dialogRef: MatDialogRef<LogoutReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reason = data?.reason || 1;
  }

  ngOnInit(): void {
  }

  deleteFiles() {
    this.dialogRef.close(true);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
