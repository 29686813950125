/**
 * All possible choices of the user to sort the files and folders.
 */
export enum SearchType {
  currentFolder = 1, // search in files and folders that are in the current folder only
  currentFolderCascade = 2, // search in files and folders that are in the current folder or any subfolder
  allFolders = 3, // search in files and folders that this user has access to
  labelCurrentFolder = 4, // search by label in the current folder
  labelCurrentFolderCascade = 5, // search by label in the current folder or any subfolder
  labelAllFolders = 6, // search by label in all folders that this user has access to
}

export enum SortType {
  nameAscending = 1,
  nameDescending = 2,
  dateAscending = 3,
  dateDescending = 4,
}
