import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { CompanyService } from 'app/services/company/company.service';
import { Company } from '../../../functional/models/company';
import { SimplicateService } from 'app/services/simplicate/simplicate.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import addons from '../addons/addons';
import { SimplicateLabelsDialogComponent } from './simplicate-labels-dialog/simplicate-labels-dialog.component';
import {AddonModel} from '../../../functional/models/addon';
import {ExactMetaFormModel, ExactMetaModel, ExactService, ExactSyncModel} from '../../../services/exact/exact.service';
import {UrlService} from '../../../services/url/url.service';
import {UserService} from '../../../services/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import { ExactLabelsDialogComponent } from './exact-labels-dialog/exact-labels-dialog.component';

@Component({
  selector: 'app-addon-details',
  templateUrl: './addon-details.component.html',
  styleUrls: ['./addon-details.component.scss']
})
export class AddonDetailsComponent implements OnInit, OnDestroy {
  companySubscription = new Subscription();
  company!: Company;
  connectionResults?: any;
  currentAddon!: AddonModel;
  simplicateState: { labels?: string[] } = { labels: undefined };
  exactState!: ExactMetaModel[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private simplicateService: SimplicateService,
    private snackBar: MatSnackBar,
    private exactService: ExactService,
    private urlService: UrlService,
    private userService: UserService,
    private translate: TranslateService
  ) {
    const id = this.activatedRoute.snapshot.params.id;
    this.currentAddon = addons.find(addon => Number(addon.id) === Number(id))!.app;
  }

  ngOnDestroy() {
    this.companySubscription.unsubscribe();
  }

  async ngOnInit() {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
      if (this.currentAddon.name === 'Simplicate') {
        this.refreshSimplicateSubscriptions();
      }
      if (this.currentAddon.name === 'Exact Online') {
        this.refreshExactSubscriptions();
      }
      if (this.currentAddon.name === 'Multifactor authenticatie') {
        this.getMFA();
      }
    });
  }

  async performAction(controller: string, type: 'enable' | 'synchronise' | 'delete') {
    if (type === 'enable') {
      if (controller === 'simplicate') {
        this.openSimplicateDialog();
      }
      if (controller === 'exact') {
        await this.sendToExact();
      }
      if (controller === 'mfa') {
        await this.setMFA(true);
      }
    }

    if (type === 'synchronise') {
      if (controller === 'simplicate') {
        await this.synchroniseSimplicateContacts();
      }
      if (controller === 'exact') {
        await this.synchroniseExactContacts();
      }
    }

    if (type === 'delete') {
      if (controller === 'simplicate') {
        await this.deleteSimplicateConnection();
      }
      if (controller === 'exact') {
        await this.deleteExactConnection();
      }
      if (controller === 'mfa') {
        await this.setMFA(false);
      }
    }
  }

  async refreshSimplicateSubscriptions() {
    const result = await this.simplicateService.getConnection(this.company.id);
    if (Object.keys(result).length === 0) {
      this.connectionResults = undefined;
    } else {
      this.connectionResults = result;
      this.simplicateState.labels = (await this.simplicateService.meta(this.company.id)).labels;
    }
  }

  async refreshExactSubscriptions() {
    const result: any = await this.exactService.getConnection(this.company.id);
    if (!result.exact.exists) {
      this.connectionResults = undefined;
    } else {
      this.connectionResults = result;
      //this.exactState.labels = (await this.simplicateService.meta(this.company.id)).labels;
    }
  }

  async deleteExactConnection() {
    try {
      await this.exactService.deleteConnection(this.company.id);
    } finally {
      await this.refreshExactSubscriptions();
    }
  }

  async deleteSimplicateConnection() {
    await this.simplicateService.deleteConnection(this.company.id);
    await this.refreshSimplicateSubscriptions();
  }

  async synchroniseExactContacts() {
    if (!this.exactState) {
      this.exactState = (await this.exactService.meta(this.company.id));
    }
    const dialogRef = this.dialog.open(
      ExactLabelsDialogComponent, {
        autoFocus: false,
        data: {
          meta: this.exactState,
          company: this.company
        }
      }
    );

    dialogRef.afterClosed().subscribe(async (exactMetaFilled?: ExactMetaFormModel[]) => {
      if (!exactMetaFilled) {
        return;
      }

      this.snackBar.open(this.translate.instant('addon.processing'), this.translate.instant('close'), {
        duration: 2000,
      });

      const synchroniseModel: ExactSyncModel = {
        documents: [],
        organizations: [],
        users: []
      };

      for (const exactDivision of exactMetaFilled) {
        if (exactDivision.synchronised || exactMetaFilled.length <= 1) {
          const rolesAsArray = [];
          for (const role of exactDivision.roles) {
            rolesAsArray.push(role.Code);
          }

          if (exactDivision.destinationFolder) {
            synchroniseModel.documents.push({
              division: exactDivision.divisionCode,
              folder: exactDivision.destinationFolder,
              name: 'Exact',
            });
          }

          synchroniseModel.organizations.push({
            division: exactDivision.divisionCode
          });

          synchroniseModel.users.push({
            division: exactDivision.divisionCode,
            roles: rolesAsArray
          });
        }
      }

      await this.exactService.synchronise(this.company.id, synchroniseModel);

      this.snackBar.open(this.translate.instant('addon.processing_done'), this.translate.instant('close'), {
        duration: 2000,
      });
    });
  }

  async synchroniseSimplicateContacts() {
    if (!this.simplicateState.labels) {
      this.simplicateState.labels = (await this.simplicateService.meta(this.company.id)).labels;
    }
    const dialogRef = this.dialog.open(
      SimplicateLabelsDialogComponent,
      { autoFocus: false, data: { labels: this.simplicateState.labels } }
    );

    dialogRef.afterClosed().subscribe(async (pickedLabels?: string[]) => {
      if (!pickedLabels) {
        return;
      }
      this.snackBar.open(this.translate.instant('addon.processing'), this.translate.instant('close'), {
        duration: 2000,
      });
      await this.simplicateService.synchronise(this.company.id, pickedLabels);
      this.snackBar.open(this.translate.instant('addon.processing_done'), this.translate.instant('close'), {
        duration: 2000,
      });
      this.simplicateState.labels = (await this.simplicateService.meta(this.company.id)).labels;
    });
  }

  openSimplicateDialog() {
    const companyId = this.company.id;
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { companyId },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async result => {
      await this.refreshSimplicateSubscriptions();
    });
  }

  async getMFA() {
    const company = await this.companyService.getCompanyByUrl();
    this.connectionResults = company.mfaRequired;
  }

  async setMFA(enabled: boolean) {
    const company = await this.companyService.findCurrentCompany();
    await this.companyService.editCompany(company.id, {
      mfaRequired: enabled,
      name: company.name,
      companyType: company.companyType,
      diskSpace: company.diskSpace,
      fromEmail: company.fromEmail,
      maxCustomers: company.maxCustomers,
      maxEmployees: company.maxEmployees
    });
    await this.getMFA();
  }

  async sendToExact() {
    const urls = await this.urlService.readByCompany(this.company.id);
    const primaryUrl = urls.find(url => url.isPrimary)!;
    const tokenRes = await this.userService.refresh();
    window.location.href = this.exactService.getUrl(primaryUrl.url, tokenRes.token);
  }
}
