import {Component, Input, OnInit} from '@angular/core';
import {Dossier, DossierState} from '../../../functional/models/dossier.model';

@Component({
  selector: 'app-dossier-progress',
  templateUrl: './dossier-progress.component.html',
  styleUrls: ['./dossier-progress.component.scss']
})
export class DossierProgressComponent implements OnInit {

  @Input() dossier!: Dossier;
  @Input() progress = 0;
  states: DossierState[] = [];

  constructor() {}

  ngOnInit(): void {
    this.states = Dossier.orderStates(this.dossier);
  }

}
