import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CompanyService} from '../../services/company/company.service';
import {Company} from '../../functional/models/company';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'app-company-chat-dialog',
  templateUrl: './delete-company-dialog.component.html',
  styleUrls: ['./delete-company-dialog.component.scss']
})
export class DeleteCompanyDialogComponent implements OnInit {

  company!: Company;
  nameForm!: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<DeleteCompanyDialogComponent>,
    public companyService: CompanyService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.nameForm = this.formBuilder.group({
      name: ['', Validators.required, this.nameMatches()]
    });
  }

  nameMatches(): ValidatorFn  {
    return (control: AbstractControl): ({ [key: string]: boolean } | null) => {
      if (this.company.name === control.value){
        return null;
      }
      return {nameInvalid: true} ;
    };
  }

  ngOnInit(): void {
    this.company = this.data.company;
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  async deleteChat() {
    await this.companyService.deleteCompany(this.company.id);
    this.dialogRef.close(true);
  }
}
