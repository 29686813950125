import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ResetComponent } from './reset/reset.component';
import { ForgotComponent } from './forgot/forgot.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { authRoutes } from './auth.routing';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatPasswordStrengthModule } from '../../components/mat-password-strength/mat-password-strength.module';
import { EmmaInputModule } from '../../components/base-components/emma-input/emma-input.module';
import { EmmaTooltipModule } from '../../components/base-components/emma-tooltip/emma-tooltip.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroEnvelope, heroInformationCircle, heroLockClosed } from '@ng-icons/heroicons/outline';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    BrowserAnimationsModule,
    RouterModule.forChild(authRoutes),
    MatPasswordStrengthModule,
    TranslateModule.forRoot(),
    MatCheckboxModule,
    NgIconsModule.withIcons({
      heroEnvelope,
      heroLockClosed,
      heroInformationCircle
    }),
    EmmaInputModule,
    EmmaTooltipModule,
  ],
  declarations: [LoginComponent, ResetComponent, ForgotComponent, AuthenticatorComponent]
})
export class AuthModule { }
