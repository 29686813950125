import {Routes} from '@angular/router';
import {InboxComponent} from './inbox/inbox.component';

export const messageRoutes: Routes = [
  {
    path: '',
    redirectTo: 'inbox',
    pathMatch: 'full'
  },
  {
    path: 'inbox/:id',
    component: InboxComponent,
    data: {
      breadcrumb: 'inbox'
    }
  },
  {
    path: 'inbox',
    component: InboxComponent,
    data: {
      breadcrumb: 'inbox'
    }
  },
  {
    path: 'outbox/:id',
    component: InboxComponent,
    data: {
      breadcrumb: 'outbox'
    }
  },
  {
    path: 'outbox',
    component: InboxComponent,
    data: {
      breadcrumb: 'outbox'
    }
  },
];
