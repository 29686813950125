import { AddonModel } from '../../../functional/models/addon';

const simplicateAddon: AddonModel = {
  actions: [
    {
      name: 'Aanschaffen (gratis)',
      type: 'enable',
      controller: 'simplicate',
      isInitial: true,
    },
    {
      name: 'Synchroniseer contacten',
      type: 'synchronise',
      controller: 'simplicate',
      isInitial: false,
    },
    {
      name: 'Verwijder connectie',
      type: 'delete',
      controller: 'simplicate',
      isInitial: false,
    }
  ],
  longDescription: `
    <br>
    <p>
      De koppeling met Simplicate is een manier om jouw gebruikers die in Simplicate staan te
      importeren naar MyEmma. Voer simpelweg een api sleutel en geheim in die je in Simplicate hebt
      gemaakt (link naar instructies), en druk op de knop om te synchroniseren. Wanneer een
      gebruiker later aangepast wordt in Simplicate, dan zal MyEmma nachtelijks controleren of er
      iets veranderd is, en zonodig de gebruiker aanpassen. Verder blijft het ook mogelijk om een
      synchronisatie af te dwingen door handmatig op de knop te drukken, wanneer je graag wil dat
      een wijziging direct overgekopieerd word.
      <br><br>
      Deze koppeling importeert de volgende data uit simplicate:<br>
      - Gebruikers<br>
      - Organisaties<br><br>
      Mocht het zo zijn dat u een goed idee hebt voor wat er nog meer gesynchroniseerd moet worden,
      schroom dan niet om een reactie achter te laten bij support@myemma.io.
      <br><br>
      Veel plezier met het gebruik!
    </p>`,
  image: './assets/img/logos/simplicate.svg',
  name: 'Simplicate',
  shortDescription: `
    Gebruik de koppeling met Simplicate om snel en eenvoudig je relaties en organisaties te
    importeren. Geen handmatig overtypen van data die al bekend is, en automatische updates wanneer
    je iets aanpast in Simplicate.`,
  comingSoon: false,
  tags: ['Beta'],
};

export default simplicateAddon;
