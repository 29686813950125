import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { companyRoutes } from './companies.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UrlSelectorModule } from '../../../components/url-selector/url-selector.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { DeleteCompanyDialogComponent } from '../../../dialogs/delete-company-dialog/delete-company-dialog.component';
import { SubNavModule } from '../../../components/sub-nav/sub-nav.module';
import { NgIconsModule } from '@ng-icons/core';
import { EmmaInputModule } from '../../../components/base-components/emma-input/emma-input.module';
import { EmmaSelectModule } from '../../../components/base-components/emma-select/emma-select.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(companyRoutes),
    MatTableModule,
    MatButtonModule,
    MatRippleModule,
    MatTooltipModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatCheckboxModule,
    UrlSelectorModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    SubNavModule,
    NgIconsModule,
    EmmaInputModule,
    EmmaSelectModule,
  ],
  declarations: [CompanyDetailsComponent, CompanyFormComponent, CompanyListComponent, DeleteCompanyDialogComponent]
})
export class CompaniesModule { }
