import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import { CreateBusinessCustomerDialogComponent } from './create-business-customer-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatRippleModule} from '@angular/material/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {SearchCompaniesModule} from '../../../components/search/search-companies/search-companies.module';
import {SearchKvkModule} from '../../../components/search/search-kvk/search-kvk.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SearchCustomersModule} from '../../../components/search/search-customers/search-customers.module';
import localeNDSNL from '@angular/common/locales/nds-NL';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {SearchCustomersAndEmployeesModule}
  from '../../../components/search/search-customers-and-employees/search-customers-and-employees.module';
import {EmmaInputModule} from '../../../components/base-components/emma-input/emma-input.module';
import {EmmaSelectModule} from '../../../components/base-components/emma-select/emma-select.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroCalendar } from '@ng-icons/heroicons/outline';
registerLocaleData(localeNDSNL);
@NgModule({
    declarations: [CreateBusinessCustomerDialogComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatRadioModule,
        MatRippleModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        SearchCompaniesModule,
        SearchKvkModule,
        TranslateModule.forRoot(),
        ReactiveFormsModule,
        MatSelectModule,
        MatDatepickerModule,
        SearchCustomersModule,
        MatCheckboxModule,
        NgIconsModule.withIcons({
            heroCalendar
        }),
        SearchCustomersAndEmployeesModule,
        EmmaInputModule,
        EmmaSelectModule,
    ],
    providers: [
        DatePipe
    ]
})
export class CreateBusinessCustomerDialogModule { }
