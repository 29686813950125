<button
  mat-button class="document-item"
  [id]="folder.id"
  [ngClass]="{'selected': isSelected(), 'highlighted': highlighted && folder.id === highlighted.id}"
  [appEmmaTooltip]="folder.name"
  (click)="triggerClick()">
  <mat-checkbox [disableRipple]="true" class="checkbox-position" (click)="changeSelection($event)" [checked]="isSelected()"></mat-checkbox>
  <mat-icon
    class="folder document-custom">
    folder
  </mat-icon>
  <span class="title">{{ folder.name }}</span>
</button>
