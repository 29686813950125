import {AfterViewInit, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Company} from '../../../functional/models/company';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NewOrganization, Organization} from '../../../functional/models/organization.model';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {OrganizationService} from '../../../services/organization/organization.service';
import {User} from '../../../functional/models/user';
import {UserService} from '../../../services/user/user.service';
import {DatePipe} from '@angular/common';
import {SearchCustomersComponent} from '../../../components/search/search-customers/search-customers.component';

@Component({
  selector: 'app-create-business-customer-dialog',
  templateUrl: './create-business-customer-dialog.component.html',
  styleUrls: ['./create-business-customer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateBusinessCustomerDialogComponent implements OnInit, AfterViewInit {
  disableAnimation = true;
  organizationForm!: UntypedFormGroup;
  company?: Company;
  customerSearch = false;
  organizationSearch = false;
  organizationEdit = true;
  currentOrganization!: Organization;
  allowEmployees = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private organizationService: OrganizationService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<CreateBusinessCustomerDialogComponent>,
    private userService: UserService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.company = data.company;
    this.organizationSearch = data.organizationSearch;
    this.organizationEdit = data.organizationEdit;
    this.currentOrganization = data.organization;
    this.allowEmployees = data.allowEmployees;
  }

  async ngOnInit(): Promise<void> {
    this.organizationForm = this.formBuilder.group({
      id: [this.currentOrganization?.id, []],
      name: [this.currentOrganization?.name || '', [Validators.required]],
      fiscalNumber: [this.currentOrganization?.fiscalNumber || '', []],
      kvk: [this.currentOrganization?.kvk || '', []],
      postalCode: [this.currentOrganization?.postalCode || '', []],
      street: [this.currentOrganization?.street || '', []],
      houseNumber: [this.currentOrganization?.houseNumber || '', []],
      premise: [this.currentOrganization?.premise || '', []],
      city: [this.currentOrganization?.city || '', []],
      companyId: [this.company!.id, []],
      relationId: [this.currentOrganization?.relationId],
      users: new UntypedFormArray([]),
    });

    for (const user of this.data?.users || []){
      this.newUser(user);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation = false);
  }

  get f() { return this.organizationForm.controls; }
  get t() { return this.f.users as UntypedFormArray; }

  newUser(user?: User) {
    this.t.push(this.formBuilder.group({
      id: [user?.id, []],
      role: [user?.role || 3 , []],
      description: [user?.description || '', []],
      companyId: [user?.companyId || this.company!.id, []],
      firstName: [user?.firstName, Validators.required],
      lastName: [user?.lastName, Validators.required],
      email: [user?.email, [Validators.required, Validators.email]],
      phone: [user?.phone || '', []],
      gender: [user?.gender || 0, []],
      street: [user?.street || '', []],
      postalCode: [user?.postalCode || '', []],
      premise: [user?.premise || '', []],
      houseNumber: [user?.houseNumber || '', []],
      city: [user?.city || '', []],
      birth: [user?.birth || '', []],
      relationId: [user?.relationId, []],
      sendMail: [!user?.id, []]
    }));
  }

  async onSubmit() {
    this.organizationForm.markAllAsTouched();

    for (const form of this.t.controls) {
      if (form.invalid) {
        form.markAllAsTouched();
      }
    }

    if (this.organizationForm.invalid) {
      return;
    }

    const updatedUsers = [];

    for (const user of this.organizationForm.value.users) {
      const parsedUser = user as User;

      if (parsedUser.birth) {
        parsedUser.birth = this.datePipe.transform(parsedUser.birth, 'yyyy-MM-dd');
      }

      if (parsedUser.id) {
        const sanitizedUser: any = {...parsedUser};
        delete sanitizedUser.id;

        updatedUsers.push(await this.userService.editUser(parsedUser.id, sanitizedUser));
      } else {
        updatedUsers.push(await this.userService.createUser(parsedUser, user.sendMail));
      }
    }

    const organizationForm = this.organizationForm.value;
    delete organizationForm.users;

    let organization: Organization;
    if (this.organizationEdit) {
      if (!organizationForm.id) {
        organization = await this.organizationService.create(organizationForm as NewOrganization);
      } else {
        organization = await this.organizationService.update(organizationForm as Organization);
      }
    } else {
      organization = this.organizationForm.value as Organization;
    }

    if (organization) {
      for (const user of updatedUsers) {
        try {
          await this.organizationService.addUser(organization.id!, user.id!);
        } catch {}
      }
    }
    if (this.organizationEdit) {
      this.snackBar.open(
        this.translateService.instant('organization.success'),
        this.translateService.instant('close'),
        {duration: 5000},
      );
    } else {
      this.snackBar.open(
        this.translateService.instant('organization.users_added'),
        this.translateService.instant('close'),
        { duration: 5000 },
      );
    }
    this.dialogRef.close( {data: {organization, users: updatedUsers}});
  }

  onUserSelected(user: User | undefined, search: SearchCustomersComponent) {
    if (user) {
      const find = this.organizationForm.value.users.find((u: User) => u.id === user.id);
      if (!find) {
        this.newUser(user);
      }
      this.customerSearch = false;
      search.clearSelected();
    }
  }

  deleteUser(i: number) {
    this.t.removeAt(i);
  }

  fillFields(event: any) {
    const company = event.data.items[0];
    this.organizationForm.controls.name.setValue(company.tradeNames.businessName);
    this.organizationForm.controls.kvk.setValue(company.kvkNumber);
    this.organizationForm.controls.postalCode.setValue(company.addresses[0].postalCode);
    this.organizationForm.controls.street.setValue(company.addresses[0].street);
    this.organizationForm.controls.houseNumber.setValue(company.addresses[0].houseNumber);
    this.organizationForm.controls.premise.setValue(company.addresses[0].houseNumberAddition);
    this.organizationForm.controls.city.setValue(company.addresses[0].city);
  }
}
