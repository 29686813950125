import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchGroupsComponent } from './search-groups.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {AutocompleteSelectModule} from '../../base-components/autocomplete-select/autocomplete-select.module';

@NgModule({
  declarations: [
    SearchGroupsComponent
  ],
  exports: [
    SearchGroupsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    TranslateModule.forRoot(),
    MatButtonModule,
    AutocompleteSelectModule,
    FormsModule
  ]
})
export class SearchGroupsModule { }
