import { Component, OnInit } from '@angular/core';
import { CssTemplateService } from 'app/services/css-template/css-template.service';
import { CssTemplate } from 'app/functional/models/css-template.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-css-input',
  templateUrl: './css-input.component.html',
  styleUrls: ['./css-input.component.scss']
})
export class CssInputComponent implements OnInit {
  cssTemplates: CssTemplate[] = [];
  formGroups: UntypedFormGroup[] = [];
  newFormGroup!: UntypedFormGroup;

  constructor(
    private cssTemplateService: CssTemplateService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    this.newFormGroup = this.formBuilder.group({
      content: ['', [Validators.required]],
    });
    this.cssTemplates = await this.cssTemplateService.readAll();
    for (const cssTemplate of this.cssTemplates) {
      this.addForm(cssTemplate);
    }
  }

  async update(index: number) {
    if (this.formGroups[index].invalid) {
      return;
    }
    const cssTemplate = {
      id: +this.formGroups[index].get('id')!.value,
      content: this.formGroups[index].get('content')!.value,
    };
    try {
      await this.cssTemplateService.update(cssTemplate);
      this.snackBar.open('Template is aangepast!', '', { duration: 5000 });
    } catch { }
  }

  async create() {
    if (this.newFormGroup.invalid) {
      this.snackBar.open('Voer eerst een template in', 'sluiten', {duration: 5000});
      return;
    }
    const newCssTemplate = {
      content: this.newFormGroup.get('content')!.value,
    };
    try {
      const template = await this.cssTemplateService.create(newCssTemplate);
      this.snackBar.open('Nieuwe template is aangemaakt!', '', {duration: 5000});
      this.cssTemplates.push(template);
      this.addForm(template);
      this.newFormGroup.reset();
    } catch { }
  }

  async delete(index: number) {
    if (this.formGroups[index].invalid) {
      return;
    }
    const id: number = +this.formGroups[index].get('id')!.value;
    try {
      await this.cssTemplateService.delete(id);
      this.snackBar.open('RIP template', '', { duration: 5000 });
      this.cssTemplates = await this.cssTemplateService.readAll();
    } catch { }
  }

  private addForm(cssTemplate: CssTemplate): void {
    this.formGroups.push(this.formBuilder.group({
      id: [cssTemplate.id, [Validators.required]],
      content: [cssTemplate.content, [Validators.required]],
    }));
  }
}
