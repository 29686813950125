import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {
  CloneDossierTemplate,
  CreateDossierActionTemplate, CreateDossierPermissionTemplate,
  CreateDossierStateTemplate, CreateDossierTemplate,
  CreateFileRequestTemplate, CreateTemplateFolder,
  DossierActionTemplate, DossierPermissionTemplate, DossierPermissionTemplatePartial,
  DossierStateTemplate,
  DossierTemplate,
  DossierTemplateOverview,
  FileRequestTemplate,
  TemplateFolder
} from 'app/functional/models/dossier-template.model';

@Injectable({
  providedIn: 'root'
})
export class DossierTemplateService {
  private url = `${environment.rootUrl}dossier_template`;

  constructor(
    private http: HttpClient,
  ) { }

  readAll(companyId?: number): Promise<DossierTemplateOverview[]> {
    let addition = '';
    if (companyId) {
      addition = `?company_id=${companyId}`;
    }
    return this.http.get<DossierTemplateOverview[]>(`${this.url}${addition}`).toPromise();
  }

  create(model: CreateDossierTemplate): Promise<DossierTemplate> {
    return this.http.post<DossierTemplate>(`${this.url}`, model).toPromise();
  }

  clone(dossierTemplateId: number, model: CloneDossierTemplate): Promise<DossierTemplate> {
    return this.http.post<DossierTemplate>(`${this.url}/${dossierTemplateId}/copy`, model).toPromise();
  }

  getRequests(dossierTemplateId: number): Promise<FileRequestTemplate[]> {
    return this.http.get<FileRequestTemplate[]>(`${this.url}/${dossierTemplateId}/file_request`).toPromise();
  }

  createRequest(dossierTemplateId: number, model: CreateFileRequestTemplate): Promise<FileRequestTemplate> {
    return this.http.post<FileRequestTemplate>(`${this.url}/${dossierTemplateId}/file_request`, model).toPromise();
  }

  deleteRequest(dossierTemplateId: number, templateFilerequestId: number) {
    return this.http.delete(`${this.url}/${dossierTemplateId}/file_request/${templateFilerequestId}`).toPromise();
  }

  getFolders(dossierTemplateId: number): Promise<TemplateFolder[]> {
    return this.http.get<TemplateFolder[]>(`${this.url}/${dossierTemplateId}/folder`).toPromise();
  }

  createFolder(dossierTemplateId: number, model: CreateTemplateFolder): Promise<TemplateFolder> {
    return this.http.post<TemplateFolder>(`${this.url}/${dossierTemplateId}/folder`, model).toPromise();
  }

  updateFolder(dossierTemplateId: number, folderId: number, model: CreateTemplateFolder): Promise<TemplateFolder> {
    return this.http.put<TemplateFolder>(`${this.url}/${dossierTemplateId}/folder/${folderId}`, model).toPromise();
  }

  deleteFolder(dossierTemplateId: number, templateFolderId: number) {
    return this.http.delete(`${this.url}/${dossierTemplateId}/folder/${templateFolderId}`).toPromise();
  }

  getStates(dossierTemplateId: number): Promise<DossierStateTemplate[]> {
    return this.http.get<DossierStateTemplate[]>(`${this.url}/${dossierTemplateId}/state`).toPromise();
  }

  createState(dossierTemplateId: number, model: CreateDossierStateTemplate): Promise<DossierStateTemplate> {
    return this.http.post<DossierStateTemplate>(`${this.url}/${dossierTemplateId}/state`, model).toPromise();
  }

  updateState(dossierTemplateId: number, templateStateId: number, model: CreateDossierStateTemplate){
    return this.http.put<DossierStateTemplate>(`${this.url}/${dossierTemplateId}/state/${templateStateId}`, model).toPromise();
  }

  deleteState(dossierTemplateId: number, templateStateId: number) {
    return this.http.delete(`${this.url}/${dossierTemplateId}/state/${templateStateId}`).toPromise();
  }

  getStateActions(dossierTemplateId: number, templateStateId: number): Promise<DossierActionTemplate[]> {
    return this.http.get<DossierActionTemplate[]>(`${this.url}/${dossierTemplateId}/state/${templateStateId}/action`).toPromise();
  }

  createStateAction(
    dossierTemplateId: number,
    templateStateId: number,
    model: CreateDossierActionTemplate
  ): Promise<DossierActionTemplate> {
    return this.http.post<DossierActionTemplate>(`${this.url}/${dossierTemplateId}/state/${templateStateId}/action`, model).toPromise();
  }

  updateStateAction(
    dossierTemplateId: number,
    templateStateId: number,
    actionId: number,
    model: CreateDossierActionTemplate
  ) {
    return this.http.put<DossierActionTemplate>(
      `${this.url}/${dossierTemplateId}/state/${templateStateId}/action/${actionId}`,
      model
    ).toPromise();
  }

  deleteStateAction(dossierTemplateId: number, templateStateId: number, templateActionId: number) {
    return this.http.delete(`${this.url}/${dossierTemplateId}/state/${templateStateId}/action/${templateActionId}`).toPromise();
  }

  async getPermissionChange(dossierTemplateId: number, templateStateId: number): Promise<DossierPermissionTemplate[]> {
    const fullPermissions: DossierPermissionTemplate[] = [];
    const partialPermissions = await this.http.get<DossierPermissionTemplatePartial[]>(
      `${this.url}/${dossierTemplateId}/state/${templateStateId}/permission_change`
    ).toPromise();

    for (const partialPermission of partialPermissions){
      fullPermissions.push({...partialPermission, permissionString: partialPermission.permissions});
    }

    return fullPermissions;
  }

  createPermissionChange(
    dossierTemplateId: number,
    templateStateId: number,
    model: CreateDossierPermissionTemplate
  ): Promise<DossierPermissionTemplate> {
    return this.http.post<DossierPermissionTemplate>(
      `${this.url}/${dossierTemplateId}/state/${templateStateId}/permission_change`,
      model
    ).toPromise();
  }

  deletePermissionChange(dossierTemplateId: number, templateStateId: number, permissionChangeId: number) {
    this.http.delete(`${this.url}/${dossierTemplateId}/state/${templateStateId}/permission_change/${permissionChangeId}`).toPromise();
  }

  updatePermissionChange(
    dossierTemplateId: number,
    templateStateId: number,
    permissionChangeId: number,
    model: CreateDossierPermissionTemplate
  ) {
    return this.http.post<DossierPermissionTemplate>(
      `${this.url}/${dossierTemplateId}/state/${templateStateId}/permission_change/${permissionChangeId}`,
      model
    ).toPromise();
  }

  async get(dossierTemplateId: number): Promise<DossierTemplate> {
    return this.http.get<DossierTemplate>(`${this.url}/${dossierTemplateId}`).toPromise();
  }
}
