import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import {
  CreateMailParameter, MailParameter, UpdateMailParameter,
} from 'app/functional/models/mail-parameter.model';

@Injectable({
  providedIn: 'root'
})
export class MailParameterService {
  private url = `${environment.rootUrl}mail_parameter`;

  constructor(
    private http: HttpClient,
  ) { }

  create(createMailParameter: CreateMailParameter[]): Promise<MailParameter[]> {
    return this.http.post<MailParameter[]>(this.url, createMailParameter).toPromise();
  }

  filter(filter: { companyId?: number; version?: number }): Promise<MailParameter[]> {
    let params = new HttpParams();
    if (filter.companyId) {
      params = params.set('company_id', filter.companyId.toString());
    }
    if (filter.version) {
      params = params.set('version', filter.version.toString());
    }
    return this.http.get<MailParameter[]>(this.url, { params }).toPromise();
  }

  /**
   * Returns the mail parameters that are used when no parameters are configured by the company.
   */
  default(): Promise<MailParameter[]> {
    return this.http.get<MailParameter[]>(this.url + '/default').toPromise();
  }

  update(id: number, mailParameter: UpdateMailParameter): Promise<MailParameter> {
    return this.http.put<MailParameter>(`${this.url}/${id}`, mailParameter).toPromise();
  }

  delete(mailParameter: MailParameter): Promise<void> {
    return this.http.delete<void>(`${this.url}/${mailParameter.id}`).toPromise();
  }
}
