<div class="card" id="inbox">
  <div class="card-header card-header-grey card-header-custom d-flex justify-content-between align-items-center">
    <div>
      <h4 class="card-title card-title-custom">{{'dashboard.inbox_title' | translate}}</h4>
      <p class="card-category card-category-custom">{{'dashboard.inbox_subtitle' | translate}}</p>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <button mat-button class="nav-link card-title-custom" routerLink="/messaging/inbox">
          <mat-icon>drafts</mat-icon> {{'dashboard.inbox_new' | translate}}

        </button>
      </li>
    </ul>
  </div>
  <div class="card-body d-flex table-responsive justify-content-center" *ngIf="inbox.length == 0">
    <div class="alert alert-grey alert-with-icon col-md-12 justify-content-center">
      <mat-icon>info</mat-icon>
      <span data-notigy="message">{{'dashboard.inbox_none' | translate}}</span>
    </div>
  </div>
  <div class="card-body table-responsive" *ngIf="inbox.length > 0">
    <div class="table-container">
      <table mat-table [dataSource]="inboxSource" matSort class="emma-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'dashboard.inbox_message' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <h4 [ngClass]="{'bold': !row.read}">
              {{row.subject}}
            </h4>
            <p class="no-margin">
              {{ row.sender.firstName + ' ' + row.sender.lastName + ' - ' + (row.creationDate | date: 'dd/MM/yy') }}
            </p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['name']; sticky: true" ></tr>
        <tr mat-row class="element" *matRowDef="let row; columns: ['name'];" (click)="selectMail(row)"></tr>
      </table>
    </div>
  </div>
</div>
