import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerInformationDialogComponent } from './customer-information-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import { NgIconsModule } from '@ng-icons/core';



@NgModule({
    declarations: [CustomerInformationDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        TranslateModule.forRoot(),
        NgIconsModule,
    ]
})
export class CustomerInformationDialogModule { }
