import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Document } from 'app/functional/models/document';
import {SelectionModel} from '@angular/cdk/collections';
import {ExplorerItem} from 'app/functional/models/explorer.model';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
  @Input() document!: Document;
  @Input() selected?: SelectionModel<ExplorerItem>;
  @Output() selectChange = new EventEmitter();
  @Output() clickChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (!this.document) {
      throw new Error('`document` is required for `DocumentComponent`');
    }
  }

  getIcon(): string {
    switch (this.document.extension.split('.').join('')) {
      case 'jpg': return 'photo';
      case 'jpeg': return 'photo';
      case 'png': return 'photo';
      case 'mp4': return 'local_movies';
      case 'mov': return 'local_movies';
      default: return 'insert_drive_file';
    }
  }

  getIconColor(): string {
    switch (this.document.extension.split('.').join('')) {
      case 'pdf': return 'pdf';

      case 'docx': return 'word';
      case 'doc': return 'word';
      case 'dot': return 'word';
      case 'dotx': return 'word';
      case 'docb': return 'word';
      case 'docm': return 'word';
      case 'dotm': return 'word';

      case 'exe': return 'exe';

      case 'xls': return 'excel';
      case 'xlt': return 'excel';
      case 'xlm': return 'excel';
      case 'xlsx': return 'excel';
      case 'xltx': return 'excel';
      case 'xlmx': return 'excel';

      case 'pps': return 'powerpoint';
      case 'pptx': return 'powerpoint';

      case 'pub': return 'publisher';
      default: return 'primary file-custom';
    }
  }

  isSelected() {
    return !!this.selected?.selected.find(item => item.file && item.file.id === this.document.id);
  }

  changeSelection(event: Event) {
    this.selectChange.emit(this.document);
    event.stopPropagation();
    event.preventDefault();
  }

  triggerClick() {
    this.clickChange.emit(this.document);
  }
}
