import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-delete-organization-user-dialog',
  templateUrl: './confirm-delete-organization-user-dialog.component.html',
  styleUrls: ['./confirm-delete-organization-user-dialog.component.scss']
})
export class ConfirmDeleteOrganizationUserDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteOrganizationUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  deleteFiles() {
    this.dialogRef.close(true);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
