import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KvkService {

  private url = `${environment.rootUrl}organization`;

  constructor(
    private http: HttpClient,
  ) { }

  getCompany(kvkNummer: number) {
    let params = new HttpParams();
    params = params.set('kvk_id', kvkNummer.toString());
    return this.http.get(`${this.url}/kvk`, { params }).toPromise();
  }
}
