import { Component, OnInit } from '@angular/core';
import { Message } from 'app/functional/models/message';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { MessageService } from 'app/services/message/message.service';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-inbox-overview',
  templateUrl: './inbox-overview.component.html',
  styleUrls: ['./inbox-overview.component.scss']
})
export class InboxOverviewComponent implements OnInit {

  inbox: Message[] = [];
  inboxSource!: MatTableDataSource<Message>;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    const claims = this.userService.tryClaims();
    const unsortedMessages: Message[] = await this.messageService.getInbox(claims.userId);
    this.inbox = unsortedMessages
        .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
    this.inboxSource = new MatTableDataSource(this.inbox);
  }

  async selectMail(row: Message) {
    await this.router.navigate(['messaging', 'inbox', row.id]);
  }
}
