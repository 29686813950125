<div class="handle" cdkDragHandle>
  <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
    <path d="M0 0h24v24H0z" fill="none"></path>
  </svg>
</div>
<div class="options">
<!--  <mat-icon>unfold_less</mat-icon>-->
  <mat-icon>keyboard_arrow_up</mat-icon>
  <mat-icon>keyboard_arrow_down</mat-icon>
  <mat-icon>content_copy</mat-icon>
  <mat-icon>delete</mat-icon>
</div>
<div class="survey-question">
  <div style="margin-top: 20px">
    <div style="display: flex;">
      <mat-form-field style="flex: 1;" class="minor-margin-right">
        <mat-label>Vraag</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>

      <mat-form-field style="flex: 0">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="type">
          <mat-option [value]="1">Tekst</mat-option>
          <mat-option [value]="2">Cijfer</mat-option>
          <mat-option [value]="3">datum</mat-option>
          <mat-option [value]="4">Enkele keuze</mat-option>
          <mat-option [value]="5">Meerkeuze</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="type === 1">
      <mat-form-field>
        <input type="text" matInput placeholder="Antwoord" disabled/>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="type === 2">
      <mat-form-field>
        <input type="tel" matInput placeholder="Antwoord" disabled/>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="type === 3">
      <mat-form-field>
        <mat-label>Kies een datum</mat-label>
        <input matInput [matDatepicker]="picker" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
        <mat-datepicker #picker disabled></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="type === 4">
      <div class="survey-input" *ngFor="let question of questions">
        <div class="split-input" [ngClass]="{'minor-margin-right': conditional}">
          <mat-icon>radio_button_unchecked</mat-icon>
          <mat-form-field>
            <input matInput placeholder="Antwoord"/>
          </mat-form-field>
        </div>

        <mat-form-field style="flex: 0;" *ngIf="conditional">
          <mat-label>Ga naar pagina</mat-label>
          <mat-select [(ngModel)]="type" style="margin-top: 0;">
            <mat-option [value]="1">Pagina 1</mat-option>
            <mat-option [value]="2">Pagina 2</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 5">
      <div class="survey-input split-input" *ngFor="let question of questions">
        <mat-icon>check_box_outline_blank</mat-icon>
        <mat-form-field>
          <input matInput placeholder="Antwoord"/>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="bottom-row-buttons">
      <button  (click)="questions.push('yeet')" *ngIf="type !== 1 && type !== 2 && type !== 3">Optie toevoegen</button>
      <mat-slide-toggle [ngClass]="{'slide-margin': type !== 1 && type !== 2 && type !== 3}">Verplicht</mat-slide-toggle>
      <mat-slide-toggle name="conditional" [(ngModel)]="conditional" *ngIf="type === 4" class="slide-margin">Conditioneel</mat-slide-toggle>
    </div>
  </div>
</div>
