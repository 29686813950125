<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Naam</mat-label>
    <mat-hint>Geef naam plz</mat-hint>
    <input matInput formControlName="name">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Waarde</mat-label>
    <mat-hint>Geef waarde plz</mat-hint>
    <input matInput formControlName="value">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Taal</mat-label>
    <mat-hint>Geef taal plz</mat-hint>
    <input matInput formControlName="language">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Versie</mat-label>
    <mat-hint>Geef versie plz</mat-hint>
    <input matInput formControlName="version">
  </mat-form-field>

  <button type="button"  (click)="create()">Aanmaken</button>
</form>

<form *ngFor="let paramForm of paramForms" [formGroup]="paramForm" class="param-form">
  <div class="readonly">
    <mat-form-field class="param-narrow">
      <mat-label>Id</mat-label>
      <input matInput readonly formControlName="id">
    </mat-form-field>

    <mat-form-field class="param-narrow">
      <mat-label>Naam</mat-label>
      <input matInput readonly formControlName="name">
    </mat-form-field>

    <mat-form-field class="param-narrow">
      <mat-label>Taal</mat-label>
      <input matInput readonly formControlName="language">
    </mat-form-field>

    <mat-form-field class="param-narrow">
      <mat-label>Versie</mat-label>
      <input matInput readonly formControlName="version">
    </mat-form-field>
  </div>

  <div class="writable">
    <mat-form-field class="param-wide">
      <mat-label>Waarde</mat-label>
      <input matInput formControlName="value">
    </mat-form-field>
  </div>
  <button  type="button" (click)="updateParam(paramForm)">
    Aanpassen
  </button>
  <hr>
</form>
