import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateDossierComponent } from './create-dossier/create-dossier.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {generalRoutes} from '../general/general.routing';
import {DossierConfigurationModule} from '../../components/dossiers/dossier-configuration/dossier-configuration.module';
import {DossierCustomerSelectorModule} from '../../components/dossiers/dossier-customer-selector/dossier-customer-selector.module';
import {DossierTemplateSelectorModule} from '../../components/dossiers/dossier-template-selector/dossier-template-selector.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DossierDetailsComponent } from './dossier-details/dossier-details.component';
import {DossierFolderModule} from '../../components/dossiers/dossier-folder/dossier-folder.module';
import {ConfirmDialogModule} from '../../dialogs/dossiers/confirm-dialog/confirm-dialog.module';
import {MatIconModule} from '@angular/material/icon';
import {CreateFolderDialogModule} from '../../dialogs/dossiers/create-folder-dialog/create-folder-dialog.module';
import {DossierOverviewModule} from '../../components/dossiers/dossier-overview/dossier-overview.module';
import { DossierDashboardComponent } from './dossier-dashboard/dossier-dashboard.component';
import {DossierProgressModule} from '../../components/dossiers/dossier-progress/dossier-progress.module';
import {CustomerInformationDialogModule} from '../../dialogs/dossiers/customer-information-dialog/customer-information-dialog.module';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {FormsModule} from '@angular/forms';
import {SubNavModule} from '../../components/sub-nav/sub-nav.module';
import {ConnectedUsersModule} from '../../components/connected-users/connected-users.module';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {RecurringSettingsModule} from '../../components/recurring-settings/recurring-settings.module';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {EmmaSelectModule} from '../../components/base-components/emma-select/emma-select.module';
import { NgIconsModule } from '@ng-icons/core';
import {heroArrowPath, heroCalendar, heroFolderOpen, heroSquare3Stack3d, heroUser } from '@ng-icons/heroicons/outline';

@NgModule({
  declarations: [CreateDossierComponent, DossierDetailsComponent, DossierDashboardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    DossierFolderModule,
    ConfirmDialogModule,
    DossierOverviewModule,
    CreateFolderDialogModule,
    DossierConfigurationModule,
    DossierCustomerSelectorModule,
    MatCheckboxModule,
    DossierTemplateSelectorModule,
    TranslateModule.forRoot(),
    RouterModule.forChild(generalRoutes),
    DossierProgressModule,
    CustomerInformationDialogModule,
    FormsModule,
    SubNavModule,
    ConnectedUsersModule,
    NgIconsModule.withIcons({
      heroSquare3Stack3d,//Collection
      heroArrowPath,//Refresh
      heroUser,
      heroCalendar,
      heroFolderOpen
    }),
    MatMenuModule,
    RecurringSettingsModule,
    MatSlideToggleModule,
    EmmaSelectModule
  ],
})
export class DossierModule { }
