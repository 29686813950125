<div class="dossier-folder" id="folder-container">
  <input multiple #file type="file" class="hidden" (change)="getFileDetails($event)"/>
  <div class="card uncollapsed">
    <div class="card-header">
      <div class="title-between">
        <h4 class="card-title">{{ folderContent.name }}</h4>
        <ng-container *ngIf="claims!.role < 3 && ((folder!.permission!.includes('m') || (folder!.permission!.includes('d')) && !dossier.dossierState?.finalState))">
          <app-emma-select [renderButton]="false" #menu>
            <button mat-icon-button button>
              <ng-icon class="ng-icon-default" name="heroEllipsisVertical" (click)="menu.toggleMenu()"></ng-icon>
            </button>

            <ng-container body>
              <span class="no-select"
                    *ngIf="folder!.permission!.includes('m')"
                    (click)="downloadFolder(); menu.toggleMenu()">{{'dossier.download' | translate}}</span>
              <span class="no-select"
                    *ngIf="folder!.permission!.includes('m')"
                    (click)="openEditFolderDialog(); menu.toggleMenu()">{{'dossier.instellingen' | translate}}</span>
              <span class="no-select"
                    *ngIf="folder!.permission!.includes('d') && !dossier.dossierState?.finalState"
                    (click)="deleteFolder(); menu.toggleMenu()">{{'dossier.verwijderen' | translate}}</span>
            </ng-container>
          </app-emma-select>
        </ng-container>
      </div>
    </div>
    <div class="card-body table-responsive dossier-flex">
      <ngx-file-drop (onFileDrop)="dropped($event)" [disabled]="!folder!.permission!.includes('w')" style="width: 100%;">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" style="width: 100%;">
        <div class="card-body d-flex table-responsive justify-content-center absolute" *ngIf="!countRequests(fileRequests) && files.length <= 0">
          <div class="alert alert-grey alert-with-icon col-md-12 no-shadow">
            <ng-icon class="ng-icon-default-size" name="information-circle"></ng-icon>
            <span data-notigy="message">{{folder.description}}</span>
          </div>
        </div>
      <mat-list class="list-container">
        <div mat-subheader *ngIf="hasRejected(files)">{{ 'dossier.afgekeurd' | translate }}</div>
        <ng-container *ngFor="let file of files">
          <mat-list-item class="custom-list-item" *ngIf="isRejected(file)">
            <ng-icon mat-list-icon class="ng-icon-default-size" name="heroXMark"></ng-icon>
            <div mat-line>
              <div class="name-container">
                <span class="emma-font-default name-60-container" (click)="displayFile(file)">{{file.name}}</span>
                <div class="items-container">
                  <span class="emma-font-red">{{'dossier.denied_message' | translate}}</span>
                  <span (click)="openReasonDialog(file)" class="emma-font-underlined">{{'dossier.view_reason' | translate}}</span>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <app-emma-select [renderButton]="false" #itemMenu>
              <button mat-icon-button (click)="itemMenu.toggleMenu()" button>
                <ng-icon class="ng-icon-default" name="heroEllipsisVertical"></ng-icon>
              </button>

              <ng-container body>
                <span class="no-select"
                  (click)="startDownload(file, downloadLink); itemMenu.toggleMenu()">
                  {{'viewer.download' | translate}}
                </span>
                <span class="no-select"
                  *ngIf="folder!.permission!.includes('m')"
                  (click)="displayFile(file); itemMenu.toggleMenu()">
                  {{'dossier.weergeven' | translate}}</span>
                <span class="no-select"
                  *ngIf="folder!.permission!.includes('d') && !dossier.dossierState?.finalState"
                  (click)="deleteFile(file); itemMenu.toggleMenu()">
                  {{'dossier.verwijderen' | translate}}
                </span>
              </ng-container>
            </app-emma-select>
          </mat-list-item>
        </ng-container>

        <div mat-subheader *ngIf="countRequests(fileRequests)">{{ 'dossier.aanleveren' | translate }}</div>
        <ng-container *ngFor="let request of fileRequests">
          <mat-list-item *ngIf="!request.fileId">
            <div mat-line style="cursor: pointer" (click)="openFilerequestFulfillDialog(request, $event)">{{request.filename}}</div>
            <ng-icon [appEmmaTooltip]="request.message" *ngIf="request.message" style="color: rgba(55, 58, 68, 1); cursor: pointer" class="ng-icon-default-size" name="heroInformationCircle"></ng-icon>
            <button mat-icon-button (click)="openFilerequestFulfillDialog(request, $event)" *ngIf="uploadable">
              <ng-icon class="ng-icon-default" name="heroDocumentPlus"></ng-icon>
            </button>
            <button style="z-index: 1;" mat-icon-button (click)="deleteFileRequest(request, $event)" *ngIf="!dossier.dossierState?.finalState && claims!.role < 3">
              <ng-icon class="ng-icon-default" name="heroTrash"></ng-icon>
            </button>
          </mat-list-item>
        </ng-container>

        <div mat-subheader *ngIf="fileRequests && files.length > 0">{{ 'dossier.aangeleverd' | translate }}</div>
        <ng-container *ngFor="let file of files">
          <mat-list-item *ngIf="!isRejected(file)" class="custom-list-item">
            <ng-icon mat-list-icon class="ng-icon-default-size" name="heroDocumentText" style="margin-top: -5px;"></ng-icon>
            <div mat-line (click)="displayFile(file)">{{file.name}}</div>


            <ng-icon *ngIf="needsSigning(file)" class="ng-icon-default-size" style="color: orange" name="heroPencilSquare"></ng-icon>
            <ng-icon *ngIf="isSigned(file)" class="ng-icon-default-size" style="color: #00a31e" name="heroCheck"></ng-icon>
            <app-emma-select [renderButton]="false" #itemMenu>
              <ng-container button>
                <button mat-icon-button (click)="itemMenu.toggleMenu()">
                  <ng-icon class="ng-icon-default" name="heroEllipsisVertical"></ng-icon>
                </button>
              </ng-container>

              <ng-container body>
                <span class="no-select"
                  (click)="startDownload(file, downloadLink); itemMenu.toggleMenu()">
                  {{'viewer.download' | translate}}
                </span>
                <span class="no-select"
                  *ngIf="folder!.permission!.includes('m')"
                  (click)="displayFile(file); itemMenu.toggleMenu()">
                  {{'dossier.weergeven' | translate}}</span>
                <span class="no-select"
                  *ngIf="isSigned(file)"
                  (click)="displaySignatures(file); itemMenu.toggleMenu()">
                  {{'dossier.signings' | translate }}
                </span>
                <span class="no-select"
                  *ngIf="folder!.permission!.includes('d') && !dossier.dossierState?.finalState"
                  (click)="deleteFile(file); itemMenu.toggleMenu()">
                  {{'dossier.verwijderen' | translate }}
                </span>
              </ng-container>
            </app-emma-select>
          </mat-list-item>
        </ng-container>
      </mat-list>
      <div class="bottom-centered">
        <div class="inline-flexed">
          <button id="add-file" class="btn btn-outline-blue small-font" (click)="file.click()" *ngIf="uploadable && (!isFinalFolder || files.length <= 0)">
            <ng-icon class="ng-icon-default-size" style="margin-right: 10px;" name="heroPaperClip"></ng-icon>
            <b>{{ 'dossier.BESTAND' | translate }}</b>
          </button>
          <button id="document-request" class="btn btn-outline-blue small-font" (click)="requestFile()" *ngIf="requestable && claims!.role !== 3">
            <ng-icon class="ng-icon-default-size" style="margin-right: 10px;" name="heroPlusSmall"></ng-icon>
            <b>{{ 'dossier.document_verzoek' | translate }}</b>
          </button>
          <div class="row" style="width: 100%;" *ngIf="isFinalFolder && isSigningAction(dossier) && files.length > 0 && claims!.role !== 3">
            <div class="col-md-6 folder-col-centered">
              <button  class="btn btn-blue btn-custom small-font" (click)="requestSigning(1)" [disabled]="!areFilesToSign()">
                <b>{{ 'dossier.accoderen' | translate }}</b>
              </button>
            </div>
            <div class="col-md-6 folder-col-centered">
              <button  class="btn btn-blue btn-custom small-font" (click)="requestSigning(0)" [disabled]="!areFilesToSign()">
                <b>{{ 'dossier.signeren' | translate }}</b>
              </button>
            </div>
          </div>
        </div>
<!--        <div class="row" *ngIf="isFinalFolder && isSigningAction(dossier) && hasManualEmployeeAction() && files.length > 0 && claims!.role !== 3">
          <div class="col-md-12 center">
            <span class="emma-font-underlined" style="cursor: pointer" (click)="noSigningRequired()">{{ 'dossier.geen_accodering' | translate }}</span>
          </div>
        </div>-->
      </div>
      </ng-template>
    </ngx-file-drop>
    </div>
  </div>
  <a [href]="downloadUrl" #downloadLink style="display: none"></a>
</div>
