import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Company } from 'app/functional/models/company';
import { RedactedUser, RedactedUserWithPermissions } from 'app/functional/models/user';
import { Organization } from 'app/functional/models/organization.model';
import { OrganizationService } from 'app/services/organization/organization.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FolderService, GroupPermission, UserPermission } from 'app/services/folder/folder.service';
import { MatLegacySnackBar as MatSnackBar  } from '@angular/material/legacy-snack-bar';
import { ExplorerItem } from '../../../functional/models/explorer.model';
import { Group } from '../../../functional/models/group';
import {
  SearchCompaniesCustomersEmployeesGroupsComponent,
} from '../../../components/search/search-companies-customers-employees-groups/search-companies-customers-employees-groups.component';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '../../../functional/models/role.model';
import {UtilsService} from '../../../services/utils/utils.service';

@Component({
  selector: 'app-file-permissions-dialog',
  templateUrl: './file-permissions-dialog.component.html',
  styleUrls: ['./file-permissions-dialog.component.scss']
})
export class FilePermissionsDialogComponent implements OnInit {
  company!: Company;
  folders!: ExplorerItem[];

  users: RedactedUserWithPermissions[] = [];
  userForms: any = [];

  groups: Group[] = [];
  groupForms: UntypedFormGroup[] = [];

  organizations: {
    organization: Organization;
    members: {
      employees: RedactedUserWithPermissions[];
      customers: RedactedUserWithPermissions[];
    };
  }[] = [];

  explicitPerimissions: UserPermission[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilePermissionsDialogComponent>,
    public organizationService: OrganizationService,
    private folderService: FolderService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private utils: UtilsService,
    private translation: TranslateService
  ) {
    this.company = this.utils.deepCopy<Company>(data.company);
    this.folders = this.utils.deepCopy<ExplorerItem[]>(data.folders);
  }

  async ngOnInit() {
    if (this.folders.length !== 1) {
      return;
    }
    const folder = this.folders[0];

    this.explicitPerimissions = await this.folderService.getUserPermissions(folder.folder!.id);
    for (const permissions of this.explicitPerimissions) {
      if (
        !permissions.userFolder.permissions.includes('r') &&
        !permissions.userFolder.permissions.includes('w') &&
        !permissions.userFolder.permissions.includes('c') &&
        !permissions.userFolder.permissions.includes('d') &&
        !permissions.userFolder.permissions.includes('m')
      ){
        if (!folder.organizations){
          continue;
        }
        for (const organization of folder.organizations) {
          if (!organization[0].users){
            continue;
          }
          if (organization[0].users.some(user => user.id === permissions.user.id) &&
            !organization[1].some(user => user.id === permissions.user.id)){
            organization[1].push({...permissions.user, permissions: permissions.userFolder.permissions});
          }
        }

        if (!folder.organizations.some(org => org[1].some(usr => usr.id === permissions.user.id))){
          folder.singleUsers?.push({...permissions.user, permissions: permissions.userFolder.permissions});
        }
      }
    }

    if (folder.organizations) {
      folder.organizations!.forEach(async ([org, users]) => {
        await this.addOrganizationInit([org, users]);
      });
    }

    if (folder.groupPermissions) {
      folder.groupPermissions!.forEach(groups => {
        this.addGroupInit(groups);
      });
    }

    if (folder.singleUsers) {
      folder.singleUsers!.forEach(user => {
        this.addUserInit(user);
      });
    }
  }

  submit() {
    let preExistingUsers: RedactedUserWithPermissions[] = [];
    let preExistingGroupPerms: GroupPermission[] = [];
    let preExistingOrganizations: Organization[] = [];

    if (this.folders.length === 1) {
      preExistingUsers = this.folders[0].singleUsers || [];
      preExistingGroupPerms = this.folders[0].groupPermissions || [];
      preExistingOrganizations = (this.folders[0].organizations || []).map(organization => organization[0]);
      if (this.folders[0].organizations) {
        for (const org of this.folders[0].organizations) {
          for (const user of org[1]) {
            preExistingUsers.push(user);
          }
        }
      }
    }

    Object.keys(this.userForms).map((key) => [this.formToPermissionString(this.userForms[key]), +this.userForms[key].get('userId')!.value])
      .flatMap(([p, userId]: any) => this.folders.map(f => [f, p, userId]))
      // @ts-ignore
      .forEach(async ([f, p, userId]) => {
        if (this.folders.length === 1) {
          if (this.explicitPerimissions.some(item => item.userFolder.userId === userId)) {
            // the user is already added to this folder
            await this.folderService.editUserPermission(f.folder.id, userId, p);
          } else {
            await this.folderService.addUserPermission(f.folder.id, userId, p);
          }
        } else {
          await this.folderService.addUserPermission(f.folder.id, userId, p);
        }
      });

    this.groupForms.flat()
      .map((form: UntypedFormGroup) => [this.formToPermissionString(form), +form.get('groupId')!.value])
      .flatMap(([p, groupId]: any) => this.folders.map(f => [f, p, groupId]))
      .forEach(async ([f, p, groupId]) => {
        if (this.folders.length === 1) {
          if (preExistingGroupPerms.some(group => group.group.id === groupId)) {
            await this.folderService.editGroupPermission(f.folder.id, groupId, p);
          } else {
            await this.folderService.addGroupPermission(f.folder.id, groupId, p);
          }
        } else {
          await this.folderService.addGroupPermission(f.folder.id, groupId, p);
        }
      });

    this.organizations.flatMap((o) => this.folders.map(f => [f, o]))
      .forEach(async ([f, o]: any)  => {
        if (this.folders.length === 1) {
          if (preExistingOrganizations.every(org => org.id !== o.organization.id)) {
            await this.organizationService.addFolder(o.organization.id, f.folder.id);
          }
        } else {
          await this.organizationService.addFolder(o.organization.id, f.folder.id);
        }
      });

    if (this.folders.length === 1) {
      const folder = this.folders[0];
      preExistingGroupPerms.forEach(async group => {
        if (this.groups.every(grp => grp.id !== group.group.id)) {
          await this.folderService.deleteGroupPermission(folder.folder!.id, group.group.id);
        }
      });

      preExistingOrganizations.forEach(async (organization) => {
        if (this.organizations.every(org => org.organization.id !== organization.id)) {
          await this.organizationService.removeFolder(organization.id, folder.folder!.id);
        }
      });


      let currentUsers: RedactedUserWithPermissions[] = [...this.users];
      for (const organization of this.organizations) {
        currentUsers = [...currentUsers, ...organization.members.customers, ...organization.members.employees];
      }

      preExistingUsers.forEach(async user => {
        if (!currentUsers.some(usr => usr.id === user.id)) {
          if (this.explicitPerimissions.some(ep => ep.user.id === user.id)) {
            // the user is granted explicit permissions to this folder, so we can remove those.
            await this.folderService.deleteUserPermission(folder.folder!.id, user.id);
          } else {
            // the user is granted permissions to a parent folder, which act transitively here, so
            // we have to unset them by explicity granting no permissions to this folder.
            await this.folderService.addUserPermission(folder.folder!.id, user.id, '');
          }
        }
      });
    }

    this.dialogRef.close(true);
    this.snackBar.open(
      this.translation.instant('exchange.rights_updated'),
      this.translation.instant('close'),
      { duration: 5000 },
    );
  }

  async onSelect(event: any, search: SearchCompaniesCustomersEmployeesGroupsComponent) {
    if (!event){
      return;
    }
    if (this.isUser(event)) {
      this.addUser(event);
    }
    if (this.isOrganization(event)) {
      await this.addOrganization(event);
    }
    if (this.isGroup(event)) {
      this.addGroup(event);
    }
    search.reset();
  }

  addUserInit(user: RedactedUserWithPermissions): void {
    this.users.push(user);
    if (!this.userForms[user.id]) {
      this.userForms[user.id] = this.formBuilder.group({
        read: [user.permissions.includes('r'), Validators.required],
        write: [user.permissions.includes('w'), Validators.required],
        create: [user.permissions.includes('c'), Validators.required],
        delete: [user.permissions.includes('d'), Validators.required],
        modify: [user.permissions.includes('m'), Validators.required],
        userId: user.id,
      });
    }
  }


  async addOrganizationInit(org: [Organization, RedactedUserWithPermissions[]]): Promise<void> {
    const users = await this.organizationService.getUsers(org[0].id);
    const redactedUsers = users.filter(usr => org[1].some(us => us.id === usr.id))
      .map(usr => ({ ...usr, permissions: org[1].find(us => us.id === usr.id)!.permissions || '' }));
    const employees = redactedUsers.filter(user => user.role === Role.employee || user.role === Role.admin);
    const customers = redactedUsers.filter(user => user.role === Role.customer);
    this.organizations.push({organization: org[0], members: {employees, customers}});
    for (const user of redactedUsers) {
      if (!this.userForms[user.id]) {
        this.userForms[user.id] = this.formBuilder.group({
          read: [user.permissions.includes('r'), Validators.required],
          write: [user.permissions.includes('w'), Validators.required],
          create: [user.permissions.includes('c'), Validators.required],
          delete: [user.permissions.includes('d'), Validators.required],
          modify: [user.permissions.includes('m'), Validators.required],
          userId: user.id,
        });
      }
    }
  }

  addUser(user: RedactedUserWithPermissions): void {
    if (this.users.some(usr => usr.id === user.id)){
      return;
    }

    this.users.push(user);

    if (!this.userForms[user.id]) {
      this.userForms[user.id] = this.formBuilder.group({
        read: [false, Validators.required],
        write: [false, Validators.required],
        create: [false, Validators.required],
        delete: [false, Validators.required],
        modify: [false, Validators.required],
        userId: user.id,
      });
    }
  }

  async addOrganization(organization: Organization): Promise<void> {
    if (this.organizations.some(o => o.organization.id === organization.id)) {
      this.snackBar.open(
        this.translation.instant('exchange.organization_already_selected'),
        this.translation.instant('close'),
        { duration: 5000 },
      );
      return;
    }

    const users = (await this.organizationService.getUsers(organization.id)).map(u => ({ ...u, permissions: '' }));
    const employees = users.filter(user => user.role === Role.employee || user.role === Role.admin);
    const customers = users.filter(user => user.role === Role.customer);

    this.organizations.push({organization, members: {employees, customers}});

    for (const user of users) {
      if (!this.userForms[user.id]) {
        this.userForms[user.id] = this.formBuilder.group({
          read: [false, Validators.required],
          write: [false, Validators.required],
          create: [false, Validators.required],
          delete: [false, Validators.required],
          modify: [false, Validators.required],
          userId: user.id,
        });
      }
    }
  }

  isUser(item: RedactedUser | Company | Group) {
    return item.hasOwnProperty('firstName');
  }

  isOrganization(item: RedactedUser | Company | Group) {
    return item.hasOwnProperty('kvk');
  }

  isGroup(item: any) {
    return !item.hasOwnProperty('firstName') && !item.hasOwnProperty('kvk');
  }

  deleteUser(user: RedactedUserWithPermissions) {
    for (const org of this.organizations) {
      org.members.customers = org.members.customers.filter(usr => usr.id !== user.id);
      org.members.employees = org.members.employees.filter(usr => usr.id !== user.id);
    }

    this.organizations = this.organizations.filter(org => org.members.employees.length !== 0 || org.members.customers.length !== 0);
    this.users = this.users.filter(usr => usr.id !== user.id);
    delete this.userForms[user.id];
  }

  deleteGroup(group: Group) {
    const pos = this.groups.findIndex(u => u.id === group.id);
    if (pos > -1) {
      this.groups.splice(pos, 1);
    }
    this.groupForms.splice(pos, 1);
  }

  deleteOrganization(organization: {
    organization: Organization;
    members: {
      employees: RedactedUserWithPermissions[];
      customers: RedactedUserWithPermissions[];
    };
  }) {
    const users = [...organization.members.employees, ...organization.members.customers];

    for (const user of users){
      if (
        !this.users.some(usr => user.id === usr.id) &&
        !this.organizations.some(org => org.organization.id !== organization.organization.id &&
          (org.members.customers.some(usr => usr.id === user.id) || org.members.employees.some(usr => usr.id === user.id)))) {
        delete this.userForms[user.id];
      }
    }

    const pos = this.organizations.findIndex(u => u.organization.id === organization.organization.id);
    if (pos > -1) {
      this.organizations.splice(pos, 1);
    }
  }

  setOther(formGroup: UntypedFormGroup, controlName: 'write' | 'delete' | 'modify' | 'create') {
    const val = !formGroup.get(controlName)!.value;
    if (val) {
      formGroup.get('read')!.patchValue(true);
    }
  }

  setRead(formGroup: any) {
    const val = formGroup.get('read')!.value;
    if (val) {
      formGroup.get('write').patchValue(false);
      formGroup.get('create').patchValue(false);
      formGroup.get('delete').patchValue(false);
      formGroup.get('modify').patchValue(false);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private formToPermissionString(form: UntypedFormGroup): string {
    let permission = '';
    if(form.get('read')?.value){
      permission = permission + 'r';
    }
    if(form.get('write')?.value){
      permission = permission + 'w';
    }
    if(form.get('create')?.value){
      permission = permission + 'c';
    }
    if(form.get('delete')?.value){
      permission = permission + 'd';
    }
    if(form.get('modify')?.value){
      permission = permission + 'm';
    }
    return permission;
  }

  private addGroup(group: Group) {
    this.groups.push(group);
    this.groupForms.push(this.formBuilder.group({
      read: [false, Validators.required],
      write: [false, Validators.required],
      create: [false, Validators.required],
      delete: [false, Validators.required],
      modify: [false, Validators.required],
      groupId: group.id,
    }));
  }

  private addGroupInit(group: GroupPermission) {
    this.groups.push(group.group);
    this.groupForms.push(this.formBuilder.group({
      read: [group.groupFolder.permissions.includes('r'), Validators.required],
      write: [group.groupFolder.permissions.includes('w'), Validators.required],
      create: [group.groupFolder.permissions.includes('c'), Validators.required],
      delete: [group.groupFolder.permissions.includes('d'), Validators.required],
      modify: [group.groupFolder.permissions.includes('m'), Validators.required],
      groupId: group.group.id,
    }));
  }
}
