<div class="emma-body-white" style="padding-top: 55px">
  <div class="row" style="max-height: 40vh; margin-left: 30px; margin-right: 30px">
    <div class="col-md-6" style="margin-top: 20px">
      <div class="row center" style="height: 100px">
        <div class="col-md-1">
          <div class="short-input" [style.background]="secondary" [cpPosition]="'bottom'" (colorPickerChange)="calculateSecondaryColors($event)" [cpOutputFormat]="'hsla'" [(colorPicker)]="secondary"></div>
        </div>
        <div class="col-md-11">
          <p style="margin-left: 5px;">
            <span style="font-weight: bold">{{ 'theme.primary_color' | translate }}</span><br/>{{ 'theme.primary_explanation' | translate }}
          </p>
        </div>
      </div>
      <div class="row center" style="height: 100px">
        <div class="col-md-1">
          <div class="short-input" [style.background]="primary" [cpPosition]="'bottom'" (colorPickerChange)="calculatePrimaryColors($event)" [cpOutputFormat]="'hsla'" [(colorPicker)]="primary"></div>
        </div>
        <div class="col-md-11">
          <p style="margin-left: 5px;">
            <span style="font-weight: bold">{{ 'theme.secondairy_color' | translate }}</span><br/>{{ 'theme.secondairy_explanation' | translate }}
          </p>
        </div>
      </div>

      <div class="flex">
        <button class="btn btn-red" (click)="resetTheme(true)">{{ 'theme.recover' | translate }}</button>
        <button class="btn btn-blue btn-custom" (click)="updateTheme()">{{ 'theme.adjust' | translate }}</button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" id="theme-preview-block">
        <div class="card-header card-header-grey card-header-icon">
        <div class="card-icon card-header-custom" [ngStyle]="{'background': 'linear-gradient(60deg, '+boxSecondaryColorStart+', '+boxSecondaryColorFinish+')', 'box-shadow': '0 4px 20px 0px '+boxSecondaryShadowStart+', 0 7px 10px -5px '+boxSecondaryShadowFinish }"><mat-icon>palette</mat-icon></div>
          <div class="exchange-card-title-between">
            <h4 class="card-title">
              {{'settings.example_title' | translate}}
            </h4>
          </div>
        </div>
        <div class="card-body">
          <div class="document-list">
            <app-document
              *ngFor="let document of documents"
              [document]="document"
              class="document-list__item">
            </app-document>
            <app-folder
              [folder]="folder"
              class="document-list__item">
            </app-folder>
          </div>
          <button [ngStyle]="{'color': buttonTextColor, 'border-color': buttonBorderColor, 'background-color': buttonBackgroundColor, 'box-shadow': '0 2px 2px 0 '+buttonShadowStart+', 0 3px 1px -2px '+buttonShadowMiddle+', 0 1px 5px 0 '+buttonShadowFinish}" class="btn btn-danger pull-right">{{'settings.example_button' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
