import {Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {map} from 'rxjs/operators';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {CompanyService} from '../../services/company/company.service';
import {Folder} from '../../functional/models/folder';
import {FolderService} from '../../services/folder/folder.service';
import {UserService} from '../../services/user/user.service';
import {ExplorerItem} from '../../functional/models/explorer.model';
import {Role} from '../../functional/models/role.model';

export class DynamicFlatNode {
  constructor(
    public folder: Folder,
    public item: string,
    public id: number,
    public level = 1,
    public expandable = false,
    public canWrite = false,
    public isLoading = false,
  ) {}
}

export class SourceFilter {
  companyId?: number;
  userId?: number;
  skipRoot?: boolean;
}

@Injectable({providedIn: 'root'})
export class DynamicDatabase {
  rootLevelNodes: Folder[] = [];

  constructor(
    private companyService: CompanyService,
    private folderService: FolderService,
    private userService: UserService
  ) {
  }

  /** Initial data from database */
  async initialData(source: SourceFilter): Promise<DynamicFlatNode[]> {
    if (source.companyId) {
      const root: Folder = await this.companyService.getRoot(source.companyId);
      if (source.skipRoot) {
        this.rootLevelNodes = (await this.folderService.getChildren(root.id)).folders;
      } else {
        this.rootLevelNodes = [await this.companyService.getRoot(source.companyId)];
      }
    }

    if (source.userId) {
      this.rootLevelNodes = await this.userService.getAccesspoints(source.userId);
    }

    const nodes: DynamicFlatNode[] = [];
    for (const rootNode of this.rootLevelNodes) {
      if (this.getUserRole() === Role.admin || this.getUserRole() === Role.superuser){
        rootNode.permission = 'rwcdm';
        nodes.push(new DynamicFlatNode(rootNode, rootNode.name, rootNode.id, 0, true, true));
      } else {
        nodes.push(new DynamicFlatNode(rootNode, rootNode.name, rootNode.id, 0, true, rootNode.permission!.includes('w')));
      }
    }
    return nodes;
  }

  getUserRole(): Role {
    return this.userService.tryClaims().role;
  }

  async getChildren(node: DynamicFlatNode): Promise<Folder[] | undefined> {
    return await this.folderService.childFolders(node.id) || undefined;
  }

  async getPermissions(folderId: number): Promise<string> {
    return await this.folderService.getCurrentUserPermissions(folderId);
  }

  async isExpandable(folder: Folder): Promise<boolean> {
    return !!await this.folderService.childFolders(folder.id);
  }
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {

  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] { return this.dataChange.value; }
  set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private treeControl: FlatTreeControl<DynamicFlatNode>,
    private database: DynamicDatabase
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  async toggleNode(node: DynamicFlatNode, expand: boolean) {
    const children: Folder[] | undefined = await this.database.getChildren(node);
    const index = this.data.indexOf(node);
    if (!children || children.length === 0 || index < 0 ) {
      node.expandable = false;
      return;
    }

    node.isLoading = true;

    if (expand) {
      const nodes: DynamicFlatNode[] = [];
      for (const folder of children) {
        if (this.database.getUserRole() === Role.admin || this.database.getUserRole() === Role.superuser){
          folder.permission = 'rwcdm';
          nodes.push(new DynamicFlatNode(folder, folder.name, folder.id, node.level + 1, true, true));
        } else {
          const permissions = await this.database.getPermissions(folder.id);
          folder.permission = permissions;
          nodes.push(new DynamicFlatNode(folder, folder.name, folder.id, node.level + 1, true, permissions.includes('w')));
        }
      }
      this.data.splice(index + 1, 0, ...nodes);
    } else {
      let count = 0;
      for (let i = index + 1; i < this.data.length
      && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
    }

    // notify the change
    this.dataChange.next(this.data);
    node.isLoading = false;
  }
}

@Component({
  selector: 'app-recursive-tree',
  templateUrl: './recursive-tree.component.html',
  styleUrls: ['./recursive-tree.component.scss']
})
export class RecursiveTreeComponent implements OnInit {
  @Input() fileSource!: SourceFilter;
  @Input() sources!: ExplorerItem[];
  @Input() multiSelect!: boolean;
  @Output() selectedChange = new EventEmitter<DynamicFlatNode[]>();

  treeControl!: FlatTreeControl<DynamicFlatNode>;
  dataSource!: DynamicDataSource;
  selected: DynamicFlatNode[] = [];

  constructor(
    private database: DynamicDatabase,
  ) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.database);
  }

  getLevel = (node: DynamicFlatNode) => node.level;
  isExpandable = (node: DynamicFlatNode) => node.expandable && !this.isOfSources(node);
  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable && !this.isOfSources(_nodeData);

  isOfSources(node: DynamicFlatNode) {
    return this.sources.some(item => item.folder?.id === node.id);
  }

  async ngOnInit() {
    this.dataSource.data = await this.database.initialData(this.fileSource);
  }

  setSelected(node: DynamicFlatNode) {
    if (!this.isOfSources(node) && node.canWrite) {
      if (this.multiSelect) {
        if (this.selected?.some(item => item.id === node.id)){
          this.selected = this.selected?.filter(item => item.id !== node.id);
        } else {
          this.selected.push(node);
        }
      } else {
        this.selected = [node];
      }
      this.selectedChange.emit(this.selected);
    }
  }

  isSelected(node: DynamicFlatNode) {
    return this.selected.some(item => item.id === node.id);
  }
}
