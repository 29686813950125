import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxOverviewComponent } from './inbox-overview.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';



@NgModule({
  declarations: [InboxOverviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    TranslateModule.forRoot(),
    MatIconModule,
  ],
  exports: [
      InboxOverviewComponent,
  ]
})
export class InboxOverviewModule { }
