import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Role} from '../../functional/models/role.model';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {User} from '../../functional/models/user';
import {UserService} from '../../services/user/user.service';
import {Group} from '../../functional/models/group';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {GroupService} from '../../services/group/group.service';

@Component({
  selector: 'app-connected-groups',
  templateUrl: './connected-groups.component.html',
  styleUrls: ['./connected-groups.component.scss']
})
export class ConnectedGroupsComponent implements OnInit {

  @Input() user!: User;
  @Input() userId!: number;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatSort) set content(content: any) {
    if (content && this.groupDataSource) {
      this.groupDataSource.sort = content;
      this.groupDataSource.paginator = this.paginator;
    }
  }

  groupDataSource: MatTableDataSource<Group> = new MatTableDataSource<Group>([]);

  constructor(
    private userService: UserService,
    private router: Router,
    private groupService: GroupService
  ) { }

  async ngOnInit() {
    await this.setGroups();
  }

  async setGroups(): Promise<void> {
    if (!this.user || this.user.role === Role.admin || this.user.role === Role.superuser) {
      return;
    }
    const groups = await this.userService.getUserGroups(this.userId);
    this.groupDataSource = new MatTableDataSource(groups);
  }

  async toGroup(row: Group) {
    await this.router.navigate(['management', 'groups', 'details', row.id]);
  }

  async deleteGroup(row: Group, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.user.role === Role.customer) {
      await this.groupService.deleteCustomer(this.user.id, row.id);
    }

    if (this.user.role === Role.employee) {
      await this.groupService.deleteEmployee(this.user.id, row.id);
    }
    await this.setGroups();
  }
}
