import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxComponent } from './inbox/inbox.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {exchangeRoutes} from '../exchange/exchange.routing';
import {TranslateModule} from '@ngx-translate/core';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {ReplyItemComponent} from './reply-item/reply-item.component';
import {SubNavModule} from '../../components/sub-nav/sub-nav.module';
import {LinkyModule} from 'ngx-linky';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    BrowserAnimationsModule,
    RouterModule.forChild(exchangeRoutes),
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTabsModule,
    TranslateModule.forRoot(),
    MatListModule,
    SubNavModule,
    LinkyModule
  ],
  declarations: [InboxComponent, ReplyItemComponent]
})
export class MessagingModule { }
