<div class="chat-container" *ngIf="chatSelected">
  <div class="chat-container-header-custom chat-container-header">
    <div class="chat-header">
      <div style="display: flex; align-items: center;">
        <mat-icon class="back-button" (click)="goBack()" id="go-back">arrow_back</mat-icon>
        <p [appEmmaTooltip]="getUsers(chatSelected)" matTooltipClass="break-line" class="chat-header-name">
          {{ chatSelected.name }} {{chatSelected.users.length > 2 ? ' ('+chatSelected.users.length+' personen)' : ''}}{{chatSelected.dossier ? ' - ' + chatSelected.dossier.name : ''}}
        </p>
      </div>
      <div *ngIf="!isMod()">
        <mat-icon class="action-icon icon-margin" (click)="leaveChat()">exit_to_app</mat-icon>
      </div>
      <div *ngIf="isMod() && chatSelected.users.length <= 1">
        <div class="btn btn-outline-white" (click)="assignSelf()">{{'chat.pickup' | translate}}</div>
      </div>
      <div *ngIf="isMod() && isInChat()">
        <app-emma-select [renderButton]="false" #menu>
          <mat-icon class="action-icon icon-margin" (click)="menu.toggleMenu()" id="actions" button>more_vert</mat-icon>
          <ng-container body>
            <span class="no-select" *ngIf="!chatSelected.isArchived" (click)="openAddPerson(); menu.toggleMenu()">{{'chat.beheren' | translate}}</span>
            <span class="no-select" *ngIf="!chatSelected.isArchived" (click)="archiveChat(); menu.toggleMenu()">{{'chat.archive' | translate }}</span>
            <span class="no-select" *ngIf="!chatSelected.isArchived && userIn(chatSelected)" (click)="leaveChat(); menu.toggleMenu()">{{'chat.verlaten' | translate}}</span>
            <span class="no-select" (click)="deleteChat(); menu.toggleMenu()">{{'chat.delete' | translate}}</span>
          </ng-container>
        </app-emma-select>
      </div>
    </div>
  </div>
  <div class="chat-body-short" id="chatBox">
    <div class="subject-container">
      <div class="subject">
        {{ chatSelected.dossier ? chatSelected.dossier.name : 'Algemene vraag' }}
      </div>
    </div>

    <ng-container *ngFor="let message of currentChatMessages">
      <div class="unread-banner" *ngIf="chatSelected.unreadCount > 0 && compareAndPrint(message)">
        <div class="unread-banner-message">
          {{chatSelected.unreadCount}} {{chatSelected.unreadCount === 1 ? ('chat.unread_message' | translate) : ('chat.unread_messages' | translate)}}
        </div>
      </div>
      <div [ngClass]="{'message-foreign': message.sender.id !== claims!.userId, 'message-self': message.sender.id === claims!.userId}">
        <div class="message-wrapper">
          <div class="message-user" *ngIf="message.sender.id !== claims!.userId">
            {{ message.sender.firstName }} {{ message.sender.lastName }}
          </div>
          <div class="message-user" *ngIf="message.sender.id === claims!.userId">
            {{'chat.you' | translate}}
          </div>
          <div class="message-content" *ngIf="message.content" [innerHTML]="message.content | linky"></div>
          <div class="message-content" *ngIf="message.file">
            <div class="flex-file-between">
              <div class="file-icon-holder">
                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                <span class="file-text">{{ message.file?.name }}</span>
              </div>
              <span class="circled-white" (click)="getUrl(message.file, link)">
                <mat-icon>get_app</mat-icon>
              </span>
              <a #link style="display: none" [href]="fileUrl"></a>
            </div>
          </div>
        </div>
        <div class="timestamp">
          {{message.createdAt | date:'HH:mm | dd/MM/yyyy'}}
        </div>
      </div>
    </ng-container>
  </div>
  <div class="chat-footer-custom chat-footer">
    <div class="message-input-container">
      <textarea
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="6"
        cdkTextareaAutosize
        type="text"
        placeholder="{{'chat.input_placeholder' | translate}}"
        class="message-input"
        [(ngModel)]="message" (keydown.enter)="sendMessage()"
        [disabled]="chatSelected.isArchived"
        #textarea>
      </textarea>
      <button matSuffix [style.height]="textarea.style.height" class="emma-subtitle1 clip-button" [disabled]="chatSelected.isArchived" (click)="file.click()"><mat-icon>attach_file</mat-icon><div class="ripple-container"></div></button>
      <input multiple #file type="file" class="hidden" (change)="uploadChatFile($event)"/>
      <button class="btn btn-green send-button" [disabled]="!message" (click)="sendMessage()"><mat-icon>send</mat-icon></button>
    </div>
  </div>
</div>

<!-- Open chats -->
<div class="chat-container" *ngIf="!chatSelected">
  <div class="chat-container-header-custom chat-container-header">
    <div class="chat-header extra-padding">
      <div class="badge-centered">
        <p *ngIf="selectedTab === 'own'">{{'chat.your_chats' | translate}} {{userFilter.length > 0 || dossierFilter.length > 0 || organizationFilter.length > 0 ? ('chat.filtered' | translate) : ''}}</p>
        <p *ngIf="selectedTab === 'all'">{{'chat.all_chats' | translate }} {{userFilter.length > 0 || dossierFilter.length > 0 || organizationFilter.length > 0 ? ('chat.filtered' | translate) : ''}}</p>
        <p *ngIf="selectedTab === 'open'">{{'chat.open_chats' | translate}} {{userFilter.length > 0 || dossierFilter.length > 0 || organizationFilter.length > 0 ? ('chat.filtered' | translate) : ''}}</p>
        <p *ngIf="selectedTab === 'archived'">{{'chat.archived_chats' | translate}} {{userFilter.length > 0 || dossierFilter.length > 0 || organizationFilter.length > 0 ? ('chat.filtered' | translate) : ''}}</p>
        <span [matBadge]="unreadCount.toString()" class="badge" *ngIf="unreadCount > 0"></span>
      </div>
      <div class="icons-centered">
        <div class="filter-action" *ngIf="isMod()" (click)="openFilter()" id="filter">
          <mat-icon class="action-grain" *ngIf="userFilter.length > 0 || dossierFilter.length > 0 || organizationFilter.length > 0">blur_on</mat-icon>
          <mat-icon class="action-icon">filter_alt</mat-icon>
        </div>
        <mat-icon class="action-icon" (click)="openAddChat()" id="add-chat">add</mat-icon>
      </div>
    </div>
  </div>
  <div class="chat-sidenav chat-sidenav-custom" id="chat-sidebar">
    <div class="chat-sidenav-tab" id="own-chats" [ngClass]="{'sidenav-tab-selected sidenav-tab-selected-custom': selectedTab === 'own', 'chat-sidenav-tab-custom': selectedTab !== 'own'}" (click)="selectTab('own')">
      <ng-icon class="sidenav-icon" name="heroChatBubbleLeftRight"></ng-icon>
      <span class="sidenav-text">{{ 'chat.eigen' | translate }}</span>
    </div>
    <div class="chat-sidenav-tab" id="open-chats" *ngIf="isMod()" [ngClass]="{'sidenav-tab-selected sidenav-tab-selected-custom': selectedTab === 'open', 'chat-sidenav-tab-custom': selectedTab !== 'open'}" (click)="selectTab('open')">
      <ng-icon class="sidenav-icon" name="heroChatBubbleLeftEllipsis"></ng-icon>
      <span class="sidenav-text">{{ 'chat.open' | translate }}</span>
    </div>
    <div class="chat-sidenav-tab" id="archived-chats" [ngClass]="{'sidenav-tab-selected sidenav-tab-selected-custom': selectedTab === 'archived', 'chat-sidenav-tab-custom': selectedTab !== 'archived'}" (click)="selectTab('archived')">
      <ng-icon class="sidenav-icon" name="heroArchiveBox"></ng-icon>
      <span class="sidenav-text">{{ 'chat.archief' | translate }}</span>
    </div>
    <div class="chat-sidenav-tab" id="all-chats" [ngClass]="{'sidenav-tab-selected sidenav-tab-selected-custom': selectedTab === 'all', 'chat-sidenav-tab-custom': selectedTab !== 'all'}" (click)="selectTab('all')">
      <ng-icon class="sidenav-icon" name="heroChatBubbleLeftRight"></ng-icon>
      <span class="sidenav-text">{{ 'chat.alle' | translate }}</span>
    </div>
  </div>
  <div class="chat-body">
    <div class="rounded-input chat-search-margin">
      <input #searchInput [(ngModel)]="nameFilter" placeholder="{{'chat.search_placeholder' | translate}}">
      <div class="rounded-input-icon">
        <mat-icon>search</mat-icon>
      </div>
    </div>
    <div class="chat-body-centered" *ngIf="displayedChats.length === 0">
      <p class="emma-font-light">{{ 'chat.empty' | translate }}</p>
    </div>
    <mat-selection-list (selectionChange)="setChatSelected($event)" [multiple]="false" class="chat-list-container">
      <ng-container *ngFor="let chat of displayedChats">
        <mat-list-option [value]="chat" class="msg">
          <div style="padding-bottom: 16px">
            <p class="msg-time">{{chat.recentMessage ? getDateFromString(chat.recentMessage.createdAt) : getDateFromString(chat.createdAt)}}</p>
            <span [matBadge]="chat.unreadCount.toString()" class="msg-badge" *ngIf="chat.unreadCount && chat.unreadCount > 0"></span>
            <p class="msg-title">{{chat.name}}{{chat.users.length > 2 ? ' ('+chat.users.length +' '+ ('chat.persons' | translate) + ')' : ''}}{{chat.dossier ? ' - ' + chat.dossier.name : ''}}</p>
            <p class="msg-message" *ngIf="!chat.recentMessage || (!chat.recentMessage.content && !chat.recentMessage.file)">{{'messaging.no_message' | translate}}</p>
            <p class="msg-message" *ngIf="chat.recentMessage && chat.recentMessage.content ">{{chat.recentMessage.content}}</p>
            <p class="msg-message" *ngIf="chat.recentMessage && chat.recentMessage.file">{{ chat.recentMessage.file.name }}</p>
          </div>
          <mat-divider class="message-divider"></mat-divider>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </div>
</div>
