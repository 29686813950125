<div class="main-content">
  <div class="container-fluid addons-container">
    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 addon-col" *ngFor="let plugin of listAddons">
        <div class="card">
          <div class="card-img-top card-shop-header">
            <img style="min-width: 200px;" [src]="plugin.app.image" [alt]="plugin.app.name">
            <mat-chip-list class="tags">
              <mat-chip class="tag" *ngFor="let tag of plugin.app.tags">{{ tag }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">{{plugin.app.name}}</h5>
            <p class="card-text">{{plugin.app.shortDescription}}</p>
            <a class="btn btn-red mt-auto" *ngIf="plugin.app.comingSoon">{{ 'addon.coming_soon' | translate }}</a>
            <a [routerLink]="'details/'+plugin.id" class="btn btn-blue btn-custom mt-auto" *ngIf="!plugin.app.comingSoon">{{ 'addon.see_details' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
