import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ExactMetaFormModel } from '../../../../services/exact/exact.service';

@Component({
  selector: 'app-exact-labels-dialog',
  templateUrl: './exact-labels-dialog.component.html',
  styleUrls: ['./exact-labels-dialog.component.scss']
})
export class ExactLabelsDialogComponent implements OnInit {

  exactMeta: ExactMetaFormModel[] = [];
  selectedMeta: ExactMetaFormModel[] = [];
  metaIndex = 1;
  source: any;

  constructor(
    public dialogRef: MatDialogRef<ExactLabelsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.exactMeta = data.meta;
    if (this.exactMeta.length <= 1){
      this.selectedMeta = this.exactMeta;
    }

    this.source = {
      companyId: data.company.id,
    };
  }

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close(false);
  }

  createSync() {
    if (this.metaIndex === this.selectedMeta.length) {
      this.dialogRef.close(this.selectedMeta);
    } else {
      this.metaIndex++;
    }
  }

  setSelectedMeta() {
    this.selectedMeta = this.exactMeta.filter(item => item.synchronised);
  }
}
