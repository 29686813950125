import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DossierTemplateService} from '../../../services/dossier-template/dossier-template.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-dossier-template-permission-dialog',
  templateUrl: './add-dossier-template-permission-dialog.component.html',
  styleUrls: ['./add-dossier-template-permission-dialog.component.scss']
})
export class AddDossierTemplatePermissionDialogComponent implements OnInit {
  addPermissionForm!: UntypedFormGroup;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public templateService: DossierTemplateService,
    public dialogRef: MatDialogRef<AddDossierTemplatePermissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  existPermissionForRole(role?: number) {
    const folderId = Number(this.addPermissionForm.get('folder')!.value);
    let matchedFolder;

    if (folderId === -1){
      matchedFolder = this.data.currentState.permissions.find((permission: any) => permission[0] === null);
    } else {
      matchedFolder = this.data.currentState.permissions.find((permission: any) => permission[0] === folderId);
    }

    if (!matchedFolder){
      return null;
    }

    if (role === -1) {
      return matchedFolder.permission[1].some((perm: any) => perm.role === null);
    }
    return matchedFolder.permission[1].some((perm: any) => perm.role === role);
  }

  ngOnInit(): void {
    this.addPermissionForm = this.formBuilder.group({
      folder: [null, [Validators.required]],
      role: [null, [Validators.required]],
      permission: ['', []],
    });
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  async addPermission() {
    this.addPermissionForm.markAllAsTouched();

    if (this.addPermissionForm.invalid){
      return;
    }

    let folderId = this.addPermissionForm.get('folder')?.value;
    let roleId = this.addPermissionForm.get('role')?.value;
    if (folderId === -1) {
      folderId = null;
    }
    if (roleId === -1) {
      roleId = null;
    }

    const result = await this.templateService.createPermissionChange(this.data.template.id, this.data.currentState.id, {
      folderTemplateId: folderId,
      role: roleId,
      permissions: this.addPermissionForm.get('permission')?.value
    });

    this.dialogRef.close(result);
  }
}
