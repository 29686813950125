import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FulfillRequestDialogComponent } from './fulfill-request-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {NgxFileDropModule} from 'ngx-file-drop';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  declarations: [FulfillRequestDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    NgxFileDropModule,
    NgIconsModule
  ],
  exports: [FulfillRequestDialogComponent]
})
export class FulfillRequestDialogModule { }
