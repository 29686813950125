<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="email-generator-dialog">
  <div class="row">
    <div class="col-md-12">
      <p class="emma-subtitle centered">{{ 'exchange.email_address' | translate }}</p>
    </div>
  </div>
  <div class="row margin-20">
    <div class="col-md-12 centered">
      <p class="emma-font-light text-align-center">
        {{ 'exchange.email_explanation' | translate }}
      </p>
    </div>
  </div>
  <div class="row margin-30">
    <div class="col-md-12">
      <div class="emma-input-container">
        <label label for="birth" class="emma-input-label">{{'users.create.email'  | translate }}</label>
        <div class="emma-input">
          <input id="birth" appEmmaInput name="directory" [value]="email" disabled>
          <button [cdkCopyToClipboard]="email" class="clear-button emma-subtitle1"><mat-icon>content_copy</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
</div>
