<div class="wrapper wrapper-full-page center">
  <div class="container">
    <div class="row login-centered">
      <form class="login-form" [ngClass]="isMobile ? 'no-margin-full-width' : ''" [formGroup]="loginForm">
        <div class="card card-login card-hidden inline-card" [ngClass]="isMobile ? 'no-margin-full-width' : 'centered-desktop'">
          <img src="assets/img/login-myemma.svg" [ngClass]="isMobile ? 'login-image-mobile' : 'login-image'">
          <div class="card-body body-centered" [ngClass]="isMobile ? '' : 'max-size'">
            <p class="emma-subtitle" *ngIf="company">{{'auth.login.title' | translate}} {{company.name}}!</p>
            <p class="emma-font-default-small">{{'auth.login.desc' | translate}}</p>
            <div class="input-group padding">
              <div class="input-group-prepend">
                <span class="input-group-text" style="padding-left: 0;">
                  <ng-icon name="heroEnvelope" class="ng-icon-default"></ng-icon>
                </span>
              </div>
              <input appEmmaInput data-test="email-input" type="email" placeholder="{{'auth.login.email' | translate}}" formControlName="email" name="email">
            </div>
            <div class="input-group padding-second" style="margin-top: 0;">
              <div class="input-group-prepend">
                <span class="input-group-text" style="padding-left: 0;">
                  <ng-icon name="heroLockClosed" class="ng-icon-default"></ng-icon>
                </span>
              </div>
              <input appEmmaInput data-test="password-input" type="password" placeholder="{{'auth.login.pass' | translate}}" formControlName="password" name="password" data-private>
            </div>

            <div class="input-group remember-me-centered padding-third">
              <div class="checkbox-container-centered">
                <mat-checkbox data-test="remember-me-checkbox" style="margin-left: 5px;" name="tjekbox" class="checkbox-centered" formControlName="remember"></mat-checkbox>
                <span>{{ 'auth.login.remember' | translate }}</span>
                <ng-icon name="heroInformationCircle" [appEmmaTooltip]="'auth.login.remember_info' | translate" class="remember-info"></ng-icon>
              </div>
              <a data-test="forgot-route" routerLink="/auth/forgot">{{'auth.login.forgot' | translate}}</a>
            </div>

            <div class="card-footer" style="margin-left: 0;">
              <button data-test="submit-button"  class="btn btn-blue btn-custom"  (click)="login()">
                <b>{{'auth.login.submit' | translate}}</b>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
