import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExchangeDetailsComponent } from './exchange-details.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {TranslateModule} from '@ngx-translate/core';
import {UserIconModule} from 'app/components/user-icon/user-icon.module';
import {FileTreeModule} from 'app/dialogs/exchange/file-tree/file-tree.module';
import {FileRequestDialogModule} from '../../../dialogs/file-request/file-request-dialog/file-request-dialog.module';
import {DisplaySignaturesDialogModule} from '../../../dialogs/display-signatures-dialog/display-signatures-dialog.module';
import {EmmaTooltipModule} from '../../base-components/emma-tooltip/emma-tooltip.module';
import {
  heroAtSymbol, heroCheckBadge, heroClipboard,
  heroClipboardDocument, heroDocumentArrowDown, heroDocumentArrowUp, heroDocumentDuplicate, heroDocumentMagnifyingGlass,
  heroDocumentPlus, heroDocumentText, heroFolderPlus, heroPencilSquare, heroServer, heroSquare2Stack, heroTrash, heroUserPlus
} from '@ng-icons/heroicons/outline';
import { NgIconsModule } from '@ng-icons/core';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  declarations: [ExchangeDetailsComponent],
  exports: [
    ExchangeDetailsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    UserIconModule,
    MatListModule,
    MatTooltipModule,
    MatBadgeModule,
    FileTreeModule,
    FileRequestDialogModule,
    DisplaySignaturesDialogModule,
    TranslateModule.forRoot(),
    MatExpansionModule,
    NgIconsModule.withIcons({
      heroFolderPlus,
      heroDocumentPlus,
      heroDocumentArrowUp,//Upload
      heroDocumentText,
      heroDocumentArrowDown,
      heroDocumentMagnifyingGlass,
      heroClipboard,
      heroDocumentDuplicate,
      heroClipboardDocument,//Link
      heroUserPlus,
      heroPencilSquare,
      heroCheckBadge,
      heroSquare2Stack,//Duplicate 2
      heroAtSymbol,
      heroServer,
      heroTrash
    }),
    EmmaTooltipModule,
  ]
})
export class ExchangeDetailsModule { }
