import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Dossier} from '../../../functional/models/dossier.model';
import {RedactedUser, User} from '../../../functional/models/user';
import {DossierService} from '../../../services/dossier/dossier.service';
import {SearchEmployeesComponent} from '../../../components/search/search-employees/search-employees.component';
import {SearchCustomersComponent} from '../../../components/search/search-customers/search-customers.component';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'app-create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateFolderDialogComponent implements OnInit {
  @ViewChild('employeeSearch') employeeSearch!: SearchEmployeesComponent;
  @ViewChild('customerSearch') customerSearch!: SearchCustomersComponent;

  dossier!: Dossier;
  customerPermissions: [RedactedUser, number][] = [];
  employeePermissions: [RedactedUser, number][] = [];
  isRequestable = false;
  nameForm = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(255),
    Validators.pattern('([A-Za-z0-9 _\\-+.\',()&€£¢$À-ÖØ-ßà-öø-ÿŠŒŽšœžŸµ]+)'),
    this.nameExists(),
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<CreateFolderDialogComponent>,
    private dossierService: DossierService,
    private translate: TranslateService
  ) {
    this.dossier = this.dialogData.dossier;
  }

  async ngOnInit() {
    this.customerPermissions = this.dossier.customers.map((customer) => [customer, 2]);
    this.employeePermissions = this.dossier.employees.map((employee) => [employee, 3]);
  }

  nameExists(): ValidatorFn  {
    return (control: AbstractControl): ({ [key: string]: boolean } | null) => {
      if (this.dossier === undefined){
        return null;
      }

      const exists = this.hasExistingFolder(control.value);
      if (!exists) {
        return null;
      }
      return {nameInvalid: true} ;
    };
  }

  hasExistingFolder(name: string) {
    return this.dossier.folders.find((folder) => folder.name === name);
  }

  async create() {
    this.nameForm.markAllAsTouched();
    if (this.nameForm.invalid){
      return;
    }

    const users: Record<number, string> = {};
    const combinedUsers = [...this.customerPermissions, ...this.employeePermissions];
    for (const user of combinedUsers) {
      users[user[0].id] = this.getPermissions(user[1]);
    }
    await this.dossierService.createFolder(this.dossier.id, {
      name: this.nameForm.value,
      description: this.translate.instant('dossier.upload_here'),
      users
    });
    this.dialogRef.close();
  }

  getPermissions(type: number): string {
    if (type === 3) {
      return 'rwcdm';
    }
    if (type === 2) {
      return 'r';
    }
    if (type === 1) {
      return 'rwcd';
    }
    return '';
  }

  onCustomerSelected(user: User | RedactedUser | undefined) {
    if (user) {
      const found = !!this.customerPermissions.find(userQueried => userQueried[0].id === user.id);
      if (!found) {
        this.customerPermissions.push([user, 2]);
      }
      this.customerSearch.clearSelected();
    }
  }

  onEmployeeSelected(user: User | RedactedUser | undefined) {
    if (user) {
      const found = !!this.employeePermissions.find(userQueried => userQueried[0].id === user.id);
      if (!found) {
        this.employeePermissions.push([user, 2]);
      }
      this.employeeSearch.clearSelected();
    }
  }

  deleteEmployee(employee: RedactedUser) {
    this.employeePermissions = this.employeePermissions.filter(employe => employe[0].id !== employee.id);
  }

  deleteCustomer(customer: RedactedUser) {
    this.customerPermissions = this.customerPermissions.filter(custome => custome[0].id !== customer.id);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
