<div class="wrapper wrapper-full-page center">
  <div class="container">
    <div class="row login-centered">
      <form class="login-form" [ngClass]="isMobile ? 'no-margin-full-width' : ''" [formGroup]="codeForm">
        <div class="card card-login card-hidden inline-card" [ngClass]="isMobile ? 'no-margin-full-width' : 'centered-desktop'">
          <img src="assets/img/forgot-header.svg" [ngClass]="isMobile ? 'login-image-mobile' : 'login-image'">
          <div class="card-body body-centered" [ngClass]="isMobile ? '' : 'max-size'">
            <p class="emma-subtitle">{{'mfa.title' | translate}}</p>
            <p class="emma-font-default-small">{{'mfa.subtitle' | translate}}</p>
            <ng-container *ngIf="qrRequired">
              <img class="qr-icon" [src]="qrUrl" alt="qr code"/>
            </ng-container>
            <ng-container *ngIf="!qrRequired">
              <div class="totp-container">
                <div style="display: flex;">
                  <div class="totp"><input appEmmaInput formControlName="field1" id="totp-1" (keyup)="focusNext($event)" (keydown)="deleteNext($event)" type="text" maxlength="1" size="1" autofocus /></div>
                  <div class="totp"><input appEmmaInput formControlName="field2" id="totp-2" (keyup)="focusNext($event)" (keydown)="deleteNext($event)" type="text" maxlength="1" size="1" /></div>
                  <div class="totp"><input appEmmaInput formControlName="field3" id="totp-3" (keyup)="focusNext($event)" (keydown)="deleteNext($event)" type="text" maxlength="1" size="1" /></div>
                  <div class="totp"><input appEmmaInput formControlName="field4" id="totp-4" (keyup)="focusNext($event)" (keydown)="deleteNext($event)" type="text" maxlength="1" size="1" /></div>
                  <div class="totp"><input appEmmaInput formControlName="field5" id="totp-5" (keyup)="focusNext($event)" (keydown)="deleteNext($event)" type="text" maxlength="1" size="1" /></div>
                  <div class="totp"><input appEmmaInput formControlName="field6" id="totp-6" (keyup)="focusNext($event)" (keydown)="deleteNext($event)" type="text" maxlength="1" size="1" (keydown.enter)="submit()" /></div>
                </div>
              </div>
            </ng-container>

            <a routerLink="/auth" class="padding-third">{{'auth.forgot.back' | translate}}</a>

            <div class="card-footer" style="margin-left: 0;">
              <button  class="btn btn-blue btn-custom"  (click)="next()" *ngIf="qrRequired">
                <b>{{'mfa.next' | translate}}</b>

              </button>
              <button  class="btn btn-blue btn-custom"  (click)="submit()" *ngIf="!qrRequired">
                <b>{{'mfa.login' | translate}}</b>

              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
