import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CreateCustomerDialogComponent} from '../../../dialogs/dossiers/create-customer-dialog/create-customer-dialog.component';
import { CreateBusinessCustomerDialogComponent } from '../../../dialogs/dossiers/create-business-customer-dialog/create-business-customer-dialog.component';
import { RedactedUser, User } from '../../../functional/models/user';
import { Company } from '../../../functional/models/company';
import { Organization } from '../../../functional/models/organization.model';
import { OrganizationService } from '../../../services/organization/organization.service';
import { Role } from '../../../functional/models/role.model';
import { SearchCompaniesAndCustomersComponent } from '../../search/search-companies-and-customers/search-companies-and-customers.component';
import { SearchEmployeesComponent } from '../../search/search-employees/search-employees.component';

@Component({
  selector: 'app-dossier-customer-selector',
  templateUrl: './dossier-customer-selector.component.html',
  styleUrls: ['./dossier-customer-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DossierCustomerSelectorComponent implements OnInit {
  @ViewChild('search') search!: SearchCompaniesAndCustomersComponent;
  @ViewChild('employeeSearch') employeeSearch!: SearchEmployeesComponent;

  @Output() usersChange = new EventEmitter();
  set users(users: [User | RedactedUser, number][]) {
    if (!users) {
      return;
    }
    this.usersValue = users;
    this.usersChange.emit(this.usersValue);
  }

  @Input()
  get users(): [User | RedactedUser, number][] {
    return this.usersValue;
  }

  @Output() organizationsChange = new EventEmitter();
  set organizations(organizations: [Organization, [User | RedactedUser, number][]][]) {
    if (!organizations) {
      return;
    }
    this.organizationValue = organizations;
    this.organizationsChange.emit(this.organizationValue);
  }

  @Input()
  get organizations(): [Organization, [User | RedactedUser, number][]][] {
    return this.organizationValue;
  }

  @Input() company!: Company;

  @Output() employeesChange = new EventEmitter();
  set employees(users: RedactedUser[]) {
    if (!users) {
      return;
    }
    this.employeesValue = users;
    this.employeesChange.emit(this.employeesValue);
  }

  @Input()
  get employees(): RedactedUser[] {
    return this.employeesValue;
  }

  @Input() thisUser!: User;

  searchDisplayed = true;
  selected = false;
  employeesValue: RedactedUser[] = [];
  usersValue: [User | RedactedUser, number][] = [];
  organizationValue: [Organization, [User | RedactedUser, number][]][] = [];
  employeeSearchDisplayed = false;

  constructor(
    private dialog: MatDialog,
    private organizationService: OrganizationService,
  ) {
  }

  async ngOnInit() {}

  openNewCustomer() {
    const dialogRefx = this.dialog.open(CreateCustomerDialogComponent, {
      panelClass: 'create-customer-dialog',
      data: {
        company: this.company,
        permissionsSelectable: false,
        isEmployee: false,
      },
      autoFocus: false
    });

    dialogRefx.afterClosed().subscribe((res: any) => {
      if (res && res.data) {
        this.users.push([res.data, 1]);
        this.usersChange.emit(this.usersValue);
        this.search.reset();
      }
    });
  }

  openNewBusinessCustomer() {
    const dialogRefy = this.dialog.open(CreateBusinessCustomerDialogComponent, {
      panelClass: 'create-business-customer-dialog',
      data: {
        company : this.company,
        organizationSearch: false,
        organizationEdit: true,
        allowEmployees: true
      },
      autoFocus: false
    });

    dialogRefy.afterClosed().subscribe((res: any) => {
      if (res && res.data) {
        const users: [User, number][] = res.data.users.map((user: User) => [user, 1]);
        this.organizations.push([res.data.organization, users]);
        this.organizationsChange.emit(this.organizationValue);
        this.search.clearSelected();
      }
    });
  }

  removeUser(userToRemove: User | RedactedUser) {
    this.users = this.users.filter((user: [User | RedactedUser, number]) => user[0].id !== userToRemove.id);
  }

  onUserSelected(user: RedactedUser) {
    if (!this.isUserSelected(user.id) && !this.isUserInOrganizationSelected(user.id)) {
      this.users.push([user, 1]);
      this.usersChange.emit(this.usersValue);
    }
    this.search.clearSelected();
  }

  onOrganizationSelected(organization: Organization) {
    const found = this.organizations.find(
      (organizationQueried: [Organization, [User | RedactedUser, number][]]) => organizationQueried[0].id === organization.id
    );
    if (!found) {
      this.organizationService.getUsers(organization.id!, Role.customer).then((res: any) => {
        let users: [RedactedUser, number][] = res.map((user: RedactedUser) => [user, 1]);
        users = users.filter(uFilter => !this.isUserSelected(uFilter[0].id) && ! this.isUserInOrganizationSelected(uFilter[0].id));
        this.organizations.push([organization, users]);
        this.organizationsChange.emit(this.organizations);
        this.search.clearSelected();
      });
    } else {
      this.search.clearSelected();
    }
  }

  deleteOrganization(organization: [Organization, [(User | RedactedUser), number][]]) {
    this.organizations = this.organizations.filter(oFilter => oFilter[0].id !== organization[0].id);
  }


  removeOrganizationUser(i: number, userElement: User | RedactedUser) {
    this.organizations[i][1] = this.organizations[i][1].filter(uFilter => uFilter[0].id !== userElement.id);
  }

  isUserInOrganizationSelected(userId: number) {
    return !!this.organizations.find(oFilter => oFilter[1].find(uFilter => uFilter[0].id === userId));
  }

  isUserSelected(userId: number) {
    return !!this.users.find((userQueried: [User | RedactedUser, number]) => userQueried[0].id === userId);
  }

  onEmployeeSelected(user: RedactedUser | undefined) {
    if (user) {
      const found = this.employees.find(userQueried => userQueried.id === user.id);
      if (!found) {
        this.employees.push(user);
        this.employeesChange.emit(this.employeesValue);
      }
      this.employeeSearch.clearSelected();
    }
  }

  removeEmployee(userToRemove: RedactedUser) {
    this.employees = this.employees.filter(user => user.id !== userToRemove.id);
  }

  onItemSelect(value: any) {
    if (value){
      if (value.hasOwnProperty('name')){
        this.onOrganizationSelected(value);
      }
      if (value.hasOwnProperty('firstName')){
        this.onUserSelected(value);
      }
    }
  }
}
