import {Routes} from '@angular/router';
import {ThemeComponent} from './theme/theme.component';

export const themeRoutes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'theme'
    },
    component: ThemeComponent,
  },
];
