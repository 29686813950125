<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div class="user-selector-dialog dialog-content">
  <form [formGroup]="userForm" *ngIf="userForm" id="organization-form">
    <div class="row">
      <div class="col-md-12 centered">
        <p class="emma-title">{{
          onlyEmployees ?
            ('dialog.create.add_employee' | translate) :
            ('dialog.create.add_contactperson' | translate)
        }}</p>
      </div>
    </div>

    <div class="row" *ngIf="t.controls.length !== 0">
      <div class="col-md-12">
        <p class="emma-subtitle"> {{
          onlyEmployees ?
            ('dialog.create.employees'| translate) :
            ('dialog.create.contact_person' | translate)
          }} </p>
      </div>
    </div>

    <div class="row" *ngFor="let item of t.controls let i = index" [formGroup]="item">
      <div class="col-md-12">
        <mat-accordion id="accordion">
          <mat-expansion-panel [expanded]="i === 0">
            <mat-expansion-panel-header id="expansion-header" class="expansion-header-flex">
              <mat-panel-title>
                {{item.get('firstName')!.value && item.get('lastName')!.value ? item.get('firstName')!.value + ' ' + item.get('lastName')!.value : 'Contactpersoon ' + (i + 1) }}
              </mat-panel-title>
              <mat-icon (click)="deleteUser(i)" id="delete-user" class="trash">delete</mat-icon>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-md-6" id="first-name">
                <div class="emma-input-container">
                  <label label for="firstName" class="emma-input-label">{{ 'users.create.firstname'  | translate }}</label>
                  <input appEmmaInput id="firstName" formControlName="firstName">
                </div>
              </div>
              <div class="col-md-6" id="last-name">
                <div class="emma-input-container">
                  <label label for="lastName" class="emma-input-label">{{ 'users.create.lastname'  | translate }}</label>
                  <input appEmmaInput id="lastName" formControlName="lastName">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="emma-input-container">
                  <label label for="email" class="emma-input-label">{{ 'users.create.email'  | translate }}</label>
                  <input appEmmaInput id="email" formControlName="email">
                </div>
              </div>
              <div class="col-md-6">
                <div class="emma-input-container">
                  <label label for="phone" class="emma-input-label">{{ 'users.create.phone'  | translate }}</label>
                  <input appEmmaInput id="phone" formControlName="phone">
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!item.get('id')!.value">
              <div class="col-md-6 col-centered-reverse">
                <mat-label>{{'users.create.sendmail' | translate}}</mat-label>
                <mat-checkbox formControlName="sendMail"></mat-checkbox>
              </div>
            </div>
            <mat-accordion id="accordion-extra">
              <mat-expansion-panel class="hidden-indicator">
                <mat-expansion-panel-header class="no-padding centered">
                  <mat-panel-title class="underline">
                    {{ 'dialog.create.other' | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="gender" class="emma-input-label">{{'users.create.gender'  | translate }}</label>
                      <app-emma-select
                        id="gender"
                        layout="input"
                        [fullWidth]="true"
                        [renderButton]="true"
                        [options]="[
                            {displayName: 'users.create.undefined' | translate, value: 0},
                            {displayName: 'users.create.male' | translate, value: 1},
                            {displayName: 'users.create.female' | translate, value: 2},
                            {displayName: 'users.create.notapplicable' | translate, value: 9},
                          ]"
                        formControlName="gender">
                      </app-emma-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="birth" class="emma-input-label">{{'users.create.birthdate'  | translate }}</label>
                      <div class="emma-input">
                        <input id="birth" appEmmaInput formControlName="birth" [matDatepicker]="picker">
                        <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
                      </div>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="street" class="emma-input-label">{{'dialog.create.street' | translate }}</label>
                      <input appEmmaInput id="street" formControlName="street">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="postalCode" class="emma-input-label">{{'dialog.create.postalcode' | translate }}</label>
                      <input appEmmaInput id="postalCode" formControlName="postalCode">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="emma-input-container">
                      <label label for="houseNumber" class="emma-input-label">{{'dialog.create.house_number' | translate }}</label>
                      <input appEmmaInput id="houseNumber" formControlName="houseNumber">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="emma-input-container">
                      <label label for="premise" class="emma-input-label">{{'dialog.create.addon' | translate }}</label>
                      <input appEmmaInput id="premise" formControlName="premise">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="city" class="emma-input-label">{{'dialog.create.residence' | translate }}</label>
                      <input appEmmaInput id="city" formControlName="city">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="emma-input-container">
                      <label label for="relationId" class="emma-input-label">{{'dialog.create.relationId' | translate }}</label>
                      <input appEmmaInput id="relationId" formControlName="relationId">
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered" id="search-customer" *ngIf="customerSearch || onlyEmployees">
        <app-search-customers-and-employees
          class="customer-search"
          [company]="company"
          (valueChange)="onUserSelected($event?.value)"
          *ngIf="allowEmployees && !onlyEmployees"
        ></app-search-customers-and-employees>
        <app-search-customers
          class="customer-search"
          [company]="company"
          (valueChange)="onUserSelected($event?.value)"
          *ngIf="!allowEmployees && !onlyEmployees">
        </app-search-customers>
        <app-search-employees
          [company]="company"
          (valueChange)="onUserSelected($event?.value)"
          *ngIf="onlyEmployees">
        </app-search-employees>
      </div>
      <div class="col-md-12 centered" id="create-customer" *ngIf="!onlyEmployees">
        <p class="link-text" (click)="customerSearch = true">{{ 'dialog.create.search_contactperson' | translate }}</p> <p class="link-text-normal">&nbsp;/&nbsp;</p> <p class="link-text" (click)="newUser()">{{ 'dialog.create.new_contactperson' | translate }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered extra-margin">
        <button  class="btn btn-blue btn-custom" id="submit" (click)="onSubmit()">
          <b>{{ 'dialog.create.add' | translate }}</b>
          <ng-icon name="heroPlusSmall" class="ng-icon-default-size"></ng-icon>
        </button>
      </div>
    </div>
  </form>
</div>
