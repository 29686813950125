import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomerDetailsComponent} from './customer-details.component';
import {UserIconModule} from '../user-icon/user-icon.module';
import {EmmaSigningOverviewModule} from '../signing/emma-signing-overview/emma-signing-overview.module';
import { NgIconsModule } from '@ng-icons/core';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {TranslateModule} from '@ngx-translate/core';
import {TagModule} from '../tag/tag.module';
import {UserRecentsModule} from '../user-recents/user-recents.module';



@NgModule({
  declarations: [
    CustomerDetailsComponent
  ],
  imports: [
    CommonModule,
    UserIconModule,
    EmmaSigningOverviewModule,
    NgIconsModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule.forRoot(),
    TagModule,
    UserRecentsModule
  ],
  exports: [
    CustomerDetailsComponent
  ]
})
export class CustomerDetailsModule { }
