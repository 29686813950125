/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CreateDocumentModel, Document, DocumentSkeleton, DocumentFilterModel, UpdateDocumentModel} from '../../functional/models/document';
import {FolderCacheService} from '../folder-cache/folder-cache.service';
import {Folder} from '../../functional/models/folder';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private url = `${environment.rootUrl}document`;

  constructor(
    private http: HttpClient,
    private folderCache: FolderCacheService
  ) { }

  async create(file: Blob, model: CreateDocumentModel, error: (response: Response) => void): Promise<Document> {
    const document_skeleton = await this.http.post<DocumentSkeleton>(`${this.url}/create`, model).toPromise();
    
    const document = DocumentSkeleton.asDocument(document_skeleton);
    this.folderCache.insertFile(document);

    const upload_response = await fetch(document_skeleton.signedUrl, {
      method: 'PUT',
      body: file,
    });
    if(!upload_response.ok) {
      error(upload_response);
      throw upload_response;
    }
    return document;
  }

  get(documentId: number): Promise<Document> {
    return this.http.get<Document>(`${this.url}/${documentId}`).toPromise();
  }

  async clone(documentId: number, targetFolderId: number) {
    await this.http.post(`${this.url}/${documentId}/copy`, {targetFolder: targetFolderId}).toPromise();
    this.folderCache.deleteFolder(targetFolderId);
  }

  getAll(filter?: DocumentFilterModel): Promise<Document[]> {

    if (filter) {
      let urlParams = new HttpParams();
      if (filter.userId) {
        urlParams = urlParams.set('user_id', JSON.stringify(filter.userId));
      }
      if (filter.companyId) {
        urlParams = urlParams.set('company_id', JSON.stringify(filter.companyId));
      }
      if (filter.end) {
        urlParams = urlParams.set('end', JSON.stringify(filter.end));
      }
      if (filter.start) {
        urlParams = urlParams.set('start', filter.start);
      }
      if (filter.parentFolderId) {
        urlParams = urlParams.set('parent_folder_id', JSON.stringify(filter.parentFolderId));
      }
      if (filter.label) {
        urlParams = urlParams.set('label', JSON.stringify(filter.label));
      }
      if (filter.parentUserId) {
        urlParams = urlParams.set('parent_user_id', JSON.stringify(filter.parentUserId));
      }

      return this.http.get<Document[]>(this.url, { params: urlParams }).toPromise();
    } else {
      return this.http.get<Document[]>(this.url).toPromise();
    }
  }

  readByCompany(companyId: number): Promise<Document[]> {
    return this.http.get<Document[]>(this.url, {
      params: {
        company_id: companyId.toString(),
      }
    }).toPromise();
  }

  async update(documentId: number, model: UpdateDocumentModel): Promise<Document> {
    const document = await this.http.put<Document>(`${this.url}/${documentId}`, model).toPromise();
    this.folderCache.deleteFile(documentId);
    this.folderCache.insertFile(document);
    return document;
  }

  async delete(documentId: number): Promise<void> {
    await this.http.delete<void>(`${this.url}/${documentId}`).toPromise();
    this.folderCache.deleteFile(documentId);
  }

  getUrl(documentId: number, disposition?: string): Promise<{ url: string }> {
    const toAdd = disposition ? `?disposition=${disposition}` : '';
    return this.http.get<{ url: string }>(`${this.url}/${documentId}/get_url/${toAdd}`).toPromise();
  }

  getPath(documentId: number) {
    return this.http.get<Folder[]>(`${this.url}/${documentId}/path`).toPromise();
  }
}
