import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {RedactedUser, User} from '../../functional/models/user';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {SelectionModel} from '@angular/cdk/collections';
import {Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Company} from '../../functional/models/company';
import {UserSelectorDialogComponent} from '../../dialogs/user-selector-dialog/user-selector-dialog.component';

@Component({
  selector: 'app-connected-users',
  templateUrl: './connected-users.component.html',
  styleUrls: ['./connected-users.component.scss']
})
export class ConnectedUsersComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(MatSort) set content(content: any) {
    if (content && this.dataSource) {
      this.dataSource.sort = content;
      this.dataSource.paginator = this.paginator;
    }
  }

  @Input() isCustomer!: boolean;
  @Input() isRedacted!: boolean;
  @Input() selectable!: boolean;
  @Input() deleteable!: boolean;

  @Input() set users(users: RedactedUser[] | User[]) {
    for (const user of users) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/dot-notation
      user['name'] = user.firstName + ' ' + user.lastName;
    }
    this.dataSource = new MatTableDataSource<RedactedUser | User>(users);
  }

  @Input() company!: Company;

  @Output() addedUsers = new EventEmitter<User[]>();
  @Output() deletedUsers = new EventEmitter<(RedactedUser | User)[]>();

  selection = new SelectionModel<User | RedactedUser>(true, []);
  dataSource: MatTableDataSource<RedactedUser | User> = new MatTableDataSource<RedactedUser | User>([]);
  displayedColumns: string[] = ['name'];

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    if (this.isRedacted){
      this.displayedColumns = [...this.displayedColumns, 'phone'];
    } else {
      this.displayedColumns = [...this.displayedColumns, 'address'];
    }
    if (this.deleteable){
      this.displayedColumns = [...this.displayedColumns, 'delete'];
    }
    if (this.selectable){
      this.displayedColumns = ['select', ...this.displayedColumns];
    }
  }

  addUsers(allowEmployees: boolean, onlyEmployees = false): Promise<User[]> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(UserSelectorDialogComponent, {
        panelClass: 'user-selector-dialog',
        data: {
          company: this.company,
          allowEmployees,
          onlyEmployees
        },
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(async (res) => {
        if (res) {
          resolve(res.data.users);
        } else {
          resolve([]);
        }
      });
    });
  }

  masterToggle() {
    if(this.isAllSelected()){
      this.selection.clear();
    }else{
      this.dataSource.filteredData.forEach((row: User | RedactedUser) => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  async gotoUser(row: RedactedUser | User) {
    if (this.isCustomer) {
      await this.router.navigate(['management', 'customers', 'details', row.id]);
    } else {
      await this.router.navigate(['management', 'employees', 'details', row.id]);
    }
  }

  deleteRow(row: RedactedUser | User, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.deletedUsers.emit([row]);
  }
}
