<div class="previous-message-container">
  <div>
    <div class="time-user-container">
      <p class="nospacing message-title">{{message.sender.firstName + ' ' + message.sender.lastName}}</p>
      <p class="message-time">{{message.creationDate | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
    </div>
    <p class="nospacing">{{message.subject}}</p>
    <div class="flex"><p class="nospacing" >{{'messaging.to' | translate}}:&nbsp;</p><p class="nospacing message-recipient">{{message.recipient.firstName + ' ' + message.recipient.lastName}}</p></div>
  </div>
  <mat-divider class="message-devider"></mat-divider>
  <p [innerHTML]="message.content | linky"></p>
</div>
<app-reply-item [message]="message.replyTo" *ngIf="message.replyTo"></app-reply-item>
