/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

class Label {
  displayName: string;

  constructor(public name: string, private translate: TranslateService) {
    this.displayName = Label.getDisplayName(name, this.translate) || name;
  }

  static getDisplayName(name: string, translate: TranslateService): string | undefined {
    const dict: Record<string, string> = {
      Relationtype_own_company: translate.instant('addon.own'),
      Relationtype_competitor: translate.instant('addon.concurrent'),
      Relationtype_employee: translate.instant('addon.employee'),
      Relationtype_person_at_organization: translate.instant('addon.relation'),
      Relationtype_private_person: translate.instant('addon.private'),
    };
    return dict[name];
  }
}


@Component({
  selector: 'app-simplicate-labels-dialog',
  templateUrl: './simplicate-labels-dialog.component.html',
  styleUrls: ['./simplicate-labels-dialog.component.scss']
})
export class SimplicateLabelsDialogComponent implements OnInit {
  labels!: Label[];
  form!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SimplicateLabelsDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data: { labels: string[] },
  ) {
    if (!data.labels) {
      throw new Error('SimplicateLabelsDialogComponent created with no labels');
    }

    this.labels = data.labels.map(l => new Label(l, this.translate));
  }

  ngOnInit(): void {
    const formContent: Record<string, unknown> = {};
    for (const label of this.labels) {
      formContent[label.name] = [true, [Validators.required]];
    }
    this.form = this.formBuilder.group(formContent);
  }

  close(shouldSync: boolean) {
    if (!shouldSync) {
      this.dialogRef.close();
      return;
    }
    const labels = Object.keys(this.form.controls)
      .filter((property) => this.form.get(property)?.value);
    this.dialogRef.close(labels);
  }
}
