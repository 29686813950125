<div class="table-paginator-container">
  <div class="mat-table-container group-table">
    <table mat-table [dataSource]="groupDataSource" matSort class="emma-table">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'users.details.groups_name' | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'users.details.groups_description' | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.description}} </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <ng-icon name="heroTrash" class="ng-icon-default hover-hero" (click)="deleteGroup(row, $event)"></ng-icon>
        </td>
      </ng-container>

      <tr class="empty-row" *matNoDataRow>
        <td colspan="9999">
          <div class="illustration">
            <img src="assets/img/illustrations/community.svg">
            <h1 class="not-found-title">{{'users.details.no_groups' | translate}}</h1>
            <span>
              {{'users.details.no_groups_reason' | translate}}
            </span>
          </div>
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="['name', 'description', 'delete']; sticky: true" ></tr>
      <tr mat-row class="element" *matRowDef="let row; columns: ['name', 'description', 'delete'];" (click)="toGroup(row)"></tr>
    </table>
  </div>
  <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]" ></mat-paginator>
</div>
