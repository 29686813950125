/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExactService {
  params = new HttpParams();

  constructor(public http: HttpClient) {
    this.params = this.params.set('kind', 'exact');
  }

  getUrl(returnUrl: string, token: string, responseType: string = 'code') {
    const encodedUrl = encodeURIComponent(`${environment.exactOnline.redirectUri}?returnUrl=${returnUrl}&addon=2&token=${token}`);
    return `https://start.exactonline.nl/api/oauth2/auth?client_id=${environment.exactOnline.clientId}
    &redirect_uri=${encodedUrl}&response_type=${responseType}`;
  }

  getConnection(companyId: number) {
    const params = this.params.set('company_id', companyId.toString());
    return this.http.get(`${environment.rootUrl}interface`, { params }).toPromise();
  }

  synchronise(companyId: number, labels: ExactSyncModel) {
    const params = this.params.set('company_id', companyId.toString());
    return this.http.put(`${environment.rootUrl}interface/sync`,  labels, { params }).toPromise();
  }

  deleteConnection(companyId: number) {
    const params = this.params.set('company_id', companyId.toString());
    return this.http.delete(`${environment.rootUrl}interface`, { params }).toPromise();
  }

  meta(companyId: number): Promise<ExactMetaModel[]> {
    const params = this.params.set('company_id', companyId.toString());
    return this.http.get<ExactMetaModel[]>(`${environment.rootUrl}interface/meta`, { params }).toPromise();
  }
}

export class ExactMetaModel {
  description!: string;
  divisionCode!: number;
  divisionName!: string;
  roles!: {
    Code: number;
    Description: string;
  }[];
}

export class ExactMetaFormModel extends ExactMetaModel {
  synchronised?: boolean;
  destinationFolder?: number;
}

export class ExactSyncModel {
  documents!: {
    division: number;
    folder: number;
    name: string;
  }[];

  organizations!: {
    division: number;
  }[];

  users!: {
    division: number;
    roles: number[];
  }[];
}

