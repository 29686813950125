import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RecursiveTreeComponent} from './recursive-tree.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';

@NgModule({
  declarations: [
    RecursiveTreeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    RecursiveTreeComponent
  ]
})
export class RecursiveTreeModule { }
