<div class="example-list">
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <app-survey-question #element (click)="editing = element" [editing]="editing == element" class="example-box" cdkDrag *ngFor="let question of questions">

<!--      <div class="drag-placeholder" *cdkDragPlaceholder></div>-->
    </app-survey-question>
  </div>

  <button style="margin-left: 8px; margin-bottom: 5px"  class="btn btn-blue btn-custom" (click)="questions.push('yeet')">Vraag toevoegen</button>
</div>
