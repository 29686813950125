import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddGroupDialogComponent } from './add-group-dialog.component';
import { NgIconsModule } from '@ng-icons/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {SearchGroupsModule} from '../../components/search/search-groups/search-groups.module';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [
    AddGroupDialogComponent
  ],
  imports: [
    CommonModule,
    NgIconsModule,
    MatButtonModule,
    SearchGroupsModule,
    TranslateModule.forRoot()
  ]
})
export class AddGroupDialogModule { }
