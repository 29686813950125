import { Injectable } from '@angular/core';
import { Folder } from 'app/functional/models/folder';
import {ListResponse} from '../folder/folder.service';
import {Document} from '../../functional/models/document';

export class FolderCache {
  parentFolderId!: number;
  listResponse!: ListResponse;
  validTill!: Date;
}

@Injectable({
  providedIn: 'root'
})
export class FolderCacheService {
  private listResponseCache: FolderCache[] = [];
  //set cache time as 5 minutes
  private cacheTime = 5 * 60000;

  constructor() { }

  insertFolder(folder: Folder) {
    const parentFolder = this.listResponseCache.find(item => item.parentFolderId === folder.parentFolder?.id);
    if (parentFolder){
      parentFolder.listResponse.folders.push(folder);
    }
  }

  insertFile(file: Document) {
    const parentFolder = this.listResponseCache.find(item => item.parentFolderId === file.parentFolder.id);
    if (parentFolder){
      parentFolder.listResponse.files.push(file);
    }
  }

  deleteFolder(folderId: number) {
    this.deleteRootCache(folderId);
    this.listResponseCache.forEach(child => child.listResponse.folders = child.listResponse.folders.filter(item => item.id !== folderId));
  }

  deleteFile(fileId: number) {
    this.listResponseCache.forEach(child => child.listResponse.files = child.listResponse.files.filter(item => item.id !== fileId));
  }

  insertRootCache(folderId: number, childFilesAndFolders: ListResponse) {
    this.listResponseCache.push({
      parentFolderId: folderId,
      listResponse: childFilesAndFolders,
      validTill: new Date(Date.now() + this.cacheTime)
    });
  }

  deleteRootCache(folderId: number) {
    this.listResponseCache = this.listResponseCache.filter(item => item.parentFolderId !== folderId);
  }

  getRootCache(folderId: number) {
    const cached = this.listResponseCache.find(item => item.parentFolderId === folderId);

    if (!cached){
      return null;
    }

    if (cached.validTill.getTime() < new Date().getTime()){
      this.deleteRootCache(folderId);
      return null;
    }

    return cached;
  }
}
