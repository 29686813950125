import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ChatService} from '../../../services/chat/chat.service';

@Component({
  selector: 'app-archive-chat-dialog',
  templateUrl: './archive-chat-dialog.component.html',
  styleUrls: ['./archive-chat-dialog.component.scss']
})
export class ArchiveChatDialogComponent implements OnInit {

  chatId!: number;

  constructor(
    public dialogRef: MatDialogRef<ArchiveChatDialogComponent>,
    private chatService: ChatService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.chatId = this.data.chatId;
  }

  async archive() {
    await this.chatService.archiveChat(this.chatId);
    this.dialogRef.close(true);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
