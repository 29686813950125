import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export enum DeleteType {
  file,
  dossier,
  folder,
  action,
  request,
  step,
  rights,
  parentUser
}

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements OnInit {

  deleteType: DeleteType = DeleteType.file;
  toDelete: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data?.deleteType) {
      this.deleteType = data.deleteType;
    }

    if (data?.toDelete){
      this.toDelete = data.toDelete;
    }
  }

  ngOnInit(): void {}

  deleteFiles() {
    this.dialogRef.close(true);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
