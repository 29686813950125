<div class="emma-body emma-body-workflow">
  <app-sub-nav
    [search]="true"
    (searchChange)="applyFilter($event)"
    [buttons]="[{icon:'add', name: 'new' | translate, event: 'new'},{name:'options', event: 'options'}]"
    (buttonClicked)="openCreateDossierTemplate()">
  </app-sub-nav>
  <div class="template-cols">
    <ng-container *ngFor="let template of filteredTemplates">
      <mat-card class="template-card" routerLink="/settings/workflow/{{template.id}}">
        <img class="template-card-image" mat-card-image src="assets/img/templates/standard.svg">
        <mat-card-content>
          <span class="emma-subtitle1">{{template.name}}</span><br />
          <span class="emma-font-default-small">{{template.description}}</span>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</div>
