import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Dossier } from 'app/functional/models/dossier.model';
import { SigningService } from 'app/services/signing/signing.service';
import { Document } from 'app/functional/models/document';
import { SignType } from 'app/functional/models/signing';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

class Data {
  dossier!: Dossier;
  files!: Document[];
  signType!: SignType;
}

@Component({
  selector: 'app-request-signing-dialog',
  templateUrl: './request-signing-dialog.component.html',
  styleUrls: ['./request-signing-dialog.component.scss']
})
export class RequestSigningDialogComponent implements OnInit {
  dossier!: Dossier;
  files!: Document[];
  signType!: number;

  constructor(
    public dialogRef: MatDialogRef<RequestSigningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private signingService: SigningService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.dossier = data.dossier;
    this.files = data.files;
    this.signType = data.signType;
  }

  ngOnInit(): void { }

  async submit() {
    // @ts-ignore
    if (!this.dossier.customers.some(user => !!user.signed)){
      this.snackBar.open(this.translate.instant('dialog.request.no_users'), '', { duration: 5000 });
      return;
    }

    // SignType.signature
    for (const file of this.files) {
      for (const user of this.dossier.customers) {
        // @ts-ignore
        if (user.signed) {
          await this.signingService.create({
            fileId: Number(file.id),
            userId: user.id,
            message: '',
            signingType: this.signType,
            dossierId: this.dossier.id,
          });
        }
      }
    }
    this.dialogRef.close({ confirmed: true });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
