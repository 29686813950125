import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfirmDialogType} from '../../../functional/models/dialog.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {

  name?: string;
  return = false;
  displayImage: any;
  confirmDialogType = ConfirmDialogType;
  availableImages = [
    {image: '../../../../assets/img/modals/archived.svg', centered: false},
    {image: '../../../../assets/img/modals/administration-submitted.svg', centered: true, bottom: true},
    {image: '../../../../assets/img/modals/add-document-to-dossier-closed.svg', centered: true, bottom: true},
    {image: '../../../../assets/img/modals/document-request-admission.svg', centered: true, bottom: true},
    {image: '../../../../assets/img/modals/dossier-sent-to-customer.svg', centered: false},
    {image: '../../../../assets/img/modals/signed.svg', centered: true},
    {image: '../../../../assets/img/modals/submit-administration.svg', centered: true, bottom: true}
  ];

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.displayImage = this.getImageFromType(data.dialogType);
  }

  getImageFromType(dialogType: ConfirmDialogType) {
    switch (dialogType) {
      case ConfirmDialogType.archived:
        return this.availableImages[0];
      case ConfirmDialogType.addDocumentToClosedDossier:
        return this.availableImages[2];
      case ConfirmDialogType.administrationSubmitted:
        return this.availableImages[1];
      case ConfirmDialogType.documentsApproved:
        return this.availableImages[5];
      case ConfirmDialogType.requestApproval:
        return this.availableImages[3];
      case ConfirmDialogType.sendToCustomer:
        return this.availableImages[4];
      case ConfirmDialogType.submitAdministration:
        return this.availableImages[6];
      case ConfirmDialogType.skipFiles:
        return this.availableImages[4];
      case ConfirmDialogType.skipSignatures:
        return this.availableImages[3];
      case ConfirmDialogType.deleteUser:
        return null;
      case ConfirmDialogType.deleteOrganization:
        return null;
      case ConfirmDialogType.employeeLimitReached:
        return null;
      case ConfirmDialogType.deleteGroup:
        return null;
      case ConfirmDialogType.deleteDossier:
        return null;
      case ConfirmDialogType.moveFile:
        return this.availableImages[6];
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  onSubmit(toDashboard: boolean = false) {
    this.dialogRef.close({
      confirmed: true,
      toDashboard
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}


