import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DossierTemplateService} from '../../../services/dossier-template/dossier-template.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-dossier-template-step-dialog',
  templateUrl: './add-dossier-template-step-dialog.component.html',
  styleUrls: ['./add-dossier-template-step-dialog.component.scss']
})
export class AddDossierTemplateStepDialogComponent implements OnInit {
  addStepForm!: UntypedFormGroup;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public templateService: DossierTemplateService,
    public dialogRef: MatDialogRef<AddDossierTemplateStepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.addStepForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      mayHaveDeadline: [true, [Validators.required]]
    });
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  async addStep() {
    this.addStepForm.markAllAsTouched();

    if (this.addStepForm.invalid){
      return;
    }

    const result = await this.templateService.createState(this.data.template.id,{
      name: this.addStepForm.get('name')?.value,
      finalState: false,
      initialState: false,
      mayHaveDeadline: this.addStepForm.get('mayHaveDeadline')?.value
    });
    this.dialogRef.close(result);
  }
}
