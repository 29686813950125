import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import {RouterModule} from '@angular/router';
import {generalRoutes} from './general.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {InboxOverviewModule} from '../../components/inbox-overview/inbox-overview.module';
import {DossierOverviewModule} from '../../components/dossiers/dossier-overview/dossier-overview.module';
import {MatIconModule} from '@angular/material/icon';
import {MailSettingsComponent} from '../../components/mail-settings/mail-settings.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatPasswordStrengthModule} from '../../components/mat-password-strength/mat-password-strength.module';
import {UserRecentsModule} from '../../components/user-recents/user-recents.module';
import {EmmaSelectModule} from '../../components/base-components/emma-select/emma-select.module';
import {AutocompleteSelectModule} from '../../components/base-components/autocomplete-select/autocomplete-select.module';
import {EmmaInputModule} from '../../components/base-components/emma-input/emma-input.module';
import {SubNavModule} from '../../components/sub-nav/sub-nav.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroChevronDown } from '@ng-icons/heroicons/outline';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    BrowserAnimationsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule.forChild(generalRoutes),
    TranslateModule.forRoot(),
    MatPasswordStrengthModule,
    InboxOverviewModule,
    DossierOverviewModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDividerModule,
    UserRecentsModule,
    EmmaSelectModule,
    NgIconsModule.withIcons({
      heroChevronDown
    }),
    AutocompleteSelectModule,
    EmmaInputModule,
    SubNavModule,
  ],
  providers: [
    DatePipe
  ],
  declarations: [DashboardComponent, ProfileComponent, MailSettingsComponent]
})
export class GeneralModule { }
