<div class="main-content" style="overflow-y: auto;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card" id="company-details-block">
          <div class="card-header card-header-grey card-header-icon">
            <div class="card-icon card-header-custom"><mat-icon>business</mat-icon></div>
            <div class="exchange-card-title-between">
              <h4 class="card-title">
                {{'company.edit' | translate}}
              </h4>
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="companyForm" *ngIf="companyForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="emma-input-container">
                    <label label for="name" class="emma-input-label">{{ 'company.name' | translate }}</label>
                    <input appEmmaInput id="name" formControlName="name">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="emma-input-container">
                    <label label for="fromEmail" class="emma-input-label">{{ 'company.email' | translate }}</label>
                    <input appEmmaInput id="fromEmail" formControlName="fromEmail">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="emma-input-container">
                    <label label for="diskSpace" class="emma-input-label">{{ 'company.diskspace' | translate }}</label>
                    <input appEmmaInput id="diskSpace" formControlName="diskSpace">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="emma-input-container">
                    <label label for="maxEmployees" class="emma-input-label">{{ 'company.employees' | translate }}</label>
                    <input appEmmaInput id="maxEmployees" formControlName="maxEmployees">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="emma-input-container">
                    <label label for="maxCustomers" class="emma-input-label">{{ 'company.customers' | translate }}</label>
                    <input appEmmaInput id="maxCustomers" formControlName="maxCustomers">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="emma-input-container">
                    <label label for="gender" class="emma-input-label">{{'company.typename'  | translate }}</label>
                    <app-emma-select
                      id="gender"
                      layout="input"
                      [fullWidth]="true"
                      [renderButton]="true"
                      formControlName="companyType"
                      [options]="[
                        {displayName: 'company.type.bookkeeper' | translate, value: '0'},
                        {displayName: 'company.type.care' | translate, value: '1'}
                      ]">
                    </app-emma-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="emma-input-container">
                    <label label for="minLength" class="emma-input-label">Minimale wachtwoord lengte</label>
                    <input appEmmaInput id="minLength" formControlName="minLength">
                  </div>
                </div>
              </div>
              <button  type="submit" class="btn btn-danger pull-right" (click)="deleteCompany()">Bedrijf verwijderen</button>
              <button  type="submit" class="btn btn-blue btn-custom pull-right" (click)="update()">{{'company.submit_edit' | translate}}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card" id="company-details-persons-block">
          <div class="card-header card-header-grey card-header-icon">
            <div class="card-icon card-header-custom"><mat-icon>person</mat-icon></div>
            <div class="exchange-card-title-between">
              <h4 class="card-title">
                {{'company.users' | translate}}
              </h4>
            </div>
          </div>
          <div class="card-body d-flex table-responsive justify-content-center" *ngIf="users.length == 0">
            <div class="alert alert-grey alert-with-icon col-md-12 justify-content-center">
              <mat-icon>info</mat-icon>
              <span data-notigy="message">{{'company.no_users' | translate}}</span>
            </div>
          </div>
          <div class="card-body table-responsive" *ngIf="users.length > 0">
            <div class="emma-input-container">
              <div class="rounded-input" style="width: 500px; height: 40px">
                <input (keyup)="applyFilter($event.target.value)" placeholder="{{'company.filter_users' | translate}}">
                <div class="rounded-input-icon">
                  <mat-icon>search</mat-icon>
                </div>
              </div>
            </div>

            <div class="table-container">
              <table mat-table [dataSource]="userSource" matSort class="emma-table">
                <!-- Name Column -->
                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'company.user_firstname' | translate}} </th>
                  <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'company.user_lastname' | translate}}  </th>
                  <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'company.user_email' | translate}}  </th>
                  <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['firstName', 'lastName', 'email']; sticky: true" ></tr>
                <tr mat-row class="element" *matRowDef="let row; columns: ['firstName', 'lastName', 'email'];" (click)="redirect(row.id)"></tr>
              </table>
            </div>
            <mat-paginator fixed [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12" *ngIf="companyId">
        <app-url-selector [companyId]="companyId"></app-url-selector>
      </div>
    </div>
  </div>
</div>
