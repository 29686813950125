import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierConfigurationComponent } from './dossier-configuration.component';
import { SearchEmployeesModule } from '../../search/search-employees/search-employees.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserIconModule } from '../../user-icon/user-icon.module';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { EmmaInputModule } from '../../base-components/emma-input/emma-input.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroTrash } from '@ng-icons/heroicons/outline';

@NgModule({
  declarations: [DossierConfigurationComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatIconModule,
    MatListModule,
    FormsModule,
    OverlayModule,
    SearchEmployeesModule,
    MatDatepickerModule,
    TranslateModule.forRoot(),
    UserIconModule,
    NgIconsModule.withIcons({
      heroTrash
    }),
    MatChipsModule,
    EmmaInputModule,
  ],
  exports: [
    DossierConfigurationComponent
  ]
})
export class DossierConfigurationModule { }
