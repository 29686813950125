import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Company } from 'app/functional/models/company';
import { Claims, RedactedUser } from 'app/functional/models/user';
import { Organization } from 'app/functional/models/organization.model';
import { OrganizationService } from 'app/services/organization/organization.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { FolderService } from 'app/services/folder/folder.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Group } from '../../../functional/models/group';
import { SearchCompaniesCustomersEmployeesGroupsComponent } from '../../../components/search/search-companies-customers-employees-groups/search-companies-customers-employees-groups.component';
import { Folder } from '../../../functional/models/folder';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '../../../functional/models/role.model';
import { DynamicFlatNode, SourceFilter } from '../../../components/recursive-tree/recursive-tree.component';
import {CompanyService} from '../../../services/company/company.service';

export class NameErrorStateMatcher implements ErrorStateMatcher {

  constructor(public currentFolders: Folder[]) {
  }

  hasExistingFolder(name: string) {
    return this.currentFolders.find((folder) => folder.name === name);
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    const isValid = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    return isValid || !!this.hasExistingFolder(control!.value);
  }
}

@Component({
  selector: 'app-create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss']
})
export class CreateFolderDialogComponent implements OnInit {
  company!: Company;
  users: RedactedUser[] = [];
  userForms: any = {};

  groups: Group[] = [];
  groupForms: UntypedFormGroup[] = [];

  organizations: {
    organization: Organization;
    members: {
      employees: RedactedUser[];
      customers: RedactedUser[];
    };
  }[] = [];

  folderName = '';
  parentFolder!: Folder;
  parentFolderPermissions!: string;
  currentFolders: Folder[] = [];
  nameForm = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(255),
    Validators.pattern('([A-Za-z0-9 _\\-+.\',()&€£¢$À-ÖØ-ßà-öø-ÿŠŒŽšœžŸµ]+)'),
    this.nameExists()
  ]);
  matcher!: ErrorStateMatcher;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Role = Role;
  currentUser: Claims;
  fileTreeFilter!: SourceFilter;
  pickedFolder?: Folder;
  templateFolder?: Folder;
  isFromDuplicate?: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateFolderDialogComponent>,
    public organizationService: OrganizationService,
    private companyService: CompanyService,
    private folderService: FolderService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.company = data.company;
    this.parentFolder = data.parentFolder;
    this.currentFolders = data.currentFolders;
    this.parentFolderPermissions = data.parentFolderPermissions;
    if (this.currentFolders) {
      this.matcher = new NameErrorStateMatcher(this.currentFolders);
    }
    this.isFromDuplicate = data.fromDuplicate;
    this.currentUser = data.currentUser;

    if (!this.parentFolder || this.isFromDuplicate) {
      if (this.currentUser.role === Role.superuser || this.currentUser.role === Role.admin) {
        this.fileTreeFilter = {
          companyId: this.company.id,
          skipRoot: this.isFromDuplicate
        };
      } else {
        this.fileTreeFilter = {
          userId: this.currentUser.userId
        };
      }
    }
  }

  nameExists(): ValidatorFn  {
    return (control: AbstractControl): ({ [key: string]: boolean } | null) => {
      const exists = this.hasExistingFolder(control.value);
      if (!exists) {
        return null;
      }
      return {nameInvalid: true} ;
    };
  }

  async ngOnInit() {
    if (this.data.fromDuplicate) {
      this.parentFolder = await this.companyService.getRoot(this.data.company.id);
      this.currentFolders = await this.folderService.childFolders(this.parentFolder.id);
    }

    if (this.data.users?.length > 0) {
      for (const user of this.data.users) {
        this.addUser(user);
      }
    }

    if (this.data.organizations?.length > 0) {
      for (const organization of this.data.organizations) {
        await this.addOrganization(organization);
      }
    }
  }

  setFolderName() {
    if (this.data.fromDuplicate) {
      if (this.organizations?.length > 0) {
        this.nameForm.setValue(this.filterFilename(this.organizations[ 0 ].organization.name));
        return;
      }

      if (this.users?.length > 0) {
        this.nameForm.setValue(this.filterFilename(this.users[ 0 ].firstName + ' ' + this.users[ 0 ].lastName));
        return;
      }
    }
  }

  filterFilename(filename: string): string {
    return filename.replace(/[<>:"/\\|?*]|\.$| +$/g, '');
  }

  hasExistingFolder(name: string) {
    return this.currentFolders.find((folder) => folder.name === name);
  }

  async submit() {
    if (!this.nameForm.value || this.nameForm.invalid || this.hasExistingFolder(this.nameForm.value)) {
      return;
    }

    let createdFolder: Folder;

    if (this.isFromDuplicate) {
      createdFolder = await this.folderService.cloneFolder(this.templateFolder!.id, this.parentFolder.id, this.nameForm.value.trim());
    } else {
      createdFolder = await this.folderService.create({
        name: this.nameForm.value.trim(),
        description: '',
        parentFolder: this.parentFolder.id,
      });
    }

    const groupPromises: Promise<any>[] = [];
    const organizationPromises: Promise<any>[] = [];
    const userPromises: Promise<any>[] = [];

    Object.keys(this.userForms).map((key) =>
      [this.formToPermissionString(this.userForms[key]),  +this.userForms[key].get('userId')!.value,])
      .forEach(([p, userId]: any) => userPromises.push(this.folderService.addUserPermission(createdFolder.id, userId, p))
    );

    this.groupForms
      .flat()
      .map((form: UntypedFormGroup) => [this.formToPermissionString(form), +form.get('groupId')!.value,])
      .forEach(([p, groupId]: any) => groupPromises.push(this.folderService.addGroupPermission(createdFolder.id, groupId, p)));

    this.organizations.forEach(o => {
        organizationPromises.push(this.organizationService.addFolder(o.organization.id, createdFolder.id));
    });

    await Promise.all(userPromises);
    await Promise.all(groupPromises);
    await Promise.all(organizationPromises);

    this.dialogRef.close(true);
    this.snackBar.open(
      this.translate.instant('exchange.folder_created'),
      this.translate.instant('close'),
      { duration: 5000 },
    );
  }

  async onSelect(event: any, search: SearchCompaniesCustomersEmployeesGroupsComponent) {
    if (!event){
      return;
    }
    if (this.isUser(event)) {
      this.addUser(event);
    }
    if (this.isOrganization(event)) {
      await this.addOrganization(event);
    }
    if (this.isGroup(event)) {
      this.addGroup(event);
    }
    search.reset();
  }

  addUser(user: RedactedUser): void {
    if (this.users.some(usr => usr.id === user.id)){
      return;
    }

    this.users.push(user);
    if (!this.userForms[user.id]) {
      this.userForms[user.id] = this.formBuilder.group({
        read: [true, Validators.required],
        write: [false, Validators.required],
        create: [false, Validators.required],
        delete: [false, Validators.required],
        modify: [false, Validators.required],
        userId: user.id,
      });
    }
  }

  async addOrganization(organization: Organization): Promise<void> {
    if (this.organizations.some(o => o.organization.id === organization.id)) {
      this.snackBar.open(
        this.translate.instant('exchange.organization_already_selected'),
        this.translate.instant('close'),
        { duration: 5000 },
      );
      return;
    }

    const users = await this.organizationService.getUsers(organization.id);
    const employees = users.filter(user => user.role === Role.employee || user.role === Role.admin);
    const customers = users.filter(user => user.role === Role.customer);

    this.organizations.push({organization, members: {employees, customers}});

    for (const user of users) {
      if (!this.userForms[user.id]) {
        this.userForms[user.id] = this.formBuilder.group({
          read: [false, Validators.required],
          write: [false, Validators.required],
          create: [false, Validators.required],
          delete: [false, Validators.required],
          modify: [false, Validators.required],
          userId: user.id,
        });
      }
    }
  }

  isUser(item: RedactedUser | Company | Group) {
    return item.hasOwnProperty('firstName');
  }

  isOrganization(item: RedactedUser | Company | Group) {
    return item.hasOwnProperty('kvk');
  }

  deleteUser(user: RedactedUser) {
    for (const org of this.organizations) {
      org.members.customers = org.members.customers.filter(usr => usr.id !== user.id);
      org.members.employees = org.members.employees.filter(usr => usr.id !== user.id);
    }

    this.organizations = this.organizations.filter(org => org.members.employees.length !== 0 || org.members.customers.length !== 0);

    this.users = this.users.filter(usr => usr.id !== user.id);
    delete this.userForms[user.id];
  }

  deleteGroup(group: Group) {
    const pos = this.groups.findIndex(u => u.id !== group.id);
    if (pos > -1) {
      this.groups.splice(pos, 1);
      this.groupForms.splice(pos, 1);
    }
  }

  deleteOrganization(organization: {
    organization: Organization;
    members: {
      employees: RedactedUser[];
      customers: RedactedUser[];
    };
  }) {
    const users = [...organization.members.employees, ...organization.members.customers];
    for (const user of users){
      if (!this.users.some(usr => user.id === usr.id) &&
        !this.organizations.some(org =>
          org.organization.id !== organization.organization.id &&
          (
            org.members.customers.some(usr => usr.id === user.id) ||
            org.members.employees.some(usr => usr.id === user.id)
          )
        )
      ) {
        delete this.userForms[user.id];
      }
    }

    const pos = this.organizations.findIndex(u => u.organization.id === organization.organization.id);
    if (pos > -1) {
      this.organizations.splice(pos, 1);
    }
  }

  setOther(formGroup: UntypedFormGroup, controlName: 'write' | 'delete' | 'modify' | 'create') {
    const val = !formGroup.get(controlName)!.value;
    if (val) {
      formGroup.controls.read.patchValue(true);
    }
  }

  setRead(formGroup: any) {
    const val = formGroup.get('read')!.value;
    if (val) {
      formGroup.controls.write.patchValue(false);
      formGroup.controls.create.patchValue(false);
      formGroup.controls.delete.patchValue(false);
      formGroup.controls.modify.patchValue(false);
    }
  }

  async setParentFolder(folder: Folder) {
    if (!this.isFromDuplicate) {
      this.currentFolders = await this.folderService.childFolders(folder.id);
      this.parentFolderPermissions = folder.permission || '';
      this.parentFolder = folder;
    }

    this.matcher = new NameErrorStateMatcher(this.currentFolders);

    if (this.isFromDuplicate) {
      this.templateFolder = folder;
      this.setFolderName();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  selectFolder(event: DynamicFlatNode[]) {
    this.pickedFolder = event[0]?.folder;
  }

  private addGroup(group: Group) {
    this.groups.push(group);
    this.groupForms.push(this.formBuilder.group({
      read: [false, Validators.required],
      write: [false, Validators.required],
      create: [false, Validators.required],
      delete: [false, Validators.required],
      modify: [false, Validators.required],
      groupId: group.id,
    }));
  }

  private formToPermissionString(form: UntypedFormGroup): string {
    let permission = '';
    if(form.get('read')?.value){
      permission = permission + 'r';
    }
    if(form.get('write')?.value){
      permission = permission + 'w';
    }
    if(form.get('create')?.value){
      permission = permission + 'c';
    }
    if(form.get('delete')?.value){
      permission = permission + 'd';
    }
    if(form.get('modify')?.value){
      permission = permission + 'm';
    }
    return permission;
  }

  private isGroup(item: any) {
    return !item.hasOwnProperty('firstName') && !item.hasOwnProperty('kvk');
  }
}
