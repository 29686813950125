import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CreateConnectionModel} from 'app/functional/models/addons.model';
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SimplicateService {
  params = new HttpParams();

  constructor(public http: HttpClient) {
    this.params = this.params.set('kind', 'simplicate');
  }

  createConnection(model: CreateConnectionModel) {
    return this.http.post(`${environment.rootUrl}interface`, model, { params: this.params }).toPromise();
  }

  getConnection(companyId: number) {
    const params = this.params.set('company_id', companyId.toString());
    return this.http.get(`${environment.rootUrl}interface`, { params }).toPromise();
  }

  synchronise(companyId: number, labels: string[]) {
    const params = this.params.set('company_id', companyId.toString());
    return this.http.put(`${environment.rootUrl}interface/sync`, { labels }, { params }).toPromise();
  }

  deleteConnection(companyId: number) {
    const params = this.params.set('company_id', companyId.toString());
    return this.http.delete(`${environment.rootUrl}interface`, { params }).toPromise();
  }

  meta(companyId: number): Promise<{ labels: string[] }> {
    const params = this.params.set('company_id', companyId.toString());
    return this.http.get<{ labels: string[] }>(`${environment.rootUrl}interface/meta`, { params }).toPromise();
  }
}
