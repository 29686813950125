import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CssInputComponent } from './css-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import {EmmaInputModule} from '../base-components/emma-input/emma-input.module';



@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatButtonModule,
    EmmaInputModule,
  ],
  exports: [CssInputComponent],
  declarations: [CssInputComponent]
})
export class CssInputComponentModule { }
