import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'environments/environment';
import {CreateMailTemplate, MailTemplate, MailTemplateKind, UpdateMailTemplate} from 'app/functional/models/mail-template.model';
import {User} from 'app/functional/models/user';
import {Signing} from 'app/functional/models/signing';
import {Company} from 'app/functional/models/company';
import {Document} from 'app/functional/models/document';
import {Role} from 'app/functional/models/role.model';
import {Dossier} from 'app/functional/models/dossier.model';
import {FileRequest} from '../../functional/models/file-request.model';

export class MailElements {
  subject?: string;
  text?: string;
  button?: string;
  header?: string;
  subtext?: string;
  footer?: string;
  list?: boolean;
  roles!: Role[];
  editable!: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MailTemplateService {
  private url = `${environment.rootUrl}mail_template`;

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Create a new mail template. Should only be called as a superuser.
   *
   * @param createMailTemplate The values of the newly created template.
   */

  create(createMailTemplate: CreateMailTemplate): Promise<MailTemplate> {
    return this.http.post<MailTemplate>(this.url, createMailTemplate).toPromise();
  }

  /**
   * Filter mail templates. Should only be called as a superuser.
   *
   * @param filter A set of optional parameters that are used for filtering.
   */

  filter(filter: { version?: number }): Promise<MailTemplate[]> {
    let params = new HttpParams();
    if (filter.version) {
      params = params.set('version', filter.version.toString());
    }
    return this.http.get<MailTemplate[]>(this.url, { params }).toPromise();
  }

  /**
   * Return for each email kind the variant with the greatest `version`.
   */
  recent(): Promise<MailTemplate[]> {
    return this.http.get<MailTemplate[]>(this.url + '/recent').toPromise();
  }

  /**
   * Modify a mail template. Should only be called as a superuser. Note that it is not allowed to
   * modify the `version` of an existing mail template.
   *
   * @param updateMailTemplate The values of the updated template.
   */

  update(id: number, updateMailTemplate: UpdateMailTemplate): Promise<MailTemplate> {
    return this.http.put<MailTemplate>(`${this.url}/${id}`, updateMailTemplate).toPromise();
  }

  kindToName(kind: MailTemplateKind): string {
    switch (kind) {
      case MailTemplateKind.activityReport: return 'Activeitsrapport';
      case MailTemplateKind.forgotPassword: return 'Wachtwoord vergeten';
      case MailTemplateKind.notificationMessage: return 'Bericht Ontvangen';
      case MailTemplateKind.notificationSignature: return 'Signering Nodig';
      case MailTemplateKind.registerSuperuser: return 'superuser Geregistreerd';
      case MailTemplateKind.registerAdmin: return 'admin Geregistreerd';
      case MailTemplateKind.registerEmployee: return 'Werknemer Geregistreerd';
      case MailTemplateKind.registerCustomer: return 'Klant Geregistreerd';
      case MailTemplateKind.reminderMessage: return 'Herinnering Bericht';
      case MailTemplateKind.reminderSigning: return 'Herinnering Signering';
      case MailTemplateKind.storageFull: return 'Opslag Vol';
      case MailTemplateKind.storageReport: return 'Dagrapport';
      case MailTemplateKind.signingDone: return 'Signering voldaan';
      case MailTemplateKind.allSigningsDone: return 'Alle signeringen voldaan';
      case MailTemplateKind.fileRequestCompleted: return 'Bestandsverzoek voldaan';
      case MailTemplateKind.allFileRequestsCompleted: return 'Alle bestandsverzoeken voldaan';
      case MailTemplateKind.signingRejected: return 'Sigingering afgewezen';
      case MailTemplateKind.dossierCompleted: return 'Dossier Afgerond';
      case MailTemplateKind.dossierStateUpdated: return 'Dossier is van staat gewisseld';
      case MailTemplateKind.deletedAccount: return 'Account verwijderd';
      case MailTemplateKind.emailUploadError: return 'Email Upload Mislukt';
      case MailTemplateKind.downloadReady: return 'zip bestanden staan klaar';
      case MailTemplateKind.dossierNearDeadline: return 'Dossier nadert deadline';
      case MailTemplateKind.fileRequestCreated: return 'Bestandsverzoek aangemaakt';
      case MailTemplateKind.fileRequestReminder: return 'Bestandsverzoek herinnering';
      case MailTemplateKind.unreadChatMessage: return 'Ongelezen chat bericht';
    }
    throw new Error(`Bogus kind: ${kind}`); // unreachable
  }

  context(kind: MailTemplateKind, company: Company) {
    const url: string = document.location.hostname;
    switch (kind) {
      case MailTemplateKind.activityReport: return {
        user: User.default(),
        company,
        url,
      };
      case MailTemplateKind.forgotPassword: return {
        user: User.default(),
        company,
        url,
      };
      case MailTemplateKind.notificationMessage: return {
        recipient: User.default(),
        sender: User.default2(),
        company,
        url,
      };
      case MailTemplateKind.notificationSignature: return {
        user: User.default(),
        file: Document.default(),
        company,
        url,
      };
      case MailTemplateKind.registerSuperuser: {
        const user = User.default();
        user.role = Role.superuser;
        return {
          user: User.default(),
          company,
          url,
        };
      }
      case MailTemplateKind.registerAdmin: {
        const user = User.default();
        user.role = Role.admin;
        return {
          user: User.default(),
          company,
          url,
        };
      }
      case MailTemplateKind.registerEmployee: {
        const user = User.default();
        user.role = Role.employee;
        return {
          user: User.default(),
          company,
          url,
        };
      }
      case MailTemplateKind.registerCustomer: {
        const user = User.default();
        user.role = Role.customer;
        return {
          user,
          company,
          url,
        };
      }
      case MailTemplateKind.reminderMessage: return {
        user: User.default(),
        company,
        url,
      };
      case MailTemplateKind.reminderSigning: return {
        user: User.default(),
        company,
        url,
      };
      case MailTemplateKind.storageFull: return {
        usedStorage: 9.5,
        company,
        url,
      };
      case MailTemplateKind.storageReport: return {
        company,
        url,
      };
      case MailTemplateKind.signingDone: return {
        dossier: Dossier.default(),
        employee: User.default(),
        signing: Signing.default(),
        company,
        url,
      };
      case MailTemplateKind.allSigningsDone: return {
        dossier: Dossier.default(),
        user: User.default(),
        // signings: [],
        company,
        url,
      };
      case MailTemplateKind.fileRequestCompleted: return {
        dossier: Dossier.default(),
        user: User.default(),
        company,
        url,
      };
      case MailTemplateKind.allFileRequestsCompleted: return {
        dossier: Dossier.default(),
        employee: User.default(),
        company,
        url,
      };
      case MailTemplateKind.signingRejected: return {
        dossier: Dossier.default(),
        employee: User.default(),
        signing: Signing.default(),
        company,
        url,
      };
      case MailTemplateKind.dossierStateUpdated: return {
        dossier: Dossier.default(),
        company,
        url,
      };
      case MailTemplateKind.deletedAccount: return {
        user: User.default(),
        company,
        url,
      };
      case MailTemplateKind.dossierCompleted: return {
        user: User.default(),
        msg: 'do not implement me this is not used',
        company,
        url,
      };
      case MailTemplateKind.emailUploadError: return {
        user: User.default(),
        msg: 'Dat waren iets te veel bestanden!',
        company,
        url,
      };
      case MailTemplateKind.downloadReady: return {
        company,
        url,
      };
      case MailTemplateKind.dossierNearDeadline: return {
        dossier: Dossier.default(),
        company,
        url,
      };
      case MailTemplateKind.fileRequestCreated: return {
        fileRequest: FileRequest.default(),
        company,
        url,
      };
      case MailTemplateKind.fileRequestReminder: return {
        fileRequest: FileRequest.default(),
        company,
        url,
      };
      case MailTemplateKind.unreadChatMessage: return {
        company,
        url,
      };
    }
    throw new Error(`Bogus kind: ${kind}`); // unreachable
  }

  meta(kind: MailTemplateKind): MailElements {
    switch (kind) {
      case MailTemplateKind.activityReport: return {
        subject: 'activityReportSubject',
        header: 'activityReportHeader',
        text: 'activityReportText',
        footer: 'footer',
        roles: [Role.employee, Role.admin, Role.superuser],
        list: true,
        editable: true,
      };
      case MailTemplateKind.forgotPassword: return {
        subject: 'forgotPasswordSubject',
        header: 'forgotPasswordHeader',
        text: 'forgotPasswordText',
        button: 'forgotPasswordButton',
        footer: 'footer',
        roles: [],
        editable: true,
      };
      case MailTemplateKind.notificationMessage: return {
        subject: 'notificationMessageSubject',
        header: 'notificationMessageHeader',
        text: 'notificationMessageText',
        button: 'notificationMessageButton',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.notificationSignature: return {
        subject: 'notificationSignatureSubject',
        header: 'notificationSignatureHeader',
        text: 'notificationSignatureText',
        button: 'notificationSignatureButton',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.registerSuperuser: return {
        subject: 'registerSuperuserSubject',
        header: 'registerSuperuserHeader',
        text: 'registerSuperuserText',
        button: 'registerSuperuserButton',
        footer: 'footer',
        roles: [],
        editable: false,
      };
      case MailTemplateKind.registerAdmin: return {
        subject: 'registerAdminSubject',
        header: 'registerAdminHeader',
        text: 'registerAdminText',
        button: 'registerAdminButton',
        footer: 'footer',
        roles: [],
        editable: false,
      };
      case MailTemplateKind.registerEmployee: return {
        subject: 'registerEmployeeSubject',
        header: 'registerEmployeeHeader',
        text: 'registerEmployeeText',
        button: 'registerEmployeeButton',
        footer: 'footer',
        roles: [],
        editable: true,
      };
      case MailTemplateKind.registerCustomer: return {
        subject: 'registerCustomerSubject',
        header: 'registerCustomerHeader',
        text: 'registerCustomerText',
        button: 'registerCustomerButton',
        footer: 'footer',
        roles: [],
        editable: true,
      };
      case MailTemplateKind.reminderMessage: return {
        subject: 'reminderMessageSubject',
        header: 'reminderMessageHeader',
        text: 'reminderMessageText',
        button: 'reminderMessageButton',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.reminderSigning: return {
        subject: 'reminderSigningSubject',
        header: 'reminderSigningHeader',
        text: 'reminderSigningText',
        button: 'reminderSigningButton',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.storageFull: return {
        subject: 'storageFullSubject',
        header: 'storageFullHeader',
        footer: 'footer',
        roles: [],
        editable: false,
      };
      case MailTemplateKind.storageReport: return {
        subject: 'storageReportSubject',
        text: 'storageReportText',
        header: 'storageReportHeader',
        footer: 'footer',
        roles: [Role.admin],
        list: true,
        editable: true,
      };
      case MailTemplateKind.signingDone: return {
        subject: 'signingDoneSubject',
        header: 'signingDoneHeader',
        text: 'signingDoneText',
        button: 'signingDoneButton',
        footer: 'footer',
        roles: [Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.allSigningsDone: return {
        subject: 'allSigningsDoneSubject',
        header: 'allSigningsDoneHeader',
        text: 'allSigningsDoneText',
        button: 'allSigningsDoneButton',
        footer: 'footer',
        roles: [Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.fileRequestCompleted: return {
        subject: 'fileRequestCompletedSubject',
        header: 'fileRequestCompletedHeader',
        text: 'fileRequestCompletedText',
        button: 'fileRequestCompletedButton',
        footer: 'footer',
        roles: [Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.allFileRequestsCompleted: return {
        subject: 'allFileRequestsCompletedSubject',
        header: 'allFileRequestsCompletedHeader',
        text: 'allFileRequestsCompletedText',
        button: 'allFileRequestsCompletedButton',
        footer: 'footer',
        roles: [Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.signingRejected: return {
        subject: 'signingRejectedSubject',
        header: 'signingRejectedHeader',
        text: 'signingRejectedText',
        button: 'signingRejectedButton',
        footer: 'footer',
        roles: [Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.dossierStateUpdated: return {
        subject: 'dossierStateUpdatedSubject',
        header: 'dossierStateUpdatedHeader',
        text: 'dossierStateUpdatedText',
        button: 'dossierStateUpdatedButton',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.deletedAccount: return {
        subject: 'deletedAccountSubject',
        header: 'deletedAccountHeader',
        text: 'deletedAccountText',
        footer: 'footer',
        roles: [],
        editable: true,
      };
      case MailTemplateKind.dossierCompleted: return {
        subject: 'dossierCompletedSubject',
        header: 'dossierCompletedHeader',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.emailUploadError: return {
        subject: 'emailUploadErrorSubject',
        header: 'emailUploadErrorHeader',
        footer: 'footer',
        roles: [],
        editable: true,
      };
      case MailTemplateKind.downloadReady: return {
        subject: 'downloadReadySubject',
        header: 'downloadReadyHeader',
        text: 'downloadReadyText',
        button: 'downloadReadyButton',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.dossierNearDeadline: return {
        subject: 'dossierNearDeadlineSubject',
        header: 'dossierNearDeadlineHeader',
        text: 'dossierNearDeadlineText',
        button: 'dossierNearDeadlineButton',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: false,
      };
      case MailTemplateKind.fileRequestCreated: return {
        subject: 'fileRequestCreatedSubject',
        header: 'fileRequestCreatedHeader',
        text: 'fileRequestCreatedText',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
      case MailTemplateKind.fileRequestReminder: return {
        subject: 'fileRequestReminderSubject',
        header: 'fileRequestReminderHeader',
        text: 'fileRequestReminderText',
        footer: 'footer',
        roles: [],
        editable: true,
      };
      case MailTemplateKind.unreadChatMessage: return {
        subject: 'unreadChatSubject',
        header: 'unreadChatHeader',
        text: 'unreadChatText',
        button: 'unreadChatButton',
        footer: 'footer',
        roles: [Role.customer, Role.employee, Role.admin, Role.superuser],
        editable: true,
      };
    }
    throw new Error(`Bogus kind: ${kind}`); // unreachable
  }

  toList(kind: MailTemplateKind): string[] {
    const res = [];
    const elements = this.meta(kind);
    for (const [k, v] of Object.entries(elements)) {
      if (k ===  'list') {
        continue;
      }
      if ((typeof v) ===  'string') {
        res.push(v);
      }
    }
    return res;
  }
}
