import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyService } from 'app/services/company/company.service';
import { Company } from 'app/functional/models/company';
import { Subscription } from 'rxjs';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from 'app/services/user/user.service';
import {Role} from 'app/functional/models/role.model';

@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss']
})
export class LogoUploadComponent implements OnInit, OnDestroy {
  companySubscription!: Subscription;
  company?: Company;
  logo?: string;
  userRole: Role;
  roles = Role;

  constructor(
      private companyService: CompanyService,
      private userService: UserService,
      private snackbar: MatSnackBar,
      private translate: TranslateService
  ) {
    this.userRole = this.userService.tryClaims().role;
  }

  ngOnDestroy(): void {
    if (!!this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }
      await this.fetchCompanyDetails();
    });
  }

  async fetchCompanyDetails() {
    if (this.company) {
      this.logo = this.companyService.imageUrl(this.company);
    }
  }

  uploadFile(e: any) {
    if (!e.target || !e.target || !e.target.files || !this.company) {
      return;
    }
    for (const file of e.target.files) {
      this.companyService.uploadLogo(this.company.id, file).then(() => {
        this.fetchCompanyDetails();
        window.dispatchEvent(new Event('logo-updated'));
        this.snackbar.open(this.translate.instant('settings.logo_uploaded'), '', { duration: 3000 });
      });
    }
  }
}
