import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DossierTemplateService} from '../../../services/dossier-template/dossier-template.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-dossier-template-request-dialog',
  templateUrl: './add-dossier-template-request-dialog.component.html',
  styleUrls: ['./add-dossier-template-request-dialog.component.scss']
})
export class AddDossierTemplateRequestDialogComponent implements OnInit {
  addRequestForm!: UntypedFormGroup;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public templateService: DossierTemplateService,
    public dialogRef: MatDialogRef<AddDossierTemplateRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.addRequestForm = this.formBuilder.group({
      fileRequests: new UntypedFormArray([]),
    });
    this.newFilerequest();
  }

  get f() { return this.addRequestForm.controls; }
  get t() { return this.f.fileRequests as UntypedFormArray; }

  newFilerequest() {
    this.t.push(this.formBuilder.group({
      filename: [null, [Validators.required]],
      description: [null, []],
    }));
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  deleteRequest(i: number) {
    this.t.removeAt(i);
  }

  async addRequests() {
    const createdFileRequests = [];
    for (const request of this.addRequestForm.value.fileRequests) {
      if (request.filename) {
        createdFileRequests.push(await this.templateService.createRequest(this.data.template.id, {
          filename: request.filename,
          folderTemplateId: this.data.currentFolder.id,
          message: request.description || ''
        }));
      }
    }
    this.dialogRef.close(createdFileRequests);
  }
}
