import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { EMPTY, from } from 'rxjs';
import { UserService } from './services/user/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LogoutReasonDialogComponent } from './dialogs/logout-reason-dialog/logout-reason-dialog.component';
import { WebsocketService } from './services/websocket/websocket.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshInProgress = false;
  private isOpen = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private websocketService: WebsocketService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleRequest(request, next));
  }

  async handleRequest(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const refreshToken = localStorage.getItem('Refresh');
    const token = localStorage.getItem('Token');
    const mfaToken = localStorage.getItem('MFAToken');
    const helper = new JwtHelperService();

    if (request.url.includes('/assets/') || request.url.includes('https://storage.googleapis.com/')) {
      return next.handle(request).toPromise();
    }

    // Do not interfere with refresh tokens being sent.
    if (request.url.includes('/v1/user/refresh') && refreshToken) {
      return next.handle(this.setHeaders(request, refreshToken!)).toPromise();
    }

    // Do not interfere with the mfa endpoints.
    if (request.url.includes('mfa') && mfaToken && !helper.isTokenExpired(mfaToken)) {
      return next.handle(this.setHeaders(request, mfaToken)).toPromise();
    }

    // If there is no token in local storage, only configure the content-type.
    if (!token) {
      return next.handle(this.setDefaultRequest(request)).toPromise();
    }

    // If there is a token which is not expired, add it as a header.
    if (token && !helper.isTokenExpired(token)) {
      return next.handle(this.setHeaders(request, token)).toPromise();
    }

    if (token && helper.isTokenExpired(token) && (!refreshToken || helper.isTokenExpired(refreshToken))) {
      return this.setLogout(2);
    }

    if (this.refreshInProgress) {
      while (this.refreshInProgress) {
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      const newToken = localStorage.getItem('Token');
      if (!helper.isTokenExpired(newToken!)) {
        return next.handle(this.setHeaders(request, newToken!)).toPromise();
      } else {
        return this.setLogout(1);
      }
    }

    if (helper.isTokenExpired(token) && !helper.isTokenExpired(refreshToken!) && !this.refreshInProgress) {
      this.refreshInProgress = true;
      try {
        const res: any = await this.userService.refresh();
        this.refreshInProgress = false;
        return next.handle(this.setHeaders(request, res.token)).toPromise();
      } catch (e: any) {
        this.refreshInProgress = false;
        return this.setLogout(e.error.code);
      } finally {
        this.refreshInProgress = false;
      }
    }

    return next.handle(this.setDefaultRequest(request)).toPromise();
  }

  async setLogout(reason: number): Promise<any> {
    this.localStorageService.resetLocalStorage();

    this.websocketService.close();
    await this.router.navigate(['auth', 'login']);

    if (!this.isOpen) {
      const dialogRef = this.dialog.open(LogoutReasonDialogComponent, {
        data: {
          reason,
        },
        autoFocus: false
      });
      this.isOpen = true;

      dialogRef.afterClosed().subscribe(() => {
        this.isOpen = false;
      });
    }
    return EMPTY;
  }

  setHeaders(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: 'Bearer ' + token,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
    });
  }

  setDefaultRequest(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
    });
  }

  isExpired(token: string): boolean {
    const helper = new JwtHelperService();
    const decoded = helper.decodeToken(token);
    if (!decoded) {
      throw new Error('Invalid token in `TokenInterceptor`!');
    }
    const offset = +(localStorage.getItem('Offset') || 0);
    const now = Math.round((new Date()).getTime() / 1000);
    return now - offset > decoded.exp;
  }
}
