import { Component, OnInit } from '@angular/core';
import { DossierTemplateService } from '../../../services/dossier-template/dossier-template.service';
import { DossierTemplateOverview } from '../../../functional/models/dossier-template.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CreateDossierTemplateDialogComponent } from
    '../../../dialogs/dossier-template/create-dossier-template-dialog/create-dossier-template-dialog.component';

@Component({
  selector: 'app-workflow-overview',
  templateUrl: './workflow-overview.component.html',
  styleUrls: ['./workflow-overview.component.scss']
})
export class WorkflowOverviewComponent implements OnInit {
  filteredTemplates: DossierTemplateOverview[] = [];
  private templates: DossierTemplateOverview[] = [];

  constructor(
    private templateService: DossierTemplateService,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.templates = await this.templateService.readAll();
    this.filteredTemplates = this.templates;
  }

  applyFilter(filterValue: string) {
    if (!filterValue || !filterValue.trim()){
      this.filteredTemplates = this.templates;
    }
    this.filteredTemplates = this.templates.filter(template => template.name.toLowerCase().includes(filterValue.trim().toLowerCase()));
  }

  openCreateDossierTemplate() {
    const dialogRef = this.dialog.open(CreateDossierTemplateDialogComponent, {autoFocus: false});

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.templates = await this.templateService.readAll();
        this.filteredTemplates = this.templates;
      }
    });
  }
}
