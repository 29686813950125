<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    (navigationChange)="changeTab($event)"
    [buttons]="[
      {name: 'Opties', event: 'menu', menu: true, hidden: (activeTab !== 'overview' && activeTab !== 'details' && activeTab !== 'files')},
      {name: 'new' | translate, event: 'new', icon: 'add', hidden: (activeTab === 'overview' || activeTab === 'details' || activeTab === 'files')},
    ]"
    (buttonClicked)="handleClick($event)"
    [navigation]="[
    {name: 'users.overview' | translate, event:'overview'},
    {name: 'users.personals' | translate, event: 'details'},
    {name: 'users.organizations' | translate, event: 'organizations'},
    {name: 'users.customers' | translate, event: 'customers', hidden: user && user.role!== 2},
    {name: 'users.groups' | translate, event: 'groups'},
    {name: 'users.dossiers' | translate, event: 'dossiers'},
    {name: 'users.files' | translate, event: 'files'}]">

    <ng-container select>
      <ng-container *ngIf="activeTab === 'details' || activeTab === 'overview'">
      <span class="no-select" (click)="resendWelcome();">{{'users.details.resend_welcome' | translate}}</span>
      <span class="no-select" (click)="resetPassword();">{{'users.details.profile_resetpass' | translate}}</span>
      <span class="no-select"
            *ngIf="user && (claims.role <= localRole.admin)"
            (click)="deleteAccount();">{{'users.details.delete_account' | translate}}</span>
      <span class="no-select"
            *ngIf="company && company.mfaRequired"
            (click)="resetMfa();">{{'users.details.mfa_reset' | translate}}</span>
      <span class="no-select"
            *ngIf="user && claims.role === 0 && user.role === 3"
            (click)="openLogging();">{{'users.details.profile_logging' | translate}}</span>
      </ng-container>
      <ng-container *ngIf="activeTab === 'files'">
        <span class="no-select" *ngIf="claims.role === 0 || claims.role === 1" (click)="handleClick('new');">
          Nieuwe folder
        </span>
        <span class="no-select" *ngIf="(claims.role === 0 || claims.role === 1)" (click)="handleClick('newFromTemplate')">
          Nieuwe folder van sjabloon
        </span>
      </ng-container>
    </ng-container>
  </app-sub-nav>

  <ng-container *ngIf="!activeTab || activeTab === 'overview'">
    <ng-container *ngIf="user">
      <app-customer-details [user]="user"></app-customer-details>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="editForm && activeTab === 'details'">
    <div class="user-form">
      <form [formGroup]="editForm">
      <div class="row">
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="email" class="emma-input-label">{{ 'users.create.email'  | translate }}</label>
            <input appEmmaInput id="email" formControlName="email">
          </div>
        </div>
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="phone" class="emma-input-label">{{ 'users.create.phone'  | translate }}</label>
            <input appEmmaInput id="phone" formControlName="phone">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="firstName" class="emma-input-label">{{ 'users.create.firstname'  | translate }}</label>
            <input appEmmaInput id="firstName" formControlName="firstName">
          </div>
        </div>
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="lastName" class="emma-input-label">{{ 'users.create.lastname'  | translate }}</label>
            <input appEmmaInput id="lastName" formControlName="lastName">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="emma-input-container">
            <label label for="role" class="emma-input-label">{{'users.create.role'  | translate }}</label>
            <app-emma-select
              id="role"
              layout="input"
              [fullWidth]="true"
              [renderButton]="true"
              [options]="[
                {displayName: 'users.create.superuser' | translate, value: 0, hidden: claims.role !== 0},
                {displayName: 'users.create.admin' | translate, value: 1, hidden: claims.role !== 0},
                {displayName: 'users.create.employee' | translate, value: 2, hidden: claims.role > 1},
                {displayName: 'users.create.customer' | translate, value: 3, hidden: claims.role > 1},
              ]"
              formControlName="role">
            </app-emma-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="gender" class="emma-input-label">{{'users.create.gender'  | translate }}</label>
            <app-emma-select
              id="gender"
              layout="input"
              [fullWidth]="true"
              [renderButton]="true"
              [options]="[
                {displayName: 'users.create.undefined' | translate, value: 0},
                {displayName: 'users.create.male' | translate, value: 1},
                {displayName: 'users.create.female' | translate, value: 2},
                {displayName: 'users.create.notapplicable' | translate, value: 9},
              ]"
              formControlName="gender">
            </app-emma-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="birth" class="emma-input-label">{{'users.create.birthdate'  | translate }}</label>
            <div class="emma-input">
              <input id="birth" appEmmaInput formControlName="birth" [matDatepicker]="picker">
              <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
            </div>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="street" class="emma-input-label">{{ 'users.create.address' | translate }}</label>
            <input appEmmaInput id="street" formControlName="street">
          </div>
        </div>
        <div class="col-md-4">
          <div class="emma-input-container">
            <label label for="houseNumber" class="emma-input-label">{{'users.create.housenr' | translate }}</label>
            <input appEmmaInput id="houseNumber" formControlName="houseNumber">
          </div>
        </div>
        <div class="col-md-2">
          <div class="emma-input-container">
            <label label for="premise" class="emma-input-label">{{ 'users.create.addition' | translate }}</label>
            <input appEmmaInput id="premise" formControlName="premise">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="postalCode" class="emma-input-label">{{'users.create.postal' | translate }}</label>
            <input appEmmaInput id="postalCode" formControlName="postalCode">
          </div>
        </div>
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="city" class="emma-input-label">{{ 'users.create.place' | translate }}</label>
            <input appEmmaInput id="city" formControlName="city">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="emma-input-container">
            <label label for="relationId" class="emma-input-label">{{ 'users.create.relationId' | translate }}</label>
            <input appEmmaInput id="relationId" formControlName="street">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">

          <div class="emma-input-container">
            <label label for="description" class="emma-input-label">{{ 'users.create.description'  | translate }}</label>
            <textarea appEmmaInput id="description" formControlName="description" style="height: 200px"></textarea>
          </div>
        </div>
      </div>
      <button  type="submit" class="btn btn-blue btn-custom pull-right" (click)="updateProfile()">{{'users.details.profile_submit' | translate}}</button>
      <div class="clearfix"></div>
    </form>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab === 'organizations'">
    <app-organization-overview [userId]="userId"></app-organization-overview>
  </ng-container>

  <ng-container *ngIf="activeTab === 'groups'">
    <app-connected-groups [user]="user" [userId]="userId"></app-connected-groups>
  </ng-container>

  <ng-container *ngIf="activeTab === 'dossiers'">
    <app-dossier-overview [userFilter]="userId" [view]="'all'"></app-dossier-overview>
  </ng-container>

  <ng-container *ngIf="activeTab === 'customers'">
    <app-connected-users
      [company]="company"
      [users]="userCustomers"
      [isCustomer]="true"
      [isRedacted]="false"
      [selectable]="false"
      [deleteable]="true"
      (deletedUsers)="deleteCustomer($event)"
    ></app-connected-users>
  </ng-container>

  <ng-container *ngIf="activeTab === 'files'">
    <div class="user-explorer-wrapper">
      <app-sub-nav
        [search]="true"
        [exchangeSubject]="true"
        (searchChange)="exchangeOverview.onSearchChange($event)">
        <div class="exchange-button-holder">
          <app-emma-select
            style="margin-left: 20px"
            name="search-select"
            [options]="[
          {displayName: 'explorer.search.current_folder' | translate, value: 1},
          {displayName: 'explorer.search.sub_folders' | translate, value: 2},
          {displayName: 'explorer.search.all_folders' | translate, value: 3}
        ]"
            [(ngModel)]="searchType"
            [prefix]="('explorer.search.placeholder' | translate) + ':'">
          </app-emma-select>

          <app-emma-select
            style="margin-left: 20px"
            name="sort-select"
            *ngIf="exchangeOverview.view === 'small'"
            [options]="[
          {displayName: 'explorer.sort.name_asc' | translate, value: 1},
          {displayName: 'explorer.sort.name_dsc' | translate, value: 2},
          {displayName: 'explorer.sort.date_asc' | translate, value: 3},
          {displayName: 'explorer.sort.date_dsc' | translate, value: 4},
        ]"
            [(ngModel)]="exchangeOverview.lSortType"
            (change)="exchangeOverview.setSort(exchangeOverview.lSortType)"
            [prefix]="('explorer.sort.placeholder' | translate) + ':'">
          </app-emma-select>

          <app-emma-select
            style="margin-left: 20px"
            name="view-select"
            [title]="'explorer.view.title' | translate"
            [renderIconOnly]="true"
            [options]="[
          {displayName: 'explorer.view.grid' | translate, value: 'small', postfixIcon: 'apps'},
          {displayName: 'explorer.view.list' | translate, value: 'list', postfixIcon: 'list'}
        ]"
            [(ngModel)]="exchangeOverview.view"
            (change)="exchangeOverview.setView(exchangeOverview.view)"
          >
          </app-emma-select>
        </div>
      </app-sub-nav>
      <app-exchange-overview
        [userId]="userId"
        [isUserOverview]="true"
        (breadCrumbChange)="setCrumbs($event)"
        [searchType]="searchType"
        #exchangeOverview></app-exchange-overview>
    </div>
  </ng-container>
</div>
