import {Routes} from '@angular/router';
import {GroupListComponent} from './group-list/group-list.component';
import {GroupDetailsComponent} from './group-details/group-details.component';

export const groupRoutes: Routes = [
  { path: '', component: GroupListComponent},
  {
    path: 'details/:id',
    component: GroupDetailsComponent,
    data: {
      isParam: true,
      subMenu: [],
      breadcrumb: 'group.name'
    },
  },
  { path: 'edit/:id', component: GroupDetailsComponent},
];
