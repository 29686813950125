import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ReplyDenyDocumentDialogComponent} from './reply-deny-document-dialog.component';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import { NgIconsModule } from '@ng-icons/core';
import {EmmaInputModule} from '../../../components/base-components/emma-input/emma-input.module';

@NgModule({
  declarations: [ReplyDenyDocumentDialogComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatRadioModule,
    MatIconModule,
    TranslateModule.forRoot(),
    NgIconsModule,
    EmmaInputModule,
  ]
})
export class ReplyDenyDocumentDialogModule { }
