<button
  mat-button class="document-item"
  [appEmmaTooltip]="upload.name">
  <mat-icon class="exe">
    {{ getIcon() }}
    <span class="type" *ngIf="getIcon() == 'insert_drive_file'">
      <strong class="extension">{{ '.' + getExtension() }}</strong>
    </span>
  </mat-icon>
  <span class="title">{{ upload.name }}</span>
</button>
