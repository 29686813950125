<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="display-signature-dialog">
  <div #signatureView>
    <div class="row">
      <div class="col-md-12 centered">
        <p class="emma-title text-align-center">{{ 'exchange.audit_trail' | translate }}</p>
      </div>
    </div>
    <ng-container *ngFor="let signature of signatures; let last = last; let first = first;">
    <div class="row margin-bottom-15" [ngClass]="first ? 'margin-top-30' : 'margin-top-15'">
      <div class="col-md-6">
        <div class="row margin-10">
          <div class="col-md-6">
            <p class="emma-font-light">{{ 'exchange.signed_by' | translate }}</p>
          </div>
          <div class="col-md-6">
            {{signature.user.firstName + ' ' + signature.user.lastName}}
          </div>
        </div>
        <div class="row margin-10">
          <div class="col-md-6">
            <p class="emma-font-light">{{ 'exchange.concerned_document' | translate }}</p>
          </div>
          <div class="col-md-6">
            {{signature.file.name}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p class="emma-font-light">{{ 'exchange.signed_when' | translate }}</p>
          </div>
          <div class="col-md-6">
            {{signature.signDate | date:'dd-MM-yyyy HH:mm'}}
          </div>
        </div>
      </div>
      <div class="col-md-6 centered">
        <img style="max-height: 180px;" src="{{signature.displayUrl}}" *ngIf="signature.displayUrl && signature.signType === 0">
        <p class="emma-font-light-small" *ngIf="!signature.displayUrl || signature.signType === 1">{{ 'exchange.no_signature_available' | translate }}</p>
      </div>
    </div>
    <hr *ngIf="!last">
    </ng-container>
  </div>
  <div class="row padding-bottom-15">
    <div class="col-md-12 centered">
      <button  class="btn btn-blue btn-custom" (click)="download(signatureView)">downloaden</button>
    </div>
  </div>
</div>
