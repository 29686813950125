import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CreateGroup} from '../../functional/models/group';

@Component({
  selector: 'app-create-group-dialog',
  templateUrl: './create-group-dialog.component.html',
  styleUrls: ['./create-group-dialog.component.scss']
})
export class CreateGroupDialogComponent implements OnInit {
  groupForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateGroupDialogComponent>
  ) {
    this.groupForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', []],
    });
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }

  submit() {
    this.groupForm.markAllAsTouched();
    if (this.groupForm.invalid){
      return;
    }

    this.dialogRef.close(this.groupForm.value as CreateGroup);
  }
}
