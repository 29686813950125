import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteSelectComponent } from './autocomplete-select.component';
import {FormsModule} from '@angular/forms';
import {EmmaSelectModule} from '../emma-select/emma-select.module';

@NgModule({
  declarations: [
    AutocompleteSelectComponent,
  ],
  exports: [
    AutocompleteSelectComponent,
  ],
  imports: [
    CommonModule,
    EmmaSelectModule,
    FormsModule
  ]
})
export class AutocompleteSelectModule { }
