import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmmaSelectComponent} from './emma-select.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    EmmaSelectComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    EmmaSelectComponent
  ]
})
export class EmmaSelectModule { }
