import {Component, Input, ViewChild, OnDestroy, OnInit} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Url } from 'app/functional/models/url.model';
import { Subscription } from 'rxjs';
import { UrlService } from 'app/services/url/url.service';
import {Company} from 'app/functional/models/company';
import {CompanyService} from 'app/services/company/company.service';
import {AddDomainDialogComponent} from 'app/dialogs/add-domain-dialog/add-domain-dialog.component';

@Component({
  selector: 'app-url-selector',
  templateUrl: './url-selector.component.html',
  styleUrls: ['./url-selector.component.scss']
})
export class UrlSelectorComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort?: MatSort;
  @Input() companyId!: number;

  urlSource?: MatTableDataSource<Url>;
  urls: Array<Url> = [];
  subscription = new Subscription();
  companySubscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private urlService: UrlService,
    private snackBar: MatSnackBar,
    private companyService: CompanyService,
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.companyId) {
      await this.updateUrls();
    } else {
      this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
        if (company) {
          this.companyId = company.id;
        }
        await this.updateUrls();
      });
    }
  }

  ngOnDestroy() {
    this.companySubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  @ViewChild(MatSort) set content(content: any) {
    if (content && this.urlSource) {
      this.urlSource.sort = content;
    }
  }

  async setPrimary(url: Url) {
    await this.urlService.setPrimary(url.id);
    await this.updateUrls();
  }

  async deleteUrl(url: Url) {
    await this.urlService.delete(url.id);
    await this.updateUrls();
  }

  async addDomain(name: string) {
    if (!name || name === '') {
      this.snackBar.open('Je hebt nog niet alle veldjes goed ingevuld', '', { duration: 5000 });
    }
    await this.urlService.create({
      url: name,
      isPrimary: false,
      companyId: this.companyId
    });
    await this.updateUrls();
  }

  openAddDomain(): void {
    const dialogRef = this.dialog.open(AddDomainDialogComponent, {
      width: '350px',
      autoFocus: false
    });
    this.subscription.unsubscribe();
    this.subscription = dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.addDomain(result.name);
      }
    });
  }

  async updateUrls() {
    this.urls = await this.urlService.readByCompany(this.companyId);
    this.urlSource = new MatTableDataSource(this.urls);
    this.urlSource.sort = this.sort || null;
  }
}
