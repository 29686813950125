import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Company} from '../../../functional/models/company';
import {Organization} from '../../../functional/models/organization.model';
import {Claims, RedactedUser} from '../../../functional/models/user';
import {Dossier} from '../../../functional/models/dossier.model';
import {OrganizationService} from '../../../services/organization/organization.service';
import {Role} from '../../../functional/models/role.model';
import {UserService} from '../../../services/user/user.service';
import {SearchEmployeesComponent} from '../../../components/search/search-employees/search-employees.component';
import {ChatService} from '../../../services/chat/chat.service';
import {CreateChatModel} from '../../../functional/models/chat.model';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SearchCustomersComponent} from '../../../components/search/search-customers/search-customers.component';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-new-chat-dialog',
  templateUrl: './new-chat-dialog.component.html',
  styleUrls: ['./new-chat-dialog.component.scss']
})
export class NewChatDialogComponent implements OnInit {
  @ViewChild('colleagueSearch') colleagueSearch!: SearchEmployeesComponent;
  @ViewChild('customerSearch') customerSearch!: SearchCustomersComponent;

  company: Company;
  selectedOrganization!: Organization | null;
  selectedUsers: RedactedUser[] = [];
  questionType = 1;
  selectedDossier!: Dossier | null;
  colleagues: RedactedUser[] = [];
  claims!: Claims;
  organizationUsers: [RedactedUser, boolean][] = [];
  chatForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<NewChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationService: OrganizationService,
    private userService: UserService,
    private chatService: ChatService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.claims = this.userService.tryClaims();
    this.company = data.company;

    this.chatForm = this.formBuilder.group({
      name: ['', []]
    });
  }

  async ngOnInit() {
    const you = await this.userService.getUser();
    this.colleagues.push(you);
  }

  async onSelect(event: any | undefined) {
    if (event) {
      if (event.hasOwnProperty('name')) {
        this.selectedOrganization = event;
        this.organizationUsers = (await this.organizationService.getUsers(this.selectedOrganization!.id!, Role.customer)).map(
          (user) => [user, true]
        );
      } else {
        if (!this.selectedUsers.some(user => user.id === event.id)) {
          this.selectedUsers.push(event);
        }
      }
      if (this.customerSearch) {
        this.customerSearch.clearSelected();
      }
    }
  }

  setQuestionType(num: number) {
    this.questionType = num;
  }

  setDossier(event: Dossier | undefined) {
    if (event) {
      this.selectedDossier = event;
    }
  }

  addColleagues(event: any) {
    if (event) {
      if (!this.colleagues.find((colleague) => colleague.id === event.id)) {
        this.colleagues.push(event);
      }
      this.colleagueSearch.clearSelected();
    }
  }

  removeDossier() {
    this.selectedDossier = null;
  }

  removeColleague(id: number) {
    this.colleagues = this.colleagues.filter((colleague) => colleague.id !== id);
  }

  getOrganizationUsersMapped(): number[] {
    const usrs = this.organizationUsers.filter((userSelection) => userSelection[1]);
    return usrs.map((userSelected) => userSelected[0].id);
  }

  getColleaguesMapped(): number[] {
    return this.colleagues.map((colleague) => colleague.id);
  }

  getUsersMapped(): number[] {
    return this.selectedUsers.map((user) => user.id);
  }

  async createChat() {


    if (!this.selectedOrganization && this.selectedUsers.length === 0 && this.colleagues.length <= 1) {
      this.snackBar.open(this.translate.instant('chat.choose_user'), this.translate.instant('close'), { duration: 5000 });
      return;
    }
    const chatModel: CreateChatModel = {
      companyId: this.company.id,
      name: this.chatForm.get('name')?.value ? this.chatForm.get('name')?.value :
        this.selectedOrganization ? this.selectedOrganization.name :
          this.selectedUsers.length > 0 ? this.selectedUsers[0].firstName + ' ' + this.selectedUsers[0].lastName :
            this.colleagues[1].firstName + ' ' + this.colleagues[1].lastName,
      users: this.selectedOrganization ? [...this.getOrganizationUsersMapped(), ...this.getColleaguesMapped()] :
        [...this.getUsersMapped(), ...this.getColleaguesMapped()]
    };

    if (this.selectedDossier) {
      chatModel.dossierId = this.selectedDossier.id;
    }

    if (this.selectedOrganization) {
      chatModel.organizationId = this.selectedOrganization.id;
    }
    const chat = await this.chatService.create(chatModel);
    this.dialogRef.close(chat);
  }

  removeUser(id: number) {
    this.selectedUsers = this.selectedUsers.filter((user) => user.id !== id);
  }

  removeOrganization() {
    this.selectedOrganization = null;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
