import {Routes} from '@angular/router';
import {groupRoutes} from './groups/groups.routing';
import {companyRoutes} from './companies/companies.routes';
import {organizationRoutes} from './organizations/organization.routing';
import localeNDSNL from '@angular/common/locales/nds-NL';
import {registerLocaleData} from '@angular/common';
import {customerRoutes} from './customers/customer.routing';
import {employeeRoutes} from './employees/employee.routing';
registerLocaleData(localeNDSNL);

export const managementRoutes: Routes = [
  {
    path: 'groups',
    data: {
      breadcrumb: 'teams',
    },
    children: groupRoutes
  },
  {
    path: 'customers',
    data: {
      breadcrumb: 'customers',
    },
    children: customerRoutes
  },
  {
    path: 'employees',
    data: {
      breadcrumb: 'employees',
    },
    children: employeeRoutes
  },
  {
    path: 'companies',
    data: {
      breadcrumb: 'companies'
    },
    children: companyRoutes
  },
  {
    path: 'organizations',
    data: {
      breadcrumb: 'organizations',
    },
    children: organizationRoutes
  }
];
