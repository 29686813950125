import { Component, OnInit } from '@angular/core';
import {MailTemplateKind} from '../../functional/models/mail-template.model';
import {MailTemplateService} from '../../services/mail-template/mail-template.service';
import {UserService} from '../../services/user/user.service';
import {MailSettingsService} from '../../services/mail-settings/mail-settings.service';
import {MatLegacySlideToggleChange as MatSlideToggleChange} from '@angular/material/legacy-slide-toggle';

@Component({
  selector: 'app-mail-settings',
  templateUrl: './mail-settings.component.html',
  styleUrls: ['./mail-settings.component.scss']
})
export class MailSettingsComponent implements OnInit {
  emailSettings: MailSettingRow[] = [];

  private templateKinds = MailTemplateKind;
  private kindsList: MailTemplateKind[] = Object.keys(this.templateKinds)
    .map(k => +k)
    .filter(n => !isNaN(n));

  constructor(
    public mailTemplateService: MailTemplateService,
    public userService: UserService,
    public mailService: MailSettingsService,
  ) { }

  async ngOnInit() {
    await this.initSettings();
  }

  async initSettings() {
    const claims = this.userService.tryClaims();
    const setSettings = await this.mailService.getSettings(claims.userId);
    for (const kind of this.kindsList) {
      const meta = this.mailTemplateService.meta(this.kindsList[kind]);
      if (meta.roles.includes(Number(claims.role))) {
        const setSetting = setSettings.find(setting => setting.emailType === kind);

        if (setSettings && setSetting !== undefined) {
          this.emailSettings.push({
            kind,
            displayName: this.mailTemplateService.kindToName(this.kindsList[kind]),
            toggled: false,
            endDate: setSetting!.endDate || null,
            toggleId: setSetting!.id,
          });
        } else {
          this.emailSettings.push({
            kind,
            displayName: this.mailTemplateService.kindToName(this.kindsList[kind]),
            toggled: true,
            endDate: null,
          });
        }
      }
    }
  }

  async setToggled($event: MatSlideToggleChange, settings: MailSettingRow) {
    if (!$event.checked) {
      await this.updateSettings(settings);
    }
    if ($event.checked) {
      if (settings.toggleId) {
        await this.mailService.deleteSetting(settings.toggleId);
        settings.toggleId = undefined;
      }
    }
  }

  async setDate(settings: MailSettingRow) {
    await this.updateSettings(settings);
  }

  async updateSettings(settings: MailSettingRow) {
    const claims = this.userService.tryClaims();
    if (!settings.toggleId) {
      const newSetting = await this.mailService.setSetting({
        emailType: settings.kind,
        userId: claims.userId,
        endDate: settings.endDate ? settings.endDate.toString() : null,
      });
      settings.endDate = newSetting.endDate ? newSetting.endDate.toString() : null;
      settings.toggleId = newSetting.id;
    } else {
      const newSetting = await this.mailService.updateSetting(settings.toggleId!, settings.endDate || null);
      settings.endDate = newSetting.endDate ? newSetting.endDate.toString() : null;
      settings.toggleId = newSetting.id;
    }
  }
}

export class MailSettingRow {
  kind!: number;
  displayName!: string;
  toggled!: boolean;
  endDate?: string | null;
  toggleId?: number;
}
