import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {Router} from '@angular/router';
import {GroupService} from '../../../../services/group/group.service';
import {TranslateService} from '@ngx-translate/core';
import {CreateGroupDialogComponent} from '../../../../dialogs/create-group-dialog/create-group-dialog.component';
import {UserService} from '../../../../services/user/user.service';
import {Group} from '../../../../functional/models/group';
import {Company} from '../../../../functional/models/company';
import {CompanyService} from '../../../../services/company/company.service';
import {Subscription} from 'rxjs';
import {Role} from '../../../../functional/models/role.model';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource!: MatTableDataSource<Group>;
  groups: Array<Group> = [];
  role = 3;
  companySubscription = new Subscription();
  company!: Company;

  constructor(
    public router: Router,
    public groupService: GroupService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    public userService: UserService,
    public companyService: CompanyService,
  ) {
    this.role = this.userService.tryClaims().role;
  }

  async ngOnInit() {
    if (this.role === Role.superuser) {
      this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
        if (company) {
          this.company = company;
        } else {
          this.company = await this.companyService.findCurrentCompany();
        }
        this.refreshGroups();
      });
    } else {
      this.company = await this.companyService.findCurrentCompany();
      this.refreshGroups();
    }
  }

  @ViewChild(MatSort) set content(content: any) {
    if (content && this.dataSource) {
      this.dataSource.sort = content;
      this.dataSource.paginator = this.paginator;
    }
  }

  openGroupCreate() {
    const dialogRef = this.dialog.open(CreateGroupDialogComponent, {autoFocus: false});

    dialogRef.afterClosed().subscribe(async result => {
      if (!result || !result.name || result.name === '') {
        return;
      }
      await this.groupService.createGroup({
        name: result.name,
        description: result.description || '',
        companyId: this.company.id,
      });
      this.snackBar.open(this.translate.instant('group.list.success_create'), '', {
        duration: 2000,
      });
      await this.refreshGroups();
    });
  }

  async refreshGroups() {
    this.groups = await this.groupService.getGroups();
    this.dataSource = new MatTableDataSource(this.groups);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.companySubscription.unsubscribe();
  }

  async redirect(id: number) {
    await this.router.navigate(['management', 'groups', 'details', id]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  handleClick(event: any) {
    if (event === 'new') {
      this.openGroupCreate();
    }
  }
}
