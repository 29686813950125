<h1 mat-dialog-title>{{'settings.dialog.add_url' | translate}}</h1>
<form [formGroup]="nameForm" *ngIf="nameForm">
  <div mat-dialog-content>
    <p>{{'settings.dialog.add_url_desc' | translate}}</p>
    <div class="emma-input-container">
      <label label for="name" class="emma-input-label">{{ 'settings.dialog.domain'  | translate }}</label>
      <input appEmmaInput id="name" formControlName="name">
    </div>
  </div>
</form>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'settings.dialog.back' | translate}}</button>
  <button mat-button (click)="submit()">{{'settings.dialog.submit' | translate}}</button>
</div>
