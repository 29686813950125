<div class="emma-body create-dossier-body table-responsive">
  <div class="row" style="margin-bottom: 20px;">
    <div class="col-md-12">
      <div class="flex items-center">
        <div class="flow-item"
           *ngIf="!dossier && !recurringDossier"
           [ngClass]="{
          'flow-item-active': dossierView !== 'workflow',
          'flow-item-current': dossierView === 'workflow'
          }"
          (click)="dossierView = 'workflow'">
          <div class="flow-item-icon">
            <ng-icon name="heroSquare3Stack3d"></ng-icon>
          </div>
          <div class="flow-item-text">
            {{'dossier_overview.template' | translate}}
          </div>
        </div>

        <div class="flow-line" *ngIf="!dossier && !recurringDossier" [class]="dossierView !== 'workflow' ? 'flow-line-active' : 'flow-line-inactive'"></div>

        <div class="flow-item"
           *ngIf="isRepeat"
          [ngClass]="{
          'flow-item-active': dossierView === 'persons' || dossierView === 'deadlines',
          'flow-item-current': dossierView === 'repeat',
          'flow-item-inactive': dossierView === 'workflow'
          }"
          (click)="dossierView = templateSelected || dossier || recurringDossier ? 'repeat' : dossierView">
          <div class="flow-item-icon">
            <ng-icon name="heroArrowPath"></ng-icon>
          </div>
          <div class="flow-item-text">
            {{'dossier_overview.recurring' | translate}}
          </div>
        </div>

        <div class="flow-line" *ngIf="isRepeat" [class]="dossierView === 'persons' || dossierView  === 'deadlines' ? 'flow-line-active' : 'flow-line-inactive'"></div>

        <div class="flow-item"
           [ngClass]="{
            'flow-item-active': dossierView === 'deadlines',
            'flow-item-current': dossierView === 'persons',
            'flow-item-inactive': dossierView !== 'deadlines' && dossierView !== 'persons'
          }"
          (click)="dossierView = templateSelected || dossier || recurringDossier  ? 'persons' : dossierView">
          <div class="flow-item-icon">
            <ng-icon name="heroUser"></ng-icon>
          </div>
          <div class="flow-item-text">
            {{'dossier_overview.persons' | translate}}
          </div>
        </div>

        <div class="flow-line" [class]="dossierView === 'deadlines' ? 'flow-line-active' : 'flow-line-inactive'"></div>

        <div class="flow-item"
           [ngClass]="{
            'flow-item-current': dossierView === 'deadlines',
            'flow-item-inactive': dossierView !== 'deadlines'
          }"
          (click)="dossierView = templateSelected || dossier || recurringDossier ? 'deadlines' : dossierView">
          <div class="flow-item-icon">
            <ng-icon name="heroFolderOpen"></ng-icon>
          </div>
          <div class="flow-item-text">
            {{'dossier_overview.dossier' | translate}}
          </div>
        </div>

        <div style="margin-left: 60px;">
          <button class="btn btn-blue" *ngIf="dossierView !== 'deadlines'" (click)="nextStep()" [disabled]="!templateSelected && !dossier">{{'dossier_overview.next' | translate }}</button>
          <button class="btn btn-blue" *ngIf="dossierView === 'deadlines' && !dossier && !recurringDossier" (click)="createDossier()">{{'dossier_overview.create' | translate}}</button>
          <button class="btn btn-blue" *ngIf="dossierView === 'deadlines' && (dossier || recurringDossier)" (click)="editDossier()">{{'dossier_overview.update' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="height: 100%; display: flex; flex-flow: column">
    <ng-container *ngIf="dossierView === 'workflow'">
      <div class="col-md-offset-3 col-md-6" style="margin-bottom: 20px">
        <h2 class="emma-subtitle">
          {{'dossier_overview.choose_workflow' | translate}}
        </h2>
        <mat-slide-toggle [(ngModel)]="isRepeat">{{'dossier_overview.repeat' | translate}}</mat-slide-toggle>
      </div>
      <div class="col-md-offset-2 col-md-8" style="overflow-y: auto; display: flex; flex-flow: column; flex: 1; margin-bottom: 100px;">
        <app-dossier-template-selector [company]="company" [(selectedTemplate)]="templateSelected" *ngIf="!dossier && company"></app-dossier-template-selector>
      </div>
    </ng-container>

    <ng-container *ngIf="dossierView === 'repeat' && isRepeat">
      <app-recurring-settings [repeatRule]="recurringDossier?.rrule || repeatRule || 'NAR'" (repeatRuleChange)="repeatRule = $event" *ngIf="thisUser || recurringDossier"></app-recurring-settings>
    </ng-container>

    <ng-container *ngIf="dossierView === 'persons'">
      <app-dossier-customer-selector
        [thisUser]="thisUser"
        [(users)]="customers"
        [(organizations)]="organizations"
        [company]="company"
        [(employees)]="employees"
        *ngIf="company"></app-dossier-customer-selector>
    </ng-container>

    <ng-container *ngIf="dossierView === 'deadlines'">
      <app-dossier-configuration
        [(dossierName)]="dossierName"
        [(deadlines)]="deadlines"
        [dossierTemplate]="templateSelected"
        [recurring]="isRepeat"
        *ngIf="!dossier">
      </app-dossier-configuration>

      <app-dossier-configuration
        [(dossierName)]="dossierName"
        [dossierStates]="dossier"
        [(deadlines)]="deadlines"
        *ngIf="dossier">
      </app-dossier-configuration>
    </ng-container>
  </div>
  <div *ngIf="false" class="row centered" id="dossier-actions">
    <button *ngIf="dossier || recurringDossier"  class="btn btn-blue btn-custom" id="edit-dossier" (click)="editDossier()">
      <b>{{'dashboard.edit_dossier' | translate}}</b>
      <ng-icon class="ng-icon-default-size centered-icon" name="heroPlusSmall"></ng-icon>
      <div class="ripple-container"></div>
    </button>
    <button *ngIf="!dossier && !recurringDossier"  class="btn btn-blue btn-custom" id="create-dossier" (click)="createDossier()">
      <b>{{'dashboard.new_dossier' | translate}}</b>
      <ng-icon class="ng-icon-default-size centered-icon" name="heroPlusSmall"></ng-icon>
      <div class="ripple-container"></div>
    </button>
  </div>
</div>
