<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    [search]="true"
    (searchChange)="applyFilter($event)"
    [buttons]="[{icon:'add', name: 'new' | translate, event: 'new'}]"
    (buttonClicked)="createBusiness($event)">
  </app-sub-nav>
  <div class="table-paginator-container">
    <div class="mat-table-container">
      <table mat-table [dataSource]="dataSource" matSort class="emma-table">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'company.id' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'company.name' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="packages">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'company.package' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <span *ngFor="let packages of row.packages">
              {{ packages.name }},
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
        <tr mat-row class="element" *matRowDef="let row; columns: displayedColumns;" (click)="redirect(row.id)"></tr>
      </table>
    </div>
    <div>
      <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]" ></mat-paginator>
    </div>
  </div>
</div>
