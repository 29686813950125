<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="new-chat-customer-dialog">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'chat.new' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p class="emma-subtitle1"> {{ 'chat.subject' | translate }} </p>
    </div>
  </div>
  <mat-radio-group [(ngModel)]="questionType" class="row" style="margin-bottom: 5px">
    <div class="col-md-6 col-sm-6">
      <mat-radio-button [value]="1"> {{ 'chat.general_question' | translate }} </mat-radio-button>
    </div>
    <div class="col-md-6 col-sm-6">
      <mat-radio-button [value]="2"> {{ 'chat.specific_dossier' | translate }} </mat-radio-button>
    </div>
  </mat-radio-group>

  <div class="row" *ngIf="questionType === 2 && !selectedDossier">
    <div class="col-md-12">
      <app-search-dossiers [company]="company" [userId]="claims.userId" (valueChange)="setDossier($event?.value)"></app-search-dossiers>
    </div>
  </div>

  <div class="row" *ngIf="questionType === 2 && selectedDossier">
    <div class="col-md-12">
      <p class="emma-subtitle1"> {{ 'chat.dossier' | translate }} </p>
    </div>
  </div>
  <div class="row" *ngIf="questionType === 2 && selectedDossier">
    <div class="col-sm-10 col-md-10">
      <p class="emma-font-light">{{selectedDossier.name}}</p>
    </div>
    <div class="col-sm-2 col-md-2">
      <mat-icon class="emma-font-light delete" (click)="removeDossier()">delete</mat-icon>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-centered">
      <button mat-button class="btn btn-blue btn-custom" (click)="createChat()"> {{ 'chat.start' | translate }}</button>
    </div>
  </div>
</div>
