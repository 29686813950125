import {Routes} from '@angular/router';
import {CustomersComponent} from './customers.component';
import {UserDetailsComponent} from '../users/user-details/user-details.component';

export const customerRoutes: Routes = [
  {
    path: 'details/:id',
    data: {
      breadcrumb: 'user.firstName;user.lastName',
      isParam: true,
      subMenu: ['details', 'organizations', 'dossiers', 'folders'],
      renderFolderCrumbs: true
    },
    component: UserDetailsComponent
  },
  {
    path: '',
    component: CustomersComponent
  }
];
