<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="clone-template-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'dossier_template.dialog.clone.title' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <form ngForm [formGroup]="cloneForm">
        <div class="emma-input-container">
          <label label for="name" class="emma-input-label">{{ 'dossier_template.dialog.clone.name' | translate }}</label>
          <input appEmmaInput id="name" formControlName="name">
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <div class="emma-input-container">
        <label label for="description" class="emma-input-label">{{ 'dossier_template.dialog.create_template.description'  | translate }}</label>
        <input appEmmaInput id="description" formControlName="description">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <mat-form-field class="full-width">
        <mat-select [placeholder]="'dossier_template.dialog.create_template.available_for' | translate" required formControlName="designation" [(ngModel)]="designation">
          <mat-option [value]='1'>{{ 'dossier_template.dialog.create_template.company_type' | translate }}</mat-option>
          <mat-option [value]="2">{{ 'dossier_template.dialog.create_template.company' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="designation && designation === 1">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <mat-form-field class="full-width">
        <mat-select placeholder="{{'dossier_template.dialog.create_template.select_company_type' | translate}}" formControlName="companyType">
          <mat-option [value]="0">{{'company.type.bookkeeper' | translate}}</mat-option>
          <mat-option [value]="1">{{'company.type.care' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="designation && designation === 2">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <mat-form-field class="full-width">
        <mat-select formControlName="companyId" required [placeholder]="'dossier_template.dialog.create_template.select_company' | translate">
          <mat-option>
            <ngx-mat-select-search
              [placeholderLabel]="'dossier_template.dialog.create_template.search' | translate"
              ngModel
              (ngModelChange)="filter($event)"
              formControlName="filter"
              [noEntriesFoundLabel]="'dossier_template.dialog.create_template.no_result' | translate">
              <mat-icon ngxMatSelectSearchClear>close</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let company of filteredCompanies" [value]="company.id">
            {{company.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button data-test="delete-submit-button"  class="btn btn-red" (click)="cloneTemplate()">
        <b> {{ 'dossier_template.dialog.clone.submit' | translate }} </b>
      </button>
    </div>
  </div>
</div>
