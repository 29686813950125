<div class="wrapper wrapper-full-page center">
  <div class="container">
    <div class="row login-centered">
      <form class="login-form" [ngClass]="isMobile ? 'no-margin-full-width' : ''" [formGroup]="forgotForm">
        <div class="card card-login card-hidden inline-card" [ngClass]="isMobile ? 'no-margin-full-width' : 'centered-desktop'">
          <img src="assets/img/forgot-header.svg" [ngClass]="isMobile ? 'login-image-mobile' : 'login-image'">
          <div class="card-body body-centered" [ngClass]="isMobile ? '' : 'max-size'">
            <p class="emma-subtitle">{{'auth.forgot.title' | translate}}</p>
            <p class="emma-font-default-small">{{'auth.forgot.desc' | translate}}</p>

            <div class="input-group padding">
              <div class="input-group-prepend">
                <span class="input-group-text" style="padding-left: 0;">
                  <ng-icon name="heroEnvelope" class="ng-icon-default"></ng-icon>
                </span>
              </div>
              <input appEmmaInput type="email" data-test="email-input" placeholder="{{'auth.forgot.email' | translate}}" formControlName="email" name="email">
            </div>

            <a routerLink="/auth" data-test="login-route" class="padding-third">{{'auth.forgot.back' | translate}}</a>

            <div class="card-footer" style="margin-left: 0;">
              <button data-test="submit-button"  class="btn btn-blue btn-custom" (click)="forgot()">
                <b>{{'auth.forgot.submit' | translate}}</b>

              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
