<div class="emma-body" style="background-color: white">
  <div class="col-md-3">
    <mat-selection-list [multiple]="false" class="overflow">
      <ng-container *ngFor="let template of templates; let first = first;">
        <mat-list-option [selected]="first" (click)="setSelected(template)">
          {{ template.name }}
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </div>
  <div class="col-md-9">
    <h1
      class="mail-subject"
      [contentEditable]="true"
      (keydown)="deletePressed($event)"
      (blur)="subject = $event.target.innerHTML"
      (contextmenu)="openContextMenu($event, contextMenu, HTMLSubject)"
      #HTMLSubject [innerHTML]="subject"
      (keyup)="saveRangePosition(HTMLSubject)"
      (click)="saveRangePosition(HTMLSubject)"
      *ngIf="selectedTemplateOptions?.subject"
      (paste)="$event.preventDefault()"
    ></h1>
    <div class="mail-template">
      <div class="mail-container">
        <div class="mail-header">
          <div [class]="'mail-image forgot-password'"></div>
        </div>
        <div class="mail-body">
          <div class="flex-centered">
            <h1
              class="mail-title"
              [contentEditable]="true"
              (keydown)="deletePressed($event)"
              (blur)="header = $event.target.innerHTML"
              (contextmenu)="openContextMenu($event, contextMenu, HTMLHeader)"
              #HTMLHeader [innerHTML]="header"
              (keyup)="saveRangePosition(HTMLHeader)"
              (click)="saveRangePosition(HTMLHeader)"
              (paste)="$event.preventDefault()"
              *ngIf="selectedTemplateOptions?.header"
            ></h1>
          </div>
          <p
            class="mail-content"
            [contentEditable]="true"
            (keydown)="deletePressed($event)"
            (blur)="text = $event.target.innerHTML"
            (contextmenu)="openContextMenu($event, contextMenu, HTMLText)"
            #HTMLText [innerHTML]="text"
            (keyup)="saveRangePosition(HTMLText)"
            (click)="saveRangePosition(HTMLText)"
            (paste)="$event.preventDefault()"
          ></p>
          <div style="display: flex; justify-content: center;" *ngIf="selectedTemplateOptions?.list">
            <table class="mail-table-centered">
              <tr>
                <td>
                  <span class="mail-link-bold">Jaaropgave.pdf</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="mail-link-bold">Document2.pdf</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="centered">
            <span
              class="mail-button"
              [contentEditable]="true"
              (keydown)="deletePressed($event)"
              (blur)="button = $event.target.innerHTML"
              (contextmenu)="openContextMenu($event, contextMenu, HTMLButton)"
              #HTMLButton [innerHTML]="button"
              (keyup)="saveRangePosition(HTMLButton)"
              (click)="saveRangePosition(HTMLButton)"
              (paste)="$event.preventDefault()"
              *ngIf="selectedTemplateOptions?.button"
            ></span>
          </div>
          <p
            class="mail-content"
            [contentEditable]="true"
            (keydown)="deletePressed($event)"
            (blur)="subtext = $event.target.innerHTML"
            (contextmenu)="openContextMenu($event, contextMenu, HTMLSubtext)"
            #HTMLSubtext [innerHTML]="text"
            (keyup)="saveRangePosition(HTMLSubtext)"
            (click)="saveRangePosition(HTMLSubtext)"
            (paste)="$event.preventDefault()"
            *ngIf="selectedTemplateOptions?.subtext"
          ></p>
        </div>
      </div>
      <div>
        <p
          class="footer-text"
          [contentEditable]="true"
          (keydown)="deletePressed($event)"
          (blur)="footer = $event.target.innerHTML"
          (contextmenu)="openContextMenu($event, contextMenu, HTMLFooter)"
          #HTMLFooter
          [innerHTML]="footer"
          (keyup)="saveRangePosition(HTMLFooter)"
          (click)="saveRangePosition(HTMLFooter)"
          (paste)="$event.preventDefault()"
          *ngIf="selectedTemplateOptions?.footer"
        ></p>
      </div>
    </div>
    <div class="flex-right">
      <button  class="btn btn-red" style="margin-right: 16px;" (click)="restoreParams()">Standaard herstellen</button>
      <button  class="btn btn-blue btn-custom" (click)="update()">Opslaan</button>
    </div>
  </div>
</div>
<app-context-menu
  [data]="context"
  #contextMenu
  (contextClicked)="log($event, context)">
</app-context-menu>
