<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <app-ftp-settings [company]="companyId" *ngIf="companyId"></app-ftp-settings>
      </div>
      <div class="col-lg-6 col-md-12">
        <app-url-selector *ngIf="claims.role <= roles.superuser"></app-url-selector>
      </div>
    </div>
  </div>
</div>
