import { Component, OnInit } from '@angular/core';
import addons from '../addons/addons';

@Component({
  selector: 'app-addon-overview',
  templateUrl: './addon-overview.component.html',
  styleUrls: ['./addon-overview.component.scss']
})
export class AddonOverviewComponent implements OnInit {

  listAddons = addons;

  constructor() { }

  ngOnInit(): void {
  }

}
