import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {CreateFolderDialogComponent} from './create-folder-dialog/create-folder-dialog.component';
import {FtpSettingsDialogComponent} from './ftp-settings-dialog/ftp-settings-dialog.component';
import {FilePermissionsDialogComponent} from './file-permissions-dialog/file-permissions-dialog.component';
import {EmailGeneratorDialogComponent} from './email-generator-dialog/email-generator-dialog.component';
import {RequestSigningDialogComponent} from './request-signing-dialog/request-signing-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {SearchCompaniesCustomersEmployeesGroupsModule} from '../../components/search/search-companies-customers-employees-groups/search-companies-customers-employees-groups.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { NgIconsModule } from '@ng-icons/core';
import {RecursiveTreeModule} from '../../components/recursive-tree/recursive-tree.module';
import {EmmaInputModule} from '../../components/base-components/emma-input/emma-input.module';
import {EmmaTooltipModule} from '../../components/base-components/emma-tooltip/emma-tooltip.module';

@NgModule({
  imports: [
    TranslateModule.forRoot(),
    FormsModule,
    CommonModule,
    MatIconModule,
    MatRadioModule,
    MatExpansionModule,
    MatButtonModule,
    SearchCompaniesCustomersEmployeesGroupsModule,
    MatTooltipModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgIconsModule,
    RecursiveTreeModule,
    EmmaInputModule,
    EmmaTooltipModule,
  ],
  declarations: [
    CreateFolderDialogComponent,
    FtpSettingsDialogComponent,
    FilePermissionsDialogComponent,
    EmailGeneratorDialogComponent,
    RequestSigningDialogComponent,
  ]
})
export class ExchangeDialogsModule { }
