import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RedactedUser, User} from '../../../functional/models/user';
import {UserService} from '../../../services/user/user.service';
import {Company} from '../../../functional/models/company';
import {EmmaSelectOption} from '../../base-components/emma-select/emma-select.component';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss']
})
export class SearchUsersComponent implements OnInit {
  @Input() set preSet(users: (User|RedactedUser)[]){
    this.options = users.map((user: User | RedactedUser) => ({
      displayName: user.firstName + ' ' + user.lastName,
      value: user,
      searchObject: user
    }));
  }

  @Input() company!: Company;
  @Output() valueChange: EventEmitter<EmmaSelectOption | undefined> = new EventEmitter<EmmaSelectOption | undefined>();
  @Input() set value(item: EmmaSelectOption | undefined) {
    this.lValue = item;
    this.valueChange.emit(this.lValue);
  };

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  options: EmmaSelectOption[] = [];
  lValue?: EmmaSelectOption;
  users: (User | RedactedUser)[] = [];

  constructor(public userService: UserService) {
  }

  async ngOnInit() {
    if (this.preSet === undefined && this.company) {
      const customers = await this.userService.getCustomers(this.company);
      const employees = await this.userService.getEmployees();
      this.users = [...customers, ...employees];
    } else {
      this.users = this.preSet;
    }
  }

  clearSelected() {
    this.lValue = undefined;
  }
}
