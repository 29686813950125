import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {User} from 'app/functional/models/user';
import {OrganizationService} from 'app/services/organization/organization.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-employee-dialog',
  templateUrl: './add-employee-dialog.component.html',
  styleUrls: ['./add-employee-dialog.component.scss']
})
export class AddEmployeeDialogComponent implements OnInit {

  organizationId!: number;
  selected!: User;

  constructor(
    public dialogRef: MatDialogRef<AddEmployeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationService: OrganizationService,
    public snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.organizationId = data.organizationId;
  }

  ngOnInit(): void {}

  async submit() {
    if (this.selected) {
      await this.organizationService.addUser(this.organizationId, this.selected.id);
      this.snackBar.open(
        this.translate.instant('add.employee_added'),
        this.translate.instant('close'),
        { duration: 5000 },
      );
      this.dialogRef.close();
      return;
    }
    this.snackBar.open(
      this.translate.instant('add.choose_employee'),
      this.translate.instant('close'),
      { duration: 5000 },
    );
  }

  setSelected(user: User | undefined) {
    if (user) {
      this.selected = user;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
