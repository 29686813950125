<div class="card" id="url-selector">
  <div class="card-header card-header-grey card-header-icon">
    <div class="card-icon card-header-custom"><mat-icon>link</mat-icon></div>
    <div class="exchange-card-title-between">
      <h4 class="card-title">
        {{'settings.urls_title' | translate}}
      </h4>

      <div class="exchange-icon-container">
        <mat-icon (click)="openAddDomain()">add</mat-icon>
      </div>
    </div>
  </div>

  <div class="card-body d-flex table-responsive justify-content-center" *ngIf="urls.length == 0">
    <div class="alert alert-grey alert-with-icon col-md-12 justify-content-center">
      <mat-icon>info</mat-icon>
      <span data-notigy="message">{{'settings.urls_none' | translate}}</span>
    </div>
  </div>
  <div class="card-body table-responsive" *ngIf="urls.length > 0">
    <div class="table-container">
      <table mat-table [dataSource]="urlSource" matSort class="emma-table">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'settings.urls_id' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'settings.urls_url' | translate}}  </th>
          <td mat-cell *matCellDef="let row"> {{row.url}} </td>
        </ng-container>

        <ng-container matColumnDef="primary">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'settings.urls_primary' | translate}}  </th>
          <td mat-cell *matCellDef="let row" class="delete" (click)="setPrimary(row)"> <mat-icon>{{row.isPrimary ? 'check' : 'close'}}</mat-icon></td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'settings.urls_delete' | translate}}  </th>
          <td mat-cell *matCellDef="let row" class="delete" (click)="deleteUrl(row)"> <mat-icon>delete</mat-icon></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['id', 'url', 'primary', 'delete']; sticky: true" ></tr>
        <tr mat-row class="element" *matRowDef="let row; columns: ['id', 'url', 'primary', 'delete'];"></tr>
      </table>
    </div>
  </div>
</div>
