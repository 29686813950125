<div class="card">
  <div class="card-header card-header-grey card-header-custom">
    <h4 class="card-title card-title-custom">CssTemplates</h4>
    <p class="card-category card-category-custom">Beheer hier de Css Templates</p>
  </div>
  <div class="card-body">
    <div>
      <form *ngFor="let template of cssTemplates; let i = index" [formGroup]="formGroups[i]">
        <div class="emma-input-container">
          <label label for="content1" class="emma-input-label">Template</label>
          <textarea class="big-area" appEmmaInput id="content1" formControlName="content"></textarea>
        </div>
        <button  (click)="update(i)">Updeet</button>
        <button  (click)="delete(i)">Deleet</button>
      </form>
      <form *ngIf="newFormGroup" [formGroup]="newFormGroup">
        <div class="emma-input-container">
          <label label for="content2" class="emma-input-label">Template</label>
          <textarea class="big-area" appEmmaInput id="content2" formControlName="content"></textarea>
        </div>
        <button  (click)="create()">Maak aan</button>
      </form>
    </div>
  </div>
</div>
