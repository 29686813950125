import { Routes } from '@angular/router';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import {OrganizationDetailsComponent} from './organization-details/organization-details.component';

export const organizationRoutes: Routes = [
  { path: '', component: OrganizationListComponent },
  {
    path: 'details/:id',
    data: {
      isParam: true,
      subMenu: [],
      breadcrumb: 'organization.name',
      renderFolderCrumbs: true
    },
    component: OrganizationDetailsComponent
  }
];
