/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Signing, SigningConversationModel } from 'app/functional/models/signing';
import { SignModel, RequestUserSignatureModel, RequestGroupSignatureModel, RejectModel } from 'app/functional/models/document';

@Injectable({
  providedIn: 'root'
})
export class SigningService {
  private url = environment.rootUrl + 'signing';

  constructor(private http: HttpClient) {
  }

  getSigningsByUser(userId: number): Promise<Signing[]> {
    return this.http.get<Signing[]>(this.url, {
      params: {
        user_id: userId.toString(),
      }
    }).toPromise();
  }

  sign(signingId: number, model: SignModel): Promise<Signing[]> {
    return this.http.post<Signing[]>(`${this.url}/${signingId}/sign`, model).toPromise();
  }

  reject(signingId: number, model: RejectModel): Promise<Signing[]> {
    return this.http.post<Signing[]>(`${this.url}/${signingId}/reject`, model).toPromise();
  }

  create(model: RequestUserSignatureModel | RequestGroupSignatureModel): Promise<Signing[]> {
    return this.http.post<Signing[]>(this.url, model).toPromise();
  }

  getSignings(fileId: number): Promise<Signing[]> {
    return this.http.get<Signing[]>(this.url, {
      params: {
        file_id: fileId.toString(),
      }
    }).toPromise();
  }

  getFileSigningsByUser(fileId: number, userId: number): Promise<Signing[]> {
    return this.http.get<Signing[]>(this.url, {
      params: {
        file_id: fileId.toString(),
        user_id: userId.toString()
      }
    }).toPromise();
  }

  getFileSigningsByDossier(dossierId: number): Promise<Signing[]> {
    return this.http.get<Signing[]>(this.url, {
      params: {
        dossier_id: dossierId.toString()
      }
    }).toPromise();
  }

  getCompanySignings(companyId: number, fulfilled?: boolean): Promise<Signing[]> {
    const params: any = {
      company_id: companyId.toString(),
    };
    if (fulfilled !== undefined) {
      params.signed = fulfilled.toString();
    }

    return this.http.get<Signing[]>(this.url, {
      params
    }).toPromise();
  }

  getChildUserSignings(parentUserId: number, fulfilled?: boolean): Promise<Signing[]> {
    const params: any = {
      parent_id: parentUserId.toString(),
    };

    if (fulfilled !== undefined) {
      params.signed = JSON.stringify(fulfilled);
    }

    return this.http.get<Signing[]>(this.url, {
      params
    }).toPromise();
  }

  getSignature(signatureId: number): Promise<ArrayBuffer> {
    // @ts-ignore
    return this.http.get<ArrayBuffer>(`${this.url}/${signatureId}/signature`, { responseType: 'arraybuffer' }).toPromise();
  }

  startConversation(signatureId: number, model: SigningConversationModel) {
    return this.http.post(`${this.url}/${signatureId}/start_conversation`, model).toPromise();
  }

  getImage(url: string): any {
    return this.http.get(`${url}`).toPromise();
  }
}

