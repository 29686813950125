import { Routes } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import {GeneralSettingsComponent} from './general-settings/general-settings.component';
import {EditorComponent} from './mail-templates/editor/editor.component';
import { ApiKeysComponent } from './api-keys/api-keys.component';
import {WorkflowEditorComponent} from './workflow-editor/workflow-editor.component';
import {SurveyEditorComponent} from './survey-editor/survey-editor.component';
import {WorkflowOverviewComponent} from './workflow-overview/workflow-overview.component';
import {CsvImportComponent} from './csv-import/csv-import.component';

export const settingsRoutes: Routes = [
    { path: 'portal', component: SettingsComponent },
    { path: 'editor', component: EditorComponent },
    { path: 'general', component: GeneralSettingsComponent },
    { path: 'api-keys', component: ApiKeysComponent },
    { path: 'workflow', component: WorkflowOverviewComponent },
    { path: 'workflow/:id', component: WorkflowEditorComponent },
    { path: 'survey', component: SurveyEditorComponent },
    { path: 'import', component: CsvImportComponent}
];
