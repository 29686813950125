import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Folder} from 'app/functional/models/folder';
import {FolderService} from 'app/services/folder/folder.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-ftp-settings-dialog',
  templateUrl: './ftp-settings-dialog.component.html',
  styleUrls: ['./ftp-settings-dialog.component.scss']
})
export class FtpSettingsDialogComponent implements OnInit {
  directoryForm: UntypedFormGroup;
  private folder: Folder;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FtpSettingsDialogComponent>,
    public folderService: FolderService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.folder = data.folder.folder;

    this.directoryForm = this.formBuilder.group({
      directory: [this.folder.ftpDestinationFolder || '/', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  async submit() {
    this.directoryForm.markAllAsTouched();
    if (this.directoryForm.invalid) {
      return;
    }

    await this.folderService.edit(this.folder.id, {
      name: this.folder.name,
      description: this.folder.description,
      parentFolder: this.folder.parentFolder!.id,
      ftpDestinationFolder: this.directoryForm.get('directory')?.value.trim(),
    });

    this.dialogRef.close(true);
    this.snackbar.open(this.translate.instant('success'), this.translate.instant('close'), {duration: 5000});
  }

  closeModal() {
    this.dialogRef.close();
  }
}
