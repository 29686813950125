import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlSelectorComponent } from './url-selector.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { companyRoutes } from '../../pages/management/companies/companies.routes';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(companyRoutes),
        MatTableModule,
        MatButtonModule,
        MatRippleModule,
        MatTooltipModule,
        MatIconModule,
        BrowserAnimationsModule,
        MatSortModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatDialogModule,
        TranslateModule.forRoot()
    ],
    exports: [
        UrlSelectorComponent,
    ],
    declarations: [
        UrlSelectorComponent
    ]
})
export class UrlSelectorModule { }
