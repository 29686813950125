import { Component, OnInit } from '@angular/core';
import { MailTemplateService } from 'app/services/mail-template/mail-template.service';
import { MailTemplate, MailTemplateKind } from 'app/functional/models/mail-template.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as handlebars from 'handlebars';
import { MailParameterService } from 'app/services/mail-parameter/mail-parameter.service';
import { MailParameter } from 'app/functional/models/mail-parameter.model';

@Component({
  selector: 'app-mail-templates',
  templateUrl: './mail-templates.component.html',
  styleUrls: ['./mail-templates.component.scss']
})
export class MailTemplatesComponent implements OnInit {
  templates: MailTemplate[] = [];
  selectedTemplate?: MailTemplate;
  selectedVersions?: number[];
  selectedVersion?: number;
  mailTemplateKinds = MailTemplateKind;
  kindsList: MailTemplateKind[] = Object.keys(this.mailTemplateKinds)
    .map(k => +k)
    .filter(n => !isNaN(n));
  kindToName!: (arg: MailTemplateKind) => string;
  form!: UntypedFormGroup;
  params!: MailParameter[];
  invalid = false;
  showKindSelector = true;

  renderedSubject?: string;
  renderedContent?: string;

  footer = 'Copyright © 2020 MyEmma, Alle rechten voorbehouden.';

  constructor(
    private mailTemplateService: MailTemplateService,
    private mailParameterService: MailParameterService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.kindToName = this.mailTemplateService.kindToName;
  }

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      subject: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });
    this.templates = await this.mailTemplateService.filter({});
    this.params = (await this.mailParameterService.filter({})).filter(mp => !mp.companyId);
    this.selectKind(this.kindsList[0]);
  }

  selectKind(kind?: MailTemplateKind) {
    this.selectedVersions = this.templates.filter(t => t.kind === kind).map(t => t.version);
    const newest = Math.max(1, ...this.selectedVersions);
    this.selectedTemplate = this.templates.find(t => t.kind === kind && t.version === newest);
    this.selectVersion(newest);
  }

  selectVersion(version: number) {
    this.selectedVersion = version;
    const kind = this.selectedTemplate?.kind;
    this.selectedTemplate = this.templates.find(t => t.kind === kind && t.version === version);
    this.form.patchValue({ subject: this.selectedTemplate?.subject });
    this.form.patchValue({ content: this.selectedTemplate?.content });
    this.updateExample();
  }

  async update() {
    if (!this.selectedTemplate) {
      return;
    }
    const body = {
      subject: this.form.get('subject')!.value,
      content: this.form.get('content')!.value,
      kind: this.selectedTemplate.kind,
    };
    this.selectedTemplate = await this.mailTemplateService.update(this.selectedTemplate.id, body);
    this.templates = await this.mailTemplateService.filter({});
  }

  updateExample() {
    const subjectRaw = this.form.get('subject')!.value;
    const contentRaw = this.form.get('content')!.value;
    if (!subjectRaw || !contentRaw) {
      return;
    }
    const subject = handlebars.compile(subjectRaw);
    const content = handlebars.compile(contentRaw);
    const ctx: Record<string, string> = {};
    this.params.forEach(p => ctx[p.name] = p.value);
    try {
      this.renderedSubject = subject(ctx);
      this.renderedContent = content(ctx);
      this.invalid = false;
    } catch {
      this.invalid = true;
    }
  }

  toggleKindSelector() {
    this.showKindSelector = !this.showKindSelector;
  }
}
