import { Injectable } from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private datePipe: DatePipe
  ) { }

  dateToDateTime(date: Date | number) {
    return this.datePipe.transform(date, 'yyyy-MM-ddThh:mm:ss') + 'Z';
  }

  deepCopy<T>(item: any): T {
    return JSON.parse(JSON.stringify(item));
  }

  /**
   * Takes a base64 string with preamble, and returns the mime_type and base64 encoded content,
   * respectively.
   *
   * @param documentStr A base64 encoded string with a preamble. Example: data:text/x-python;base64,aW1wb3J0I...
   */
  splitPreamble(documentStr: string): { mimeType: string; documentURI: string } {
    const parts = documentStr.split(',');
    const mimeType = parts[0].split(':')[1].split(';')[0];
    const documentURI = parts[1];
    return {mimeType, documentURI};
  }
}
