import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {SignaturepadComponent} from '../../../components/signaturepad/signaturepad.component';

@Component({
  selector: 'app-sign-document-dialog',
  templateUrl: './sign-document-dialog.component.html',
  styleUrls: ['./sign-document-dialog.component.scss']
})
export class SignDocumentDialogComponent implements AfterViewInit {

  @ViewChild(SignaturepadComponent, { static: true }) signaturePad!: SignaturepadComponent;

  public signaturePadOptions: any = {
    canvasWidth: 450,
    canvasHeight: 300
  };

  signature?: string;

  constructor(public dialogRef: MatDialogRef<SignDocumentDialogComponent>) { }

  ngAfterViewInit() {
    //this.signaturePad.set('minWidth', 5);
    this.clear();
  }

  clear() {
    this.signaturePad.clear();
  }

  drawComplete() {
    this.signature = this.signaturePad.toDataURL();
  }

  saveSignature() {
    if (this.signature) {
      this.dialogRef.close({signature: this.signature});
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
