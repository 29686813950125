<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="edit-folder-dialog">
  <div class="row">
    <div class="col-md-12 centered">
      <p class="emma-title">{{'dossier.folder.edit_title' | translate}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 col-md-offset-1 centered">
      <div class="emma-input-container" style="width: 80%;" *ngIf="nameForm">
        <label for="filename" class="emma-input-label">{{'dossier.folder.title_placeholder' | translate}}</label>
        <input id="filename" appEmmaInput type="text" placeholder="Voorbeeld B.V." [(ngModel)]="dossierName" [formControl]="nameForm" required>
        <div style="margin-top: 5px">
            <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('required')">
              {{ 'dossier.folder.name_obligated' | translate}}
            </span>
          <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('pattern')">
              {{ 'dossier.folder.name_wrong' | translate}}
            </span>
          <span class="emma-input-error" *ngIf="nameForm.touched && nameForm.invalid && nameForm.hasError('nameInvalid')">
              {{ 'exchange.name_already_exist' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="false">
    <div class="col-md-12 centered">
      <mat-checkbox [(ngModel)]="isRequestable">{{ 'dialog.info.document_request' | translate }}</mat-checkbox>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2">
      <p class="emma-subtitle">{{'dossier.customers' | translate}}</p>
    </div>
  </div>

  <div class="row" *ngFor="let customer of customerPermissions">
    <div class="col-md-3">
      <b>{{customer[0].firstName + ' ' + customer[0].lastName}}</b>
    </div>
    <div class="col-md-1 col-md-offset-1">
      <button mat-icon-button (click)="deleteCustomer(customer[0])">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div class="col-md-7 align-center">
      <mat-radio-group [(ngModel)]="customer[1]">
        <mat-radio-button [value]="1">{{'dossier.rights.rwa' | translate}}</mat-radio-button> &nbsp;&nbsp;
        <mat-radio-button [value]="2">{{'dossier.rights.r' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 col-md-offset-2 centered">
      <app-search-customers [preSet]="dossier.customers" class="full-width" (valueChange)="onCustomerSelected($event?.value)" class="full-width" #customerSearch></app-search-customers>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2">
      <p class="emma-subtitle">{{'dossier.employees' | translate}}</p>
    </div>
  </div>

  <div class="row" *ngFor="let employee of employeePermissions">
    <div class="col-md-3">
      <b>{{employee[0].firstName + ' ' + employee[0].lastName}}</b>
    </div>
    <div class="col-md-1 col-md-offset-1">
      <button mat-icon-button (click)="deleteEmployee(employee[0])">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div class="col-md-7 align-center">
      <mat-radio-group [(ngModel)]="employee[1]">
        <mat-radio-button [value]="3">{{'dossier.rights.rwd' | translate}}</mat-radio-button>&nbsp;&nbsp;
        <mat-radio-button [value]="2">{{'dossier.rights.r' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 col-md-offset-2 centered">
      <app-search-employees [preSet]="dossier.employees" class="full-width" (valueChange)="onEmployeeSelected($event?.value)" #employeeSearch></app-search-employees>
    </div>
  </div>

  <div class="row centered">
    <button  class="btn btn-blue btn-custom" (click)="edit()">
      <b>{{'dossier.folder.edit' | translate}}</b>
    </button>
  </div>
</div>
