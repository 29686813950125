<div class="table-paginator-container">
  <div class="mat-table-container">
    <table mat-table [dataSource]="dataSource" class="big-table folder-table emma-table" matSort (matSortChange)="storeSort($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="listSelection.hasValue() && isAllSelected()"
            [indeterminate]="listSelection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? toggle(row) : null"
            [checked]="isSelected(row)"
            [aria-label]="checkboxLabel(row)"
            class="selector">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-icon [ngClass]="document.getIconColor(row)" *ngIf="row?.extension" class="big-file-icon">
            {{ document.getIcon(row) }}
            <span class="type" *ngIf="document.getIcon(row) == 'insert_drive_file'"></span>
          </mat-icon>
          <mat-icon class="folder-icon" *ngIf="!row?.extension && !row?.filename">folder</mat-icon>
          <mat-icon class="big-file-icon-grey" *ngIf="row.filename">insert_drive_file</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'file_table.naam' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="folder-name-field">{{element?.name || element?.filename || '-'}}</td>
      </ng-container>
      <ng-container matColumnDef="extension">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'file_table.extensie' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element?.extension || '-'}} </td>
      </ng-container>
      <ng-container matColumnDef="size">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'file_table.grootte' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.size ? floorSize(element.size / 1000000) + ' MB' : '-'}} </td>
      </ng-container>
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'file_table.datum' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element?.creationDate | date:'dd/MM/yyy HH:mm' || '-'}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="clickRow(row)"
        [ngClass]="{'row-selected': listSelection.isSelected(row) || highlighted == row}"
        (dragover)="highlightFolder(row, $event)"
        (dragleave)="unHighlightFolder(row)"
        (drop)="fileDragDropped($event, row)"
        (dragstart)="startDragging(row, $event)"
        (dragend)="stopDragging(row)"
        [draggable]="true"
        style="z-index: 1000; position: relative;">
      </tr>
    </table>
  </div>
  <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
