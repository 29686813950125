<div class="exchange-details-container" *ngIf="listSelection.selected.length === 0">
  <div class="exchange-details-header">
    <div class="exchange-header-icon">
      <mat-icon style="color: lightgray">folder</mat-icon>
      <span class="question">?</span>
    </div>
    <div class="exchange-header-name1">
      {{ 'exchange_details.header' | translate }}
    </div>
  </div>
  <div class="exchange-details-body">
    <mat-divider></mat-divider>
    <div class="exchange-details-actions">
      <ul class="exchange-action-list">
        <li id="list-button-create-folder" *ngIf="canUserCreate()" (click)="createFolder()"><ng-icon class="ng-icon-default" name="heroFolderPlus"></ng-icon> {{ 'exchange_details.create_folder' | translate }} </li>
        <li id="list-button-request" *ngIf="canUserAdministrateCurrentFolder()" (click)="openFileRequest()"><ng-icon class="ng-icon-default" name="heroDocumentText"></ng-icon> {{ 'exchange_details.request' | translate }} </li>
        <li id="list-button-upload" *ngIf="canUserWrite()" (click)="uploadFile()"><ng-icon class="ng-icon-default" name="heroDocumentArrowUp"></ng-icon> {{ 'exchange_details.upload_file' | translate }} </li>
      </ul>
    </div>
  </div>
</div>
<div class="exchange-details-container" *ngIf="listSelection.selected.length > 0">
  <div class="exchange-details-header" *ngIf="listSelection.selected.length === 1">
    <div class="exchange-header-icon unknown-icon" *ngIf="listSelection.selected[0].request">
      <mat-icon>insert_drive_file</mat-icon>
      <span class="question">?</span>
    </div>
    <div class="exchange-header-icon document-icon" *ngIf="listSelection.selected[0].file">
      <mat-icon>insert_drive_file</mat-icon>
    </div>
    <div class="exchange-header-icon folder-icon" *ngIf="listSelection.selected[0].folder">
      <mat-icon>folder</mat-icon>
    </div>
    <div *ngIf="canUserAdministrate" style="display: flex" class="pointer">
      <div id="edit-title" #editTitle class="exchange-header-name" contenteditable (keypress)="blockEnter($event)" (paste)="$event.preventDefault()" (blur)="setTitle($event)">
        {{listSelection.selected[0]?.file?.name || listSelection.selected[0]?.folder?.name || listSelection.selected[0].request?.filename}}
      </div>
      <mat-icon class="edit-icon" (click)="editTitle.focus()">edit</mat-icon>
    </div>
    <div [id]="'edit-title'" class="exchange-header-name" *ngIf="!canUserAdministrate">
      {{listSelection.selected[0]?.file?.name || listSelection.selected[0]?.folder?.name || listSelection.selected[0].request?.filename}}
    </div>
    <div class="exchange-header-date">
      {{ 'exchange_details.created' | translate }} {{listSelection.selected[0]?.file?.creationDate || listSelection.selected[0]?.request?.creationDate || listSelection.selected[0]?.folder?.creationDate | date:'dd-MM-yyy'}}
    </div>
    <div *ngIf="canUserAdministrate" style="display: flex" class="pointer">
      <div id="edit-description" class="exchange-header-description" #editDescription contenteditable (keypress)="blockEnter($event)" (paste)="$event.preventDefault()" (blur)="setDescription($event)">
        {{listSelection.selected[0]?.file?.description || listSelection.selected[0]?.request?.message || listSelection.selected[0]?.folder?.description || 'exchange_details.example_description' | translate}}
      </div>
      <mat-icon class="edit-icon" (click)="editDescription.focus()">edit</mat-icon>
    </div>
    <div [id]="'edit-description'" class="exchange-header-description" *ngIf="!canUserAdministrate">
      {{listSelection.selected[0]?.file?.description || listSelection.selected[0]?.request?.message || listSelection.selected[0]?.folder?.description || 'exchange_details.example_description' | translate}}
    </div>
  </div>
  <div class="exchange-details-header" *ngIf="listSelection.selected.length > 1">
    <div class="exchange-header-icon folder-icon">
      <mat-icon>folder</mat-icon>
      <mat-icon class="overlap">folder</mat-icon>
    </div>
    <div class="exchange-header-name">
     ({{listSelection.selected.length}}) {{ 'exchange_details.documents' | translate }}
    </div>
  </div>
  <div class="exchange-details-body">
    <div class="exchange-details-accessors" id="accessors-list" *ngIf="listSelection.selected.length === 1">
      <div *ngFor="let organization of listSelection.selected[0].organizations">
        <br />
        {{organization[0].name}}:<br/>
        <div class="exchange-accessors-container" *ngIf="organization[1].length > 0">
          <ng-container *ngFor="let user of organization[1]">
            <app-user-icon
              *ngIf="user.role !== localRole.admin && user.role !== localRole.employee"
              [short]="getFistLetters(user.firstName, user.lastName)"
              [appEmmaTooltip]="getTooltip(user)"></app-user-icon>
          </ng-container>
        </div>
      </div>

      <div *ngIf="listSelection.selected[0].groupPermissions && listSelection.selected[0].groupPermissions.length > 0">
        <br />
        {{ 'exchange_details.groepen' | translate }}
        <br/>
        <div class="exchange-accessors-container">
          <app-user-icon
            *ngFor="let groupPermission of listSelection.selected[0].groupPermissions"
            [short]="getFistLetter(groupPermission.group.name)"
            [appEmmaTooltip]="getGroupTooltip(groupPermission)"></app-user-icon>
        </div>
      </div>

      <div *ngIf="hasSingleCustomers(listSelection.selected[0])">
        <br />
        {{ 'exchange_details.gebruikers' | translate }}
        <br/>
        <div class="exchange-accessors-container">
          <ng-container *ngFor="let user of listSelection.selected[0].singleUsers">
            <app-user-icon
              *ngIf="user.role !== localRole.admin && user.role !== localRole.employee"
              [short]="getFistLetters(user.firstName, user.lastName)"
              [appEmmaTooltip]="getTooltip(user)"></app-user-icon>
          </ng-container>
        </div>
      </div>

      <div *ngIf="hasEmployees(listSelection.selected[0])">
        <br />
        {{ 'exchange_details.employees' | translate }}
        <br/>
        <div class="exchange-accessors-container">
          <ng-container *ngFor="let user of listSelection.selected[0].singleUsers">
            <app-user-icon
              *ngIf="user.role === localRole.admin || user.role === localRole.employee"
              [short]="getFistLetters(user.firstName, user.lastName)"
              [appEmmaTooltip]="getTooltip(user)"></app-user-icon>
          </ng-container>

          <ng-container *ngFor="let organization of listSelection.selected[0].organizations">
            <ng-container *ngIf="organization[1].length > 0">
              <ng-container *ngFor="let user of organization[1]">
                <app-user-icon
                  *ngIf="user.role === localRole.admin || user.role === localRole.employee"
                  [short]="getFistLetters(user.firstName, user.lastName)"
                  [appEmmaTooltip]="getTooltip(user)"></app-user-icon>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div *ngIf="listSelection.selected[0].signings?.length > 0">
        <br />
        {{ 'exchange_details.signeren' | translate }}<br/>
        <div class="exchange-accessors-container">
        <app-user-icon
          *ngFor="let userSigning of listSelection.selected[0].signings"
          (click)="openSigningDetails(userSigning)"
          [short]="getFistLetters(userSigning.user.firstName, userSigning.user.lastName)"
          [badge]="getBadge(userSigning)"
          [appEmmaTooltip]="getSigningTooltip(userSigning)"></app-user-icon>
        </div>
      </div>
    </div>

    <ng-container *ngIf="listSelection.selected.length === 1 && canUserRead && currentUserRole !== localRole.customer">
      <mat-divider></mat-divider>

      <mat-expansion-panel class="exchange-child-folder-overview" [expanded]="childViewOpened" (opened)="setChildrenOpened()" (closed)="setChildrenClosed()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Onderliggende mappen
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item class="pointer" *ngFor="let item of children?.folders" (click)="emitActiveFolder(item, this.listSelection.selected[ 0 ].folder)">
            <mat-icon mat-list-icon>folder</mat-icon>
            <h4 mat-line>{{item.name}}</h4>
          </mat-list-item>
        </mat-list>
        <mat-list>
          <mat-list-item class="pointer" *ngFor="let item of children?.files" (click)="emitActiveFile(item)">
            <mat-icon mat-list-icon>insert_drive_file</mat-icon>
            <h4 mat-line>{{item.name}}</h4>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </ng-container>

    <mat-divider></mat-divider>
    <div class="exchange-details-actions">
      <ul class="exchange-action-list">
        <li id="list-button-filerequest-fulfill" *ngIf="listSelection.selected.length === 1 && canUserWrite() && hasRequest()" (click)="openFileReqestFulfillDialog(listSelection.selected[0])"><ng-icon class="ng-icon-default" name="heroDocumentArrowUp"></ng-icon> {{ 'exchange_details.upload_file' | translate }} </li>
        <li id="list-button-download" *ngIf="canUserRead && !hasRequest()" (click)="triggerDownloads(downloader)"><ng-icon class="ng-icon-default" name="heroDocumentArrowDown"></ng-icon> {{'exchange_details.download' | translate }} </li>
        <li id="list-button-view" *ngIf="listSelection.selected.length === 1 && canUserRead && !hasRequest()" (click)="emitActiveItem()"><ng-icon class="ng-icon-default" name="heroDocumentMagnifyingGlass"></ng-icon> {{ 'exchange_details.view' | translate }} </li>
        <li id="list-button-clone" *ngIf="canUserRead && !hasRequest() && currentUserRole !== localRole.customer" (click)="cloneFiles()"><ng-icon class="ng-icon-default" name="heroDocumentDuplicate"></ng-icon> {{ 'exchange_details.clone_to' | translate }} </li>
        <li id="list-button-move" *ngIf="currentUserRole !== localRole.customer && canUserRead && canUserDelete" (click)="moveFiles()"><ng-icon class="ng-icon-default" name="heroClipboardDocument"></ng-icon> {{ 'exchange_details.move' | translate }} </li>
        <li id="list-button-give-access" *ngIf="listSelection.selected.length > 1 && currentUserRole !== localRole.customer && canUserAdministrate && !hasFile() && !hasRequest()" (click)="openPermissionsDialog()"><ng-icon class="ng-icon-default" name="heroUserPlus"></ng-icon> {{ 'exchange_details.give_access' | translate }} </li>
        <li id="list-button-edit-access" *ngIf="listSelection.selected.length === 1 && currentUserRole !== localRole.customer && canUserAdministrate && !hasFile() && !hasRequest()" (click)="openPermissionsDialog()"><ng-icon class="ng-icon-default" name="heroUserPlus"></ng-icon> {{ 'exchange_details.edit_access' | translate }} </li>
        <li id="list-button-request-approved" *ngIf="currentUserRole !== localRole.customer && canUserAdministrate && !hasFolder() && !hasRequest()" (click)="openSignatureDialog()"><ng-icon class="ng-icon-default" name="heroPencilSquare"></ng-icon> {{ 'exchange_details.request_approve' | translate }} </li>
        <li id="list-button-approvals-view" *ngIf="listSelection.selected.length === 1 && !hasFolder() && !hasRequest() && hasCompletedSignature() && currentUserRole !== localRole.customer && canUserAdministrate" (click)="openSignaturesDialog()"><ng-icon class="ng-icon-default" name="heroCheckBadge"></ng-icon> {{ 'exchange_details.view_approvals' | translate }} </li>
        <li id="list-button-duplicate" *ngIf="currentUserRole !== localRole.customer && canUserWrite() && canUserRead && !hasFile() && !hasRequest()" (click)="duplicateFolder()"><ng-icon class="ng-icon-default" name="heroSquare2Stack"></ng-icon> {{ 'exchange_details.duplicate' | translate }} </li>
        <li id="list-button-email-generation" *ngIf="listSelection.selected.length === 1 && !hasFile() && !hasRequest() && currentUserRole !== localRole.customer" (click)="openEmailDialog()"><ng-icon class="ng-icon-default" name="heroAtSymbol"></ng-icon> {{ 'exchange_details.generate_email' | translate }} </li>
        <li id="list-button-delete" *ngIf="canUserDelete" (click)="deleteItems()"><ng-icon class="ng-icon-default" name="heroTrash"></ng-icon> {{ 'exchange_details.delete' | translate }} </li>
        <li id="list-button-ftp" *ngIf="currentUserRole !== localRole.customer && ftpSet && canUserAdministrate && !hasFile() && !hasRequest()" (click)="openFtpSettingsDialog()"><ng-icon class="ng-icon-default" name="heroServer"></ng-icon> {{ 'exchange_details.ftp_settings' | translate }} </li>
      </ul>
    </div>
    <a #downloader style="display: none" [href]="downloadUrl"></a>
  </div>
</div>
