<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-success card-header-icon" (click)="toggleKindSelector()">
            <div class="card-icon"><mat-icon>drafts</mat-icon></div>
            <h4 class="card-title">{{ 'templates.email' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- Selector for the email kinds -->
              <div class="col-md-3" *ngIf="showKindSelector">
                <mat-selection-list [multiple]="false">
                  <ng-container *ngFor="let kind of kindsList; let first = first;">
                    <mat-list-option [selected]="first" [value]="kind" (click)="selectKind(kind)">
                      {{ kindToName(kind) }}
                    </mat-list-option>
                  </ng-container>
                </mat-selection-list>
              </div>

              <div [class]="showKindSelector ? 'col-md-9' : 'col-md-12'">
                <div class="row">
                  <div class="col-md-6">
                    <!-- Selector for the template version -->
                    <mat-form-field *ngIf="selectedVersions" class="pad-bottom">
                      <mat-select [value]="selectedVersions[0]">
                        <mat-option
                          *ngFor="let version of selectedVersions; let first = first;"
                          [value]="version"
                          (click)="selectVersion(version)"
                        >
                          {{ version }}
                        </mat-option>
                      </mat-select>
                      <mat-hint>
                        {{ 'templates.version' | translate }}
                      </mat-hint>
                    </mat-form-field>

                    <p>
                      Typ hieronder je nieuwe template voor een email. Parameters van de gebruiker
                      die je wil gebruiken (zoals <code>user.email</code>) moeten geprefixt worden
                      met een <code>\</code> (e.g. <code>\&#123;&#123; user.email &#125; &#125;
                      </code>). MailParameters die direct geinclude moeten worden moeten niet
                      geprefixt worden.
                    </p>

                    <!-- Text inputs for the content and the subject templates -->
                    <form [formGroup]="form">
                      <mat-form-field>
                        <mat-label>
                          {{ 'templates.subject' | translate }}
                        </mat-label>
                        <input matInput formControlName="subject" (keyup)="updateExample()">
                      </mat-form-field>
                      <mat-form-field class="mono">
                        <mat-label>
                          {{ 'templates.content' | translate }}
                        </mat-label>
                        <textarea matInput formControlName="content" rows="50" placeholder="" (keyup)="updateExample()"></textarea>
                      </mat-form-field>
                      <button  (click)="update()">{{ 'templates.update' | translate }}</button>
                    </form>
                  </div>

                  <div class="col-md-6 mono" style="white-space: pre-wrap;">
                    <h3>
                      {{ renderedSubject }}
                    </h3>
                    <p>
                      {{ renderedContent }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
