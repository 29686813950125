import { RedactedUserWithPermissions } from './user';

export class ParentFolder {
  companyId!: number;
  creationDate!: Date;
  description!: string;
  id!: number;
  name!: string;

  static default(): ParentFolder {
    return {
      companyId: 1,
      creationDate: new Date(),
      description: 'De map waarin het bestand zit',
      id: 1,
      name: 'Mijn map',
    };
  }
}

export class Folder {
  id!: number;
  name!: string;
  description!: string;
  companyId!: number;
  creationDate!: Date;
  parentFolder?: ParentFolder;
  isFinalFolder?: boolean;
  ftpDestinationFolder?: string;
  labels!: number[];
  permission?: string;
  pending?: boolean; // checks whether the model is alreading being processed.
  doubleClicked?: boolean; // checks whether the model should be opened after processing.

  static default() {
    return {
      id: 1,
      name: 'Mapje',
      description: 'Omschrijving mapje',
      companyId: 1,
      creationDate: new Date(),
      parentFolder: ParentFolder.default(),
      isFinalFolder: undefined,
      ftpDestinationFolder: undefined,
      permission: undefined,
    };
  }
}

export class FolderWithHistory extends Folder {
  originalPath?: string;
}

export class FolderWithChildren extends FolderWithHistory {
  children: FolderWithChildren[] = [];
}

export class FolderMeta {
  users!: RedactedUserWithPermissions[];
}

export class CreateFolderModel {
  name!: string;
  description!: string;
  parentFolder!: number;
}

export class EditFolderModel {
  name!: string;
  description!: string;
  parentFolder!: number;
  ftpDestinationFolder?: string;
}
