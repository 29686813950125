import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OrganizationService} from '../../../services/organization/organization.service';
import {Organization} from '../../../functional/models/organization.model';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogComponent} from '../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogType} from '../../../functional/models/dialog.model';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent implements OnInit {
  @Input() organizationId!: number;
  @Input() organization!: Organization;

  organizationForm!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private organizationService: OrganizationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.organizationForm = this.formBuilder.group({
      id: [this.organization.id, []],
      name: [this.organization.name, [Validators.required]],
      fiscalNumber: [this.organization.fiscalNumber, []],
      kvk: [this.organization.kvk, []],
      postalCode: [this.organization.postalCode, []],
      street: [this.organization.street, []],
      houseNumber: [this.organization.houseNumber, []],
      premise: [this.organization.premise, []],
      city: [this.organization.city, []],
      companyId: [this.organization.companyId, []],
      relationId: [this.organization.relationId, []]
    });

  }

  async updateOrganization() {
    await this.organizationService.update(this.organizationForm.value as Organization);
    this.snackBar.open(this.translate.instant('organization.update.submitted'), '', {duration: 2000});
  }

  async deleteOrganization() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,
      {
        panelClass: 'confirm-dialog',
        data: {dialogType: ConfirmDialogType.deleteOrganization},
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (res) {
        await this.organizationService.delete(this.organizationId);
        await this.router.navigate(['management', 'organizations']);
      }
    });
  }
}
