<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    [search]="true"
    (searchChange)="dossierOverview.filter = $event"
    [buttons]="[
    {icon:'add', name: 'new' | translate, event: 'new', hidden: dossierOverview.selection.selected.length !== 0 || claims.role === localRole.customer},
    {name: 'options' | translate, event: 'menu', menu: true, hidden: dossierOverview.selection.selected.length === 0}
    ]"
    (buttonClicked)="handleClick($event)"
    [navigation]="[
      {name: 'dossier_overview.open' | translate, event: 'open'},
      {name: 'dossier_overview.closed' | translate, event: 'closed'},
      {name: 'dossier_overview.all' | translate, event: 'all'},
      {name: 'dossier_overview.recurring' | translate, event: 'recurring', hidden: claims.role === localRole.customer}
    ]"
    (navigationChange)="setView($event)">

    <ng-container select>
      <span class="no-select" (click)="dossierOverview.downloadDossiers();">{{'dossier_overview.download' | translate}}</span>
      <span class="no-select" (click)="dossierOverview.deleteSelected();">{{'dossier_overview.delete' | translate}}</span>
    </ng-container>
  </app-sub-nav>
  <app-dossier-overview [selectable]="true" [view]="view" #dossierOverview></app-dossier-overview>
</div>
