import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RedactedUser, User} from '../../../functional/models/user';
import {UserService} from '../../../services/user/user.service';
import {Company} from '../../../functional/models/company';
import {EmmaSelectOption} from '../../base-components/emma-select/emma-select.component';

@Component({
  selector: 'app-search-customers-and-employees',
  templateUrl: './search-customers-and-employees.component.html',
  styleUrls: ['./search-customers-and-employees.component.scss']
})
export class SearchCustomersAndEmployeesComponent implements OnInit {
  @Input() preSet?: (User | RedactedUser)[];
  @Input() company!: Company;
  @Output() valueChange: EventEmitter<EmmaSelectOption | undefined> = new EventEmitter<EmmaSelectOption | undefined>();
  @Input() set value(item: EmmaSelectOption | undefined) {
    this.lValue = item;
    this.valueChange.emit(this.lValue);
  };

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  options: EmmaSelectOption[] = [];
  lValue?: EmmaSelectOption;
  users: (User | RedactedUser)[] = [];

  constructor(public userService: UserService) {
  }

  async ngOnInit() {
    await this.resetCustomers();
  }

  async resetCustomers() {
    if (!this.preSet) {
      this.users = (await Promise.all([
        this.userService.getCustomers(this.company),
        this.userService.getEmployees()
      ])).flat();
    } else {
      this.users = this.preSet;
    }
    this.options = this.users.map((user: User | RedactedUser) => ({
      displayName: user.firstName + ' ' + user.lastName,
      value: user,
      searchObject: user
    }));
  }

  clearSelected() {
    this.lValue = undefined;
  }

}
