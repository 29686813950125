import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '../../../functional/models/company';
import { Group } from '../../../functional/models/group';
import { UserService } from '../../../services/user/user.service';
import { GroupService } from '../../../services/group/group.service';
import { Role } from '../../../functional/models/role.model';
import { EmmaSelectOption } from '../../base-components/emma-select/emma-select.component';

@Component({
  selector: 'app-search-groups',
  templateUrl: './search-groups.component.html',
  styleUrls: ['./search-groups.component.scss']
})
export class SearchGroupsComponent implements OnInit {
  @Input() company!: Company;
  @Input() preSetGroup?: Group[];
  @Output() valueChange: EventEmitter<EmmaSelectOption | undefined> = new EventEmitter<EmmaSelectOption | undefined>();
  @Input() set value(item: EmmaSelectOption | undefined) {
    this.lValue = item;
    this.valueChange.emit(this.lValue);
  };

  get value(): EmmaSelectOption | undefined {
    return this.lValue;
  }

  options: EmmaSelectOption[] = [];
  lValue?: EmmaSelectOption;
  searchable: Group[] = [];

  constructor(
    private userService: UserService,
    private groupService: GroupService
  ) {}

  async ngOnInit() {
    await this.resetOrganizations();
  }

  async resetOrganizations() {
    const claims = this.userService.tryClaims();
    if (!this.preSetGroup) {
      if (claims.role === Role.superuser || claims.role === Role.admin) {
        this.searchable = [...this.searchable, ...(await this.groupService.readByCompany(claims.companyId))];
      } else if (claims.role === Role.employee) {
        this.searchable = [...this.searchable, ...(await this.groupService.readByUser(claims.userId))];
      }
    } else {
      this.searchable = [...this.searchable, ...this.preSetGroup];
    }
    this.options = this.searchable.map((group: Group) => ({
      displayName: group.name,
      value: group,
      searchObject: group
    }));
  }

  clearSelected() {
    this.lValue = undefined;
  }
}
