import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DownloadZipModel, Zip, ZipUrlModel} from '../../functional/models/zip.model';

@Injectable({
  providedIn: 'root'
})
export class ZipService {
  private url = `${environment.rootUrl}zip`;

  constructor(private http: HttpClient) {
  }

  getZips(userId: number): Promise<Zip[]> {
    return this.http.get<Zip[]>(`${this.url}?user_id=${userId}`).toPromise();
  }

  createZip(model: DownloadZipModel) {
    return this.http.post(`${this.url}`, model).toPromise();
  }

  getUrlFromZip(userId: number, fileName: string): Promise<ZipUrlModel> {
    const fileEncoded = encodeURIComponent(encodeURIComponent(fileName));
    return this.http.get<ZipUrlModel>(`${this.url}/url?user_id=${userId}&file_name=${fileEncoded}`).toPromise();
  }
}
