<div class="exchange-overview-wrapper emma-body">
  <ngx-file-drop
      data-test="dropzone"
      [dropZoneClassName]="isUserOverview ? 'fill-screen-user' : 'fill-screen'"
      [contentClassName]="isUserOverview ? 'fill-screen-user' : 'fill-screen'"
      class="explorer-container"
      (onFileDrop)="dropped($event)"
      [disabled]="!canUserWrite()">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div
          class="illustration"
          *ngIf="folders.length === 0 &&
          documents.length === 0 &&
          uploads.length === 0 &&
          requests.length === 0">
          <img src="assets/img/illustrations/add_files.svg">
          <h1 class="not-found-title">{{'exchange.no_files' | translate}}</h1>
          <span>
            {{'exchange.no_files_desc1' | translate}}
            {{'exchange.no_files_desc2' | translate}}
          </span>
        </div>

        <div class="fill-screen" *ngIf="view === 'list'">
          <app-file-table
            [folders]="folders"
            [documents]="documents"
            [uploads]="uploads"
            [fileRequests]="requests"
            [listSelection]="listSelection"
            [(highlighted)]="highlighted"
            [(dragging)]="dragging"
            (dropped)="fileDragDropped($event)"
            (selectFileChange)="selectDocument($event)"
            (selectFolderChange)="selectFolder($event)"
            (selectRequestChange)="selectRequest($event)"
            (clickFileChange)="clickDocument($event)"
            (clickFolderChange)="clickFolder($event)"
            (clickRequestChange)="clickRequest($event)"
            (listUpdated)="refreshDisplay()"
            (dropUpload)="dropped($event.event, $event.file)"
            style="z-index: 99"
            *ngIf="!(uploads.length === 0 && documents.length === 0 && folders.length === 0 && requests.length === 0)"
          ></app-file-table>
        </div>
        <div class="document-list" *ngIf="view === 'small'">
          <ng-container *ngFor="let folder of folders">
            <ngx-file-drop data-test="dropzone" dropZoneClassName="document-list__item" contentClassName="document-list__item" class="document-list__item" (onFileDrop)="dropped($event, folder)" [disabled]="!canUserWrite()">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <app-folder
                  [id]="folder.id"
                  [folder]="folder"
                  [selected]="listSelection"
                  [highlighted]="highlighted"
                  (selectChange)="selectFolder(folder)"
                  (clickChange)="clickFolder(folder)"
                  (dragover)="highlightFolder(folder, $event)"
                  (dragleave)="unHighlightFolder(folder)"
                  (drop)="fileDragDropped($event)"
                  (dragstart)="startDragging(folder, $event)"
                  (dragend)="stopDragging()"
                  [draggable]="true"
                  class="document-list__item"
                  [attr.data-test]="'folder-block-' + folder.id">
                </app-folder>
              </ng-template>
            </ngx-file-drop>
          </ng-container>
          <ng-container *ngFor="let request of requests">
            <app-file-request
              [request]="request"
              [appEmmaTooltip]="request.filename"
              [selected]="listSelection"
              (selectChange)="selectRequest(request)"
              (clickChange)="clickRequest(request)"
              (drop)="fileDragDropped($event)"
              (dragstart)="startDragging(request, $event)"
              (dragend)="stopDragging()"
              [draggable]="true"
              *ngIf="request.isActive && !request.fileId"
              class="document-list__item"
              [attr.data-test]="'request-block-' + request.id">
            </app-file-request>
          </ng-container>
          <app-document
            *ngFor="let document of documents"
            [document]="document"
            [selected]="listSelection"
            (selectChange)="selectDocument(document)"
            (clickChange)="clickDocument(document)"
            [draggable]="true"
            (dragstart)="startDragging(document, $event)"
            (dragend)="stopDragging()"
            [attr.data-test]="'file-block-' + document.id"
            class="document-list__item">
          </app-document>
          <ng-container *ngFor="let upload of uploads">
            <app-upload
              *ngIf="upload.parentFolderId === currentFolder.id"
              [upload]="upload"
              class="document-list__item"
              [appEmmaTooltip]="upload.name">
            </app-upload>
          </ng-container>
        </div>
      </ng-template>
    </ngx-file-drop>
  <app-exchange-details
    class="explorer-exchange-details"
    [listSelection]="listSelection"
    (explorerChanged)="refreshDisplay()"
    (navigationChange)="navigate($event)"
    (folderCreateChange)="openDialog()"
    (uploadChange)="openFilePicker()"
    [currentFolder]="currentFolder"
    [currentFolderPermissions]="currentFolderPermissions"
    [company]="company"
    *ngIf="company">
  </app-exchange-details>
</div>
<input data-test="exchange-file-input" multiple #fileInput type="file" class="hidden" (change)="getFileDetails($event)"/>
