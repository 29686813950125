<div class="recurring-settings" style="height: 100%">
  <div class="row" style="height: 100%;">
    <form [formGroup]="recurringForm">
      <div class="col-md-6">
        <h4 class="emma-subtitle">{{'recurring.title' | translate}}</h4>
        <div class="row">
          <div class="col-md-12">
            <div class="emma-input-container">
              <label label for="start" class="emma-input-label">{{'recurring.start' | translate }}</label>
              <div class="emma-input" [ngClass]="{'is-invalid': start.matches('.is-invalid')}">
                <input #start id="start" formControlName="startDate" appEmmaInput [matDatepicker]="picker" [min]="today" (dateChange)="createRRule()">
                <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="picker.open()"></ng-icon>
              </div>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="emma-input-container">
              <label label for="name" class="emma-input-label">{{ 'recurring.repeat' | translate }}</label>
              <input appEmmaInput id="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="interval" (change)="createRRule()">
            </div>
          </div>

          <div class="col-md-4">
            <div class="emma-input-container">
              <label label for="name" class="emma-input-label">{{ 'recurring.interval' | translate }}</label>
              <app-emma-select
              [(ngModel)]="frequency"
              [fullWidth]="true"
              layout="input"
              [ngModelOptions]="{standalone: true}"
              (change)="createRRule()"
              [options]="[
                {value: 3, displayName: interval > 1 ? ('recurring.days' | translate) : ('recurring.day' | translate)},
                {value: 2, displayName: interval > 1 ? ('recurring.weeks' | translate) : ('recurring.week' | translate)},
                {value: 1, displayName: interval > 1 ? ('recurring.months' | translate) : ('recurring.month' | translate)},
                {value: 0, displayName: interval > 1 ? ('recurring.years' | translate) : ('recurring.year' | translate)},
              ]">
              </app-emma-select>
            </div>
          </div>

          <div class="col-md-4" *ngIf="frequency === 0">
            <div class="emma-input-container">
              <label label for="start" class="emma-input-label">{{'recurring.on_months' | translate }}</label>
              <app-emma-select
                [(ngModel)]="setMonth"
                [ngModelOptions]="{standalone: true}"
                [fullWidth]="true"
                layout="input"
                (change)="createRRule()"
                [options]="monthOptions">
              </app-emma-select>
            </div>
          </div>

          <div class="col-md-4" *ngIf="frequency === 0 || frequency === 1">
            <div class="emma-input-container">
              <label label for="start" class="emma-input-label">{{'recurring.on_days' | translate }}</label>
              <app-emma-select
                [(ngModel)]="setDayOfMonth"
                [ngModelOptions]="{standalone: true}"
                [fullWidth]="true"
                layout="input"
                (change)="createRRule()"
                [options]="dayOptions">
              </app-emma-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" style="margin: 10px" *ngIf='frequency === 2'>
            <label label for="start" class="emma-input-label">{{'recurring.on_days' | translate }}</label>
            <div class="inline-flex padding-bottom">
              <span *ngFor="let day of weekdays" (click)="day.selected = !day.selected; createRRule()" class="weekday-selection" [ngClass]="{'weekday-selected': day.selected}">{{('weekdays.short.' + day.day) | translate}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="emma-input-container">
              <label label class="emma-input-label">{{ 'recurring.ends' | translate }}</label>
              <app-emma-select
                [(ngModel)]="repeat"
                [ngModelOptions]="{standalone: true}"
                [fullWidth]="true"
                layout="input"
                (change)="createRRule()"
                [options]="[
                {value: 1, displayName: 'recurring.never' | translate},
                {value: 2, displayName: 'recurring.on' | translate},
                {value: 3, displayName: 'recurring.after' | translate},
              ]">
              </app-emma-select>
            </div>
          </div>

          <div class="col-md-8" *ngIf="repeat === 2">
            <div class="emma-input-container">
              <label label class="emma-input-label">{{ 'recurring.end' | translate }}</label>
              <div class="emma-input" [ngClass]="{'is-invalid': end.matches('.is-invalid')}">
                <input #end id="end" formControlName="endDate" appEmmaInput [matDatepicker]="endPicker" [min]="recurringForm.get('startDate')?.value" (dateChange)="createRRule()">
                <ng-icon class="ng-icon-default hover-hero" name="heroCalendar" (click)="endPicker.open()"></ng-icon>
              </div>
              <mat-datepicker #endPicker></mat-datepicker>
            </div>
          </div>

          <div class="col-md-8" *ngIf="repeat === 3">
            <div class="emma-input-container">
              <label label class="emma-input-label">{{ 'recurring.times' | translate }}</label>
              <div class="emma-input">
                <input appEmmaInput [(ngModel)]="count" [ngModelOptions]="{standalone: true}" (change)="createRRule()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="col-md-6" style="height: 100%;">
      <h4 class="emma-subtitle">{{'recurring.occurences' | translate}}</h4>
      <div class="rrule-card-container">
        <div *ngFor="let rule of getRRuleOccurences()" class="rrule-card">
          <div class="rrule-card-day" [ngClass]="{'rrule-excluded': rule.excluded}">
            {{rule.date.getUTCDate()}}
          </div>
          <div class="rrule-card-content">
            <div class="rrule-card-content-month" [ngClass]="{'rrule-excluded': rule.excluded}">{{('months.long.' + (rule.date.getUTCMonth() + 1)) | translate}} - {{rule.date.getUTCFullYear()}}</div>
            <div class="rrule-card-content-day" [ngClass]="{'rrule-excluded': rule.excluded}">{{'weekdays.long.' + rule.date.getUTCDay() | translate}}</div>
          </div>
          <div class="rrule-card-trash" (click)="toggleException(rule)">
            <ng-icon class="ng-icon-default" [name]="rule.excluded ? 'heroClock' : 'heroTrash'"></ng-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
