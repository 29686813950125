<div class="wrapper wrapper-full-page center">
  <div class="container">
    <div class="row login-centered">
      <form class="login-form" [formGroup]="formGroup" [ngClass]="isMobile ? 'no-margin-full-width' : ''">
        <div class="card card-login card-hidden inline-card" [ngClass]="isMobile ? 'no-margin-full-width' : 'centered-desktop'">
          <img src="assets/img/forgot-header.svg" [ngClass]="isMobile ? 'login-image-mobile' : 'login-image'">
          <div class="card-body body-centered" [ngClass]="isMobile ? '' : 'max-size'">
            <p class="emma-subtitle">{{type ? ('auth.new.title' | translate) : ('auth.reset.title' | translate)}}</p>
            <p class="emma-font-default-small">{{type ? ('auth.new.desc' | translate) : ('auth.reset.desc' | translate)}}</p>
            <div class="input-group" style="margin-bottom: 20px">
              <div class="input-group-prepend">
                <span class="input-group-text" style="padding-left: 0;">
                  <ng-icon name="heroLockClosed" class="ng-icon-default"></ng-icon>
                </span>
              </div>
                <input data-test="password-input" appEmmaInput type="password" placeholder="{{'auth.reset.pass' | translate}}" required formControlName="password" name="password" data-private>
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"  style="padding-left: 0;">
                  <ng-icon name="heroLockClosed" class="ng-icon-default"></ng-icon>
                </span>
              </div>
              <div style="display: flex; flex-flow: column; width: 100%">
                <input data-test="password-repeat-input" appEmmaInput type="password" placeholder="{{'auth.reset.pass_repeat' | translate}}" required formControlName="confirm" name="confirm" data-private>
                <div style="margin-top: 5px">
                  <span class="emma-input-error" *ngIf="formGroup?.touched && formGroup?.invalid && formGroup?.get('confirm')?.hasError('invalidMatch')">
                    {{'profile.passwords_not_matching' | translate}}
                  </span>
                </div>
              </div>
            </div>
            <div class="input-group small-padding">
              <mat-password-strength
                [min]="company?.passwordLength || 8" #passwordComponentWithValidation
                [password]="formGroup.get('confirm')?.value"
                (onStrengthChanged)="onStrengthChanged($event)"
              ></mat-password-strength>
              <mat-password-strength-info
                [passwordComponent]="passwordComponentWithValidation"
                [lowerCaseCriteriaMsg]="'profile.lowercase_required' | translate"
                [upperCaseCriteriaMsg]="'profile.uppercase_required'| translate"
                [digitsCriteriaMsg]="'profile.digit_required'| translate"
                [specialCharsCriteriaMsg]="'profile.special_required' | translate"
                [minCharsCriteriaMsg]="'profile.minimal_required' | translate:{'characters': company?.passwordLength.toString() || '8'}">
              </mat-password-strength-info>
            </div>
            <div class="card-footer" style="margin-left: 0;">
              <button data-test="submit-button"  class="btn btn-blue btn-custom" (click)="reset()">
                <b>{{type ? ('auth.new.submit' | translate) : ('auth.reset.submit' | translate) }}</b>

              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
