<div class="main-content">
  <form [formGroup]="form">
    <div class="emma-input-container">
      <label label for="name" class="emma-input-label">Naam</label>
      <input appEmmaInput id="name" formControlName="name">
    </div>
    <br>
    <div class="emma-input-container">
      <label label for="userId" class="emma-input-label">User ID</label>
      <input appEmmaInput id="userId" formControlName="userId">
    </div>
    <button type="button"  (click)="create(form)">{{ 'api_keys.create' | translate }}</button>
  </form>

  <div *ngFor="let key of keys">
    <p>{{ key.name }} <span *ngIf="key.secret">({{ key.secret }})</span></p>
    <button  (click)="delete(key)">{{ 'api_keys.remove' | translate }}</button>
    <hr>
    <br><br><br>
  </div>
</div>
