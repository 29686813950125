<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="add-group-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'dialog.create.add_group' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <app-search-groups [company]="company" (valueChange)="selectedGroup = $event?.value"></app-search-groups>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom extra-margin-top">
      <button data-test="delete-submit-button"  class="btn btn-blue" (click)="addGroup()">
        <b> {{ 'dialog.create.add' | translate }} </b>

      </button>
    </div>
  </div>
</div>
