import {Component, Input, OnInit} from '@angular/core';
import {Message} from '../../../functional/models/message';

@Component({
  selector: 'app-reply-item',
  templateUrl: './reply-item.component.html',
  styleUrls: ['./reply-item.component.scss']
})
export class ReplyItemComponent implements OnInit {
  @Input() message!: Message;

  constructor() { }

  ngOnInit(): void {
  }

}
