/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Ftp, UpdateFtp } from 'app/functional/models/ftp.model';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FtpService {
  private url = environment.rootUrl + 'ftp_settings/';

  constructor(private http: HttpClient) { }

  readByCompany(companyId: number): Promise<Ftp> {
    return this.http.get<Ftp>(this.url, {
      params: {
        company_id: companyId.toString(),
      }
    }).toPromise();
  }

  update(model: UpdateFtp): Promise<Ftp> {
    return this.http.put<Ftp>(this.url, model).toPromise();
  }
}
