export class FileRequest {
  id!: number;
  dossierId?: number;
  folderId!: number;
  fileId?: number;
  message?: string;
  creationDate!: string;
  filename!: string;
  isActive!: boolean;
  exampleFileId?: number;
  description?: string;
  pending?: boolean;
  userIds!: number[];
  remindedAt!: string;

  static default(): FileRequest {
    return {
      id: 1,
      dossierId: 1,
      folderId: 1,
      fileId: 1,
      creationDate: new Date().toISOString(),
      isActive: true,
      filename: 'Voorbeeld bestand',
      userIds: [1],
      remindedAt: new Date().toISOString(),
    };
  }
}

export class NewFileRequest {
  dossierId!: number;
  folderId!: number;
  filename!: string;
  deadline?: Date;
  exampleFileId?: number;
  isActive!: boolean;
  message?: string;
  notifyUserIds!: number[];
  userIds!: number[];
}

export class EditFileRequest {
  exampleFileId?: number;
  filename!: string;
  folderId!: number;
  message?: string;
}

export class FulFillFileRequest {
  fileRequestId!: number;
  name?: string;
  description!: string;
  documentSize!: number;
}
