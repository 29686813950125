import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../../../../functional/models/company';
import {Subscription} from 'rxjs';
import {CompanyService} from '../../../../services/company/company.service';
import {Role} from '../../../../functional/models/role.model';
import {OrganizationService} from '../../../../services/organization/organization.service';
import {UserService} from '../../../../services/user/user.service';
import {Organization} from '../../../../functional/models/organization.model';
import {NavVariableService} from '../../../../services/nav-variable/nav-variable.service';
import {NavigationButton, NavigationTab} from '../../../../components/sub-nav/sub-nav.component';
import {TranslateService} from '@ngx-translate/core';
import {Claims, RedactedUser, User} from '../../../../functional/models/user';
import {DossierOverviewComponent} from '../../../../components/dossiers/dossier-overview/dossier-overview.component';
import {ExchangeOverviewComponent} from '../../../../components/exchange/exchange-overview/exchange-overview.component';
import {ConnectedUsersComponent} from '../../../../components/connected-users/connected-users.component';
import {CreateFolderDialogComponent} from '../../../../dialogs/exchange/create-folder-dialog/create-folder-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Folder} from '../../../../functional/models/folder';
import {SearchType, SortType} from '../../../../functional/models/exchange.model';

@Component({
  selector: 'app-organization-details-page',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationDetailsComponent implements OnInit, OnDestroy {

  @ViewChild(OrganizationDetailsComponent) details!: OrganizationDetailsComponent;
  @ViewChild('organizationContacts') organizationContacts!: ConnectedUsersComponent;
  @ViewChild('organizationEmployees') organizationEmployees!: ConnectedUsersComponent;
  @ViewChild(DossierOverviewComponent) dossiers!: DossierOverviewComponent;
  @ViewChild(ExchangeOverviewComponent) exchange!: ExchangeOverviewComponent;

  organizationId: number;
  company!: Company;
  companySubscription!: Subscription;
  organizations!: Organization[];
  currentOrganization!: Organization;
  buttons: NavigationButton[] = [];
  activeTab?: string;
  claims!: Claims;
  role = Role;
  employees: RedactedUser[] = [];
  contacts: RedactedUser[] = [];
  searchType = SearchType.currentFolder;
  sortType = SortType.nameAscending;
  private crumbs: Folder[] = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private organizationService: OrganizationService,
    private userService: UserService,
    private navVariableService: NavVariableService,
    private translateService: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.organizationId = Number(this.activeRoute.snapshot.params.id);
    window.addEventListener('window-navigate', (e: any) => this.navigate(e.detail.folder), false);
  }

  ngOnDestroy(): void {
    this.companySubscription.unsubscribe();
  }

  async ngOnInit() {
    this.claims = this.userService.tryClaims();
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (company) {
        this.company = company;
      } else {
        this.company = await this.companyService.findCurrentCompany();
      }

      if (this.claims.role === Role.superuser || this.claims.role === Role.admin) {
        this.currentOrganization = await this.organizationService.getOrganization(this.organizationId);
      } else if (this.claims.role === Role.employee) {
        this.currentOrganization = await this.organizationService.getOrganization(this.organizationId);
      } else {
        throw new Error('Unreachable statement reached');
      }
      this.navVariableService.navVariables.next({organization: this.currentOrganization});
    });

    this.employees = await this.organizationService.getUsers(this.organizationId, Role.employee);
    this.contacts = await this.organizationService.getUsers(this.organizationId, Role.customer);
  }

  async setActive(activatedTab: NavigationTab) {
    this.activeTab = activatedTab.event;
    if (activatedTab.event === 'details') {
      this.buttons = [{name: 'Opties', event: 'menu', menu: true}];
    }
    else if (activatedTab.event === 'files') {
      this.buttons = [{name: 'Opties', event: 'menu', menu: true}];
    }
    else {
      this.buttons = [{name: this.translateService.instant('new'), event: 'new', icon: 'add'}];
    }

    if (this.activeTab === 'files'){
      this.navVariableService.navVariables.next({organization: this.currentOrganization, folderCrumbs: this.crumbs});
    } else {
      this.navVariableService.navVariables.next({organization: this.currentOrganization});
    }
  }

  deleteOrganization() {
    this.details.deleteOrganization();
  }

  async handleClick(event: any) {
    if (event === 'new' && this.activeTab === 'dossiers') {
      await this.createNewDossier();
    }
    if (event === 'new' && this.activeTab === 'persons') {
      const users = await this.organizationContacts.addUsers(false);
      await this.addUsersToOrganization(users);
      this.contacts = await this.organizationService.getUsers(this.organizationId, Role.customer);
    }
    if(event === 'new' && this.activeTab === 'employees') {
      const users = await this.organizationEmployees.addUsers(true, true);
      await this.addUsersToOrganization(users);
      this.employees = await this.organizationService.getUsers(this.organizationId, Role.employee);
    }
    if (event === 'new' && this.activeTab === 'files'){
      await this.openCreateFolder();
    }

    if (event === 'newFromTemplate' && this.activeTab === 'files') {
      await this.newFolderFromTemplate();
    }
  }

  newFolderFromTemplate() {
    const dialog = this.dialog.open(
      CreateFolderDialogComponent,
      {
        data: {
          company: this.company,
          currentUser: this.userService.tryClaims(),
          users: [...this.employees, ...this.contacts],
          organizations: [this.currentOrganization],
          fromDuplicate: true
        },
        autoFocus: false
      }
    );
    dialog.afterClosed().subscribe(async () => {
      if (this.exchange.currentFolder.id === -1){
        await this.exchange.init();
      } else {
        await this.exchange.refreshDisplay();
      }
    });
  }

  openCreateFolder() {
    const dialog = this.dialog.open(
      CreateFolderDialogComponent,
      {
        data: {
          company: this.company,
          currentUser: this.userService.tryClaims(),
          users: [...this.employees, ...this.contacts],
          organizations: [this.currentOrganization]
        },
        autoFocus: false
      }
    );
    dialog.afterClosed().subscribe(async () => {
      if (this.exchange.currentFolder.id === -1){
        await this.exchange.init();
      } else {
        await this.exchange.refreshDisplay();
      }
    });
  }

  async addUsersToOrganization(users: User[]) {
    const promises = [];
    for (const user of users) {
      if (!this.contacts.some(contact => contact.id === user.id)) {
        promises.push(this.organizationService.addUser(this.organizationId, user.id));
      }
    }
    await Promise.all(promises);
  }

  async createNewDossier() {
    let employees: number[] = [];
    let customers: number[] = [];
    try {
      employees = (await this.organizationService.getUsers(this.currentOrganization.id, Role.employee)).map(item => item.id);
      customers = (await this.organizationService.getUsers(this.currentOrganization.id, Role.customer)).map(item => item.id);
    } finally {
      await this.router.navigate(['dossier', 'create'],{
        queryParams: {customer: customers, employee: employees, organization: this.currentOrganization.id}
      });
    }
  }

  async deleteUsers(event: (RedactedUser | User)[]) {
    const calls = [];
    for (const user of event){
      calls.push(this.organizationService.removeUser(this.organizationId, user.id));
    }
    await Promise.all(calls);
    this.employees = await this.organizationService.getUsers(this.organizationId, Role.employee);
    this.contacts = await this.organizationService.getUsers(this.organizationId, Role.customer);
  }

  navigate(item: Folder) {
    if (this.exchange) {
      const folder = this.exchange.breadCrumb.find(e => e.id === Number(item.id));
      if (folder) {
        this.exchange.navigate(folder);
      }
    }
  }

  setCrumbs(event: Folder[]) {
    this.crumbs = event;
    this.navVariableService.navVariables.next({organization: this.currentOrganization, folderCrumbs: event});
  }
}
