import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DossierTemplateService} from '../../../services/dossier-template/dossier-template.service';
import {DossierTemplate, DossierTemplateOverview} from '../../../functional/models/dossier-template.model';
import {Company} from '../../../functional/models/company';
import {UserService} from '../../../services/user/user.service';
import {Role} from '../../../functional/models/role.model';

@Component({
  selector: 'app-dossier-template-selector',
  templateUrl: './dossier-template-selector.component.html',
  styleUrls: ['./dossier-template-selector.component.scss']
})
export class DossierTemplateSelectorComponent implements OnInit {
  @Input() company!: Company;

  @Output() selectedTemplateChange = new EventEmitter();
  set selectedTemplate(template: DossierTemplate) {
    this.selected = template;
    this.selectedTemplateChange.emit(this.selected);
  }

  @Input()
  get selectedTemplate(): DossierTemplate {
    return this.selected;
  }

  templates: DossierTemplateOverview[] = [];
  selected!: DossierTemplate;

  constructor(
    private dossierTemplateService: DossierTemplateService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    if (this.userService.tryClaims().role === Role.superuser){
      this.templates = await this.dossierTemplateService.readAll();
    } else {
      this.templates = await this.dossierTemplateService.readAll(this.company.id);
    }
  }

  async selectDossier(template: DossierTemplateOverview) {
    this.selectedTemplate = await this.dossierTemplateService.get(template.id);
  }
}
