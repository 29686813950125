import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Folder} from 'app/functional/models/folder';
import {Document} from 'app/functional/models/document';
import {SelectionModel} from '@angular/cdk/collections';
import {ExplorerItem} from 'app/functional/models/explorer.model';
import {FileRequest} from '../../../functional/models/file-request.model';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FolderComponent implements OnInit {
  @Input() folder!: Folder;
  @Input() selected?: SelectionModel<ExplorerItem>;
  @Input() highlighted?: Folder | Document | FileRequest;
  @Output() selectChange = new EventEmitter();
  @Output() clickChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (!this.folder) {
      throw new Error('`folder` is required for `FolderComponent`');
    }
  }

  log(event: DragEvent) {

  }

  allowDrop($event: DragEvent) {
    $event.preventDefault();
  }

  isSelected() {
    return !!this.selected?.selected.find(item => item.folder && item.folder!.id === this.folder!.id);
  }

  changeSelection(event: Event) {
    this.selectChange.emit(this.folder);
    event.stopPropagation();
    event.preventDefault();
  }

  triggerClick() {
    this.clickChange.emit(this.folder);
  }
}
