import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Dossier} from '../../../functional/models/dossier.model';
import {RedactedUser, User} from '../../../functional/models/user';
import {DossierService} from '../../../services/dossier/dossier.service';
import {SearchEmployeesComponent} from '../../../components/search/search-employees/search-employees.component';
import {SearchCustomersComponent} from '../../../components/search/search-customers/search-customers.component';
import {FolderService} from '../../../services/folder/folder.service';
import {Folder} from '../../../functional/models/folder';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-folder-dialog',
  templateUrl: './edit-folder-dialog.component.html',
  styleUrls: ['./edit-folder-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditFolderDialogComponent implements OnInit {
  @ViewChild('employeeSearch') employeeSearch!: SearchEmployeesComponent;
  @ViewChild('customerSearch') customerSearch!: SearchCustomersComponent;

  dossier!: Dossier;
  customerPermissions: [RedactedUser, number][] = [];
  employeePermissions: [RedactedUser, number][] = [];
  isRequestable = false;
  dossierName!: string;
  folder!: Folder;

  nameForm!: UntypedFormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<EditFolderDialogComponent>,
    private dossierService: DossierService,
    private folderService: FolderService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.dossier = this.dialogData.dossier;
    this.dossierName = this.dialogData.folder.name;
    this.folder = this.dialogData.folder;

    this.nameForm = new UntypedFormControl(this.dossier.name || '', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern('([A-Za-z0-9 _\\-+.\',()&€£¢$À-ÖØ-ßà-öø-ÿŠŒŽšœžŸµ]+)'),
      this.nameExists(),
    ]);

    const permissions = await this.folderService.getUserPermissions(this.dialogData.folder.id);

    for (const permission of permissions) {
      const found = this.dossier.employees.find(employee => employee.id === permission.user.id);
      const permissionId = this.getPermissionsId(
        permission.userFolder.permissions.includes('r'),
        permission.userFolder.permissions.includes('w'),
        permission.userFolder.permissions.includes('d'),
        permission.userFolder.permissions.includes('m'),
      );

      if (found) {
        this.employeePermissions.push([permission.user, permissionId]);
      } else {
        this.customerPermissions.push([permission.user, permissionId]);
      }
    }
  }

  nameExists(): ValidatorFn  {
    return (control: AbstractControl): ({ [key: string]: boolean } | null) => {
      if (control.value === this.folder.name){
        return null;
      }
      const exists = this.hasExistingFolder(control.value);
      if (!exists) {
        return null;
      }
      return {nameInvalid: true} ;
    };
  }

  hasExistingFolder(name: string) {
    return this.dossier.folders.find((folder) => folder.name === name);
  }

  async edit() {
    this.nameForm.markAllAsTouched();
    if (this.nameForm.invalid) {
      return;
    }

    const users: Record<number, string> = {};
    const combinedUsers = [...this.customerPermissions, ...this.employeePermissions];
    for (const user of combinedUsers) {
      users[user[0].id] = this.getPermissions(user[1]);
    }
    await this.dossierService.editFolder(this.dossier.id, this.folder.id, {
      name: this.nameForm.value,
      description: this.translate.instant('dossier.upload_here'),
      users,
    });
    this.dialogRef.close();
  }

  getPermissions(type: number) {
    if (type === 3) {
      return 'rwcdm';
    }
    if (type === 2) {
      return 'r';
    }
    if (type === 1) {
      return 'rwcd';
    }
    return '';
  }

  getPermissionsId(r: boolean, w: boolean, d: boolean, m: boolean): number {
    if (r && w && d && m) {
      return 3;
    }

    if (r && !w && !d && !m) {
      return 2;
    }

    if (r && w && d && !m) {
      return 1;
    }
    return 0;
  }

  onCustomerSelected(user: User | RedactedUser | undefined) {
    if (user) {
      const found = !!this.customerPermissions.find(userQueried => userQueried[0].id === user.id);
      if (!found) {
        this.customerPermissions.push([user, 2]);
      }
      this.customerSearch.clearSelected();
    }
  }

  onEmployeeSelected(user: User | RedactedUser | undefined) {
    if (user) {
      const found = !!this.employeePermissions.find(userQueried => userQueried[0].id === user.id);
      if (!found) {
        this.employeePermissions.push([user, 2]);
      }
      this.employeeSearch.clearSelected();
    }
  }

  deleteEmployee(employee: RedactedUser) {
    this.employeePermissions = this.employeePermissions.filter(employe => employe[0].id !== employee.id);
  }

  deleteCustomer(customer: RedactedUser) {
    this.customerPermissions = this.customerPermissions.filter(custome => custome[0].id !== customer.id);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
