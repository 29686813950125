import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'app/services/company/company.service';
import { Claims } from 'app/functional/models/user';
import { UserService } from 'app/services/user/user.service';
import { Role } from 'app/functional/models/role.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  companyId!: number;
  claims!: Claims;
  roles = Role;

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    this.claims = this.userService.tryClaims(); // assume logged in
    this.companyId = (await this.companyService.findCurrentCompany()).id;
  }
}
