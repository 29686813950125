<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    [search]="true"
    (searchChange)="applyFilter($event)"
    [buttons]="selectedMail ? [{icon:'add', name: 'new' | translate, event: 'new'}, {icon:'reply', name: 'messaging.reply' | translate, event: 'reply'}] : [{icon:'add', name: 'new' | translate, event: 'new'}]"
    (buttonClicked)="handleClick($event)">
  </app-sub-nav>
  <div class="inbox-container row">
    <div class="col-md-4" style="border-right: 1px solid rgba(180, 180, 180, 0.3); height: 100%;">
      <mat-selection-list (selectionChange)="setMailSelected($event)" [multiple]="false" class="list-container">
        <ng-container *ngFor="let message of displayedMessages | slice: pageEvent.pageIndex * pageEvent.pageSize : (pageEvent.pageIndex + 1) * pageEvent.pageSize;">
          <mat-list-option  [disableRipple]="true" [value]="message.id" class="list-option msg">
            <span class="unread-item" *ngIf="!message.read && message.sender.id !== claims.userId">&#x25cf;</span>
            <div [style]="message.content.length < 39 ? 'padding-bottom: 16px' : '' ">
              <p class="msg-time">{{getDateFromString(message.creationDate.toString())}}</p>
              <p class="msg-title">{{isOutbox() ? message.recipient.firstName + ' ' + message.recipient.lastName : message.sender.firstName + ' ' + message.sender.lastName}}</p>
              <p class="msg-subject">{{message.subject ? message.subject : 'messaging.no_subject' | translate}}</p>
              <p class="msg-message">{{message.content ? message.content : 'messaging.no_message' | translate}}</p>
            </div>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
      <mat-paginator
        [length]="displayedMessages.length"
        [pageSizeOptions]="[25, 50, 100]"
        (page)="pageEvent = $event"
        style="position: absolute;bottom: -20px;width: calc(100% - 30px);">
      </mat-paginator>
    </div>
    <div class="col-md-8" style="height: 100%;">
      <div class="illustration" *ngIf="!selectedMail">
        <img src="assets/img/illustrations/message.svg">
        <h1 class="not-found-title">Geen bericht geselecteerd!</h1>
        <span>
          Selecteer een bericht om deze in te zien of klik op nieuw om een bericht op te stellen.
        </span>
      </div>

      <div *ngIf="selectedMail" class="message-container">
        <div>
          <div class="time-user-container">
            <p class="nospacing message-title">{{selectedMail.sender.firstName + ' ' + selectedMail.sender.lastName}}</p>
            <p class="message-time">{{selectedMail.creationDate | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
          </div>
          <p class="nospacing">{{selectedMail.subject}}</p>
          <div class="flex"><p class="nospacing" >{{'messaging.to' | translate}}:&nbsp;</p><p class="nospacing message-recipient">{{selectedMail.recipient.firstName + ' ' + selectedMail.recipient.lastName}}</p></div>
        </div>
        <mat-divider class="message-devider"></mat-divider>
        <p class="main-message-content" [innerHTML]="selectedMail.content | linky"></p>
        <app-reply-item *ngIf="selectedMail.replyTo" [message]="selectedMail.replyTo"></app-reply-item>
      </div>
    </div>
  </div>
</div>

