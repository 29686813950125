<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card" id="workflow-editor">
          <div class="card-header card-header-grey card-header-icon">
            <div class="card-icon card-header-custom"><mat-icon>assignment</mat-icon></div>
            <div class="exchange-card-title-between">
              <h4 class="card-title">
                {{'menu.survey_editor' | translate}}
              </h4>
            </div>
          </div>
          <div class="card-body">
            <app-survey-page></app-survey-page>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
