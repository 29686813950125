import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { dossierEventNames } from '../../../functional/models/dossier.model';
import { DossierTemplateService } from '../../../services/dossier-template/dossier-template.service';

@Component({
  selector: 'app-add-dossier-template-action-dialog',
  templateUrl: './add-dossier-template-action-dialog.component.html',
  styleUrls: ['./add-dossier-template-action-dialog.component.scss']
})
export class AddDossierTemplateActionDialogComponent implements OnInit {
  addActionForm!: UntypedFormGroup;
  dossierEvents = dossierEventNames;
  sourceState!: number;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public templateService: DossierTemplateService,
    public dialogRef: MatDialogRef<AddDossierTemplateActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.addActionForm = this.formBuilder.group({
      name: [this.data.action?.name || null, [Validators.required]],
      type: [this.data.action?.event || null, [Validators.required]],
      happy: [this.data.action?.isHappy || false, [Validators.required]],
      target: [this.data.target || null, [Validators.required]]
    });
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  async addAction() {
    this.addActionForm.markAllAsTouched();

    if (this.addActionForm.invalid){
      return;
    }

    if (!this.data.source) {
      const result = await this.templateService.createStateAction(this.data.template.id, this.data.currentState.id, {
        destinationStateTemplateId: this.addActionForm.get('target')?.value,
        event: this.addActionForm.get('type')?.value,
        isHappy: this.addActionForm.get('happy')?.value,
        name: this.addActionForm.get('name')?.value
      });
      this.dialogRef.close(result);
    } else {
      const result = await this.templateService.updateStateAction(
        this.data.template.id,
        this.data.action.dossierStateTemplateId,
        this.data.action.id,
        {
          destinationStateTemplateId: this.addActionForm.get('target')?.value,
          event: this.addActionForm.get('type')?.value,
          isHappy: this.addActionForm.get('happy')?.value,
          name: this.addActionForm.get('name')?.value
        });
        this.dialogRef.close(result);
    }
  }
}
