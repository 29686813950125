import {AddonModel} from '../../../functional/models/addon';

const mfaAddon: AddonModel = {
  actions: [
    {
      name: 'Aanschaffen (gratis)',
      type: 'enable',
      controller: 'mfa',
      isInitial: true,
    },
    {
      name: 'Uitschakelen',
      type: 'delete',
      controller: 'mfa',
      isInitial: false,
    }
  ],
  longDescription: '' +
    '        <br>\n' +
    '        <p>Multifactor authenticatie (MFA) is tegenwoordig de industriestandaard om uw data te beveiligen. ' +
    'Met MyEmma MFA kunt u met elke authenticator app (Google authenticator, Microsoft authenticator, etc.) ' +
    'synchroniseren om tokens te genereren en in te loggen bij MyEmma. ' +
    'Mocht u ook gebruik maken van de MyEmma app dan wordt deze ook beveiligd met een eenmalige SMS code bij het inloggen. ' +
    'Hiermee kunt u uw klanten verzekeren dat hun gegevens in goede handen zijn.\n' +
    '          <br><br>' +
    '          Mochten er nog problemen voordoen bij het gebruik van de MFA addon, ' +
    'schroom dan niet om een reactie achter te laten bij support@myemma.io.<br><br>\n' +
    '\n' +
    '          Veel plezier met het gebruik!</p>',
  image: './assets/img/logos/mfa.svg',
  name: 'Multifactor authenticatie',
  shortDescription: 'Met multifactor authenticatie laat u uw klanten inloggen met hun mobiel via Google Authenticator, ' +
    'hierdoor kunt u de veiligheid van uw documenten goed waarborgen!',
  comingSoon: false,
};

export default mfaAddon;
