<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-icon">
            <div class="card-icon card-header-custom"><mat-icon>vpn_key</mat-icon></div>
            <div class="flex space-between">
              <h4 class="card-title">{{currentAddon.name}}</h4>
              <div class="button-container">
                <ng-container *ngFor="let action of currentAddon.actions">
                  <button
                    *ngIf="!connectionResults && action.isInitial"
                    (click)="performAction(action.controller, action.type)"
                    class="btn btn-blue btn-custom btn-small">
                    {{action.name}}
                  </button>
                  <button
                    *ngIf="connectionResults && !action.isInitial"
                    (click)="performAction(action.controller, action.type)"
                    class="btn btn-blue btn-custom btn-small">
                    {{action.name}}
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="card-body" [innerHTML]="currentAddon.longDescription">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
