import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  resetLocalStorage() {
    const vPreference = localStorage.getItem('view-preference');
    const sPreference = localStorage.getItem('sort-preference');
    const tableSort = localStorage.getItem('file-table-sort');
    const rFilter = localStorage.getItem('recents-filter');

    localStorage.clear();

    if (vPreference) {
      localStorage.setItem('view-preference', vPreference);
    }

    if(rFilter) {
      localStorage.setItem('recents-filter', rFilter);
    }

    if (sPreference) {
      localStorage.setItem('sort-preference', sPreference);
    }

    if (tableSort) {
      localStorage.setItem('file-table-sort', tableSort);
    }
  }
}
