import { AccessorsPermissions, GroupPermission } from '../../services/folder/folder.service';
import { Document } from './document';
import { Folder } from './folder';
import { Organization } from './organization.model';
import { Signing } from './signing';
import { RedactedUserWithPermissions } from './user';
import { FileRequest } from './file-request.model';

export class ExplorerItem {
  file?: Document;
  folder?: Folder;
  request?: FileRequest;
  users?: RedactedUserWithPermissions[];
  singleUsers?: RedactedUserWithPermissions[];
  organizations?: [Organization, RedactedUserWithPermissions[]][];
  groupPermissions?: GroupPermission[];
  accessors?: AccessorsPermissions[];
  signings?: Signing[];
}
