<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div class="logout-reason-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{ 'logout_dialog.' + reason + '.title' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered extra-margin extra-margin-top">
      <p class="emma-font-light"> {{ 'logout_dialog.' + reason + '.description' | translate }} </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button  class="btn btn-blue" (click)="deleteFiles()">
        <b> {{ 'logout_dialog.' + reason + '.continue' | translate }} </b>

      </button>
    </div>
  </div>
</div>
