import {Component, Input, OnInit} from '@angular/core';
import {Claims, RedactedUser, User} from '../../functional/models/user';
import {UserService} from '../../services/user/user.service';
import {Role} from '../../functional/models/role.model';
import {Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {UserSelectorDialogComponent} from '../../dialogs/user-selector-dialog/user-selector-dialog.component';
import {Company} from '../../functional/models/company';
import {ConfirmDeleteDialogComponent, DeleteType} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

  @Input() user!: User;
  @Input() company!: Company;

  employees: RedactedUser[] = [];
  currentUser!: Claims;
  lRole = Role;

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  async ngOnInit() {
    this.currentUser = this.userService.tryClaims();
    if (this.user.role === Role.customer) {
      await this.updateUserDetails();
    }
  }

  async updateUserDetails() {
    this.employees = (await this.userService.getUserEmployees(this.user.id)) || [];
  }

  async navigateToUser(employee: RedactedUser) {
    await this.router.navigate(['management', 'employees', 'details', employee.id]);
  }

  async deleteUser(employee: RedactedUser, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: {
        deleteType: DeleteType.parentUser
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data){
        await this.userService.deleteParentUser(this.user.id, employee.id);
        await this.updateUserDetails();
      }
    });
  }

  addEmployee() {
    const dialogRef = this.dialog.open(UserSelectorDialogComponent, {
      panelClass: 'user-selector-dialog',
      data: {
        company: this.company,
        allowEmployees: true,
        onlyEmployees: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async (data: { data: {users: User[]}}) => {
      if (data){
        const promises = [];
        for (const user of data.data.users) {
          if (!this.employees.some(employee => employee.id !== user.id)) {
            promises.push(this.userService.setParent(this.user.id, {parentUserId: user.id}));
          }
        }
        await Promise.all(promises);
        await this.updateUserDetails();
      }
    });
  }
}
