import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Company } from 'app/functional/models/company';
import { GroupService } from 'app/services/group/group.service';
import { CompanyService } from 'app/services/company/company.service';
import {ConfirmDialogComponent} from '../../../../dialogs/dossiers/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogType} from '../../../../functional/models/dialog.model';
import {NavVariableService} from '../../../../services/nav-variable/nav-variable.service';
import {NavigationTab} from '../../../../components/sub-nav/sub-nav.component';
import {RedactedUser, User} from '../../../../functional/models/user';
import {CreateGroup, Group} from '../../../../functional/models/group';
import {ConnectedUsersComponent} from '../../../../components/connected-users/connected-users.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('employeeOverview') employeeOverview!: ConnectedUsersComponent;
  @ViewChild('customerOverview') customerOverview!: ConnectedUsersComponent;

  id: number;
  subscription = new Subscription();
  companySubscription = new Subscription();
  company!: Company;
  activeTab?: string;
  employees: User[] = [];
  group!: Group;
  customers: User[] = [];
  groupForm!: UntypedFormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private groupService: GroupService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private companyService: CompanyService,
    private navVariableService: NavVariableService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.id = this.activeRoute.snapshot.params.id;
  }

  async ngOnInit() {
    const company = this.companyService.company.getValue();
    if (company) {
      this.company = company;
    } else {
      this.company = await this.companyService.findCurrentCompany();
    }
    await this.fetchGroupDetails();

    this.groupForm = this.formBuilder.group({
      name: [this.group?.name || '', [Validators.required]],
      description: [this.group?.description || '', []]
    });

    this.employees = await this.groupService.readEmployees(this.group.id);
    this.customers = await this.groupService.readCustomers(this.group.id);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.companySubscription.unsubscribe();
  }

  async fetchGroupDetails() {
    this.group = await this.groupService.getGroup(this.id);
    this.navVariableService.navVariables.next({group: this.group});
  }

  async deleteGroup() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,
      {
        panelClass: 'confirm-dialog',
        data: {dialogType: ConfirmDialogType.deleteGroup},
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (res) {
        await this.groupService.deleteGroup(this.id);
        this.snackBar.open(this.translate.instant('group.details.success_delete'), '', {
          duration: 2000,
        });
        await this.router.navigate(['management', 'groups']);
      }
    });
  }

  async update() {
    this.groupForm.markAllAsTouched();
    if (this.groupForm.invalid){
      return;
    }

    const createGroup = this.groupForm.value as CreateGroup;
    createGroup.companyId = this.company.id;
    await this.groupService.editGroup(this.id, createGroup);
    this.snackBar.open(this.translate.instant('group.list.success_update'), '', {
      duration: 2000,
    });
  }

  setActive(activeTab: NavigationTab) {
    this.activeTab = activeTab.event;
  }

  async handleClick(event: string) {
    if (this.activeTab === 'employees'){
      const employees = await this.employeeOverview.addUsers(true, true);
      const promises = [];
      for (const employee of employees){
        if (!this.employees.some(emp => emp.id === employee.id)){
          promises.push(this.groupService.addEmployee(this.group.id, employee.id));
        }
      }
      this.employees = await this.groupService.readEmployees(this.group.id);
    }

    if (this.activeTab === 'persons') {
      const customers = await this.customerOverview.addUsers(false);
      const promises = [];
      for (const customer of customers){
        if (!this.customers.some(emp => emp.id === customer.id)){
          promises.push(this.groupService.addCustomer(this.group.id, customer.id));
        }
      }
      this.customers = await this.groupService.readCustomers(this.group.id);
    }
  }

  async deleteEmployees(event: (RedactedUser | User)[]) {
    const calls = [];
    for (const user of event){
      calls.push(this.groupService.deleteEmployee(user.id, this.group.id));
    }
    await Promise.all(calls);
    this.employees = await this.groupService.readEmployees(this.group.id);
  }

  async deleteCustomers(event: (RedactedUser | User)[]) {
    const calls = [];
    for (const user of event){
      calls.push(this.groupService.deleteCustomer(user.id, this.group.id));
    }
    await Promise.all(calls);
    this.customers = await this.groupService.readCustomers(this.group.id);
  }
}
