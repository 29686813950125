import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

export class NavigationTab {
  name!: string;
  event!: string;
  hidden?: boolean;
}

export class NavigationButton {
  icon?: string;
  name!: string;
  event!: string;
  menu?: boolean;
  hidden?: boolean;
  class?: string;
}

@Component({
  selector: 'app-sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubNavComponent implements OnInit {
  @Input() set navigation(navBar: NavigationTab[]){
    this.navBar = navBar;
    this.setFragment(this.activatedRoute.snapshot.fragment!);
  };

  @Input() search = false;
  @Output() searchChange = new EventEmitter<string>();

  @Input() buttons: NavigationButton[] = [];
  @Output() buttonClicked = new EventEmitter<string>();

  @Input() activeLink?: NavigationTab;
  @Output() navigationChange = new EventEmitter<NavigationTab>();

  @Input() exchangeSubject = false;

  @Input() back = false;

  navBar: NavigationTab[] = [];


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
      pairwise()
    ).subscribe(([previousEvent, currentEvent]) => {
      if (previousEvent instanceof NavigationStart && currentEvent instanceof NavigationEnd) {
        if (previousEvent.url === currentEvent.url && !this.activatedRoute.snapshot.fragment) {
          this.setFragment();
        }
      }
    });
  }

  setFragment(fragment?: string){
    if (!fragment) {
      this.activeLink = this.navBar[0];
      if (this.activeLink) {
        window.location.hash = this.activeLink.event;
      }
    } else {
      if (this.navBar.some(item => item.event === fragment)){
        this.activeLink = this.navBar.find(item => item.event === fragment);
      } else {
        this.activeLink = this.navBar[0];
      }
    }
    this.navigationChange.emit(this.activeLink);
  }

  changeTab(menuItem: NavigationTab) {
    this.activeLink = menuItem;
    this.navigationChange.emit(this.activeLink);
  }
}
