import { Routes } from '@angular/router';
import {CreateDossierComponent} from './create-dossier/create-dossier.component';
import {DossierDetailsComponent} from './dossier-details/dossier-details.component';
import {DossierDashboardComponent} from './dossier-dashboard/dossier-dashboard.component';

export const dossierRoutes: Routes = [
  {
    path: 'create',
    component: CreateDossierComponent,
    data: {
      breadcrumb: 'create'
    }
  },
  { path: 'edit/:id', component: CreateDossierComponent},
  { path: 'recurring/edit/:recurringId',  component: CreateDossierComponent},
  {
    path: 'details/:id',
    data: {
      breadcrumb: 'dossier.name',
      subMenu: ['persons', 'settings'],
      isParam: true,
    },
    component: DossierDetailsComponent
  },
  {
    path: '',
    component: DossierDashboardComponent,
  }
];
