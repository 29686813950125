<form ngForm [formGroup]="organizationForm">
  <div class="row">
    <div class="col-md-6">
      <div class="emma-input-container">
        <label label for="name" class="emma-input-label">{{ 'organization.create.details.naam'  | translate }}</label>
        <input appEmmaInput id="name" formControlName="name">
      </div>
    </div>
    <div class="col-md-6">
      <div class="emma-input-container">
        <label label for="kvk" class="emma-input-label">{{ 'organization.create.details.kvk_nummer'  | translate }}</label>
        <input appEmmaInput id="kvk" formControlName="kvk">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="emma-input-container">
        <label label for="street" class="emma-input-label">{{ 'organization.create.details.straatnaam'  | translate }}</label>
        <input appEmmaInput id="street" formControlName="street">
      </div>
    </div>
    <div class="col-md-4">
      <div class="emma-input-container">
        <label label for="houseNumber" class="emma-input-label">{{ 'organization.create.details.huisnummer'  | translate }}</label>
        <input appEmmaInput id="houseNumber" formControlName="houseNumber">
      </div>
    </div>
    <div class="col-md-2">
      <div class="emma-input-container">
        <label label for="premise" class="emma-input-label">{{ 'organization.create.details.toevoeging'  | translate }}</label>
        <input appEmmaInput id="premise" formControlName="premise">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="emma-input-container">
        <label label for="postalCode" class="emma-input-label">{{ 'organization.create.details.postcode'  | translate }}</label>
        <input appEmmaInput id="postalCode" formControlName="postalCode">
      </div>
    </div>
    <div class="col-md-6">
      <div class="emma-input-container">
        <label label for="city" class="emma-input-label">{{ 'organization.create.details.woonplaats'  | translate }}</label>
        <input appEmmaInput id="city" formControlName="city">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="emma-input-container">
        <label label for="relationId" class="emma-input-label">{{ 'organization.create.details.relationId'  | translate }}</label>
        <input appEmmaInput id="relationId" formControlName="relationId">
      </div>
    </div>
    <div class="col-md-6">
      <div class="emma-input-container">
        <label label for="fiscalNumber" class="emma-input-label">{{ 'organization.create.details.fiscaal_nummer'  | translate }}</label>
        <input appEmmaInput id="fiscalNumber" formControlName="fiscalNumber">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-md-offset-6">
      <a mat-button class="btn btn-green" (click)="updateOrganization()">
        {{ 'organization.create.details.bijwerken' | translate }}
        <div class="ripple-container"></div>
      </a>
      <a mat-button class="btn btn-red" (click)="deleteOrganization()">
        {{ 'organization.create.details.verwijderen' | translate }}
        <div class="ripple-container"></div>
      </a>
    </div>
  </div>
</form>
