<div class="dialog">
  <p class="emma-subtitle">
    <!-- FIXME: translation -->
    {{ 'simplicate.labels_select' | translate}}
  </p>
  <p class="emma-font-light">
    <!-- FIXME: translation -->
    {{ 'simplicate.labels_loaded' | translate }}
  </p>

  <form [formGroup]="form" class="form">
    <mat-checkbox *ngFor="let label of labels" [formControlName]="label.name">
      <span class="black">
        {{ label.displayName }}
      </span>
    </mat-checkbox>
  </form>

  <button  class="btn btn-blue btn-custom btn-small" (click)="close(true)">{{ 'simplicate.synchronise' | translate }}</button>
  &nbsp; <!-- haha html goes nbspppppp -->
  <button  class="btn btn-blue btn-custom btn-small" (click)="close(false)">{{ 'simplicate.close' | translate }}</button>
</div>
