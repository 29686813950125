import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Dossier } from '../../../functional/models/dossier.model';

@Component({
  selector: 'app-customer-information-dialog',
  templateUrl: './customer-information-dialog.component.html',
  styleUrls: ['./customer-information-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerInformationDialogComponent {

  dossier!: Dossier;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomerInformationDialogComponent>,
  ) {
    this.dossier = this.data.dossier;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
