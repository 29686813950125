import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LogoUploadComponent} from './logo-upload.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {NgIconsModule } from '@ng-icons/core';
import { heroArrowUpTray } from '@ng-icons/heroicons/outline';



@NgModule({
  declarations: [
      LogoUploadComponent
  ],
  exports: [
      LogoUploadComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    MatButtonModule,
    MatIconModule,
    NgIconsModule.withIcons({
      heroArrowUpTray
    })
  ]
})
export class LogoUploadModule { }
