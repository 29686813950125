<app-emma-select
  [renderButton]="false"
  [options]="filteredOptions"
  [fullWidth]="true"
  [(ngModel)]="selectValue"
  noResults="geen resultaten gevonden"
  #select>
  <input
    class="emma-input"
    type="text"
    button
    [disabled]="disabled"
    (focusin)="select.showMenu = true"
    [(ngModel)]="displayValue"
    [placeholder]="placeholder"
    #validations="ngModel">
</app-emma-select>
