import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FileTreeComponent} from './file-tree.component';
import {RecursiveTreeModule} from '../../../components/recursive-tree/recursive-tree.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  declarations: [
    FileTreeComponent
  ],
  exports: [
    FileTreeComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule.forRoot(),
    RecursiveTreeModule,
    MatButtonModule,
    NgIconsModule,
  ]
})
export class FileTreeModule { }
