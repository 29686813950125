import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserIconComponent } from './user-icon.component';
import {MatIconModule} from '@angular/material/icon';



@NgModule({
    declarations: [UserIconComponent],
    exports: [
        UserIconComponent
    ],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class UserIconModule { }
