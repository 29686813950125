import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {NewFileRequest, FileRequest, FulFillFileRequest, EditFileRequest} from 'app/functional/models/file-request.model';
import { Document, DocumentSkeleton } from 'app/functional/models/document';

export class FilterFileRequest {
  userId?: number;
  dossierId?: number;
  companyId?: number;
  folderId?: number;
  parentUserId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class FileRequestService {
  private url = `${environment.rootUrl}file_request`;

  constructor(
    private http: HttpClient,
  ) { }

  create(newFileRequest: NewFileRequest): Promise<FileRequest> {
    return this.http.post<FileRequest>(this.url, newFileRequest).toPromise();
  }

  filter(filter: FilterFileRequest): Promise<FileRequest[]> {
    let params = new HttpParams();
    if (filter.userId) {
      params = params.set('user_id', filter.userId.toString());
    }
    if (filter.companyId) {
      params = params.set('company_id', filter.companyId.toString());
    }
    if (filter.dossierId) {
      params = params.set('dossier_id', filter.dossierId.toString());
    }
    if (filter.folderId) {
      params = params.set('folder_id', filter.folderId.toString());
    }
    if (filter.parentUserId) {
      params = params.set('parent_user_id', filter.parentUserId.toString());
    }
    return this.http.get<FileRequest[]>(this.url, { params }).toPromise();
  }

  edit(fileRequestId: number, model: EditFileRequest): Promise<FileRequest> {
    return this.http.put<FileRequest>(`${this.url}/${fileRequestId}`, model).toPromise();
  }

  async fulfill(file: Blob, fulFillFileRequest: FulFillFileRequest, error: (response: Response) => void): Promise<Document> {
    const fulfillUrl = `${this.url}/${fulFillFileRequest.fileRequestId}/fulfill-using-url`;
    const documentSkeleton = await this.http.post<DocumentSkeleton>(fulfillUrl, fulFillFileRequest).toPromise();

    const uploadResponse = await fetch(documentSkeleton.signedUrl, {
      method: 'PUT',
      body: file,
    });
    if(!uploadResponse.ok) {
      error(uploadResponse);
      throw uploadResponse;
    }

    const document = DocumentSkeleton.asDocument(documentSkeleton);
    return document;
  }

  delete(fileRequestId: number) {
    return this.http.delete(`${this.url}/${fileRequestId}`).toPromise();
  }
}
