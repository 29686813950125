/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {MFAChallengeModel, MFAChallengeResultModel, MFAOptionModel} from '../../functional/models/mfa.model';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class MfaService {

  private url = `${environment.rootUrl}mfa`;
  private smsEndpoint = `${this.url}/sms`;
  private authenticatorEndpoint = `${this.url}/authenticator`;

  constructor(public http: HttpClient) {
  }

  resetMFAUser(mfaId: number) {
    return this.http.delete(`${this.url}/${mfaId}`).toPromise();
  }

  getMFAUser() {
    const helper = new JwtHelperService();
    const jwt = localStorage.getItem('MFAToken');
    if (jwt) {
      return helper.decodeToken(jwt!);
    }
  }

  getMethodsByUser(userId?: number): Promise<MFAOptionModel[]> {
    return this.http.get<MFAOptionModel[]>(`${this.url}`, {
      params: {
        user_id: userId!.toString()
      }
    }).toPromise();
  }

  getMethods(kind?: number, userId?: number): Promise<MFAOptionModel[]> {
    return this.http.get<MFAOptionModel[]>(`${this.url}`, {
      params: {
        kind: kind!.toString(),
        user_id: userId!.toString()
      }
    }).toPromise();
  }

  challenge(model: MFAChallengeModel) {
    return this.http.post<MFAChallengeResultModel>(this.url, model).toPromise().then((res: any) => {
      localStorage.setItem('MFAToken', res.token);
    });
  }

  configureSMS(phone: string): Promise<any> {
    return this.http.post(`${this.smsEndpoint}/initial`, {
      phone
    }).toPromise();
  }

  verifyIntialSMS(code: string, phone: string) {
    return this.http.post(`${this.smsEndpoint}/initial/verify`, {
      code,
      phone
    }).toPromise().then((res: any) => {
        localStorage.removeItem('MFAToken');
        localStorage.setItem('Token', res.token);
        localStorage.setItem('Refresh', res.refresh);
    });
  }

  requestSMS(): Promise<any> {
    return this.http.post(`${this.smsEndpoint}/standard`, {}).toPromise();
  }

  verifySMS(code: string) {
    return this.http.post(`${this.smsEndpoint}/standard/verify`, {code}).toPromise().then((res: any) => {
        localStorage.removeItem('MFAToken');
        localStorage.setItem('Token', res.token);
        localStorage.setItem('Refresh', res.refresh);
    });
  }

  configureAuthenticator(): Promise<{ url: string }> {
    return this.http.post<{url: string}>(`${this.authenticatorEndpoint}/initial`, {}).toPromise();
  }

  verifyIntialAuthenticator(code: string) {
    return this.http.post(`${this.authenticatorEndpoint}/initial/verify`, {
      code,
    }).toPromise().then((res: any) => {
      localStorage.removeItem('MFAToken');
      localStorage.setItem('Token', res.token);
      localStorage.setItem('Refresh', res.refresh);
    });
  }

  verifyAuthenticator(code: string) {
    return this.http.post(`${this.authenticatorEndpoint}/standard/verify`, {code}).toPromise().then((res: any) => {
      localStorage.removeItem('MFAToken');
      localStorage.setItem('Token', res.token);
      localStorage.setItem('Refresh', res.refresh);
    });
  }
}
