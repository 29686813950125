import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FileRequestService} from '../../../services/file-request/file-request.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {RedactedUser, RedactedUserWithPermissions, User} from '../../../functional/models/user';
import {Role} from '../../../functional/models/role.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-file-request-dialog',
  templateUrl: './file-request-dialog.component.html',
  styleUrls: ['./file-request-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileRequestDialogComponent implements OnInit {
  accessors?: (User | RedactedUserWithPermissions)[];
  chosenUser?: RedactedUser;
  isFilled = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Role = Role;
  fileRequestForm: UntypedFormGroup;
  isSpecific = false;

  constructor(
    public dialogRef: MatDialogRef<FileRequestDialogComponent>,
    private requestService: FileRequestService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this.fileRequestForm = this.formBuilder.group({
      fileName: ['' , [Validators.required]],
      description: ['', []]
    });
  }

  ngOnInit(): void {
  }

  async requestDocument() {

    const requesters: number[] = (this.accessors || []).filter((accessor: any) => accessor.requested).map(accessor => accessor.id);

    await this.requestService.create({
      dossierId: this.data?.dossierId || null,
      folderId: this.data.folderId,
      message: this.fileRequestForm.get('description')?.value,
      filename: this.fileRequestForm.get('fileName')?.value,
      isActive: true,
      notifyUserIds: requesters,
      userIds: requesters
    });

    this.dialogRef.close(true);
  }

  closeModal() {
    this.dialogRef.close();
  }

  next() {
    this.fileRequestForm.markAllAsTouched();
    if (this.fileRequestForm.invalid) {
      return;
    }
    this.isFilled = true;
  }

  setSpecific() {
    if (this.data.accessors) {
      this.accessors = this.data.accessors.map((accessor: any) => ({...accessor, requested: accessor.role === Role.customer}));
    }
    this.isSpecific = true;
  }

  setBack() {
    this.accessors = [];
    this.isSpecific = false;
  }
}
