import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectedUsersComponent } from './connected-users.component';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {UserIconModule} from '../user-icon/user-icon.module';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatSortModule} from '@angular/material/sort';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { NgIconsModule } from '@ng-icons/core';
import {UserSelectorDialogModule} from '../../dialogs/user-selector-dialog/user-selector-dialog.module';

@NgModule({
  declarations: [
    ConnectedUsersComponent
  ],
  exports: [
    ConnectedUsersComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    UserIconModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule.forRoot(),
    MatCheckboxModule,
    NgIconsModule,
    UserSelectorDialogModule
  ]
})
export class ConnectedUsersModule { }
