import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyTabNav as MatTabNav, MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {LogoUploadModule} from '../../components/logo-upload/logo-upload.module';
import { NgIconsModule } from '@ng-icons/core';
import {
  heroBriefcase,
  heroCog,
  heroEnvelopeOpen,
  heroUserGroup,
  heroXCircle,
  heroUsers,
  heroInbox,
  heroDocumentDuplicate,
  heroFolder,
  heroInformationCircle,
  heroUserCircle,
  heroQuestionMarkCircle,
  heroArrowRightOnRectangle,
  heroArrowDownTray,
  heroBuildingOffice2,
  heroKey,
  heroIdentification,
  heroSquaresPlus,
  heroSwatch,
  heroPuzzlePiece,
  heroSquare3Stack3d,
  heroRectangleGroup,
  heroViewColumns,
  heroExclamationCircle,
  heroInboxArrowDown,
  heroFolderArrowDown
} from '@ng-icons/heroicons/outline';

@NgModule({
  providers: [
    MatTabNav
  ],
    imports: [
        FormsModule,
        TranslateModule.forChild(),
        CommonModule,
        RouterModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatFormFieldModule,
        MatOptionModule,
        MatButtonModule,
        MatIconModule,
        MatBadgeModule,
        MatMenuModule,
        ReactiveFormsModule,
        MatTabsModule,
        NgIconsModule.withIcons({
            heroXCircle,
            heroArrowRightOnRectangle,//logout
            heroQuestionMarkCircle,
            heroInformationCircle,
            heroUserCircle,
            heroFolderArrowDown,
            heroArrowDownTray,//Download
            heroRectangleGroup,//Template
            heroDocumentDuplicate,
            heroFolder,
            heroInbox,
            heroBuildingOffice2,
            heroUsers,
            heroPuzzlePiece,
            heroSwatch,
            heroKey,
            heroCog,
            heroSquaresPlus,
            heroUserGroup,
            heroIdentification,
            heroBriefcase,
            heroViewColumns,//Boards
            heroSquare3Stack3d,//Collection
            heroExclamationCircle,
            heroInboxArrowDown,
            heroEnvelopeOpen
        }),
        LogoUploadModule,
    ],
  declarations: [
    NavbarComponent,
    SidebarComponent,
  ],
  exports: [
    NavbarComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { }
