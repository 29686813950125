import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatRippleModule} from '@angular/material/core';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatIconModule} from '@angular/material/icon';

import {MatPasswordStrengthComponent} from
    'app/components/mat-password-strength/component/mat-password-strength/mat-password-strength.component';
import {MatPasswordStrengthInfoComponent} from
    'app/components/mat-password-strength/component/mat-password-strength-info/mat-password-strength-info.component';
import {MatPassToggleVisibilityComponent} from
    'app/components/mat-password-strength/component/mat-pass-toggle-visibility/mat-pass-toggle-visibility.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';

export {MatPasswordStrengthComponent} from './component/mat-password-strength/mat-password-strength.component';
export {
  MatPasswordStrengthInfoComponent
} from './component/mat-password-strength-info/mat-password-strength-info.component';
export {MatPassToggleVisibilityComponent} from './component/mat-pass-toggle-visibility/mat-pass-toggle-visibility.component';
export {MatPasswordStrengthValidator} from './validator/mat-password-strength-validator';
export {regExpValidator} from './validator/regexp.class';

@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule,
        MatCardModule,
        MatIconModule,
        MatRippleModule,
        MatButtonModule
    ],
    exports: [
        MatPasswordStrengthComponent,
        MatPasswordStrengthInfoComponent,
        MatPassToggleVisibilityComponent
    ],
    declarations: [
        MatPasswordStrengthComponent,
        MatPasswordStrengthInfoComponent,
        MatPassToggleVisibilityComponent
    ]
})
export class MatPasswordStrengthModule {
  static forRoot(): ModuleWithProviders<MatPasswordStrengthModule> {
    return {
      ngModule: MatPasswordStrengthModule,
      providers: []
    };
  }
}
