import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { RouterModule } from '@angular/router';
import { EditorComponent } from './editor/editor.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {ExchangeModule} from '../../exchange/exchange.module';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import { ContextMenuComponent } from './editor/context-menu/context-menu.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    EditorComponent,
    ContextMenuComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    MatMenuModule,
    ExchangeModule,
    TranslateModule.forRoot(),
  ],
})
export class MailTemplatesModule { }
