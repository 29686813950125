import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupListComponent } from './group-list/group-list.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {groupRoutes} from './groups.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CreateGroupDialogComponent } from '../../../dialogs/create-group-dialog/create-group-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {SubNavModule} from '../../../components/sub-nav/sub-nav.module';
import {NgIconsModule} from '@ng-icons/core';
import {ConnectedUsersModule} from '../../../components/connected-users/connected-users.module';
import {EmmaInputModule} from '../../../components/base-components/emma-input/emma-input.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(groupRoutes),
        TranslateModule.forRoot(),
        MatTableModule,
        MatButtonModule,
        MatRippleModule,
        MatTooltipModule,
        MatIconModule,
        BrowserAnimationsModule,
        MatSortModule,
        MatPaginatorModule,
        FormsModule,
        MatSnackBarModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatOptionModule,
        ReactiveFormsModule,
        SubNavModule,
        NgIconsModule,
        ConnectedUsersModule,
        EmmaInputModule,
    ],
    declarations: [
        GroupListComponent,
        GroupDetailsComponent,
        CreateGroupDialogComponent
    ]
})
export class GroupsModule { }
