import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ChatMessage, ChatModel } from 'app/functional/models/chat.model';
import { Login } from 'app/functional/models/login.model';
import { EntityKind, SocketMessage } from 'app/functional/models/socket-message.model';
import { Claims } from 'app/functional/models/user';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import {ZipUrlModel} from '../../functional/models/zip.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  messages = new Subject<ChatMessage>();
  chats = new Subject<ChatModel>();
  logins = new Subject<Login>();
  zips = new Subject<ZipUrlModel>();

  private socket?: WebSocket;
  private helper!: JwtHelperService;

  constructor(
    private http: HttpClient,
  ) {
    //this.helper = new JwtHelperService();
    //this.setupSocket();
  }

  async setupSocket() {
    let claims = this.getLoginClaims();
    while (!claims) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      claims = this.getLoginClaims();
    }
    this.socket = new WebSocket(`${environment.socketUrl}feed/${claims.userId}`);

    this.socket.onopen = () => {
      this.socket!.send('Bearer ' + localStorage.getItem('Token'));
    };

    this.socket.onmessage = async (event: MessageEvent) => {
      const parsedData: SocketMessage<any> = JSON.parse(event.data);
      switch (parsedData.kind) {
        case EntityKind.chatMessage: {
          await this.playNotification();
          this.messages.next(parsedData.entity as ChatMessage);
          break;
        }
        case EntityKind.chat: {
          await this.playNotification();
          this.chats.next(parsedData.entity as ChatModel);
          break;
        }
        case EntityKind.login: {
          this.logins.next(parsedData.entity as Login);
          break;
        }
        case EntityKind.zip: {
          this.zips.next(parsedData.entity as ZipUrlModel);
          break;
        }
      }
    };

    this.socket.onclose = async (event: CloseEvent) => {
      this.socket = undefined;
      const token = localStorage.getItem('Token');
      if (!token || this.helper.isTokenExpired(token)) {
        while (!localStorage.getItem('Refresh')) {
          await new Promise(resolve => setTimeout(resolve, 2_000));
        }
        await this.refresh();
        //this.setupSocket();
      } else {
        //this.setupSocket();
      }
    };
  }

  async playNotification() {
    const audioPlayer: HTMLAudioElement = document.getElementById('beep')! as HTMLAudioElement;
    //audioPlayer.src = 'assets/notification.wav';
    await audioPlayer.play();
  }

  close() {
    this.socket?.close();
  }

  /**
   * FIXME: Booooo bad duplication.
   */
  async refresh(): Promise<any> {
    const refresh = !!localStorage.getItem('Refresh');
    if (refresh) {
      const url = `${environment.rootUrl}user`;
      const res: any = await this.http.post(`${url}/refresh`, {}).toPromise();
      localStorage.setItem('Token', res.token);
      localStorage.setItem('Refresh', res.refresh);
      return res;
    } else {
      return null;
    }
  }

  /**
   * FIXME: Booooo bad duplication.
   */
  getLoginClaims(): Claims | null | undefined {
    const helper = new JwtHelperService();
    const jwt = localStorage.getItem('Token');
    return helper.decodeToken(jwt!);
  }
}
