<div class="table-paginator-container">
  <div class="mat-table-container">
    <table mat-table [dataSource]="dataSource" matSort class="connected-user-table emma-table">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            class="selector">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customers.name' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <span class="cell-icon-centered">
            <app-user-icon [firstName]="row.firstName" [lastName]="row.lastName" style="margin-right: 15px"></app-user-icon>
            <span class="cell-block">
              <span class="font-bold">{{row.firstName}} {{row.lastName}}</span>
              <span class="font-light">{{row.email}}</span>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customers.phone' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <span class="cell-block">
            <span class="font-default">{{row.phone || ' - '}}</span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> {{'customers.address' | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <span class="cell-block">
            <span class="font-default" *ngIf="row.street">{{row.street}} {{row.houseNumber}} {{row.premise}}</span>
            <span class="font-default" *ngIf="row.postalCode || row.city">{{row.postalCode}} {{row.city}}</span>
            <span class="font-default" *ngIf="!row.street && !row.postalCode && !row.city"> - </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <ng-icon name="heroTrash" style="z-index: 1000;" class="ng-icon-default hover-hero" (click)="deleteRow(row, $event)"></ng-icon>
        </td>
      </ng-container>

      <tr class="empty-row" *matNoDataRow>
        <td colspan="9999">
          <div class="illustration" *ngIf="isCustomer">
            <img src="assets/img/illustrations/collaborate.svg">
            <h1 class="not-found-title">{{'connected_users.no_customers' | translate}}</h1>
            <span>
              {{'connected_users.no_customers_text1' | translate}}
              {{'connected_users.no_customers_text2' | translate}}
            </span>
          </div>
          <div class="illustration" *ngIf="!isCustomer">
            <img src="assets/img/illustrations/collaborate.svg">
            <h1 class="not-found-title">{{'connected_users.no_employees' | translate}}</h1>
            <span>
              {{'connected_users.no_employees_text1' | translate}}
              {{'connected_users.no_employees_text2' | translate}}
            </span>
          </div>
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="element" *matRowDef="let row; columns: displayedColumns;" (click)="gotoUser(row)"></tr>
    </table>
  </div>
  <div>
    <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </div>
</div>
