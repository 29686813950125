<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>

<div class="request-signing-dialog">
  <div class="row margin-30">
    <div class="col-md-12 centered">
      <p class="emma-title">{{signType === 0 ? ('dialog.request.sign' | translate) : ('dialog.request.accept' | translate)}}</p>
    </div>
  </div>

  <div class="row margin-20">
    <div class="col-md-12 align-text-center">
      <p>{{'dialog.request.sign_dossier_title' | translate : { type: signType === 0 ? ('dialog.request.type.signature' | translate | lowercase) : ('dialog.request.type.approval' | translate | lowercase)} }}</p>
      <b style="font-weight: bold" *ngFor="let file of files; let last = last">{{last? file.name : file.name + ', '}}</b>
      <br /><br/>
      <p>{{ 'dialog.request.which_customers' | translate : {type: signType === 0 ? ('dialog.request.type.signature' | translate | lowercase) : ('dialog.request.type.approval' | translate | lowercase)} }}</p>
    </div>
  </div>

  <div class="row margin-20">
    <div class="col-md-12 centered">
      <p class="emma-subtitle">
        {{ 'dialog.request.customers' | translate }}
      </p>
    </div>
  </div>
  <div class="row" *ngFor="let customer of dossier.customers">
    <div class="col-md-4 col-md-offset-3">
      <b>{{customer.firstName + ' ' + customer.lastName}}</b>
    </div>
    <div class="col-md-4">
      <mat-checkbox [(ngModel)]="customer['signed']">{{signType === 1 ? ('dialog.request.accept' | translate) : ('dialog.request.sign' | translate)}}</mat-checkbox>
    </div>
  </div>
  <div class="row margin-top-50">
    <div class="col-md-12 centered">
      <button  class="btn btn-blue btn-custom " (click)="submit()">
        <b>{{ 'dialog.request.dossier_to_customer' | translate }}</b>

      </button>
    </div>
  </div>
</div>
