import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UserService} from '../../../services/user/user.service';
import {Role} from '../../../functional/models/role.model';
import {Folder} from '../../../functional/models/folder';
import {CompanyService} from '../../../services/company/company.service';
import {DynamicFlatNode, SourceFilter} from '../../../components/recursive-tree/recursive-tree.component';
import {Company} from '../../../functional/models/company';
import {ExplorerItem} from '../../../functional/models/explorer.model';

@Component({
  selector: 'app-file-tree',
  templateUrl: './file-tree.component.html',
  styleUrls: ['./file-tree.component.scss']
})
export class FileTreeComponent implements OnInit {
  rootFolders?: Folder[];
  type: 'clone'|'copy';
  source!: SourceFilter;
  company!: Company;
  selectedFolderIds: number[] = [];
  selectedFolders!: ExplorerItem[];
  multi: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FileTreeComponent>,
    public companyService: CompanyService,
    public userService: UserService
  ) {
    this.type = data.type;
    this.company = data.company;
    this.source = data.source;
    this.selectedFolders = data.selectedFolders || [];
    this.multi = data.multi || false;
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }

  submit() {
    if (this.multi){
      this.dialogRef.close({folderIds: this.selectedFolderIds});
    } else {
      if (this.selectedFolderIds.length > 0) {
        this.dialogRef.close({folderId: this.selectedFolderIds[0]});
      } else {
        this.dialogRef.close();
      }
    }
  }

  async initializeFromRoot() {
    const role = this.userService.getLoginClaims()!.role;
    if (role >= Role.employee) {
      await this.initializeFromUserRoot();
    } else {
      await this.initializeFromAdminRoot();
    }
  }

  async initializeFromAdminRoot() {
    this.rootFolders = [(await this.companyService.getRoot(this.data.company))];
  }

  async initializeFromUserRoot() {
    const user = this.userService.getLoginClaims();
    this.rootFolders = await this.userService.getAccesspoints(user!.userId);
  }

  setSelected(event: DynamicFlatNode[]) {
      this.selectedFolderIds = event.map(item => item.id);
  }
}
