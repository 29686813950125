<span
  style="position:fixed; top: 0; left: 0;"
  class="context-menu-trigger"
  #matTrigger="matMenuTrigger"
  #triggerElement
  [matMenuTriggerFor]="menu"
  *ngIf="isInitial"
></span>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let value of values">
    <button mat-menu-item (click)="emitData(value[0])">
      {{ value[0] }} ({{ value[1] }})
    </button>
  </ng-container>

  <ng-container *ngFor="let child of children">
    <button mat-menu-item [matMenuTriggerFor]="contextItem.menu">
      {{ child[0] == '0' || child[0] == 0 ? 'Item' : child[0]}}
    </button>
    <app-context-menu
      [data]="child[1]"
      [isInitial]="false"
      #contextItem
      [prefix]="child[0]"
      (contextClicked)="emitData($event)"
    ></app-context-menu>
  </ng-container>
</mat-menu>
