<nav class="navbar navbar-expand-lg navbar-absolute fixed-top main-navbar emma-navbar">
  <div class="navbar-wrapper">
    <ng-container *ngFor="let crumb of breadcrumbs; let last = last">
      <a class="navbar-brand" [routerLink]="crumb.url" *ngIf="!crumb.disableTitle">
        <ng-container *ngIf="!!crumb.label">
          {{!crumb.isParam ? ('menu.' + crumb.label | translate) : getParamData(crumb.label)}} {{ last && (!crumb.folderCrumbs || navData.folderCrumbs?.length <= 0) ? '' : '>' }}
        </ng-container>
      </a>

      <ng-container *ngIf="crumb.folderCrumbs">
        <ng-container *ngFor="let folder of navData.folderCrumbs; let lastFolder = last">
          <a class="navbar-brand" (click)="navigate(folder)">
            {{folder.name}} {{ lastFolder && !navData.fileCrumb ? '' : '>' }}
          </a>
        </ng-container>
        <ng-container *ngIf="navData.fileCrumb">
          <a class="navbar-brand">
            {{navData.fileCrumb.name}}
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="collapse navbar-collapse justify-content-end" id="navigation">
    <div class="input-group no-border" *ngIf="claims && claims.role === localRole.superuser">
      <mat-form-field appearance="outline" style="margin-bottom: -1.25em">
        <mat-select disableOptionCentering panelClass="myPanelClass" [placeholder]="'navbar.company' | translate">
          <mat-option>
            <ngx-mat-select-search
              [placeholderLabel]="'dossier_template.dialog.create_template.search' | translate"
              ngModel
              (ngModelChange)="filter($event)"
              [noEntriesFoundLabel]="'dossier_template.dialog.create_template.no_result' | translate"
              [ngModelOptions]="{standalone: true}">
              <ng-icon class="ng-icon-default" name="heroXCircle"></ng-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option selected (click)="setCompany(undefined)">{{ 'navbar.company' | translate}}</mat-option>
          <mat-option *ngFor="let company of filteredCompanies" [value]="company" (click)="setCompany(company)">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a class="nav-link" id="navbarDropdownMenuLink" [matMenuTriggerFor]="menu">
          <ng-icon class="ng-icon-default" name="heroFolderArrowDown"></ng-icon>
          <span class="notification" *ngIf="zipItems.length > 0">{{zipItems.length}}</span>
        </a>
        <mat-menu class="dropdown-size" #menu="matMenu">
          <ng-container *ngIf="zipItems.length > 0">
            <a class="dropdown-item" (click)="openDownload(item, zipLink)" *ngFor="let item of zipItems">{{fixName(item.zipName)}}</a>
          </ng-container>

          <ng-container *ngIf="zipItems.length <= 0">
            <div class="no-items">
              <p class="emma-font-light text-align-center">{{ 'navbar.no_downloads' | translate }}</p>
            </div>
          </ng-container>
        </mat-menu>
        <a [href]="url" #zipLink style="display: none"></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard/profile">
          <ng-icon class="ng-icon-default" name="heroUserCircle" type="solid"></ng-icon>
          <p>
            <span class="d-lg-none d-md-block">{{ 'navbar.account' | translate }}</span>
          </p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link pointer" href="https://intercom.help/MyEmma/nl/" target="_blank">
          <ng-icon class="ng-icon-default" name="heroInformationCircle" type="solid"></ng-icon>
          <p>
            <span class="d-lg-none d-md-block">{{ 'navbar.support' | translate }}</span>
          </p>
        </a>
      </li>
      <li class="nav-item" id="intercom_widget" *ngIf="claims && claims.role !== localRole.customer">
        <a class="nav-link pointer" target="_blank">
          <ng-icon class="ng-icon-default" name="heroQuestionMarkCircle"></ng-icon>
          <p>
            <span class="d-lg-none d-md-block">{{ 'navbar.support' | translate }}</span>
          </p>
        </a>
      </li>
      <li data-test="logout-button" class="nav-item">
        <a class="nav-link pointer" (click)="logout()">
          <ng-icon class="ng-icon-default" name="heroArrowRightOnRectangle"></ng-icon>
          <p>
            <span class="d-lg-none d-md-block">{{ 'navbar.logout' | translate }}</span>
          </p>
        </a>
      </li>
    </ul>
  </div>

  <span class="navbar-toggler" (click)="sidebarToggle()">
    <span class="sr-only">{{ 'navbar.toggle' | translate }}</span>
    <span class="navbar-toggler-icon icon-bar"></span>
    <span class="navbar-toggler-icon icon-bar"></span>
    <span class="navbar-toggler-icon icon-bar"></span>
  </span>
</nav>
