<div class="emma-body" style="padding-top: 55px">
  <app-sub-nav
    [search]="true"
    (searchChange)="applyFilter($event)"
    [buttons]="[{icon:'add', name: 'new' | translate, event: 'new'},{name: 'options' | translate, event: 'options', hidden: true}]"
    (buttonClicked)="handleClick($event)">
  </app-sub-nav>
  <div class="customer-overview table-paginator-container">
    <div class="mat-table-container">
      <table mat-table [dataSource]="dataSource" matSort class="emma-table">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              class="selector">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customers.name' | translate}} </th>
          <td mat-cell *matCellDef="let row">
            <span class="cell-icon-centered">
              <app-user-icon [firstName]="row.firstName" [lastName]="row.lastName" style="margin-right: 15px"></app-user-icon>
              <span class="cell-block">
                <span class="font-bold">{{row.firstName}} {{row.lastName}}</span>
                <span class="font-light">{{row.email}}</span>
              </span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef class="sort-style"> {{'customers.address' | translate}} </th>
          <td mat-cell *matCellDef="let row" >
            <span class="cell-block">
              <span class="font-default" *ngIf="row.street">{{row.street}} {{row.houseNumber}} {{row.premise}}</span>
              <span class="font-default" *ngIf="row.postalCode || row.city">{{row.postalCode}} {{row.city}}</span>
              <span class="font-default" *ngIf="!row.street && !row.postalCode && !row.city"> - </span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="organizations">
          <th mat-header-cell *matHeaderCellDef class="sort-style"> {{'customers.organizations' | translate}} </th>
          <td mat-cell *matCellDef="let row">
          <span *ngIf="row?.organizations?.length === 0" class="font-default"> - </span>
          <span class="cell-block" *ngIf="row?.organizations?.length > 0">
            <span (click)="routeToOrganization($event, organization)" class="font-link" *ngFor="let organization of row.organizations">{{organization.name}}</span>
          </span>
          </td>
        </ng-container>

        <tr class="empty-row" *matNoDataRow>
          <td colspan="9999">
            <div class="illustration">
              <img src="assets/img/illustrations/collaborate.svg">
              <h1 class="not-found-title">{{'customers.no_customers' | translate}}</h1>
              <span>
              {{'customers.no_customers_text1' | translate}}
              {{'customers.no_customers_text2' | translate}}
            </span>
            </div>
          </td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
        <tr mat-row class="element" *matRowDef="let row; columns: displayedColumns;" (click)="redirect(row.id)"></tr>
    </table>
    </div>
    <div>
      <mat-paginator fixed [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>
