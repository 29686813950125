import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SurveyQuestionComponent} from '../survey-question/survey-question.component';

@Component({
  selector: 'app-survey-division',
  templateUrl: './survey-division.component.html',
  styleUrls: ['./survey-division.component.scss']
})
export class SurveyDivisionComponent implements OnInit {

  questions = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith'
  ];

  editing?: SurveyQuestionComponent;

  constructor() { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
  }

}
