import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public snackBar: MatSnackBar, private translate: TranslateService, public router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 404) {
            return throwError(err);
          }

          if (err.status === 401 && this.router.url.includes('auth')) {
            return throwError(err);
          }

          if (err.status === 401 && !this.router.url.includes('auth')){
            this.translate.get(['unauthorized', 'close']).toPromise().then((msg) => {
              this.snackBar.open(
                msg.unauthorized,
                msg.close,
                {duration: 5000}
              );
            });
            return throwError(err);
          }
          this.translate.get(['error.' + err.error.code, 'close']).toPromise().then((msg) => {
            this.snackBar.open(
      msg['error.' + err.error.code] + ' [Error: ' + err.error.code + ']',
              msg.close,
              {duration: 5000},
            );
          });
        }
        return throwError(err);
      })
    );
  }
}

