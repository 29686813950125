import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MailAddressResultModel} from '../../functional/models/inbound-mail.model';

@Injectable({
  providedIn: 'root'
})
export class InboundMailService {
  private url = `${environment.rootUrl}inbound`;

  constructor(private http: HttpClient) { }

  getMail(folderId: number, userId: number): Promise<MailAddressResultModel> {
    return this.http.post<MailAddressResultModel>(`${this.url}/email`, {folderId, userId}).toPromise();
  }
}
