import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, Location } from '@angular/common';
import { CompanyService } from './services/company/company.service';
import { Company } from './functional/models/company';
import { UserService } from './services/user/user.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { DateAdapter } from '@angular/material/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  companyId!: number;
  company!: Company;
  companySubscription = new Subscription();
  unread = 0;
  muted = true;
  autoPlay = true;
  private initializedSound = false;

  constructor(
      public translate: TranslateService,
      public location: Location,
      public companyService: CompanyService,
      public userService: UserService,
      public titleService: Title,
      @Inject(DOCUMENT) public currentDocument: HTMLDocument,
      private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('nl');
    window.addEventListener('unread-set',  (e: any) => this.unread = e.detail.count(), false);
  }

  @HostListener('document:click', ['$event'])
  async documentClick(event: MouseEvent) {
    try {
      if (!this.initializedSound) {
        const player = (document.getElementById('beep') as HTMLAudioElement);
        player.pause();
        this.initializedSound = true;
      }
    }catch (e){}
  }

  pathContains(url: string) {
    const title = this.location.prepareExternalUrl(this.location.path());
    return title.includes(url);
  }

  isIE() {
    const ua = window.navigator.userAgent;
    return ua.includes('MSIE') || ua.includes('Trident/');
  }

  async ngOnInit() {
    this.companySubscription = this.companyService.company.subscribe(async (company: Company | null) => {
      if (!this.currentDocument) {
        throw new Error('document is null');
      }
      if (company) {
        this.company = company;
        this.currentDocument.getElementById('theme')!.setAttribute('href', environment.rootUrl + 'company/' + this.company.id + '/css');
      } else {
        this.company = await this.companyService.findCurrentCompany();
        this.currentDocument.getElementById('theme')!.setAttribute('href', environment.rootUrl + 'company/css');
      }
      await this.setupLanguage();
      await this.setupEnvironment();
    });
  }

  async setupLanguage() {
    this.translate.addLangs(['nl', 'en', '1_en', '1_nl']);
    this.translate.setDefaultLang('nl');

    if (localStorage.getItem('Token') || localStorage.getItem('Refresh')) {
      const user = await this.userService.getUser();

      if (user.language) {
        if (this.company && this.company.companyType >= 1) {
          this.translate.use(`${this.company.companyType}_${user.language}`);
        } else {
          this.translate.use(user.language);
        }
        this.currentDocument.documentElement.lang = user.language;
        return;
      }
    }

    const browserLang = this.translate.getBrowserLang();
    if (this.company && this.company.companyType >= 1) {
      this.translate.use(browserLang?.match(/en|nl/) ? `${this.company.companyType}_${browserLang}` : `${this.company.companyType}_nl`);
      if (browserLang?.includes('en')) {
        this.currentDocument.documentElement.lang = 'en';
      }
      if (browserLang?.includes('nl')) {
        this.currentDocument.documentElement.lang = 'nl';
      }
    } else {
      this.translate.use(browserLang?.match(/en|nl/) ? browserLang : 'nl');
      if (browserLang?.includes('en')) {
        this.currentDocument.documentElement.lang = 'en';
      }
      if (browserLang?.includes('nl')) {
        this.currentDocument.documentElement.lang = 'nl';
      }
    }
  }

  async setupEnvironment() {
    this.titleService.setTitle(this.company.name);
    const imageUrl = this.companyService.imageUrl(this.company);
    this.currentDocument.getElementById('appFavicon')!.setAttribute('href', imageUrl);
  }

  ngOnDestroy(): void {
    this.companySubscription.unsubscribe();
  }

  onSideNavScroll(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  isAuthenticated() {
    const token = localStorage.getItem('Token');
    const refresh = localStorage.getItem('Refresh');
    const helper = new JwtHelperService();

    if ((token && !helper.isTokenExpired(token)) || (refresh && !helper.isTokenExpired(refresh))) {
      return true;
    }
    return false;
  }
}
