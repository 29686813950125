<button mat-icon-button [autofocus]="false" class="dialog-close-button" (click)="closeModal()">
  <ng-icon name="heroXCircle"></ng-icon>
</button>
<div class="create-group-dialog modal-centered">
  <div class="row">
    <div class="col-md-12 centered extra-margin-top">
      <p class="emma-title title-padding"> {{'groups.list.dialog.title' | translate}} </p>
    </div>
  </div>
  <form [formGroup]="groupForm" *ngIf="groupForm">
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <div class="emma-input-container">
          <label label for="name" class="emma-input-label">{{'groups.list.dialog.name' | translate }}</label>
          <input appEmmaInput id="name" formControlName="name">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered extra-margin extra-margin-top">
        <div class="emma-input-container">
          <label label for="description" class="emma-input-label">{{'groups.list.dialog.description' | translate }}</label>
          <textarea appEmmaInput id="description" formControlName="description" style="height: 100px"></textarea>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12 centered small-margin-bottom">
      <button data-test="delete-submit-button"  class="btn btn-blue" (click)="submit()">
        <b> {{'groups.list.dialog.submit' | translate}} </b>

      </button>
    </div>
  </div>
</div>
