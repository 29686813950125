import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {LogoutReasonDialogComponent} from '../../dialogs/logout-reason-dialog/logout-reason-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(public router: Router, private dialog: MatDialog,) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem('Token');
    const refresh = localStorage.getItem('Refresh');
    const helper = new JwtHelperService();

    if (!token && !refresh) {
      this.router.navigate(['auth', 'login'], {queryParams: {returnUrl: state.url}});
      return false;
    }

    if (token && helper.isTokenExpired(token) && (!refresh || helper.isTokenExpired(refresh))) {
      localStorage.removeItem('Token');
      localStorage.removeItem('Refresh');

      this.router.navigate(['auth', 'login'], { queryParams: { returnUrl: state.url }}).then(() => {
        this.dialog.open(LogoutReasonDialogComponent, {
          data: {
            reason: 2,
          },
          autoFocus: false
        });
      });
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
