import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiKey, FullApiKey } from 'app/functional/models/api-key.model';
import { ApiKeyService } from 'app/services/api-key/api-key.service';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss']
})
export class ApiKeysComponent implements OnInit {
  keys?: (ApiKey | FullApiKey)[];
  form!: UntypedFormGroup;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private apiKeyService: ApiKeyService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      userId: [undefined, [Validators.required]],
    });
    const claims = this.userService.tryClaims();
    this.keys = await this.apiKeyService.byUser(claims.userId);
  }

  async create(form: UntypedFormGroup): Promise<void> {
    if (form.invalid) {
      this.snackBar.open(
        'Je formulier klopt niet 100%',
        'Sluiten',
        { duration: 5000 },
      );
      return;
    }
    const newApiKey = {
      name: this.form.get('name')!.value,
      userId: +this.form.get('userId')!.value,
    };
    const apiKey = await this.apiKeyService.create(newApiKey);
    this.keys?.push(apiKey);
  }

  async delete(key: ApiKey): Promise<void> {
    await this.apiKeyService.delete(key.id);
    const idx = this.keys!.findIndex(k => k.id === key.id);
    this.keys!.splice(idx, 1);
  }
}
