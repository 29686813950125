import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {SelectionModel} from '@angular/cdk/collections';
import {Upload} from 'app/functional/models/upload';
import {Folder} from '../../functional/models/folder';
import {Document} from '../../functional/models/document';
import {MatSort, Sort} from '@angular/material/sort';
import {FolderService} from '../../services/folder/folder.service';
import {ExplorerItem} from '../../functional/models/explorer.model';
import {FileRequest} from '../../functional/models/file-request.model';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {Subscription, timer} from 'rxjs';
import {EmmaFileDropEntry, FileSystemDirectoryEntry, FileSystemEntry, FileSystemFileEntry} from 'app/functional/models/file-drop.model';

@Component({
  selector: 'app-file-table',
  templateUrl: './file-table.component.html',
  styleUrls: ['./file-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileTableComponent implements AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @Input() listSelection!: SelectionModel<ExplorerItem>;
  @Output() dropUpload = new EventEmitter<{event: EmmaFileDropEntry[]; file: any}>();
  @Output() dropped = new EventEmitter<DragEvent>();
  @Output() draggingChange = new EventEmitter<Folder | Document | FileRequest | undefined>();
  @Output() highlightedChange = new EventEmitter();
  @Output() selectFileChange = new EventEmitter();
  @Output() selectFolderChange = new EventEmitter();
  @Output() clickFolderChange = new EventEmitter();
  @Output() clickFileChange = new EventEmitter();
  @Output() clickRequestChange = new EventEmitter();
  @Output() listUpdated = new EventEmitter();
  @Output() selectionChange = new EventEmitter();
  @Output() selectRequestChange = new EventEmitter();

  get folders(): Folder[] {
    return this.localFolders;
  }

  @Input()
  set folders(val: Folder[]) {
    this.localFolders = val;
    if (this.isInitialized) {
      this.setTableData();
    }
  }

  get uploads(): Upload[] {
    return this.localUploads;
  }

  @Input()
  set uploads(val: Upload[]) {
    this.localUploads = val;
    if (this.isInitialized) {
      this.setTableData();
    }
  }

  get highlighted(): Folder | Document | FileRequest | undefined  {
    return this.localHighlighted;
  }

  @Input()
  set highlighted(folder: Folder | Document | FileRequest | undefined) {
    this.localHighlighted = folder;
    this.highlightedChange.emit(this.localHighlighted);
  }

  get dragging(): Folder | Document | FileRequest | undefined  {
    return this.localDragging;
  }

  @Input()
  set dragging(item: Folder | Document | FileRequest | undefined) {
    this.localDragging = item;
    this.draggingChange.emit(this.localDragging);
  }

  get documents(): Document[] {
    return this.localDocuments;
  }

  @Input()
  set documents(val: Document[]) {
    this.localDocuments = val;
    if (this.isInitialized) {
      this.setTableData();
    }
  }

  get fileRequests(): FileRequest[] {
    return this.localRequests;
  }

  @Input()
  set fileRequests(val: FileRequest[]) {
    this.localRequests = val;
    if (this.isInitialized) {
      this.setTableData();
    }
  }

  localHighlighted?: Folder | Document | FileRequest;
  localDragging?: Document | Folder | FileRequest;
  singleFolderPlaceholder = document.createElement('img');
  singleFilePlaceholder = document.createElement('img');
  singleRequestPlaceholder = document.createElement('img');
  multiFolderPlaceholder = document.createElement('img');
  multiFilePlaceholder = document.createElement('img');
  multiRequestPlaceholder = document.createElement('img');
  displayedColumns: string[] = ['select', 'icon', 'name', 'extension', 'size', 'creationDate'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  document = Document;
  isInitialized = false;

  private localFolders: Folder[] = [];
  private localDocuments: Document[] = [];
  private localUploads: Upload[] = [];
  private localRequests: FileRequest[] = [];
  private sort!: MatSort;

  private numOfActiveReadEntries = 0;
  private files: EmmaFileDropEntry[] = [];
  private dropEventTimerSubscription: Subscription | null = null;

  constructor(
    public folderService: FolderService,
    private zone: NgZone,
  ) {
    this.singleFolderPlaceholder.src = 'assets/img/folder.png';
    this.singleFilePlaceholder.src = 'assets/img/file.png';
    this.singleRequestPlaceholder.src = 'assets/img/request.png';
    this.multiFolderPlaceholder.src = 'assets/img/folders.png';
    this.multiFilePlaceholder.src = 'assets/img/files.png';
    this.multiRequestPlaceholder.src = 'assets/img/requests.png';
  }

  setDataSourceAttributes() {
    this.dataSource.sort = this.sort;
  }

  setTableData() {
    if (this.uploads || this.documents || this.folders || this.fileRequests) {
      const activeRequests = this.fileRequests.filter(req => req.isActive && !req.fileId);
      const documents = this.documents.sort((a, b) => a.name.localeCompare(b.name));

      this.dataSource.data = [...this.folders, ...activeRequests, ...documents, ...this.uploads];
    }
  }

  ngAfterViewInit() {
    const item: {id: string; dir: 'asc' | 'desc' | ''}[] =
      localStorage.getItem('file-table-sort') ? JSON.parse(localStorage.getItem('file-table-sort')!) : [];
    for (const sortDir of item) {
      if (sortDir.dir !== '') {
        this.sort.sort({id: sortDir.id, start: sortDir.dir, disableClear: false});
      }
    }
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.setTableData();
    this.isInitialized = true;
  }

  ngOnDestroy() {
    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
      this.dropEventTimerSubscription = null;
    }
    this.files = [];
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.listSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  async masterToggle() {
    if(this.isAllSelected()){
      this.listSelection.clear();
    } else {
      const promises: Promise<any>[] = [];

      this.dataSource.data.forEach(row => {
        row.pending = false;
        promises.push(this.toggle(row));
      });

      await Promise.all(promises);
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.listSelection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  async clickRow(row: any) {
    if (this.isSelected(row)) {
      if (this.isFolder(row)) {
        this.clickFolderChange.emit(row);
      }
      if (this.isFile(row)) {
        this.clickFileChange.emit(row);
      }
      if (this.isRequest(row)) {
        this.clickRequestChange.emit(row);
      }
    } else {
      await this.toggle(row);
    }
  }

  floorSize(num: number) {
    return Math.floor( num * 100) / 100;
  }

  async toggle(row: any) {
    if (this.isFolder(row)) {
      this.selectFolderChange.emit(row);
    }
    if (this.isFile(row)) {
      this.selectFileChange.emit(row);
    }
    if (this.isRequest(row)) {
      this.selectRequestChange.emit(row);
    }
  }

  isRequest(item: Folder | Document | FileRequest) {
    return item.hasOwnProperty('filename');
  }

  isFile(item: Folder | Document | FileRequest) {
    return item.hasOwnProperty('extension');
  }

  isFolder(item: Folder | Document | FileRequest) {
    return item.hasOwnProperty('ftpDestinationFolder');
  }

  isSelected(item: Folder | Document) {
    if (this.isFolder(item)) {
      return !!this.listSelection.selected.find((folder) => folder.folder && folder.folder?.id === item.id);
    }
    if (this.isFile(item)) {
      return !!this.listSelection.selected.find((file) => file.file && file.file?.id === item.id);
    }
    if (this.isRequest(item)) {
      return !!this.listSelection.selected.find((request) => request.request && request.request?.id === item.id);
    }
  }

  highlightFolder(row: any, event: DragEvent) {
    if (this.isFolder(row)) {
      event.preventDefault();
      event.stopPropagation();
      if (this.highlighted !== row) {
        this.highlighted = row;
      }
      event.preventDefault();
      event.stopPropagation();
    }
  }

  unHighlightFolder(row: any) {
    if (this.isFolder(row)) {
      if (this.highlighted === row) {
        this.highlighted = undefined;
      }
    }
  }

  async fileDragDropped(event: DragEvent, row: any) {
    if (!this.isFolder(row)){
      return;
    }

    if (event.dataTransfer && !this.dragging) {
      let items: FileList | DataTransferItemList;
      if (event.dataTransfer.items) {
        items = event.dataTransfer.items;
      } else {
        items = event.dataTransfer.files;
      }
      event.stopPropagation();
      event.preventDefault();

      this.checkFiles(items, row);
    } else {
      this.dropped.next(event);
    }
  }

  startDragging(row: any, event: any) {
    if (!this.isFolder(row)) {
      if (this.listSelection.selected.length > 1) {
        event.dataTransfer.setDragImage(this.multiFilePlaceholder, 0, 0);
      } else {
        event.dataTransfer.setDragImage(this.singleFilePlaceholder, 0, 0);
      }
    }

    if (this.isFolder(row)) {
      if (this.listSelection.selected.length > 1) {
        event.dataTransfer.setDragImage(this.multiFolderPlaceholder, 0, 0);
      } else {
        event.dataTransfer.setDragImage(this.singleFolderPlaceholder, 0, 0);
      }
    }

    if (this.isRequest(row)) {
      if (this.listSelection.selected.length > 1) {
        event.dataTransfer.setDragImage(this.multiRequestPlaceholder, 0, 0);
      } else {
        event.dataTransfer.setDragImage(this.singleRequestPlaceholder, 0, 0);
      }
    }
    this.dragging = row;
  }

  stopDragging(row: any) {
    if (this.isFile(row) || this.isFolder(row)) {
      this.dragging = undefined;
      this.highlighted = undefined;
    }
  }

  storeSort(event: Sort) {
    localStorage.setItem('file-table-sort', JSON.stringify([{id: event.active, dir: event.direction}]));
  }

  private checkFiles(items: FileList | DataTransferItemList, row?: any): void {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let entry: FileSystemEntry | null = null;
      const dataTransferItem = (item as DataTransferItem);
      if (!!dataTransferItem.webkitGetAsEntry) {
        entry = dataTransferItem.webkitGetAsEntry();
      }

      if (!entry) {
        if (item) {
          const fakeFileEntry: FileSystemFileEntry = {
            name: (item as File).name,
            isDirectory: false,
            isFile: true,
            file: <T>(callback: (filea: File) => T) => callback(item as File),
          };
          const toUpload: EmmaFileDropEntry = new EmmaFileDropEntry(fakeFileEntry.name, fakeFileEntry);
          this.files.push(toUpload);
        }

      } else {
        if (entry.isFile) {
          const toUpload: EmmaFileDropEntry = new EmmaFileDropEntry(entry.name, entry);
          this.files.push(toUpload);
        } else if (entry.isDirectory) {
          this.traverseFileTree(entry, entry.name);
        }
      }
    }

    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
    }

    this.dropEventTimerSubscription = timer(200, 200)
      .subscribe(() => {
        if (this.files.length > 0 && this.numOfActiveReadEntries === 0) {
          const files = this.files;
          this.files = [];
          this.dropUpload.emit({event: files, file: row});
        }
      });
  }

  private traverseFileTree(item: FileSystemEntry, path: string): void {
    if (item.isFile) {
      const toUpload: EmmaFileDropEntry = new EmmaFileDropEntry(path, item);

      this.files.push(toUpload);

    } else {
      path = path + '/';
      const dirReader = (item as FileSystemDirectoryEntry).createReader();
      let entries: FileSystemEntry[] = [];

      const readEntries = () => {
        this.numOfActiveReadEntries++;
        dirReader.readEntries((result) => {
          if (!result.length) {
            // add empty folders
            if (entries.length === 0) {
              const toUpload: EmmaFileDropEntry = new EmmaFileDropEntry(path, item);
              this.zone.run(() => {
                this.files.push(toUpload);
              });

            } else {
              for (const entry of entries) {
                this.zone.run(() => {
                  this.traverseFileTree(entry, path + entry.name);
                });
              }
            }

          } else {
            // continue with the reading
            entries = entries.concat(result);
            readEntries();
          }

          this.numOfActiveReadEntries--;
        });
      };
      readEntries();
    }
  }
}
